import React, { useEffect, useState } from 'react'
import NavBar_emp from '../NavBar_emp'
import { Container, Tab, Tabs } from '@material-ui/core'
import { ProfileTags } from '../profile/profile'
import { Button, Checkbox, Grid, Paper, TextField, Typography, FormControlLabel, Switch } from '@material-ui/core'
import JobsImg from '../../assets/jobs.png'
import { styled } from '@material-ui/styles'
import MessagesImg from '../../assets/messages.png'
import EyesImg from '../../assets/eyes.png'
import ClicksImg from '../../assets/clicks.png'
import SmolBag from '../../assets/smolBag.png'
import Preview from '../../assets/Preview.png'
import Delete from '../../assets/delete.png'
import Edit from '../../assets/edit.png'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import InputAdornment from '@mui/material/InputAdornment'
// import DeleteIcon from '../../emp_settings/deleteIcon.svg';
import NoJobs from '../../images/no-jobs.jpeg';
import DeleteIcon from '../emp_settings/deleteIcon.svg'
import DollarIcon from './Input-icon.png'
import LinkIcon from './link-icon.png'
import { Slider } from '@mui/material'
import Select from 'react-select'
import MUIRichTextEditor from 'mui-rte'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Helmet } from 'react-helmet'
import './employerDashboard.css'
import Cookies from 'js-cookie'
import axiosInstance from '../../axios'
import { convertToRaw } from 'draft-js'
import ChevRight from '../Search-jobs/chevron-right 1.png'
import { jobTitlesArr, locoptions } from '../navbarArrays/navbarArrays'
import axios from 'axios'
import Alert from '../alerts/alert';
import Loader from '../loader/loader';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

const EmployerDashboard = () => {
  const [value, setValue] = useState('acp')
  const [createJobPost, setCreateJobPost] = useState(false)

  const [accessToken, setaccessToken] = useState('')
  const [jobs, setjobs] = useState([])

  const [info, setinfo] = useState({
    job_views: 0,
    job_clicks: 0,
    messages: 0,
  })

  // pagination
  const [nextLink, setnextLink] = useState(null)
  const [prevLink, setprevLink] = useState(null)
  const [paginationPages, setpaginationPages] = useState([])
  const [activePagination, setActivePagination] = useState(1)

  useEffect(() => {
    if (Cookies.get('access_token')) {
      setaccessToken(Cookies.get('access_token'))
    } else {
      window.location.href = '/register'
    }
  }, [])

  const getData = () => {
    setloader(true);
    axiosInstance
      .get('/district/get_jobs/', {
        params: {
          // access: accessToken,
          page: activePagination,
        },
      })
      .then((res) => {
        // console.log(res);
        setjobs(res.data.results)

        // setting links
        setnextLink(res.data.links.next)
        setprevLink(res.data.links.previous)
        setpaginationPages(res.data.links.list_of_pages)
        setloader(false);
      })
      .catch((err) => {
        console.log(err)
      })

    axiosInstance.get('/district/post_job/').then(res=>{
      // console.log(res.data.data);
      setinfo(res.data.data);
    }).catch(err=>{
      console.log(err.response);
    })
  }

  useEffect(() => {
      getData()
  }, [accessToken, activePagination])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const acp = (
    <div className='EmpDashCont'>
      <div className='activeStats'>
        <div className='cardStatsCont'>
          <Stats img={MessagesImg} stat={info.messages} statName='Messages' />
          <Stats img={EyesImg} stat={info.job_views} statName='Job Views' />
          <Stats img={ClicksImg} stat={info.job_clicks} statName='Clicked Apply' />
        </div>
        <button className='cardDraft' onClick={() => setCreateJobPost(true)}>
          Create job post
        </button>
      </div>
      {jobs.length ? jobs.map((item) => {
        return (
          <JobCard
            jobData={item}
            getData={() => {
              getData()
            }}
          />
          // <JobCard  title='High School English Teacher - Leave Replacement Teacher' school='Summit Public Schools' postedAgo='23 days ago' expire='6/20/2021' messages='150' views='150' applied='150' />
        )
      }) : <div>
        <div className='d-flex justify-content-center'>
          <img src = {NoJobs} />
        </div>
       <div className='d-flex justify-content-center fw-bold fs-2'>
        No Job
      </div> 
      </div> }

      <div className='paginationCOnt'>
        <div
          className={`paginationCircle ${!prevLink ? 'noDispPagi' : null}`}
          onClick={() => {
            setActivePagination(activePagination - 1)
            // localStorage.setItem('page', prevLink);
          }}
        >
          <img src={ChevRight} alt='' style={{ transform: 'rotate(180deg)' }} />
        </div>

        {paginationPages.map((item) => {
          return (
            <div
              className={`paginationCircle ${activePagination === item ? 'activePagi' : null}`}
              onClick={() => {
                setActivePagination(item)
                // localStorage.setItem('page', item);
              }}
            >
              {item}
            </div>
          )
        })}

        <div
          className={`paginationCircle ${!nextLink ? 'noDispPagi' : null}`}
          onClick={() => {
            setActivePagination(activePagination + 1)
            // localStorage.setItem('page', nextLink);
          }}
        >
          <img src={ChevRight} alt='' />
        </div>
      </div>
    </div>
  )

  const draft = (
    <div className='EmpDashCont'>
      <JobCard jobData={jobs[0]} draft />
    </div>
  )

  const renderSwitch = (param) => {
    switch (param) {
      case "acp":
          return acp;
      case "draft":
        return draft;
    }
  }

  //ALERT CODE:
  // const [alertComp, setAlert] = React.useState(false)
  // const [typeOfAlert, setType] = React.useState('')
  // const [descOfAlert, setDesc] = React.useState('')

  const [loader, setloader] = useState(false);

  return (
    <>
      {
        loader ? <Loader /> : null
      }
      <div style={{ background: '#ffffff' }}>
        <Helmet>
          <title>Your Jobs | Frame Change</title>
        </Helmet>
        <NavBar_emp />
        {/* ALERT
        {alertComp ? <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlert} /> : null} */}
        <Container style={{ marginTop: '10vh' }}>
          <div style={{ paddingTop: '2em' }}>
            <div style={{ width: '100%' }}>

                          {/* <Tabs
                              variant="scrollable"
                              scrollButtons
                              allowScrollButtonsMobile
                              value={value}
                              onChange={handleChange}
                              textColor="inherit"
                              TabIndicatorProps={{ style: { background: 'orange' } }}
                              aria-label="secondary tabs example"
                          >
                              <Tab style={{ textTransform: 'capitalize' }} value="acp" label="Active job posts" />
                              <Tab style={{ textTransform: 'capitalize' }} value="draft" label="Draft" />
                          </Tabs> */}
                      </div>
            {renderSwitch(value)}
            {/* <acp /> */}
          </div>
        </Container>
        <Modal
          open={createJobPost}
          onClose={() => setCreateJobPost(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div className='modalCont'>
            <JobPostForm getData={() => getData()} closeModal={() => setCreateJobPost(false)} />
          </div>
        </Modal>
      </div>
    </>
  )
}

export default EmployerDashboard

const JobCard = (props) => {
  const data = props.jobData
  const [editJobPost, seteditJobPost] = useState(false)
  const [accessToken, setaccessToken] = useState('')
  //ALERT CODE:
  const [alertComp, setAlert] = React.useState(false)
  const [typeOfAlert, setType] = React.useState('')
  const [descOfAlert, setDesc] = React.useState('')
  useEffect(() => {
    if (Cookies.get('access_token')) {
      setaccessToken(Cookies.get('access_token'))
    }
  }, [])

  const [openDeleteAccount, setOpenDeleteAccount] = React.useState(false)

  const handleClickOpenDeleteAccount = () => {
    setOpenDeleteAccount(true)
  }

  const handleCloseDeleteAccount = () => {
    setOpenDeleteAccount(false)
  }

  const handleDeletePost = () => {
    

    axiosInstance
      .delete('/district/post_job/', {
        params : {
          access: accessToken,
          id: props.jobData.id,
        }
      })
      .then((res) => {
        setAlert(true)
        setType('success')
        setDesc('Successfully Deleted')
        setOpenDeleteAccount(false);
        props.getData();
      })
      .catch((err) => {
        setAlert(true)
        setType('error')
        setDesc(err.toString())
        console.log(err)
      })
  }

  const calculateDayDifference = (expiryDate) => {
    const curr = new Date()
    const exp = new Date(expiryDate)
    const oneDay = 24 * 60 * 60 * 1000

    const diffDays = Math.round(Math.abs((exp - curr) / oneDay))

    return diffDays
  }

  const handlePreview = (id) => {
    window.location.href = `/job-detail/${id}`
  }

  return (
    <div className='EmpDashJobCard'>
      {/* ALERT */}
      {alertComp ? <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlert} /> : null}
      <div className='cardTitleCont'>
        <div className='cardTitleBlock'>
          <div className='cardTitle'>{data.job_title}</div>
          <div className='cardSchool'>{props.district}</div>
        </div>
        <div className='cardInfoBlock'>
          {props.draft ? (
            <button className='cardDraft'>Post</button>
          ) : (
            <div className='cardStatsCont'>
              <Stats img={MessagesImg} stat={0} statName='Messages' />
              <Stats img={EyesImg} stat={data.no_of_views} statName='Job Views' />
              <Stats img={ClicksImg} stat={data.no_of_clicks} statName='Clicked Apply' />
            </div>
          )}
        </div>
      </div>
      <div className='profileTagCont'>
        {data.shift_time ? <ProfileTags img={SmolBag} text={data.shift_time} /> : null}
        {data.phd_required ? <ProfileTags img={SmolBag} text='PHD Required' /> : null}
        {data.license_required ? <ProfileTags img={SmolBag} text='License Required' /> : null}
        {data.is_remote ? <ProfileTags img={SmolBag} text='Remote Job' /> : null}
      </div>
      <div className='cardBottomCont'>
        <div className='cardBottomInfo'>
          <div className='cardBottomPosted'>Posted: {calculateDayDifference(data.date_created)} days ago</div>
          <div className='cardBottomExpire'>Job Expires: {data.expiry_date}</div>
        </div>
        <div className='cardBottomC2a'>
          <div className='c2aIcon' onClick={() => setOpenDeleteAccount(true)}>
            <img src={Delete} alt='' />
            Delete
          </div>
          <div className='c2aIcon' onClick={() => seteditJobPost(true)}>
            <img src={Edit} alt='' />
            Edit
          </div>
          <div className='c2aIcon' onClick={() => handlePreview(data.id)}>
            <img src={Preview} alt='' />
            Preview
          </div>
        </div>
      </div>
      <Modal open={editJobPost} onClose={() => seteditJobPost(false)} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
        <div className='modalCont'>
          <JobPostForm getData={props.getData} jobData={data} closeModal={() => seteditJobPost(false)} />
        </div>
      </Modal>

      <Dialog open={openDeleteAccount} onClose={handleCloseDeleteAccount}>
        <DialogContent>
          <Grid container justifyContent='row' spacing={1}>
            <Grid item lg={2} md={3} sm={3} xs={3}>
              <img src={DeleteIcon} alt='Delete Icon' />
            </Grid>
            <Grid container direction='column' item lg={10} md={9} sm={9} xs={9}>
              <span style={{ fontWeight: '600', fontSize: '19px' }}>Delete this Post</span>

              <span style={{ fontSize: '14px', marginTop: '1em' }}>Are you sure you want to delete this post?</span>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingBottom: '1.5em' }}>
          <Button
            style={{ textTransform: 'none', border: '1px solid #808080', borderRadius: '18px', padding: '0.5em 1em', color: '#808080' }}
            onClick={handleCloseDeleteAccount}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeletePost}
            style={{
              textTransform: 'none',
              background: 'linear-gradient(275.34deg, #D80202 -10.41%, #D70000 -10.4%, #FF4545 102.29%)',
              borderRadius: '18px',
              padding: '0.5em 1em',
              color: '#fff',
            }}
            autoFocus
          >
            Delete Post
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const Stats = (props) => {
  return (
    <div className='statsBadge'>
      <img src={props.img} alt='' />
      <div>
        <div className='stat'>{props.stat}</div>
        <div className='statName'>{props.statName}</div>
      </div>
    </div>
  )
}

const JobPostForm = (props) => {
  const [selectedSkillOption, setSelectedSkillOption] = useState(null)
  const [accessToken, setaccessToken] = useState('')
  const [isEdit, setisEdit] = useState(false);
  const [shiftTypeArr, setshiftTypeArr] = useState([]);
  

  useEffect(() => {
    if (Cookies.get('access_token')) {
      setaccessToken(Cookies.get('access_token'))
    } else {
      window.location.href = '/register'
    }

    if (props.jobData) {
      if(props.jobData.shift_type.length){
        let shift_type_arr = [];
  
        try{
          props.jobData.shift_type.split(',').map(item => {
            shift_type_arr.push({label : item, value : item})
          })
        }catch{
          shift_type_arr = []
        }
  
        // setjobPostForm({...jobPostForm, shift_type : shift_type_arr})
        // props.jobData['shift_type'] = shift_type_arr
        setshiftTypeArr(shift_type_arr);
      }
      console.log(props.jobData);

      setjobPostForm(props.jobData)
           
      setisEdit(true)
    }
    

  }, [])

  const [salRange, setSalRange] = useState([35000, 75000])
  const Skills = [
    { value: 'Part Time', label: 'Part Time' },
    { value: 'Full Time', label: 'Full Time' },
  ]

  const yesNoArray = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ]

  const [textEditorData, setTextEditorData] = useState('')

  const [jobPostForm, setjobPostForm] = useState({
    job_title: '',
    shift_type: '',
    location: '',
    city : '',
    state : '',
    expiry_date: '',
    salary_range: '',
    salary_from: '',
    salary_to: '',
    is_remote: false,
    apply_url: '',
    description: '',
    phd_required: false,
    license_required: false,
    year_of_exp: 0,
    working_hours: 0,
  })
  //ALERT CODE:
  const [alertComp, setAlert] = React.useState(false)
  const [typeOfAlert, setType] = React.useState('')
  const [descOfAlert, setDesc] = React.useState('');
  const [errorHandlers, seterrorHandlers] = useState({
    job_title: false,
    state: false,
    location: false,
    expiryDate: false,
    description: false,
  });


  const handleJobPostSubmit = () => {

    if (!(jobPostForm.salary_from && jobPostForm.salary_to)) {
      setAlert(true)
      setType('warning')
      setDesc('Please Select the Salary Range')
      return
    }

    if (!(jobPostForm.job_title && jobPostForm.location && jobPostForm.state && jobPostForm.expiry_date && jobPostForm.description)) {
      
      const data = {
        job_title : jobPostForm.job_title.length === 0 ? true : false,
        location : jobPostForm.location.length === 0 ? true : false,
        state : jobPostForm.state.length === 0 ? true : false,
        expiryDate : jobPostForm.expiry_date.length === 0 ? true : false,
        description : jobPostForm.description.length === 0 ?  true : false,
      };
      seterrorHandlers(data)
      
      return
    }
    


    axiosInstance
      .post('/district/post_job/', jobPostForm)
      .then((res) => {
        setAlert(true)
        setType('success')
        setDesc('SuccessFully Posted')
        props.getData()
        props.closeModal()
      })
      .catch((err) => {
        console.log(err.response);
        setAlert(true)
        setType('error')
        setDesc(err.toString())
      })
  }

  const handleEditPost = () => {


    if (!(jobPostForm.salary_from && jobPostForm.salary_to)) {
      setAlert(true)
      setType('warning')
      setDesc('Please Select the Salary Range')
      return
    }

    if (!(jobPostForm.job_title && jobPostForm.location && jobPostForm.state && jobPostForm.expiry_date && jobPostForm.description)) {
      
      const data = {
        job_title : jobPostForm.job_title.length === 0 ? true : false,
        location : jobPostForm.location.length === 0 ? true : false,
        state : jobPostForm.state.length === 0 ? true : false,
        expiryDate : jobPostForm.expiry_date.length === 0 ? true : false,
        description : jobPostForm.description.length === 0 ?  true : false,
      };
      seterrorHandlers(data)
      
      return
    }
    

    axiosInstance
      .patch('/district/post_job/', jobPostForm)
      .then((res) => {
        setAlert(true)
        setType('success')
        setDesc('SuccessFully Updated')
        //Changed:
        //props.closeModal()
        props.getData()
        props.closeModal()
      })
      .catch((err) => {
        console.log(err)
      })
  }


  const myTheme = createTheme({
    // Set up your custom MUI theme here
  })

  return (
    <div>
      
      <div style={{ marginRight: '2.5em', marginLeft: '2.5em', display: 'flex', flexDirection: 'column' }}>
        {/* ALERT */}
      {alertComp ? <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlert} /> : null}
      
        <Typography style={{ fontSize: '19px', fontWeight: '600' }}>{isEdit ? 'Edit Job Post' : 'Create a job post'}</Typography>
        <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim</p>
        <br />
        <form>
          <Grid container item spacing={3}>
            <Grid item lg={6}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className='label'>Job Title</span>
              <div >
                <div>
                  <Select
                    // isMulti
                    value={{ value: jobPostForm.job_title, label: jobPostForm.job_title }}
                    onChange={(selectedSkillOption) => {
                      setjobPostForm({ ...jobPostForm, job_title: selectedSkillOption.value })
                    }}
                    options={jobTitlesArr}
                    
                    className={errorHandlers.job_title ? "incorrectInput" : null}
                  />
                </div>
              </div>
              </div>
            </Grid>
            <Grid item lg={6}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className='label'>Job type</span>
                <Select
                  isMulti
                  value={shiftTypeArr}
                  onChange={(selectedSkillOption) => {
                    let arr = '';
                    selectedSkillOption.map((item, index) => {
                      if (index === selectedSkillOption.length - 1) {
                        arr += item.value
                      } else {
                        arr += item.value + ', '
                      }
                    })
                    setjobPostForm({ ...jobPostForm, shift_type: arr })
                    setshiftTypeArr(selectedSkillOption)
                  }}
                  options={Skills}
                />
              </div>
            </Grid>
            <Grid item lg={6}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className='label'>City</span>
               
                  <TextField name='startDate' error={errorHandlers.location} value={jobPostForm.location} onChange={e=>setjobPostForm({...jobPostForm, location : e.target.value})}
                                      size="small" type="text" placeholder='E.g. New York, Chicago' variant='outlined' />             
              </div>
            </Grid>

            <Grid item lg={6}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className='label'>State</span>
                {/* <TextField name='startDate' value={jobPostForm.location} onChange={e=>setjobPostForm({...jobPostForm, location : e.target.value})}
                                    size="small" type="text" placeholder='E.g. San francisco, California' variant='outlined' /> */}

                <Select
                  // isMulti
                  value={{ value: jobPostForm.state, label: jobPostForm.state }}
                  onChange={(selectedSkillOption) => {
                    setjobPostForm({ ...jobPostForm, state: selectedSkillOption.value })
                  }}
                  options={locoptions}
                  
                  className={errorHandlers.state ? "incorrectInput" : null}
                />
              </div>
            </Grid>
            <Grid item lg={6}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className='label'>Expiry date</span>
                <TextField
                  name='endDate'
                  value={jobPostForm.expiry_date}
                  onChange={(e) => setjobPostForm({ ...jobPostForm, expiry_date: e.target.value })}
                  size='small'
                  type='date'
                  placeholder='Select date'
                  variant='outlined'
                  error ={errorHandlers.expiryDate}
                />
              </div>
            </Grid>
            <Grid item lg={12}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className='label'>Salary range(Annual)</span>
                <Box sx={{ width: 350 }}>
                  <Slider
                    value={salRange}
                    onChange={(event, newValue) => {
                      setSalRange(newValue)
                      setjobPostForm({
                        ...jobPostForm,
                        salary_to: newValue[1],
                        salary_from: newValue[0],
                        salary_range: `${newValue[0]} - ${newValue[1]}`,
                      })
                      // setjobPostForm({...jobPostForm, salary_from : newValue[0]});
                    }}
                    valueLabelDisplay='auto'
                    min={10000}
                    max={100000}
                    step={1000}
                  />
                </Box>
              </div>
            </Grid>
            <Grid item lg={5}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img src={DollarIcon} />
                      </InputAdornment>
                    ),
                  }}
                  size='small'
                  type='text'
                  value={salRange[0]}
                  variant='outlined'
                />
              </div>
            </Grid>
            <Grid style={{ display: 'flex', alignItems: 'center' }}> - </Grid>
            <Grid item lg={5}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img src={DollarIcon} />
                      </InputAdornment>
                    ),
                  }}
                  size='small'
                  type='text'
                  value={salRange[1]}
                  variant='outlined'
                />
              </div>
            </Grid>
            <Grid item lg={12}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography style={{ fontSize: '14px', color: '#706F6F', marginTop: '1em', marginRight: '1.5em' }}>Remote Work?</Typography>

                <FormControlLabel
                  control={<IOSSwitch sx={{ m: 1 }} />}
                  checked={jobPostForm.is_remote}
                  onChange={(e) => {
                    setjobPostForm({ ...jobPostForm, is_remote: e.target.checked })
                  }}
                />
              </div>
            </Grid>
            {/* <Grid item lg={12} xs={12}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>


                                <Checkbox
                                    style={{ padding: '0', color: '#0DAC69' }}
                                    name='isWorking'
                                />
                                <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Candidate preferences</span>
                            </div>
            </Grid> */}
            {/* <Grid item lg={6}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>


                                <span className='label'>PHD Required</span>
                                <Select
                                    isMulti
                                    value={jobPostForm.phd_required}
                                    onChange={(selectedSkillOption) => { setjobPostForm({...jobPostForm, phd_required : selectedSkillOption}) }}
                                    options={yesNoArray}
                                />
                            </div>
            </Grid> */}
            <Grid item lg={6}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography style={{ fontSize: '14px', color: '#706F6F', marginTop: '1em', marginRight: '1.5em' }}>PHD Required?</Typography>

                <FormControlLabel
                  control={<IOSSwitch sx={{ m: 1 }} />}
                  checked={jobPostForm.phd_required}
                  onChange={(e) => {
                    setjobPostForm({ ...jobPostForm, phd_required: e.target.checked })
                  }}
                />
              </div>
            </Grid>
            <Grid item lg={6}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography style={{ fontSize: '14px', color: '#706F6F', marginTop: '1em', marginRight: '1.5em' }}>License Required?</Typography>

                <FormControlLabel
                  control={<IOSSwitch sx={{ m: 1 }} />}
                  checked={jobPostForm.license_required}
                  onChange={(e) => {
                    setjobPostForm({ ...jobPostForm, license_required: e.target.checked })
                  }}
                />
              </div>
            </Grid>
            {/* <Grid item lg={6}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>


                                <span className='label'>License Required</span>
                                <Select
                                    isMulti
                                    value={selectedSkillOption}
                                    onChange={(selectedSkillOption) => { setjobPostForm({...jobPostForm, license_required : selectedSkillOption}) }}
                                    options={yesNoArray}
                                />
                            </div>
            </Grid> */}
            {/* <div className='label' style={ { color: '#0F172A', marginLeft: '10px' } }>Select the communication region</div>
            <Grid item lg={12} xs={12}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>


                                <Checkbox
                                    style={{ padding: '0', color: '#0DAC69' }}
                                    name='isWorking'
                                />
                                <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Brooklyn</span>
                            </div>
            </Grid>
            <Grid item lg={12} xs={12}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>


                                <Checkbox
                                    style={{ padding: '0', color: '#0DAC69' }}
                                    name='isWorking'
                                />
                                <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Manhattan</span>
                            </div>
            </Grid> */}
            <Grid item lg={12}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className='label'>Job post link</span>
                <TextField
                  onChange={(e) => setjobPostForm({ ...jobPostForm, apply_url: e.target.value })}
                  value={jobPostForm.apply_url}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img src={LinkIcon} />
                      </InputAdornment>
                    ),
                  }}
                  size='small'
                  type='text'
                  placeholder='Past your job post link here (e.g. https://xyz/job_post.com)'
                  variant='outlined'
                />
              </div>
            </Grid>

            <Grid item lg={12}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className='label'>Description</span>
                <TextField
                  size='small'
                  value={jobPostForm.description}
                  onChange={(e) => setjobPostForm({ ...jobPostForm, description: e.target.value })}
                  name='jobtitle'
                  placeholder='Enter Job Description'
                  variant='outlined'
                  error={errorHandlers.description}
                />
              </div>
            </Grid>

            <ThemeProvider theme={myTheme}>
              {/* <MUIRichTextEditor
                label="Type something here..."
                // defaultValue="Hello World"
                // defaultValue={JSON.stringify(convertToRaw(jobPostForm.job_title))}
                // value={jobPostForm.description}
                // onChange={e=>console.log(e.getCurr}
                onChange={e=>setjobPostForm({...jobPostForm, description: e.getCurrentContent().getPlainText()})}
                inlineToolbar={true}
            controls={ [
                  "title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo"
            ] }
              /> */}
            </ThemeProvider>
          </Grid>
        </form>
      </div>
      <div className='btnProfileModalCont'>
        {/* {isEdit ? null : (
          <button className='btnContCancelBtn' onClick={props.closeModal}>
            Save as Draft
          </button>
        )} */}
        <button className='btnContSaveBtn' onClick={isEdit ? handleEditPost : handleJobPostSubmit}>
          {isEdit ? 'Update' : 'Post'}
        </button>
      </div>
    </div>
  )
}

const IOSSwitch = styled((props) => <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  margin: '10px',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#10B981',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))
