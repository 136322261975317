import React, { useCallback, useState, useEffect } from 'react';
import { Box, Grid, TextField, Typography, Button, Input } from '@material-ui/core';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MailIcon from '@material-ui/icons/Mail';
import { styled, alpha } from '@mui/material/styles';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Divider from '@mui/material/Divider';
import Logo from './logo.svg';
import Profile from './profile.svg';
import Cookies from 'js-cookie';
import Modal from '@mui/material/Modal'
import MaintainenceGuy from '../../images/undraw_maintenance_re_59vn 1.png'

import srch from './searchicon.svg';
//import cross from './cross.png';
import arrow from './arrow.svg';
import handleLogout from '../../Logout/logout';


function DrawerComponent() {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [loginModal, setLoginModal] = useState(false)

    const [NotianchorEl, setNotiAnchorEl] = useState(null);
    const [profile, setprofile] = useState({
        "name": '',
        "email": '',
        "phone": '',
        'profile_pic': null
    })

    useEffect(() => {
        // if(!(localStorage.getItem('name') && localStorage.getItem('email') && localStorage.getItem('phone')))

        // window.addEventListener('storage', ()=>{
        //   console.log('hello');
        setprofile({
            "name": (localStorage.getItem('name') === '' || localStorage.getItem('name') === 'null') ? '' : localStorage.getItem('name'),
            "email": (localStorage.getItem('email') === '' || localStorage.getItem('email') === 'null') ? '' : localStorage.getItem('email'),
            "phone": (localStorage.getItem('phone') === '' || localStorage.getItem('phone') === 'null') ? '' : localStorage.getItem('phone'),
            "profile_pic": (localStorage.getItem('profile_pic') === '' || localStorage.getItem('profile_pic') === 'null') ? '' : localStorage.getItem('profile_pic')
        })
        // })
        // console.log(profile);
    }, [])





    const Notiopen = Boolean(NotianchorEl);
    const NotihandleClick = (event) => {
        setNotiAnchorEl(event.currentTarget);
    };
    const NotihandleClose = () => {
        setNotiAnchorEl(null);
    };


    const handleSandwichClick = () => {
        const sandwichBtn = document.querySelector(".navbar-toggler-icon");
        sandwichBtn.classList.toggle("crossBtn");
        console.log("Clicked")
    }


    return (
        <>
            <Navbar fixed="top" collapseOnSelect expand="lg" style={{ background: '#FFF' }} variant="light">
                <Container>
                    <Navbar.Brand href="/dashboard"><img src={Logo} alt="logo" /></Navbar.Brand>

                    <Nav.Link href="#memes" onClick={() => setLoginModal(true)}>
                        <Badge badgeContent={1} color="primary" style={{ color: 'black' }}>
                            <ChatBubbleOutlineIcon />
                        </Badge>
                    </Nav.Link>

                    <Nav.Link onClick={NotihandleClick} id="noti-menu">
                        <div>
                            <Badge badgeContent={4} color="primary" aria-owns={anchorEl ? 'simple-menu' : undefined}
                                aria-haspopup="true" style={{ color: 'black' }} >
                                <NotificationsNoneIcon />
                            </Badge>
                        </div>

                    </Nav.Link>
                    <StyledMenu
                        id="noti-menu"
                        anchorEl={NotianchorEl}
                        open={Notiopen}
                        onClose={NotihandleClose}
                    >
                        <div className='notificationDD'>
                            <div className="notiDDHeader">
                                <div className="notiDDTitle">Notifications</div>
                                <div className="notiDDsubTitle">Mark all as read</div>
                            </div>
                            <div className="noiCont" onClick={() => setLoginModal(true)}>
                                <Notification img='//placedog.net/40/40' name='Nelly Miller' time='2' desc='Lorem ipsum dolor sit amet, consecteurr adipiscing elit.' />
                                <Notification img='//placedog.net/40/40' name='Nelly Miller' time='2' desc='Lorem ipsum dolor sit amet, consecteurr adipiscing elit.' />
                                <Notification img='//placedog.net/40/40' name='Nelly Miller' time='2' desc='Lorem ipsum dolor sit amet, consecteurr adipiscing elit.' />
                            </div>
                            <div className="notiC2a">View All</div>
                        </div>
                    </StyledMenu>




                    <Navbar.Toggle className="navbar-toggler" aria-controls="responsive-navbar-nav" onClick={handleSandwichClick}/>
                    <Navbar.Collapse id="responsive-navbar-nav"  style={{margin:'10px', width:'92%', maxWidth:'100%', maxHeight:'35rem', overflowY:'scroll', overflowX:'hidden'}}>
                        {/* <Nav>
                            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                <Grid item gap={3} xs={1} style={{  maxWidth:'2rem' }}>
                                    <div style={{ margin:'25% 0% 25% 0%', display:'inline-block', verticalAlign:'middle', background: '#EAEEF1', borderRadius: '50%', height:'30px', width:'30px'}}>
                                        <img src={cross} alt="Close" style={{display:'inline-block', margin:'25%', verticalAlign:'middle'}} />
                                    </div>

                                </Grid>
                                <Grid item style={{width:'100%', marginLeft:'10px'}}>
                                    <div className="searchBoxSmall" style={styles.searchBoxSmall}>
                                        <Grid container direction='row' spacing={1}>
                                            <Grid item xs={'auto'}>
                                                <img src={srch} alt="Search by Title" />
                                            </Grid>
                                            <Grid item xs={'auto'}>
                                                <input type="text" placeholder="Search by Job Title" style={styles.srchField} />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Box>

                        </Nav> */}


                        <Nav >
                            <div className='profileDiv' >
                                <Box style={{ display: 'flex', flexDirection: 'row', width:'100%' }}>
                                    <img height='49' width='49' src={
                                        (localStorage.getItem('profile_pic') === '' || localStorage.getItem('profile_pic') === 'null') ?
                                            `https://ui-avatars.com/api/?name=${profile.name}?rounded=true` : localStorage.getItem('profile_pic')
                                    } style={{ borderRadius: '50%' }} />
                                    <Box style={{width:'100%'}}>
                                        <Typography style={{ fontWeight: '600', fontSize: '18px', marginLeft: '10px', maxWidth:'80%' }}>{
                                            !(localStorage.getItem('name') === '' || localStorage.getItem('name') === 'null') ? localStorage.getItem('name') : null
                                        }</Typography>
                                        { <Typography style={{ fontSize: '14px', color: '#878E99', wordWrap:'break-word', maxWidth:'80%', marginLeft: '10px' }}>{
                                        (localStorage.getItem('email') === '' || localStorage.getItem('email' === 'null')) ?
                                            null : localStorage.getItem('email')
                                    }</Typography> }
                                        {/* <Typography style={{ fontSize: '14px', color: '#878E99', marginLeft: '10px' }}>{
                                            (localStorage.getItem('phone') === '' || localStorage.getItem('phone' === 'null')) ?
                                                null : localStorage.getItem('phone')
                                        }</Typography> */}

                                    </Box>
                                </Box>
                            </div>

                            <Nav className="me-auto" style={{width:'100%', marginTop:'10px'}}>

                                <Nav.Link href="/search-jobs" style={{ fontWeight: '500', color: 'black', display: 'flex', flexDirection: 'row', justifyContent:'space-between', padding:'10px' }}>Find jobs <img src={arrow} alt="Arrow"/></Nav.Link> 
                                <Nav.Link href="/saved-jobs" style={{ fontWeight: '500', color: 'black', display: 'flex', flexDirection: 'row', justifyContent:'space-between', padding:'10px'  }}>Saved jobs<img src={arrow} alt="Arrow"/></Nav.Link>
                                <Nav.Link onClick={() => setLoginModal(true)} style={{ fontWeight: '500', color: 'black', display: 'flex', flexDirection: 'row', justifyContent:'space-between', padding:'10px'  }}>Jobs alerts<img src={arrow} alt="Arrow"/></Nav.Link>
                                {/* <Nav.Link href="#plans" onClick={() => setLoginModal(true)} style={{ fontWeight: '500', color: 'black', display: 'flex', flexDirection: 'row', justifyContent:'space-between', padding:'10px'  }}>Subscription plans<img src={arrow} alt="Arrow"/></Nav.Link> */}
                            </Nav>

                            <Divider />

                            <Nav className="me-auto" style={{width:'100%'}}>
                                <Nav.Link href="/profile" style={{ fontWeight: '500', color: 'black', display: 'flex', flexDirection: 'row', justifyContent:'space-between', padding:'10px'  }}>Profile<img src={arrow} alt="Arrow"/></Nav.Link>
                                <Nav.Link href="/setting/jobPrefernces" style={{ fontWeight: '500', color: 'black', display: 'flex', flexDirection: 'row', justifyContent:'space-between', padding:'10px'  }}>Job preference<img src={arrow} alt="Arrow"/></Nav.Link>
                                <Nav.Link href="/setting/attachments" style={{ fontWeight: '500', color: 'black',  display: 'flex', flexDirection: 'row', justifyContent:'space-between', padding:'10px'  }}>Upload Documents(Resume)<img src={arrow} alt="Arrow"/></Nav.Link>
                                <Nav.Link href="/setting/contactPrefernces" style={{ fontWeight: '500', color: 'black', display: 'flex', flexDirection: 'row', justifyContent:'space-between', padding:'10px'  }}>Contact Preference<img src={arrow} alt="Arrow"/></Nav.Link>
                                <Nav.Link href="/setting/account" style={{ fontWeight: '500', color: 'black', display: 'flex', flexDirection: 'row', justifyContent:'space-between', padding:'10px'   }}>Account<img src={arrow} alt="Arrow"/></Nav.Link>
                                <Nav.Link href="/setting/helpCenter" style={{ fontWeight: '500', color: 'black', display: 'flex', flexDirection: 'row', justifyContent:'space-between', padding:'10px'   }}>Help Center<img src={arrow} alt="Arrow"/></Nav.Link>
                                <Divider />
                                <Nav.Link onClick={handleLogout} style={{ fontWeight: '500', color: 'black', display: 'flex', flexDirection: 'row', justifyContent:'space-between', padding:'10px'   }}>Logout<img src={arrow} alt="Arrow"/></Nav.Link >
                            </Nav>
                            
                            
                            {/* <StyledMenu
                                id="profile-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <Box style={{ display: 'flex', flexDirection: 'row', margin: '1em' }}>
                                    <img height='49' width='49' src={
                                        (localStorage.getItem('profile_pic') === '' || localStorage.getItem('profile_pic') === 'null') ?
                                            `https://ui-avatars.com/api/?name=${profile.name}?rounded=true` : localStorage.getItem('profile_pic')
                                    } style={{ margin: '5px', borderRadius: '50%' }} />
                                    <Box>
                                        <Typography style={{ fontWeight: '600', fontSize: '18px' }}>{
                                            !(localStorage.getItem('name') === '' || localStorage.getItem('name') === 'null') ? localStorage.getItem('name') : null
                                        }</Typography>
                                        <Typography style={{ fontSize: '14px', color: '#878E99' }}>{
                                            (localStorage.getItem('email') === '' || localStorage.getItem('email' === 'null')) ?
                                                null : localStorage.getItem('email')
                                        }</Typography>
                                        <Typography style={{ fontSize: '14px', color: '#878E99' }}>{
                                            (localStorage.getItem('phone') === '' || localStorage.getItem('phone' === 'null')) ?
                                                null : localStorage.getItem('phone')
                                        }</Typography>

                                    </Box>

                                </Box>
                                <Divider />
                                <MenuItem onClick={() => window.location.href = '/profile'}>Profile</MenuItem>
                                <MenuItem onClick={() => window.location.href = '/setting/jobPrefernces'}>Job preference</MenuItem>
                                <MenuItem onClick={() => window.location.href = '/setting/attachments'}>Upload Documents(Resume)</MenuItem>
                                <MenuItem onClick={() => window.location.href = '/setting/contactPrefernces'}>Contact Preference</MenuItem>
                                <MenuItem onClick={() => window.location.href = '/setting/account'}>Account</MenuItem>
                                <MenuItem onClick={() => window.location.href = '/setting/helpCenter'}>Help Center</MenuItem>
                                <Divider />
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </StyledMenu> */}
                        </Nav>


                    </Navbar.Collapse>
                    
                </Container>
                <Modal
                    open={loginModal}
                    onClose={() => { setLoginModal(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className='modalCont' style={{ width: 'fit-content' }}>
                        <img src={MaintainenceGuy} alt="" />
                        <div className='loginModalHead'>This action is not available yet...</div>
                        <div className='loginModalSubHead'>We are woking on it, please come back later.</div>
                        <button className='loginModalBtn' onClick={() => setLoginModal(false)}>Okay</button>
                    </div>
                </Modal>
            </Navbar>
        </>
    );
}
export default DrawerComponent;



const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(5),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}))

const Notification = (props) => {
    return (
        <div className="notification">
            <img src={props.img} alt="" className="notiImg" />
            <div className="notiBody">
                <div className="notiBodyHead">
                    <div className="notiName">{props.name}</div>
                    <div className="notiTime">{props.time} min ago</div>
                </div>
                <div className="notiDesc">{props.desc}</div>
            </div>
        </div>
    )
}

const styles = {

    searchBoxSmall: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        background: '#fff',
        border: '1px solid #BEBEBE',
        borderRadius: '25px',
        paddingLeft: '1em',
        paddingRight: '1em',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    srchField: {
        border: "none",
        borderColor: "transparent",
        outline: "none",
        width: "100%",
    },
}
