import { CircularProgress, FormHelperText, Grid, IconButton, Input, InputAdornment, Snackbar, Typography } from '@material-ui/core';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect, useState, useRef } from 'react';
import Logo from '../login/logo.svg';
import TextField from '@material-ui/core/TextField';
import ReCAPTCHA from "react-google-recaptcha";
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { FcGoogle } from 'react-icons/fc';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { SiMicrosoftoutlook } from 'react-icons/si';
import { AiFillApple, AiFillFacebook, AiFillLinkedin } from 'react-icons/ai';
import { useHistory } from "react-router-dom";
import './style.css';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import GirlImg from '../login/girl.svg';
import Tick from '../login/tick.svg';
import Outlook from './outlook.svg';
import axiosInstance from '../../axios';
import { Alert } from '@mui/material';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Helmet } from "react-helmet";
import Modal from '@mui/material/Modal'
import MaintainenceGuy from '../../images/undraw_maintenance_re_59vn 1.png';

import { useLinkedIn } from 'react-linkedin-login-oauth2';
import linkedin from 'react-linkedin-login-oauth2';
import loaderJson from '../../assets/loader.json';
import mainLoaderJson from '../../assets/mainLoader.json';
import Lottie from 'react-lottie';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Alert1 from '../alerts/alert';


const theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',

        ].join(','),
    },
});




function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}
const Register = (props) => {
    const [loginModal, setLoginModal] = useState(false)

    const history = useHistory();


    const [values1, setValues1] = React.useState({
        password: '',
        showPassword: false,
    });

    const handleChange1 = (prop) => (event) => {
        setpasswordError(false);
        setpasswordErrorText('');
        setValues1({ ...values1, [prop]: event.target.value });
        setSignupData({ ...SignupData, [prop]: event.target.value });
    };
    const handleClickShowPassword1 = () => {
        setValues1({ ...values1, showPassword: !values1.showPassword });
    };


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const { height, width } = useWindowDimensions();

    // const [captcha, setCaptcha] = useState(false);
    // const [firstName, setFirstName] = useState('');
    // const [lastName, setLastName] = useState('');
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const [SignupData, setSignupData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',

    })

    const [loader, setloader] = React.useState(false);

    const [ReCaptchaText, setReCaptchaText] = React.useState("");
    const [captchaValue, setcaptchaValue]   = React.useState("");


    const [lastnameError, setlastnameError] = React.useState(false);
    const [lastnameErrorText, setlastnameErrorText] = React.useState("");

    const [firstnameError, setfirstnameError] = React.useState(false);
    const [firstnameErrorText, setfirstnameErrorText] = React.useState("");

    const [passwordError, setpasswordError] = React.useState(false);
    const [passwordErrorText, setpasswordErrorText] = React.useState("");

    const [emailError, setemailError] = React.useState(false);
    const [emailErrorText, setemailErrorText] = React.useState("");

    const convertArrayToString = (arr) => {
        let str = '';
        if (arr != null) {
            arr.map(a => {
                str = str + a + '\n';
            });
        }
        return str;
    }

    // verifying captcha
    const recaptchaRef = useRef(null);

    function ValidateEmail(mail) 
    {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
        {   
            return (true)
        }
            return (false)
    }


    const handleSubmit = ()=>{
        if (captcha) {
            if (validateData()) {
                const data = {
                    username: SignupData.email,
                    password: SignupData.password,
                    email: SignupData.email,
                    first_name: SignupData.firstname,
                    last_name: SignupData.lastname,
                    captcha : captchaValue
                }

                console.log(data);
                setloader(true);
                axiosInstance.post('/auth/register/', data).then((res) => {

                    console.log(res);
                    const data = res.data;
                    // console.log(data);
                    
                    localStorage.setItem('access_token', data.access);
                    localStorage.setItem('refresh_token', data.refresh);

                    Cookies.set('access_token', data.access);
                    Cookies.set('refresh_token', data.refresh);

                    axiosInstance.get('auth/get_user_profile/',{
                        headers: {
                            Authorization: `Bearer ${Cookies.get('access_token')}`
                        }
                    }).then(res=>{
                        const data = res.data.msg;
                        localStorage.setItem('name', data.name);
                        localStorage.setItem('email', data.email);
                        localStorage.setItem('phone', data.phone);
                        localStorage.setItem('profile_pic', data.profile_pic);
                        localStorage.setItem('candidate_id', data.candidate_id);
                        history.push('/onboarding');
                    })
                    .catch(err=>{
                        console.log(err.data);
                        // alert(err.data);
                    })


                }).catch((err) => {
                    setloader(false);
                    const x = err.response.data.error;
                    if(x === 'Invalid Recaptcha'){
                        setReCaptchaText("Invalid Recaptcha");
                        return;
                    }

                    setpasswordError(convertArrayToString(x.password));
                    setemailError(convertArrayToString(x.username));

                    setemailErrorText(convertArrayToString(x.username));
                    setpasswordErrorText(convertArrayToString(x.password));
                });

            }

        }
        else {
            setReCaptchaText("Please complete the reCAPTCHA")
        }
    }


    const ValidateSingleField = (data) => {
        if (data == "")
            return true;
        return false;

    }

    const validateData = () => {


        setlastnameError(ValidateSingleField(SignupData.lastname));
        ValidateSingleField(SignupData.lastname) ? setlastnameErrorText('Last Name cannot be blank') : setlastnameErrorText('');

        setfirstnameError(ValidateSingleField(SignupData.firstname));
        ValidateSingleField(SignupData.firstname) ? setfirstnameErrorText('First Name cannot be blank') : setfirstnameErrorText('');


        setpasswordError(ValidateSingleField(SignupData.password));
        ValidateSingleField(SignupData.password) ? setpasswordErrorText('Password cannot be blank') : setpasswordErrorText('');

        setemailError(ValidateSingleField(SignupData.email));
        ValidateSingleField(SignupData.email) ? setemailErrorText('Email cannot be blank') : setemailErrorText('');


        if (!ValidateSingleField(SignupData.password) && !ValidateSingleField(SignupData.email) && !ValidateSingleField(SignupData.lastname) && !ValidateSingleField(SignupData.firstname)) {


            return true;


        }
        return false;

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    const [open, setOpen] = React.useState(true);

    const [captcha, setCaptcha] = useState(false);

    
    const [socialAuthLoader, setsocialAuthLoader] = useState(false);
    // facebook login
    const facebookLogin = () => {
        function init() {

            window.fbAsyncInit = function() {
                window.FB.init({
                    appId      : '328723999219498',
                    cookie     : true,
                    xfbml      : true,
                });
                
                window.FB.AppEvents.logPageView();   
                
            };
        }

        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        function fbLogin() {
            window.FB.login(function(response) {
                if(response.authResponse){
                    fbAfterLogin();
                }
            })
        }

        function fbAfterLogin() {
            window.FB.getLoginStatus(function(response) {
                if(response.status === 'connected') {
                    window.FB.api('/me', function(response) {
                        console.log(response.accessToken);
                    })
                }
            })
        }

        fbLogin();
    }

    const facebookAuth = (auth_token) => {
        setsocialAuthLoader(true);
        const data = {
            auth_token : auth_token
        }
        axiosInstance.post('auth/facebook/', data)
        .then(res=>{
            setsocialAuthLoader(false);
            
            localStorage.setItem('access_token', data.access);
            localStorage.setItem('refresh_token', data.refresh);

            Cookies.set('access_token', res.data.access);
            Cookies.set('refresh_token', res.data.refresh);
            Cookies.set('user', res.data.user);

            axiosInstance.get('/auth/get_user_profile/',{
                headers: {
                    Authorization: `Bearer ${Cookies.get('access_token')}`
                }
            }).then(res1=>{
                const data = res1.data.msg;
                localStorage.setItem('name', data.name);
                localStorage.setItem('email', data.email);
                localStorage.setItem('phone', data.phone);
                localStorage.setItem('profile_pic', data.profile_pic);
                localStorage.setItem('candidate_id', data.candidate_id);
                if(Cookies.get('user') === 'DistrictAdmin'){
                    history.push('/employer/dashboard');
                }
                else{
                    history.push('/dashboard');
                }
            })
            .catch(err=>{
                setsocialAuthLoader(false);
                alert('Something Went Wrong');
                console.log(err);
            })


        }).catch(err=>{
            setsocialAuthLoader(false);
            setAlertFunc('warning', err.response.data.detail);
            // alert(err.response.data.detail);
        })
    }

    // LinkedIn Login
    const { linkedInLogin } = useLinkedIn({
        clientId: '78t0pi37fpcv9t',
        scope : "r_emailaddress r_liteprofile",
        state : 'someRandom',
        redirectUri: 'https://fc.unisight.in/auth/linkedin/',
        onSuccess: (code) => {
          console.log(code);
          linkedinAuth(code);
        },
        onError: (error) => {
          console.log(error);
        },
    });

    const linkedinAuth = (auth_token) => {
        setsocialAuthLoader(true);
        const data = {
            auth_token : auth_token
        }
        axiosInstance.post('auth/linkedin/', data)
        .then(res=>{
            setsocialAuthLoader(false);
            console.log(res);
            
            localStorage.setItem('access_token', data.access);
            localStorage.setItem('refresh_token', data.refresh);

            Cookies.set('access_token', res.data.access);
            Cookies.set('refresh_token', res.data.refresh);
            Cookies.set('user', res.data.user);

            axiosInstance.get('/auth/get_user_profile/',{
                headers: {
                    Authorization: `Bearer ${Cookies.get('access_token')}`
                }
            }).then(res1=>{
                const data = res1.data.msg;
                console.log(data);
                localStorage.setItem('name', data.name);
                localStorage.setItem('email', data.email);
                localStorage.setItem('phone', data.phone);
                localStorage.setItem('profile_pic', data.profile_pic);
                localStorage.setItem('candidate_id', data.candidate_id);
                if(Cookies.get('user') === 'DistrictAdmin'){
                    history.push('/employer/dashboard');
                }
                else{
                    history.push('/dashboard');
                }
            })
            .catch(err=>{
                setsocialAuthLoader(false);
                alert('Something Went Wrong');
                console.log(err);
            })


        }).catch(err=>{
            setsocialAuthLoader(false);
            // alert(err.response.data.detail);
            setAlertFunc('warning', err.response.data.detail);
        })

    }

    // google login
    const googleAuth = (response) => {
        const auth_token = response.credential;
        setsocialAuthLoader(true);
        const data = {
            auth_token : auth_token
        }
        axiosInstance.post('auth/google/', data)
        .then(res=>{
            setsocialAuthLoader(false);
            console.log(res);
            
            localStorage.setItem('access_token', data.access);
            localStorage.setItem('refresh_token', data.refresh);
            
            Cookies.set('access_token', res.data.access);
            Cookies.set('refresh_token', res.data.refresh);
            Cookies.set('user', res.data.user);

            axiosInstance.get('/auth/get_user_profile/', {
                headers: {
                    Authorization: `Bearer ${Cookies.get('access_token')}`
                }
            }).then(res1=>{
                const data = res1.data.msg;
                console.log(data);
                localStorage.setItem('name', data.name);
                localStorage.setItem('email', data.email);
                localStorage.setItem('phone', data.phone);
                localStorage.setItem('profile_pic', data.profile_pic);
                localStorage.setItem('candidate_id', data.candidate_id);
                if(Cookies.get('user') === 'DistrictAdmin'){
                    history.push('/employer/dashboard');
                }
                else{
                    history.push('/dashboard');
                }
            })
            .catch(err=>{
                setsocialAuthLoader(false);
                alert('Something Went Wrong');
                console.log(err);
            })


        }).catch(err=>{
            setsocialAuthLoader(false);
            // alert(err.response.data.detail);
            setAlertFunc('warning', err.response.data.detail);
            // console.log(err.response);
        })
    }

    useEffect(() => {
        window.google.accounts.id.initialize({
          client_id : '860859219621-6oa5vude3ko11e778rlqoeouk7blfjef.apps.googleusercontent.com'  ,
          callback : googleAuth,
          context : 'use'
        });

        window.google.accounts.id.renderButton(
            document.getElementById('signInGoogle'),
            {theme : "outline", size : "large"}
        );
        
    })  

    const [alert, setAlert] = React.useState(false);

    //ALERT CODE:
    const [alertComp, setAlert1] = React.useState(false)
    const [typeOfAlert, setType1] = React.useState('')
    const [descOfAlert, setDesc1] = React.useState('');

    const setAlertFunc = (type, desc) => {
        setAlert1(true);
        setType1(type);
        setDesc1(desc);
    }


    return (
        <ThemeProvider theme={ theme }>
            {/* ALERT */}
            {alertComp ? (
                <Alert1 type={typeOfAlert} desc={descOfAlert} alertState={setAlert} />
            ) : null}
            {
                socialAuthLoader ? 
                <div className='' style={{height : '120vh', display : 'grid', placeContent : 'center', width : '100vw', backgroundColor : 'rgb(255,255,255,0.6)', position : 'fixed', zIndex: '100'}}>
                    {/* <iframe src="https://embed.lottiefiles.com/animation/66433"></iframe> */}
                    <Lottie 
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: loaderJson,
                            rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                            }
                        }}
                        height={200}
                        width={200}
                    />
                </div> : null
            }
            <Helmet>
                <title>Frame Change - Register</title>
            </Helmet>
            <div style={{ position: 'relative', zIndex: '1' }}>
                <Grid container style={{}}>
                    {width > 959 ? <Grid style={{ background: '#F1F1F1' }} item lg={8} md={7} sm={12} xs={12}>
                        <div style={{ position: 'sticky', top: '0' }}>


                            <img style={{ margin: '3% 5%' }} src={Logo} alt='logo' />
                            <Grid item container style={{}}>
                                <Grid item lg={6} md={5} >
                                    <div style={{ marginLeft: '13%' }}>
                                        <Typography style={{ fontWeight: '600', fontSize: '33px' }}>Find your next teaching job. Right now.</Typography>
                                        <br />
                                        <Typography variant="body1" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor</Typography>
                                        <br />
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <img style={{ marginRight: '10px' }} src={Tick} alt='tick' />
                                            <Typography variant="body1" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam fringilla urna, porttitor</Typography>
                                        </div>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <img style={{ marginRight: '10px' }} src={Tick} alt='tick' />
                                            <Typography variant="body1" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam fringilla urna, porttitor</Typography>
                                        </div>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <img style={{ marginRight: '10px' }} src={Tick} alt='tick' />
                                            <Typography variant="body1" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam fringilla urna, porttitor</Typography>
                                        </div>
                                        <br />

                                    </div>

                                </Grid>
                                <Grid item lg={6} md={7} >
                                    <div style={{ position: 'relative', height: '80vh' }}>
                                        <img style={{ width: '100%', position: 'absolute', bottom: '0em' }} src={GirlImg} alt='girl' />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid> : <></>
                    }

                    <Grid style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', overflow: 'auto' }} item lg={4} md={5} sm={12} xs={12}>
                        {/* <div style={{ height: '54px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span className='text'>Are you an Employer? <span style={{ fontWeight: '600' }}><u>Register here</u></span></span>
                </div>
                <hr style={{ border: '1px solid ##F1F1F1', color: '##F1F1F1', width: '100%' }} /> */}
                        {width > 959 ? <></> :
                            <div style={{ background: '#FBF5F3', width: '100%', height: '8vh' }}>
                                <img style={{ paddingTop: '5px', width: '90px', position: 'absolute', top: '0', left: '0' }} src={Logo} alt='logo' />
                            </div>}
                        <Grid style={{ marginTop: '5%', padding: '5% 15%' }} container direction='column' >
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                <Typography className='text' style={{ fontSize: '25px', fontWeight: '600' }}>Create an account</Typography>
                                <br />
                                <span className='label'>Already have an account?<span style={{ fontWeight: '700', color: '#0072C6', cursor: 'pointer' }} onClick={() => history.push("/login", { from: "Register" })}> Sign in</span></span>
                            </div>
                            <br />
                            <span className='label'>First Name</span>
                            <TextField placeholder='First name' variant='outlined' error={firstnameError} helperText={firstnameErrorText} value={SignupData.firstname}
                                onChange={(event) => {
                                    setfirstnameError(false);
                                    setfirstnameErrorText('');
                                    setSignupData({ ...SignupData, firstname: event.target.value });
                                }} />
                            <br />
                            <span className='label'>Last Name</span>
                            <TextField className='inputField' placeholder='Last name' variant='outlined' error={lastnameError} helperText={lastnameErrorText} value={SignupData.lastname}
                                onChange={(event) => {
                                    setlastnameError(false);
                                    setlastnameErrorText('');
                                    setSignupData({ ...SignupData, lastname: event.target.value })
                                }} />
                            <br />
                            <span className='label'>Email</span>
                            <TextField className='inputField' type="email" error={emailError} helperText={emailErrorText} value={SignupData.email}
                                    onChange={(event) => {
                                    setemailError(false);
                                    setSignupData({ ...SignupData, email: event.target.value })
                                    setemailErrorText(ValidateEmail(event.target.value)? '' : 'Please enter a valid email address, example: you@yourdomain.com');
                                }} placeholder='name@mail.com' variant='outlined'
                                FormHelperTextProps={{
                                   style : {color : 'red'}
                                }}
                            />
                            <br />
                            <span className='label'>Password</span>
                            <OutlinedInput
                                className='inputField'
                                placeholder='6 characters, 1 capital letter'
                                variant='outlined'
                                error={passwordError}
                                type={values1.showPassword ? 'text' : 'password'}
                                value={values1.password}
                                onChange={handleChange1('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword1}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {values1.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText style={{ color: 'red' }}>{passwordErrorText}</FormHelperText>
                            
                               
                            
                            <Grid container justifyContent='space-between' style={{ marginTop: '10px' }}>
                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: '20px 0' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Checkbox
                                            style={{ padding: '0', color: '#0DAC69' }}
                                            defaultChecked

                                        />
                                        <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>I agree to the <span style={{ color: "#1A78DF" }}>terms and condition</span> and <span style={{ color: "#1A78DF" }}>privacy policy</span></span>

                                    </div>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: '20px 0' }}>
                                    <ReCAPTCHA
                                        sitekey="6LdrTn8cAAAAADxkhpfTvTp_nVulm9D_8BH6_sBJ"
                                        stoken="6LdrTn8cAAAAADsdX_YanKa76kXsJ3VG4qowSt7o"
                                        ref={recaptchaRef}
                                        onExpired={() => {
                                            setCaptcha(false);
                                        }}
                                        onChange={(value) => {
                                            setReCaptchaText("")
                                            setCaptcha(true);
                                            setcaptchaValue(value);
                                        }}
                                    />
                                    <FormHelperText style={{ color: 'red' }}>{ReCaptchaText}</FormHelperText>
                                </Grid>

                            </Grid>
                            <br />
                            {loader ? <div style={{ display: 'flex', justifyContent: 'center' }} >
                                <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                            </div> : <Button style={{ textTransform: 'none', fontSize: "18px", fontFamily: 'Montserrat', background: '#EB881B', color: '#FFF', fontWeight: '600', borderRadius: '25px', padding: '10px' }}
                                variant="contained"
                                onClick={handleSubmit}
                            >Create account</Button>}



                            <br />
                            <br />

                            <div className="separator">or continue with</div>

                            <br />
                            <Grid container justifyContent='space-evenly'>
                                {/* <AiFillFacebook onClick={() => setLoginModal(true)} style={{ width: '3em', height: '3em', background: '#EDEDED', padding: '9px', borderRadius: '50%', color: '#1976D2', margin: '1px', cursor: 'pointer' }} /> */}
                                <div className='col-12 d-flex justify-content-center py-3' id='signInGoogle' src={Outlook}><img src={Outlook} /></div>
                                <FacebookLogin
                                    appId="328723999219498"
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    callback={(res)=>{facebookAuth(res.accessToken)}}
                                    render = {
                                        renderProps => (
                                            <AiFillFacebook onClick={renderProps.onClick} style={{ width: '3em', height: '3em', background: '#EDEDED', padding: '9px', borderRadius: '50%', color: '#1976D2', margin: '1px', cursor: 'pointer' }} />
                                            )
                                        }
                                />
                                <AiFillLinkedin  onClick={linkedInLogin} style={{ width: '3em', height: '3em', background: '#EDEDED', padding: '9px', borderRadius: '50%', color: '#0077B5', margin: '1px', cursor: 'pointer' }} />
                                {/* <AiFillLinkedin onClick={() => setLoginModal(true)} style={{ width: '3em', height: '3em', background: '#EDEDED', padding: '9px', borderRadius: '50%', color: '#0077B5', margin: '1px', cursor: 'pointer' }} /> */}
                                {/* <FcGoogle id='' onClick={()=>{
                                    console.log("Hello!");
                                    window.google.accounts.id.prompt();
                                    
                                }} style={{ width: '3em', height: '3em', background: '#EDEDED', padding: '9px', borderRadius: '50%', margin: '1px', cursor: 'pointer' }} /> */}

                                <AiFillApple onClick={() => setLoginModal(true)} style={{ width: '3em', height: '3em', background: '#EDEDED', padding: '9px', borderRadius: '50%', margin: '1px', cursor: 'pointer' }} />

                                {/* <SiMicrosoftoutlook style={{ width: '32px', height: '32px', background: '#EDEDED', padding: '7px', borderRadius: '50%', color: '#0072C6' }} /> */}
                                <img onClick={() => setLoginModal(true)} src={Outlook} style={{ width: '3em', height: '3em', background: '#EDEDED', padding: '9px', borderRadius: '50%', margin: '1px', cursor: 'pointer' }} alt="outlook" />
                                
                            </Grid>
                        </Grid>


                    </Grid>


                </Grid>
            </div>
            <Modal
        open={loginModal}
        onClose={() => {setLoginModal(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"  
      >
                <div className='modalCont' style={{width: 'fit-content'}}>
                    <img src={ MaintainenceGuy } alt="" />
                    <div className='loginModalHead'>This action is not available yet...</div>
                    <div className='loginModalSubHead'>We are woking on it, please come back later.</div>
                    <button className='loginModalBtn' onClick={() => setLoginModal(false)}>Okay</button>
                </div>
      </Modal>
        </ThemeProvider>
    );
}

export default Register;
