import React, { useState, useEffect } from 'react'
import { Button, Checkbox, Container, FormControl, FormControlLabel, Grid, IconButton, NativeSelect, Switch, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import NavbarEmpWSrch from '../NavBar_emp_searchBar/index.js'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import srch from '../emp_settings/searchicon.svg';
import { DataGrid } from "@material-ui/data-grid";
import axiosInstance from '../../axios';
import Loader from '../loader/loader';
import moment from 'moment';
import './activitylog.css'
import {activityLogStatement, activityType} from './utils';
import MUIDataTable from "mui-datatables";

const columns = ["Date", "Name", "Type", "Description"];



const ActivityLog = () => {
      const PendingColumns = [
        {
            field: 'timestamp',
            headerName: 'Date',
            description: 'Date',
            sortable: true,
            width: 190,
            renderCell : (e) => {
              return <div>{moment(e.value).format('hh:mm A, Do MMM YY')}</div>
            }
        },
        {
            field: 'contents.name',
            headerName: 'Admin Name',
            description: 'Full Name',
            sortable: true,
          width: 190,
          renderCell: ( e ) => {
            return (
              <div>
                <div className='tableNameName'>{e.row.contents.admin.name}</div>
              </div>
              )
            }
        },
        // {
        //     field: 'email',
        //     headerName: 'EMAIL',
        //     description: 'User Email Address',
        //     sortable: true,
        //     width: 290,
        // },
        {
            field: 'type',
            headerName: 'Type',
            description: 'Type',
            sortable: true,
            width: 120,
            renderCell: ( e ) => {
              return (
                <div>
                  <div className='tableType'>{activityType(e.row)}</div>
                </div>
              )
            }
        },
        {
            field: 'contents',
            headerName: 'Description',
            description: 'Description',
            sortable: false,
          width: 440,
            renderCell: ( e ) => {
              return(
                <div>
                  <div className='tableNameName'>{activityLogStatement(e.row)}</div>
                </div>
              )
            }
        },
    ];

    const columns = [
      {
       name: "timestamp",
       label: "Date",
       options: {
        filter: false,
        sort: true,
        customBodyRender : (e) => {
          return <div>{moment(e).format('hh:mm A, Do MMM YY')}</div>
        }
       }
      },
      {
       name: "user_name",
       label: "Name",
       options: {
        filter: true,
        sort: true,
       }
      },
      {
       name: "type",
       label: "Type",
       options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <div>
              <div className='tableType'>{activityType(value)}</div>
            </div>
          );
        }
       }
      },
      {
        name: "contents",
        label: "Description",
        options: {

          filter: false,
          sort: false,
          display: false,
        }
      },
      {
        name : 'statement',
        label: 'Description',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return (
              <div>
                <div className='tableNameName'>{activityLogStatement(tableMeta.rowData[3], tableMeta.rowData[2])}</div>
              </div>
            );
          }
        }, 
      }
     ];


    const PendingRows = [
        {
            id: 1,
            timestamp: 'Jan 1, 2021 at 01:49 pm',
            name: "Savannah Nguyen",
            position: 'Recruiter',
            email: "savannah.nguyen@gmail.com",
            type: "Job Post",
            post: 'High School English Teacher - Leave...'
        },
        {
            id: 2,
            timestamp: 'Jan 1, 2021 at 01:49 pm',
            name: "Savannah Nguyen",
            position: 'Recruiter',
            email: "savannah.nguyen@gmail.com",
            type: "Job Post",
            msgTo: 'Robert Fox',
            regarding: 'Math Teacher'
        },
        {
            id: 3,
            timestamp: 'Jan 1, 2021 at 01:49 pm',
            name: "Savannah Nguyen",
            position: 'Recruiter',
            email: "savannah.nguyen@gmail.com",
            type: "Job Post",
            post: 'High School English Teacher - Leave...'
        },
        {
            id: 4,
            timestamp: 'Jan 1, 2021 at 01:49 pm',
            name: "Savannah Nguyen",
            position: 'Recruiter',
            email: "savannah.nguyen@gmail.com",
            type: "Job Post",
            msgTo: 'Robert Fox',
            regarding: 'Math Teacher'
        }
    ];

    const [activityLog, setactivityLog] = useState([]);
    const [loader, setloader] = useState(false);

    
    const getData = () => {
      setloader(true);
      axiosInstance.get('/district/activity_log')
      .then(res => {
        setactivityLog(res.data.activity_log);
        setloader(false);
      }).catch(err => {
        console.log(err);
        setloader(false);
      })
    }

    useEffect(() => {
      getData();
    }, [])

    let activityRows = [];
    useEffect(() => {
      activityLog.map(item => {
        // console.log(item);
        const data = {
          id : item.id,
          date : item.timestamp,
          
        }
      })
    }, [activityLog])

  return (
    <div>
      <NavbarEmpWSrch />
      {
        loader ? <Loader /> : null
      }
      <Container style={{marginTop: '10vh'}}>
        <button className="activityLogBkBtn" onClick={() => window.location.href='/employer/settings/teamPage'}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0846 7H2.91797" stroke="#033462" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.0013 11.0827L2.91797 6.99935L7.0013 2.91602" stroke="#033462" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Back
        </button>
        <h3>Activity Logs</h3>
        <Grid container direction='row'>
            {/* <Grid item xs={'auto'}>
                <div className='teamHeader'>
              <SearchBar />
              <select name="" id="">
              <option>Type</option>
              </select>
              <select name="" id="">
              <option>All Roles</option>
              </select>
              <select name="" id="">
              <option>All Statuses </option>
              </select>
                    <div className='clearfilters' style={styles.noFilter}>Clear Filters</div>
                </div>
            </Grid> */}
        </Grid>
        <div style={{height: '523px'}}>
          {/* <DataGrid
              rows={activityLog}
              columns={PendingColumns}
              pageSize={8}
              rowsPerPageOptions={[8]}
              disableSelectionOnClick
              options={{
                search : true
              }}
          /> */}

          <MUIDataTable
            data={activityLog}
            columns={columns}
            options = {{
                selectableRows: false,
                download: false,
                viewColumns: false,
                print: false,
                search : false,
                searchAlwaysOpen: true,
                searchPlaceholder: 'Search',
              }
            }
          />
        </div>
      </Container>
    </div>
  )
}

export default ActivityLog

export const SearchBar = ( { placeholder, noSearch, br70}) => {
        return(
            <React.Fragment>
                <div className="searchBox" style={br70 ? styles.searchBoxBigBr70 : styles.searchBoxBig}>
                    <Grid container direction='row' spacing={1}>
                        <Grid item xs={'auto'}>
                            <Grid container direction='row' spacing={2}>
                                { noSearch ? null : (
                                    <Grid item xs={ 'auto' }>
                                        <img src={srch} alt="Search by Title"/>
                                    </Grid>
                                ) }
                                <Grid item xs={'true'}>
                                    <div style={styles.hiddenLabel} aria-hidden="true">Search</div>
                                    <input type="text" placeholder={placeholder || "Search"} style={styles.srchField} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }

    const styles = {
    searchBoxBig: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        background: '#fff',
        border: '1px solid #BEBEBE',
        borderRadius: '5px',
        paddingLeft: '4vh',
        paddingRight: '4vh',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    searchBoxBigBr70: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        background: '#fff',
        border: '1px solid #BEBEBE',
        borderRadius: '70px',
        paddingLeft: '4vh',
        paddingRight: '4vh',
        paddingTop: '10px',
        paddingBottom: '10px',
        marginLeft: '24px',
        marginRight: '24px',
        marginBottom: '14px',
    },
    filterButton: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#4A4A4A',
        background: '#fff',
        border: '1px solid #BEBEBE',
        borderRadius: '5px',
        paddingLeft: '4vh',
        paddingRight: '4vh',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    roleButton: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#4A4A4A',
        background: '#F1F5F9',
        borderRadius: '50px',
        paddingLeft: '7px',
        paddingRight: '7px',
        paddingTop: '5px',
        paddingBottom: '5px',
    },
    noFilter: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#F98608',
        background: '#fff',
        paddingLeft: '4vh',
        paddingRight: '4vh',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    noFilterBlack: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#000',
        background: '#fff',
        paddingLeft: '4vh',
        paddingRight: '4vh',
        paddingTop: '15px',
        paddingBottom: '15px',
    },
    srchField: {
        border: "none",
        borderColor: "transparent",
        outline: "none",
        width: "100%",
    },
    hiddenLabel: {
        height: 0,
        visibility: "hidden",
    }
}
