import React, {useEffect, useState} from 'react';
import NavBar_emp from '../NavBar_emp'
import {Helmet} from "react-helmet";
import Phone from '../emp_settings/Phone.png';
import Mail from '../emp_settings/Mail.png'
import Web from '../emp_settings/Web.png';
import Cookies from 'js-cookie';
import axiosInstance from '../../axios';




const EmployerPreview = () => {

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(
            getWindowDimensions()
        );
    
        useEffect(() => {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }
    
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }, []);
    
        return windowDimensions;
    }
    
    const [selectedJob, setselectedJob] = useState({
        about: null,
        address: null,
        avg_graduation_rate: null,
        avg_teacher_salary: null,
        city: null,
        district_name: null,
        employee_benefits: null,
        exp_per_student: null,
        free_or_reduced_lunch: null,
        highest_grade: null,
        id: null,
        institution_type: null,
        instruction: null,
        latitude: null,
        longitude: null,
        lowest_grade: null,
        median_home_value: null,
        median_household_income: null,
        median_rent: null,
        no_of_schools: null,
        no_of_students: null,
        no_of_teachers: null,
        other: null,
        percent_proficient_maths: null,
        percent_proficient_reading: null,
        phone: null,
        salary_info: null,
        shape_area: null,
        shape_length: null,
        state: null,
        student_to_teacher_ratio: null,
        support_services: null,
        teachers_in_1_2_year: null,
        total_employee_benefits: null,
        total_salaries: null,
        website: null,
        zipcode: null
    })

    // const [accessToken, setaccessToken] = useState('');
    // useEffect(() => {
    //     if ( Cookies.get( 'access_token' ) ) {
    //         setaccessToken( Cookies.get( 'access_token' ) );
    //     } else {
    //         window.location.href = '/register';
    //     }
    // }, [])

    useEffect(()=>{
        getData();
    }, [])

    const getData = () => {
        axiosInstance.get('/district/profile/').then(res=>{
            console.log(res.data);
            setselectedJob(res.data);
        }).catch(err=>{
            console.log(err);
        })
    }

  return (
      <>
        <Helmet>
                <title>Frame Change</title>
        </Helmet>
        <NavBar_emp />

        <React.Fragment>
                    <div style={{marginTop: '100px', marginBottom: '50px', padding: '1rem'}}>
                        <div style={ { display: 'inline-flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' } }>
                            <h4 style={{maxWidth: '520px'}}>{selectedJob.district_name}</h4>
                        </div>
                        <p style={{maxWidth: '520px',  marginTop: '10px'}}>
                            {selectedJob.address ? `${selectedJob.address}, ` : null} {selectedJob.state}
                        </p>
                        <div style={ { display: 'inline-flex', gap: '15px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' } }>
                            <a href={selectedJob.website} target='_' style={ { display: 'inline-flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' } }>
                                <img src={Web} /> {selectedJob.website}
                            </a>
                            <a style={ { display: 'inline-flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' } }>
                                <img src={Mail} /> {selectedJob.email}
                            </a>
                            <a href={`tel:${selectedJob.phone}`} style={ { display: 'inline-flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' } }>
                                <img src={Phone} /> {selectedJob.phone}
                            </a>
                        </div>
                        <div className='fourGridCol' style={{marginTop: '100px'}}>
                            <div className='firstColHead'>Academics</div>
                            <div className='colData'>
                                <h4>{selectedJob.percent_proficient_reading}%</h4>
                                Percent Proficient - Reading
                            </div>
                            <div className='colData'>
                                <h4>{selectedJob.percent_proficient_maths}%</h4>
                                Percent Proficient - Math
                            </div>
                            <div className='colData'>
                                <h4>{selectedJob.avg_graduation_rate}%</h4>
                                Average Graduation Rate
                            </div>
                            <div className='colData'>
                                <h4>{selectedJob.highest_grade}</h4>
                                Highest Grade Available
                            </div>
                            <div className='colData'>
                                <h4>{selectedJob.lowest_grade}</h4>
                                Lowest Grade Available
                            </div>
                        </div>
                        <div className='fourGridCol' style={{marginTop: '50px'}}>
                            <div className='firstColHead'>Students</div>
                            <div className='colData'>
                                <h4>{selectedJob.no_of_students}</h4>
                                Students
                            </div>
                            <div className='colData'>
                                <h4>{selectedJob.free_or_reduced_lunch}%</h4>
                                Free or Reduced Lunch
                            </div>
                        </div>
                        <div className='fourGridCol' style={{marginTop: '50px'}}>
                            <div className='firstColHead'>Teachers</div>
                            <div className='colData'>
                                <h4>{selectedJob.no_of_teachers}<sub style={{fontSize: '12px'}}>National {selectedJob.student_to_teacher_ratio}</sub></h4>
                                Student-Teacher Ratio
                            </div>
                            <div className='colData'>
                                <h4>{selectedJob.teachers_in_1_2_year}%</h4>
                                Teachers in First/Second Year
                            </div>
                        </div>
                        <div className='graphColDiv' style={{marginTop: '50px'}}>
                            <div className='firstColHead'>Finances</div>
                            <div className='colData'>
                                <h4>${selectedJob.median_household_income} <sub style={{fontSize: '12px'}}>/ student</sub><p style={{fontSize: '12px'}}>National $12,239</p></h4>
                                Median Household Income
                            </div>
                            <div className="bardGraphContDiv">
                                <div className="bardGraphCont">
                                    <div className="barColor" style={{width: `${selectedJob.instruction ? selectedJob.instruction : 0}%`}} />
                                    <div className="barLabel">Instruction</div>
                                    <div className="barPercent">{selectedJob.instruction}%</div>
                                </div>
                                <div className="bardGraphCont">
                                    <div className="barColor" style={{width: `${selectedJob.support_services ? selectedJob.support_services : 0}%`}} />
                                    <div className="barLabel">Support Services</div>
                                    <div className="barPercent">{selectedJob.support_services}%</div>
                                </div>
                                <div className="bardGraphCont">
                                    <div className="barColor" style={{width: `${selectedJob.other ? selectedJob.other : 0}%`}} />
                                    <div className="barLabel">Other</div>
                                    <div className="barPercent">{selectedJob.other}%</div>
                                </div>
                            </div>
                        </div>
                        <div className='fourGridCol' style={{marginTop: '50px'}}>
                            <div className='firstColHead'>LIVING IN THE AREA</div>
                            <div className='colData'>
                                <h4>${selectedJob.median_household_income} <p style={{fontSize: '12px'}}>National $62,438</p></h4>
                                Median Household Income
                            </div>
                            <div className='colData'>
                                <h4>${selectedJob.median_rent} <p style={{fontSize: '12px'}}>National $1,062</p></h4>
                                Median Rent
                            </div>
                            <div className='colData'>
                                <h4>${selectedJob.median_home_value} <p style={{fontSize: '12px'}}>National $217,500</p></h4>
                                Median Home Value
                            </div>
                        </div>
                    </div>
                </React.Fragment>;
      </>
  )
}

export default EmployerPreview;