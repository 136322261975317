import { Button, Checkbox, Container, FormControl, FormControlLabel, Grid, IconButton, NativeSelect, Switch, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import EditImg from './edit.svg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from './deleteIcon.svg';
import Modal from '@mui/material/Modal'
import NavBar_emp from '../NavBar_emp';
import Footer from '../Footer'
import {Helmet} from "react-helmet";
import BluePen from './blueedit.svg'; 
import BlueBin from './bluebin.svg'; 
import srch from './searchicon.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DataGrid } from "@material-ui/data-grid";
import EP from './Frame 6780.png'
import Doup from './Frame 6783.png'
import Del from './Frame 6781.png'
import Edit from './Frame 678.png'
import Arrow from './Icon.png'
import Phone from './Phone.png'
import Mail from './Mail.png'
import Web from './Web.png';
import axiosInstance from '../../axios';
import Cookies from 'js-cookie';
import Accept from './accept.png'
import Reject from './reject.png'
import MobScreen from './Frame 6970.png'
import {useHistory} from 'react-router-dom'
import './style.css';
import axios from 'axios';
import Alert from '../alerts/alert';
import ReactCrop from 'react-image-crop';
import Loader from '../loader/loader';
import handleLogout from '../../Logout/logout';

const styles = {
    searchBoxBig: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        background: '#fff',
        border: '1px solid #BEBEBE',
        borderRadius: '5px',
        paddingLeft: '10px',
        paddingRight: '4vh',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    searchBoxBigBr70: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        background: '#fff',
        border: '1px solid #BEBEBE',
        borderRadius: '70px',
        paddingLeft: '10px',
        paddingRight: '4vh',
        paddingTop: '10px',
        paddingBottom: '10px',
        marginLeft: '24px',
        marginRight: '24px',
        marginBottom: '14px',
    },
    filterButton: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#4A4A4A',
        background: '#fff',
        border: '1px solid #BEBEBE',
        borderRadius: '5px',
        paddingLeft: '4vh',
        paddingRight: '4vh',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    roleButton: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#4A4A4A',
        background: '#F1F5F9',
        borderRadius: '50px',
        paddingLeft: '7px',
        paddingRight: '7px',
        paddingTop: '5px',
        paddingBottom: '5px',
    },
    noFilter: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#F98608',
        background: '#fff',
        paddingLeft: '4vh',
        paddingRight: '4vh',
        paddingTop: '10px',
        paddingBottom: '10px',
        cursor: 'pointer',
    },
    noFilterBlack: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#000',
        background: '#fff',
        paddingLeft: '4vh',
        paddingRight: '4vh',
        paddingTop: '15px',
        paddingBottom: '15px',
        cursor: 'pointer',
    },
    srchField: {
        border: "none",
        borderColor: "transparent",
        outline: "none",
        width: "100%",
    },
    hiddenLabel: {
        height: 0,
        visibility: "hidden",
    }
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    margin: '10px',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#10B981',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

function EmpSetting(){

    //ALERT CODE:
    const [alertComp, setAlert] = React.useState(false);
    const [typeOfAlert, setType] = React.useState("");
    const [descOfAlert, setDesc] = React.useState("");

    const alertFunc = (type, desc) => {
        setType(type);
        setDesc(desc);
        setAlert(true);
    }

    const [value, setValue] = React.useState("userRoles");
        useEffect(() => {
        let route = window.location.pathname
        let reouteBrkDown = route.split( '/' )
            if ( reouteBrkDown[3] ) {
                setValue( reouteBrkDown[3] );
            }
    }, [])

    const [accessToken, setaccessToken] = useState('');
    const [roles, setRoles] = useState( [] )
    const [regions, setRegions] = useState([])

const history = useHistory();
    useEffect(() => {
        if ( Cookies.get( 'access_token' ) ) {
            setaccessToken( Cookies.get( 'access_token' ) );
        } else {
            window.location.href = '/register';
        }
    }, [])

    useEffect(()=>{
        if(accessToken !== ''){
            getData();
            getProfile();
            getRoles()
        }
    }, [accessToken])

    const [districtProfile, setDistrictProfile] = useState()

    const getProfile = async () => {
        setloader(true);
        axiosInstance.get('/district/profile/').then(res=>{
            setDistrictProfile(res.data);
            setDistrictWebsite(res.data.website)
            setDistrictAbout(res.data.about)
            setDistrictPhnumber(res.data.phone)
            setloader(false);
            console.log(res.data)
        }).catch(err=>{
            console.log(err);
            setAlert(true);
            setType("error");
            setDesc(err.toString())
            setloader(false);
        })
    }

    const getData = async () => {
        setloader(true);
        axiosInstance.get('/district/account_settings').then(res=>{
            // console.log(res.data.data);
            setaccountSetting(res.data.data);
            setmodalEdit(res.data.data);
            console.log(accountSetting);
            setloader(false);
        }).catch(err=>{
            console.log(err);
            setAlert(true);
            setType("error");
            setDesc(err.toString());
            setloader(false);
        })
    }

    const handleChange = (event, newValue) => {
        setValue( newValue );
        history.push(`/employer/settings/${newValue}`)
    };
    const [accountSetting, setaccountSetting] = useState({
        firstName : '',
        lastName : '',
        enail : '',
        phone : '',
        address : '',
        city : '',
        state : '',
        zipcode : ''
    });

    // const [firstNameEdit, setfirstNameEdit] = useState('');
    // const [lastNameEdit, setlastNameEdit] = useState('');
    // const [emailEdit, setemailEdit] = useState('');
    // const [phoneEdit, setphoneEdit] = useState('');
    // const [streetEdit, setstreetEdit] = useState('');
    // const [cityEdit, setcityEdit] = useState('');
    // const [stateEdit, setstateEdit] = useState('');
    // const [zipcodeEdit, setzipCodeEdit] = useState('');

    const [modalEdit, setmodalEdit] = useState({
        firstName : '',
        lastName : '',
        email : '',
        phone : '',
        address : '',
        city : '',
        state : '',
        zipcode : ''
    });

  

    const handleEditForm = async () => {
        const data = {
            // access : accessToken,
            firstName : modalEdit.firstName,
            lastName : modalEdit.lastName,
            email : modalEdit.email,
            phone : modalEdit.phone,
            address : modalEdit.address,
            city : modalEdit.city,
            state : modalEdit.state,
            zipcode : modalEdit.zipcode
        }

        axiosInstance.post('/district/account_settings/', data)
        .then(res=>{
            setAlert(true);
            setType("success");
            setDesc('Successfully Updated');
            getData().then(res=>{
                handleCloseEditAccountInfo();
            })

        }).catch(err=>{
            setAlert(true);
            setType("error");
            setDesc(err.toString());
            console.log(err.toString());
        })
    }

    
    const [changePassword, setchangePassword] = useState({
        "current_password" : '',
        "new_password" : '',
        "confirm_new_password" : ''
    });

    const [teamTab, setteamTab] = useState("pending");

    const handleTeamChange = (event, newValue) => {
        setteamTab(newValue);
    };

    const comparePassword = (pass1, pass2) => {
        if(pass1 === pass2)
            return true;
        return false;
    }

    const changePasswordInput = async (e) => {
        // console.log(changePassword);
        const targetName = e.target.name;
        const targetValue = e.target.value;

        setchangePassword({...changePassword, [targetName] : targetValue})

        // console.log(changePassword);
       
    }

    const handleChngePasswordForm = async () => {
        console.log(changePassword);
        const {current_password, new_password, confirm_new_password} = changePassword;
        const samePassword = await comparePassword(new_password, confirm_new_password);
        if(!(current_password && new_password && confirm_new_password)){
            setAlert(true);
            setType("warning");
            setDesc('Please fill all the inputs');
            return;
        }
        if(!samePassword){
            setAlert(true);
            setType("warning");
            setDesc('Passwords are not Matching');
            return;
        }
        const data = {
            // 'access' : accessToken,
            'current_password' : current_password,
            'new_password' : new_password
        }
        axiosInstance.post('/auth/change_password/', data)
        .then(data=>{
            // console.log(data);
            setAlert(true);
            setType("success");
            setDesc('Password Changed Successfully');
            setchangePassword({
                "current_password" : '',
                "new_password" : '',
                "confirm_new_password" : ''
            });
            handleCloseEditPassword();
        })
        .catch(data=>{
            console.log(data);
            setAlert(true);
            setType("warning");
            setDesc("Incorrect Current Password! Try Again");
        })
    }

    const [openEditAccountInfo, setOpenEditAccountInfo] = React.useState(false);

    const handleClickOpenEditAccountInfo = () => {
        setOpenEditAccountInfo(true);
    };

    const handleCloseEditAccountInfo = () => {
        setOpenEditAccountInfo(false);
    };

    const [openEditPassword, setOpenEditPassword] = React.useState(false);

    const handleClickOpenEditPassword = () => {
        setOpenEditPassword(true);
    };

    const handleCloseEditPassword = () => {
        setOpenEditPassword(false);
    };

    const [openDeleteAccount, setOpenDeleteAccount] = React.useState(false);

    const handleClickOpenDeleteAccount = () => {
        setOpenDeleteAccount(true);
    };

    const handleCloseDeleteAccount = () => {
        setOpenDeleteAccount(false);
    };

    const handleDeleteButton = () => {
        const data = {
            'access' : accessToken
        }
        axiosInstance.post('/auth/delete_account/', data)
        .then(()=>{
            setAlert(true);
            setType("success");
            setDesc('Successfully Account Deleted');
            handleLogout();
        })
        .catch(data=>{
            console.log(data);
        })
    }

    const {width, height} = useWindowDimensions();

    const [contactPreferences, setcontactPreferences] = useState({
        "send_notifs_via_email" : false,
        "send_notifs_viapush" : false
    })

    const handleContactPreference = () => {
        console.log(contactPreferences, "helllo");
    }

    const doNothing = (param, event) => {
        event.stopPropagation();
      };
      


    const ContactPreference =
        <Grid container justifyContent='center' direction='column' alignItems='center' style={{ background: '#F5F7FA', padding: '5% 0' }}>
            <Grid container direction='column' item lg={7} md={10} sm={11} xs={11} style={{ borderRadius: '10px', border: '2px solid #D0DBE6', background: '#FFF', margin: '1em 0', padding: '2em' }}>
                <Grid container direction='row' justifyContent="space-between">
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container direction='column'>
                            <span style={{ fontSize: '23px', fontWeight: '600', marginBottom: '8px' }}>Job Seeker Messages</span>

                            <span style={{ color: '#636363', marginBottom: '8px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</span>
                        </Grid>
                    </Grid>
                    <Grid item lg={1}>
                        <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                            label="ON"
                        />
                    </Grid>
                </Grid>
                <hr style={{ width: '100%', border: '1px solid #D0DBE6 ', margin: '1em 0' }} />
                <br />
                <Grid container item lg={12} style={{ margin: '1em 0' }} spacing={1}>
                    <Grid container direction='column' item lg={5} md={6}>
                        <span style={{ fontSize: '16px', fontWeight: '600', marginBottom: '8px' }}>Notifications</span>
                        <span style={{ fontSize: '16px', color: '#9C9C9C', marginBottom: '8px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</span>

                    </Grid>
                    <Grid container justifyContent={width > 959 ? 'flex-end' : 'flex-start'} item lg={6} md={6}>
                        <FormControlLabel
                            label="Email"
                            style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                            control={<Checkbox 
                                checked = {contactPreferences['send_notifs_via_email']}
                                onChange={e=>{
                                    setcontactPreferences({...contactPreferences, ['send_notifs_via_email'] : e.target.checked });
                                    console.log(contactPreferences['send_notifs_via_email'], e.target.checked);
                                }}
                                style={{ color: '#0AA55E' }} />}
                        />
                        <FormControlLabel
                            label="Push Notification"
                            style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                            control={<Checkbox 
                                checked={contactPreferences['send_notifs_viapush']} 
                                onChange={e=>setcontactPreferences({...contactPreferences, ['send_notifs_viapush'] : e.target.checked })}
                                style={{ color: '#0AA55E' }} />}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>;
    

    const Account =
        <Grid container justifyContent='center' direction='column' alignItems='center' style={{ background: '#F5F7FA', padding: '5% 0' }}>

            <Grid container item lg={8} md={10} sm={10} xs={10} style={{ borderRadius: '15px', border: '2px solid #D0DBE6', position: 'relative', padding: '20px' }}>
                <div onClick={handleClickOpenEditAccountInfo} style={{ position: 'absolute', cursor: 'pointer', right: '2%', top: '5%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <img style={{ width: '20px', height: '20px' }} src={EditImg} alt='edit' /><span style={{ paddingLeft: '10px', fontWeight: '500', color: '#F28612', fontSize: '16px' }}>Edit</span>
                </div>
                <Grid container spacing={2} >

                    <Grid item lg={3} md={2} sm={2} xs={12}>
                        <div>
                            <Typography style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}>CONTACT INFO</Typography>
                        </div>

                    </Grid>
                    <Grid item lg={4} md={5} sm={5} xs={12}>
                        <div>

                            <label style={{ color: '#636363', paddingTop: '3px' }} htmlFor="name">First Name</label>

                        </div>
                        <div style={{ paddingTop: '10px', fontWeight: '450' }}>
                            <span>{accountSetting.firstName}</span>
                        </div>
                        <br></br>
                        <br></br>

                        <div >

                            <label style={{ color: '#636363' }} htmlFor="email">Email</label>
                        </div>
                        <div style={{ paddingTop: '10px', fontWeight: '450' }}>

                            <span style={{ paddingTop: '3px' }}>{accountSetting.email}</span>
                        </div>

                    </Grid>
                    <Grid item lg={4} md={5} sm={5} xs={12}>
                        <div>
                            <label style={{ color: '#636363' }} htmlFor="name">Last Name</label>
                        </div>
                        <div style={{ paddingTop: '10px', fontWeight: '450' }}>
                            <span>{accountSetting.lastName}</span>
                        </div>
                        <br>
                        </br>
                        <br></br>
                        <div>
                            <label style={{ color: '#636363' }}>Phone Number</label>
                        </div>
                        <div style={{ paddingTop: '10px', fontWeight: '450' }}>
                            <span>{accountSetting.phone}</span>
                        </div>
                        <br>
                        </br>
                        <br></br>

                    </Grid>

                </Grid>

                <Grid container spacing={2}   >
                    <Grid item lg={3} md={2} sm={2} xs={12}>
                        <div>
                            <Typography style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}>ADDRESS INFO</Typography>
                        </div>

                    </Grid>

                    <Grid item lg={4} md={5} sm={5} xs={12}>
                        <div>

                            <label style={{ color: '#636363', fontsize: '16px' }} htmlFor="name">Street</label>

                        </div>
                        <div style={{ paddingTop: '10px', fontWeight: '450' }}>
                            < span>{accountSetting.address}</span>
                        </div>
                        <br></br>
                        <br></br>

                        <div>

                            <label style={{ color: '#636363' }} htmlFor="name">State</label>
                        </div>
                        <div style={{ paddingTop: '10px', fontWeight: '450' }}>

                            <span>{accountSetting.state}</span>
                        </div>

                    </Grid>




                    <Grid item lg={4} md={5} sm={5} xs={12}>
                        <div>
                            <label style={{ color: '#636363' }} htmlFor="city">City/Suburb</label>
                        </div>

                        <div style={{ paddingTop: '10px', fontWeight: '450' }}>
                            <span>{accountSetting.city}</span>
                        </div>
                        <br>
                        </br>
                        <br></br>


                        <div>
                            <label style={{ color: '#636363' }}>Zip/Post code</label>
                        </div>
                        <div style={{ paddingTop: '10px', fontWeight: '450' }}>
                            <span>{accountSetting.zipcode}</span>
                        </div>
                        <br>
                        </br>
                        <br></br>




                    </Grid>

                </Grid>
            </Grid>
            <Grid container item lg={8} md={10} sm={10} xs={10} style={{ borderRadius: '15px', border: '2px solid #D0DBE6', marginTop: '2em', padding: '20px' }}>
                <Grid container spacing={2} >
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography style={{ color: '#000', fontWeight: '600', fontSize: '19px' }}>Account Settings</Typography>
                        <Typography style={{ color: '#000', fontSize: '14px', marginTop: '0.5em' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida.</Typography>

                        <Typography style={{ color: '#0667C3', fontSize: '16px', marginTop: '2em', fontWeight: '600', cursor: 'pointer' }} onClick={handleClickOpenEditPassword}>Change Password</Typography>
                        <Typography style={{ color: '#EC2525', fontSize: '16px', marginTop: '0.5em', fontWeight: '600', cursor: 'pointer' }} onClick={handleClickOpenDeleteAccount}>Delete Account</Typography>

                    </Grid>

                </Grid>


            </Grid>
        </Grid>

    const handleTableRoleChange = ( newRoleId, userId ) => {
        var updatedRolesForPush = {}
        updatedRolesForPush[`${userId}`] = newRoleId
        const data = {
            // 'access': accessToken.toString(),
            'bulk_transfer': '0',
            'new_roles_data': updatedRolesForPush
        }
        console.log(data)
        axiosInstance.post( '/district/reassign_role/', data).then(res => {
            setAlert(true);
            setType("success");
            setDesc(res.data.message.toString())
            getMembets()
        }).catch(err => console.log(err))
    }
    
    const RoleButton = (props) => {
        return(
            <div>
                {
                    props.value ? ( 
                        <select name="role" onChange={(e) => handleTableRoleChange(e.target.value, props.userId)}>
                            {roles.map((e,i) => <option value={e.id} selected={props.value === e.id}>{e.name}</option>)}
                        </select>
                    ) : (
                        <div className = 'roleButton' style = { styles.roleButton } >
                            Admin
                        </div>
                    )
                }
            </div>
        );
    }

    const ActionButtons = (props) => {
        return(
            <div className="actions">
                <Button className='actionBtn' onClick={() => deleteMem(props.id)}><img src={BlueBin} alt='Delete'/></Button>
            </div>
        );
    }

        useEffect( () => {
        if ( accessToken !== '' ) {
            getMembets()
        }
    }, [accessToken])
    
    const getMembets = () => { 
        setloader(true)
        axiosInstance.get('/district/team/').then( res => {
                console.log(res.data)
                var members = res.data.filter( e => e.verified )
                setMembersRow( members )
                var pendingMembers = res.data.filter( e => !e.verified )
                setPendingRow( pendingMembers )
                setloader(false)
            } ).catch( err => {
                console.log( err ) 
                setloader(false)
            })
    }

    
    const [acceptPending, setAcceptPending] = useState( false )
    const [activeAccept, setActiveAccept] = useState({})
    const [activeAcceptRole, setActiveAcceptRole] = useState()
    const [checkedStateComPref, setCheckedStateComPref] = useState(
    new Array(regions.length).fill(false)
    );    
    
      const handleOnChangeComPref = (position) => {
    const updatedCheckedState = checkedStateComPref.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedStateComPref(updatedCheckedState);
  };
    const handleAccept = ( id ) => {
            setCheckedStateComPref(new Array(regions.length).fill(false))
            setActiveAcceptRole(roles[0].id)
            setAcceptPending( true )
            const activeAcc = PendingRows.filter( e => e.id === id )[0]
            setActiveAccept(activeAcc)
    }
    
    const acceptMemFinal = () => {
        var finalComPref = []
        console.log(checkedStateComPref)
        checkedStateComPref.map( ( item, index ) => { 
            if ( item ) { 
                finalComPref.push( regions[index].id)
            }
        } )
        const data = {
            // "access": accessToken,
            "non_admin_user_id": activeAccept.id.toString(),
            "request_action": "1",
            "role_id": activeAcceptRole.toString(),
            "comm_prefs_ids": finalComPref
        }
        console.log(data)
        axiosInstance.post('/district/team_request/', data)
        .then((res)=>{
            getMembets()
            setAcceptPending(false)
            setAlert(true);
            setType("success");
            setDesc('Accepted a new team member')
        })
        .catch(err=>{
            console.log( err );
            getMembets()
        })

    }

    const [rejectPending, setRejectPending] = useState( false )
    const [rejectMemPer, setRejectMem] = useState({})

    const deleteMem = ( id ) => {
        setRejectPending( true )
        const rejectAcc = MemberRows.filter( e => e.id === id )[0]
        setRejectMem(rejectAcc)
    }

    const rejectMem = ( id ) => {
        setRejectPending( true )
        const rejectAcc = PendingRows.filter( e => e.id === id )[0]
        setRejectMem(rejectAcc)
    }

    const handleReject = () => { 
        const data = {
            // "access": accessToken,
            "non_admin_user_id": rejectMemPer.id.toString(),
            "request_action": "0"
        }
        console.log(data)
        axiosInstance.post('/district/team_request/', data)
        .then((res)=>{
            setRejectPending( false )
            getMembets()
            setAlert(true);
            setType("success");
            setDesc('Successfully Removed');
        })
        .catch(err=>{
            console.log( err );
            getMembets()
        })
    }
    const ActionPendingButtons = (props) => {
        return(
            <div className="actions">
                <Button className='actionBtn' onClick={() => handleAccept(props.id)} ><img src={Accept} alt='Edit'/></Button>
                <Button className='actionBtn' onClick={() => rejectMem(props.id)}><img src={Reject} alt='Delete'/></Button>
            </div>
        );
    }

    const [teamFilter, setTeamFilter] = useState('')
    const [roleFilter, setRoleFilter] = useState('')

    const MemberColumns = [
        {
            field: 'full_name',
            headerName: 'USER NAME',
            description: 'Full Name',
            sortable: true,
            width: 300
        },
        {
            field: 'username',
            headerName: 'EMAIL',
            description: 'User Email Address',
            sortable: true,
            width: 290,
        },
        {
            field: 'school_name',
            headerName: 'SCHOOL NAME',
            description: 'School Name',
            sortable: true,
            width: 230
        },
        {
            field: 'userRole',
            headerName: 'ROLE',
            description: "User's Designated Role",
            sortable: false,
            width: 230,
            renderCell: (cellValues) => <RoleButton value={cellValues.row.role_id} userId={cellValues.row.id} />
        },
        {
            field: 'userActions',
            headerName: 'ACTIONS',
            description: "Edit or Delete Entries",
            sortable: false,
            width: 120,
            renderCell: (cellValues) => <ActionButtons id={cellValues.row.id} />
        },
    ];

    const [MemberRows, setMembersRow] = useState( [
        {
            id: 1,
            full_name: "",
            username: "",
            school_name: "",
            role: ''
        },
    ] )
    

    const PendingColumns = [
        {
            field: 'full_name',
            headerName: 'USER NAME',
            description: 'Full Name',
            sortable: true,
            width: 300
        },
        {
            field: 'username',
            headerName: 'EMAIL',
            description: 'User Email Address',
            sortable: true,
            width: 350,
        },
        {
            field: 'school_name',
            headerName: 'SCHOOL NAME',
            description: 'School Name',
            sortable: true,
            width: 230
        },
        {
            field: 'userActions',
            headerName: 'ACTIONS',
            description: "Edit or Delete Entries",
            sortable: false,
            width: 270,
            renderCell: (cellValues) => <ActionPendingButtons id={cellValues.row.id} />
        },
    ];


    const [PendingRows, setPendingRow] = useState( [
        {
            id: 1,
            full_name: "",
            username: "",
            school_name: "",
        },
    ] )


    const PendingRequests = <div className='membersGrid'>
        <DataGrid
            rows={ PendingRows.filter( e => (e.full_name.toLowerCase().includes( teamFilter.toLowerCase() ) || e.school_name?.toLowerCase().includes( teamFilter.toLowerCase() ) || e.username.toLowerCase().includes( teamFilter.toLowerCase() ))) }
            columns={PendingColumns}
            pageSize={8}
            rowsPerPageOptions={[8]}
            disableSelectionOnClick
        />
    </div>

    const MembersPage = <div className='membersGrid'>
        <DataGrid
            rows={MemberRows.filter( e => {
                if ( e.full_name.toLowerCase().includes( teamFilter.toLowerCase() ) || e.school_name?.toLowerCase().includes( teamFilter.toLowerCase() ) || e.username.toLowerCase().includes( teamFilter.toLowerCase() ) ) {
                    if ( roleFilter === '' ) {
                        return e
                    } else if ( parseInt( roleFilter ) === e.role_id ) {
                        return e
                    } else return null
                }
            } )}
            columns={MemberColumns}
            pageSize={8}
        rowsPerPageOptions={[8]}
        disableSelectionOnClick
        />
    </div>  

    const renderTeamSwitch = (param) => {
        switch (param) {
            case "pending":
                return PendingRequests;
            case "members":
                return MembersPage;
        }
    }

    const TeamPage = 
    <div className='teamContainer'>
        <Grid container direction='row'>
            <Grid item xs={9}>
                <Tabs
                    variant='standard'
                    centred={true}
                    value={teamTab}
                    onChange={handleTeamChange}
                    textColor="inherit"
                    TabIndicatorProps={{ style: { background: 'orange' } }}
                    aria-label="secondary tabs example"
                >
                    <Tab style={{ textTransform: 'capitalize' }} value="pending" label="Pending Requests" />
                    <Tab style={{ textTransform: 'capitalize' }} value="members" label="Members" />
                </Tabs>
            </Grid>
            <Grid item xs={2}>
                <div className='activityLog' style={styles.noFilterBlack} onClick={() => window.location.href='/activity-log'}>View Activity Log</div>
            </Grid>
            <Grid item xs={'auto'}>
                <div className='teamHeader'>
                    <SearchBar value={teamFilter} onChange={(e) => setTeamFilter(e.target.value)} />
                            <select name="rolesFilter" onChange={e => setRoleFilter(e.target.value)} style={styles.filterButton}>
                                <option value="" selected={roleFilter === ''}>All Roles</option>
                                {roles.map((e,i) => <option value={e.id} selected={roleFilter === e.id}>{e.name}</option>)}
                        </select>
                        <div className='clearfilters' style={ styles.noFilter } onClick={ () => { setRoleFilter( '' );setTeamFilter('')}}>Clear Filters</div>
                </div>
            </Grid>
        </Grid>
            { renderTeamSwitch( teamTab ) }
            <Modal
        open={rejectPending}
        onClose={() => setRejectPending(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"  
      >
                <div className='modalCont' style={{width: 'max-content'}}>
                    <h3>Are You Sure You Want to Remove "{rejectMemPer.full_name}"</h3>
                <div className="BtnCOnt">
                    <div className="BtnCOntLeft" onClick={() => setRejectPending(false)}>Cancel</div>
                    <button onClick={handleReject} style={{background : 'linear-gradient(275.34deg, #D80202 -10.41%, #D70000 -10.4%, #FF4545 102.29%)'}}>Yes</button>
                </div>
            </div>
      </Modal>
            <Modal
                    open={acceptPending}
                    onClose={() => {setAcceptPending(false)}}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"  
                >
                    <div className='modalCont' style={{width: '1250px'}}>
                        <h1>Assign role</h1>
                        <p style={{maxWidth: '555px'}}>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim</p>
                        <div className="modalTeamData">
                            <div>{activeAccept.full_name}</div>
                            <div>{activeAccept.username}</div>
                            <div>{activeAccept.school_name}</div>
                        </div>
                    <div>Select role</div>
                    <select style={{marginTop: '7px', marginBottom: '28px', width:'100%', padding: '17px'}} onChange={(e) => setActiveAcceptRole(e.target.value)}>
                    {roles.map((e,i) => <option value={e.id}>{e.name}</option>)}
                    </select>
                    <div>Select Region for communication</div>
                    { regions.map( (e,i) => {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'row', margin: '10px 0' }}>
                            <Checkbox
                                style={{ padding: '0', color: '#0DAC69' }}
                                name='isWorking'
                                checked={checkedStateComPref[i]}
                                onChange={() => handleOnChangeComPref(i)}
                            />
                            <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>{e.region}</span>
                        </div>
                        )
                    })}
                    <div style={{ display: 'flex', flexDirection: 'row', margin: '10px 0' }}>
                        <Checkbox
                            style={{ padding: '0', color: '#0DAC69' }}
                            name='isWorking'
                        />
                        <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Manhattan</span>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', marginTop: '20px'}}>
        <button className='btnContCancelBtn2' id='certificate_close_modal' onClick={() => {setAcceptPending(false)}}>
          Cancel
        </button>
        <button className='btnContSaveBtn' onClick={acceptMemFinal}>
          Save
        </button>
      </div>
                    </div>
                </Modal>
    </div>

    const [createRole, setRole] = useState(false)
    const [deleteUser, setDelete] = useState(false)
    const [delModalPos, setDelPos] = useState(1)
    function calculateRatio(num_1, num_2){

    for(var num=num_2; num>1; num--) {

        if((num_1 % num) == 0 && (num_2 % num) == 0) {
            num_1=num_1/num;
            num_2=num_2/num;
        }

    }
    var ratio = num_1+":"+num_2;
    return ratio;
}


    const getRoles = async () => {
        setloader(true)
        axiosInstance.get('/district/user_role/').then(res=>{
            setRoles(res.data);
            console.log(res.data)
            setloader(false)
        }).catch(err=>{
            console.log(err);
            setAlert(true);
            setType("error");
            setDesc(err.toString());
            setloader(false)
        })
    }

    const [addRole, setAddRole] = useState({
        role_name: '',
        role_description: '',
        save_as_draft: false,
        post_job: false,
        edit_job: false,
        delete_job: false,
        initiate_message: false,
        job_invites: false,
        view_all_job_posts: false,
        reply_message: false,
        block_user_message: false,
        respond_to_request: false,
        edit_member_profile: false,
        remove_team_member: false,
        view_activity_log: false,
        edit_district_profile: false,
        edit_contact_prefs: false,
    })

    const handleAddRole = e => {
            const { name, value } = e.target;
            setAddRole(prevState => ({
                ...prevState,
                [name]: value
            }));
        };
    const handleAddRoleCheckbox = e => {
            const name = e.target.name;
            setAddRole(prevState => ({
                ...prevState,
                [name]: !prevState[name]
            }));
        };
        const handleAddRoleCancel = () => {
            setAddRole({
        role_name: '',
        role_description: '',
        save_as_draft: false,
        post_job: false,
        edit_job: false,
        delete_job: false,
        initiate_message: false,
        job_invites: false,
        view_all_job_posts: false,
        reply_message: false,
        block_user_message: false,
        respond_to_request: false,
        edit_member_profile: false,
        remove_team_member: false,
        view_activity_log: false,
        edit_district_profile: false,
        edit_contact_prefs: false,
            } )
            setRole( false )
            setEditRole(false)
        }

    const handleDouplicate = ( i ) => {
        const tempRole = roles[i]
        tempRole['role_name'] = roles[i].name
        tempRole['role_description'] = roles[i].description
        setAddRole(tempRole)
        setduplicateRole(true);
        setRole( true )
        }

    const handleAddRoleSubmit = () => {
        if ( editRole ) {
            axiosInstance.patch(`/district/user_role/?role_id=${addRole.id}&role_name=${addRole.role_name}&role_description=${addRole.role_description}&save_as_draft=${addRole.save_as_draft}&post_job=${addRole.post_job}&edit_job=${addRole.edit_job}&delete_job=${addRole.delete_job}&initiate_message=${addRole.initiate_message}&job_invites=${addRole.job_invites}&view_all_job_posts=${addRole.view_all_job_posts}&reply_message=${addRole.reply_message}&block_user_message=${addRole.block_user_message}&respond_to_request=${addRole.respond_to_request}&edit_member_profile=${addRole.edit_member_profile}&remove_team_member=${addRole.remove_team_member}&view_activity_log=${addRole.view_activity_log}&edit_district_profile=${addRole.edit_district_profile}&edit_contact_prefs=${addRole.edit_contact_prefs}&
            `).then(res=>{
                console.log(res.data)
                handleAddRoleCancel()
                alertFunc('success', 'Role Updated Successfully');
                getRoles()
            }).catch(err=>{
                console.log( err );
                handleAddRoleCancel()
                console.log( err );
                getRoles()
                setAlert(true);
                setType("error");
                setDesc(err.response.data.error.toString());
            })
        } else if( duplicateRole ) {
            axiosInstance.post(`/district/user_role/?role_id=${addRole.id}&role_name=${addRole.role_name}&role_description=${addRole.role_description}&save_as_draft=${addRole.save_as_draft}&post_job=${addRole.post_job}&edit_job=${addRole.edit_job}&delete_job=${addRole.delete_job}&initiate_message=${addRole.initiate_message}&job_invites=${addRole.job_invites}&view_all_job_posts=${addRole.view_all_job_posts}&reply_message=${addRole.reply_message}&block_user_message=${addRole.block_user_message}&respond_to_request=${addRole.respond_to_request}&edit_member_profile=${addRole.edit_member_profile}&remove_team_member=${addRole.remove_team_member}&view_activity_log=${addRole.view_activity_log}&edit_district_profile=${addRole.edit_district_profile}&edit_contact_prefs=${addRole.edit_contact_prefs}&
            `).then(res=>{
                console.log(res.data)
                setduplicateRole(false);
                handleAddRoleCancel();
                alertFunc("success", "Role Added Successfully");
                getRoles()
            }).catch(err=>{
                console.log(err.response);
                setduplicateRole(false);
                getRoles();
                alertFunc("error", err.response.data.error.toString());
                handleAddRoleCancel()
            })
        } else {
             axiosInstance.post(`/district/user_role/?role_name=${addRole.role_name}&role_description=${addRole.role_description}&save_as_draft=${addRole.save_as_draft}&post_job=${addRole.post_job}&edit_job=${addRole.edit_job}&delete_job=${addRole.delete_job}&initiate_message=${addRole.initiate_message}&job_invites=${addRole.job_invites}&view_all_job_posts=${addRole.view_all_job_posts}&reply_message=${addRole.reply_message}&block_user_message=${addRole.block_user_message}&respond_to_request=${addRole.respond_to_request}&edit_member_profile=${addRole.edit_member_profile}&remove_team_member=${addRole.remove_team_member}&view_activity_log=${addRole.view_activity_log}&edit_district_profile=${addRole.edit_district_profile}&edit_contact_prefs=${addRole.edit_contact_prefs}&
            `).then(res=>{
                console.log(res.data)
                getRoles()
                handleAddRoleCancel();
                alertFunc("success", "Role Added Successfully");
            }).catch(err=>{
                console.log(err);
                getRoles();
                alertFunc("error", err.response.data.error.toString());
                handleAddRoleCancel()
            })
        }
    }

    const handleRoleDelete = () => {
        axiosInstance.delete(`/district/user_role/?role_id=${delRoleId}`).then(res=>{
            console.log(res.data)
            alertFunc("success", "Role Deleted Successfully");
            stopDelete()
        }).catch(err=>{
            console.log(err);
            setAlert(true);
            setType("error");
            setDesc(err.response.data.error.toString());
            stopDelete()
        })
    }

    const handleEditRole = ( num ) => {
        const tempRole = roles[num]
        tempRole['role_name'] = roles[num].name
        tempRole['role_description'] = roles[num].description
        setAddRole(tempRole)
        setEditRole( true )
        setRole( true )
    }

    const [editRole, setEditRole] = useState(false)
    const [duplicateRole, setduplicateRole] = useState(false);
    const [delRoleId, setDelRoleId] = useState()
    const [delRoleName, setDelRoleName] = useState()
    const [deleteRolesUsers, setDeleteRolesUsers] = useState([])
    const [updatedRoles, setUpdatedRoles] = useState( [] )
    
    const handleChangeRoleDD = (e, index) => {
        var tempRoles = [...updatedRoles]
        tempRoles[index] = e.target.value
        setUpdatedRoles(tempRoles)
    }

    useEffect(() => {
      console.log(updatedRoles)
    }, [updatedRoles])
    
    const handleRoleTransfer = () => {
        setDelPos( 3 )
        var updatedRolesForPush = {}
        deleteRolesUsers.map( ( e, i ) => {
            updatedRolesForPush[`${e.id}`] = updatedRoles[i]
        } )
        const data = {
            // 'access': accessToken.toString(),
            'bulk_transfer': '0',
            'new_roles_data': updatedRolesForPush
        }
        console.log(data)
        axiosInstance.post( '/district/reassign_role/', data).then(res => console.log(res.data)).catch(err => console.log(err))
    }

    const handleDeleteRoleOne = async ( id, name ) => { 
        console.log( id, name )
        setDelRoleId( id )
        setDelRoleName( name )
        axiosInstance.get(`district/users_with_role/?&role_id=${id}`).then(res=>{
            console.log( res.data )
            if ( res.data.length === 0 ) {
                setDelPos( 3 )
            }
            setDeleteRolesUsers(res.data)
            setDelete(true)
            const updatedRoles = roles.filter( e => !( e.id === id ) )
            setRoles(updatedRoles)
        }).catch(err=>{
            console.log(err);
        })
    }

    const stopDelete = () => {
        getRoles()
        setUpdatedRoles([])
        setDelPos(1)
        setDelete( false )
    }

    const [rolesSearch, setRolesSearch] = useState('')

    const UserRoles = <div className='userRolesCont'>
        <div className='searchBarCont'>
            <SearchBar value={rolesSearch} onChange={(e) => setRolesSearch(e.target.value)} />
        <button onClick={() => {setRole(true)}}>+ Add a new role</button>
        </div>
    <div className="tableCont">
                        <div className="tableLayout2">
                            <div className="tableHead">Role name</div>
                            <div className="tableHead">Description</div>
                            <div className="tableHead">Action</div>
                        </div>
                        {roles.filter(role => {
                              if (rolesSearch === '') {
                                return role;
                              } else if (role.name.toLowerCase().includes(rolesSearch.toLowerCase()) || role.description.toLowerCase().includes(rolesSearch.toLowerCase())) {
                                return role;
                              }
                            }).map((e,i) => {
                            return (
                                <div className="docTableCont2">
                            <div className={`tableLayout2 docRow docRowFirst docRowLast`}>
                    <div className="docName">{e.name} 
                    </div>
                    <div className="docDate">{e.description}</div>
                        <div className="IconCont">
                            <img style={{"cursor" : "pointer"}} onClick={() => handleEditRole(i)} src={ EP }/>
                            <img style={{"cursor" : "pointer"}} src={Doup} onClick={() => handleDouplicate(i)} />
                            <img style={{"cursor" : "pointer"}} src={Del} onClick={() => {handleDeleteRoleOne(e.id, e.name)}} />
                        </div>
                    </div>
                </div>
                            )
                        })}
                        
        </div>
        <Modal
        open={deleteUser}
        onClose={stopDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"  
      >
                <div className='modalCont' style={{width: '1250px'}}>
                    <h3>Delete "{delRoleName}" User Role</h3>
                <p>You're about to delete a user role which is currenlty in use. Please change the user role for the users currenlty using this role</p>
                <div className="posIndicator">
                    <div className={`indicator ${delModalPos > 0 ? 'activeIndicator' : null}`}><div className="indicatorNum">1</div>Configure Transfer Role</div>
                    <div className="line"/>
                    <div className={`indicator ${delModalPos > 1 ? 'activeIndicator' : null}`}><div className="indicatorNum">2</div>Review </div>
                    <div className="line"/>
                    <div className={`indicator ${delModalPos > 2 ? 'activeIndicator' : null}`}><div className="indicatorNum">3</div>Delete User Role </div>
                </div>
                { delModalPos === 1 ? (
                    <div>
                    <div className='tableOverflow'>
                        <div className="deleTabLayout tableHeads">
                            <div></div>
                            <div>User name</div>
                            <div>Email</div>
                            <div>School name</div>
                            <div>Role</div>
                            </div>
                            { deleteRolesUsers.map( (e,index) => {
                                return (
                                    <div className="deleTabLayout">
                                        <div></div>
                                        <div>{e.full_name}</div>
                                        <div>{e.username}</div>
                                        <div>{e.school_name}</div>
                                        <div>
                                            <select onChange={(e) => handleChangeRoleDD(e, index)}>
                                                {roles.map((e,i) => <option value={e.id}>{e.name}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>) : null}
                { delModalPos === 2 ? (
                    <div>
                    <div className='tableOverflow'>
                        <div className="deleTabLayout2 tableHeads">
                            <div>User name</div>
                            <div></div>
                            <div>School name</div>
                            <div>Previous role</div>
                            <div></div>
                            <div>New role</div>
                            </div>
                            { deleteRolesUsers.map( (e,index) => {
                                return (
                                    <div className="deleTabLayout2">
                                        <div>{e.full_name}</div>
                                        <div></div>
                                        <div>{e.school_name}</div>
                                        <div>{delRoleName}</div>
                                        <div><img src={Arrow} alt="" /></div>
                                        <div>{ roles.map( e => {
                                            if ( e.id.toString() === updatedRoles[index] ) {
                                                return e.name
                                            } else {
                                                return null
                                            }
                                        })}</div>
                                    </div>
                                )
                            })}
                        </div>
                        <div>Press Transfer Role & Continue to change 3 user from Manager Role to Supervisor Role </div>
                </div>) : null}
                { delModalPos === 3 ? (
                    <div style={{padding: '2rem'}}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl sed quis dui porttitor pellentesque erat facilisis tempus ac. Eget sem purus purus ut gravida augue elementum. Amet, odio purus aliquet suspendisse justo velit aenean pellentesque. Risus dolor mattis donec vulputate vitae odio magnis morbi. Turpis nisi, sagittis sed lacus cras massa arcu et lectus. Eu gravida aliquam aliquet malesuada. Non cursus sed ut aliquet. Egestas fringilla quisque pulvinar vestibulum lorem lacus erat pharetra aliquet. Sed condimentum non, augue varius ultricies magna donec ipsum. 
                </div>) : null}
                <div className="BtnCOnt">
                    <div className="BtnCOntLeft" onClick={ stopDelete}>Cancel</div>
                    {delModalPos === 1 ? <button onClick={() => setDelPos(delModalPos + 1)}>Continue</button> : null}
                    {delModalPos === 2 ? <button onClick={handleRoleTransfer}>Transfer Role & Continue</button> : null}
                    {delModalPos === 3 ? <button onClick={handleRoleDelete} style={{background : 'linear-gradient(275.34deg, #D80202 -10.41%, #D70000 -10.4%, #FF4545 102.29%)'}}>Submit</button> : null}
                </div>
            </div>
      </Modal>
        <Modal
        open={createRole}
        onClose={handleAddRoleCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"  
      >
                <div className='modalCont' style={{width: '950px'}}>
                    <h3>{editRole ? 'Edit' : 'Create'} a user role</h3>
                <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do <br /> amet sint. Velit officia consequat duis enim</p>
                <div className="searchLabelU">Role name</div>
                <SearchBar placeholder='E.g. Manager' name='role_name' value={addRole.role_name} onChange={handleAddRole} />
                <div className="searchLabelU">Description</div>
                <SearchBar placeholder='Enter Description' noSearch name='role_description' maxlength="100" value={addRole.role_description} onChange={handleAddRole} />
                <hr />
                <h4>Manage permissions</h4>
                <p>Check to allow permission for this role</p>
                <h5 style={{margin: '10px 0'}}>Job Post</h5>
                
                <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '5px 0' }}>
                    <Checkbox style={{ padding: '0', color: '#0DAC69' }} name='post_job' checked={addRole.post_job} onChange={handleAddRoleCheckbox} />
                    <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Allow user to post a job</span>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '5px 0' }}>
                    <Checkbox style={{ padding: '0', color: '#0DAC69' }} name='edit_job' checked={addRole.edit_job} onChange={handleAddRoleCheckbox} />
                    <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Allow user to edit job post</span>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '5px 0' }}>
                    <Checkbox style={{ padding: '0', color: '#0DAC69' }} name='delete_job' checked={addRole.delete_job} onChange={handleAddRoleCheckbox} />
                    <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Allow user to Delete job post</span>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '5px 0' }}>
                    <Checkbox style={{ padding: '0', color: '#0DAC69' }} name='job_invites' checked={addRole.job_invites} onChange={handleAddRoleCheckbox} />
                    <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Allow user to send job invites to job seekers</span>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '5px 0' }}>
                    <Checkbox style={{ padding: '0', color: '#0DAC69' }} name='view_all_job_posts' checked={addRole.view_all_job_posts} onChange={handleAddRoleCheckbox} />
                    <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Allow user to view all the job posts</span>
                </Grid>
                <h5 style={{margin: '10px 0'}}>Messages</h5>
                <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '5px 0' }}>
                    <Checkbox style={{ padding: '0', color: '#0DAC69' }} name='initiate_message' checked={addRole.initiate_message} onChange={handleAddRoleCheckbox} />
                    <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Allow user Initiate message with a job seeker</span>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '5px 0' }}>
                    <Checkbox style={{ padding: '0', color: '#0DAC69' }} name='reply_message' checked={addRole.reply_message} onChange={handleAddRoleCheckbox} />
                    <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Allow user to reply to a job seeker’s message</span>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '5px 0' }}>
                    <Checkbox style={{ padding: '0', color: '#0DAC69' }} name='block_user_message' checked={addRole.block_user_message} onChange={handleAddRoleCheckbox} />
                    <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Allow user to block a job seeker</span>
                </Grid>
                <h5 style={{margin: '10px 0'}}>Manage team</h5>
                <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '5px 0' }}>
                    <Checkbox style={{ padding: '0', color: '#0DAC69' }} name='respond_to_request' checked={addRole.respond_to_request} onChange={handleAddRoleCheckbox} />
                    <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Allow user to Accept/Reject a team member request</span>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '5px 0' }}>
                    <Checkbox style={{ padding: '0', color: '#0DAC69' }} name='edit_member_profile' checked={addRole.edit_member_profile} onChange={handleAddRoleCheckbox} />
                    <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Allow user to edit team member’s profile</span>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '5px 0' }}>
                    <Checkbox style={{ padding: '0', color: '#0DAC69' }} name='remove_team_member' checked={addRole.remove_team_member} onChange={handleAddRoleCheckbox} />
                    <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Allow user to remove a team member</span>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '5px 0' }}>
                    <Checkbox style={{ padding: '0', color: '#0DAC69' }} name='view_activity_log' checked={addRole.view_activity_log} onChange={handleAddRoleCheckbox} />
                    <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Allow user to view activity log</span>
                </Grid>
                <h5 style={{margin: '10px 0'}}>District profile</h5>
                <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '5px 0' }}>
                    <Checkbox style={{ padding: '0', color: '#0DAC69' }} name='edit_district_profile' checked={addRole.edit_district_profile} onChange={handleAddRoleCheckbox} />
                    <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Allow user to edit district profile</span>
                </Grid>
                <h5 style={{margin: '10px 0'}}>District Contact Preferences</h5>
                <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '5px 0' }}>
                    <Checkbox style={{ padding: '0', color: '#0DAC69' }} name='edit_contact_prefs' checked={addRole.edit_contact_prefs} onChange={handleAddRoleCheckbox} />
                    <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Allow user to edit district contact preferences</span>
                </Grid>
                <div className="BtnCOnt">
                    <div className="BtnCOntLeft" onClick={handleAddRoleCancel}>Cancel</div>
                    <button onClick={handleAddRoleSubmit}>Save</button>
                </div>
                </div>
      </Modal>
    </div>
    
    const [deleteCommPref, setDeleteCommPref] = useState( false )
    
    const getRegions = () => { 
        setloader(true)
        setRegionInput('')
        setDeleteCommPref(false)
        axiosInstance.get( '/district/communication_prefs/').then(res => {
            setRegions(res.data)
            setloader(false)
        }).catch(err => {
            console.log(err)
            setloader(false)
        })
    }

    useEffect(() => {
        if ( accessToken !== '' ) {
            getRegions()
        }
    }, [accessToken] )
    
    const [regionInput, setRegionInput] = useState( '' )
    const [delRegion, setDelRegion] = useState( {
        id: 0,
        region: ''
    })

    const addRegion = () => {
        axiosInstance.post( '/district/communication_prefs/', {
                // access: accessToken,
                region: regionInput
            }).then(res => getRegions()).catch(err => console.log(err))
    }
    

    const handleRegionDel1 = ( region, id ) => {
        setDelRegion( {
            id: id,
            region: region
        } )
        setDeleteCommPref(true)
    }
    
    const deleteRegion = () => {
        axiosInstance.delete( '/district/communication_prefs/', {
            data: {
                // access: accessToken,
                comm_id: delRegion.id.toString()
            }
        }).then(res => getRegions()).catch(err => console.log(err))
    }

    const CommPrefs = <div className='commsPrefsCont'>
        <h3>Communication Preferences</h3>
        <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. <br /> Velit officia consequat duis enim</p>
        <div className="inputContCom">
            <SearchBar noSearch type="text" placeholder='Add a region' value={regionInput} onChange={e => setRegionInput(e.target.value)} />
            <button style={{padding: '0 20px'}} onClick={addRegion}>Add to list</button>
        </div>
        <div className="comPrefTable">
            <div className="comPref1 comPrefTitle">Regions</div>
            <div className="comPref2 comPrefTitle">Action</div>
        </div>
        { regions.map( e => {
            return (
                <div className="comPrefTable">
                    <div className="comPref1">{e.region}</div>
                    <div className="comPref2">
                        <img style={{"cursor" : "pointer"}} onClick={() => {handleRegionDel1(e.region, e.id)}} src={Del} />
                    </div>
                </div>
            )
        })}
        <Modal
        open={deleteCommPref}
        onClose={() => setDeleteCommPref(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"  
      >
                <div className='modalCont' style={{width: 'max-content'}}>
                    <h3>Are You Sure You Want to Delete "{delRegion.region}"</h3>
                <div className="BtnCOnt">
                    <div className="BtnCOntLeft" onClick={() => setDeleteCommPref(false)}>Cancel</div>
                    <button onClick={ deleteRegion} style={{background : 'linear-gradient(275.34deg, #D80202 -10.41%, #D70000 -10.4%, #FF4545 102.29%)'}}>Yes</button>
                </div>
            </div>
      </Modal>
    </div>

    const [dProfileEdit, setDProfileEdit] = useState(false)
    const [districtWebsite, setDistrictWebsite] = useState('')
    const [districtAbout, setDistrictAbout] = useState('')
    const [districtPhnumber, setDistrictPhnumber] = useState('')

    const handleEditCancel = () => {
        setDistrictWebsite(districtProfile.website)
        setDistrictAbout(districtProfile.about)
        setDistrictPhnumber(districtProfile.phone)
        setDProfileEdit(false)
    }

    const patchDistrictProfile = async () => {
        axiosInstance.patch(`/district/profile/?phone=${districtPhnumber.toString()}&website=${districtWebsite}&about=${districtAbout}`).then(res=>{
            console.log(res.data)
            setDProfileEdit(false)
        }).catch(err=>{
            console.log(err);
            setAlert(true);
            setType("error");
            setDesc(err.toString());
        })
    }

    // chanhe profile picture
    // for cropping photo
    const [srcImage, setsrcImage] = useState(null)

    //save the image that used to be crop
    const [image, setImage] = useState(null)
    //change the aspect ratio of crop tool as you preferred
    const [crop, setCrop] = useState({ aspect: 1 / 1 })
    //save the resulted image
    const handleProfilePic = (e) => {
        // console.log(e.target.files[0]);
        // console.log(URL.createObjectURL(e.target.files[0]));
        // console.log(typeof(e.target.files[0]));
        setsrcImage(URL.createObjectURL(e.target.files[0]))
      }

      const getCroppedImg = async () => {
        try {
          const canvas = document.createElement('canvas')
          const scaleX = image.naturalWidth / image.width
          const scaleY = image.naturalHeight / image.height
          canvas.width = crop.width
          canvas.height = crop.height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
          )
          // const blob_ = canvas.toBlob(function(blob) {
          //   saveAs(blob, "pretty image.png");
          // });
          const base64Image = canvas.toDataURL('image/jpeg', 1)
          const blob_ = dataURItoBlob(base64Image)
          const imageFile = new File([blob_], `logo_profilePic`)
          return imageFile
        } catch (e) {
          console.log('crop the image')
        }
      }

      function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
          byteString = atob(dataURI.split(',')[1])
        else byteString = unescape(dataURI.split(',')[1])
    
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length)
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }
    
        return new Blob([ia], { type: mimeString })
      }

      const submitProfilePic = async () => {
        const imageFile = await getCroppedImg()
    
        let formD = new FormData()
        // formD.append('access', accessToken)
        formD.append('photo', imageFile)
    
        axiosInstance
          .post('/district/school_profile_logo/', formD)
          .then((res) => {
            getProfile().then(res1=>{
                setsrcImage(null)
                setImage(null)
                setCrop(null)
            })
            setAlert(true)
            setType('success')
            setDesc('Updated Successfully')
          })
          .catch((err) => {
            console.log(err.response)
            setAlert(true)
            setType('error')
            setDesc('err.response');
          })
      }

    const DistrProfile = 
    <div style={{marginTop: '70px', marginBottom: '50px', padding: '1rem'}}>
    <Modal
                    open={dProfileEdit}
                    onClose={handleEditCancel}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"  
                >
                    <div className='modalCont' style={{width: '700px'}}>
                    <h1>Edit District Profile Data</h1>
                    <div style={{display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                    <div style={{width: '50%'}}>
                    <div style={{ fontSize: '14px', marginBottom: '5px' }}>Website URL</div>
                    <TextField
                                placeholder="Enter your website URL"
                                size="medium"
                                variant="outlined"
                                value={districtWebsite}
                                name='current_website'
                                type='url'
                                onChange={(e) => setDistrictWebsite(e.target.value)}
                                style={{width: '100%'}}
                            />
                            </div>
                            <div style={{width: '45%'}}>
                            <div style={{ fontSize: '14px', marginBottom: '5px' }}>Phone Number</div>
                    <TextField
                                placeholder="Enter your website URL"
                                size="medium"
                                variant="outlined"
                                value={districtPhnumber}
                                name='current_website'
                                type='number'
                                onChange={(e) => setDistrictPhnumber(e.target.value)}
                                style={{width: '100%'}}
                            />
                            </div>
                            </div>
                            <div style={{width: '100%', marginTop: '15px'}}>
                            <div style={{ fontSize: '14px', marginBottom: '5px' }}>About</div>
                    <textarea value={districtAbout} onChange={(e) => setDistrictAbout(e.target.value)} placeholder='Enter About your School' style={{width: '100%', resize: 'none', height: '150px'}} />
                            </div>
                    <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', marginTop: '20px'}}>
        <button className='btnContCancelBtn2' id='certificate_close_modal' onClick={handleEditCancel}>
          Cancel
        </button>
        <button className='btnContSaveBtn' onClick={patchDistrictProfile}>
          Save
        </button>
        </div>
                    </div>
                </Modal>


                <div style={{ display: 'inline-flex', width: '100%', alignItems: 'center', gap: '20px' }}>
                <div class='circle' style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <img
                        src={
                            districtProfile && districtProfile.school_logo
                              ?  districtProfile.school_logo
                              : {Web}
                            }
                        alt=''
                    />

                </div>
                <h4 style={{ maxWidth: '520px' }}>{districtProfile?.district_name} (School District)</h4>
                <img src={Edit} style={{ cursor: "pointer" }} onClick={() => setDProfileEdit(true)} />
            </div>
            <div style={{ display: 'inline-flex', width: '100%', alignItems: 'center', gap: '20px' }}>
                <div
                    className='profileCompletion'
                    style={{
                        marginTop: '5px',
                        color: '#E1882D',
                        cursor: 'pointer',
                    }}
                    onClick={() =>
                        document
                        .getElementById('profile_pic_upload_button')
                        .click()
                    }
                >
                    Change Photo
                    <input
                        type='file'
                        name=''
                        id='profile_pic_upload_button'
                        accept='image/*'
                        hidden
                        onChange={handleProfilePic}
                    />
                </div>
            </div>
            <div>
                {srcImage && (
                    <div className='crop-container d-lg-flex d-md-block justify-content-around '>
                        <div className='d-lg-block d-sm-flex justify-content-sm-center'>
                            <ReactCrop
                                style={{
                                    maxWidth: '60vw',
                                    maxHeight: '75vh',
                                    padding: '5vh 0',
                                    overflow: 'auto',
                                }}
                                src={srcImage}
                                onImageLoaded={setImage}
                                crop={crop}
                                onChange={setCrop}
                            />
                        </div>
                        <div className='d-sm-flex flex-lg-column justify-content-sm-evenly  pt-sm-3'>
                            <Button
                                className='cropButton btnContSaveBtn mb-lg-5 mr-2'
                                onClick={submitProfilePic}
                            >
                                Upload Photo
                            </Button>
                            <Button
                                className='cancelButton'
                                style={{
                                    color: 'white',
                                    backgroundColor: '#a79b9b',
                                }}
                                onClick={() => {
                                    setsrcImage(null)
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            </div>


        <p style={{maxWidth: '520px',  marginTop: '10px'}}>
            {districtAbout}
        </p>
        <div style={ { display: 'inline-flex', gap: '15px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' } }>
            <a href={districtWebsite} target='_' style={{textDecoration: 'none'}}>
                <div style={ { display: 'inline-flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' } }>
                    <img src={Web} /> {districtWebsite}
                </div>
            </a>
            <a href={`mailto:${accountSetting.email}`} target='_' style={{textDecoration: 'none'}}>
                <div style={ { display: 'inline-flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' } }>
                    <img src={Mail} /> {accountSetting.email}
                </div>
            </a>
            <a href={`tel:${districtPhnumber}`} target='_' style={{textDecoration: 'none'}}>
                <div style={ { display: 'inline-flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' } }>
                    <img src={Phone} /> {districtPhnumber}
                </div>
            </a>
        </div>
        <div className='fourGridCol' style={{marginTop: '100px'}}>
            <div className='firstColHead'>Academics</div>
            <div className='colData'>
                <h4>{districtProfile?.percent_proficient_reading}%</h4>
                Percent Proficient - Reading
            </div>
            <div className='colData'>
                <h4>{districtProfile?.percent_proficient_maths}%</h4>
                Percent Proficient - Math
            </div>
            <div className='colData'>
                <h4>{districtProfile?.avg_graduation_rate}%</h4>
                Average Graduation Rate
            </div>
        </div>
        <div className='fourGridCol' style={{marginTop: '100px'}}>
            <div className='firstColHead'>Grade</div>
            <div className='colData'>
                <h4>{districtProfile?.lowest_grade}%</h4>
                Lowest Grade
            </div>
            <div className='colData'>
                <h4>{districtProfile?.highest_grade}%</h4>
                Heighest Grade
            </div>
        </div>
        <div className='fourGridCol' style={{marginTop: '50px'}}>
            <div className='firstColHead'>Students</div>
            <div className='colData'>
                <h4>{districtProfile?.no_of_students}</h4>
                Students
            </div>
        </div>
        <div className='fourGridCol' style={{marginTop: '50px'}}>
            <div className='firstColHead'>Teachers</div>
            <div className='colData'>
                <h4>{calculateRatio(districtProfile?.no_of_students, districtProfile?.no_of_teachers)} <sub style={{fontSize: '12px'}}>National 17:1</sub></h4>
                Student-Teacher Ratio
            </div>
        </div>
        <div className='graphColDiv' style={{marginTop: '50px'}}>
            <div className='firstColHead'>Finances</div>
            <div className='colData'>
                <h4>$ {districtProfile?.median_household_income} <sub style={{fontSize: '12px'}}>/ student</sub><p style={{fontSize: '12px'}}>National $12,239</p></h4>
                Median Household Income
            </div>
            <div className="bardGraphContDiv">
                <div className="bardGraphCont">
                    <div className="barColor" style={{width: '58%'}} />
                    <div className="barLabel">Instruction</div>
                    <div className="barPercent">58%</div>
                </div>
                <div className="bardGraphCont">
                    <div className="barColor" style={{width: '58%'}} />
                    <div className="barLabel">Support Services</div>
                    <div className="barPercent">58%</div>
                </div>
                <div className="bardGraphCont">
                    <div className="barColor" style={{width: '58%'}} />
                    <div className="barLabel">Other</div>
                    <div className="barPercent">58%</div>
                </div>
            </div>
        </div>
    </div>

    const HelpCentre = <div></div>

    const renderSwitch = (param) => {
        switch (param) {
            case "contactPreferences":
                return ContactPreference;
            case "account":
                return Account;
            case "teamPage":
                return TeamPage;
            case "userRoles":
                return UserRoles;
            case "commPreferences":
                return CommPrefs;
            case "helpCentre":
                return  HelpCentre;
            case "distrProfile":
                return DistrProfile;
        }
    }

    const [loader, setloader] = useState(false);
    if ( width > 960 ) {
        return (
        <div style={ { background: '#F5F7FA' } }>
        <Helmet>
                <title>Settings | Frame Change</title>
            </Helmet>
            <NavBar_emp />
            {loader ? <Loader /> : null}
            {/* ALERT */}
            {alertComp ? <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlert} /> : null}
            <div style={{width: 'fit-content', margin: 'auto'}}>

                <div style={{ paddingTop: '2em', marginTop: '8vh', marginBottom: '50px', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>

                        <Tabs
                            variant="fullWidth"
                            // scrollButtons
                            // allowScrollButtonsMobile
                            centred={true}
                            value={value}
                            onChange={handleChange}
                            textColor="inherit"
                            TabIndicatorProps={{ style: { background: 'orange' } }}
                            aria-label="secondary tabs example"
                        >
                            <Tab style={{ textTransform: 'capitalize' }} value="distrProfile" label="District Profile" />
                            <Tab style={{ textTransform: 'capitalize' }} value="contactPreferences" label="Contact Preferences" />
                            <Tab style={{ textTransform: 'capitalize' }} value="teamPage" label="Team" />
                            <Tab style={{ textTransform: 'capitalize' }} value="userRoles" label="User Roles" />
                            <Tab style={{ textTransform: 'capitalize' }} value="commPreferences" label="Communication Preferences" />
                            <Tab style={{ textTransform: 'capitalize' }} value="account" label="Account" />
                            <Tab style={{ textTransform: 'capitalize' }} value="helpCentre" label="Help Centre" />
                        </Tabs>
                    </div>
                    {renderSwitch(value)}
                </div>
            </div>

            {/* Edit Job Alert Card */}
            <Dialog
                open={openEditAccountInfo}
                onClose={handleCloseEditAccountInfo}

            >
                <DialogTitle style={{ padding: '1.5em 1em' }} >
                    <span style={{ fontWeight: '700' }}>Edit account information</span>
                    <br />
                    <span style={{ fontWeight: '300', fontSize: '14px', color: '#474747' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In neque nunc tristique.</span>

                </DialogTitle>
                <DialogContent >
                    <Grid container justifyContent='column'>
                        <span style={{ fontWeight: '600', fontSize: '16px' }}>Contact info</span>
                        <Grid container direction='row' style={{ marginTop: '1em' }} spacing={1}>
                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12} >
                                <span style={{ fontSize: '14px' }}>First name</span>
                                <TextField
                                    id="standard-size-small"
                                    defaultValue={accountSetting.firstName}
                                    size="small"
                                    variant="outlined"
                                    onChange={(e) => setmodalEdit({...modalEdit, firstName : e.target.value})} value={modalEdit.firstName}
                                />
                            </Grid>
                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12} >
                                <span style={{ fontSize: '14px' }}>Last name</span>
                                <TextField
                                    id="standard-size-small"
                                    defaultValue={accountSetting.lastName}
                                    size="small"
                                    variant="outlined"
                                    onChange={(e) => setmodalEdit({...modalEdit, lastName : e.target.value})} value={modalEdit.lastName}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction='row' style={{ marginTop: '1em' }} spacing={1}>
                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12} >
                                <span style={{ fontSize: '14px' }}>Email</span>
                                <TextField
                                    id="standard-size-small"
                                    defaultValue={accountSetting.email}
                                    size="small"
                                    variant="outlined"
                                    disabled
                                />
                            </Grid>
                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12} >
                                <span style={{ fontSize: '14px' }}>Phone number</span>
                                <TextField
                                    id="standard-size-small"
                                    defaultValue={accountSetting.phone}
                                    size="small"
                                    type="number"
                                    min="0"
                                    variant="outlined"
                                    onChange={(e) => setmodalEdit({...modalEdit, phone : e.target.value})} value={modalEdit.phone}
                                />
                            </Grid>
                        </Grid>
                        <span style={{ fontWeight: '600', fontSize: '16px', marginTop: '2em' }}>Address info</span>
                        <Grid container direction='row' style={{ marginTop: '1em' }} spacing={1}>
                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12} >
                                <span style={{ fontSize: '14px' }}>Street*</span>
                                <TextField
                                    id="standard-size-small"
                                    defaultValue={accountSetting.address}
                                    size="small"
                                    variant="outlined"
                                    onChange={(e) => setmodalEdit({...modalEdit, address : e.target.value})} value={modalEdit.address}
                                />
                            </Grid>
                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12} >
                                <span style={{ fontSize: '14px' }}>City/Suburb</span>
                                <TextField
                                    id="standard-size-small"
                                    defaultValue={accountSetting.city}
                                    size="small"
                                    variant="outlined"
                                    onChange={(e) => setmodalEdit({...modalEdit, city : e.target.value})} value={modalEdit.city}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction='row' style={{ marginTop: '1em' }} spacing={1}>
                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12} >
                                <span style={{ fontSize: '14px' }}>State*</span>
                                <TextField
                                    id="standard-size-small"
                                    defaultValue={accountSetting.state}
                                    size="small"
                                    variant="outlined"
                                    onChange={(e) => setmodalEdit({...modalEdit, state : e.target.value})} value={modalEdit.state}
                                />
                            </Grid>
                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12} >
                                <span style={{ fontSize: '14px' }}>Zip/Post code*</span>
                                <TextField
                                    id="standard-size-small"
                                    defaultValue={accountSetting.zipcode}
                                    type="number"
                                    min="0"
                                    size="small"
                                    variant="outlined"
                                    onChange={(e) => setmodalEdit({...modalEdit, zipcode : e.target.value})} value={modalEdit.zipcode}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions style={{ paddingBottom: '1.5em' }}>
                    <Button style={{ textTransform:'none',border: '1px solid #808080', borderRadius: '18px', padding: '0.5em 1em', color: '#808080' }} onClick={handleCloseEditAccountInfo}>Cancel</Button>
                    <Button style={{ textTransform:'none',background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', borderRadius: '18px', padding: '0.5em 1em', color: '#fff' }} onClick={handleEditForm} autoFocus>
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>
            {/* EditPassword */}
            <Dialog
                open={openEditPassword}
                onClose={handleCloseEditPassword}

            >
                <DialogTitle style={{ padding: '1em 1em', minWidth: '300px' }} >
                    <span style={{ fontWeight: '700' }}>Change Password</span>
                </DialogTitle>
                <DialogContent >
                    <Grid container justifyContent='column' spacing={2}>
                        <Grid container direction='column' item lg={12} md={12} sm={12} xs={12} >
                            <span style={{ fontSize: '14px' }}>Current password</span>
                            <TextField
                                placeholder="Enter your current password"
                                size="medium"
                                variant="outlined"
                                value={changePassword.current_password}
                                name='current_password'
                                type='password'
                                onChange={changePasswordInput}
                            />
                        </Grid>
                        <Grid container direction='column' item lg={12} md={12} sm={12} xs={12} >
                            <span style={{ fontSize: '14px' }}>New password</span>
                            <TextField
                                placeholder="Create a  new password"
                                size="medium"
                                variant="outlined"
                                type='password'
                                value={changePassword.new_passord}
                                name='new_password'
                                onChange={changePasswordInput}
                            />
                        </Grid>
                        <Grid container direction='column' item lg={12} md={12} sm={12} xs={12} >
                            <span style={{ fontSize: '14px' }}>Confirm password</span>
                            <TextField
                                placeholder="Confirm your new password"
                                size="medium"
                                variant="outlined"
                                type='password'
                                value={changePassword.confirm_new_password}
                                name='confirm_new_password'
                                onChange={changePasswordInput}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions style={{ paddingBottom: '1.5em' }}>
                    <Button style={{textTransform:'none', border: '1px solid #808080', borderRadius: '18px', padding: '0.5em 1em', color: '#808080' }} onClick={handleCloseEditPassword}>Cancel</Button>
                    <Button style={{textTransform:'none', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', borderRadius: '18px', padding: '0.5em 1em', color: '#fff' }} onClick={handleChngePasswordForm} autoFocus>
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>




            {/* Delete Alert */}

            <Dialog
                open={openDeleteAccount}
                onClose={handleCloseDeleteAccount}

            >

                <DialogContent >
                    <Grid container justifyContent='row' spacing={1} >
                        <Grid item lg={2} md={3} sm={3} xs={3} >
                            <img src={DeleteIcon} alt='Delete Icon' />
                        </Grid>
                        <Grid container direction='column' item lg={10} md={9} sm={9} xs={9} >
                            <span style={{ fontWeight: '600', fontSize: '19px' }}>Delete my account</span>

                            <span style={{ fontSize: '14px', marginTop: '1em' }}>Are you sure you want to delete your account? if you delete your account, you will permanently lose your profile, messages, and photos</span>

                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ paddingBottom: '1.5em' }}>
                    <Button style={{ textTransform:'none',border: '1px solid #808080', borderRadius: '18px', padding: '0.5em 1em', color: '#808080' }} onClick={handleCloseDeleteAccount}>Cancel</Button>
                    <Button style={{ textTransform:'none',background: 'linear-gradient(275.34deg, #D80202 -10.41%, #D70000 -10.4%, #FF4545 102.29%)', borderRadius: '18px', padding: '0.5em 1em', color: '#fff' }} onClick={handleDeleteButton} autoFocus>
                        Delete Account
                    </Button>
                </DialogActions>
            </Dialog>
        <Footer />
        </div>
    )
    } else {
        return (
            <div>
                <Helmet>
                <title>Frame Change</title>
            </Helmet>
            <NavBar_emp />
                <a href="/">
                    <img src={MobScreen} alt="" style={{display: 'block', margin: 'auto', marginTop: '150px', marginBottom: '50px'}} />
                </a>
                <Footer />
            </div>
        )
    }
}



export default EmpSetting;

export const SearchBar = ( { placeholder, noSearch, br70, onChange, value, name}) => {
        return(
            <>
                <div className="searchBox" style={br70 ? styles.searchBoxBigBr70 : styles.searchBoxBig}>
                    <Grid container direction='row' spacing={1} style={{width: '100%'}}>
                        <Grid item xs={'auto'} style={{width: '100%'}}>
                            <Grid container direction='row' spacing={2} style={{width: '100%'}}>
                                { noSearch ? null : (
                                    <Grid item xs={ 'auto' }>
                                        <img src={srch} alt="Search by Title"/>
                                    </Grid>
                                ) }
                                <Grid item xs={'true'} style={{width: '100%'}}>
                                    <div style={styles.hiddenLabel} aria-hidden="true">Search</div>
                                    <input type="text" placeholder={placeholder || "Search"} style={styles.srchField} name={name} onChange={onChange} value={value} maxlength="100" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </>
        );
    }
