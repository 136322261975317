const stateflags={
  Delaware: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c6/Flag_of_Delaware.svg/150px-Flag_of_Delaware.svg.png",
  Pennsylvania: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f7/Flag_of_Pennsylvania.svg/150px-Flag_of_Pennsylvania.svg.png",
  "New Jersey": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/92/Flag_of_New_Jersey.svg/167px-Flag_of_New_Jersey.svg.png",
  Georgia: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/Flag_of_Georgia_%28U.S._state%29.svg/160px-Flag_of_Georgia_%28U.S._state%29.svg.png",
  Connecticut: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Flag_of_Connecticut.svg/130px-Flag_of_Connecticut.svg.png",
  Massachusetts: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Flag_of_Massachusetts.svg/167px-Flag_of_Massachusetts.svg.png",
  Maryland: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Flag_of_Maryland.svg/150px-Flag_of_Maryland.svg.png",
  "South Carolina": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Flag_of_South_Carolina.svg/150px-Flag_of_South_Carolina.svg.png",
  "New Hampshire": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Flag_of_New_Hampshire.svg/150px-Flag_of_New_Hampshire.svg.png",
  Virginia: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/47/Flag_of_Virginia.svg/146px-Flag_of_Virginia.svg.png",
  "New York": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Flag_of_New_York.svg/180px-Flag_of_New_York.svg.png",
  "North Carolina": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/Flag_of_North_Carolina.svg/150px-Flag_of_North_Carolina.svg.png",
  "Rhode Island": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Rhode_Island.svg/114px-Flag_of_Rhode_Island.svg.png",
  Vermont: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Vermont.svg/167px-Flag_of_Vermont.svg.png",
  Kentucky: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Flag_of_Kentucky.svg/180px-Flag_of_Kentucky.svg.png",
  Tennessee: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Flag_of_Tennessee.svg/167px-Flag_of_Tennessee.svg.png",
  Louisiana: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Flag_of_Louisiana.svg/155px-Flag_of_Louisiana.svg.png",
  Indiana: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Flag_of_Indiana.svg/150px-Flag_of_Indiana.svg.png",
  Mississippi: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Flag_of_Mississippi.svg/167px-Flag_of_Mississippi.svg.png",
  Illinois: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Flag_of_Illinois.svg/167px-Flag_of_Illinois.svg.png",
  Alabama: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Alabama.svg/150px-Flag_of_Alabama.svg.png",
  Maine: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Flag_of_Maine.svg/127px-Flag_of_Maine.svg.png",
  Missouri: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/Flag_of_Missouri.svg/172px-Flag_of_Missouri.svg.png",
  Arkansas: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/Flag_of_Arkansas.svg/150px-Flag_of_Arkansas.svg.png",
  Michigan: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Flag_of_Michigan.svg/150px-Flag_of_Michigan.svg.png",
  Florida: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f7/Flag_of_Florida.svg/150px-Flag_of_Florida.svg.png",
  Texas: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f7/Flag_of_Texas.svg/150px-Flag_of_Texas.svg.png",
  Iowa: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Flag_of_Iowa.svg/150px-Flag_of_Iowa.svg.png",
  Wisconsin: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/22/Flag_of_Wisconsin.svg/150px-Flag_of_Wisconsin.svg.png",
  California: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Flag_of_California.svg/150px-Flag_of_California.svg.png",
  Minnesota: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Flag_of_Minnesota.svg/158px-Flag_of_Minnesota.svg.png",
  Oregon: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Flag_of_Oregon.svg/167px-Flag_of_Oregon.svg.png",
  Kansas: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Flag_of_Kansas.svg/167px-Flag_of_Kansas.svg.png",
  "West Virginia": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/22/Flag_of_West_Virginia.svg/180px-Flag_of_West_Virginia.svg.png",
  Nevada: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f1/Flag_of_Nevada.svg/150px-Flag_of_Nevada.svg.png",
  Nebraska: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/Flag_of_Nebraska.svg/167px-Flag_of_Nebraska.svg.png",
  Colorado: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Flag_of_Colorado.svg/150px-Flag_of_Colorado.svg.png",
  "North Dakota": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/Flag_of_North_Dakota.svg/128px-Flag_of_North_Dakota.svg.png",
  "South Dakota": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Flag_of_South_Dakota.svg/160px-Flag_of_South_Dakota.svg.png",
  Montana: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7a/State_Flag_of_Montana.svg/150px-State_Flag_of_Montana.svg.png",
  Washington: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/Flag_of_Washington.svg/168px-Flag_of_Washington.svg.png",
  Idaho: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_Idaho.svg/127px-Flag_of_Idaho.svg.png",
  Wyoming: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Wyoming.svg/143px-Flag_of_Wyoming.svg.png",
  Utah: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Commemorative_Flag_of_Utah_%282021%29.svg/167px-Commemorative_Flag_of_Utah_%282021%29.svg.png",
  Oklahoma: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Flag_of_Oklahoma.svg/150px-Flag_of_Oklahoma.svg.png",
  "New Mexico": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Flag_of_New_Mexico.svg/150px-Flag_of_New_Mexico.svg.png",
  Arizona: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/Flag_of_Arizona.svg/150px-Flag_of_Arizona.svg.png",
  Alaska: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Flag_of_Alaska.svg/142px-Flag_of_Alaska.svg.png",
  Hawaii: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Flag_of_Hawaii.svg/180px-Flag_of_Hawaii.svg.png",
  Ohio: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Flag_of_Ohio.svg/244px-Flag_of_Ohio.svg.png"
}

export default stateflags;