import { Grid, IconButton, Typography, InputAdornment, FormHelperText, CircularProgress } from '@material-ui/core';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import Logo from '../login/logo.svg';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { FcGoogle } from 'react-icons/fc';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { SiMicrosoftoutlook } from 'react-icons/si';
import { AiFillApple, AiFillFacebook, AiFillLinkedin } from 'react-icons/ai';
import { useHistory } from "react-router-dom";
import GirlImg from '../login/girl.svg';
import Tick from '../login/tick.svg';
import Outlook from '../register/outlook.svg';
import axiosInstance from '../../axios';
import { Alert } from '@mui/material';
import Cookies from 'js-cookie';
import { Helmet } from "react-helmet";
import Modal from '@mui/material/Modal'
import MaintainenceGuy from '../../images/undraw_maintenance_re_59vn 1.png'
import axios from 'axios';
import {baseURL} from '../../axios';
import { WindowSharp } from '@mui/icons-material';
import CustomAlert from '../alerts/alert';

const theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',

        ].join(','),
    },
});


const submitFormOnEnter = (e) => {
    if(e.keyCode === 13){
        document.getElementById('submitButton').click();
    }
    return;
}


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect( () => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener( "resize", handleResize ); 
    }, []);

    return windowDimensions;
}
const ForgotPass = ( props ) => {
    const [loginModal, setLoginModal] = useState(false)

    const history = useHistory();

    const [values1, setValues1] = React.useState({
        password: '',
        showPassword: false,
    });

    const handleChange1 = (prop) => (event) => {
        setAlert(false);
        setpasswordError(false);
        setpasswordErrorText('');
        setValues1({ ...values1, [prop]: event.target.value });
        setLoginData({ ...LoginData, [prop]: event.target.value });
    };
    const handleClickShowPassword1 = () => {
        setValues1({ ...values1, showPassword: !values1.showPassword });
    };


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [LoginData, setLoginData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        otp : '',
    })

    const [passwordError, setpasswordError] = React.useState(false);
    const [passwordErrorText, setpasswordErrorText] = React.useState("");

    const [emailError, setemailError] = React.useState(false);
    const [emailErrorText, setemailErrorText] = React.useState("");

    const [otpError, setotpError] = useState(false);
    const [otpErrorText, setotpErrorText] = useState("");



    const ValidateSingleField = (data) => {
        if (data == "")
            return true;
        return false;

    }

    const validateData = () => {

        setpasswordError(ValidateSingleField(LoginData.password));
        ValidateSingleField(LoginData.password) ? setpasswordErrorText('Password cannot be blank') : setpasswordErrorText('');

        setemailError(ValidateSingleField(LoginData.email));
        ValidateSingleField(LoginData.email) ? setemailErrorText('Email cannot be blank') : setemailErrorText('');

        if (!ValidateSingleField(LoginData.password) && !ValidateSingleField(LoginData.email)) {

            return true;

        }
        return false;

    }

  const [curposition, setPosition] = useState(1)
  
    const [open, setOpen] = React.useState(true);

    const [alert, setAlert] = React.useState(false);

    const [loader, setloader] = React.useState(false);


    const { height, width } = useWindowDimensions();


    const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const handleMail = () => {
        if(LoginData.email.length === 0){
            setemailError(true);
            setemailErrorText('Email cannot be blank');
        }else if(!validateEmail(LoginData.email)){
            setemailError(true);
            setemailErrorText('Invalid Email');
        }else{
            setloader(true);
            axios.post(baseURL + '/auth/send_otp/', {
                email: LoginData.email
            }).then(res => {
                if(res.data.msg === 'success'){
                    setPosition(2);
                }else{
                    setemailError(true);
                    setemailErrorText(res.data.msg);
                }
                setloader(false);
            }).catch(err => {
                setemailError(true);
                if(err.response.data.msg === 'User not Found'){
                    setemailErrorText('User not found');
                }else{
                    setemailErrorText('Something went wrong');
                }
                setloader(false);
            })
        }
    }

    const handleOTP = () => {
        if(curposition === 2 && LoginData.otp.length){
            setloader(true);
            axios.post(baseURL + '/auth/verify_otp/', {
                email: LoginData.email,
                otp: LoginData.otp
            }).then(res => {
                if(res.data.msg === 'success'){
                    setPosition(3);
                }else{
                    setotpError(true);
                    setotpErrorText(res.data.msg);
                }
                setloader(false);
            }).catch(err => {
                // console.log(err.response.data.msg)
                setotpError(true);
                setotpErrorText(err.response.data.msg);
                setloader(false);
            })
        }else{
            setotpError(true);
            setotpErrorText('OTP cannot be blank');
        }
    }

    const [customAlert, setcustomAlert] = useState({
        open: false,
        message: '',
        type : '',
    });

    const handlePassword = () => {
        if(LoginData.password != LoginData.confirmPassword){
            setpasswordError(true);
            setpasswordErrorText('Password and Confirm Password do not match');
            return;
        }else if(LoginData.password.length < 6){
            setpasswordError(true);
            setpasswordErrorText('Password must be atleast 6 characters');
            return;
        }
        setloader(true);
        setpasswordError(false);
        setpasswordErrorText('');
        axios.post(baseURL + '/auth/forgot_change_password/', {
            email: LoginData.email,
            password: LoginData.password,
            verification_string: 'null'
        }).then(res => {
            if(res.data.msg === 'Successfully Changed Password'){
                setPosition(4);
                setcustomAlert({
                    open: true,
                    message: 'Password Changed Successfully',
                    type: 'success'
                })
                setTimeout(() => {
                    history.push('/login');
                }, 2000);
            }else{
                setpasswordError(true);
                setpasswordErrorText(res.data.msg);
            }
            setloader(false);
        }).catch(err => {
            setpasswordError(true);
            setpasswordErrorText('Something went wrong');
            setloader(false);
        })
    }


    return (
        <ThemeProvider theme={ theme }>
            <Helmet>
                <title>Frame Change - Forgot Password</title>
            </Helmet>

            <div style={{ position: 'relative', zIndex: '1' }}>
                {customAlert.open ? < CustomAlert type={customAlert.type} desc={customAlert.message} alertState={customAlert.open} /> : null}
                <Grid container style={{ minHeight: '100vh' }}>
                    {width > 959 ? <Grid style={{ background: '#F1F1F1' }} item lg={8} md={7} sm={12} xs={12}>
                        <div style={{ position: 'sticky', top: '0' }}>
                            <img style={{ margin: '3% 5%' }} src={Logo} alt='logo' />
                            <Grid item container>
                                <Grid item lg={6} md={5} >
                                    <div style={{ marginLeft: '13%' }}>
                                        <Typography style={{ fontWeight: '600', fontSize: '33px' }}>Find your next teaching job. Right now.</Typography>
                                        <br />
                                        <Typography variant="body1" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor</Typography>
                                        <br />
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <img style={{ marginRight: '10px' }} src={Tick} alt='tick' />
                                            <Typography variant="body1" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam fringilla urna, porttitor</Typography>
                                        </div>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <img style={{ marginRight: '10px' }} src={Tick} alt='tick' />
                                            <Typography variant="body1" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam fringilla urna, porttitor</Typography>
                                        </div>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <img style={{ marginRight: '10px' }} src={Tick} alt='tick' />
                                            <Typography variant="body1" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam fringilla urna, porttitor</Typography>
                                        </div>
                                        <br />

                                    </div>

                                </Grid>
                                <Grid item lg={6} md={7} >
                                    <div style={{ position: 'relative', height: '80vh' }}>
                                        <img style={{ width: '100%', position: 'absolute', bottom: '0em' }} src={GirlImg} alt='girl' />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid> : <></>
                    }

                    <Grid style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} item lg={4} md={5} sm={12} xs={12}>
                        {width > 959 ? <></> :
                            <div style={{ background: '#FBF5F3', width: '100%', height: '8vh' }}>
                                <img style={{ paddingTop: '5px', width: '90px', position: 'absolute', top: '0', left: '0' }} src={Logo} alt='logo' />
                            </div>}
                        <Grid onKeyDown={submitFormOnEnter} style={{ marginTop: '15%', padding: '5% 15%' }} container direction='column' id='main_container' >
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                <Typography className='text' style={{ fontSize: '25px', fontWeight: '600' }}>Forgot Password</Typography>
                            </div>
                            <br />
                            {curposition == 1 ? <React.Fragment><span className='label'>Email</span>
                            <TextField className='inputField' type="email" error={emailError} helperText={emailErrorText} placeholder="Enter your email" value={LoginData.email} onChange={ (event) => {setAlert(false); setemailError(false); setemailErrorText('');  setLoginData({ ...LoginData, email: event.target.value })} } variant='outlined' /></React.Fragment> 
                            : null}
                 
                { curposition === 1 ? <React.Fragment>
                    <br />
                            
                            <br />
                  { loader ? <div style={ { display: 'flex', justifyContent: 'center' } } >
                                <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                            </div> : <Button id='submitButton' style={{ textTransform: 'none', fontSize: "18px", fontFamily: 'Montserrat', background: '#EB881B', color: '#FFF', fontWeight: '600', borderRadius: '25px', padding: '10px' }}
                                onClick={() => handleMail()}
                                variant="contained" >Submit</Button>}</React.Fragment> : null}
                
                            <br />
                { curposition === 2 ? <React.Fragment>
                    
                            
                            <br />
                            <TextField className='inputField' type="email" error={otpError} helperText={otpErrorText} placeholder="Enter OTP" variant='outlined' value={LoginData.otp} onChange={handleChange1('otp')} />
                            <br />
                            {loader ? <div style={{ display: 'flex', justifyContent: 'center' }} >
                                <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                            </div> :
                            <>
                                <Button id='submitButton' style={{ textTransform: 'none', fontSize: "18px", fontFamily: 'Montserrat', background: '#EB881B', color: '#FFF', fontWeight: '600', borderRadius: '25px', padding: '10px' }}
                                onClick={() => handleOTP()}
                                variant="contained" >Submit OTP</Button>
                                <br />
                                <h3 style={ { color: 'green' } } className='label'>OTP Sent on Email!</h3>
                            </>
                            }
                </React.Fragment> : null }
                { curposition >= 3 ? <React.Fragment>
                  <span className='label'>New Password</span>
                            <OutlinedInput
                                className='inputField'
                                placeholder='6 characters minimum'
                                variant='outlined'
                                error={passwordError}
                                type={values1.showPassword ? 'text' : 'password'}
                                value={LoginData.password}
                                onChange={handleChange1('password')}
                                    
                        
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword1}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {values1.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                  />
                  <span className='label'>Re-Enter Password</span>
                            <OutlinedInput
                                className='inputField'
                                placeholder='6 characters minimum'
                                variant='outlined'
                                error={passwordError}
                                type={values1.showPassword ? 'text' : 'password'}
                                value={LoginData.confirmPassword}
                                onChange={handleChange1('confirmPassword')}
                                    
                        
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword1}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {values1.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                  />
                  <br />
                  <FormHelperText style={{color:'red'}}>{passwordErrorText}</FormHelperText>

                            
                            <br />
                  {loader ? <div style={{ display: 'flex', justifyContent: 'center' }} >
                                <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                            </div> : <Button id='submitButton' style={{ textTransform: 'none', fontSize: "18px", fontFamily: 'Montserrat', background: '#EB881B', color: '#FFF', fontWeight: '600', borderRadius: '25px', padding: '10px' }}
                                onClick={() => handlePassword()}
                                variant="contained" >Change Password</Button>}
                </React.Fragment> : null }            
                
                            
                            {alert ? <Alert style={{marginTop:"1em"}}  severity="error" sx={{ width: '100%' }}>
                                    Invalid Credentials!
                                </Alert> : <></>}
                            <br />
                            
                            <br />
                        </Grid>


                    </Grid>


                </Grid>
            </div>
        </ThemeProvider>
    );
}

export default ForgotPass;
