import { Container, Grid } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import Logo from '../../logo.svg'
import { AiFillFacebook, AiFillYoutube, AiFillTwitterSquare, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai'
import { Facebook, Instagram, Twitter, YouTube, LinkedIn } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import Modal from '@mui/material/Modal'
import MaintainenceGuy from '../../images/undraw_maintenance_re_59vn 1.png'
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
function Footer({ register }) {
  const history = useHistory()
  const { height, width } = useWindowDimensions()

  const [loginModal, setLoginModal] = useState(false)
  return (
    <>
      <Modal
        open={loginModal}
        onClose={() => {
          setLoginModal(false)
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='modalCont' style={{ width: 'fit-content' }}>
          <img src={MaintainenceGuy} alt='' />
          <div className='loginModalHead'>This action is not available yet...</div>
          <div className='loginModalSubHead'>We are woking on it, please come back later.</div>
          <button className='loginModalBtn' onClick={() => setLoginModal(false)}>
            Okay
          </button>
        </div>
      </Modal>
      <Grid
        style={{ padding: '3.5em 0', background: '#E7EbF1', marginTop: '50px' }}
        container
        alignItems='center'
        justifyContent='center'
        direction='column'
      >
        <Grid container direction='column' style={{ width: '90%' }}>
          <Container style={{ margin: 'auto' }}>
            <Grid container>
              <Grid style={{ padding: '10px' }} container direction='column' justifyContent='flex-start' item lg={6} md={12} sm={12} xs={12}>
                <img style={{ width: '80px', height: '56px' }} src={Logo} alt='Logo' />
                <br />
                <p style={{ fontSize: '14px', color: '#686F79' }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis lacus sit malesuada id. Interdum ut lorem in vitae interdum
                  massa. Elementum, aliquam amet, venenatis facilisis volutpat, ut in. Nulla a in lacus amet lobortis nunc. Aliquet vulputate sit
                  egestas tincidunt.
                </p>
                <br />
                <p style={{ fontSize: '14px', color: '#686F79' }}>Contact us on help@framechange.com</p>

                <p style={{ fontSize: '14px', color: '#686F79' }}>Find us on:</p>
                <Grid container direction='row'>
                  <Facebook style={{ margin: '0 10px' }} />
                  <Instagram style={{ margin: '0 10px' }} />
                  <Twitter style={{ margin: '0 10px' }} />
                  <YouTube style={{ margin: '0 10px' }} />
                  <LinkedIn style={{ margin: '0 10px' }} />
                </Grid>
              </Grid>
              <Grid style={{ padding: '18px 10px 10px 10px ' }} item lg={2} md={4} sm={4} xs={12}>
                <span style={{ fontWeight: '600' }}>Job Seeker</span>
                <br />
                <br />
                <span style={{ fontSize: '14px', color: '#474747', cursor: 'pointer' }}>
                  <span
                    onClick={() => {
                      register ? history.push({ pathname: '/register' }) : history.push({ pathname: '/search-jobs' })
                    }}
                  >
                    Search job
                  </span>
                  <br />
                  <span
                    onClick={() => {
                      register ? history.push({ pathname: '/register' }) : history.push({ pathname: '/dashboard' })
                    }}
                  >
                    Hot jobs
                  </span>
                  <br />
                  <span
                    onClick={() => {
                      register ? history.push({ pathname: '/register' }) : history.push({ pathname: '/dashboard' })
                    }}
                  >
                    Recommended jobs
                  </span>
                  <br />
                  <span
                    onClick={() => {
                      register ? history.push({ pathname: '/register' }) : history.push({ pathname: '/dashboard' })
                    }}
                  >
                    Search jobs by region
                  </span>
                  <br />
                  <span
                    onClick={() => {
                      register ? history.push({ pathname: '/register' }) : history.push({ pathname: '/job-alert' })
                    }}
                  >
                  Job Alerts
                  </span>
                  <br />
                  <span
                    onClick={() => {
                      register ? history.push({ pathname: '/register' }) : history.push({ pathname: '/saved-jobs' })
                    }}
                  >
                  Saved Jobs
                  </span>
                  <br />
                  <span
                    onClick={() => {
                      register ? history.push({ pathname: '/register' }) : history.push({ pathname: '/profile' })
                    }}
                  >
                    Profile
                  </span>
                </span>
              </Grid>
              <Grid style={{ padding: '18px 10px 10px 10px ' }} item lg={2} md={4} sm={4} xs={12}>
                <span style={{ fontWeight: '600' }}>Employer</span>
                <br />
                <br />
                <span
                  style={{ fontSize: '14px', color: '#474747', cursor: 'pointer' }}
                  onClick={() => {
                    register ? history.push({ pathname: '/register' }) : history.push({ pathname: '/employer/job-posting' })
                  }}
                >
                  Post a job
                  <br />
                  <span
                    onClick={() => {
                      register ? history.push({ pathname: '/register' }) : history.push({ pathname: '/employer/dashboard' })
                    }}
                  >
                    Featured candidate
                  </span>
                  <br />
                  {/* <span
                    onClick={() => {
                      register ? history.push({ pathname: '/register' }) : history.push({ pathname: '/search-jobs' })
                    }}
                  >
                    Subscription plans
                  </span>
                  <br /> */}
                  <span
                    onClick={() => {
                      register ? history.push({ pathname: '/register' }) : history.push({ pathname: '/login' })
                    }}
                  >
                    Employer login
                  </span>
                  <br />
                  <span
                    onClick={() => {
                      register ? history.push({ pathname: '/register' }) : history.push({ pathname: '/message' })
                    }}
                  >
                    Messages
                  </span>
                  <br />
                  <span
                    onClick={() => {
                      register ? history.push({ pathname: '/register' }) : history.push({ pathname: '/employer/dashboard' })
                    }}
                  >
                    Dashboard
                  </span>
                </span>
              </Grid>

              <Grid style={{ padding: '18px 10px 10px 10px ' }} item lg={2} md={4} sm={4} xs={12}>
                <span style={{ fontWeight: '600' }}>Content</span>
                <br />
                <br />
                <span style={{ fontSize: '14px', color: '#474747', cursor: 'pointer' }} onClick={() => setLoginModal(true)}>
                  Videos
                  <br />
                  Live stream
                  <br />
                  Blog
                  <br />
                  Podcast
                  <br />
                  Social media content
                  <br />
                  Editorial content
                </span>
              </Grid>
            </Grid>
            <br />
            <Grid container style={{ padding: '10px' }}>
              <span style={{ color: '#808080', fontSize: '14px', marginRight: '1em' }}>© 2021 Frame Change</span>
              <span style={{ color: '#808080', fontSize: '14px', marginRight: '1em', cursor: 'pointer' }} onClick={() => setLoginModal(true)}>
                Contact
              </span>
              <span
                style={{ color: '#808080', fontSize: '14px', marginRight: '1em', cursor: 'pointer' }}
                onClick={() => {
                  history.push({ pathname: '/cookies' })
                }}
              >
                FAQ
              </span>
              <span style={{ color: '#808080', fontSize: '14px', marginRight: '1em', cursor: 'pointer' }} onClick={() => setLoginModal(true)}>
                About us
              </span>
              <span
                style={{ color: '#808080', fontSize: '14px', marginRight: '1em', cursor: 'pointer' }}
                onClick={() => {
                  history.push({ pathname: '/cookies' })
                }}
              >
                Cookies
              </span>
              <span
                style={{ color: '#808080', fontSize: '14px', marginRight: '1em', cursor: 'pointer' }}
                onClick={() => {
                  history.push({ pathname: '/privacy-policy' })
                }}
              >
                Privacy
              </span>
              <span
                style={{ color: '#808080', fontSize: '14px', marginRight: '1em', cursor: 'pointer' }}
                onClick={() => {
                  history.push({ pathname: '/terms-conditions' })
                }}
              >
                Terms & Conditions
              </span>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </>
  )
}

export default Footer
