import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import './styles.css';


function PlusButton(faq) {
  const [show, setShow] = useState(false);
  return (
    <>
      <button className='button' onClick={() => setShow(prev => !prev)}>+</button>
      {faq.question}
      {show && <Box>{faq.answer}</Box>}
    </>
  );
}

export default PlusButton;