import { Button, Checkbox, FormControl, Grid, Paper, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Step, Stepper } from 'react-form-stepper';
import NativeSelect from '@material-ui/core/NativeSelect';
import './style.css';
import Success from './success.svg';
import Profile from './profile.svg';
import Select from 'react-select';
import DotDesign from './dotDesign.svg';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Logo from '../login/logo.svg';
import { v4 as uuidv4 } from 'uuid';
import { Redirect } from 'react-router-dom';
import axiosInstance from '../../axios';
import school_district from '../../assets/school.json';
import Cookies from 'js-cookie';
import { Helmet } from "react-helmet";
import Tooltip from '@mui/material/Tooltip';
import { FaInfoCircle } from "react-icons/fa";
import { jobTitlesArr } from '../navbarArrays/navbarArrays';
import axios from 'axios';
import Alert from '../alerts/alert'
import {baseURL} from '../../axios';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}




const OnBoarding = (props) => {
    const [alertComp, setAlert] = React.useState(false)
    const [typeOfAlert, setType] = React.useState('')
    const [descOfAlert, setDesc] = React.useState('');

    const { height, width } = useWindowDimensions();

    const [activeStep, setActiveStep] = useState(0);
    const [nextDisabled, setNextDisabled] = useState(false);

    const [selectedJobOption, setSelectedJobOption] = useState([]);
    const [selectedLocOption, setSelectedLocOption] = useState([]);


    const [isBack, setIsBack] = useState(true);
    const [accessToken, setAccessToken] = useState('');
    const [activeStep_1, setactiveStep_1] = useState({
        school: false,
        degree: false,
        desc: false,
        startDate: false
    });

    const [activeStep_2, setactiveStep_2] = useState({
        school: false,
        position: false,
        desc: false,
        startDate: false
    });

    const [activeStep_3, setactiveStep_3] = useState({
        certificateName: false,
        certificateAuthority: false,
        desc: false,
        startDate: false
    });

    useEffect(() => {
        if (Cookies.get('access_token')) {
            setAccessToken(Cookies.get('access_token'));
            try {
                if (localStorage.getItem('email'))
                    document.getElementById('emailField').value = localStorage.getItem('email');
            } catch (err) {
                console.log(err);
            }
        } else {
            window.location.href = '/register';
        }
    }, [])

    const handleSubmit1 = () => {


        const educationData = []

        for (let i = 0; i < inputFieldsEducation.length; i++) {
            // console.log(inputFieldsEducation.school, inputFieldsEducation.school.value);
            educationData.push({
                degree: inputFieldsEducation[i].degree,
                school: inputFieldsEducation[i].school ? inputFieldsEducation[i].school.value : null,
                start_date: inputFieldsEducation[i].startDate,
                description: inputFieldsEducation[i].desc
            })
        }

        const workExp = []

        for (let i = 0; i < inputFieldsWork.length; i++) {
            workExp.push({
                position: inputFieldsWork[i].position.value,
                school: inputFieldsWork[i].school ? inputFieldsWork[i].school.value : null,
                start_date: inputFieldsWork[i].startDate,
                description: inputFieldsWork[i].desc
            })
        }

        const certs = []

        for (let i = 0; i < inputFieldsCertificate.length; i++) {
            certs.push({
                name: inputFieldsCertificate[i].certificateName,
                authority: inputFieldsCertificate[i].certificateAuthority,
                start_date: inputFieldsCertificate[i].startDate,
                description: inputFieldsCertificate[i].desc
            })
        }

        const data = {
            // access: accessToken,
            phone: parseInt(phNumber),
            street: street,
            city: city,
            state: state,
            zipcode: parseInt(zip),
            educations: educationData,
            work_exps: workExp,
            certificates: certs
        }

        axiosInstance.post('/seeker/onboarding/', data).then((res) => {
            const data = res.data;
            if (data.status === 'success') {
                localStorage.setItem('phone', phNumber);
            }
        }).catch((err) => {
            if (err)
                console.log(data);
            console.log(err.data)
        });
    }

    //Education Form Starts
    const [inputFieldsEducation, setInputFieldsEducation] = useState([
        { id: uuidv4(), degree: '', school: '', startDate: '', endDate: '', desc: '' },
    ]);
    const handleSubmitEducation = (e) => {
        e.preventDefault();
        console.log("InputFields", inputFieldsEducation);
    };

    const handleChangeInputEducation = (id, event) => {
        if (event.target.name === 'startDate' || event.target.name === 'endDate') {
            if (event.target.value.split('-')[0].length > 4) {
                return;
            }

            // years condition
            if((event.target.value.split('-')[0] >= 1000) && (event.target.value.split('-')[0] < 1930))
            return;
        
            if((event.target.value.split('-')[0] > 2050) && (event.target.value.split('-')[0] < 9999))
            return;
        }
        const newInputFields = inputFieldsEducation.map(i => {
            if (id === i.id) {
                i[event.target.name] = event.target.value
            }
            return i;
        })

        setInputFieldsEducation(newInputFields);
    }
    const handleChangeSchoolEducation = (id, selectedDist) => {
        const newInputFields = inputFieldsEducation.map(i => {
            if (id === i.id) {
                i['school'] = selectedDist
            }
            return i;
        })

        setInputFieldsEducation(newInputFields);
    }

    const handleAddFieldsEducation = () => {
        setInputFieldsEducation([...inputFieldsEducation, { id: uuidv4(), degree: '', school: '', startDate: '', endDate: '', desc: '' }]);
        setTimeout(function () {
            window.scrollTo(0, window.scrollY + 400)
        }, 50)
    }

    const handleRemoveFieldsEducation = id => {
        window.scrollTo(0, window.scrollY - 400);
        setTimeout(function () {
            const values = [...inputFieldsEducation];
            values.splice(values.findIndex(value => value.id === id), 1);
            setInputFieldsEducation(values);
        }, 500)

    }

    const [schoolList, setschoolList] = useState([]);
    const [searchSchoolText, setsearchSchoolText] = useState('');

    useEffect(() => {
        getSchoolData();
    }, [searchSchoolText])

    const getSchoolData = () => {
        axios.get(`${baseURL}/seeker/get_school_by_search/`, {
            params: {
                search: searchSchoolText
            }
        }).then(res => {
            setschoolList(res.data.msg);
        }).catch(err => {
            console.log(err);
        })
    }


    const educationForm = <>
        <div style={{ marginLeft: '2.5em', display: "flex", flexDirection: 'column' }}>
            <Tooltip title='These fields are optional. You can skip these by clicking on "Continue"' placement="right-start" arrow><Typography style={{ fontSize: '19px', fontWeight: '600', width: 'fit-content' }}>Education <FaInfoCircle /></Typography></Tooltip>
            <br />

            <form onSubmit={handleSubmitEducation}>
                {inputFieldsEducation.map((inputField, idx) => (
                    <Grid key={inputField.id} container item spacing={3} lg={11}>
                        <Grid item lg={6}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>

                                <span className='label'>Degree/Speciality</span>
                                <TextField
                                    size="small"
                                    value={inputField.degree}
                                    name='degree'
                                    placeholder='Ex: Bachelors'
                                    variant='outlined'
                                    onChange={event => handleChangeInputEducation(inputField.id, event)}
                                    error={activeStep_1.degree}
                                />
                            </div>
                        </Grid>
                        <Grid item lg={6}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='label'>School/Institution</span>
                                <Select
                                    value={inputField.school}
                                    name='school'
                                    className={activeStep_1.school ? "incorrectInput" : null}
                                    onChange={selectedDist => {
                                        // inputField.school = selectedDist;
                                        // console.log(selectedDist, inputField.school);
                                        // handleChangeInputEducation(inputField.id, selectedDist);
                                        handleChangeSchoolEducation(inputField.id, selectedDist)

                                    }}
                                    isClearable={true}
                                    onKeyDown={(e) => { setsearchSchoolText(e.target.value) }}
                                    options={schoolList}
                                />
                                {/* <TextField
                                    size="small"
                                    value={inputField.school}
                                    name='school'
                                    placeholder='Ex: Boston University'
                                    variant='outlined'
                                    onChange={event => handleChangeInputEducation(inputField.id, event)}
                                /> */}
                            </div>
                        </Grid>
                        <Grid item lg={6}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='label'>Start date</span>
                                <input
                                    size="small"
                                    error={activeStep_1.startDate}
                                    value={inputField.startDate}
                                    name='startDate'
                                    InputProps={{
                                        inputProps: {
                                          min: "1930-01-01",
                                          max: "2050-01-01"
                                        },
                                    }}
                                    type="date"
                                    placeholder='Start date'
                                    min="1980-01-01"
                                    style={{
                                        padding: '0.5em',
                                        borderRadius: '5px',
                                        border: '1px solid #e1e1e1'
                                    }}
                                    max="2050-01-01"
                                    variant='outlined'
                                    onChange={event => handleChangeInputEducation(inputField.id, event)}
                                />
                            </div>
                        </Grid>
                        <Grid item lg={6}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='label'>End Date</span>
                                <input
                                    size="small"
                                    name='endDate'
                                    value={inputField.endDate}
                                    type="date"
                                    InputProps={{
                                        inputProps: {
                                            min: inputField.startDate
                                                ? inputField.startDate
                                                : null,
                                            max: "2050-01-01"
                                        },
                                    }}
                                    placeholder='End date'
                                    variant='outlined'
                                    min="1930-01-01"
                                    style={{
                                        padding: '0.5em',
                                        borderRadius: '5px',
                                        border: '1px solid #e1e1e1'
                                    }}
                                    max="2050-12-31"
                                    onChange={event => handleChangeInputEducation(inputField.id, event)}
                                />
                            </div>
                        </Grid>
                        <Grid item lg={12} md={11} sm={11} xs={11}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='label'>Description</span>
                                <TextField
                                    name='desc'
                                    error={activeStep_1.desc}
                                    value={inputField.desc}
                                    multiline={true}
                                    rows={4}
                                    size="small"
                                    variant='outlined'
                                    onChange={event => handleChangeInputEducation(inputField.id, event)}
                                />
                            </div>
                        </Grid>
                        <Grid container justifyContent={inputFieldsEducation.length == idx + 1 ? 'space-between' : 'flex-end'} item lg={12} md={11} sm={11} xs={11}>

                            {inputFieldsEducation.length == idx + 1 ? <Button variant='contained' style={{ background: "#fff", borderRadius: '18px', fontWeight: '600', textTransform: 'none', border: '2px solid #0881f4', color: '#0881f4' }} onClick={handleAddFieldsEducation}>Add Another</Button> : <></>}
                            <Button variant='contained' style={{ background: "#fff", borderRadius: '18px', fontWeight: '600', textTransform: 'none', color: '#909090' }} disabled={inputFieldsEducation.length === 1} onClick={() => handleRemoveFieldsEducation(inputField.id)}><RiDeleteBin6Line style={{ height: '2em' }} />&nbsp;Remove</Button>


                        </Grid>
                        <hr style={{ width: '100%' }}></hr>
                        <br />
                    </Grid>


                ))}


            </form>


        </div>

    </>;
    //Work Form Starts
    const [inputFieldsWork, setInputFieldsWork] = useState([
        { id: uuidv4(), position: '', school: '', startDate: '', endDate: '', desc: '', isWorking: false },
    ]);
    const handleSubmitWork = (e) => {
        e.preventDefault();
        console.log("InputFields", inputFieldsWork);
    };

    const handleChangeInputWork = (id, event) => {
        if (event.target.name === 'startDate' || event.target.name === 'endDate') {
            if (event.target.value.split('-')[0].length > 4) {
                return;
            }

            
            // years condition
            if((event.target.value.split('-')[0] >= 1000) && (event.target.value.split('-')[0] < 1930))
                return;
        
            if((event.target.value.split('-')[0] > 2050) && (event.target.value.split('-')[0] < 9999))
                return;
        }
        const newInputFields = inputFieldsWork.map(i => {
            if (id === i.id) {
                i[event.target.name] = event.target.value
            }
            return i;
        })

        setInputFieldsWork(newInputFields);
    }

    const handleChangeSchoolWork = (id, selectedDist, name) => {
        const newInputFields = inputFieldsWork.map(i => {
            if (id === i.id) {
                i[name] = selectedDist;
            }
            return i;
        })

        setInputFieldsWork(newInputFields);
    }
    const handleChangeInputWorkCheckBox = (id, event) => {
        var curEnd = document.getElementById("endDate" + event.target.id);
        if (event.target.checked) {
            curEnd.style.display = 'none';
        } else {
            curEnd.style.display = 'flex';
        }
        const newInputFields = inputFieldsWork.map(i => {
            if (id === i.id) {
                i[event.target.name] = event.target.checked
            }
            return i;
        })

        setInputFieldsWork(newInputFields);
    }

    const handleAddFieldsWork = () => {
        window.scrollTo(0, window.scrollY + 400);

        setInputFieldsWork([...inputFieldsWork, { id: uuidv4(), position: '', school: '', startDate: '', endDate: '', desc: '', isWorking: false }])
        setTimeout(function () {
            window.scrollTo(0, document.body.scrollHeight)
        }, 50)
    }

    const handleRemoveFieldsWork = id => {
        window.scrollTo(0, window.scrollY - 400);

        const values = [...inputFieldsWork];
        values.splice(values.findIndex(value => value.id === id), 1);
        setInputFieldsWork(values);
    }

    const Districts = [
        { value: 'Constance Beard', label: 'Constance Beard' },
        { value: 'Pine Woods School', label: 'Pine Woods School' }

    ];

    const workForm = <>
        <div style={{ marginLeft: '2.5em', display: "flex", flexDirection: 'column' }}>
            <Tooltip title='These fields are optional. You can skip these by clicking on "Continue"' placement="right-start" arrow><Typography style={{ fontSize: '19px', fontWeight: '600', width: 'fit-content' }}>Work Experience <FaInfoCircle /></Typography></Tooltip>
            <br />
            <form onSubmit={handleSubmitWork}>
                {inputFieldsWork.map((inputField, idx) => {
                    return <Grid key={inputField.id} container item spacing={3} lg={11}>
                        <Grid item lg={6}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='label'>Position</span>
                                <Select
                                    className={activeStep_2.school ? "incorrectInput" : null}
                                    value={inputField.position}
                                    isClearable={true}
                                    onChange={selectedDist => handleChangeSchoolWork(inputField.id, selectedDist, 'position')}
                                    options={jobTitlesArr}
                                />

                                {/* <TextField size="small"
                                    error={activeStep_2.position}
                                    name='position'
                                    value={inputField.position}
                                    onChange={event => handleChangeInputWork(inputField.id, event)}
                                    placeholder='Ex: Assistant Professor' variant='outlined' /> */}
                            </div>
                        </Grid>
                        <Grid item lg={6}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>


                                <span className='label'>School District</span>
                                <Select
                                    className={activeStep_2.school ? "incorrectInput" : null}
                                    value={inputField.school}
                                    onChange={selectedDist => handleChangeSchoolWork(inputField.id, selectedDist, 'school')}
                                    isClearable={true}
                                    onKeyDown={(e) => { setsearchSchoolText(e.target.value) }}
                                    options={schoolList}
                                />

                            </div>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>


                                <Checkbox
                                    style={{ padding: '0', color: '#0DAC69' }}
                                    name='isWorking'
                                    checked={inputField.isWorking}
                                    onChange={event => handleChangeInputWorkCheckBox(inputField.id, event)
                                    }
                                    id={inputField.id}
                                />
                                <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Currently working</span>
                            </div>
                        </Grid>

                        <Grid item lg={6}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>


                                <span className='label'>Start date</span>
                                <input name='startDate'
                                    value={inputField.startDate}
                                    error={activeStep_2.startDate}
                                    InputProps={{
                                        inputProps: {
                                          min: "1930-01-01",
                                          max: "2050-01-01"
                                        },
                                    }}
                                    style={{
                                        padding: '0.5em',
                                        borderRadius: '5px',
                                        border: '1px solid #e1e1e1'
                                    }}
                                    onChange={event => handleChangeInputWork(inputField.id, event)}
                                    size="small" type="date" placeholder='Start date' variant='outlined' />
                            </div>
                        </Grid>
                        <Grid item lg={6}>
                            <div style={{ display: 'flex', flexDirection: 'column' }} id={"endDate" + inputField.id}>


                                <span className='label'>End Date</span>
                                <input name='endDate'
                                    value={inputField.endDate}
                                    min="1930-01-01"
                                    style={{
                                        padding: '0.5em',
                                        borderRadius: '5px',
                                        border: '1px solid #e1e1e1'
                                    }}
                                    max="2050-12-31"
                                    onChange={event => handleChangeInputWork(inputField.id, event)}
                                    InputProps={{
                                        inputProps: {
                                            min: inputField.startDate
                                                ? inputField.startDate
                                                : null,
                                            max: "2050-01-01"

                                        },
                                    }}
                                    size="small" type="date" placeholder='End date' variant='outlined' />
                            </div>
                        </Grid>
                        <Grid item lg={12}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>


                                <span className='label'>Description</span>
                                <TextField name='desc'
                                    value={inputField.desc}
                                    error={activeStep_2.desc}
                                    onChange={event => {
                                        if (!(event.target.value.length > 255))
                                            handleChangeInputWork(inputField.id, event)
                                    }}
                                    multiline={true} rows={4} size="small" variant='outlined' />
                            </div>
                        </Grid>
                        <Grid container justifyContent={inputFieldsWork.length == idx + 1 ? 'space-between' : 'flex-end'} item lg={12} md={11} sm={11} xs={11}>

                            {inputFieldsWork.length == idx + 1 ? <Button variant='contained' style={{ background: "#fff", borderRadius: '18px', fontWeight: '600', textTransform: 'none', border: '2px solid #0881f4', color: '#0881f4' }} onClick={handleAddFieldsWork}>Add Another</Button> : <></>}
                            <Button variant='contained' style={{ background: "#fff", borderRadius: '18px', fontWeight: '600', textTransform: 'none', color: '#909090' }} disabled={inputFieldsWork.length === 1} onClick={() => handleRemoveFieldsWork(inputField.id)}><RiDeleteBin6Line style={{ height: '2em' }} /> &nbsp;Remove</Button>


                        </Grid>
                        <hr style={{ width: '100%' }}></hr>
                        <br />
                    </Grid>



                })}
            </form>

        </div>

    </>;

    //Certificate Form Starts
    const [inputFieldsCertificate, setInputFieldsCertificate] = useState([
        { id: uuidv4(), certificateName: '', certificateAuthority: '', startDate: '', endDate: '', desc: '', isNotExpired: false },
    ]);
    const handleSubmitCertificate = (e) => {
        e.preventDefault();
        console.log("InputFields", inputFieldsCertificate);
    };

    const handleChangeInputCertificate = (id, event) => {
        if (event.target.name === 'startDate' || event.target.name === 'endDate') {
            if (event.target.value.split('-')[0].length > 4) {
                return;
            }
            
            // years condition
            if((event.target.value.split('-')[0] >= 1000) && (event.target.value.split('-')[0] < 1930))
                return;
        
            if((event.target.value.split('-')[0] > 2050) && (event.target.value.split('-')[0] < 9999))
                return;
        }
        const newInputFields = inputFieldsCertificate.map(i => {
            if (id === i.id) {
                i[event.target.name] = event.target.value
            }
            return i;
        })

        setInputFieldsCertificate(newInputFields);
    }
    const handleChangeInputCertificateCheckBox = (id, event) => {
        var certCurEnd = document.getElementById("certEnd" + event.target.id)
        if (event.target.checked) {
            certCurEnd.style.display = 'none';
        } else {
            certCurEnd.style.display = 'flex';
        }
        const newInputFields = inputFieldsCertificate.map(i => {
            if (id === i.id) {
                i[event.target.name] = event.target.checked
            }
            return i;
        })

        setInputFieldsCertificate(newInputFields);
    }

    const handleAddFieldsCertificate = () => {
        window.scrollTo(0, window.scrollY + 400);

        setInputFieldsCertificate([...inputFieldsCertificate, { id: uuidv4(), certificateName: '', certificateAuthority: '', startDate: '', endDate: '', desc: '', isNotExpired: false }])
        setTimeout(function () {
            window.scrollTo(0, document.body.scrollHeight)
        }, 50)
    }

    const handleRemoveFieldsCertificate = id => {
        window.scrollTo(0, window.scrollY - 400);

        const values = [...inputFieldsCertificate];
        values.splice(values.findIndex(value => value.id === id), 1);
        setInputFieldsCertificate(values);
    }


    const certificateForm = <>
        <div style={{ marginLeft: '2.5em', display: "flex", flexDirection: 'column' }}>
            <Tooltip title='These fields are optional. You can skip these by clicking on "Continue"' placement="right-start" arrow><Typography style={{ fontSize: '19px', fontWeight: '600', width: 'fit-content' }}>Certificate <FaInfoCircle /></Typography></Tooltip>
            <br />
            <form onSubmit={handleSubmitCertificate}>
                {inputFieldsCertificate.map((inputField, idx) => (

                    <Grid container key={inputField.id} item spacing={3} lg={11}>
                        <Grid item lg={6}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>


                                <span className='label'>Certificate Name</span>
                                <TextField size="small"
                                    name='certificateName'
                                    error={activeStep_3.certificateName}
                                    value={inputField.certificateName}
                                    onChange={event => handleChangeInputCertificate(inputField.id, event)}

                                    placeholder='Certificate Name' variant='outlined' />
                            </div>
                        </Grid>
                        <Grid item lg={6}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>


                                <span className='label'>Certificate Authority</span>
                                <TextField name='certificateAuthority'
                                    value={inputField.certificateAuthority}
                                    error={activeStep_3.certificateAuthority}
                                    onChange={event => handleChangeInputCertificate(inputField.id, event)}
                                    size="small" placeholder='Certificate Authority' variant='outlined' />
                            </div>
                        </Grid>

                        <Grid item lg={6}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>


                                <span className='label'>Start date</span>
                                <input
                                    name='startDate'
                                    min="1930-01-01"
                                    style={{
                                        padding: '0.5em',
                                        borderRadius: '5px',
                                        border: '1px solid #e1e1e1'
                                    }}
                                    InputProps={{
                                        inputProps: {
                                          min: "1930-01-01",
                                          max: "2050-01-01"
                                        },
                                    }}
                                    max="2050-12-31"
                                    value={inputField.startDate}
                                    error={activeStep_3.startDate}
                                    onChange={event => handleChangeInputCertificate(inputField.id, event)}
                                    size="small" type="date" placeholder='Start date' variant='outlined' />
                            </div>
                        </Grid>
                        <Grid item lg={6}>
                            <div style={{ display: 'flex', flexDirection: 'column' }} id={"certEnd" + inputField.id}>


                                <span className='label'>End Date</span>
                                <input name='endDate'
                                    min="1930-01-01"
                                    style={{
                                        padding: '0.5em',
                                        borderRadius: '5px',
                                        border: '1px solid #e1e1e1'
                                    }}
                                    max="2050-12-31"
                                    value={inputField.endDate}
                                    InputProps={{
                                        inputProps: {
                                            min: inputField.startDate
                                                ? inputField.startDate
                                                : null,
                                            max: "2050-01-01"
                                        },
                                    }}
                                    onChange={event => handleChangeInputCertificate(inputField.id, event)}
                                    size="small" type="date" placeholder='End date' variant='outlined' />
                            </div>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>


                                <Checkbox
                                    style={{ padding: '0', color: '#0DAC69' }}
                                    name='isNotExpired'
                                    checked={inputField.isNotExpired}
                                    onChange={event => handleChangeInputCertificateCheckBox(inputField.id, event)}
                                    id={inputField.id}
                                />
                                <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>This certificate does not expire</span>
                            </div>
                        </Grid>

                        <Grid item lg={12}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>


                                <span className='label'>Description</span>
                                <TextField
                                    name='desc'
                                    error={activeStep_3.desc}
                                    value={inputField.desc}
                                    onChange={event => {
                                        if (!(event.target.value.length > 255))
                                            handleChangeInputCertificate(inputField.id, event)
                                    }}
                                    multiline={true} rows={4} size="small" variant='outlined' />
                            </div>
                        </Grid>
                        <Grid container justifyContent={inputFieldsCertificate.length == idx + 1 ? 'space-between' : 'flex-end'} item lg={12} md={11} sm={11} xs={11}>

                            {inputFieldsCertificate.length == idx + 1 ? <Button variant='contained' style={{ background: "#fff", borderRadius: '18px', fontWeight: '600', textTransform: 'none', border: '2px solid #0881f4', color: '#0881f4' }} onClick={handleAddFieldsCertificate}>Add Another</Button> : <></>}
                            <Button variant='contained' style={{ background: "#fff", borderRadius: '18px', fontWeight: '600', textTransform: 'none', color: '#909090' }} disabled={inputFieldsCertificate.length === 1} onClick={() => handleRemoveFieldsCertificate(inputField.id)}><RiDeleteBin6Line style={{ height: '2em' }} />&nbsp;Remove</Button>


                        </Grid>
                        <hr style={{ width: '100%' }}></hr>
                        <br />
                    </Grid>
                ))}
            </form>



        </div>

    </>;

    const Joboptions = [
        { value: 'Maths Teacher', label: 'Maths Teacher' },
        { value: 'English Teacher', label: 'English Teacher' },
        { value: 'Assistant Teacher', label: 'Assistant Teacher' },
        { value: 'Class Teacher', label: 'Class Teacher' },
        { value: 'History Teacher', label: 'History Teacher' },
    ];

    const Locoptions = [
        { value: 'Alabama', label: 'Alabama' },
        { value: 'Alaska', label: 'Alaska' },
        { value: 'American Samoa', label: 'American Samoa' },
        { value: 'Arizona', label: 'Arizona' },
        { value: 'Arkansas', label: 'Arkansas' },
        { value: 'California', label: 'California' },
        { value: 'Colorado', label: 'Colorado' },
        { value: 'Connecticut', label: 'Connecticut' },
        { value: 'Delaware', label: 'Delaware' },
        { value: 'District Of Columbia', label: 'District Of Columbia' },
        { value: 'Federated States Of Micronesia', label: 'Federated States Of Micronesia' },
        { value: 'Florida', label: 'Florida' },
        { value: 'Georgia', label: 'Georgia' },
        { value: 'Guam', label: 'Guam' },
        { value: 'Hawaii', label: 'Hawaii' },
        { value: 'Idaho', label: 'Idaho' },
        { value: 'Illinois', label: 'Illinois' },
        { value: 'Indiana', label: 'Indiana' },
        { value: 'Iowa', label: 'Iowa' },
        { value: 'Kansas', label: 'Kansas' },
        { value: 'Kentucky', label: 'Kentucky' },
        { value: 'Louisiana', label: 'Louisiana' },
        { value: 'Maine', label: 'Maine' },
        { value: 'Marshall Islands', label: 'Marshall Islands' },
        { value: 'Maryland', label: 'Maryland' },
        { value: 'Massachusetts', label: 'Massachusetts' },
        { value: 'Michigan', label: 'Michigan' },
        { value: 'Minnesota', label: 'Minnesota' },
        { value: 'Mississippi', label: 'Mississippi' },
        { value: 'Missouri', label: 'Missouri' },
        { value: 'Montana', label: 'Montana' },
        { value: 'Nebraska', label: 'Nebraska' },
        { value: 'Nevada', label: 'Nevada' },
        { value: 'New Hampshire', label: 'New Hampshire' },
        { value: 'New Jersey', label: 'New Jersey' },
        { value: 'New Mexico', label: 'New Mexico' },
        { value: 'New York', label: 'New York' },
        { value: 'North Carolina', label: 'North Carolina' },
        { value: 'North Dakota', label: 'North Dakota' },
        { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
        { value: 'Ohio', label: 'Ohio' },
        { value: 'Oklahoma', label: 'Oklahoma' },
        { value: 'Oregon', label: 'Oregon' },
        { value: 'Palau', label: 'Palau' },
        { value: 'Pennsylvania', label: 'Pennsylvania' },
        { value: 'Puerto Rico', label: 'Puerto Rico' },
        { value: 'Rhode Island', label: 'Rhode Island' },
        { value: 'South Carolina', label: 'South Carolina' },
        { value: 'South Dakota', label: 'South Dakota' },
        { value: 'Tennessee', label: 'Tennessee' },
        { value: 'Texas', label: 'Texas' },
        { value: 'Utah', label: 'Utah' },
        { value: 'Vermont', label: 'Vermont' },
        { value: 'Virgin Islands', label: 'Virgin Islands' },
        { value: 'Virginia', label: 'Virginia' },
        { value: 'Washington', label: 'Washington' },
        { value: 'West Virginia', label: 'West Virginia' },
        { value: 'Wisconsin', label: 'Wisconsin' },
        { value: 'Wyoming', label: 'Wyoming' },
    ];

    const checkNullValue = (value) => {
        if (value === '' || value === null || value === undefined)
            return true;
        return false;
    }

    const [activeStep0, setactiveStep0] = useState({
        phNumber: false,
        street: false,
        city: false,
        state: false,
        zip: false
    })



    function clickContinue() {
        let noAhead = false;
        if (activeStep === 0) {
            setEmail(document.getElementById('emailField').value);

            if (phNumber === '' || street === '' || city === '' || state === '' || zip === '' || email === '' || phNumber === null || phNumber?.length < 10 || street === null || city === null || state === null || zip === null || email === null || phNumber === undefined || street === undefined || city === undefined || state === undefined || zip === undefined || email === undefined) {
                setactiveStep0({
                    phNumber: (checkNullValue(phNumber) || phNumber?.length != 10) ? true : false,
                    street: checkNullValue(street) ? true : false,
                    city: checkNullValue(city) ? true : false,
                    state: checkNullValue(state) ? true : false,
                    zip: checkNullValue(zip) ? true : false
                })
                console.log(checkNullValue(phNumber), phNumber?.length != 10, activeStep0.phNumber);
                return;
            }
        }
        else if (activeStep === 1) {
            inputFieldsEducation.forEach((e) => {

                if ((e.school !== '' && e.degree !== '' && e.desc !== '' && e.startDate !== '') || (e.school === '' && e.degree === '' && e.desc === '' && e.startDate === '')) {
                    setactiveStep_1({
                        school: checkNullValue(e.school) ? true : false,
                        degree: checkNullValue(e.degree) ? true : false,
                        desc: checkNullValue(e.desc) ? true : false,
                        startDate: checkNullValue(e.startDate) ? true : false
                    })

                } else {
                    setactiveStep_1({
                        school: e.school.length === 0 ? true : false,
                        degree: e.degree.length === 0 ? true : false,
                        desc: e.desc.length === 0 ? true : false,
                        startDate: e.startDate.length === 0 ? true : false
                    })
                    // console.log(e)
                    // alert('Please enter the required Information.')
                    noAhead = true;

                    return
                }

            })
        }
        else if (activeStep === 2) {
            inputFieldsWork.forEach((e) => {

                if ((e.school !== '' && e.position !== '' && e.desc !== '' && e.startDate !== '') || (e.school === '' && e.position === '' && e.desc === '' && e.startDate === '')) {
                    setactiveStep_2({
                        school: checkNullValue(e.school) ? true : false,
                        position: checkNullValue(e.position) ? true : false,
                        desc: checkNullValue(e.desc) ? true : false,
                        startDate: checkNullValue(e.startDate) ? true : false
                    })
                } else {
                    setactiveStep_2({
                        school: e.school.length === 0 ? true : false,
                        degree: e.degree.length === 0 ? true : false,
                        desc: e.desc.length === 0 ? true : false,
                        startDate: e.startDate.length === 0 ? true : false
                    })
                    // console.log(e)
                    // alert('Please enter the required Information.')
                    noAhead = true;
                    return
                }

            })
        }
        else if (activeStep === 3) {
            inputFieldsCertificate.forEach((e) => {
                if ((e.certificateName !== '' && e.certificateAuthority !== '' && e.desc !== '' && e.startDate !== '') || (e.certificateName === '' && e.certificateAuthority === '' && e.desc === '' && e.startDate === '')) {
                    setactiveStep_3({
                        certificateName: checkNullValue(e.certificateName) ? true : false,
                        certificateAuthority: checkNullValue(e.certificateAuthority) ? true : false,
                        desc: checkNullValue(e.desc) ? true : false,
                        startDate: checkNullValue(e.startDate) ? true : false
                    })
                } else {
                    setactiveStep_3({
                        certificateName: e.certificateName.length === 0 ? true : false,
                        certificateAuthority: e.certificateAuthority.length === 0 ? true : false,
                        desc: e.desc.length === 0 ? true : false,
                        startDate: e.startDate.length === 0 ? true : false
                    })
                    // console.log(e)
                    // alert('Please enter the required Information.')
                    noAhead = true;
                    return
                }

            })
        }
        setIsBack(false);
        if (activeStep >= 4) {
            setNextDisabled(true);
            handleSubmit2();
        } else if (activeStep === 3) {
            handleSubmit1()
        }
        if (noAhead === false) {
            setActiveStep(activeStep + 1);
        }
    }

    const [minSal, setMinSal] = useState('')

    const handleSubmit2 = () => {

        const pjt = []

        for (let i = 0; i < selectedJobOption.length; i++) {
            pjt.push(selectedJobOption[i].value)
        }

        const pjl = []

        for (let i = 0; i < selectedLocOption.length; i++) {
            pjl.push(selectedLocOption[i].value)
        }

        let checkBox1 = document.getElementById('Full-Time')
        let checkBox2 = document.getElementById('Part-Time')
        let checkBox3 = document.getElementById('Remote-Only')

        let jt = []

        if (checkBox1.checked) { jt.push('Full Time') }
        if (checkBox2.checked) { jt.push('Part Time') }
        if (checkBox3.checked) { jt.push('Remote Only') }

        const data = {
            // access: accessToken,
            preffered_job_titles: pjt,
            preffered_job_locations: pjl,
            job_type: jt,
            minimum_salary: minSal,
            notify_about_nearby_jobs : false
            // notify_about_nearby_jobs: document.getElementById('notify_about_nearby_jobs').checked, //to make dynamic
        }

        axiosInstance.post('/seeker/onboarding_2/', data).then((res) => {
            const data = res.data;
            console.log(data);


        }).catch((err) => {
            if (err)
                console.log(err.data)
        });
    }

    const preferenceForm = <>
        <div style={{ marginLeft: '2.5em', marginRight: "2.5em", display: "flex", flexDirection: 'column' }}>
            <div style={{ textAlign: 'center' }}>
                <Typography style={{ fontSize: '19px', fontWeight: '600', margin: '0 5%' }}>One last step, set up your job preferences</Typography>

                <Typography className="perferencestyle" style={width < 420 ? { fontSize: '14px', color: '#6C6866', margin: '10px 8%' } : { fontSize: '14px', color: '#6C6866', margin: '10px 15%' }}>Let’s select your job prefernce so we can better help you find what you truely need</Typography>
            </div>
            <br />
            <Grid item container spacing={3} lg={11}>
                <Grid item lg={12} xs={10}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>


                        <span className='label'>Job Title</span>
                        <Select
                            isMulti
                            value={selectedJobOption}
                            onChange={
                                (selectedJobOption) => {
                                    if (selectedJobOption.length <= 3)
                                        setSelectedJobOption(selectedJobOption)
                                    else {
                                        // alert('You can select only 3 job titles');
                                        setAlert(true);
                                        setType("warning");
                                        setDesc("You can select only 3 job titles!")
                                    }

                                }}
                            options={jobTitlesArr}
                        />
                        {/* <TextField size="small" placeholder='Certificate Authority' variant='outlined' /> */}
                    </div>
                </Grid>
                <Grid item lg={12} xs={10}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>


                        <span className='label'>Location</span>
                        <Select
                            isMulti
                            value={selectedLocOption}
                            onChange={(selectedLocOption) => {
                                if (selectedLocOption.length <= 3)
                                    setSelectedLocOption(selectedLocOption)
                                else {
                                    setAlert(true);
                                    setType("warning");
                                    setDesc("You can select only 3 job locations!")
                                }
                            }}
                            options={Locoptions}
                        />
                    </div>
                </Grid>


                <Grid item style={{ paddingBottom: '0px', marginBottom: '0px' }} lg={12}>
                    <span className='col-12 ml-5 label'><b>I am Open to</b></span>
                </Grid>
                <Grid item lg={4} sm={12}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>


                        <Checkbox
                            style={{ padding: '0', color: '#0DAC69' }} id='Full-Time'


                        />
                        <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Full-time</span>
                    </div>
                </Grid>
                <Grid item lg={4} xs={12}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>


                        <Checkbox
                            style={{ padding: '0', color: '#0DAC69' }} id='Part-Time'


                        />
                        <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Part-time</span>
                    </div>
                </Grid>
                <Grid item lg={4} xs={12}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>


                        <Checkbox
                            style={{ padding: '0', color: '#0DAC69' }} id='Remote-Only'


                        />
                        <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Remote only</span>
                    </div>
                </Grid>

                <Grid item lg={5}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>


                        <FormControl variant="outlined" size="small">
                            <select value={minSal} name='age' id='outlined-age-native-simple' onChange={(e) => { setMinSal(e.target.value) }}>
                                <option value="" disabled selected >
                                    Hourly Salary
                                </option>
                                <option value="0-19">$ ($0-$19)</option>
                                <option value="20-39">$$ ($20-$39)</option>
                                <option value="40-59">$$$ ($40-$59)</option>
                                <option value="60-79">$$$$ ($60-$79)</option>
                                <option value="80">$$$$$ ({'>'}$79)</option>
                            </select>
                        </FormControl>
                    </div>
                </Grid>
                {/* <Grid item lg={10}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>


                        <Checkbox
                            style={{ padding: '0', color: '#FF4642' }} id='notify_about_nearby_jobs'


                        />
                        <span className='label' style={{ color: '#0F172A', marginLeft: '10px', marginRight: '15px' }}>Notify me when nearby job posts are available</span>
                    </div>
                </Grid> */}




            </Grid>





        </div>

    </>;

    const [phNumber, setPhNumber] = useState()
    const [street, setStreet] = useState()
    const [city, setCity] = useState()
    const [state, setState] = useState()
    const [zip, setZip] = useState()
    const [email, setEmail] = useState()

    const contactForm = <>

        <div style={{ marginLeft: '2.5em', display: "flex", flexDirection: 'column' }}>
            <Typography style={{ fontSize: '19px', fontWeight: '600' }}>Contact info</Typography>
            <br />
            <Grid container item spacing={3} lg={11}>
                <Grid item lg={6}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>


                        <span className='label'>Your email<span style={{ color: 'red' }}>*</span></span>
                        <TextField size="small" disabled placeholder='abc@gmail.com' variant='outlined' onChange={(e) => setEmail(e.target.value)} value={email} type='email' required={true} id='emailField' />
                    </div>
                </Grid>
                <Grid item lg={6}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                        <Tooltip title='Please enter a valid phone number without area code or any special characters' placement="right-start">
                            <div className='label'>Phone number<span style={{ color: 'red' }}>*</span> <FaInfoCircle /></div></Tooltip>
                        <Tooltip title='Please enter a valid phone number without area code or any special characters' placement="right-start">
                            <TextField size="small"
                                helperText={activeStep0.phNumber ? 'Phone Number must be atleast 10 numbers' : ' '}
                                error={activeStep0.phNumber} placeholder='Enter your phone number' type='number' variant='outlined' onChange={(e) => {
                                    if ((e.target.value == '' || e.target.value >= 0) && e.target.value.length <= 10)
                                        setPhNumber(e.target.value)
                                }} value={phNumber} id="phNumber" required={true} /></Tooltip>
                    </div>
                </Grid>
            </Grid>
            <br />
            <br />
            <Typography style={{ fontSize: '19px', fontWeight: '600' }}>Address info</Typography>
            <br />
            <Grid container item spacing={3} lg={11}>
                <Grid item lg={6}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>


                        <span className='label'>Street<span style={{ color: 'red' }}>*</span></span>
                        <TextField size="small" error={activeStep0.street} placeholder='Enter your street name' onChange={(e) => setStreet(e.target.value)} value={street} id="street" variant='outlined' />
                    </div>
                </Grid>
                <Grid item lg={6}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>


                        <span className='label'>City/Suburb<span style={{ color: 'red' }}>*</span></span>
                        <TextField size="small" error={activeStep0.city} placeholder='Enter your city/suburb' variant='outlined' onChange={(e) => setCity(e.target.value)} value={city} id="city" />
                    </div>
                </Grid>
            </Grid>
            <Grid container item spacing={3} lg={11}>
                <Grid item lg={6} style={{ width: '250px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>


                        <span className='label'>State<span style={{ color: 'red' }}>*</span></span>
                        <Select
                            onChange={newState => setState(newState.value)}
                            options={Locoptions}
                            id="state"
                            className={activeStep0.state ? "incorrectInput" : null}
                        />
                        {/* <TextField size="small" placeholder='Enter your state name' variant='outlined' onChange={(e) => setState(e.target.value)} value={state} /> */}
                    </div>
                </Grid>
                <Grid item lg={6}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>


                        <span className='label'>Zip/Post code<span style={{ color: 'red' }}>*</span></span>
                        <TextField size="small" error={activeStep0.zip} placeholder='Enter your zip code' variant='outlined' onChange={(e) => {
                            if (e.target.value == '' || e.target.value > 0)
                                setZip(e.target.value)
                        }} value={zip} id="zip" type='number' />
                    </div>
                </Grid>



            </Grid>





        </div>

    </>;


    const renderSwitch = (param) => {
        switch (param) {
            case 0:
                return contactForm;
            case 1:
                return educationForm;
            case 2:
                return workForm;
            case 3:
                return certificateForm;
            case 4:
                return preferenceForm;


        }
    }

    const FormPage = <>
        <br />
        {renderSwitch(activeStep)}
        <br />
        <br />
        <br />
        <div style={{ float: 'right' }}>
            {isBack ? <></> : <Button


                onClick={() => {
                    if (activeStep == 1) {
                        setIsBack(true);
                    }
                    if (activeStep >= -1) {
                        setNextDisabled(false);
                        setActiveStep(activeStep - 1);
                    }
                }}
                style={{ textTransform: 'none', border: '1px solid #808080', fontSize: "14px", margin: "15px 15px", width: '6em', fontFamily: 'Montserrat', background: '#FFF', color: '#808080', fontWeight: '600', borderRadius: '20px', padding: '7px 10px' }} variant="contained" >Back</Button>

            }


            <Button
                disabled={nextDisabled} onClick={clickContinue}
                style={{ textTransform: 'none', fontSize: "14px", margin: "15px 15px", width: '8em', fontFamily: 'Montserrat', background: '#EB881B', color: '#FFF', fontWeight: '600', borderRadius: '20px', padding: '7px 10px' }} variant="contained" >{activeStep >= 4 ? 'Finish' : 'Continue'}</Button>
        </div>
    </>



    return (



        <div>
            <Helmet>
                <title>Frame Change</title>
            </Helmet>
            {width > 1220 ? <img style={{ margin: '43em 2em 5em 16em', position: "absolute", zIndex: 1 }} src={DotDesign} /> : <div></div>}

            <div>
                <Grid container alignItems='center' direction='column' className='mainCont'>

                    {activeStep < 4 ? <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '5%' }}>
                        <img src={Logo} alt='Logo' />
                        <br />
                        <span style={{ fontWeight: '600', fontSize: '19px' }}>Complete Personal Information</span><br />
                        <span style={{ fontWeight: '400', fontSize: '14px', padding: '0 5px', textAlign: 'center', color: '#7C6C66' }}>A completed profile increase your chances of getting hired by 10 times </span>
                        <br />
                        <Stepper
                            // maxWidth: '45em', width: '40%', minWidth: '20em',
                            style={{ fontFamily: 'Montserrat', padding: '24px 0 0 0' }}
                            steps={[{ label: 'Basic info' }, { label: 'Education' }, { label: 'Experience' }, { label: 'Certificate' }]}
                            activeStep={activeStep}

                            connectorStateColors
                            connectorStyleConfig={{ activeColor: '#F9A182', completedColor: '#F9A182' }}
                            styleConfig={{ activeBgColor: '#F9A182', completedBgColor: '#F9A182', inactiveBgColor: '#E1D1C7', labelFontSize: '15px', fontWeight: '500' }}
                        />
                    </div> : <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '5%' }}>
                        <img style={{ borderRadius: '50%' }} src={`https://ui-avatars.com/api/?name=${localStorage.getItem('name')}`} alt='profile' />
                        <br />
                        <span style={{ fontWeight: '600', fontSize: '19px' }}>{
                            !(localStorage.getItem('name') === '' || localStorage.getItem('name') === 'null') ? localStorage.getItem('name') : null
                        }</span>
                        <span style={{ fontWeight: '400', fontSize: '14px', marginTop: "6px", padding: '0 5px', textAlign: 'center', color: '#7C6C66' }}>{
                            !(localStorage.getItem('email') === '' || localStorage.getItem('email') === 'null') ? localStorage.getItem('email') : null
                        }</span>
                        <span style={{ fontWeight: '400', fontSize: '14px', marginTop: "6px", padding: '0 5px', textAlign: 'center', color: '#7C6C66' }}>{
                            !(localStorage.getItem('phone') === '' || localStorage.getItem('phone') === 'null') ? localStorage.getItem('phone') : null
                        }</span>



                    </div>}


                    {alertComp ? (
                        <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlert} />
                    ) : null}
                    <div style={{ zIndex: 2, display: 'flex', justifyContent: 'center', margin: '0 10px' }}>

                        <div style={{ width: '95%', maxWidth: '45em', margin: '8% 3%', backgroundColor: '#FFF', borderRadius: '10px', boxShadow: '0 1px 9px 2px rgb(51 51 51 / 30%)' }}>


                            {/* <br />
                    {renderSwitch(activeStep)}
                    <br />
                    <br />
                    <br />
                    <div style={{ float: 'right' }}>
                        {isBack ? <></> : <Button


                            onClick={() => {
                                if (activeStep == 1) {
                                    setIsBack(true);
                                }
                                if (activeStep >= -1) {
                                    setNextDisabled(false);
                                    setActiveStep(activeStep - 1);
                                }
                            }}
                            style={{ textTransform: 'none', border: '1px solid #808080', fontSize: "14px", margin: "15px 15px", width: '6em', fontFamily: 'Montserrat', background: '#FFF', color: '#808080', fontWeight: '600', borderRadius: '20px', padding: '7px 10px' }} variant="contained" >Back</Button>

                        }

                        <Button
                            disabled={nextDisabled} onClick={() => {
                                setIsBack(false);
                                // console.log(activeStep);
                                if (activeStep >= 4)
                                    setNextDisabled(true);
                                setActiveStep(activeStep + 1);


                            }}
                            style={{ textTransform: 'none', fontSize: "14px", margin: "15px 15px", width: '8em', fontFamily: 'Montserrat', background: '#EB881B', color: '#FFF', fontWeight: '600', borderRadius: '20px', padding: '7px 10px' }} variant="contained" >{activeStep >= 4 ? 'Finish' : 'Continue'}</Button>
                    </div> */}


                            {activeStep == 5 ? <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', margin: "2em" }}>
                                <img style={{ width: '100%' }} src={Success} alt="success" />
                                <Typography style={{ fontSize: "23px", fontWeight: '600' }}>Your account is successfully created</Typography>
                                <Typography style={{ fontSize: "16px", fontWeight: '400' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis</Typography>
                                <br />
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <Button
                                        style={{ textTransform: 'none', fontSize: "14px", margin: "15px 15px", fontFamily: 'Montserrat', background: '#fff', color: '#EB881B', border: '2px solid #EB881B', fontWeight: '600', borderRadius: '20px', }} variant="contained" onClick={() => { window.location = '/search-jobs' }}>Start exploring</Button>

                                    <Button
                                        style={{ textTransform: 'none', fontSize: "14px", margin: "15px 15px", fontFamily: 'Montserrat', background: '#EB881B', color: '#FFF', fontWeight: '600', borderRadius: '20px', }} variant="contained" onClick={() => { window.location = '/dashboard' }}>Go to Dashboard</Button>

                                </div>

                            </div>
                                : FormPage}

                        </div>
                    </div>
                </Grid>
            </div>
        </div>
    );
}

export default OnBoarding;
