import React, { useState, useEffect } from 'react';
import NavBar_noProfile from '../NavBar_noProfile';
import Footer from '../Footer';
import { Tab, Tabs } from '@material-ui/core';
import { privacyContent, termsContent, cookieContent, faqsamples } from './content';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';


function PrivacyPolicy() {
    const [value, setValue] = React.useState();
    
  let history = useHistory();

    useEffect(() => {
        // setValue("privacy-policy")
        let urlPath = history.location.pathname;
        let pathName = urlPath.substring(1);
        setValue(pathName)
        
    }, [history.location.pathname])
    


    const handleChange = (event, newValue) => {
        setValue(newValue);
        setTimeout(() => { window.scrollTo(0, document.body.scrollTop) }, 50);

    };

    const renderSwitch = (param) => {
        switch (param) {
            case "terms-conditions":
                return termsContent;
            case "privacy-policy":
                return privacyContent;
            case "cookies":
                return cookieContent;
            case "faq":
                return faqsamples;
        }
    }

    return (
        <div>
            <Helmet>
                <title>Policies | Frame Change</title>
            </Helmet>
            <NavBar_noProfile />
            <br /><br /><br /><br />
            <div style={{ width: '100%', justifyContent: 'center', paddingLeft: '100px' }}>

                <Tabs
                    variant="scrollable"
                    scrollButtons={false}
                    allowScrollButtonsMobile
                    value={value}
                    onChange={handleChange}
                    textColor="inherit"
                    TabIndicatorProps={{ style: { background: 'orange' } }}
                    // initialSelectedIndex={'privacy-policy'}

                >
                    <Tab style={{ textTransform: 'capitalize' }} value="privacy-policy" label="Privacy Policy" />
                    <Tab style={{ textTransform: 'capitalize' }} value="terms-conditions" label="Terms & Conditions" />
                    <Tab style={{ textTransform: 'capitalize' }} value="cookies" label="Cookies" />
                    <Tab style={{ textTransform: 'capitalize' }} value="faq" label="FAQ" />
                </Tabs>
            </div>
            {renderSwitch(value)}
            <Footer />
        </div>
    )
}

export default PrivacyPolicy;