import { CircularProgress, Grid, Tab, Tabs, TextareaAutosize, TextField, Typography } from '@material-ui/core';

import React from 'react';
import Container from 'react-bootstrap/Container';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import postIcon from "./Vector (1).png"
import srch from "./searchicon.svg"
import "react-multi-carousel/lib/styles.css";
import licenseLogo from "./teacherLicence.svg"
import workExperienceLogo from "./experience.png"
import NavBar from '../NavBar';
import Footer from '../Footer';
import { Button, createTheme, Divider, FormControl, FormControlLabel, Modal, Radio, RadioGroup, Dialog, DialogContent, DialogActions, Stack } from '@mui/material';
import connection1 from "./connection1.png"
import './styles.css';
import "./Requests.css"
import axiosInstance from '../../axios';
import Alert from "../alerts/alert"
import deleteIcon from "./delete.svg"
import DeleteIcon from "./deleteIcon.svg"
import editIcon from "./edit.svg"
// import { ProfileTags } from '../Posts/OpportunitiesHome';




// get window dimension 

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}


const styles = {
  searchBoxBig: {
    fontSize: '14px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    background: '#fff',
    border: '1px solid #BEBEBE',
    borderRadius: '25px',
    paddingLeft: '2vh',
    paddingRight: '2vh',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  searchBoxSmall: {
    fontSize: '14px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    background: '#fff',
    border: '1px solid #BEBEBE',
    borderRadius: '25px',
    paddingLeft: '1em',
    paddingRight: '1em',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  srchField: {
    border: "none",
    borderColor: "transparent",
    outline: "none",
    width: "100%",
    // padding:"0px 12px",
    // margin: "10px 0px"
  },
  hiddenLabel: {
    height: 0,
    visibility: "hidden",
  },
};



// job opportunity modal 

const OpportunityForm = (props) => {
  const { height, width } = useWindowDimensions()
  const [textEditorData, setTextEditorData] = useState('')
  const [radioValue, setRadioValue] = useState(false)
  const [opportunityPreference, setopportunityPreference] = useState({
    writing: false,
    speaking_on_clubhouse: false,
    mentoring: false,
    content_creation: false,
  })

  //   const save = (data) => {
  //   console.log(data);
  // };

  const myTheme = createTheme({
    // Set up your custom MUI theme here
  })

  return (
    <div>
      <div
        style={{
          marginLeft: '2.5em',
          marginRight: '2.5em',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div>
          <Typography
            style={{ fontSize: '19px', fontWeight: '700', margin: '0 ' }}
          >
            Post an Opportunity
          </Typography>

          <Typography
            className='perferencestyle'
            style={
              width < 420
                ? { fontSize: '14px', color: '#6C6866', margin: '10px 0' }
                : { fontSize: '14px', color: '#6C6866', margin: '10px 0' }
            }
          >
            I am open to receiving requests.
            This will show your contact button on your profile.
          </Typography>
        </div>
        <div >
          <Divider style={{ marginTop: "15px ", marginBottom: "20px" }} />
        </div>
        <div>
          <div
            style={{ marginBottom: '24px' }}
          >
            <Typography
              style={{ fontSize: '17px', fontWeight: '700', }}
            >
              Title
            </Typography>
            <Typography
              variant='p'
              style={{ marginBottom: "24px" }}
            >
              Write a title for the opportunity.
            </Typography>
          </div>
          <TextField
            size='small'
            name='degree'
            placeholder='ex: Bachelors'
            variant='outlined'
            // style={{width:'100%', marginTop:'10px'}} 
            className="descInput"
            // value={aboutmeText}
            // onChange={(e) => {
            //   setaboutmeText(e.target.value);
            //   checkCharacterLimit(e.target.value);
            // }}
            inputProps={{ maxLength: 500 }}
          />
          <div
            style={{ marginTop: '30px' }}
          > <Typography
            style={{ fontSize: '17px', fontWeight: '700', }}
          >
              Description
            </Typography>
            <Typography
              variant='p'
              style={{ marginBottom: "24px" }}
            >
              Write a detailed description about this opportunity.
            </Typography>
          </div>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={6}

            // style={{ width: 200 }}
            className="descInput"
          />
        </div>
        <div style={{ marginBottom: "24px" }}>
          <Typography
            style={{ fontSize: '17px', fontWeight: '700', margin: '0 ' }}
          >
            Compensataion
          </Typography>
          <Typography
            variant='p'
            style={{ marginBottom: "24px" }}
          >
            Is it a paid opportunity?
          </Typography>

          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexWrap: "wrap" }}>
            <FormControl>
              {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel value="unpaid" onChange={() => setRadioValue(false)} style={{ marginRight: "100px" }} control={<Radio />} label="Unpaid" />
                <FormControlLabel value="paid" onChange={() => setRadioValue(true)} control={<Radio />} label="Paid" />

              </RadioGroup>
            </FormControl>
            {
              radioValue &&
              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} className="priceInput">
                <TextField
                  size='small'
                  name='degree'
                  placeholder='Enter amount'
                  variant='outlined'
                  style={{ display: "block", marginBottom: "-5px", padding: "0 6px" }}
                  // className="descInput"
                  // value={aboutmeText}
                  // onChange={(e) => {
                  //   setaboutmeText(e.target.value);
                  //   checkCharacterLimit(e.target.value);
                  // }}
                  inputProps={{ maxLength: 500 }}
                />
                <select name="salary_filter" id="salary_filter" style={{ display: "block", padding: "13px 10px" }}
                //  value={filtersApplied.salary_filter} onChange={(e) => {
                //   setfiltersApplied({ ...filtersApplied, salary_filter: e.target.value })}}
                >
                  <option value="" disabled selected >
                    Hourly Salary
                  </option>
                  <option value="0-19">$ ($0-$19)</option>
                  <option value="20-39">$$ ($20-$39)</option>
                  <option value="40-59">$$$ ($40-$59)</option>
                  <option value="60-79">$$$$ ($60-$79)</option>
                  <option value="80">$$$$$ ({'>'}$79)</option>
                </select>
              </div>
            }

          </div>

        </div>
        <br />
        {/* <div style={{height: '150px'}}>
            <ThemeProvider theme={myTheme}>
              <MUIRichTextEditor
                label="Type something here..."
                onSave={setTextEditorData}
                inlineToolbar={true}
            controls={ [
                  "title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo"
            ] }
              />
            </ThemeProvider>
          </div> */}
        <div style={{ marginBottom: "24px" }}>
          <Typography
            style={{ fontSize: '17px', fontWeight: '700', margin: '0 ' }}
          >
            Cateagory
          </Typography>
          <Typography
            variant='p'
            style={{ marginBottom: "24px" }}
          >
            Select category this opportunity fits in.
          </Typography>
        </div>
        <div className='optionCont2'>
          <div className='option2'>
            <input
              type='checkbox'
              name=''
              checked={opportunityPreference.writing}
              onChange={(e) =>
                setopportunityPreference({
                  ...opportunityPreference,
                  writing: !opportunityPreference.writing,
                })
              }
              id=''
            />
            <div>
              <div className='optionTitle'>Writing</div>
              <div className='optionSubtext'>
                Contributing articles or blogs on certain topics
              </div>
            </div>
          </div>
          <div className='option2'>
            <input
              type='checkbox'
              checked={opportunityPreference.speaking_on_clubhouse}
              onChange={(e) =>
                setopportunityPreference({
                  ...opportunityPreference,
                  speaking_on_clubhouse:
                    !opportunityPreference.speaking_on_clubhouse,
                })
              }
              name=''
              id=''
            />
            <div>
              <div className='optionTitle'>Speaking on ClubHouse</div>
              <div className='optionSubtext'>
                Contributing articles or blogs on certain topics
              </div>
            </div>
          </div>
          <div className='option2'>
            <input
              type='checkbox'
              checked={opportunityPreference.mentoring}
              onChange={(e) =>
                setopportunityPreference({
                  ...opportunityPreference,
                  mentoring: !opportunityPreference.mentoring,
                })
              }
              name=''
              id=''
            />
            <div>
              <div className='optionTitle'>Mentoring</div>
              <div className='optionSubtext'>
                Contributing articles or blogs on certain topics
              </div>
            </div>
          </div>
          <div className='option2'>
            <input
              type='checkbox'
              name=''
              id=''
              checked={opportunityPreference.content_creation}
              onChange={(e) =>
                setopportunityPreference({
                  ...opportunityPreference,
                  content_creation: !opportunityPreference.content_creation,
                })
              }
            />
            <div >
              <div className='optionTitle'>Content Creation</div>
              <div className='optionSubtext'>
                Contributing articles or blogs on certain topics
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='btnProfileModalCont'>
        <button className='btnContCancelBtn' onClick={props.closeModal}>
          Cancel
        </button>
        <button
          className='btnContSaveBtn'
        // onClick={handleOpportunityPreference}
        >
          Save
        </button>
      </div>
    </div>
  )
}

// small search bar 

const SearchBarSmall = () => {

  let history = useHistory();
  const submitQuery = () => {
    // let history = useHistory();

    const jobTitle = document.getElementById('jobTitleIDsm');
    const location = document.getElementById('locationIDsm');

    // console.log(jobTitle.value, location.value);

    localStorage.setItem('candidateKeyword', jobTitle.value);
    localStorage.setItem('candidateLocation', location.value);

    // history.push('/search-candidate');

  }

  return (
    <Container>
      <div className='navbarSearch' style={{
        // padding: '0em 1em',
        maxWidth: '900px'
      }}>
        <Grid container direction='row'
        //  spacing={1}
        >
          <Grid item xs={12}>
            <Grid container direction='column'
            // spacing={1}
            >
              <Grid item xs={12}>
                <div className="searchBoxSmall" style={styles.searchBoxSmall}>
                  <Grid container direction='row'
                  // spacing={1}
                  >
                    <Grid item xs={'auto'}>
                      <img src={srch} alt="Search by Title" />
                    </Grid>
                    <Grid item xs={'auto'}>
                      <input type="text" id='jobTitleIDsm' placeholder="Search by Job Title" style={styles.srchField} />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* <Grid item xs={12}>
                  <div className="searchBoxSmall" style={styles.searchBoxSmall}>
                    <Grid container direction='row' spacing={1}>
                      <Grid item xs={'auto'}>
                        <img src={pin} alt="Search by Location" />
                      </Grid>
                      <Grid item xs={'auto'}>
                        <input type="text" id='locationIDsm' placeholder="Location or Zip Code" style={styles.srchField} />
                      </Grid>
                    </Grid>
                  </div>
                </Grid> */}
              {/* <Grid item xs={12}>
                  <Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', color: '#FFF', fontWeight: '600', borderRadius: '25px', padding: '10px', minWidth: '100%' }} variant="contained" >Search</Button>
                </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}


// const Opportunities = () => {
//   return (
//     <div
//       style={{ margin: "0", backgroundColor: "white", borderRadius: "10px", padding: "35p 25px" }}
//     //  key={index}
//     >

//       <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "0 10px", padding: "12px 15px" }}>

//         <div style={{
//           display: "flex",
//           // justifyContent:"space-between",
//           alignItems: "center",
//         }}>
//           <img
//             src={connection1}
//             alt="connection" style={{ width: "50px", height: "50px", paddingLeft: "10px", paddingRight: '10px' }} />
//           <div>
//             {/* <Typography variant="h6" className='personName'>Krishna Barbe</Typography> */}
//             <h6 style={{ marginBottom: "0px", fontWeight: "600" }} className='personName'>Krishna Barbe</h6>
//             <p style={{ marginBottom: "0px" }}>45 mins ago</p>
//             {/* <Typography variant="p">45 mins ago</Typography> */}
//           </div>
//         </div>
//         <div>
//           <select name="salary_filter" id="salary_filter" style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'white', color: '#0667C3', fontWeight: '600', borderRadius: '25px', padding: '10px 21px', minWidth: '100%', margin: "10px 10px" }} variant="outlined"
//           //  value={filtersApplied.salary_filter}
//           //   onChange={(e)=>{
//           //     setfiltersApplied({...filtersApplied, salary_filter : e.target.value})
//           // }}
//           >
//             <option value="" disabled selected >
//               Contact
//             </option>
//             <option
//             //  value="0-19"
//             >Reach us</option>
//             <option
//             //  value="20-39"
//             >Reach us</option>
//             <option
//             //  value="40-59"
//             >Reach us</option>
//             <option
//             //  value="60-79"
//             >Reach us</option>
//             <option
//             //  value="80"
//             >Reach us</option>
//           </select>
//         </div>

//       </div>

//       <Grid
//         container
//         direction="row"
//         style={{ padding: "0 25px", marginTop: "20px" }}
//         // justifyContent="space-between"
//         alignItems="center"
//         wrap="wrap"
//         spacing={1}

//       >
//         <Grid item > <Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'white', color: 'black', fontWeight: '600', borderRadius: '25px', padding: '5px 10px', minWidth: '100%', whiteSpace: "nowrap" }} variant="outlined" ><img src={licenseLogo} style={{ padding: "0px 4px" }} alt="" /> Teacher's License</Button></Grid>

//         <Grid item> <Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'white', color: 'black', fontWeight: '600', borderRadius: '25px', padding: '5px 10px', minWidth: '100%', whiteSpace: "nowrap" }} variant="outlined" ><img src={workExperienceLogo} style={{ padding: "0px 4px" }} alt="" /> 5 years work experience</Button></Grid>

//         <Grid item> <Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'white', color: 'black', fontWeight: '600', borderRadius: '25px', padding: '5px 10px', minWidth: '100%', whiteSpace: "nowrap" }} variant="outlined" ><img src={workExperienceLogo} style={{ padding: "0px 4px" }} alt="" /> Work 5 country</Button></Grid>

//       </Grid>

//       <Divider style={{ margin: "26px 25px" }} />


//       <div style={{ padding: "0 25px" }}>
//         <h6 style={{ fontWeight: "bold", marginBottom: "10px" }}>Social science expert</h6>
//         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque risus pharetra molestie proin id et morbi. Eu nunc at et suscipit ut augue. Commodo elementum turpis tellus nec est, uis. Ac, lacinia vel quisque enim, eu hac. Nibh ullamcorper tempus in ultrices nunc, amet tortor pharetra, tellus. Velit felis iaculis ligula nunc nunc mattis. <Button>view more</Button></p>
//       </div>

//       <Grid
//         container
//         direction="row"
//         // justifyContent="space-between"
//         alignItems="center"
//         wrap="wrap"
//         style={{ padding: "0 25px", paddingBottom: "30px" }}
//         spacing={1}

//       >
//         <Grid item ><Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'white', color: 'black', fontWeight: '600', borderRadius: '25px', padding: '5px 10px', minWidth: '100%', whiteSpace: "nowrap" }} variant="outlined" >Content writing</Button></Grid>

//         <Grid item ><Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'white', color: 'black', fontWeight: '600', borderRadius: '25px', padding: '5px 10px', minWidth: '100%', whiteSpace: "nowrap" }} variant="outlined" >Academi writing</Button></Grid>

//         <Grid item ><Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'white', color: 'black', fontWeight: '600', borderRadius: '25px', padding: '5px 10px', minWidth: '100%', whiteSpace: "nowrap" }} variant="outlined" >Writing</Button></Grid>
//       </Grid>
//     </div>
//   )
// }

const Posts = () => {

  const [value, setValue] = React.useState("");
  const [opportunityForm2, setOpportunityForm2] = useState(false)
  const [alertComp, setAlert] = React.useState(false)
  const [typeOfAlert, setType] = React.useState('')
  const [descOfAlert, setDesc] = React.useState('');
  const [requests, setRequests] = useState([]);
  const [loadingState, setLoadingState] = useState(true);
  const [loading, setLoading] = useState(true);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [openDeleteAccount, setOpenDeleteAccount] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const [opportunityForm, setOpportunityForm] = useState(false)
  const [selectedId, setSelectedId] = React.useState(null);
  const [posts, setPosts] = useState([]);
  const [acceptLoading, setAcceptLoading] = useState(false)
  const [rejectLoading, setRejectLoading] = useState(false)
  const [idForLoading, setIdForLoading] = useState("")
  const [allConnections, setAllConnections] = useState([])
  const [connectionLoading, setConnectionLoading] = useState(true)
  let history = useHistory();

    useEffect(() => {
        let urlPath = history.location.pathname;
        let pathName = urlPath.substring(1);
        setValue(pathName)
        
    }, [history.location.pathname])

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTimeout(() => { window.scrollTo(0, document.body.scrollTop) }, 50);

  };

  // for connection requests =======================

  useEffect(() => {
    getApportunityPosts();

  }, [])
  useEffect(() => {
    allConnection();

  }, [])

  const getApportunityPosts = () => {
    axiosInstance.get('opportunities/pending_requests')
      .then(res => {
        setRequests(res.data)
        setLoading(false)
        // console.log("pending requets are", res.data);
      })
      .catch(err => {
        console.log(err)
      })

  }
  const allConnection = () => {
    axiosInstance.get('opportunities/all_connections/')
      .then(res => {
        const allConnected = res.data.sender.concat(res.data.receiver);
        setAllConnections(allConnected)
        setConnectionLoading(false)
      })
      .catch(err => {
        console.log(err)
      })

  }
  console.log(allConnections);

  const requestAccept = (id) => {
    setIdForLoading(id)
    setAcceptLoading(true)
    const accepted = {
      "request_id": id,
      "is_accepted": "1"
    }
    axiosInstance.post('/opportunities/pending_requests/', accepted)
      .then(res => {
        // console.log(res)
        setRequests(requests.filter(e => e.request_id != id))
        setAlert(true)
        setType('success')
        setDesc('Request Accepted')
        setAcceptLoading(false)
        getApportunityPosts()
      }
      )
      .catch(err => {
        console.log(err)
        setAlert(true)
        setType('error')
        setDesc(err.toString())
      })

  }

  const requestReject = (id) => {
    setIdForLoading(id)
    setRejectLoading(true)
    const rejected = {
      "request_id": id,
      "is_accepted": "0"
    }
    axiosInstance.post('/opportunities/pending_requests/', rejected)
      .then(res => {
        setRequests(requests.filter(e => e.request_id != id))
        setAlert(true)
        setType('success')
        setDesc('Request rejected')
        setRejectLoading(false)
        getApportunityPosts()
        // console.log(res);

      }
      )
      .catch(err => {
        // console.log(err)
        setAlert(true)
        setType('error')
        setDesc(err.toString())

      })

  }

  const allRequests = <>
    {/* alert  */}
    {alertComp ? (
      <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlert} />
    ) : null}
    <div className='requests-main-div'>
      {loading ?
        <div className='d-flex justify-content-center mt-5 mb-5'>
          <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
        </div>

        :
        requests.length < 1 ?
          <div className='d-flex justify-content-center mt-5 mb-5'>
            <h6 style={{ fontWeight: "bold" }}> No request remaining</h6>
          </div>
          :
          <div style={{ marginTop: "55px" }}>

            <div >

              {
                requests.map(request => {
                  return (
                    <div className=' pendingRequests'>

                      <div className='request-text'>
                        <div style={{
                          display: "flex",
                          // justifyContent:"space-between",
                          alignItems: "center",
                        }}>
                          <img src={request.sender_info.profile_pic == null ? `https://ui-avatars.com/api/?name=${request.sender_info.full_name}?rounded=true` : request.sender_info.profile_pic} alt="connection" style={{ width: "2.2em", height: "2.2em", marginRight: '13px', borderRadius: "50%" }} />
                          <div>
                            <Typography variant="h6" style={{ fontWeight: "bold", fontSize: "1rem" }}>
                              {request.sender_info.full_name}
                            </Typography>
                            <Typography variant="subtitle1" style={{ fontSize: "0.85rem" }}>
                              {request.sender_info.location}
                            </Typography>
                          </div>
                        </div>
                        <div style={{ padding: "0 0px", marginTop: "24px" }}>
                          <p>{request.sender_info.about} </p>
                        </div>

                        <Grid
                          container
                          direction="row"
                          // justifyContent="space-between"
                          alignItems="center"
                          wrap="wrap"
                          style={{ marginTop: "20px" }}
                          spacing={1}

                        >
                          {
                            request.sender_interests.map(interest => {
                              return (
                                <Grid item >
                                  <ProfileTags2 text={interest} />
                                </Grid>

                              )
                            })
                          }


                        </Grid>
                      </div>

                      <div className='accept-div' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button className='requestButton' id='srchButton' style={{ textTransform: 'none', fontSize: "0.8rem", fontFamily: 'Montserrat', background: 'white', color: '#0667C3', fontWeight: '600', borderRadius: '25px', padding: '4px 15px', margin: "10px 10px" }} variant="outlined" onClick={() => requestAccept(request.request_id)} >
                          {(acceptLoading && request.request_id == idForLoading) ?
                            <div className='d-flex justify-content-center '>
                              <CircularProgress style={{ color: 'green', justifyContent: 'center', height: "25px", width: "25px" }} />
                            </div>

                            : "Accept"}
                        </Button>
                        <Button className='requestButton' id='srchButton' style={{ textTransform: 'none', fontSize: "0.8rem", fontFamily: 'Montserrat', background: 'white', fontWeight: '600', borderRadius: '25px', padding: '4px 15px', margin: "10px 10px" }} variant="outlined" color="error"
                          onClick={() => requestReject(request.request_id)} >
                          {(rejectLoading && request.request_id == idForLoading) ?
                            <div className='d-flex justify-content-center '>
                              <CircularProgress style={{ color: 'green', justifyContent: 'center', height: "25px", width: "25px" }} />
                            </div>

                            : "Reject"}
                        </Button>

                      </div>

                    </div>
                  )
                })
              }
            </div>

          </div>
      }


    </div>

  </>

  // for my post ==============================


  useEffect(() => {
    getposts()
  }, [])

  const getposts = () => {
    axiosInstance.get('/opportunities/posts')
      .then(res => {
        setPosts(res.data)
        console.log("posts are", res.data);
        setLoadingState(false)

      }).catch(err => {
        console.log(err)
      }
      )
  }

  const handleClickOpenDeleteAccount = (id) => {
    console.log(id, "id is");
    setOpenDeleteAccount(true);
    setSelected(id);
  };
  const handleCloseDeleteAccount = () => {
    setOpenDeleteAccount(false);
  };

  const handleDeleteButton = () => {

    axiosInstance.delete('/opportunities/posts/',
      {

        data: {
          id: selected
        }

      }
    )
      .then(res => {
        setAlert(true)
        setType('success')
        setDesc('Successfully Updated')
        console.log(res)
        handleCloseDeleteAccount();
        getposts()
      })
      .then(err => {
        console.log(err);
        setAlert(true)
        setType('error')
        setDesc(err.toString())
      })


  }

  const handleEditPost = (postData) => {
    setOpportunityForm(true)
    setSelectedId(postData)
  }

  const AllPosts = <>

    <div className='requests-main-div'>

      <Modal
        open={opportunityForm}
        onClose={() => setOpportunityForm(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='modalCont'>
          <OpportunityForm closeModal={() => setOpportunityForm(false)}
            getApportunityPosts={getposts} postData={selectedId} setAlert={setAlert} setType={setType} setDesc={setDesc} />


        </div>
      </Modal>
      <Dialog
        open={openDeleteAccount}
        onClose={handleCloseDeleteAccount}

      >

        <DialogContent >
          <Grid container justifyContent='row' spacing={1} >
            <Grid item lg={2} md={3} sm={3} xs={3} >
              <img src={DeleteIcon} alt='Delete Icon' />
            </Grid>
            <Grid container direction='column' item lg={10} md={9} sm={9} xs={9} >
              <span style={{ fontWeight: '600', fontSize: '19px' }}>Delete my account</span>

              <span style={{ fontSize: '14px', marginTop: '1em' }}>Are you sure you want to delete your account? if you delete your account, you will permanently lose your profile, messages, and photos</span>

            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingBottom: '1.5em' }}>
          <Button style={{ textTransform: 'none', border: '1px solid #808080', borderRadius: '18px', padding: '0.5em 1em', color: '#808080' }} onClick={handleCloseDeleteAccount}>Cancel</Button>
          <Button style={{ textTransform: 'none', background: 'linear-gradient(275.34deg, #D80202 -10.41%, #D70000 -10.4%, #FF4545 102.29%)', borderRadius: '18px', padding: '0.5em 1em', color: '#fff' }} onClick={handleDeleteButton} autoFocus>
            Delete Account
          </Button>
        </DialogActions>
      </Dialog>

      {/* ALERT */}
      {alertComp ? (
        <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlert} />
      ) : null}


      <div style={{ marginTop: "55px" }}>


        <div >

          {
            loadingState ? <div className='d-flex justify-content-center mb-5'>
              <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
            </div>
              :
              posts.length < 1 ?
                <div className='d-flex justify-content-center mt-5 mb-5'>
                  <h6 style={{ fontWeight: "bold" }}> No post available</h6>
                </div>
                :
                posts.map(post => {
                  return (
                    <div className='single-request'>


                      <div className='request-text'>
                        <Typography variant="h5">
                          <h5 style={{ fontWeight: "bold" }}>{post.title} </h5>
                        </Typography>
                        <div style={{ padding: "0 0px", marginTop: "20px" }}>
                          <p>{
                            post.description.substring(0, 110) + "..."

                          } </p>
                        </div>

                        <Grid
                          container
                          direction="row"
                          // justifyContent="space-between"
                          alignItems="center"
                          wrap="wrap"
                          style={{ marginTop: "20px" }}
                        // spacing={1}

                        >
                          {
                            post.seeker_details.is_education && <ProfileTags2 text='Education' />
                          }
                          {
                            post.seeker_details.is_phd && <ProfileTags2 text='PHD' />
                          }
                          {
                            post.seeker_details.is_work_exp && <ProfileTags2 text='Work Experience' />
                          }

                        </Grid>
                      </div>
                      <div className='rating-div'>
                        <div className='rating '>
                          <p style={{ marginBottom: "-5px", fontSize: "1.3rem", fontWeight: "bold", color: "#E2C218", paddingRight: "7px" }}>{windowDimensions[0] > 900 ? "3.5" : <><span style={{ color: "black", fontWeight: "500", fontSize: "1rem" }}>Rating :</span>  3.5 </>} </p>
                          <div>
                            <svg width="" height="1.3rem" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M27.967 10.7689C27.8853 10.5187 27.6686 10.3377 27.4094 10.3035L18.5697 8.9344L14.6121 0.938795C14.4855 0.718759 14.2524 0.583374 14.0001 0.583374C13.7479 0.583374 13.5148 0.718759 13.3882 0.938795L9.43063 9.00247L0.590929 10.3716C0.33169 10.4058 0.114945 10.5867 0.0332859 10.837C-0.0474643 11.0846 0.0214471 11.3568 0.210258 11.5353L6.61572 17.8195L5.10609 26.6225C5.0557 26.8776 5.15648 27.1392 5.36442 27.2936C5.58572 27.4289 5.86378 27.4289 6.08507 27.2936L14 23.1587L21.9149 27.3345C22.0102 27.3889 22.118 27.4173 22.2276 27.4167C22.3727 27.4179 22.5151 27.3748 22.6356 27.2935C22.8435 27.1392 22.9443 26.8776 22.8939 26.6224L21.3843 17.7506L27.7897 11.4664C27.9786 11.288 28.0475 11.0157 27.9667 10.7681L27.967 10.7689Z" fill="#E2C218" />
                            </svg>
                          </div>

                        </div>

                        <Stack spacing={2} direction="row">
                          <Button variant="text" style={{ fontWeight: "bold" }} onClick={() => handleClickOpenDeleteAccount(post.id)} ><img src={deleteIcon} alt="" style={{ paddingRight: "7px", paddingBottom: "3px" }} /> Delete</Button>
                          <Button variant="text" style={{ fontWeight: "bold" }} onClick={() => handleEditPost(post)}><img src={editIcon} alt="" style={{ paddingRight: "7px", paddingBottom: "3px" }} /> Edit</Button>

                        </Stack>

                      </div>

                    </div>
                  )
                })


          }
        </div>

      </div>

    </div>



  </>


  // for connection ==================================

  const connections = <>
    {/* alert  */}
    {alertComp ? (
      <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlert} />
    ) : null}
    <div className='requests-main-div'>
      {connectionLoading ?
        <div className='d-flex justify-content-center mt-5 mb-5'>
          <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
        </div>

        :
        allConnections.length < 1 ?
          <div className='d-flex justify-content-center mt-5 mb-5'>
            <h6 style={{ fontWeight: "bold" }}> No request remaining</h6>
          </div>
          :
          <div style={{ marginTop: "55px" }}>

            <div >

              {
                allConnections.map(c => {
                  return (
                    <div className=' pendingRequests'>

                      <div className='request-text'>
                        <div style={{
                          display: "flex",
                          // justifyContent:"space-between",
                          alignItems: "center",
                        }}>
                          <img src={c.sender_info.profile_pic == null ? `https://ui-avatars.com/api/?name=${c.sender_info.full_name}?rounded=true` : c.sender_info.profile_pic} alt="connection" style={{ width: "2.2em", height: "2.2em", marginRight: '13px', borderRadius: "50%" }} />
                          <div>
                            <Typography variant="h6" style={{ fontWeight: "bold", fontSize: "1rem" }}>
                              {c.sender_info.full_name}
                            </Typography>
                            <Typography variant="subtitle1" style={{ fontSize: "0.85rem" }}>
                              {c.sender_info.location}
                            </Typography>
                          </div>
                        </div>
                        <div style={{ padding: "0 0px", marginTop: "24px" }}>
                          <p>{c.sender_info.about} </p>
                        </div>

                        <Grid
                          container
                          direction="row"
                          // justifyContent="space-between"
                          alignItems="center"
                          wrap="wrap"
                          style={{ marginTop: "20px" }}
                          spacing={1}

                        >
                          {
                            c.sender_interests.map(interest => {
                              return (
                                <Grid item >
                                  {/* <Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'white', color: 'black', fontWeight: '600', borderRadius: '25px', padding: '5px 15px', minWidth: '100%', whiteSpace: "nowrap", border: "1px solid #E4E8F1" }} >{interest}</Button> */}
                                  <ProfileTags2 text={interest}/>
                                </Grid>

                              )
                            })
                          }


                        </Grid>
                      </div>

                      <div className='accept-div' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button className='requestButton' id='srchButton' style={{ textTransform: 'none', fontSize: "0.8rem", fontFamily: 'Montserrat', background: 'white', color: '#0667C3', fontWeight: '600', borderRadius: '25px', padding: '4px 15px', margin: "10px 10px" }} variant="outlined" onClick={() => requestAccept(c.request_id)} > Remove
                          {/* {(acceptLoading && c.request_id==idForLoading) ? 
                            <div className='d-flex justify-content-center '>
                              <CircularProgress style={{ color: 'green', justifyContent: 'center', height:"25px", width:"25px"  }} />
                            </div>

                            : "Accept" } */}
                        </Button>
                        {/* <Button className='requestButton' id='srchButton' style={{ textTransform: 'none', fontSize: "0.8rem", fontFamily: 'Montserrat', background: 'white', fontWeight: '600', borderRadius: '25px', padding: '4px 15px', margin: "10px 10px" }} variant="outlined" color="error"
                          onClick={() => requestReject(c.request_id)} >
                             {(rejectLoading && c.request_id==idForLoading) ?
                            <div className='d-flex justify-content-center '>
                              <CircularProgress style={{ color: 'green', justifyContent: 'center' , height:"25px", width:"25px"  }} />
                            </div>

                            : "Reject" }
                          </Button> */}

                      </div>

                    </div>
                  )
                })
              }
            </div>

          </div>
      }


    </div>

  </>


  const renderSwitch = (param) => {
    switch (param) {
      case "requests":
        return allRequests;
      case "myPosts":
        return AllPosts;
      case "myConnection":
        return connections;

    }
  }
  return (
    <div style={{ paddingTop: "144px", minHeight: "100vh", backgroundColor: "#F5F7FA", boxSizing: "border-box" }}>

      {/* job opportunity modal  */}

      <Modal
        open={opportunityForm2}
        onClose={() => setOpportunityForm2(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='modalCont'>
          <OpportunityForm closeModal={() => setOpportunityForm2(false)} />

        </div>
      </Modal>
      {/* navbar  */}

      {/* <NavBar_emp /> */}
      <NavBar></NavBar>


      <div className='all-requests'>



        {/* search bar and related  */}

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"

        >
          <div>
            <Typography variant="h4">
              <h1 style={{ paddingBottom: "25px", fontWeight: "bold", fontSize: "2rem", color: "#000000" }}>Your Posts <img style={{ maxHeight: "18px" }} src={postIcon} alt="" /> </h1>
            </Typography>
          </div>

          {/* search box  */}


          <div>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div> <SearchBarSmall /></div>

              <div>
                <Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', color: '#FFF', fontWeight: '600', borderRadius: '25px', padding: '10px 21px', minWidth: '100%', margin: "10px 10px" }} variant="contained" onClick={() => setOpportunityForm(true)}>Job Opportunity</Button>
              </div>
            </Grid>

          </div>


        </Grid>

        {/* tab  */}

        <div style={{ marginBottom: "61px" }}>
          <Tabs
            variant="scrollable"
            scrollButtons={false}
            allowScrollButtonsMobile
            value={value}
            onChange={handleChange}
            textColor="inherit"
            TabIndicatorProps={{ style: { background: 'orange' } }}
            // initialSelectedIndex={'requests'}

          >
            <Tab style={{ textTransform: 'capitalize' }} value="requests" label="Connection Requests" />
            <Tab style={{ textTransform: 'capitalize' }} value="myPosts" label="My Posts" />
            <Tab style={{ textTransform: 'capitalize' }} value="myConnection" label="My connection" />

          </Tabs>
        </div>
        {renderSwitch(value)}


      </div>





      <Footer />


    </div>
  );
};

export default Posts;

export const ProfileTags2 = (props) => {
  return (
    <div style={{ marginRight: "7px", whiteSpace: "nowrap" }} className={`profileTag2 ${props.img ? null : 'tagBoldText2'}`}>
      <img src={props.img} alt='' />
      {props.text}
    </div>
  )
}
