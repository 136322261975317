import { CircularProgress, FormHelperText, Grid, IconButton, Input, InputAdornment, Snackbar, Typography } from '@material-ui/core';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import Logo from '../login/logo.svg';
import TextField from '@material-ui/core/TextField';
import ReCAPTCHA from "react-google-recaptcha";
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { FcGoogle } from 'react-icons/fc';
import Select from 'react-select';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { SiMicrosoftoutlook } from 'react-icons/si';
import { AiFillApple, AiFillFacebook, AiFillLinkedin } from 'react-icons/ai';
import { useHistory } from "react-router-dom";
import './style.css';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import GirlImg from '../login/girl.svg';
import Tick from '../login/tick.svg';
import Outlook from './outlook.svg';
import axiosInstance, {baseURL} from '../../axios';
import Alert from '../alerts/alert';
import Cookies from 'js-cookie';
import {Helmet} from "react-helmet";
import school_district from '../../assets/school.json';
import axios from 'axios';

const theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',

        ].join(','),
    },
});




function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}
const EmployerRegister = (props) => {

    const history = useHistory();
    //ALERT CODE:
    const [alertComp, setAlert] = React.useState(false);
    const [typeOfAlert, setType] = React.useState("");
    const [descOfAlert, setDesc] = React.useState("");

    const [values1, setValues1] = React.useState({
        password: '',
        showPassword: false,
    });

    const handleChange1 = (prop) => (event) => {
        setValues1({ ...values1, [prop]: event.target.value });
        setSignupData({ ...SignupData, [prop]: event.target.value });
    };
    const handleClickShowPassword1 = () => {
        setValues1({ ...values1, showPassword: !values1.showPassword });
    };


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const { height, width } = useWindowDimensions();

    // const [captcha, setCaptcha] = useState(false);
    // const [firstName, setFirstName] = useState('');
    // const [lastName, setLastName] = useState('');
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const [SignupData, setSignupData] = useState({
        firstname: '',
        lastname: '',
        districtname: '',
        schoolname: '',
        email: '',
        jobTitle: '',
        password : ''
    })

    const [loader, setloader] = React.useState(false);

    const [ReCaptchaText, setReCaptchaText] = React.useState("");

    const [lastnameError, setlastnameError] = React.useState(false);
    const [lastnameErrorText, setlastnameErrorText] = React.useState("");

    const [districtnameError, setdistrictnameError] = React.useState(false);
    const [districtnameErrorText, setdistrictnameErrorText] = React.useState("");

    const [schoolnameError, setschoolnameError] = React.useState(false);
    const [schoolnameErrorText, setschoolnameErrorText] = React.useState("");

    const [firstnameError, setfirstnameError] = React.useState(false);
    const [firstnameErrorText, setfirstnameErrorText] = React.useState("");

    const [emailError, setemailError] = React.useState(false);
    const [emailErrorText, setemailErrorText] = React.useState("");

    const [jobTitleError, setjobTitleError] = React.useState(false);
    const [jobTitleErrorText, setjobTitleErrorText] = React.useState("");
    
    const [thankYou, setThankYou] = React.useState( false );
    const [captchaValue, setcaptchaValue]   = React.useState("");
    const [passwordError, setpasswordError] = React.useState(false);
    const [passwordErrorText, setpasswordErrorText] = React.useState("");

    const convertArrayToString = (arr) => {
        let str = '';
        if (arr != null) {
            arr.map(a => {
                str = str + a + '\n';
            });
        }
        return str;
    }

    const ValidateSingleField = (data) => {
        if (data == "")
            return true;
        return false;

    }

    const validateData = () => {


        setlastnameError(ValidateSingleField(SignupData.lastname));
        ValidateSingleField(SignupData.lastname) ? setlastnameErrorText('Last Name cannot be blank') : setlastnameErrorText('');

        setfirstnameError(ValidateSingleField(SignupData.firstname));
        ValidateSingleField(SignupData.firstname) ? setfirstnameErrorText('First Name cannot be blank') : setfirstnameErrorText('');

        setemailError(ValidateSingleField(SignupData.email));
        ValidateSingleField(SignupData.email) ? setemailErrorText('Email cannot be blank') : setemailErrorText('');


        if (!ValidateSingleField(SignupData.email) && !ValidateSingleField(SignupData.lastname) && !ValidateSingleField(SignupData.firstname)) {


            return true;


        }
        return false;

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    const handleSignUp = () => {
        // setThankYou(true);

        // console.log(SignupData);
        const data = {
            username : SignupData.email,
            first_name : SignupData.firstname,
            last_name : SignupData.lastname,
            email : SignupData.email,
            password : SignupData.password,
            school_district_id : SignupData.districtname.value,
            captcha : captchaValue,
            school_name : SignupData.schoolname,
            job_title : SignupData.jobTitle
        }
        if(!captcha){
            setAlert(true);
            setType("warning")
            setDesc('Please Verify Captcha!');
            return;
        }
        // console.log(data);

        axiosInstance.post('/district/register/', data)
        .then(res=>{
            // console.log(data);
            setAlert(true);
            setType("success")
            setDesc('Successfully Registered');
            setThankYou(true);
            // const data = res.data;
            // console.log(data);
            // Cookies.set('access_token', data.access);
            // Cookies.set('refresh_token', data.refresh);

        }).catch(err=>{
            console.log(err.response.data.error);
            setAlert(true);
            setType("error")
            setDesc(err.response.data.error);
            
            const x = err.response.data.error;

            setpasswordError(convertArrayToString(x.password));
            setemailError(convertArrayToString(x.username));

            setemailErrorText(convertArrayToString(x.username));
            setpasswordErrorText(convertArrayToString(x.password));
        })
    }

    const [open, setOpen] = React.useState(true);

    const [captcha, setCaptcha] = useState(false);
    const [schoolList, setschoolList] = useState([]);
    const [searchSchoolText, setsearchSchoolText] = useState('');

    useEffect(()=> {
        getSchoolData();
    }, [searchSchoolText])

    const getSchoolData = () => {
        axios.get(`${baseURL}/seeker/get_school_by_search/`, {
            params : {
                search : searchSchoolText
            }
        }).then(res=>{
            setschoolList(res.data.msg);
        }).catch(err=>{
            console.log(err);
        })
    }

    
    return (
        <ThemeProvider theme={ theme }>
            <Helmet>
                <title>Frame Change</title>
            </Helmet>
            {/* ALERT */}
            {alertComp ? <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlert} /> : null}
            <div style={{ position: 'relative', zIndex: '1' }}>
                <Grid container style={{}}>
                    {width > 959 ? <Grid style={{ background: '#F1F1F1' }} item lg={8} md={7} sm={12} xs={12}>
                        <div style={{ position: 'sticky', top: '0' }}>


                            <img style={{ margin: '3% 5%' }} src={Logo} alt='logo' />
                            <Grid item container style={{}}>
                                <Grid item lg={6} md={5} >
                                    <div style={{ marginLeft: '13%' }}>
                                        <Typography style={{ fontWeight: '600', fontSize: '33px' }}>Dapibus id senectus lectus enim nunc.</Typography>
                                        <br />
                                        <Typography variant="body1" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor</Typography>
                                        <br />
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <img style={{ marginRight: '10px' }} src={Tick} alt='tick' />
                                            <Typography variant="body1" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam fringilla urna, porttitor</Typography>
                                        </div>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <img style={{ marginRight: '10px' }} src={Tick} alt='tick' />
                                            <Typography variant="body1" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam fringilla urna, porttitor</Typography>
                                        </div>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <img style={{ marginRight: '10px' }} src={Tick} alt='tick' />
                                            <Typography variant="body1" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam fringilla urna, porttitor</Typography>
                                        </div>
                                        <br />

                                    </div>

                                </Grid>
                                <Grid item lg={6} md={7} >
                                    <div style={{ position: 'relative', height: '80vh' }}>
                                        <img style={{ width: '100%', position: 'absolute', bottom: '0em' }} src={GirlImg} alt='girl' />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid> : null
                    }

                    { thankYou ? (
                        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'auto' }} item lg={4} md={5} sm={12} xs={12}>
                            <div className='tkCont'>
                                <div className="tkHeading">Thank you for your inquiry!</div>
                                <div className="tkSub">
                                    We will contact you within 24 hours to get you started. While you're waiting, why not <span style={{ color: '#0072C6', cursor: 'pointer' }}>learn more about what k12FrameChange</span> can do for you?
                                </div>
                                <div className='tkSub' style={{marginTop: '50px'}}>Already have an account?<span style={{ fontWeight: '700', color: '#0072C6', cursor: 'pointer' }} onClick={() => history.push("/login", { from: "Register" })}> Sign in</span></div>
                            </div>
                        </Grid>
                    ) : (
                            <Grid style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', overflow: 'auto' }} item lg={4} md={5} sm={12} xs={12}>
                        {/* <div style={{ height: '54px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span className='text'>Are you an Employer? <span style={{ fontWeight: '600' }}><u>Register here</u></span></span>
                </div>
                <hr style={{ border: '1px solid ##F1F1F1', color: '##F1F1F1', width: '100%' }} /> */}
                        {width > 959 ? <></> :
                            <div style={{ background: '#FBF5F3', width: '100%', height: '8vh' }}>
                                <img style={{ paddingTop: '5px', width: '90px', position: 'absolute', top: '0', left: '0' }} src={Logo} alt='logo' />
                            </div>}
                        <Grid style={{ marginTop: '5%', padding: '5% 15%' }} container direction='column' >
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                <Typography className='text' style={{ fontSize: '25px', fontWeight: '600' }}>Create an account for employers</Typography>
                                <br />
                                <span className='label'>Already have an account?<span style={{ fontWeight: '700', color: '#0072C6', cursor: 'pointer' }} onClick={() => history.push("/login", { from: "Register" })}> Sign in</span></span>
                            </div>
                            <br />
                            <span className='label'>First Name</span>
                            <TextField placeholder='First name' variant='outlined' error={firstnameError} helperText={firstnameErrorText} value={SignupData.firstname}
                                onChange={(event) => {
                                    setfirstnameError(false);
                                    setfirstnameErrorText('');
                                    setSignupData({ ...SignupData, firstname: event.target.value });
                                }} />
                            <br />
                            <span className='label'>Last Name</span>
                            <TextField className='inputField' placeholder='Last name' variant='outlined' error={lastnameError} helperText={lastnameErrorText} value={SignupData.lastname}
                                onChange={(event) => {
                                    setlastnameError(false);
                                    setlastnameErrorText('');
                                    setSignupData({ ...SignupData, lastname: event.target.value })
                                }} />
                            <br />
                            <span className='label'>Job Title</span>
                            <TextField className='inputField' type="Job Title" helperText={jobTitleErrorText} value={SignupData.jobTitle}
                                onChange={(event) => {
                                    setjobTitleError(false);
                                    setjobTitleErrorText('');
                                    setSignupData({ ...SignupData, jobTitle: event.target.value })
                                }} placeholder='e.g. - Manager' variant='outlined' />
                            <br />
                            <span className='label'>Email</span>
                            <TextField className='inputField' type="email" error={emailError} helperText={emailErrorText} value={SignupData.email}
                                onChange={(event) => {
                                    setemailError(false);
                                    setemailErrorText('');
                                    setSignupData({ ...SignupData, email: event.target.value })
                                }} placeholder='name@mail.com' variant='outlined' />
                            <br />
                            <span className='label'>District Name</span>
                            {/* <TextField className='inputField' placeholder='District name' variant='outlined' error={districtnameError} helperText={districtnameErrorText} value={SignupData.districtname}
                                onChange={(event) => {
                                    setdistrictnameError(false);
                                    setdistrictnameErrorText('');
                                    setSignupData({ ...SignupData, districtname: event.target.value })
                                }} /> */}
                                <Select
                                    value={SignupData.districtname}
                                    isClearable={true}
                                    onChange={event=>{
                                        setdistrictnameError(false);
                                        setdistrictnameErrorText('');
                                        setSignupData({ ...SignupData, districtname: event })
                                    }}
                                    onKeyDown = {(e)=>{setsearchSchoolText(e.target.value)}}
                                    options={ schoolList }
                                />
                            <br />
                            <span className='label'>School Name</span>
                            <TextField className='inputField' placeholder='School name' variant='outlined' error={schoolnameError} helperText={schoolnameErrorText} value={SignupData.schoolname}
                                onChange={(event) => {
                                    setschoolnameError(false);
                                    setschoolnameErrorText('');
                                    setSignupData({ ...SignupData, schoolname: event.target.value })
                                }} />
                            <br />
                            
                            <span className='label'>Password</span>
                            <OutlinedInput
                                className='inputField'
                                placeholder='6 characters, 1 capital letter'
                                variant='outlined'
                                error={passwordError}
                                type={values1.showPassword ? 'text' : 'password'}
                                value={values1.password}
                                onChange={handleChange1('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword1}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {values1.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText style={{ color: 'red' }}>{passwordErrorText}</FormHelperText>
                               
                            
                            <Grid container justifyContent='space-between' style={{ marginTop: '10px' }}>
                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: '20px 0' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Checkbox
                                            style={{ padding: '0', color: '#0DAC69' }}
                                            defaultChecked

                                        />
                                        <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>I agree to the <span style={{ color: "#1A78DF" }}>terms and condition</span> and <span style={{ color: "#1A78DF" }}>privacy policy</span></span>

                                    </div>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: '20px 0' }}>
                                    <ReCAPTCHA
                                        sitekey="6LdrTn8cAAAAADxkhpfTvTp_nVulm9D_8BH6_sBJ"
                                        stoken="6LdrTn8cAAAAADsdX_YanKa76kXsJ3VG4qowSt7o"
                                        onExpired={() => {
                                            setCaptcha(false);
                                        }}
                                        onChange={(value) => {
                                            setReCaptchaText("")
                                            setCaptcha(true);
                                            setcaptchaValue(value);
                                        }}
                                    />
                                    <FormHelperText style={{ color: 'red' }}>{ReCaptchaText}</FormHelperText>
                                </Grid>

                            </Grid>
                            <br />
                            {loader ? <div style={{ display: 'flex', justifyContent: 'center' }} >
                                <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                            </div> : <Button style={{ textTransform: 'none', fontSize: "18px", fontFamily: 'Montserrat', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', color: '#FFF', fontWeight: '600', borderRadius: '25px', padding: '10px' }}
                                variant="contained"
                                // onClick={() => {
                                //     if (captcha) {
                                //         if (validateData()) {
                                //             const data = {
                                //                 username: SignupData.email,
                                //                 password: SignupData.password,
                                //                 email: SignupData.email,
                                //                 first_name: SignupData.firstname,
                                //                 last_name: SignupData.lastname
                                //             }

                                //             console.log(data);
                                //             setloader(true);
                                //             axiosInstance.post('/auth/register/', data).then((res) => {

                                //                 console.log(res);
                                //                 const data = res.data;
                                //                 console.log(data);
                                //                 Cookies.set('access_token', data.access);
                                //                 Cookies.set('refresh_token', data.refresh);

                                //                 history.push('/onboarding');

                                //             }).catch((err) => {
                                //                 setloader(false);
                                //                 const x = err.response.data.error;

                                //                 setpasswordError(convertArrayToString(x.password));
                                //                 setemailError(convertArrayToString(x.username));

                                //                 setemailErrorText(convertArrayToString(x.username));
                                //                 setpasswordErrorText(convertArrayToString(x.password));


                                //             });

                                //         }

                                //     }
                                //     else {
                                //         setReCaptchaText("Please complete the reCAPTCHA")
                                //     }

                                // }
                                // }
                                    onClick={() => handleSignUp()}
                            >Create account</Button>}



                            <br />
                            <br />

                        </Grid>


                    </Grid>
                    )}


                </Grid>
            </div>
            
        </ThemeProvider>
    );
}

export default EmployerRegister;
