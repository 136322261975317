import React from 'react'
import Footer from '../Footer'
import NavBarSrch from '../NavBar_withSearch'
import Img505 from './Frame 6970.png'

const FiveOFive = () => {
  return (
    <div>
      <NavBarSrch />
      <a href='/'><img src={Img505} alt="" style={{display: 'block', margin: 'auto', marginTop: '11vh', cursor: 'pointer', maxWidth: '95vw'}} /></a>
      <Footer />
    </div>
  )
}

export default FiveOFive
