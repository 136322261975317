import React, { useCallback, useState, useEffect } from 'react';
import { Box, Grid, TextField, Typography, Button, Input } from '@material-ui/core';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MailIcon from '@material-ui/icons/Mail';
import { styled, alpha } from '@mui/material/styles';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Divider from '@mui/material/Divider';
import Logo from './logo.svg';
import Profile from './profile.svg';
import Cookies from 'js-cookie';
import Modal from '@mui/material/Modal'
import MaintainenceGuy from '../../images/undraw_maintenance_re_59vn 1.png'
import axios from 'axios';
import axiosInstance from '../../axios';
import { useHistory } from 'react-router-dom';
import DrawerComponent from "./Drawer";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import handleLogout from '../../Logout/logout';

import './style.css'

function NavBar() {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [loginModal, setLoginModal] = useState(false)

  const [NotianchorEl, setNotiAnchorEl] = useState(null);
  const [profile, setprofile] = useState({
    "name": '',
    "email": '',
    "phone": '',
    'profile_pic': null
  })
  const [notifications, setnotifications] = useState([]);
  const [notificationCount, setnotificationCount] = useState(0);



  // const webSocketConnection = async () => {
  //   const ws = new WebSocket('ws://127.0.0.1:8000/ws/notification/');

    

  //   ws.onopen = () => {
  //     try{
  //       const data = {
  //         access_token : "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTY1MjIwOTkzMSwiaWF0IjoxNjUxOTUwNzMxLCJqdGkiOiJlNWVmYTUxZGMzYmM0MDA4YTcwMDM4MDAxOWI4MDViZSIsInVzZXJfaWQiOjE0fQ._IhPezOfyyw6E8i6FQfTRL8z7Mo3Pv-fPh_WxV1bWAk"
  //       }
  //       ws.send(JSON.stringify(data));
  //     }catch(err){
  //       console.log(err);
  //     }
  //   };

  //   ws.onmessage = (e) => {
  //     try{
  //       const data = JSON.parse(e.data);
  //       setnotificationCount(data.count)
  //       setnotifications(data.notifications);
  //     }catch(err){
  //       setnotifications([]);
  //       setnotificationCount(0);
  //     }
  //   }

  //   ws.onerror = (e) => {
  //     console.log('WebSocket Client Error'); 
  //   }
  //   // return ws;
  // }

  useEffect(() => {
    // if(!(localStorage.getItem('name') && localStorage.getItem('email') && localStorage.getItem('phone')))

    // window.addEventListener('storage', ()=>{
    //   console.log('hello');
    setprofile({
      "name": (localStorage.getItem('name') === '' || localStorage.getItem('name') === 'null') ? '' : localStorage.getItem('name'),
      "email": (localStorage.getItem('email') === '' || localStorage.getItem('email') === 'null') ? '' : localStorage.getItem('email'),
      "phone": (localStorage.getItem('phone') === '' || localStorage.getItem('phone') === 'null') ? '' : localStorage.getItem('phone'),
      "profile_pic": (localStorage.getItem('profile_pic') === '' || localStorage.getItem('profile_pic') === 'null') ? '' : localStorage.getItem('profile_pic')
    })

    if(localStorage.getItem('access_token')){
      Cookies.set('access_token', localStorage.getItem('access_token'));
      checkEmployer();
    }
    if(localStorage.getItem('user')){
      Cookies.set('user', localStorage.getItem('user'));
    }
    if(localStorage.getItem('refresh_token')){
      Cookies.set('refresh_token', localStorage.getItem('refresh_token'));
    }
    // })
    // console.log(profile);
  }, [])

  useEffect(() => {
    notificationFetch();
  }, [notificationCount])


  const notificationFetch = () => {
    axiosInstance.get('/auth/notification/').then(res=>{
      setnotifications(res.data.data.notifications);
      setnotificationCount(res.data.data.count);
    }).catch(err=>{
      console.log(err);
    })
  }

  const history = useHistory();

  const notificationClick = (id, job_id) => {
    // console.log(notification);
    // axiosInstance.post('/notification/changeStatus', { id })
    const data = {
      // access : localStorage.getItem('access_token'),
      notification_id : id
    }
    axiosInstance.post('/auth/notiification_change/', data).then(res=>{
      console.log(res);
      setnotificationCount(notificationCount-1);
      if(job_id){
        sessionStorage.setItem('mobJobId', job_id);
        history.push('/job-detail');
      }
    }).catch(err=>{
      console.log(err);
    })
  }

  const Notiopen = Boolean(NotianchorEl);
  const NotihandleClick = (event) => {
    setNotiAnchorEl(event.currentTarget);
  };
  const NotihandleClose = () => {
    setNotiAnchorEl(null);
  };


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const checkEmployer = () => {
    if(Cookies.get('access_token')){
      if(Cookies.get('user') === 'DistrictAdmin'){
        window.location.href="employer/dashboard"
      }
    }else{
      window.location.href="/login";
    }
  }
  
  const markAsRead = () => {
    axiosInstance.post('/auth/mark_all_notification/').then(res=>{
      setnotificationCount(0);
      notificationFetch();
    }).catch(err=>{
      console.log(err);
    })
  }

  return (
    <div>
      {
        isMobile ? (
          <DrawerComponent />
        ) : (
          <Navbar fixed="top" collapseOnSelect expand="lg" style={{ background: '#FFF' }} variant="light">
            <Container>
              <Navbar.Brand href="/dashboard"><img src={Logo} alt="logo" /></Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="/search-jobs">Find jobs</Nav.Link>
                  <Nav.Link href="/saved-jobs">Saved jobs</Nav.Link>
                  <Nav.Link href="/job-alert">Jobs alerts</Nav.Link>
                  {/* <Nav.Link href="#plans" onClick={() => setLoginModal(true)}>Subscription plans</Nav.Link> */}
                </Nav>
                <Nav>
                  <Nav.Link onClick={NotihandleClick} id="noti-menu">
                    <div>
                      <Badge badgeContent={notificationCount} color="primary" aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true" >
                        <NotificationsNoneIcon />
                      </Badge>
                    </div>

                  </Nav.Link>
                  <StyledMenu
                    id="noti-menu"
                    anchorEl={NotianchorEl}
                    open={Notiopen}
                    onClose={NotihandleClose}
                  >
                    <div className='notificationDD'>
                      <div className="notiDDHeader">
                        <div className="notiDDTitle">Notifications</div>
                        <div className="notiDDsubTitle" onClick={markAsRead}>Mark all as read</div>
                      </div>
                      <div className="noiCont">
                        {
                          notifications && notifications.map(item=>{
                            return(<>
                              <Notification notificationClick={notificationClick} id={item.id} img='//placedog.net/40/40' name={item.title} created_at = {item.created_at} job_id = {item.job_id} desc={item.message} />

                            </>)
                          })
                        }
                        {/* <Notification img='//placedog.net/40/40' name='Nelly Miller' time='2' desc='Lorem ipsum dolor sit amet, consecteurr adipiscing elit.' /> */}
                      </div>
                      <div className="notiC2a">View All</div>
                    </div>
                  </StyledMenu>
                  <Nav.Link href="/message">
                    <Badge color="primary">
                      <ChatBubbleOutlineIcon />
                    </Badge>
                  </Nav.Link>
                  <Nav.Link onClick={handleClick} id="profile-menu">
                    <Badge color="primary">
                      <PersonOutlineIcon />
                    </Badge>
                  </Nav.Link>
                  <StyledMenu
                    id="profile-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <Box style={{ display: 'flex', flexDirection: 'row', margin: '1em' }}>
                      <img height='49' width='49' src={
                        (localStorage.getItem('profile_pic') === '' || localStorage.getItem('profile_pic') === 'null') ?
                          `https://ui-avatars.com/api/?name=${profile.name}?rounded=true` : localStorage.getItem('profile_pic')
                      } style={{ margin: '5px', borderRadius: '50%' }} />
                      <Box>
                        <Typography style={{ fontWeight: '600', fontSize: '18px' }}>{
                          !(localStorage.getItem('name') === '' || localStorage.getItem('name') === 'null') ? localStorage.getItem('name') : null
                        }</Typography>
                        <Typography style={{ fontSize: '14px', color: '#878E99' }}>{
                          (localStorage.getItem('email') === '' || localStorage.getItem('email' === 'null')) ?
                            null : localStorage.getItem('email')
                        }</Typography>
                        <Typography style={{ fontSize: '14px', color: '#878E99' }}>{
                          (localStorage.getItem('phone') === '' || localStorage.getItem('phone' === 'null')) ?
                            null : localStorage.getItem('phone')
                        }</Typography>

                      </Box>

                    </Box>
                    <Divider />
                    <MenuItem onClick={() => window.location.href = '/profile'}>Profile</MenuItem>
                    <MenuItem onClick={() => window.location.href = '/setting/jobPrefernces'}>Job preference</MenuItem>
                    <MenuItem onClick={() => window.location.href = '/setting/attachments'}>Upload Documents(Resume)</MenuItem>
                    <MenuItem onClick={() => window.location.href = '/setting/contactPrefernces'}>Contact Preference</MenuItem>
                    <MenuItem onClick={() => window.location.href = '/setting/account'}>Account</MenuItem>
                    <MenuItem onClick={() => window.location.href = '/setting/helpCenter'}>Help Center</MenuItem>
                    <Divider />
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </StyledMenu>
                </Nav>
              </Navbar.Collapse>
            </Container>
            <Modal
              open={loginModal}
              onClose={() => { setLoginModal(false) }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className='modalCont' style={{ width: 'fit-content' }}>
                <img src={MaintainenceGuy} alt="" />
                <div className='loginModalHead'>This action is not available yet...</div>
                <div className='loginModalSubHead'>We are woking on it, please come back later.</div>
                <button className='loginModalBtn' onClick={() => setLoginModal(false)}>Okay</button>
              </div>
            </Modal>
          </Navbar>
        )
      }
    </div>
  )

}

export default NavBar;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(5),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}))

const Notification = (props) => {

  const getDateDifference = (dataContLeft) => {
    var today = new Date();
    var date = new Date(dataContLeft);
    var diffMs = (today - date); // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minute

    const ans = diffDays>0 ? `${diffDays} Day` : '' + diffHrs>0 ? `${diffHrs} Hours` : '' + `${diffMins} mins`;
    console.log(today, dataContLeft, diffDays, diffHrs, diffMins, ans);
    return ans;
  }

  return (
    <div className="notification" style={{"justifyContent" : "start", "cursor" : "pointer"}} onClick={e=>props.notificationClick(props.id, props.job_id)}>
      <img src={props.img} alt="" className="notiImg" />
      <div className="notiBody">
        <div className="notiBodyHead">
          <div className="notiName">{props.name}</div>
          <div className="notiTime">{getDateDifference(props.created_at)}</div>
        </div>
        <div className="notiDesc">{props.desc}</div>
      </div>
    </div>
  )
}


const checkEmployer = () => {
  if(Cookies.get('access_token')){
    if(Cookies.get('user') === 'DistrictAdmin'){
      window.location.href('/dashboard')
    }
  }else{
    window.location.href('/login');
  }
}
