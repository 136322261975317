import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom'
import OutlinedInput from '@material-ui/core/OutlinedInput';



import NavBar_noProfile from '../NavBar_noProfile';
import Footer from '../Footer';
import { Helmet } from "react-helmet";
import { Box, Container, Grid, Typography, Tabs, Tab } from '@material-ui/core';
import './styles.css';
import blog1 from '../../images/blog1.png';
import blog2 from '../../images/blog2.png';
import blog3 from '../../images/blog3.png';
import blog4 from '../../images/blog4.png';
import blog5 from '../../images/blog5.png';
import blog6 from '../../images/blog6.png';
import blog7 from '../../images/blog7.png';
import blog8 from '../../images/blog8.png';
// import blogsHoriz from './blogsData.js';

import Carousel from 'react-material-ui-carousel'
import axios from 'axios';


import srch from './searchicon.svg';
import axiosInstance from '../../axios';


function createBlog(blog) {


    return (
        <Grid item lg={4} md={4} sm={12} xs={12} style={{ padding: '1.5em 1em 1em 1em' }}>

            <img src={blog.image} alt="" style={{ maxWidth: '100%', maxHeight: '90%' }} />
            <div>
                <p style={{ marginTop: '2rem' }}>{blog.posting_date}</p>


                <Link to=
                    {{
                        pathname: `/blog/${blog.id}`
                    }}
                >

                    <h5 style={{ color: 'black' }}>{blog.blog_title}</h5></Link>
                <p dangerouslySetInnerHTML={{__html : blog.blog_content}} ></p>
                <p><strong>-{blog.author_name}</strong></p>
            </div>
        </Grid>
    );
}

function Blog() {
    //Form:

    // For swipable reviews:
    const [open, setOpen] = useState(false);
    const [blogsHoriz, setblogsHoriz] = useState([]);
    const [mainBlog, setmainBlog] = useState('');

    const [latestBlogs, setlatestBlogs] = useState([]);

    const [contactForm, setcontactForm] = useState({
        first_name : '',
        last_name : '',
        email : '',
        message : ''
    });

    const imageArray = [blog1, blog2, blog3, blog4, blog5, blog6, blog7, blog8];

    const randomImage = () => {
        return imageArray[Math.floor(Math.random()*imageArray.length)];
    }

    useEffect(()=>{
        getData();
    }, [])

    const getData = () => {
        // axios.get('http://127.0.0.1:8000/blogs/blog_list').then(res=>{
        //     setblogsHoriz(res.data.results.slice(1, 4));
        //     setmainBlog(res.data.results[0]);
        // }).catch(err=>{
        //     console.log(err);
        // })

        axiosInstance.get('/blogs/blog_list').then(res=>{
            setblogsHoriz(res.data.results.slice(1, 4));
            setmainBlog(res.data.results[0]);
        }).catch(err=>{
            console.log(err);
        })

        // fetching latest blogs
        axiosInstance.get('/blogs/latest_blog').then(res=>{
            setlatestBlogs(res.data.results);
        }).catch(err=>{
            console.log(err);
        })
        // axiosInstance.get('blogs.')
    }

    const submitContactUs = () => {
        if(contactForm.first_name && contactForm.last_name && contactForm.email && contactForm.message){
            axiosInstance.post('/blogs/contact/', contactForm).then(res=>{
                alert('Successfully Submitted');
                setcontactForm({
                    first_name : '',
                    last_name : '',
                    email : '', 
                    message : ''
                })
            }).catch(err=>{
                console.log(err);
                alert(err.error);
            })
        }else{
            alert('Please recheck the Form');
        }
    }

    return (
        <div>
            <Helmet>
                <title>Blogs | Frame Change</title>
            </Helmet>
            <NavBar_noProfile />

            {/* Search Bar */}
            <Container maxWidth="lg" style={{ width: '100%', marginTop: '120px' }}>
                <Grid container className='searchBar' spacing={1} style={{ width: '95%', margin: 'auto' }}>
                    <img src={srch} alt="" style={{ maxWidth: '100%', position: 'absolute' }} />
                    <div style={{ margin: '0px 30px' }}>Search</div>
                </Grid>

                {/* Heading */}
                {
                    mainBlog ? <>
                        <Grid container spacing={1} style={{ width: '100%', marginTop: '30px' }}>
                            <Grid item lg={6} md={6} sm={12} xs={12} style={{ padding: '1.5em 1em 1em 1em' }}>
                                <img src={mainBlog.blog_image ? mainBlog.blog_image : blog1} alt="" style={{ maxWidth: '100%' }} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12} style={{ alignContent: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '1em 1em 2em 1em' }}>
                                <p>{mainBlog.posting_date}</p>

                                <Link to=
                                    {{
                                        pathname: `/blog/${mainBlog.id}`
                                    }}
                                >
                                    <h5 style={{ color: 'black' }}>{mainBlog.blog_title}</h5></Link>
                                <p dangerouslySetInnerHTML={{__html : mainBlog.blog_content}}></p>
                                <p><strong>{mainBlog.author_name}</strong></p>
                            </Grid>
                        </Grid>
                    </> : <h2 className='d-flex justify-content-center pt-3'>Coming Soon! Stay Tuned</h2>
                }
            </Container>


            {/* Horizontal blogs */}
            <Container maxWidth="lg" style={{ display: 'flex', flexDirection: 'row' }}>
                <Grid container spacing={1} style={{ width: '100%', marginTop: '30px' }}>

                    {blogsHoriz.map(createBlog)}


                </Grid>
            </Container>


            {/* Latest blogs */}
            <Container maxWidth="lg" style={{ padding: '3rem 3rem' }}>
                {
                    latestBlogs.length ? 
                    <h2>THE LATEST</h2> : null
                }
                <Grid container lg={12} md={12} sm={12} xs={12} spacing={1} style={{ width: '100%', marginTop: '20px' }}>
                    {
                        latestBlogs.length ? <>
                            <Grid container lg={8} md={8} sm={12} xs={12} spacing={1} style={{ width: '100%' }}>
                                <Grid container lg={6} md={6} sm={12} xs={12} spacing={1} style={{ width: '100%' }}>
                                    <img src={latestBlogs[0].blog_image ? latestBlogs[0].blog_image : randomImage()} alt="" style={{ maxWidth: '100%', padding: '2em 0em 2em 0em' }} />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12} style={{ alignContent: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '30px' }}>
                                    <p>{latestBlogs[0].posting_date}</p>
                                    <Link to=
                                        {{
                                            pathname: `/blog/${latestBlogs[0].id}`
                                        }}
                                    >
                                        <h5 style={{ color: 'black' }}>{latestBlogs[0].blog_title}</h5></Link>
                                    <p dangerouslySetInnerHTML={{__html : latestBlogs[0].blog_content}}></p>
                                    <p><strong>- {latestBlogs[0].author_name}</strong></p>
                                </Grid>
                            </Grid>
                        </> : null
                    }

                    {/* Subscription */}
                    <Grid container className="subscription" lg={4} md={4} sm={12} xs={12} spacing={1} style={{ width: '100%' }}>
                        <div>
                            <h5 style={{ fontSize: '1rem', textAlign: 'center', padding: '1rem' }}>Learn about more insightful topics regarding teaching profession</h5>
                            <p style={{ fontSize: '0.9rem', textAlign: 'center' }}>Stay up to date, subscribe to user our newsletter</p>
                            <p style={{ fontSize: '0.9rem', paddingLeft: '0.5rem' }}>Select topic of interest</p>
                            <div className="bubblediv" style={{ maxWidth: '100%', display: 'flex', flexDirection: "row" }}>
                                <p className="bubble" style={{ fontSize: '0.9rem', paddingLeft: '0.5rem' }}>Student Management</p>
                                <p className="bubble" style={{ fontSize: '0.9rem', paddingLeft: '0.5rem' }}>Improve Teaching</p>
                            </div>
                            <div className="bubblediv" style={{ maxWidth: '100%', display: 'flex', flexDirection: "row" }}>
                                <p className="bubble" style={{ fontSize: '0.9rem', paddingLeft: '0.5rem' }}>Conducting exams</p>
                                <p className="bubble" style={{ fontSize: '0.9rem', paddingLeft: '0.5rem' }}>Student Interactions</p>
                            </div>

                            <div className="email">
                                <div style={{ margin: '0px 20px' }}>Enter your email</div>
                            </div>

                            <div className="subscribeButton">
                                <div style={{ margin: '0px 20px' }}>Subscribe</div>
                            </div>

                        </div>

                    </Grid>




                </Grid>
                {
                    latestBlogs.length > 1 ? <>
                        {
                            latestBlogs.slice(1, 4).map(item=>{
                                return(
                                    <Grid container lg={8} md={8} sm={12} xs={12} spacing={1} style={{ width: '100%', marginTop: '20px' }}>
                                        <Grid container lg={6} md={6} sm={12} xs={12} spacing={1} style={{ width: '100%' }}>
                                            <img src={item.blog_image ? item.blog_image : randomImage()} alt="" style={{ maxWidth: '100%', padding: '2em 0em 2em 0em' }} />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12} style={{ alignContent: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '30px' }}>
                                            <p>{item.posting_date}</p>
                                            <Link to=
                                                {{
                                                    pathname: `/blog/${item.id}`
                                                }}
                                            >
                                                <h5 style={{ color: 'black' }}>{item.blog_title}</h5></Link>
                                            <p dangerouslySetInnerHTML={{__html : item.blog_content}} ></p>
                                            <p><strong>-{item.author_name}</strong></p>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </> : null
                }
                

            </Container>


            {/* Contact Us */}
            <Container className='contactUs' maxWidth="100%" style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <Grid container style={{ width: '100%', gap: 80 }} >
                    <Grid className='contactForm' item lg={5} md={5} sm={12} xs={12} >
                        <h3>Contact Us</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                        <Grid container direction='column' >
                            <span className='label'>First Name</span>
                            <OutlinedInput
                                className='inputField'
                                placeholder='First Name'
                                value={contactForm.first_name}
                                onChange={e=>setcontactForm({...contactForm, first_name : e.target.value})}
                                variant='outlined'
                            />
                            <span className='label'>Last Name</span>
                            <OutlinedInput
                                className='inputField'
                                placeholder='Last Name'
                                value={contactForm.last_name}
                                onChange={e=>setcontactForm({...contactForm, last_name : e.target.value})}
                                variant='outlined'
                            />
                            <span className='label'>Email</span>
                            <OutlinedInput
                                className='inputField'
                                placeholder='Email'
                                value={contactForm.email}
                                onChange={e=>setcontactForm({...contactForm, email : e.target.value})}
                                variant='outlined'
                            />
                            <span className='label'>Message</span>
                            <OutlinedInput
                                className='inputField'
                                value={contactForm.message}
                                onChange={e=>setcontactForm({...contactForm, message : e.target.value})}
                                placeholder='Message'
                                variant='outlined'
                            />
                        </Grid>


                        <div className="subscribeButton" onClick={submitContactUs} style={{ width: '30%', cursor:'pointer' }}>
                            Submit
                        </div>
                    </Grid>

                    {/* Swipable Review cards */}
                    <Grid item className="card" lg={5} md={5} sm={12} xs={12} style={{ margin: 'auto'}}>
                        <div>
                            <Carousel
                                className="carousel"
                                animation='slide'
                                autoPlay={false}
                                activeIndicatorIconButtonProps={{
                                    style: {
                                        backgroundColor: '#DDE3EE',
                                        color: '#FD8012',
                                        marginTop: '5px'
                                    }
                                }}

                                indicatorIconButtonProps={{
                                    style: {
                                        color: '#DDE3EE',
                                        marginTop: '5px'
                                    }
                                }}
                            >
                                <div className='carouselItem'>
                                    <p className="quote" >“</p>
                                    <p className="comment">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet egestas nunc, suscipit donec sed urna, rhoncus enim volutpat. Aliquam in dapibus pellentesque diam elit orci bibendum. Aliquet a felis semper proin ut facilisi felis semper proin ut facilisi et et odio. </p>
                                    <p className="commentBy">Jenny Wilson</p>
                                    <p className="commentLoc">Lorem Ipsum</p>
                                </div>
                                <div className='carouselItem'>
                                    <p className="quote" >“</p>
                                    <p className="comment">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet egestas nunc, suscipit donec sed urna, rhoncus enim volutpat. Aliquam in dapibus pellentesque diam elit orci bibendum. Aliquet a felis semper proin ut facilisi felis semper proin ut facilisi et et odio.</p>
                                    <p className="commentBy">Jenny Wilson</p>
                                    <p className="commentLoc">Lorem Ipsum</p>
                                </div>
                                <div className='carouselItem'>
                                    <p className="quote" >“</p>
                                    <p className="comment">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet egestas nunc, suscipit donec sed urna, rhoncus enim volutpat. Aliquam in dapibus pellentesque diam elit orci bibendum. Aliquet a felis semper proin ut facilisi felis semper proin ut facilisi et et odio.</p>
                                    <p className="commentBy">Jenny Wilson</p>
                                    <p className="commentLoc">Lorem Ipsum</p>
                                </div>
                            </Carousel>
                        </div>
                    </Grid>
                </Grid>

            </Container>






            <Footer style={{ marginTop: '1000px' }} />
        </div >
    )
}

export default Blog;
