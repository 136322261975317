import React, { useState, useRef, useEffect } from 'react'
import { useRecordWebcam, CAMERA_STATUS } from "react-record-webcam"
import { Helmet } from "react-helmet";
import vidRecord from '../../images/vidRecord.png'
import Upload from '../../images/Upload.png'
import UploadWhite from '../../images/Upload.svg'
import UploadBlk from '../../images/UploadBlk.png'
import Cross from '../../images/Cross.png'
import Tick from '../../images/Tick.png'
import Retry from '../../images/Retry.png';
import axios from 'axios';
import axiosInstance from '../../axios';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import './styles.css'
import Alert from '../alerts/alert';

const OPTIONS = {
  filename: "test-filename",
  fileType: "mp4",
  width: 1920,
  height: 1080
};

const VideoRecording = () => {
  
  // Miniute limit for video recording
  const minuteLimitForVideo=2;
  //ALERT CODE:

  const [alertComp, setAlert] = React.useState(false);
  const [typeOfAlert, setType] = React.useState("");
  const [descOfAlert, setDesc] = React.useState("");
  const recordWebcam = useRecordWebcam(OPTIONS);

  const getRecordingFileHooks = async () => {
    const blob = await recordWebcam.getRecording()
    setVideoFilePath(URL.createObjectURL(blob));
    setvidFile({ blob });
    setVidFinal(true)
  }

  const [timer, setTimer] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const countRef = useRef(null)

  const [videoFilePath, setVideoFilePath] = useState(null);
  const [vidFinal, setVidFinal] = useState(false);
  const [vidFile, setvidFile] = useState(null);
  const history = useHistory();
  const [accessToken, setaccessToken] = useState(null);

  const getData = () => {
    if (!accessToken)
      return;

    axiosInstance.get('/seeker/record_video/').then(res => {

      console.log(res);
    }).catch(err => {
      console.log(err);
    })
  }

  useEffect(() => {
    if (Cookies.get('access_token')) {
      setaccessToken(Cookies.get('access_token'));
      recordWebcam.open();

      getData();
    } else {
      window.location.href = '/login';
    }
  }, [accessToken])

  const handleStart = () => {
    recordWebcam.start()
    setIsActive(true)
    setIsPaused(true)
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1)
    }, 1000);
    setTimeout(function(){
      setAlert(true);
      setType('warning');
     setDesc(`Your ${minuteLimitForVideo} minutes limit has been reached`); 
     handleReset(); },1000*60*minuteLimitForVideo);
  }

  const handleReset = () => {
    recordWebcam.stop()
    clearInterval(countRef.current)
    setIsActive(false)
    setIsPaused(false)
    setTimer(0)
  }

  const formatTime = () => {
    const getSeconds = `0${(timer % 60)}`.slice(-2)
    const minutes = `${Math.floor(timer / 60)}`
    const getMinutes = `0${minutes % 60}`.slice(-2)
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2)

    return `${getMinutes} : ${getSeconds}`
  }

  const handleVideoUpload = (event) => {
    recordWebcam.close()
    setVideoFilePath(URL.createObjectURL(event.target.files[0]));
    setvidFile({
      blob: event.target.files[0]
    });

    setVidFinal(true);
  }

  const handleReject = () => {
    setVideoFilePath(null)
    setVidFinal(false)
    recordWebcam.close()
  }

  const handleUploadVideo = () => {
    let formD = new FormData();
    // formD.append('access', accessToken);
    formD.append('video', vidFile.blob);
    // console.log(videoFilePath, vidFile);
    axiosInstance.post('/seeker/record_video/', formD).then(res => {
      setAlert(true);
      setType("success");
      setDesc('Successfully Uploaded');
      history.push('/profile');
    }).catch(err => {
      console.log(err);
      setAlert(true);
      setType("error");
      setDesc('Something went wrong');
    })
  }
  return (
    <div>
      <Helmet>
        <title>Record your Video | Frame Change
        </title>
      </Helmet>

      {/* ALERT */}
      {alertComp ? <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlert} /> : null}
      {(!vidFinal) ? (
        <div className='videoRecorderContainer' >
          <div className="vidRecordTitle">Video resume</div>
          <div className="vidRecordSubTitle">Introduce yourself to future employers through a short 2 minute video.</div>
          <div className="recorderContainer">
            <div className="recorderLeft">
              <div className="transcriptTitle">Transcript for you Video Resume</div>
              <p className="transcript">
                Hi there! My name is (Your Name). Currently, I’m completing/ completed my (about education or Work experience). My coursework has provided me with a strong foundation in (your profession), as I’ve already completed courses/ job in the (working/ worked).
                <br />
                <br />
                In the last few years, I’ve been able to augment my (education/job) with hands-on experience, attained through internships/ job experience. (Talk about work experiences) and My (supervisors/ colleague) have all praised my relationship-building and communication skills.
                <br />
                <br />
                Finally, I simply love (your field). I love making an impact, spreading the word, and seeing the change for the business. I’m dedicated and passionate, and I guarantee that you won’t regret hiring me. Thanks so much for taking the time to listen!
              </p>
              <div className="transcriptTitle">Guidelines</div>
              <p className="transcript">
                1. Video can be recorded using any device (like Smartphone, Laptop, Tablet), make sure your device has properly functioning Camera and Microphone.
                <br />
                2. Record the Video in a well lit environment.
                <br />
                3. Ensure that there is no background noise while recording the video.
                <br />
                4. Please record the video according to the Transcript provided above.
              </p>
            </div>
            <div className="recordRight">
              <div className="videoCont">
                {
                  (recordWebcam.status === CAMERA_STATUS.OPEN ||
                    recordWebcam.status === CAMERA_STATUS.RECORDING ||
                    recordWebcam.status === CAMERA_STATUS.PREVIEW) ? null : (
                    <div className="reqestPermissionCont">
                      <img src={vidRecord} alt="" style={{ marginBottom: '15px' }} />
                      To record video, your browser will need to
                      <br />
                      request access to your camera & microphone
                      <button onClick={recordWebcam.open} className='reqPermissonBtn'>Request Permission</button>
                    </div>
                  )
                }
                <video
                  ref={recordWebcam.webcamRef}
                  style={{
                    display: `${recordWebcam.status === CAMERA_STATUS.OPEN ||
                        recordWebcam.status === CAMERA_STATUS.RECORDING
                        ? "block"
                        : "none"
                      }`
                  }}
                  autoPlay
                  muted
                  className='vidPlayer'
                />
                <video
                  ref={recordWebcam.previewRef}
                  style={{
                    display: `${recordWebcam.status === CAMERA_STATUS.PREVIEW ? "block" : "none"
                      }`
                  }}
                  controls
                  className='vidPlayer'
                />
              </div>
              {
                (recordWebcam.status === CAMERA_STATUS.OPEN ||
                  recordWebcam.status === CAMERA_STATUS.RECORDING ||
                  recordWebcam.status === CAMERA_STATUS.PREVIEW) ? null : (
                  <div className="controlsCont">
                    <input type="file" id="upload" hidden accept="video/*" onChange={handleVideoUpload} />
                    <label for="upload" className='fileUploadInitial'><img src={Upload} alt="" className='uploadIconBlu' /> <img src={UploadWhite} alt="" className='uploadIconWht' /> Upload Video</label>
                  </div>
                )
              }
              {
                (recordWebcam.status === CAMERA_STATUS.OPEN) ? (
                  <div className="recorderOptions">
                    <div className="recordTextC2A">Click on <span style={{ color: '#DC404E' }}>RECORD</span> to start recording</div>
                    <div className="startRecording" onClick={handleStart}>
                      <div className="redCircle"></div>
                    </div>
                    <div className="uploadSec">
                      <input type="file" id="upload" hidden accept="video/*" onChange={handleVideoUpload} />
                      <label for="upload" className='fileUploadSec'><img src={UploadBlk} alt="" /> Upload Video</label>
                    </div>
                  </div>
                ) : null
              }
              {
                (recordWebcam.status === CAMERA_STATUS.RECORDING) ? (
                  <div className="recorderOptions">
                    <div className="recordTextC2A" style={{ color: '#DC404E' }}>{formatTime()}</div>
                    <div className="startRecording squareFlex" onClick={handleReset}>
                      <div className="redSquare"></div>
                    </div>
                    <div className="uploadSec">
                      <input type="file" id="upload" hidden accept="video/*" onChange={handleVideoUpload} />
                      <label for="upload" className='fileUploadSec'><img src={UploadBlk} alt="" /> Upload Video</label>
                    </div>
                  </div>
                ) : null
              }
              {
                (recordWebcam.status === CAMERA_STATUS.PREVIEW) ? (
                  <div className="recorderOptions">
                    <div className="recordTextC2A" style={{ color: '#054D92', fontWeight: '500' }}>Would you like to confirm this video?</div>
                    <div className="finalControls">
                      <div className="crossSelect" onClick={recordWebcam.close}>
                        <img src={Cross} alt="" />
                      </div>
                      <div className="crossSelect" onClick={getRecordingFileHooks}>
                        <img src={Tick} alt="" />
                      </div>
                    </div>
                    <div className="uploadSec">
                      <input type="file" id="upload" hidden accept="video/*" onChange={handleVideoUpload} />
                      <label for="upload" className='fileUploadSec'><img src={UploadBlk} alt="" /> Upload Video</label>
                    </div>
                  </div>
                ) : null
              }
            </div>
          </div>
        </div>
      ) : (
        <div className='previewCont'>
          <div className="vidRecordTitle">Preview Video resume</div>
          <div className="vidRecordSubTitle">Click on save and confirm to save this video or click on retry</div>
          <video src={videoFilePath} controls className='previewVid' />
          <div className="previewControlsCont">
            <div className="previewControlRetry" onClick={handleReject}>
              <img src={Retry} alt="" /> Retry
            </div>
            <div className="previewControlAccept" onClick={handleUploadVideo}>Save and Confirm</div>
          </div>
        </div>
      )
      }
    </div>
  )
}

export default VideoRecording

