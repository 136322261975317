import { Grid, IconButton, Typography, InputAdornment, FormHelperText, CircularProgress } from '@material-ui/core';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import Logo from './logo.svg';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { FcGoogle } from 'react-icons/fc';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { SiMicrosoftoutlook, SiFacebook, SiApple, SiLinkedin } from 'react-icons/si';
import { AiFillApple, AiFillFacebook, AiFillLinkedin } from 'react-icons/ai';
import { useHistory } from "react-router-dom";
import './style.css';
import GirlImg from './girl.svg';
import Tick from './tick.svg';
import Outlook from '../register/outlook.svg';
import axiosInstance from '../../axios';
import { Alert } from '@mui/material';
import Cookies from 'js-cookie';
import { Helmet } from "react-helmet";
import Modal from '@mui/material/Modal'
import axios from 'axios';
import loaderJson from '../../assets/loader.json';
import mainLoaderJson from '../../assets/mainLoader.json';
import Lottie from 'react-lottie';
import MaintainenceGuy from '../../images/undraw_maintenance_re_59vn 1.png';
// import FacebookLogin from 'react-facebook-login';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import Alert1 from '../alerts/alert';


import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

const theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',

        ].join(','),
    },
});



const submitFormOnEnter = (e) => {
    if (e.keyCode === 13) {
        document.getElementById('submitButton').click();
    }
    return;
}


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        if (Cookies.get('access_token')) {
            window.location.href = '/dashboard';
        }
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}
const Login = (props) => {

    //ALERT CODE:
    const [alertComp, setAlert1] = React.useState(false)
    const [typeOfAlert, setType1] = React.useState('')
    const [descOfAlert, setDesc1] = React.useState('');

    const setAlertFunc = (type, desc) => {
        setAlert1(true);
        setType1(type);
        setDesc1(desc);
    }

    const [loginModal, setLoginModal] = useState(false)

    const history = useHistory();

    const [values1, setValues1] = React.useState({
        password: '',
        showPassword: false,
    });

    const handleChange1 = (prop) => (event) => {
        setAlert(false);
        setpasswordError(false);
        setpasswordErrorText('');
        setValues1({ ...values1, [prop]: event.target.value });
        setLoginData({ ...LoginData, [prop]: event.target.value });
    };
    const handleClickShowPassword1 = () => {
        setValues1({ ...values1, showPassword: !values1.showPassword });
    };


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [LoginData, setLoginData] = useState({
        email: '',
        password: '',

    })

    const [passwordError, setpasswordError] = React.useState(false);
    const [passwordErrorText, setpasswordErrorText] = React.useState("");

    const [emailError, setemailError] = React.useState(false);
    const [emailErrorText, setemailErrorText] = React.useState("");

    const ValidateSingleField = (data) => {
        if (data == "")
            return true;
        return false;

    }

    const validateData = () => {

        setpasswordError(ValidateSingleField(LoginData.password));
        ValidateSingleField(LoginData.password) ? setpasswordErrorText('Password cannot be blank') : setpasswordErrorText('');

        setemailError(ValidateSingleField(LoginData.email));
        ValidateSingleField(LoginData.email) ? setemailErrorText('Email cannot be blank') : setemailErrorText('');

        if (!ValidateSingleField(LoginData.password) && !ValidateSingleField(LoginData.email)) {

            return true;

        }
        return false;

    }

    const [open, setOpen] = React.useState(true);

    const [alert, setAlert] = React.useState(false);

    const [loader, setloader] = React.useState(false);


    const { height, width } = useWindowDimensions();

    const [socialAuthLoader, setsocialAuthLoader] = useState(false);
    // facebook login
    const facebookLogin = () => {
        function init() {

            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: '328723999219498',
                    cookie: true,
                    xfbml: true,
                });

                window.FB.AppEvents.logPageView();

            };
        }

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        function fbLogin() {
            window.FB.login(function (response) {
                if (response.authResponse) {
                    fbAfterLogin();
                }
            })
        }

        function fbAfterLogin() {
            window.FB.getLoginStatus(function (response) {
                if (response.status === 'connected') {
                    window.FB.api('/me', function (response) {
                        console.log(response.accessToken);
                    })
                }
            })
        }

        fbLogin();
    }

    const facebookAuth = (auth_token) => {
        setsocialAuthLoader(true);
        const data = {
            auth_token: auth_token
        }
        axiosInstance.post('auth/facebook/', data)
            .then(res => {
                setsocialAuthLoader(false);
                console.log(res);
                Cookies.set('access_token', res.data.access);
                Cookies.set('refresh_token', res.data.refresh);
                Cookies.set('user', res.data.user);

                axiosInstance.get('/auth/get_user_profile/').then(res1 => {
                    const data = res1.data.msg;
                    console.log(data);
                    localStorage.setItem('name', data.name);
                    localStorage.setItem('email', data.email);
                    localStorage.setItem('phone', data.phone);
                    localStorage.setItem('profile_pic', data.profile_pic);
                    localStorage.setItem('candidate_id', data.candidate_id);
                    if (Cookies.get('user') === 'DistrictAdmin') {
                        history.push('/employer/dashboard');
                    }
                    else {
                        history.push('/dashboard');
                    }
                })
                    .catch(err => {
                        setsocialAuthLoader(false);
                        alert('Something Went Wrong');
                        console.log(err);
                    })


            }).catch(err => {
                setsocialAuthLoader(false);
                setAlertFunc('warning', err.response.data.detail);
                console.log(err, err.response.data);
                // alert(err.response.data.detail);
            })
    }

    // LinkedIn Login
    const { linkedInLogin } = useLinkedIn({
        clientId: '78t0pi37fpcv9t',
        scope: "r_emailaddress r_liteprofile",
        state: 'someRandom',
        redirectUri: 'https://fc.unisight.in/auth/linkedin/',
        onSuccess: (code) => {
            console.log(code);
            linkedinAuth(code);
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const linkedinAuth = (auth_token) => {
        setsocialAuthLoader(true);
        const data = {
            auth_token: auth_token
        }
        axiosInstance.post('auth/linkedin/', data)
            .then(res => {
                setsocialAuthLoader(false);
                console.log(res);
                Cookies.set('access_token', res.data.access);
                Cookies.set('refresh_token', res.data.refresh);
                Cookies.set('user', res.data.user);

                axiosInstance.get('/auth/get_user_profile/').then(res1 => {
                    const data = res1.data.msg;
                    console.log(data);
                    localStorage.setItem('name', data.name);
                    localStorage.setItem('email', data.email);
                    localStorage.setItem('phone', data.phone);
                    localStorage.setItem('profile_pic', data.profile_pic);
                    localStorage.setItem('candidate_id', data.candidate_id);

                    if (Cookies.get('user') === 'DistrictAdmin') {
                        history.push('/employer/dashboard');
                    }
                    else {
                        history.push('/dashboard');
                    }
                })
                    .catch(err => {
                        setsocialAuthLoader(false);
                        alert('Something Went Wrong');
                        console.log(err);
                    })


            }).catch(err => {
                setsocialAuthLoader(false);
                setAlertFunc('warning', err.response.data.detail);
                console.log(err, err.response.data);
                // alert(err.response.data.detail);
            })

    }

    // google login
    const googleAuth = (response) => {
        const auth_token = response.credential;
        setsocialAuthLoader(true);
        const data = {
            auth_token: auth_token
        }
        axiosInstance.post('auth/google/', data)
            .then(res => {
                setsocialAuthLoader(false);
                console.log(res);
                Cookies.set('access_token', res.data.access);
                Cookies.set('refresh_token', res.data.refresh);
                Cookies.set('user', res.data.user);

                axiosInstance.get('/auth/get_user_profile/').then(res1 => {
                    const data = res1.data.msg;
                    console.log(data);
                    localStorage.setItem('name', data.name);
                    localStorage.setItem('email', data.email);
                    localStorage.setItem('phone', data.phone);
                    localStorage.setItem('profile_pic', data.profile_pic);
                    localStorage.setItem('candidate_id', data.candidate_id);

                    if (Cookies.get('user') === 'DistrictAdmin') {
                        history.push('/employer/dashboard');
                    }
                    else {
                        history.push('/dashboard');
                    }
                })
                    .catch(err => {
                        setsocialAuthLoader(false);
                        alert('Something Went Wrong');
                        console.log(err);
                    })


            }).catch(err => {
                setsocialAuthLoader(false);
                setAlertFunc('warning', err.response.data.detail);
                console.log(err, err.response.data);
                // alert(err.response.data.detail);
            })
    }

    useEffect(() => {
        if (window.google != undefined) {
            window.google.accounts.id.initialize({
                client_id: '860859219621-6oa5vude3ko11e778rlqoeouk7blfjef.apps.googleusercontent.com',
                callback: googleAuth,
                context: 'use'
            });

            window.google.accounts.id.renderButton(
                document.getElementById('signInGoogle'),
                { theme: "outline", size: "large" }
            );
        }

    })


    return (
        <ThemeProvider theme={theme}>
            {/* ALERT */}
            {alertComp ? (
                <Alert1 type={typeOfAlert} desc={descOfAlert} alertState={setAlert} />
            ) : null}
            {
                socialAuthLoader ?
                    <div className='' style={{ height: '120vh', display: 'grid', placeContent: 'center', width: '100vw', backgroundColor: 'rgb(255,255,255,0.6)', position: 'fixed', zIndex: '100' }}>
                        {/* <iframe src="https://embed.lottiefiles.com/animation/66433"></iframe> */}
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: loaderJson,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice"
                                }
                            }}
                            height={200}
                            width={200}
                        />
                    </div> : null
            }
            
            <Helmet>
                <title>Frame Change - Login</title>
            </Helmet>
            <div style={{ position: 'relative', zIndex: '1' }}>
                <Grid container style={{ minHeight: '100vh' }}>
                    {width > 959 ? <Grid style={{ background: '#F1F1F1' }} item lg={8} md={7} sm={12} xs={12}>
                        <div style={{ position: 'sticky', top: '0' }}>
                            <img style={{ margin: '3% 5%' }} src={Logo} alt='logo' />
                            <Grid item container>
                                <Grid item lg={6} md={5} >
                                    <div style={{ marginLeft: '13%' }}>
                                        <Typography style={{ fontWeight: '600', fontSize: '33px' }}>Find your next teaching job. Right now.</Typography>
                                        <br />
                                        <Typography variant="body1" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor</Typography>
                                        <br />
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <img style={{ marginRight: '10px' }} src={Tick} alt='tick' />
                                            <Typography variant="body1" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam fringilla urna, porttitor</Typography>
                                        </div>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <img style={{ marginRight: '10px' }} src={Tick} alt='tick' />
                                            <Typography variant="body1" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam fringilla urna, porttitor</Typography>
                                        </div>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <img style={{ marginRight: '10px' }} src={Tick} alt='tick' />
                                            <Typography variant="body1" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam fringilla urna, porttitor</Typography>
                                        </div>
                                        <br />

                                    </div>

                                </Grid>
                                <Grid item lg={6} md={7} >
                                    <div style={{ position: 'relative', height: '80vh' }}>
                                        <img style={{ width: '100%', position: 'absolute', bottom: '0em' }} src={GirlImg} alt='girl' />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid> : <></>
                    }

                    <Grid style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} item lg={4} md={5} sm={12} xs={12}>
                        {/* <div style={{ height: '54px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span className='text'>Are you an Employer? <span style={{ fontWeight: '600' }}><u>Register here</u></span></span>
                </div>
                <hr style={{ border: '1px solid ##F1F1F1', color: '##F1F1F1', width: '100%' }} /> */}
                        {width > 959 ? <></> :
                            <div style={{ background: '#FBF5F3', width: '100%', height: '8vh' }}>
                                <img style={{ paddingTop: '5px', width: '90px', position: 'absolute', top: '0', left: '0' }} src={Logo} alt='logo' />
                            </div>}
                        <Grid onKeyDown={submitFormOnEnter} style={{ marginTop: '15%', padding: '5% 15%' }} container direction='column' id='main_container' >
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                <Typography className='text' style={{ fontSize: '25px', fontWeight: '600' }}>Sign in</Typography>
                                <br />
                                <span className='label'>Don’t have an account?<span style={{ fontWeight: '700', color: '#0072C6', cursor: 'pointer' }} onClick={() => history.push("/register", { from: "Login" })}>  Create one</span></span>
                            </div>
                            <br />
                            <span className='label'>Email</span>
                            <TextField className='inputField' placeholder='name@mail.com' type="email" error={emailError} helperText={emailErrorText} value={LoginData.email} onChange={(event) => { setAlert(false); setemailError(false); setemailErrorText(''); setLoginData({ ...LoginData, email: event.target.value }) }} variant='outlined' />
                            <br />
                            <span className='label'>Password</span>
                            <OutlinedInput
                                className='inputField'
                                placeholder='6 characters, 1 capital letter'
                                variant='outlined'
                                error={passwordError}
                                type={values1.showPassword ? 'text' : 'password'}
                                value={LoginData.password}
                                onChange={handleChange1('password')}


                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword1}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {values1.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText style={{ color: 'red' }}>{passwordErrorText}</FormHelperText>

                            {alert ? <Alert style={{ marginTop: "1em" }} severity="error" sx={{ width: '100%' }}>
                                Invalid Credentials!
                            </Alert> : <></>}

                            <Grid container justifyContent='space-between' style={{ marginTop: '10px' }}>
                                <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '20px 0' }}>
                                    <Checkbox
                                        style={{ padding: '0', color: '#0DAC69' }}
                                        defaultChecked
                                        id="keepMeSignedIn"
                                    />
                                    <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Keep me signed in</span>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '20px 0', display: 'flex', justifyContent: width >= 600 ? 'flex-end' : 'center', cursor : 'pointer' }} onClick={() => history.push('/forgot-password')}>
                                    <span className='label' style={{ color: '#0072C6', fontWeight: '600' }}>Forgot password?</span>
                                </Grid>
                            </Grid>
                            <br />
                            {loader ? <div style={{ display: 'flex', justifyContent: 'center' }} >
                                <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                            </div> : <Button id='submitButton' style={{ textTransform: 'none', fontSize: "18px", fontFamily: 'Montserrat', background: '#EB881B', color: '#FFF', fontWeight: '600', borderRadius: '25px', padding: '10px' }}
                                onClick={() => {

                                    if (validateData()) {
                                        const data = {
                                            username: LoginData.email,
                                            password: LoginData.password,

                                        }

                                        // console.log(data);
                                        setloader(true);
                                        axiosInstance.post('/auth/login/', data).then((res) => {
                                            setAlert(false);
                                            // console.log(res);
                                            const data = res.data;
                                            localStorage.setItem('access_token', data.access);
                                            localStorage.setItem('refresh_token', data.refresh);

                                            Cookies.set('access_token', data.access);
                                            Cookies.set('refresh_token', data.refresh);
                                            Cookies.set('user', data.user);
                                            console.log(document.getElementById("keepMeSignedIn").checked);
                                            if (document.getElementById("keepMeSignedIn").checked) {
                                                localStorage.setItem('access_token', data.access);
                                                localStorage.setItem('user', data.user);
                                                localStorage.setItem('refresh_token', data.refresh);
                                            } else {
                                                localStorage.removeItem('access_token');
                                                localStorage.removeItem('user');
                                                localStorage.removeItem('refresh_token');
                                            }
                                            axiosInstance.get('auth/get_user_profile/',{
                                                headers: {
                                                    Authorization: `Bearer ${data.access}`
                                                }
                                            }).then(res => {
                                                const user_data = res.data.msg;
                                                localStorage.setItem('access_token', data.access);
                                                localStorage.setItem('refresh_token', data.refresh);
                                                localStorage.setItem('name', user_data.name);
                                                localStorage.setItem('email', user_data.email);
                                                localStorage.setItem('phone', user_data.phone);
                                                localStorage.setItem('profile_pic', user_data.profile_pic);
                                                localStorage.setItem('candidate_id', user_data.candidate_id);
                                                if (Cookies.get('user') === 'DistrictAdmin') {
                                                    history.push('/employer/dashboard');
                                                }
                                                else {
                                                    history.push('/dashboard');
                                                }
                                            })
                                                .catch(err => {
                                                    console.log(err.data);
                                                })

                                            // localStorage.setItem('access_token', data.access);
                                            // localStorage.setItem('refresh_token', data.refresh);



                                        }).catch((err) => {
                                            setloader(false);
                                            if (err)
                                                setAlert(true);
                                            setemailError(true);
                                            setpasswordError(true);
                                            console.log(err.data);


                                            // setpasswordError(convertArrayToString(x.password));
                                            // setemailError(convertArrayToString(x.username));

                                            // setemailErrorText(convertArrayToString(x.username));
                                            // setpasswordErrorText(convertArrayToString(x.password));


                                        });

                                    }




                                }
                                }
                                variant="contained" >Sign in</Button>}

                            <br />
                            <br />

                            <div className="separator">or continue with</div>

                            <br />
                            <Grid container justifyContent='space-evenly'>
                                <div className='col-12 d-flex justify-content-center py-3' id='signInGoogle' src={Outlook}><img src={Outlook} /></div>
                                {/* <AiFillFacebook onClick={() => facebookLogin()} style={{ width: '3em', height: '3em', background: '#EDEDED', padding: '9px', borderRadius: '50%', color: '#1976D2', margin: '1px', cursor: 'pointer' }} /> */}
                                <FacebookLogin
                                    appId="328723999219498"
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    callback={(res) => { facebookAuth(res.accessToken) }}
                                    render={
                                        renderProps => (
                                            <AiFillFacebook onClick={renderProps.onClick} style={{ width: '3em', height: '3em', background: '#EDEDED', padding: '9px', borderRadius: '50%', color: '#1976D2', margin: '1px', cursor: 'pointer' }} />
                                        )
                                    }
                                />
                                <AiFillLinkedin onClick={linkedInLogin} style={{ width: '3em', height: '3em', background: '#EDEDED', padding: '9px', borderRadius: '50%', color: '#0077B5', margin: '1px', cursor: 'pointer' }} />

                                {/* <AiFillLinkedin onClick={() => setLoginModal(true)} style={{ width: '3em', height: '3em', background: '#EDEDED', padding: '9px', borderRadius: '50%', color: '#0077B5', margin: '1px', cursor: 'pointer' }} /> */}

                                {/* <FcGoogle onClick={() => setLoginModal(true)} style={{ width: '3em', height: '3em', background: '#EDEDED', padding: '9px', borderRadius: '50%', margin: '1px', cursor: 'pointer' }} /> */}

                                <AiFillApple onClick={() => setLoginModal(true)} style={{ width: '3em', height: '3em', background: '#EDEDED', padding: '9px', borderRadius: '50%', margin: '1px', cursor: 'pointer' }} />

                                {/* <SiMicrosoftoutlook style={{ width: '32px', height: '32px', background: '#EDEDED', padding: '7px', borderRadius: '50%', color: '#0072C6' }} /> */}
                                <img onClick={() => setLoginModal(true)} src={Outlook} style={{ width: '3em', height: '3em', background: '#EDEDED', padding: '9px', borderRadius: '50%', margin: '1px', cursor: 'pointer' }} alt="outlook" />
                            </Grid>

                        </Grid>


                    </Grid>


                </Grid>
            </div>
            <Modal
                open={loginModal}
                onClose={() => { setLoginModal(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modalCont' style={{ width: 'fit-content' }}>
                    <img src={MaintainenceGuy} alt="" />
                    <div className='loginModalHead'>This action is not available yet...</div>
                    <div className='loginModalSubHead'>We are woking on it, please come back later.</div>
                    <button className='loginModalBtn' onClick={() => setLoginModal(false)}>Okay</button>
                </div>
            </Modal>
        </ThemeProvider>
    );
}

export default Login;
