import { Paper, Box, Container, Grid, TextField, Typography, Button, Input, CircularProgress } from '@material-ui/core';
import { FaRegHeart } from 'react-icons/fa';
import { FaHeart } from 'react-icons/fa';
import React, { useCallback, useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import Select from 'react-select';
import S1 from './S1.svg';
import S2 from './S2.svg';
import S3 from './S3.svg';
import S4 from './S4.svg';
import Bg from './bg.svg';
import Search from './search.svg';
import Location from './location.svg';
import DesiredJob from './desiredJob.svg';
import Lateral from './lateral.svg';
import Stretch from './stretch.svg';
import './style.css';
import Dollar from './dollar.svg';
import Phd from './phd.svg';
import Teacher from './teacher.svg';
import FullTime from './fullTime.svg';
import Modal from '@mui/material/Modal'
import Recommend from './recommand'
import Footer from '../Footer/index.js'
import CountUp from 'react-countup';
import srch from './searchicon.svg';
import pin from './pincodeicon.svg';
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_usaLow from "@amcharts/amcharts5-geodata/usaLow";

import bgimg from './mainbg.jpg';


import { BsArrowRightShort } from 'react-icons/bs';
import ProfileImg from './profile.svg';
import ProfileImg0 from './profile0.svg';

import ProfileImg1 from './profile1.svg';
import ProfileImg2 from './profile2.svg';
import {Helmet} from "react-helmet";
import San from './san.svg';
import NavBar from '../NavBar';
import Maps from './maps.svg';
import Featured from './featured.svg';
import { border } from '@mui/system';
import axiosInstance from '../../axios';
import Cookies from 'js-cookie';
import { useHistory, Link } from "react-router-dom";
import MaintainenceGuy from '../../images/undraw_maintenance_re_59vn 1.png'
import axios from 'axios';

import stateflags from './state_flags.js';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}
function Dashboard () {
    const [loginModal, setLoginModal] = useState(false)
    const [recommendModal, setRecommendModal] = useState(false);
    const [accessToken, setAccessToken] = useState('');
    const [about, setabout] = useState('');
    const [seekerPoint, setseekerPoint] = useState(0);
    const [profilePic, setprofilePic] = useState(
        (localStorage.getItem('profile_pic') === '' || localStorage.getItem('profile_pic') === 'null') ? '' : localStorage.getItem('profile_pic')
    );
    const [miscProfile, setmiscProfile] = useState()

    const [recommendedJobs, setrecommendedJobs] = useState([]);
    const [hotJobs, sethotJobs] = useState([]);
    const [modalData, setmodalData] = useState(
        {
            "id": '',
            "city": '',
            "state": '',
            "district": '',
            "address": '',
            "district_id": '',
            "is_saved_job": '',
            "unmatched_school_district": '',
            "is_scraped": '',
            "scraped_job_ID": '',
            "scrap_source": '',
            "date_created": '',
            "job_title": '',
            "apply_url": '',
            "description": '',
            "location": '',
            "expiry_date": '',
            "date_posted": '',
            "vacancies": '',
            "position": '',
            "salary_from": '',
            "salary_to": '',
            "contact_person": '',
            "contact_title": '',
            "contact_phone": '',
            "contact_email": '',
            "salary_range": '',
            "no_of_job_openings": '',
            "shift_type": '',
            "other_details": '',
            "no_of_views": '',
            "phd_required": '',
            "license_required": '',
            "school_district": '',
            "admin": '',
            "non_admin": ''
        }
    );
    const [remJobs, setremJobs] = useState([]);
    const [jobTitleSearch, setjobTitleSearch] = useState(localStorage.getItem('jobTitleSearch') > 0? localStorage.getItem('jobTitleSearch') : '');
    const [locationSearch, setlocationSearch] = useState(localStorage.getItem('locationSearch') > 0? localStorage.getItem('locationSearch') : '');
  

    // loader
    const [recommendJobsLoader, setrecommendJobsLoader] = useState(false);
    const [hotJobsLoader, sethotJobsLoader] = useState(false);

    const [PossibilitiesData, setPossibilitiesData] = useState([]);
    const [possibilitiesLoader, setpossibilitiesLoader] = useState(false);

    // blogs
    const [featuredBlogs, setfeaturedBlogs] = useState([]);
    useEffect(() => {
        if ( Cookies.get( 'access_token' ) ) {
            setAccessToken( Cookies.get( 'access_token' ) );
        } else {
            window.location.href = '/register';
        }
    }, [])

    useEffect(()=>{
        if(accessToken.length > 0){

            setrecommendJobsLoader(true);
            sethotJobsLoader(true);
            setpossibilitiesLoader(true);
            axiosInstance.get('/seeker/dashboard/',{
                timeout : 120*1000,
            }).then(res=>{
                setrecommendJobsLoader(false);
                setrecommendedJobs(res.data.recommendedJobs);
                // console.log(res.data.recommendedJobs);
                // console.log(res)
            })

            axiosInstance.get('/seeker/hotjobs', {
                timeout : 120*1000
            }).then(res=>{
                sethotJobsLoader(false);
                sethotJobs(res.data.hotJobs);
            })
                

            axiosInstance.get('/seeker/get_about/', {
                timeout : 120*1000
            }).then(res=>{
                // console.log(res.data.data.about);
                setabout(res.data.data.about);
            }).catch(err=>console.log(err))

            axiosInstance.get('/seeker/dashboard_misc/', {
                timeout : 120*1000
            }).then(res=>{
                setmiscProfile(res.data.msg);
            }).catch(err=>console.log(err));

            axiosInstance.get('/seeker/profile_point/', {
               timeout : 120*1000
            }).then(res=>{
                setseekerPoint(res.data.msg.percentage);
            }).catch(err=>console.log(err))

            
            
            axiosInstance.get('/seeker/get_possibilities/', {
                timeout : 120*1000
            }).then(res=>{
                setpossibilitiesLoader(false);
                setPossibilitiesData(res.data.data.users);
            }).catch(err=>console.log(err))

            axiosInstance.get('/blogs/featured_blogs/')
            .then(res=>{
                // console.log(res.data.results);
                setfeaturedBlogs(res.data.results);
            }).catch(err=>console.log(err));

        }
    }, [accessToken])

    let history = useHistory();

    const responsive1 = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1.5
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,

        }
    };

    const responsiveHotJobs = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2.5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1.5
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,

        }
    };

    const [toggleHeart, setToggleHeart] = useState(false)

    const changeColor = useCallback(() => {
        setToggleHeart(!toggleHeart)
    }, [])

    const logoArray = [S1, S2, S3, S4];
    const secondLogoArray = [DesiredJob, Stretch, Lateral];
    
    const randomSelector = (array) => {
        return array[Math.floor(Math.random()*array.length)];
    }

    const calculateDollar = (value) => {
        if(value > 0 && value <= 19){
            return(
                <img src={Dollar} />
            )
        }else if(value >19 &&  value<=39){
            return(
                <React.Fragment>
                    <img src={Dollar} />
                    <img src={Dollar} />
                </React.Fragment>
            )
        }else if(value > 39 && value <= 59){
            return(
                <React.Fragment>
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                </React.Fragment>
            )
        }else if(value > 59 && value <= 79){
            return(
                <React.Fragment>
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                </React.Fragment>
            )
        }else if(value > 79){
            return(
                <React.Fragment>
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                </React.Fragment>
            )
        }
    }

    const RecommendedJobsData = [
        {
            title: 'Math Teacher',
            logo: S1,
            secondLogo: DesiredJob,
            location: 'San Francisco, California',

        },
        {
            title: 'Superintendent ',
            logo: S2,
            secondLogo: Stretch,
            location: 'San Francisco, California',

        },
        {
            title: 'Math Teacher',
            logo: S3,
            secondLogo: Lateral,
            location: 'Philadelphia, Pennsylvania',

        },
        {
            title: 'Math Teacher',
            logo: S4,
            secondLogo: Lateral,
            location: 'Philadelphia, Pennsylvania',

        },
    ];

    const HotJobsData = [
        {
            title: 'Superintendent',
            logo: S1,
            secondLogo: DesiredJob,
            location: 'Montgomery County Public Schools - Silver Spring, MD',

        },
        {
            title: 'English Teacher',
            logo: S2,
            secondLogo: Stretch,
            location: 'Montgomery County Public Schools - Silver Spring, MD',

        },
        {
            title: 'Superintendent',
            logo: S1,
            secondLogo: DesiredJob,
            location: 'Montgomery County Public Schools - Silver Spring, MD',

        }
    ];

    const { height, width } = useWindowDimensions();

    const styles = {
        mainBGportrait: {
            background: `url(${bgimg}), #c5c8cc`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            minHeight: '80vw',
            paddingTop: '50vw',
            paddingLeft: '5vw',
            paddingRight: '5vw',
            paddingBottom: '3vw',
        },
        mainBGlandscape: {
            background: `url(${bgimg}), #c5c8cc`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            minHeight: '80vh',
            paddingTop: '50vh',
            paddingLeft: '10vh',
            paddingRight: '10vh',
            paddingBottom: '3vh',
        },
        cardstyle: {
            background: '#fff',
            borderRadius: '12px',
            padding: '3vh',
        },
        searchBoxBig: {
            fontSize: '14px',
            fontFamily: 'Montserrat',
            fontWeight: '600',
            background: '#fff',
            border: '1px solid #BEBEBE',
            borderRadius: '25px',
            paddingLeft: '2vh',
            paddingRight: '2vh',
            paddingTop: '4px',
            paddingBottom: '4px',
        },
        searchBoxSmall: {
            fontSize: '14px',
            fontFamily: 'Montserrat',
            fontWeight: '600',
            background: '#fff',
            border: '1px solid #BEBEBE',
            borderRadius: '25px',
            paddingLeft: '1em',
            paddingRight: '1em',
            paddingTop: '10px',
            paddingBottom: '10px',
        },
        srchField: {
            border: "none",
            borderColor: "transparent",
            outline: "none",
            width: "100%",
        },
        hiddenLabel: {
            height: 0,
            visibility: "hidden",
        },
};

    const jobTitlesArr = [
  {value: 'Athletic Coach', label: 'Athletic Coach'},
  {value: 'Athletic Coordinator', label: 'Athletic Coordinator'},
  {value: 'Athletic Director', label: 'Athletic Director'},
  {value: 'Secretary to Director of Athletics', label: 'Secretary to Director of Athletics'},
  {value: 'Administrative Assistant To Director Of Facilities', label: 'Administrative Assistant To Director Of Facilities'},
  {value: 'Administrative Assistant To The Safety And Security Director', label: 'Administrative Assistant To The Safety And Security Director'},
  {value: 'Asst. Superintendent-Building And Grounds', label: 'Asst. Superintendent-Building And Grounds'},
  {value: 'Buildings And Grounds Administrative Staff', label: 'Buildings And Grounds Administrative Staff'},
  {value: 'Buildings And Grounds Coordinator', label: 'Buildings And Grounds Coordinator'},
  {value: 'Buildings And Grounds Director', label: 'Buildings And Grounds Director'},
  {value: 'Capital Projects', label: 'Capital Projects'},
  {value: 'Capital Projects Coordinator', label: 'Capital Projects Coordinator'},
  {value: 'Capital Projects Director', label: 'Capital Projects Director'},
  {value: 'Custodian', label: 'Custodian'},
  {value: 'Facilities And Maintenance', label: 'Facilities And Maintenance'},
  {value: 'Facilities And Maintenance Coordinator', label: 'Facilities And Maintenance Coordinator'},
  {value: 'Facilities And Maintenance Director', label: 'Facilities And Maintenance Director'},
  {value: 'Maintenance Secretary', label: 'Maintenance Secretary'},
  {value: 'Planning and Construction', label: 'Planning and Construction'},
  {value: 'Planning and Construction Coordinator', label: 'Planning and Construction Coordinator'},
  {value: 'Planning And Construction Director', label: 'Planning And Construction Director'},
  {value: 'Safety And Security', label: 'Safety And Security'},
  {value: 'Safety And Security Coordinator', label: 'Safety And Security Coordinator'},
  {value: 'Safety And Security Director', label: 'Safety And Security Director'},
  {value: 'Warehouse Coordinator', label: 'Warehouse Coordinator'},
  {value: 'Warehouse Services Director', label: 'Warehouse Services Director'},
  {value: 'Assistant to the Business Administrator', label: 'Assistant to the Business Administrator'},
  {value: 'Assistant To The Business Director', label: 'Assistant To The Business Director'},
  {value: 'Assistant to the Director of Operations', label: 'Assistant to the Director of Operations'},
  {value: 'Asst. Superintendent-Business and Finance', label: 'Asst. Superintendent-Business and Finance'},
  {value: 'Attorney / Legal Services', label: 'Attorney / Legal Services'},
  {value: 'Budget and Finance', label: 'Budget and Finance'},
  {value: 'Business Coordinator', label: 'Business Coordinator'},
  {value: 'Business Director', label: 'Business Director'},
  {value: 'Finance and Accounting', label: 'Finance and Accounting'},
  {value: 'Finance and Accounting Coordinator', label: 'Finance and Accounting Coordinator'},
  {value: 'Finance and Accounting Director', label: 'Finance and Accounting Director'},
  {value: 'Purchasing Administrative Staff', label: 'Purchasing Administrative Staff'},
  {value: 'Purchasing Agent', label: 'Purchasing Agent'},
  {value: 'Purchasing Coordinator', label: 'Purchasing Coordinator'},
  {value: 'Purchasing Director', label: 'Purchasing Director'},
  {value: 'Academic Administrative Assistant', label: 'Academic Administrative Assistant'},
  {value: 'Academic Coordinator', label: 'Academic Coordinator'},
  {value: 'Academic Director', label: 'Academic Director'},
  {value: 'Administrative Assistant - Early Childhood Education', label: 'Administrative Assistant - Early Childhood Education'},
  {value: 'Administrative Assistant - Teaching And Learning', label: 'Administrative Assistant - Teaching And Learning'},
  {value: 'Administrative Assistant Curriculum and Instruction', label: 'Administrative Assistant Curriculum and Instruction'},
  {value: 'Administrative Assistant To The Director Of Instruction / Curriculum', label: 'Administrative Assistant To The Director Of Instruction / Curriculum'},
  {value: 'Adult and Continuing Education', label: 'Adult and Continuing Education'},
  {value: 'Adult and Continuing Education Coordinator', label: 'Adult and Continuing Education Coordinator'},
  {value: 'Adult and Continuing Education Director', label: 'Adult and Continuing Education Director'},
  {value: 'Agricultural Education', label: 'Agricultural Education'},
  {value: 'Agricultural Education Coordinator', label: 'Agricultural Education Coordinator'},
  {value: 'Agricultural Education Director', label: 'Agricultural Education Director'},
  {value: 'Alternative Education', label: 'Alternative Education'},
  {value: 'Alternative Education Coordinator', label: 'Alternative Education Coordinator'},
  {value: 'Alternative Education Director', label: 'Alternative Education Director'},
  {value: 'American Sign Language Coordinator', label: 'American Sign Language Coordinator'},
  {value: 'Assessment and Accountability', label: 'Assessment and Accountability'},
  {value: 'Assessment and Accountability Coordinator', label: 'Assessment and Accountability Coordinator'},
  {value: 'Assessment and Accountability Director', label: 'Assessment and Accountability Director'},
  {value: 'Asst. Superintendent-Elementary Education', label: 'Asst. Superintendent-Elementary Education'},
  {value: 'Asst. Superintendent-Instruction / Curriculum', label: 'Asst. Superintendent-Instruction / Curriculum'},
  {value: 'Asst. Superintendent-Secondary Education', label: 'Asst. Superintendent-Secondary Education'},
  {value: 'Bilingual Education', label: 'Bilingual Education'},
  {value: 'Bilingual Education Coordinator', label: 'Bilingual Education Coordinator'},
  {value: 'Bilingual Education Director', label: 'Bilingual Education Director'},
  {value: 'Career And Technical Education', label: 'Career And Technical Education'},
  {value: 'Career And Technical Education Coordinator', label: 'Career And Technical Education Coordinator'},
  {value: 'Career and Technical Education Curriculum', label: 'Career and Technical Education Curriculum'},
  {value: 'Career And Technical Education Director', label: 'Career And Technical Education Director'},
  {value: 'Character Counselor', label: 'Character Counselor'},
  {value: 'College Readiness', label: 'College Readiness'},
  {value: 'College Readiness Coordinator', label: 'College Readiness Coordinator'},
  {value: 'College Readiness Director', label: 'College Readiness Director'},
  {value: 'Common Core Curriculum', label: 'Common Core Curriculum'},
  {value: 'Common Core Curriculum Coordinator', label: 'Common Core Curriculum Coordinator'},
  {value: 'Common Core Curriculum Director', label: 'Common Core Curriculum Director'},
  {value: 'Curriculum-Elementary', label: 'Curriculum-Elementary'},
  {value: 'Curriculum-Elementary Coordinator', label: 'Curriculum-Elementary Coordinator'},
  {value: 'Curriculum-K-12', label: 'Curriculum-K-12'},
  {value: 'Curriculum-Secondary', label: 'Curriculum-Secondary'},
  {value: 'Curriculum-Secondary Coordinator', label: 'Curriculum-Secondary Coordinator'},
  {value: 'Digital Content & Distance Learning', label: 'Digital Content & Distance Learning'},
  {value: 'Digital Content & Distance Learning Coordinator', label: 'Digital Content & Distance Learning Coordinator'},
  {value: 'Digital Content & Distance Learning Director', label: 'Digital Content & Distance Learning Director'},
  {value: 'District Reading Coordinator', label: 'District Reading Coordinator'},
  {value: 'Early Childhood Education', label: 'Early Childhood Education'},
  {value: 'Early Childhood Education Coordinator', label: 'Early Childhood Education Coordinator'},
  {value: 'Early Childhood Education Curriculum', label: 'Early Childhood Education Curriculum'},
  {value: 'Early Childhood Education Director', label: 'Early Childhood Education Director'},
  {value: 'Education Specialist', label: 'Education Specialist'},
  {value: 'Elementary Education Coordinator', label: 'Elementary Education Coordinator'},
  {value: 'Elementary Education Director', label: 'Elementary Education Director'},
  {value: 'English / Lang Arts', label: 'English / Lang Arts'},
  {value: 'English / Lang Arts Coordinator', label: 'English / Lang Arts Coordinator'},
  {value: 'Fine And Performing Arts', label: 'Fine And Performing Arts'},
  {value: 'Fine And Performing Arts Coordinator', label: 'Fine And Performing Arts Coordinator'},
  {value: 'Fine And Performing Arts Director', label: 'Fine And Performing Arts Director'},
  {value: 'Benefits Coordinator', label: 'Benefits Coordinator'},
  {value: 'Fine Arts Curriculum', label: 'Fine Arts Curriculum'},
  {value: 'Fine Arts Curriculum Coordinator', label: 'Fine Arts Curriculum Coordinator'},
  {value: 'Fine Arts Curriculum Director', label: 'Fine Arts Curriculum Director'},
  {value: 'Foreign Language', label: 'Foreign Language'},
  {value: 'Foreign Languages Curriculum Coordinator', label: 'Foreign Languages Curriculum Coordinator'},
  {value: 'Foreign Languages Curriculum Director', label: 'Foreign Languages Curriculum Director'},
  {value: 'Gifted & Talented Curriculum', label: 'Gifted & Talented Curriculum'},
  {value: 'Gifted and Talented Curriculum Coordinator', label: 'Gifted and Talented Curriculum Coordinator'},
  {value: 'Gifted And Talented Dept Chairperson', label: 'Gifted And Talented Dept Chairperson'},
  {value: 'Head Start', label: 'Head Start'},
  {value: 'Head Start Coordinator', label: 'Head Start Coordinator'},
  {value: 'Head Start Director', label: 'Head Start Director'},
  {value: 'Innovative Programs Curriculum', label: 'Innovative Programs Curriculum'},
  {value: 'Innovative Programs Curriculum Coordinator', label: 'Innovative Programs Curriculum Coordinator'},
  {value: 'Innovative Programs Curriculum Director', label: 'Innovative Programs Curriculum Director'},
  {value: 'Instruction / Curriculum', label: 'Instruction / Curriculum'},
  {value: 'Instruction / Curriculum Coach', label: 'Instruction / Curriculum Coach'},
  {value: 'Instruction / Curriculum Coordinator', label: 'Instruction / Curriculum Coordinator'},
  {value: 'Instruction / Curriculum Director', label: 'Instruction / Curriculum Director'},
  {value: 'K-12 Education Coordinator', label: 'K-12 Education Coordinator'},
  {value: 'K-12 Education Director', label: 'K-12 Education Director'},
  {value: 'Language Arts / English Director, K-12', label: 'Language Arts / English Director, K-12'},
  {value: 'Language Arts / English, K-12', label: 'Language Arts / English, K-12'},
  {value: 'Literacy Curriculum', label: 'Literacy Curriculum'},
  {value: 'Literacy Curriculum Coordinator', label: 'Literacy Curriculum Coordinator'},
  {value: 'Literacy Curriculum Director', label: 'Literacy Curriculum Director'},
  {value: 'Mathematics Director, K-12', label: 'Mathematics Director, K-12'},
  {value: 'Mathematics, K-12', label: 'Mathematics, K-12'},
  {value: 'Mathematics, K-12 Coordinator', label: 'Mathematics, K-12 Coordinator'},
  {value: 'Multicultural Education', label: 'Multicultural Education'},
  {value: 'Multicultural Education Coordinator', label: 'Multicultural Education Coordinator'},
  {value: 'Multicultural Education Director', label: 'Multicultural Education Director'},
  {value: 'Music', label: 'Music'},
  {value: 'Music Department Chairperson', label: 'Music Department Chairperson'},
  {value: 'Music Department Coordinator', label: 'Music Department Coordinator'},
  {value: 'Outdoor Education', label: 'Outdoor Education'},
  {value: 'Outdoor Education Coordinator', label: 'Outdoor Education Coordinator'},
  {value: 'Outdoor Education Director', label: 'Outdoor Education Director'},
  {value: 'Reading', label: 'Reading'},
  {value: 'Reading Intervention Director', label: 'Reading Intervention Director'},
  {value: 'Reading Interventionist', label: 'Reading Interventionist'},
  {value: 'Science Coordinator', label: 'Science Coordinator'},
  {value: 'Science Department', label: 'Science Department'},
  {value: 'Science Department Chairperson', label: 'Science Department Chairperson'},
  {value: 'Science, K-12', label: 'Science, K-12'},
  {value: 'Secondary Education Director', label: 'Secondary Education Director'},
  {value: 'Social Studies', label: 'Social Studies'},
  {value: 'Social Studies Coordinator', label: 'Social Studies Coordinator'},
  {value: 'Social Studies Department Chairperson', label: 'Social Studies Department Chairperson'},
  {value: 'Stem Curriculum', label: 'Stem Curriculum'},
  {value: 'Stem Curriculum Coordinator', label: 'Stem Curriculum Coordinator'},
  {value: 'Stem Director', label: 'Stem Director'},
  {value: 'Teaching And Learning', label: 'Teaching And Learning'},
  {value: 'Teaching And Learning Coordinator', label: 'Teaching And Learning Coordinator'},
  {value: 'Teaching And Learning Director', label: 'Teaching And Learning Director'},
  {value: 'Textbook', label: 'Textbook'},
  {value: 'Textbook Coordinator', label: 'Textbook Coordinator'},
  {value: 'Textbook Director', label: 'Textbook Director'},
  {value: 'Vocational Education', label: 'Vocational Education'},
  {value: 'Vocational Education Coordinator', label: 'Vocational Education Coordinator'},
  {value: 'Vocational Education Director', label: 'Vocational Education Director'},
  {value: 'Asst. Superintendent', label: 'Asst. Superintendent'},
  {value: 'Asst. Superintendent-Administration', label: 'Asst. Superintendent-Administration'},
  {value: 'Asst. Superintendent-Human Resources', label: 'Asst. Superintendent-Human Resources'},
  {value: 'Asst. Superintendent-Student Services', label: 'Asst. Superintendent-Student Services'},
  {value: 'Board Clerk', label: 'Board Clerk'},
  {value: 'Board Member', label: 'Board Member'},
  {value: 'Board President', label: 'Board President'},
  {value: 'Board Secretary', label: 'Board Secretary'},
  {value: 'Board Treasurer', label: 'Board Treasurer'},
  {value: 'Board Vice President', label: 'Board Vice President'},
  {value: 'Chief Academic Officer', label: 'Chief Academic Officer'},
  {value: 'Chief Administrative Officer', label: 'Chief Administrative Officer'},
  {value: 'Chief Educational Officer', label: 'Chief Educational Officer'},
  {value: 'Chief Executive Officer', label: 'Chief Executive Officer'},
  {value: 'Chief Financial Officer', label: 'Chief Financial Officer'},
  {value: 'Chief Of Staff', label: 'Chief Of Staff'},
  {value: 'Chief Operating Officer', label: 'Chief Operating Officer'},
  {value: 'Dean', label: 'Dean'},
  {value: 'Dean Of Students', label: 'Dean Of Students'},
  {value: 'Superintendent', label: 'Superintendent'},
  {value: 'Administrative Assist. To The Board', label: 'Administrative Assist. To The Board'},
  {value: 'Administrative Assist. To The Chief Academic Officer', label: 'Administrative Assist. To The Chief Academic Officer'},
  {value: 'Administrative Assist. To The Chief Executive Officer', label: 'Administrative Assist. To The Chief Executive Officer'},
  {value: 'Administrative Assist. To The Chief Financial Officer', label: 'Administrative Assist. To The Chief Financial Officer'},
  {value: 'Administrative Assist. To The Director Of Professional Development', label: 'Administrative Assist. To The Director Of Professional Development'},
  {value: 'Administrative Employee-Pupil Personnel', label: 'Administrative Employee-Pupil Personnel'},
  {value: 'Admissions', label: 'Admissions'},
  {value: 'Admissions Coordinator', label: 'Admissions Coordinator'},
  {value: 'Admissions Director', label: 'Admissions Director'},
  {value: 'Advancement', label: 'Advancement'},
  {value: 'Advancement Coordinator', label: 'Advancement Coordinator'},
  {value: 'Advancement Director', label: 'Advancement Director'},
  {value: 'Assist. Superintendent-Professional Development', label: 'Assist. Superintendent-Professional Development'},
  {value: 'Asst. Superintendent-Pupil Personnel', label: 'Asst. Superintendent-Pupil Personnel'},
  {value: 'Asst. Superintendents - Operations', label: 'Asst. Superintendents - Operations'},
  {value: 'Attendance', label: 'Attendance'},
  {value: 'Attendance Coordinator', label: 'Attendance Coordinator'},
  {value: 'Attendance Director', label: 'Attendance Director'},
  {value: 'Benefits Administrative Assist.', label: 'Benefits Administrative Assist.'},
  {value: 'Benefits Director', label: 'Benefits Director'},
  {value: 'Charter Schools Coordinator', label: 'Charter Schools Coordinator'},
  {value: 'Development Coordinator', label: 'Development Coordinator'},
  {value: 'Development Director', label: 'Development Director'},
  {value: 'District Administration', label: 'District Administration'},
  {value: 'District Administration Coordinator', label: 'District Administration Coordinator'},
  {value: 'District Administration Director', label: 'District Administration Director'},
  {value: 'District Administrator', label: 'District Administrator'},
  {value: 'District Compliance', label: 'District Compliance'},
  {value: 'District Compliance Coordinator', label: 'District Compliance Coordinator'},
  {value: 'District Compliance Director', label: 'District Compliance Director'},
  {value: 'Executive Assistant To The Superintendent', label: 'Executive Assistant To The Superintendent'},
  {value: 'Food Service / Cafeteria Manager', label: 'Food Service / Cafeteria Manager'},
  {value: 'Food Services', label: 'Food Services'},
  {value: 'Food Services Coordinator', label: 'Food Services Coordinator'},
  {value: 'Food Services Director', label: 'Food Services Director'},
  {value: 'Human Resources', label: 'Human Resources'},
  {value: 'Human Resources Administrative Assistant', label: 'Human Resources Administrative Assistant'},
  {value: 'Human Resources Coordinator', label: 'Human Resources Coordinator'},
  {value: 'Human Resources Director', label: 'Human Resources Director'},
  {value: 'Operations', label: 'Operations'},
  {value: 'Operations Coordinator', label: 'Operations Coordinator'},
  {value: 'Operations Director', label: 'Operations Director'},
  {value: 'Organizational Development', label: 'Organizational Development'},
  {value: 'Organizational Development Coordinator', label: 'Organizational Development Coordinator'},
  {value: 'Organizational Development Director', label: 'Organizational Development Director'},
  {value: 'Paraprofessional / Instructional Aide', label: 'Paraprofessional / Instructional Aide'},
  {value: 'Professional Development', label: 'Professional Development'},
  {value: 'Professional Development Coordinator', label: 'Professional Development Coordinator'},
  {value: 'Professional Development Director', label: 'Professional Development Director'},
  {value: 'Publicity And Communications', label: 'Publicity And Communications'},
  {value: 'Publicity And Communications Coordinator', label: 'Publicity And Communications Coordinator'},
  {value: 'Publicity And Communications Director', label: 'Publicity And Communications Director'},
  {value: 'Pupil Personnel', label: 'Pupil Personnel'},
  {value: 'Pupil Personnel Coordinator', label: 'Pupil Personnel Coordinator'},
  {value: 'Pupil Personnel Director', label: 'Pupil Personnel Director'},
  {value: 'Risk Management', label: 'Risk Management'},
  {value: 'Risk Management Coordinator', label: 'Risk Management Coordinator'},
  {value: 'Risk Management Director', label: 'Risk Management Director'},
  {value: 'School Improvement', label: 'School Improvement'},
  {value: 'School Improvement Coordinator', label: 'School Improvement Coordinator'},
  {value: 'School Improvement Director', label: 'School Improvement Director'},
  {value: 'Student Placement', label: 'Student Placement'},
  {value: 'Student Placement Coordinator', label: 'Student Placement Coordinator'},
  {value: 'Student Placement Director', label: 'Student Placement Director'},
  {value: '21St Cclc Coordinator', label: '21St Cclc Coordinator'},
  {value: '21St Cclc Director', label: '21St Cclc Director'},
  {value: 'Administrative Assistant For Grants / Federal Programs', label: 'Administrative Assistant For Grants / Federal Programs'},
  {value: 'Administrative Assistant Of Federal Programs', label: 'Administrative Assistant Of Federal Programs'},
  {value: 'Administrative Assistant Of Special Services', label: 'Administrative Assistant Of Special Services'},
  {value: 'Administrative Assistant Title I Instructional', label: 'Administrative Assistant Title I Instructional'},
  {value: 'Administrative Assistant Title Iii', label: 'Administrative Assistant Title Iii'},
  {value: 'Asst. Superintendent-Federal Programs', label: 'Asst. Superintendent-Federal Programs'},
  {value: 'Business Partnerships & Grants', label: 'Business Partnerships & Grants'},
  {value: 'Business Partnerships & Grants Director', label: 'Business Partnerships & Grants Director'},
  {value: 'Diagnostician / Testing', label: 'Diagnostician / Testing'},
  {value: 'Diagnostician / Testing Coordinator', label: 'Diagnostician / Testing Coordinator'},
  {value: 'Diagnostician / Testing Director', label: 'Diagnostician / Testing Director'},
  {value: 'Federal And State Programs', label: 'Federal And State Programs'},
  {value: 'Federal And State Programs Director', label: 'Federal And State Programs Director'},
  {value: 'Federal Programs And Grants Coordinator', label: 'Federal Programs And Grants Coordinator'},
  {value: 'Gear Up Coordinator', label: 'Gear Up Coordinator'},
  {value: 'Grants', label: 'Grants'},
  {value: 'Grants And Funded Programs Director', label: 'Grants And Funded Programs Director'},
  {value: 'Grants Specialist', label: 'Grants Specialist'},
  {value: 'Guidance & Counseling Coordinator', label: 'Guidance & Counseling Coordinator'},
  {value: 'Guidance & Counseling Director', label: 'Guidance & Counseling Director'},
  {value: 'Guidance & Counseling, Elementary', label: 'Guidance & Counseling, Elementary'},
  {value: 'Guidance & Counseling, K-12', label: 'Guidance & Counseling, K-12'},
  {value: 'Guidance & Counseling, Secondary', label: 'Guidance & Counseling, Secondary'},
  {value: 'Guidance Counselor', label: 'Guidance Counselor'},
  {value: 'Psychologist', label: 'Psychologist'},
  {value: 'Psychologist Coordinator', label: 'Psychologist Coordinator'},
  {value: 'Race To The Top (Rttt) Coordinator', label: 'Race To The Top (Rttt) Coordinator'},
  {value: 'Race To The Top (Rttt) Director', label: 'Race To The Top (Rttt) Director'},
  {value: 'School Psychology Director', label: 'School Psychology Director'},
  {value: 'Secretary To Psychologist', label: 'Secretary To Psychologist'},
  {value: 'Secretary To Title I', label: 'Secretary To Title I'},
  {value: 'Student Assessment', label: 'Student Assessment'},
  {value: 'Student Assessment Coordinator', label: 'Student Assessment Coordinator'},
  {value: 'Title I Coordinator', label: 'Title I Coordinator'},
  {value: 'Title I Director', label: 'Title I Director'},
  {value: 'Title II Coordinator', label: 'Title II Coordinator'},
  {value: 'Title II Director', label: 'Title II Director'},
  {value: 'Title III Coordinator', label: 'Title III Coordinator'},
  {value: 'Title III Director', label: 'Title III Director'},
  {value: 'Title IV Coordinator', label: 'Title IV Coordinator'},
  {value: 'Title IX Coordinator', label: 'Title IX Coordinator'},
  {value: 'Title IX Director', label: 'Title IX Director'},
  {value: 'Title VI Coordinator', label: 'Title VI Coordinator'},
  {value: 'Title VII Coordinator', label: 'Title VII Coordinator'},
  {value: 'Title VII Director', label: 'Title VII Director'},
  {value: 'Title X Coordinator', label: 'Title X Coordinator'},
  {value: 'Child Nutrition', label: 'Child Nutrition'},
  {value: 'Child Nutrition Coordinator', label: 'Child Nutrition Coordinator'},
  {value: 'Child Nutrition Director', label: 'Child Nutrition Director'},
  {value: 'Health Aide', label: 'Health Aide'},
  {value: 'Health And Physical Education', label: 'Health And Physical Education'},
  {value: 'Health And Physical Education Coordinator', label: 'Health And Physical Education Coordinator'},
  {value: 'Health And Physical Education Director', label: 'Health And Physical Education Director'},
  {value: 'Health Services Coordinator', label: 'Health Services Coordinator'},
  {value: 'Health Services Director', label: 'Health Services Director'},
  {value: 'Health Services Secretary', label: 'Health Services Secretary'},
  {value: 'Health Services Staff', label: 'Health Services Staff'},
  {value: 'Nurse', label: 'Nurse'},
  {value: 'Wellness', label: 'Wellness'},
  {value: 'Wellness Coordinator', label: 'Wellness Coordinator'},
  {value: 'Wellness Director', label: 'Wellness Director'},
  {value: 'Asst. Superintendent-Special Education', label: 'Asst. Superintendent-Special Education'},
  {value: 'Asst. Superintendent-Special Services', label: 'Asst. Superintendent-Special Services'},
  {value: 'Autism', label: 'Autism'},
  {value: 'Autism Coordinator', label: 'Autism Coordinator'},
  {value: 'Autism Director', label: 'Autism Director'},
  {value: 'Disability Services', label: 'Disability Services'},
  {value: 'Disability Services Coordinator', label: 'Disability Services Coordinator'},
  {value: 'Disability Services Director', label: 'Disability Services Director'},
  {value: 'District Rti', label: 'District Rti'},
  {value: 'District Rti Coordinator', label: 'District Rti Coordinator'},
  {value: 'District Rti Director', label: 'District Rti Director'},
  {value: 'Occupational And Physical Therapy', label: 'Occupational And Physical Therapy'},
  {value: 'Occupational And Physical Therapy Coordinator', label: 'Occupational And Physical Therapy Coordinator'},
  {value: 'Occupational And Physical Therapy Director', label: 'Occupational And Physical Therapy Director'},
  {value: 'Occupational Therapist', label: 'Occupational Therapist'},
  {value: 'Physical Therapist', label: 'Physical Therapist'},
  {value: 'Special Education Administrative Assistant', label: 'Special Education Administrative Assistant'},
  {value: 'Special Education Aide', label: 'Special Education Aide'},
  {value: 'Special Education Assistant Director', label: 'Special Education Assistant Director'},
  {value: 'Special Education Coordinator', label: 'Special Education Coordinator'},
  {value: 'Special Education Director', label: 'Special Education Director'},
  {value: 'Special Education Instructor', label: 'Special Education Instructor'},
  {value: 'Speech And Hearing', label: 'Speech And Hearing'},
  {value: 'Speech And Hearing Coordinator', label: 'Speech And Hearing Coordinator'},
  {value: 'Speech And Hearing Director', label: 'Speech And Hearing Director'},
  {value: 'Speech and Language Therapist', label: 'Speech and Language Therapist'},
  {value: 'Speech Pathologist', label: 'Speech Pathologist'},
  {value: 'Speech Pathologist Coordinator', label: 'Speech Pathologist Coordinator'},
  {value: 'Speech Pathology Director', label: 'Speech Pathology Director'},
  {value: 'Speech Therapist', label: 'Speech Therapist'},
  {value: 'Activities Coordinator', label: 'Activities Coordinator'},
  {value: 'Activities Director', label: 'Activities Director'},
  {value: 'Before And After School', label: 'Before And After School'},
  {value: 'Before And After School Coordinator', label: 'Before And After School Coordinator'},
  {value: 'Before And After School Director', label: 'Before And After School Director'},
  {value: 'Coach', label: 'Coach'},
  {value: 'Student Activities Coordinator', label: 'Student Activities Coordinator'},
  {value: 'Student Activities Staff', label: 'Student Activities Staff'},
  {value: '504 Coordinator', label: '504 Coordinator'},
  {value: '504 Director', label: '504 Director'},
  {value: 'Aboriginal / Native Education', label: 'Aboriginal / Native Education'},
  {value: 'Aboriginal / Native Education Coordinator', label: 'Aboriginal / Native Education Coordinator'},
  {value: 'Aboriginal / Native Education Director', label: 'Aboriginal / Native Education Director'},
  {value: 'Administrative Assistant For Student Services', label: 'Administrative Assistant For Student Services'},
  {value: 'Administrative Assistant To The Director Of Aboriginal / Native Education', label: 'Administrative Assistant To The Director Of Aboriginal / Native Education'},
  {value: 'Administrative Assistant To The Director Of Student Services & Records', label: 'Administrative Assistant To The Director Of Student Services & Records'},
  {value: 'At-Risk Coordinator', label: 'At-Risk Coordinator'},
  {value: 'At-Risk Director', label: 'At-Risk Director'},
  {value: 'Attendance And Discipline', label: 'Attendance And Discipline'},
  {value: 'Attendance And Discipline Coordinator', label: 'Attendance And Discipline Coordinator'},
  {value: 'Attendance And Discipline Director', label: 'Attendance And Discipline Director'},
  {value: 'Community Education Coordinator', label: 'Community Education Coordinator'},
  {value: 'Community Education Director', label: 'Community Education Director'},
  {value: 'Community Relations', label: 'Community Relations'},
  {value: 'HIV / AIDS', label: 'HIV / AIDS'},
  {value: 'HIV / AIDS Coordinator', label: 'HIV / AIDS Coordinator'},
  {value: 'Home School', label: 'Home School'},
  {value: 'Home School Coordinator', label: 'Home School Coordinator'},
  {value: 'Home School Director', label: 'Home School Director'},
  {value: 'Homeless And Migrant Education', label: 'Homeless And Migrant Education'},
  {value: 'Homeless And Migrant Education Coordinator', label: 'Homeless And Migrant Education Coordinator'},
  {value: 'Homeless And Migrant Education Director', label: 'Homeless And Migrant Education Director'},
  {value: 'International Education', label: 'International Education'},
  {value: 'International Education Coordinator', label: 'International Education Coordinator'},
  {value: 'International Education Director', label: 'International Education Director'},
  {value: 'Parent And Community Relations', label: 'Parent And Community Relations'},
  {value: 'Parent And Community Relations Director', label: 'Parent And Community Relations Director'},
  {value: 'Parent And Community Resource Director', label: 'Parent And Community Resource Director'},
  {value: 'Parent / Community Resource Coordinator', label: 'Parent / Community Resource Coordinator'},
  {value: 'Positive Behavior Interventions & Supports', label: 'Positive Behavior Interventions & Supports'},
  {value: 'Positive Behavior Interventions & Supports Coordinator', label: 'Positive Behavior Interventions & Supports Coordinator'},
  {value: 'Safe & Drug Free Schools', label: 'Safe & Drug Free Schools'},
  {value: 'Safe & Drug Free Schools Coordinator', label: 'Safe & Drug Free Schools Coordinator'},
  {value: 'Safe & Drug Free Schools Director', label: 'Safe & Drug Free Schools Director'},
  {value: 'School Social Worker', label: 'School Social Worker'},
  {value: 'School Social Worker Coordinator', label: 'School Social Worker Coordinator'},
  {value: 'School Social Worker Director', label: 'School Social Worker Director'},
  {value: 'Student Records', label: 'Student Records'},
  {value: 'Student Records Coordinator', label: 'Student Records Coordinator'},
  {value: 'Student Services & Records', label: 'Student Services & Records'},
  {value: 'Student Services & Records Coordinator', label: 'Student Services & Records Coordinator'},
  {value: 'Student Services & Records Director', label: 'Student Services & Records Director'},
  {value: 'Assistant Superintendent Of Administration And Technology', label: 'Assistant Superintendent Of Administration And Technology'},
  {value: 'Automotive Technology', label: 'Automotive Technology'},
  {value: 'Data Management Coordinator', label: 'Data Management Coordinator'},
  {value: 'Data Management Director', label: 'Data Management Director'},
  {value: 'District Network Administrator', label: 'District Network Administrator'},
  {value: 'Education Management Information System Coordinator', label: 'Education Management Information System Coordinator'},
  {value: 'Information Technology Coordinator', label: 'Information Technology Coordinator'},
  {value: 'Information Technology Director', label: 'Information Technology Director'},
  {value: 'Instructional Technology Coordinator', label: 'Instructional Technology Coordinator'},
  {value: 'Instructional Technology Director', label: 'Instructional Technology Director'},
  {value: 'Librarian', label: 'Librarian'},
  {value: 'Library And Media Services', label: 'Library And Media Services'},
  {value: 'Library And Media Services Coordinator', label: 'Library And Media Services Coordinator'},
  {value: 'Library And Media Services Director', label: 'Library And Media Services Director'},
  {value: 'Secretary To The Information Director Of Technology', label: 'Secretary To The Information Director Of Technology'},
  {value: 'Technology And IT', label: 'Technology And IT'},
  {value: 'Technology Technician', label: 'Technology Technician'},
  {value: 'Administrative Secretary Of Transportation', label: 'Administrative Secretary Of Transportation'},
  {value: 'Bus Driver', label: 'Bus Driver'},
  {value: 'Driver Instructor', label: 'Driver Instructor'},
  {value: 'Transportation Administrator', label: 'Transportation Administrator'},
  {value: 'Transportation Coordinator', label: 'Transportation Coordinator'},
  {value: 'Transportation Director', label: 'Transportation Director'},
  {value: 'Transportation Staff', label: 'Transportation Staff'},
  {value: 'Food service worker', label: 'Food service worker'},
  {value: 'Custodian', label: 'Custodian'},
  {value: 'Teacher', label: 'Teacher'},
  {value: 'Teaching assistant', label: 'Teaching assistant'},
  {value: 'Crossing guard', label: 'Crossing guard'},
  {value: 'Sports coach', label: 'Sports coach'},
  {value: 'School bus driver', label: 'School bus driver'},
  {value: 'Academic adviser', label: 'Academic adviser'},
  {value: 'Groundskeeper', label: 'Groundskeeper'},
  {value: 'Maintenance technician', label: 'Maintenance technician'},
  {value: 'Registrar', label: 'Registrar'},
  {value: 'Academic coordinator', label: 'Academic coordinator'},
  {value: 'Special education teacher', label: 'Special education teacher'},
  {value: 'School nurse', label: 'School nurse'},
  {value: 'Webmaster', label: 'Webmaster'},
  {value: 'Guidance counselor', label: 'Guidance counselor'},
  {value: 'Athletic director', label: 'Athletic director'},
  {value: 'Vice principal', label: 'Vice principal'},
  {value: 'Superintendent', label: 'Superintendent'},
  {value: 'Principal', label: 'Principal'},
]

const Locoptions = [
    { value: 'Alabama', label: 'Alabama' },
    { value: 'Alaska', label: 'Alaska' },
    { value: 'American Samoa', label: 'American Samoa' },
    { value: 'Arizona', label: 'Arizona' },
    { value: 'Arkansas', label: 'Arkansas' },
    { value: 'California', label: 'California' },
    { value: 'Colorado', label: 'Colorado' },
    { value: 'Connecticut', label: 'Connecticut' },
    { value: 'Delaware', label: 'Delaware' },
    { value: 'District Of Columbia', label: 'District Of Columbia' },
    { value: 'Federated States Of Micronesia', label: 'Federated States Of Micronesia' },
    { value: 'Florida', label: 'Florida' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Guam', label: 'Guam' },
    { value: 'Hawaii', label: 'Hawaii' },
    { value: 'Idaho', label: 'Idaho' },
    { value: 'Illinois', label: 'Illinois' },
    { value: 'Indiana', label: 'Indiana' },
    { value: 'Iowa', label: 'Iowa' },
    { value: 'Kansas', label: 'Kansas' },
    { value: 'Kentucky', label: 'Kentucky' },
    { value: 'Louisiana', label: 'Louisiana' },
    { value: 'Maine', label: 'Maine' },
    { value: 'Marshall Islands', label: 'Marshall Islands' },
    { value: 'Maryland', label: 'Maryland' },
    { value: 'Massachusetts', label: 'Massachusetts' },
    { value: 'Michigan', label: 'Michigan' },
    { value: 'Minnesota', label: 'Minnesota' },
    { value: 'Mississippi', label: 'Mississippi' },
    { value: 'Missouri', label: 'Missouri' },
    { value: 'Montana', label: 'Montana' },
    { value: 'Nebraska', label: 'Nebraska' },
    { value: 'Nevada', label: 'Nevada' },
    { value: 'New Hampshire', label: 'New Hampshire' },
    { value: 'New Jersey', label: 'New Jersey' },
    { value: 'New Mexico', label: 'New Mexico' },
    { value: 'New York', label: 'New York' },
    { value: 'North Carolina', label: 'North Carolina' },
    { value: 'North Dakota', label: 'North Dakota' },
    { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
    { value: 'Ohio', label: 'Ohio' },
    { value: 'Oklahoma', label: 'Oklahoma' },
    { value: 'Oregon', label: 'Oregon' },
    { value: 'Palau', label: 'Palau' },
    { value: 'Pennsylvania', label: 'Pennsylvania' },
    { value: 'Puerto Rico', label: 'Puerto Rico' },
    { value: 'Rhode Island', label: 'Rhode Island' },
    { value: 'South Carolina', label: 'South Carolina' },
    { value: 'South Dakota', label: 'South Dakota' },
    { value: 'Tennessee', label: 'Tennessee' },
    { value: 'Texas', label: 'Texas' },
    { value: 'Utah', label: 'Utah' },
    { value: 'Vermont', label: 'Vermont' },
    { value: 'Virgin Islands', label: 'Virgin Islands' },
    { value: 'Virginia', label: 'Virginia' },
    { value: 'Washington', label: 'Washington' },
    { value: 'West Virginia', label: 'West Virginia' },
    { value: 'Wisconsin', label: 'Wisconsin' },
    { value: 'Wyoming', label: 'Wyoming' },
];


const submitQuery =  () => {

    // console.log(jobTitle.value, location.value);

    localStorage.setItem('jobTitleSearch', jobTitleSearch);
    localStorage.setItem('locationSearch', locationSearch);

    history.push('/search-jobs');
}

const SearchBarBig = () => {
    return(
        <React.Fragment>
        <Grid item sm={10}>
            <div className="searchBoxBig" style={styles.searchBoxBig}>
                <Grid container direction='row' spacing={2} sm={10}>
                    <Grid item xs={'auto'} style={{width: '50%'}}>
                        <Grid container direction='row' spacing={2}>
                            <Grid item xs={'auto'} style={{paddingRight: '2px'}}>
                                <img src={srch} alt="Search by Title"/>
                            </Grid>
                            <Grid item xs={'true'}>
                                <div style={styles.hiddenLabel} aria-hidden="true"> Job Title</div>
                                <input type="text" id='jobTitleTextID' 
                                placeholder="Search Jobs by Keywords" value={jobTitleSearch} onChange={e=>setjobTitleSearch(e.target.value)} style={styles.srchField} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={'auto'} style={{width: '50%'}}>
                        <Grid container direction='row' spacing={2}>
                            <Grid item xs={'auto'} style={{paddingRight: '2px'}}>
                                <img src={pin} alt="Search by Location"/>
                            </Grid>
                            <Grid item xs={'auto'}>
                                <div style={styles.hiddenLabel} aria-hidden="true">Search Job by State</div>
                                {/* <Select
                                    // isMulti
                                    // className=''
                                    
                                    style={{border : "solid transparent"}}
                                    value={selectedState.state}
                                    onChange={(selectedLocOption) => { setselectedState({...selectedState, state : selectedLocOption}) }}
                                    options={Locoptions}
                                /> */}
                                <input id="locationID"  type="text" value={locationSearch} onChange={e=>setlocationSearch(e.target.value)} placeholder="Search by State" style={styles.srchField} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Grid>
        <Grid item sm={2}>
            <Button id='srchButton' onClick={submitQuery} style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', color: '#FFF', fontWeight: '600', borderRadius: '25px', padding: '10px', minWidth: '100%' }} variant="contained" >Search</Button>
        </Grid>
        </React.Fragment>
    );
}

const SearchBarSmall = () => {
    return(
        <Grid item xs={12}>
            <Grid container direction='column' spacing={1}>
                <Grid item xs={12}>
                    <div className="searchBoxSmall" style={styles.searchBoxSmall}>
                        <Grid container direction='row' spacing={1}>
                            <Grid item xs={'auto'}>
                                <img src={srch} alt="Search by Title"/>
                            </Grid>
                            <Grid item xs={'auto'}>
                                <input type="text" placeholder="Search by Job Title" style={styles.srchField} />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="searchBoxSmall" style={styles.searchBoxSmall}>
                        <Grid container direction='row' spacing={1}>
                            <Grid item xs={'auto'}>
                                <img src={pin} alt="Search by Location"/>
                            </Grid>
                            <Grid item xs={'auto'}>
                                <input type="text" placeholder="Location or Zip Code" style={styles.srchField} />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', color: '#FFF', fontWeight: '600', borderRadius: '25px', padding: '10px', minWidth: '100%' }} variant="contained" >Search</Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

const [stateDatattt, setStateDatattt] = useState()
const [activeState, setActiveState] = useState('California')
const [activeStateData, setActiveStateData] = useState({
    avg_salary: '$---',
    no_of_schools: 0,
    no_of_teachers: 0,
    no_of_districts: 0
})


useEffect(() => {
    axiosInstance.get('/seeker/get_statewise_data/').then(res => {
        setStateDatattt(res.data)
        setActiveStateData(res.data[activeState])
    })
}, [])

useEffect(() => {
    if (stateDatattt){
        setActiveStateData(stateDatattt[activeState])
        console.log(stateDatattt[activeState])
    }
}, [activeState])

useEffect(() => {
    am5.ready( function () {

      // =================================
      // Create map chart
      // =================================

      // Create root and chart
      var root = am5.Root.new( "chartdiv" );

      // Set themes
      root.setThemes( [
        am5themes_Animated.new( root )
      ] );

      var chart = root.container.children.push(
        am5map.MapChart.new( root, {
          panX: "rotateX",
          projection: am5map.geoAlbersUsa()
        } )
      );

      // Create polygon series
      var polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new( root, {
          geoJSON: am5geodata_usaLow
        } )
      );

      polygonSeries.mapPolygons.template.setAll( {
        tooltipText: "{name}"
      } );

      polygonSeries.mapPolygons.template.states.create( "hover", {
        fill: am5.color( 0x297373 )
      } );

      var zoomOut = root.tooltipContainer.children.push( am5.Button.new( root, {
        x: am5.p100,
        y: 0,
        centerX: am5.p100,
        centerY: 0,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 12,
        paddingRight: 12,
        dx: -20,
        dy: 20,
        themeTags: ["zoom"],
        icon: am5.Graphics.new( root, {
          themeTags: ["button", "icon"],
          strokeOpacity: 0.7,
          draw: function ( display ) {
            display.moveTo( 0, 0 );
            display.lineTo( 12, 0 );
          }
        } )
      } ) );

      zoomOut.get( "background" ).setAll( {
        cornerRadiusBL: 40,
        cornerRadiusBR: 40,
        cornerRadiusTL: 40,
        cornerRadiusTR: 40
      } );
      zoomOut.events.on( "click", function () {
        if ( currentSeries ) {
          currentSeries.hide();
        }
        chart.goHome();
        zoomOut.hide();
        currentSeries = regionalSeries.US.series;
        currentSeries.show();
      } );
      zoomOut.hide();


      // =================================
      // Set up point series
      // =================================

    //   Load store data
      axiosInstance.get('/seeker/map_data').then(res => {
          setupStores(res.data)
      })


      var regionalSeries = {};
      var currentSeries;

      // Parses data and creats map point series for domestic and state-level
      function setupStores ( data ) {
        console.log( data );

        // Init country-level series
        regionalSeries.US = {
          markerData: [],
          series: createSeries( "stores" )
        };

        // Set current series
        currentSeries = regionalSeries.US.series;

        // Process data
        am5.array.each( data, function ( store ) {

          // Get store data
          var store = {
            state: store.state_abb,
            long: am5.type.toNumber( store.longitude ),
            lat: am5.type.toNumber( store.latitude ),
            location: store.district_name,
            city: store.city,
            count: am5.type.toNumber( store.count )
          };

          // Process state-level data
          if ( regionalSeries[store.state] == undefined ) {
            var statePolygon = getPolygon( "US-" + store.state );
            if ( statePolygon ) {

              var centroid = statePolygon.visualCentroid();

              // Add state data
              regionalSeries[store.state] = {
                target: store.state,
                type: "state",
                name: statePolygon.dataItem.dataContext.name,
                count: store.count,
                stores: 1,
                state: store.state,
                markerData: [],
                geometry: {
                  type: "Point",
                  coordinates: [centroid.longitude, centroid.latitude]
                }
              };
              regionalSeries.US.markerData.push( regionalSeries[store.state] );

            }
            else {
              // State not found
              return;
            }
          }
          else {
            regionalSeries[store.state].stores++;
            regionalSeries[store.state].count += store.count;
          }

          // Process city-level data
          if ( regionalSeries[store.city] == undefined ) {
            regionalSeries[store.city] = {
              target: store.city,
              type: "city",
              name: store.city,
              count: store.count,
              stores: 1,
              state: store.state,
              markerData: [],
              geometry: {
                type: "Point",
                coordinates: [store.long, store.lat]
              }
            };
            regionalSeries[store.state].markerData.push( regionalSeries[store.city] );
          }
          else {
            regionalSeries[store.city].stores++;
            regionalSeries[store.city].count += store.count;
          }

          // Process individual store
          regionalSeries[store.city].markerData.push( {
            name: store.location,
            count: store.count,
            stores: 1,
            state: store.state,
            geometry: {
              type: "Point",
              coordinates: [store.long, store.lat]
            }
          } );

        } );
        console.log( regionalSeries.US.markerData )
        regionalSeries.US.series.data.setAll( regionalSeries.US.markerData );
      }

      // Finds polygon in series by its id
      function getPolygon ( id ) {
        var found;
        polygonSeries.mapPolygons.each( function ( polygon ) {
          if ( polygon.dataItem.get( "id" ) == id ) {
            found = polygon;
          }
        } )
        return found;
      }

      // Creates series with heat rules
      function createSeries ( heatfield ) {

        // Create point series
        var pointSeries = chart.series.push(
          am5map.MapPointSeries.new( root, {
            valueField: heatfield,
            calculateAggregates: true
          } )
        );

        // Add store bullet
        var circleTemplate = am5.Template.new( root );
        pointSeries.bullets.push( function () {
          var container = am5.Container.new( root, {} );

          var circle = container.children.push( am5.Circle.new( root, {
            radius: 10,
            fill: am5.color( 0x000000 ),
            fillOpacity: 0.7,
            cursorOverStyle: "pointer",
            tooltipText: "{name}:\n[bold]{stores} districts[/]"
          }, circleTemplate ) );

          var label = container.children.push( am5.Label.new( root, {
            text: "{stores}",
            fill: am5.color( 0xffffff ),
            populateText: true,
            centerX: am5.p50,
            centerY: am5.p50,
            textAlign: "center"
          } ) );

          // Set up drill-down
          circle.events.on( "click", function ( ev ) {

            // Determine what we've clicked on
            var data = ev.target.dataItem.dataContext;
            console.log(data)
            setActiveState(data.name)



            // No id? Individual store - nothing to drill down to further
            // if ( !data.target ) {
            //   return;
            // }

            // // Create actual series if it hasn't been yet created
            // if ( !regionalSeries[data.target].series ) {
            //   regionalSeries[data.target].series = createSeries( "count" );
            //   regionalSeries[data.target].series.data.setAll( data.markerData );
            // }

            // // Hide current series
            // if ( currentSeries ) {
            //   currentSeries.hide();
            // }

            // // Control zoom
            // if ( data.type == "state" ) {
            //   var statePolygon = getPolygon( "US-" + data.state );
            //   polygonSeries.zoomToDataItem( statePolygon.dataItem );
            // }
            // else if ( data.type == "city" ) {
            //   chart.zoomToGeoPoint( {
            //     latitude: data.geometry.coordinates[1],
            //     longitude: data.geometry.coordinates[0]
            //   }, 64, true );
            // }
            // zoomOut.show();

            // // Show new targert series
            // currentSeries = regionalSeries[data.target].series;
            // currentSeries.show();
          } );

          return am5.Bullet.new( root, {
            sprite: container
          } );
        } );

        // Add heat rule for circles
        pointSeries.set( "heatRules", [{
          target: circleTemplate,
          dataField: "value",
          min: 10,
          max: 30,
          key: "radius"
        }] )

        // Set up drill-down
        // TODO

        return pointSeries;
      }

    } );
}, [])


// handle chat room
const handleSendInvite = (data) => {
    axiosInstance.post('/messaging/create_room/', {
        access: accessToken,
        receiver_id: data.user_id,
        job_id: ''
    }).then(res => {
        history.push(`/message?room_id=${res.data.room_id}`);
    }).catch(err => {
        console.log(err.response);
    })
}

return (
    <React.Fragment>
    <Helmet
        title={"Dashboard | Frame Change"}
    />
        <NavBar />
        <div classname="bgimg" style={ height > width ? styles.mainBGportrait : styles.mainBGlandscape}>
            <Paper style={styles.cardstyle} elevation={0}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography style={{fontSize: '23', fontFamily: 'Montserrat', fontWeight: '600'}}>Job Search</Typography>
                    </Grid>
                    {width > 800 ? 
                        <React.Fragment>
                            <Grid item sm={10}>
                                <div className="searchBoxBig" style={styles.searchBoxBig}>
                                    <Grid container direction='row' spacing={2} sm={10} style={{alignItems: 'center'}}>
                                        <Grid item xs={'auto'} style={{width: '50%'}}>
                                            <Grid container direction='row' spacing={2} style={{alignItems: 'center', width: '100%', flexWrap: 'nowrap'}}>
                              <Grid item xs={'auto'} style={{paddingRight: '2px'}}>
                                  <img src={srch} alt="Search by Location"/>
                              </Grid>
                              <Grid item xs={'auto'} style={{width: '100%'}}>
                                  <div style={styles.hiddenLabel} aria-hidden="true">Search Candidate by Job Title</div>
                                  <Select
                                        // isMulti
                                        // className=''
                                        id='jobTitleID'
                                        options={jobTitlesArr}
                                        onChange={e => setjobTitleSearch(e.value)}
                                        className='stateDD'
                                        placeholder= 'Job Title'
                                    />
                              </Grid>
                          </Grid>
                                        </Grid>
                                        <Grid item xs={'auto'} style={{width: '50%'}}>
                                            <Grid container direction='row' spacing={2} style={{alignItems: 'center', width: '100%', flexWrap: 'nowrap'}}>
                                                <Grid item xs={'auto'} style={{paddingRight: '2px'}}>
                                                    <img src={pin} alt="Search by Location"/>
                                                </Grid>
                                                <Grid item xs={'auto'} style={{width: '100%'}}>
                                                    <div style={styles.hiddenLabel} aria-hidden="true">Search Job by State</div>
                                                    <Select
                                                        // isMulti
                                                        // className=''
                                                        id='locationID'
                                                        options={Locoptions}
                                                        onChange={e => setlocationSearch(e.value)}
                                                        className='stateDD'
                                                        placeholder= 'Search by state'
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item sm={2}>
                                <Button id='srchButton' onClick={submitQuery} style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', color: '#FFF', fontWeight: '600', borderRadius: '25px', padding: '10px', minWidth: '100%' }} variant="contained" >Search</Button>
                            </Grid>
                        </React.Fragment>
                    : 
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Grid container direction='column' spacing={1}>
                                <Grid item xs={12}>
                                    <div className="searchBoxSmall" style={styles.searchBoxSmall}>
                                        <Grid container direction='row' spacing={2} style={{alignItems: 'center', width: '100%', flexWrap: 'nowrap'}}>
                              <Grid item xs={'auto'} style={{paddingRight: '2px'}}>
                                  <img src={srch} alt="Search by Location"/>
                              </Grid>
                              <Grid item xs={'auto'} style={{width: '100%'}}>
                                  <div style={styles.hiddenLabel} aria-hidden="true">Search Candidate by Job Title</div>
                                  <Select
                                        // isMulti
                                        // className=''
                                        id='jobTitleID'
                                        options={jobTitlesArr}
                                        onChange={e => setjobTitleSearch(e.value)}
                                        className='stateDD'
                                        placeholder= 'Job Title'
                                    />
                              </Grid>
                          </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="searchBoxSmall" style={styles.searchBoxSmall}>
                                        <Grid container direction='row' spacing={1} style={{alignItems: 'center', width: '100%', flexWrap: 'nowrap'}}>
                              <Grid item xs={'auto'} style={{paddingRight: '2px'}}>
                                  <img src={pin} alt="Search by Location"/>
                              </Grid>
                              <Grid item xs={'auto'} style={{width: '100%'}}>
                                  <div style={styles.hiddenLabel} aria-hidden="true">State</div>
                                  <Select
                                        // isMulti
                                        // className=''
                                        id='locationID'
                                        options={Locoptions}
                                        onChange={e => setlocationSearch(e.value)}
                                        className='stateDD'
                                        placeholder= 'Search by state'
                                    />
                              </Grid>
                          </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', color: '#FFF', fontWeight: '600', borderRadius: '25px', padding: '10px', minWidth: '100%' }} variant="contained" >Search</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                    }
                </Grid>
            </Paper>
        </div>
        <Grid style={{ margin: '1em 0' }} container alignItems='center' justifyContent='center' direction='column'>

            {/* Recomended Jobs */}
            <Grid container direction='column' style={{ width: '90%' }}>

                <span style={{ fontSize: '19px', color: '#808080', padding: '24px' }}>RECOMMENDED JOBS</span>
                {
                    recommendJobsLoader && recommendJobsLoader ? 
                    <div className='d-flex justify-content-center'>
                        <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                    </div> : 
                    <Container style={{ margin: 'auto', padding: '0' }} >

                        <Carousel
                            ssr
                            partialVisbile
                            itemClass="image-item"
                            infinite={true}
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            autoPlaySpeed={5000}
                            autoPlay
                            responsive={responsive1}>

                            {recommendedJobs.map(props => {
                                return (
                                    <Grid container alignItems='center' justifyContent='center'>

                                        <Grid container  className='recommendedJobsCard'  style={{ boxShadow: '0px 0px 7px 0px rgb(0 0 0 / 30%)', height : '300px', padding: '20px 15px 40px 15px', background: '#fff', margin: '0.5em 0', borderRadius: '10px',cursor:'pointer' }} item lg={12} direction='column' 
                                        onClick={()=>{
                                            setRecommendModal(true);
                                            setmodalData(props);
                                            setremJobs(recommendedJobs)
                                        }}  >

                                            <Grid container justifyContent='space-between' direction='row' >
                                                <img style={{ height: '40px', width: '36px' }} src={randomSelector(logoArray)} />
                                                <img src={randomSelector(secondLogoArray)} />
                                            </Grid>
                                            <div className='textOverflow' style={{ fontSize: '20px', lineHeight: '22px', height: '44px', fontWeight: '500', marginTop: '20px' }}>{props.job_title}</div>
                                            <div className='textOverflow' style={{ fontWeight: '400', color: '#939393', fontSize: '14px' }}>{props.location}</div>
                                            <div className='textOverflow' style={ { fontWeight: '400', color: '#000000', fontSize: '14px', lineHeight: '16px', height: '48px', marginTop: '5px'}}>{props.description}</div>
                                            
                                        </Grid>
                                    </Grid>)
                            })}




                        </Carousel>


                    </Container>
                }
                
            </Grid>


            {/* Hot Jobs */}
            <Grid container direction='column' style={{ width: '90%' }}>

                <span style={{ fontSize: '19px', color: '#808080', padding: '24px' }}>HOT JOBS</span>
                {
                    hotJobsLoader && hotJobsLoader ? 
                    <div className='d-flex justify-content-center'>
                        <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                    </div>
                    : 
                    <Container style={{ margin: 'auto', padding: '0' }} >

                        <Carousel
                            ssr
                            partialVisbile
                            itemClass="image-item"
                            infinite={true}
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            autoPlaySpeed={5000}
                            responsive={responsiveHotJobs}>

                            {hotJobs.map(props => {
                                return (
                                    <a target="_" href={props.apply_url} style={{ color: 'unset', textDecoration: 'none' }}>
                                        <Grid container alignItems='center' justifyContent='center' style={{ background: '#fff', cursor: 'pointer'  }} >
        
                                            <Grid container style={{ boxShadow: '0px 0px 7px 0px rgb(0 0 0 / 30%)', padding: '5px 15px 20px 15px', background: '#fff', margin: '0.5em 0', borderRadius: '10px', height: '260px' }} item lg={12} direction='column'  >
                                {/*toggleHeart ? <FaHeart size='1.5em' style={{ color: 'red', position: 'absolute', right: '5%', top: '10%' }} onClick={changeColor} /> : <FaRegHeart size='1.5em' style={{ color: 'black', position: 'absolute', right: '5%', top: '10%' }} onClick={changeColor} /> */}
        
                                                <span className='textOverflow' style={{ fontSize: '20px', lineHeight: '22px', height: '44px', fontWeight: '500', marginTop: '20px' }}>{props.job_title}</span>
                                            <span className='textOverflow' style={{ fontWeight: '400', color: '#939393', fontSize: '14px', marginTop: '5px' }}>{props.location}</span>
                                            <span className='textOverflow' style={ { fontWeight: '400', color: '#000000', fontSize: '14px', lineHeight: '16px', height: '48px', marginTop: '5px'}}>{props.description}</span>
        
                                                {
                                                    (props.city || props.state) ? (
                                                        <div style={{ marginTop: '2.5em', display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                                                            <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px 0 0', margin: '5px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
        
                                                                <img src={stateflags[props.state]} style={{maxWidth:'100%', maxHeight:'100%', borderRadius: '15px'}} />
                                                                <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >{props.city} {props.state}</button>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }
                                            </Grid>
                                        </Grid>
                                    </a>
                                )
                            })}




                        </Carousel>


                    </Container>
                }
            </Grid>

            {/* POssibilities */}
            <Grid container direction='column' style={{ width: '90%' }}>

                <span style={{ fontSize: '28px', fontWeight: '600', color: '#000', padding: '24px' }}>Possibilities <span style={{ fontSize: '14px', fontWeight: '500' }}>more<BsArrowRightShort /></span></span>
                <Container style={{ padding: '0' }}>
                    <Grid container spacing={2} style={{ padding: '0' }}>
                        <Grid container style={{ marginTop: '10px' }} justifyContent='center' direction='column' item lg={6} md={7} sm={12} xs={12}>
                            <Grid container >

                                {
                                    possibilitiesLoader ?  
                                    <div className='col-12 d-flex justify-content-center'>
                                        <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                                    </div> : <>
                                    {width > 600 ? PossibilitiesData && PossibilitiesData.map(possibility => {
                                        return (<React.Fragment>
                                            <Grid container style={{ borderBottom: '1px solid #DEDEDE', padding: '10px 0' }} >
                                                <Grid container justifyContent='flex-start' alignItems='flex-start' item lg={2} md={2} sm={2} xs={3}>
                                                    <img  style={{ padding: ' 0 1em', borderRadius : '50%', maxWidth : '104px', maxHeight : '64px'  }} src={possibility.profile ? possibility.profile : `https://ui-avatars.com/api/?name=${possibility.title}?rounded=true`} alt='' />
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={8} xs={8}>
                                                    <span ><b>{possibility.title}</b> {possibility.city}, {possibility.state}</span>
                                                    <p>{possibility.desc}
                                                    </p>
                                                    <div style={{ marginTop: '0.5em', display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                                                        {
                                                            possibility.certificate ? 
                                                            <div style={{ border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                                <img src={Teacher} />
                                                                <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Teacher’s license</button>
                                                            </div> : null
                                                        }
                                                        {
                                                            possibility.work_experience ? 
                                                            <div style={{ border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                                <img src={FullTime} />
                                                                <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >5 Year Work Experience</button>
                                                            </div> : null
                                                        }
                                                    </div>

                                                </Grid>
                                                <Grid item lg={2} md={2} sm={2} xs={4} onClick={() => handleSendInvite(possibility)}>
                                                    <button className='contactBtn' style={{ color: '#0667C3', fontWeight: '500', padding: '0.5em 1.5em', fontSize: '14px', border: '1px solid #0667C3', borderRadius: '20px', background: '#fff' }}>Contact</button>
                                                </Grid>
                                            </Grid>
                                            </React.Fragment>
                                        );
                                    }) : PossibilitiesData && PossibilitiesData.map(possibility => {
                                        return (
                                            <Grid container style={{ borderBottom: '1px solid #DEDEDE', padding: '10px 0' }} >
                                                <Grid item container>
                                                    <Grid container justifyContent='center' item xs={3}>
                                                        <img style={{ padding: ' 0 1em', borderRadius : '50%', maxWidth : '104px', maxHeight : '64px'  }} src={possibility.profile ? possibility.profile : `https://ui-avatars.com/api/?name=${possibility.title}?rounded=true`} />
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <span style={{ fontWeight: '600' }}>{possibility.title}</span>
                                                        <br />
                                                        <span style={{ fontSize: '12px' }}>{possibility.type} </span>
                                                        <br />
                                                        <span style={{ fontSize: '12px' }}>{possibility.city}, {possibility.state}  </span>
                                                    </Grid>
                                                    <Grid justifyContent='flex-end' container item xs={4}>
                                                        <button className="contact">Contact</button>
                                                    </Grid>
                                                </Grid>
                                                <div style={{ marginTop: '0.5em', display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                                                    {
                                                        possibility.certificate ? 
                                                        <div style={{ border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', padding: '5px 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                            <img src={Teacher} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Teacher’s license</button>
                                                        </div> : null
                                                    }
                                                    {
                                                        possibility.work_experience ? 
                                                        <div style={{ border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', padding: '5px 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                            <img src={FullTime} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >5 Year Work Experience</button>
                                                        </div> : null
                                                    }
                                                </div>
                                            </Grid>)
                                    })}

                                    </>
                                }

                            </Grid>
                        </Grid>
                        <Grid container style={{ marginTop: '10px' }} justifyContent='center' item lg={6} md={5} sm={12} xs={12}>
                            <Grid container >
                                <Grid container style={{ padding: '1em', borderRadius: '10px', border: '1px solid #E4E4E4' }} item lg={12} md={12} sm={12} xs={12}>
                                    <Grid container >
                                        <Grid style={{ padding: '10px 0' }} container item lg={7} md={12} sm={7} xs={12}>
                                            <Grid item lg={4} md={4} sm={5} xs={5}>
                                                <img style={{ width: '103px', height: '103px', borderRadius: '50%' }} src={
                                                    !profilePic ? 
                                                    `https://ui-avatars.com/api/?name=${localStorage.getItem('name')}` : profilePic
                                                } alt='' />
                                                <div style={{width: '100%', borderRadius: '20px', height: '10px', backgroundColor: '#c8c9ca', marginTop: '10px'}}><div style={{height: '10px', borderRadius: '20px', backgroundColor: `${seekerPoint <= 35 ? 'red' : `${seekerPoint <= 75 ? '#f08900' : '#23b06c'}`}`, width: `${seekerPoint}%`}} /></div>
                                            </Grid>
                                            <Grid item lg={8} md={8} sm={7} xs={7} style={{ paddingLeft: '8px' }}>
                                                <span style={{ textTransform: 'capitalize', fontSize: '14px', color: '#0667C3' }}>{seekerPoint}% Profile completed</span><br />
                                                <span style={{ fontSize: '23px', fontWeight: '600' }}>
                                                    {
                                                        localStorage.getItem('name') ? 
                                                            localStorage.getItem('name') : null
                                                    }
                                                </span>
                                                <p className='proficient'>I AM PROFICIENT</p>
                                            </Grid>
                                        </Grid>
                                        <Grid style={{ padding: '10px 0' }} container spacing={1} item lg={5} md={12} sm={5} xs={12}>
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <button style={{ color: '#0667C3', fontWeight: '500', width: '110px', height: '34px', fontSize: '14px', border: '1px solid #0667C3', borderRadius: '20px', background: '#fff' }}
                                                 className='contactBtn' onClick={e=>history.push(`/candidate-profile/${localStorage.getItem('candidate_id')}`)}>View Profile</button>

                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <button style={{ color: '#fff', fontWeight: '500', width: '130px', height: '34px', fontSize: '14px', border: '1px solid #0667C3', borderRadius: '20px', background: '#0667C3' }}
                                                    onClick={e=>history.push('/profile')}
                                                >Update Profile</button>

                                            </Grid>
                                        </Grid>


                                    </Grid>

                                    <div style={{ marginTop: '0.5em', display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                                        {
                                            miscProfile?.check_certificate ? (
                                                <div style={{ border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                    <img src={Teacher} />
                                                    <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Teacher’s license</button>
                                                </div>
                                            ) : null
                                        }

                                        {
                                            miscProfile?.check_workExperience ? (
                                                <div style={{ border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                    <img src={FullTime} />
                                                    <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >5 Year Work Experience</button>
                                                </div>
                                            ) : null
                                        }

                                        {
                                            miscProfile?.check_education ? (
                                                <div style={{ border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                    <img src={Phd} />
                                                    <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Phd completed</button>
                                                </div>
                                            ) : null
                                        }


                                    </div>
                                    <br />
                                    <p style={{ padding: '0 10px' }} >{about}</p>
                                    <div style={{ marginTop: '0.5em', display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                                        {
                                            miscProfile > 0 ? <>
                                                {miscProfile.opportunity_preferences[0 ].writing ? <div style={{border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} ><b>Writing</b></button>
                                                </div> : null}
                                                {miscProfile.opportunity_preferences[1 ].speaking_on_clubhouse ? <div style={{border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                    <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} ><b>Speaking on Clubhouse</b></button>
                                                </div> : null}
                                                {miscProfile.opportunity_preferences[2 ].mentoring ? <div style={{border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                    <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} ><b>Mentoring</b></button>
                                                </div> : null}
                                                {miscProfile.opportunity_preferences[3 ].content_creation ? <div style={{border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                    <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} ><b>Content Creation</b></button>
                                                </div> : null}
                                            </>  : null       
                                        }

                                    </div>
                                    {/* </Grid> */}

                                </Grid>
                                <Grid justifyContent='space-between' container style={{ marginTop: '10px' }} direction='row'>
                                    <Grid container item direction='column'
                                        style={{ marginTop: '10px', padding: '0 1em', borderRadius: '10px', border: '1px solid #E4E4E4' }}
                                        lg={6} md={6} sm={12} xs={12}>
                                        <Grid item>
                                            <span style={{ fontSize: '11px' }}>TOTAL JOB POSTS</span><br />
                                        </Grid>
                                        <Grid item>
                                            <p style={{ fontSize: '39px' }}><CountUp duration={5} end={miscProfile?.total_jobs} /></p>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='column' style={{ marginTop: '10px', padding: '0 1em', borderRadius: '10px', border: '1px solid #E4E4E4' }} item
                                        lg={5} md={6} sm={12} xs={12}>
                                        <Grid item>
                                            <span style={{ fontSize: '11px' }}>New jobs this week</span><br />
                                        </Grid>
                                        <Grid item>
                                            <p style={{ fontSize: '39px' }}><CountUp duration={5} end={miscProfile?.total_jobs} /></p>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid>
                </Container>
            </Grid>
            {/* Search jobs based on region */}
            <Grid container direction='column' style={{ width: '90%' }}>

                <span style={{ fontSize: '28px', fontWeight: '600', color: '#000', padding: '24px' }}>Search jobs based on region</span>
                <Container style={{ margin: 'auto', padding: '0' }} >
                    <Grid spacing={2} container >
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div id='chartdiv' style={{width: '100%', height: '100%', maxWidth: '100vw', minHeight: '350px'}} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container style={{ background: '#F5F7FA', borderRadius: '8px', padding: '3% 5%' }}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <span style={{ textTransform: 'uppercase', color: '#474747' }}>{activeState} stats</span>
                                </Grid>
                                <Grid style={{ marginTop: '3%' }} item lg={6} md={6} sm={6} xs={12}>
                                    <span style={{ textTransform: 'uppercase', fontSize: '14px', color: '#808080' }}>Average salary</span>
                                    <p style={{ fontSize: '28px', color: '#0E0E0E' }}>{activeStateData?.avg_salary}</p>
                                </Grid>
                                <Grid style={{ marginTop: '3%' }} item lg={6} md={6} sm={6} xs={12}>
                                    <span style={{ textTransform: 'uppercase', fontSize: '14px', color: '#808080' }}>Total number of schools</span>
                                    <p style={{ fontSize: '28px', color: '#0E0E0E' }}>{activeStateData?.no_of_schools}</p>
                                </Grid>
                                <Grid style={{ marginTop: '3%' }} item lg={6} md={6} sm={6} xs={12}>
                                    <span style={{ textTransform: 'uppercase', fontSize: '14px', color: '#808080' }}>Total number of districts</span>
                                    <p style={{ fontSize: '28px', color: '#0E0E0E' }}>{activeStateData?.no_of_districts}</p>
                                </Grid>
                                <Grid style={{ marginTop: '3%' }} item lg={6} md={6} sm={6} xs={12}>
                                    <span style={{ textTransform: 'uppercase', fontSize: '14px', color: '#808080' }}>Total number of teachers</span>
                                    <p style={{ fontSize: '28px', color: '#0E0E0E' }}>{activeStateData?.no_of_teachers}</p>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Container>
            </Grid>

            {/* Featured Articles */}
            <Grid container direction='column' style={{ width: '90%' }}>
                {
                    featuredBlogs.length ? <>
                        <span style={{ fontSize: '28px', fontWeight: '600', color: '#000', padding: '24px' }}>Featured Articles</span>
                        <Container style={{ margin: 'auto', padding: '0' }} >
                            <Grid spacing={2} container onClick={() => setLoginModal(true)}>
                                {
                                    featuredBlogs.length ? <>
                                        <Grid container item lg={6} md={6} sm={12} xs={12}>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <img style={{ width: '100%' }} src={featuredBlogs[0].blog_image ? featuredBlogs[0].blog_image : Featured} alt='' />
                                            </Grid>
                                            <Grid style={{ marginTop: '3%' }} item lg={12} md={12} sm={12} xs={12}>
                                                <Link style={{color:'black', textDecoration : 'none'}} to=
                                                    {{
                                                        pathname: `/blog/${featuredBlogs[0].id}`
                                                    }}
                                                >
                                                    <span style={{ fontSize: '28px', fontWeight: '600' }}>
                                                        {featuredBlogs[0].blog_title}
                                                    </span>
                                                </Link>
                                                <p>
                                                    {featuredBlogs[0].short_description}  
                                                </p>
                                            </Grid>

                                        </Grid>
                                    </> : null
                                }
                                <Grid container item lg={6} md={6} sm={12} xs={12}>
                                    <Grid container item lg={12} spacing={2}>
                                        {
                                            featuredBlogs.length > 1 ? <>
                                                {
                                                    featuredBlogs.slice(1, 5).map(item=>{
                                                        return(
                                                            <Grid style={{ border: '1px solid #000', margin: '5px', padding: '5% 5% 0 5% ', borderRadius: '8px' }} item lg={12} md={12} sm={12} xs={12}>
                                                                <Link style={{ fontSize: '19px', fontWeight: '500', color:'black', textDecoration:'none' }} to=
                                                                    {{
                                                                        pathname: `/blog/${featuredBlogs[0].id}`
                                                                    }}
                                                                >
                                                                    <span >{item.blog_title}</span>
                                                                </Link>
                                                                <p style={{ fontSize: '14px' }}>{item.short_description}</p>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </> : null
                                        }
                                        
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </> : null
                }

            </Grid>

        </Grid >
        <Modal
        open={recommendModal}
        onClose={() => setRecommendModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      ><div className='modalCont recommandModal'><Recommend data={modalData} remJobs = {remJobs} closeRecommend={() => setRecommendModal(false)} /></div>
        </Modal>
        <Modal
        open={loginModal}
        onClose={() => {setLoginModal(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"  
      >
                <div className='modalCont' style={{width: 'fit-content'}}>
                    <img src={ MaintainenceGuy } alt="" />
                    <div className='loginModalHead'>This action is not available yet...</div>
                    <div className='loginModalSubHead'>We are woking on it, please come back later.</div>
                    <button className='loginModalBtn' onClick={() => setLoginModal(false)}>Okay</button>
                </div>
      </Modal>
      <Footer />
    </React.Fragment>
)
}

export default Dashboard;
