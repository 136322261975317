import { Container, Switch } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import NavBarSrch from '../NavBar_withSearch'
import NavBar from '../NavBar'
import NavBarEmpSrch from '../NavBar_emp_searchBar'
import NavBarEmp from '../NavBar_emp'
// import { SearchBar } from '../emp_settings'
import Dot3 from './3dot.png'
import Select from 'react-select';
import Send from './send.png'
import Check from './Frame 5924.png'
import axios from 'axios';
import loaderJson from '../../assets/loader.json';
import Lottie from 'react-lottie';
import Cookies from 'js-cookie'
// import socketIOClient from 'socket.io-client';
import Mail from '../candidateProfile/mail.png'
import Call from '../candidateProfile/call.png'
import Contact from '../candidateProfile/contact.png';
import Calendly from '../../assets/calendly.png';
import moment from 'moment';
import $, { makeArray } from 'jquery';
import Helmet from 'react-helmet';

// import {SearchBar} from '../emp_settings/index';
import useWebSocket, { ReadyState } from 'react-use-websocket'

import './message.css'
import axiosInstance from '../../axios'

const Message = () => {
  const [allMessages, setAllMessages] = React.useState([])
  const [activeMessageProf, setActiveMessageProf] = useState(null)
  const [priMessages, setPriMessages] = useState([])

  const [accessToken, setaccessToken] = useState('')
  useEffect(() => {
    if (Cookies.get('access_token')) {
      setaccessToken(Cookies.get('access_token'))
    } else {
      window.location.href = '/register'
    }
  }, [])

  const [socketUrl, setSocketUrl] = useState(null)
  const [messageHistory, setMessageHistory] = useState([])

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl)
  useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastMessage))
      setPriMessages((prev) => prev.concat(JSON.parse(lastMessage.data)))
    }
  }, [lastMessage, setMessageHistory])

  useEffect(() => {
    if (activeMessageProf !== null) {
      getPrevMessages()
      setSocketUrl(
        `wss://framechange--backend.herokuapp.com/ws/chat/${activeMessageProf?.room}/?access=${accessToken}`
      )
    }
  }, [accessToken, activeMessageProf])

  const [curMsg, setCurMsg] = useState('')

  const submitFormOnEnter = (e) => {
    if(e.keyCode === 13){
        document.getElementById('sendMessageBtn').click();
    }
    return;
}
  const handleClickSendMessage = () => {
    if(curMsg.length <= 0)
      return;
    sendMessage(
      JSON.stringify({
        message: curMsg,
        room_id: activeMessageProf.room,
        sender: accessToken,
        sender_type: localStorage.getItem('user') === 'JobSeeker' ? '1' : '0',
      })
    )
    setCurMsg('')
  }
  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState]

  const getPrevMessages = () => {
    axiosInstance
      .get(
        `/messaging/view_messages?room_id=${activeMessageProf?.room}`
      )
      .then((res) => setPriMessages(res.data))
      .then(() =>
        document
          .getElementsByClassName('messageCont')[0]
          .scrollTo(
            0,
            document.getElementsByClassName('messageCont')[0].scrollHeight
          )
      )
      .catch((err) => {
        console.log(err);
        setPriMessages([]);
      })
  }

  useEffect(() => {
    document
      .getElementsByClassName('messageCont')[0]
      .scrollTo(
        0,
        document.getElementsByClassName('messageCont')[0].scrollHeight
      )
  }, [priMessages])
  
  const [messageProps, setmessageProps] = useState({
    is_seekers : localStorage.getItem('user') === 'JobSeeker' ? '1' : '0',
    job_id : ''
  });

  const [messageJobs, setmessageJobs] = useState([]);
  const [activeJobIndex, setactiveJobIndex] = useState(null);

  useEffect(() => {
      getAllMsgs();
      getMessageJobs();
  }, [messageProps])

  const getMessageJobs = () => {
    axiosInstance.get('/messaging/message_jobs/').then(res=>{
      makeArray(res.data);
    }).catch(err=>{
      console.log(err);
    })
  }

  const makeArray = (data) => {
    let arr = [];
    Object.keys(data).map(item=>{
      arr.push({value : item, label : data[item]});
    })
    setmessageJobs(arr);
  }

  const getAllMsgs = () => {
    setloader(true);
    axiosInstance
      .get(
        `/messaging/chat_screen`, {params: messageProps}
      )
      .then((res) => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('room_id');
        var main_res = res.data.participants;

        document.getElementById('seeker_count').innerHTML = res.data.seeker_count;
        document.getElementById('employer_count').innerHTML = res.data.emp_count;

        console.log(res.data.participants.length);
        if(res.data.participants.length === 0){
          setAllMessages([]);
          setActiveMessageProf({
            room: '',
            name: '',
            profile_pic: '',
            job_id: '',
            is_seekers: '',
          });
          setloader(false);
          return;
        }

        if(myParam == null){
          setActiveMessageProf(main_res[0]);
        }
        for(let i = 0; i < main_res.length; i++){
          if(main_res[i].room === parseInt(myParam)){
            setActiveMessageProf(main_res[i]);
            setactiveJobIndex(i);
            // document.getElementsByClassName('chat-person')[i]
            //   .scrollTo(
            //     0,
            //     document.getElementsByClassName('messageCont')[i].scrollHeight
            //   )
          }
        }
        setAllMessages(main_res)
        // console.log(main_res)
        setloader(false);
      })
      .catch((err) => {
        console.log(err);
        setAllMessages([])
        setloader(false);
      })
  }

  useEffect(() => {
    console.log(document.getElementsByClassName('person-chat'));
  }, [allMessages])
  
  
  const handleActiveMsger = ( e ) => {
    if ( window.innerWidth > 960 ) {
      setPriMessages([])
      setActiveMessageProf(e)
    } else {
      window.location.href = `/messageMsg/${e.user}/${e.room}/${messageProps.is_seekers}`
    }
  }

  const [characterLimit, setcharacterLimit] = useState(false);

  const checkCharacterLimit = (text) => {
    if(text.length > 254){
      setcharacterLimit(true);
      return;
    }
    setcharacterLimit(false);
    return;
  }

  function formatAMPM(date) {
    var hours = date.getUTCHours();
    var minutes = date.getUTCMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  


  
function convertUTCDateToLocalDate(date) {

  var local = moment.utc(date).local().format('LLL');
  // console.log(local, "- UTC now to local"); 
  return local
}
useEffect(() => {
  turnActiveMessage('job_seeker');
}, [])

const turnActiveMessage = (type) => {
  if(type === 'job_seeker'){
    document.getElementById('job_seeker_message_tab').classList.add('activeMessagePart');
    document.getElementById('employer_message_tab').classList.remove('activeMessagePart');
    if(localStorage.getItem('user') === 'JobSeeker'){
      setshowMessageSelect(false);
    }else if(localStorage.getItem('user') === 'DistrictAdmin'){
      setshowMessageSelect(true);
    }
    setmessageProps({
      is_seekers : '1',
      job_id : ''
    });
  }else if(type === 'employer'){
    document.getElementById('employer_message_tab').classList.add('activeMessagePart');
    document.getElementById('job_seeker_message_tab').classList.remove('activeMessagePart');
    if(localStorage.getItem('user') === 'JobSeeker'){
      setshowMessageSelect(true);
    }else if(localStorage.getItem('user') === 'DistrictAdmin'){
      setshowMessageSelect(false);
    }
    setmessageProps({
      is_seekers : '0',
      job_id : ''
    });
  }
}

const [showMessageSelect, setshowMessageSelect] = useState(false);

const [loader, setloader] = useState(false);


  return (
    <>
      <Helmet>
        <title>Messages | Frame Change</title>
      </Helmet>
      <div>
        
        {Cookies.get('user') === 'JobSeeker' ? (
          window.innerWidth > 960 ? (
            <NavBarSrch />
          ) : (
            <NavBar />
          )
        ) : window.innerWidth > 960 ? (
          <NavBarEmpSrch />
        ) : (
          <NavBarEmp />
        )}
        <Container style={{ marginTop: '100px' }}>
          {
            loader ? 
            <div className='' style={{height : '120vh', paddingTop : '20vh', display : 'grid', width : '100vw', backgroundColor : 'rgb(255,255,255,0.6)', position : 'fixed', zIndex: '100'}}>
                <Lottie 
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loaderJson,
                        rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={200}
                    width={200}
                />
            </div> : null
          }
          <div className='message-container'>
            <div className='leftMessagePeople'>
              <div className='mainControl'>
                <div className='leftNotid activeMessagePart' id='job_seeker_message_tab' onClick={()=>turnActiveMessage('job_seeker')}>Job seekers (<span id='seeker_count'></span>)</div>
                <div className='leftNotid' id='employer_message_tab' onClick={()=>turnActiveMessage('employer')}>Employers (<span id='employer_count'></span>)</div>
              </div>
              {
                showMessageSelect ? (
                  <div className='d-flex justify-content-center mb-2' id="jobSelectMessage">
                    <div style={{width : '95%', borderRadius : '20px'}}>
                      <Select
                          style={{borderRadius : '20px!important'}}
                          options={messageJobs}
                          placeholder='Filter by Jobs / School District'
                          onChange={(e)=>{
                            setmessageProps({...messageProps, job_id : e.value});
                          }}
                        />
                    </div>
                  </div>
                ) : null
              }
              {/* <span>The WebSocket is currently {connectionStatus}</span> */}
              {/* <div className='switchMessage'>
                <IOSSwitch />
                View only Private Chats{' '}
              </div> */}
              {/* <SearchBar br70 /> */}
              {allMessages.map((e) => (
                <MessageProf
                  onClick={() => handleActiveMsger(e)}
                  active={e.room === activeMessageProf?.room}
                  name={e.user_name}
                  className='chat-person'
                  location={e.last_message}
                  profPic={
                    e.profile_pic === ''
                      ? `https://ui-avatars.com/api/?name=${e.user_name}?rounded=true`
                      : e.profile_pic
                  }
                />
              ))}
            </div>
            <div className='rightMessage deskOnly'>
              <div className='messageRightTop'>
                <MessageProfHeader
                  onClick={() => {
                      window.location.href = `/candidate-profile/${activeMessageProf.user}`
                    
                  }}
                  name={activeMessageProf?.user_name}
                  email={activeMessageProf?.user_email}
                  phone={activeMessageProf?.user_phone}
                  calendly={activeMessageProf?.user_calendly}

                  profPic={
                    activeMessageProf?.profile_pic === ''
                      ? `https://ui-avatars.com/api/?name=${activeMessageProf?.user_name}?rounded=true`
                      : activeMessageProf?.profile_pic
                  }
                />
                {/* <img src={Dot3} alt='' /> */}
              </div>
              <div className='messageCont'>
                {priMessages.map((e) => {
                  return (
                    <MessageComp
                      profImg={
                        e.sender !== activeMessageProf?.user
                          ? `https://ui-avatars.com/api/?name=${e.sender_name}?rounded=true`
                          : activeMessageProf.profile_pic === ''
                          ? `https://ui-avatars.com/api/?name=${e.sender_name}?rounded=true`
                          : activeMessageProf?.profile_pic
                      }
                      // time={`${e.sent_at_date ? e.sent_at_date : ''} ${
                      //   e.sent_at_time
                      // }`}
                      time={convertUTCDateToLocalDate(e.sent_at)}
                      name={e.sender_name}
                      left={
                        e.sender_id
                          ? e.sender_id !== activeMessageProf.user
                            ? false
                            : true
                          : e.sender !== activeMessageProf.user
                          ? false
                          : true
                      }
                    >
                      {e.message}
                    </MessageComp>
                  )
                })}
              </div>
              <div className='sendMsgCont'>
                <input onKeyDown={submitFormOnEnter}
                  type='text'
                  placeholder='Type a message...'
                  className='sendMsgInpt'
                  value={curMsg}
                  onChange={(e) => {
                      setCurMsg(e.target.value);
                      checkCharacterLimit(e.target.value);
                    }
                  }
                />
                
                <button
                id="sendMessageBtn"
                  className='sendMsgBtn'
                  onClick={handleClickSendMessage}
                  disabled={readyState !== ReadyState.OPEN}
                >
                  Send <img src={Send} alt='' />
                </button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default Message

const MessageProf = (props) => {
  return (
    <div
      className={`messageProfileCont ${
        props.active ? 'activeMessageProf' : null
      }`}
      onClick={props.onClick}

    >
      <img src={props.profPic} alt='' />
      <div>
        <div className='profName'>{props.name}</div>
          
        <div className='profLocation'>{props.location}</div>
      </div>
    </div>
  )
}

const MessageProfHeader = (props) => {
  return (
    <div
      className={`messageProfileCont ${
        props.active ? 'activeMessageProf' : null
      }`}
      onClick={props.onClick}

    >
      <img src={props.profPic} alt='' />
      <div>
        <div className='profName pb-1'>{props.name} </div> 
        {
          props.location ? 
          <div className='profLocation'>{props.location}</div> : null
        }
        {
          props.email ? 
            <img  onClick={()=>window.location.href='mailto:'+ props.email} src={Mail} style={{cursor: 'pointer', height : '30px', paddingRight : '8px'}} />
             : null
        }
        {
          props.phone ? 
          <img onClick={()=>window.location.href='tel:+'+ props.phone} src={Call} style={{cursor: 'pointer', height: '30px'}} />
          : null
        }
        {
          props.calendly ? 
          <img
              src={Calendly}
              style={{  height : '25px' }}
              onClick={() =>
                window.open(`https://calendly.com/${props.calendly}/30min`, '_blank')
              }
          /> : null
        }
        
      </div>
    </div>
  )
}


const MessageComp = (props) => {

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '" target="_">' + url + '</a>'
    })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }

  const calciTime = (time) => {
    return time;
  }

  return (
    <div
      className={`messageContMsg ${
        props.left ? 'messageLeft' : 'messageRight'
      }`}
    >
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          gap: '10px',
          fontSize: '14px',
          color: 'gray',
        }}
      >
        <img
          src={props.profImg}
          alt=''
          style={{ borderRadius: '50%', height: '32px', width: '32px' }}
        />
        {props.name}      
      </div>
      <div className='message'>
        <div dangerouslySetInnerHTML={{ __html: urlify(props.children) }} />
        <div className='timeContMsg'>
          {calciTime(props.time)} {props.checked ? <img src={Check} alt='' /> : null}
        </div>
      </div>
    </div>
  )
}

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  margin: '10px',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#10B981',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))
