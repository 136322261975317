import { Box, Container, Grid, Typography, Tabs, Tab } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import "react-multi-carousel/lib/styles.css";
import NavBar from '../NavBar_withSearch';
import Dollar from '../Dashboard/dollar.svg';
import Phd from '../Dashboard/phd.svg';
import Teacher from '../Dashboard/teacher.svg';
import FullTime from '../Dashboard/fullTime.svg';
import Time from '../Dashboard/time.svg';
import San from '../Dashboard/san.svg';
import { FaRegHeart } from 'react-icons/fa';
import { FaHeart } from 'react-icons/fa';
import DesiredJob from './desiredJob.svg';
import GreenDesiredJob from './GreenDesiredJob.svg';
import Profile from './profile.svg';
import School from './school.svg';
import Tick from './Tick.svg'
import Phone from '../emp_settings/Phone.png';
import NavBar_emp from '../NavBar_emp';
import Mail from '../emp_settings/Mail.png'
import Web from '../emp_settings/Web.png';
import Modal from '@mui/material/Modal'
import CloseIcon from '../../assets/Close.svg'
import { useLocation } from 'react-router-dom';
import LeftChevron from '../../assets/LeftChevron.svg'
import RightChevron from '../../assets/RightChevron.svg'
import Badge1 from '../../assets/badge1.svg'
import Badge2 from '../../assets/badge2.svg'
import Q from '../../assets/q.svg'
import Lottie from 'react-lottie';
import './search-jobs.css';
import { Helmet } from "react-helmet";
import Cookies from 'js-cookie';
import axiosInstance from '../../axios';
import loaderJson from '../../assets/loader.json';
import Carousel from 'react-multi-carousel';
import { calculateDollar } from '../Search-jobs/index.js';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}
function SavedJobs(props) {

    const [tabValue, setTabValue] = React.useState('job-description');

    const [toggleHeart, setToggleHeart] = useState(false)

    const [modal, setModal] = useState(false);

    const changeColor = useCallback(() => {
        setToggleHeart(!toggleHeart)
    }, [])

    const { height, width } = useWindowDimensions();
    const [accessToken, setaccessToken] = useState('');
    const [jobID, setjobID] = useState('');

    useEffect(() => {
        if (Cookies.get('access_token')) {
            const url_section = window.location.href.split('/');
            console.log(url_section[url_section.length - 1]);
            setjobID(url_section[url_section.length - 1]);
            // if (url_section[url_section.length - 1]) {
            // } else {
            //     window.location.href = '/dashboard';
            // }
        } else {
            window.location.href = '/register';
        }
    }, [])

    const location = useLocation();
    const [loader, setloader] = useState(false);
    const [selectedJob, setselectedJob] = useState({
        "id": "",
        "city": "",
        "state": "",
        "district": "",
        "address": "",
        "district_id": "",
        "is_saved_job": "",
        "unmatched_school_district": "",
        "is_scraped": "",
        "scraped_job_ID": "",
        "scrap_source": "",
        "date_created": "",
        "job_title": "",
        "apply_url": "",
        "description": "",
        "location": "",
        "expiry_date": "",
        "date_posted": "",
        "vacancies": "",
        "position": "",
        "salary_from": "",
        "salary_to": "",
        "contact_person": "",
        "contact_title": "",
        "contact_phone": "",
        "contact_email": "",
        "salary_range": "",
        "no_of_job_openings": "",
        "shift_type": "",
        "other_details": "",
        "no_of_views": "",
        "phd_required": "",
        "license_required": "",
        "school_district": "",
        "admin": "",
        "non_admin": ""
    })

    // const myParam = location.state.params;
    // setjobID(myParam);

    const renderSwitch = (param) => {
        switch (param) {
            case "job-description":
                return JobDescription;
            case "employer-details":
                return <>
                    <div style={{ marginTop: '70px', marginBottom: '50px', padding: '1rem' }}>
                        <div style={{ display: 'inline-flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h4 style={{ maxWidth: '520px' }}>Anderson Community School Corporation (School District)</h4>
                        </div>
                        <p style={{ maxWidth: '520px', marginTop: '10px' }}>
                            Anderson Community School Corporation is a public school district located in ANDERSON, IN. It has 6,811 students in grades PK, K-12 with a student-teacher ratio of 15 to 1. According to state test scores, 29% of students are at least proficient in math and 30% in reading.
                        </p>
                        <div style={{ display: 'inline-flex', gap: '15px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' }}>
                            <div style={{ display: 'inline-flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' }}>
                                <img src={Web} /> acsc.net
                            </div>
                            <div style={{ display: 'inline-flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' }}>
                                <img src={Mail} /> jennywilson@mail.com
                            </div>
                            <div style={{ display: 'inline-flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' }}>
                                <img src={Phone} /> 434 241 41284
                            </div>
                        </div>
                        <div className='fourGridCol' style={{ marginTop: '100px' }}>
                            <div className='firstColHead'>Academics</div>
                            <div className='colData'>
                                <h4>30%</h4>
                                Percent Proficient - Reading
                            </div>
                            <div className='colData'>
                                <h4>30%</h4>
                                Percent Proficient - Math
                            </div>
                            <div className='colData'>
                                <h4>30%</h4>
                                Average Graduation Rate
                            </div>
                        </div>
                        <div className='fourGridCol' style={{ marginTop: '50px' }}>
                            <div className='firstColHead'>Students</div>
                            <div className='colData'>
                                <h4>6,811</h4>
                                Students
                            </div>
                            <div className='colData'>
                                <h4>30%</h4>
                                Free or Reduced Lunch
                            </div>
                        </div>
                        <div className='fourGridCol' style={{ marginTop: '50px' }}>
                            <div className='firstColHead'>Teachers</div>
                            <div className='colData'>
                                <h4>15:1 <sub style={{ fontSize: '12px' }}>National 17:1</sub></h4>
                                Student-Teacher Ratio
                            </div>
                            <div className='colData'>
                                <h4>30%</h4>
                                Teachers in First/Second Year
                            </div>
                        </div>
                        <div className='graphColDiv' style={{ marginTop: '50px' }}>
                            <div className='firstColHead'>Finances</div>
                            <div className='colData'>
                                <h4>$37,038 <sub style={{ fontSize: '12px' }}>/ student</sub><p style={{ fontSize: '12px' }}>National $12,239</p></h4>
                                Median Household Income
                            </div>
                            <div className="bardGraphContDiv">
                                <div className="bardGraphCont">
                                    <div className="barColor" style={{ width: '58%' }} />
                                    <div className="barLabel">Instruction</div>
                                    <div className="barPercent">58%</div>
                                </div>
                                <div className="bardGraphCont">
                                    <div className="barColor" style={{ width: '58%' }} />
                                    <div className="barLabel">Support Services</div>
                                    <div className="barPercent">58%</div>
                                </div>
                                <div className="bardGraphCont">
                                    <div className="barColor" style={{ width: '58%' }} />
                                    <div className="barLabel">Other</div>
                                    <div className="barPercent">58%</div>
                                </div>
                            </div>
                        </div>
                        <div className='fourGridCol' style={{ marginTop: '50px' }}>
                            <div className='firstColHead'>LIVING IN THE AREA</div>
                            <div className='colData'>
                                <h4>$37,038 <p style={{ fontSize: '12px' }}>National $62,843</p></h4>
                                Median Household Income
                            </div>
                            <div className='colData'>
                                <h4>$757 <p style={{ fontSize: '12px' }}>National $1,062</p></h4>
                                Median Rent
                            </div>
                            <div className='colData'>
                                <h4>$72,900 <p style={{ fontSize: '12px' }}>National $217,500</p></h4>
                                Median Home Value
                            </div>
                        </div>
                    </div>
                </>;
        }
    }


    const JobDescription =
        <Grid container direction='column' style={{ marginTop: '1em' }}>
            <span style={{ fontSize: '22px', fontWeight: '550' }}>Full Job Description</span>
            {selectedJob.description}
        </Grid>;

    const [isCandidate, setisCandidate] = useState(false);
    useEffect(() => {
        if(localStorage.getItem('user') === 'JobSeeker')
            setisCandidate(true);
        else
            setisCandidate(false);
    }, [])


    const calculateDayLeft = (expiryDate) => {
        const curr = new Date();
        const exp = new Date(expiryDate);
        const oneDay = 24 * 60 * 60 * 1000;

        if (curr > exp)
            return false;

        const diffDays = Math.round(Math.abs((exp - curr) / oneDay));

        return diffDays;
    }


    const saveJobFn = (id) => {
        // setloader(true);
        const data = {
            // access: accessToken,
            job_id: id
        }
        // console.log(data);
        axiosInstance.post('/seeker/toggle_saved_job_status/', data).then(res => {
            // setloader(false);
            getData();
            // console.log(selectedJob);

        }).catch(err => {
            alert(err);
            // setloader(false);
        })
    }

    useEffect(() => {
        getData();
    }, [jobID])

    const getData = () => {
        setloader(true);
        if (jobID != '') {
            axiosInstance.get('/seeker/search_jobs/', {
                params: {
                    job_id: jobID
                }
            }).then(res => {
                console.log(res.data.job);
                setselectedJob(res.data.job);
                setloader(false);
            }).catch(err => {
                console.log(err);
            })
        }
    }

    

    return (
        <>
            {
                loader ?
                    <div className='' style={{ height: '120vh', display: 'grid', placeContent: 'center', width: '100vw', backgroundColor: 'rgb(255,255,255,0.6)', position: 'fixed', zIndex: '100' }}>
                        {/* <iframe src="https://embed.lottiefiles.com/animation/66433"></iframe> */}
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: loaderJson,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice"
                                }
                            }}
                            height={200}
                            width={200}
                        />
                    </div> : null
            }
            <Helmet>
                <title>Frame Change</title>
            </Helmet>
            {isCandidate ? <NavBar /> : <NavBar_emp />}
            <Grid style={{ padding: '3.5em 10px', background: '#F5F7FA' }} container alignItems='center' justifyContent='center' direction='column'>

                <Grid container direction='column' style={{ width: '100%' }}>

                    <Container className='main_container' style={{ margin: 'auto', padding: '0' }} >
                        <div style={{ marginTop: '7em', marginBottom: '1em' }}>
                        </div>
                        <Grid container spacing={2}>

                            <Grid container item>
                                <Grid container>
                                    <Grid container direction='column' style={{ padding: '2em 1em', color: '#fff', borderRadius: '15px 15px 0 0', position: 'relative', background: 'linear-gradient(109.78deg, #2C8C63 -15.8%, #1F838E 120.54%)' }}>
                                        <img src={DesiredJob} alt='Desired job' style={{ position: 'absolute', top: '10px', right: '15px' }} />
                                        <span style={{ fontWeight: '450', color: '#FFF' }}>{selectedJob.district}</span>
                                        <span style={{ marginTop: '5px', fontWeight: '200', fontSize: '14px', color: '#FFF' }}>{selectedJob.unmatched_school_district}</span>
                                        <span style={{ marginTop: '5px', lineHeight: '1.2', fontWeight: '600', color: '#FFF', fontSize: '24px' }}>{selectedJob.job_title}</span>
                                        <Grid container spacing={1} style={{ marginTop: '2.5em', display: 'flex', alignItems: 'center', direction: 'row' }}>

                                            {
                                                (selectedJob.state || selectedJob.city) ? (
                                                    <Grid item>
                                                        <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >

                                                            <img src={San} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >{selectedJob.city} {selectedJob.state}</button>
                                                        </div>

                                                    </Grid>
                                                ) : null
                                            }

                                            <Grid item>
                                                {
                                                    selectedJob.license_required ? <>
                                                        <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                            <img src={Teacher} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} > Teacher’s license  Required</button>
                                                        </div>
                                                    </> : null
                                                }
                                            </Grid>

                                            <Grid item>
                                                {
                                                    selectedJob.salary_from ? <>
                                                        <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                            {calculateDollar(selectedJob.salary_from)}
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Salary Estimate</button>
                                                        </div>
                                                    </> : null
                                                }
                                            </Grid>
                                            <Grid item>
                                                {
                                                    selectedJob.phd_required ? <>
                                                        <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                            <img src={Phd} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >    PHD Required</button>
                                                        </div>
                                                    </> : null
                                                }
                                            </Grid>
                                            <Grid item>
                                                {
                                                    selectedJob.shift_type ? <>
                                                        <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                            <img src={FullTime} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Full-time</button>
                                                        </div>
                                                    </> : null
                                                }
                                            </Grid>
                                        </Grid>
                                       
                                        <Grid container justifyContent='space-between' >
                                            <Grid container direction='column' style={{ marginTop: '1em' }} item lg={6} >
                                                {
                                                    selectedJob.date_created ? (
                                                        <span style={{ fontSize: '14px', color: '#FFF', marginTop: '25px' }}>Start Date: <span style={{ fontWeight: '400' }}>{selectedJob.date_created}</span></span>
                                                    ) : null
                                                }
                                                {
                                                    selectedJob.expiry_date ? (
                                                        <span style={{ fontSize: '14px', color: '#FFF', marginTop: '10px' }}>Job expiry: <span style={{ fontWeight: '400' }}>{selectedJob.expiry_date}
                                                            {
                                                                calculateDayLeft(selectedJob.expiry_date) ? (
                                                                    <> ({calculateDayLeft(selectedJob.expiry_date)} days left apply now)</>
                                                                ) : <span style={{ fontWeight: 'bold' }}> Expired!</span>
                                                            }
                                                        </span>
                                                        </span>
                                                    ) : null
                                                }
                                            </Grid>

                                            <Grid container justifyContent={width < 1280 ? 'center' : 'flex-end'} style={{ marginTop: '1em' }} alignItems='center' direction='row' item lg={6} >
                                                {
                                                    selectedJob.is_saved_job ?
                                                        <FaHeart style={{ color: 'orange' }} size='1.5em' onClick={() => saveJobFn(selectedJob.id)} /> :
                                                        <FaRegHeart size='1.5em' onClick={() => saveJobFn(selectedJob.id)} />
                                                }
                                                <span style={{ marginLeft: '5px' }}>Save Job</span>
                                                <a href={selectedJob.apply_url} target="_" style={{ color: 'unset', textDecoration: 'none' }}>
                                                    <button style={{ color: '#000', background: '#FFF', padding: '0.5em 1em', fontSize: '14px', borderRadius: '25px', border: '0px', marginLeft: '15px', fontWeight: '600' }}>
                                                        Apply now
                                                    </button>
                                                </a>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid container direction='column' style={{ padding: '2em 1em', color: '#000', borderRadius: '0 0 15px 15px', position: 'relative', background: '#fff' }}>
                                        <Grid container spacing={1}>
                                            <Grid container direction='column' item lg={6} md={6} sm={6} xs={12}>
                                                <span style={{ fontWeight: '500', fontSize: '19px' }}>Learn more about the employer</span>
                                                <span style={{ fontWeight: '400', fontSize: '14px' }}>Chat with people who already worked at this school and get to know your future job</span>

                                            </Grid>

                                            <Grid container spacing={1} direction='row' item lg={6} md={6} sm={6} xs={12}>
                                                <Grid item lg={6}>
                                                    <div style={{ border: '1px solid #E4E8F1', padding: '0 5px', background: '#FFF', height: '62px', borderRadius: '25px', display: 'flex', alignItems: 'center' }} onClick={() => setModal(true)}   >

                                                        <img src={Profile} />
                                                        <button style={{ background: '#FFF', fontWeight: '600', textAlign: 'start', border: 'none', fontSize: '10px' }} >Chat with employees of this school </button>
                                                    </div>

                                                </Grid>
                                                <Grid item lg={6}>
                                                    <div style={{ border: '1px solid #E4E8F1', padding: '0 5px', background: '#FFF', height: '62px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >

                                                        <img src={School} />
                                                        <button style={{ background: '#FFF', fontWeight: '600', textAlign: 'start', border: 'none', fontSize: '10px' }} >Chat with Hiring department of this school </button>
                                                    </div>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction='column' style={{ margin: '3em 0' }}>
                                            <Tabs
                                                variant="scrollable"
                                                scrollButtons
                                                allowScrollButtonsMobile
                                                value={tabValue}
                                                onChange={(event, newVal) => { setTabValue(newVal); }}
                                                textColor="inherit"
                                                TabIndicatorProps={{ style: { background: 'orange' } }}
                                                aria-label="secondary tabs example"
                                            >
                                                <Tab style={{ textTransform: 'none' }} value="job-description" label="Job Description" />
                                                {/* <Tab style={{ textTransform: 'none' }} value="employer-details" label="Employer Details" /> */}
                                            </Tabs>
                                            {renderSwitch(tabValue)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid >
            <Modal
                open={modal}
                onClose={() => setModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modalCont'><JobModal closeModal={() => setModal(false)} /></div>
            </Modal>
        </>
    )
}

export default SavedJobs;

const JobModal = (props) => {

    const { height, width } = useWindowDimensions();
    

    const responsive1 = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,

        }
    };


    return (
        <div style={{ position: 'relative' }}>
            <img src={CloseIcon} alt="" className='modalClose' onClick={props.closeModal} style={{ marginTop: '15px' }} /><br></br>
            <div className='ModalHeading'>Learn about the school district’s work culture</div>
            <div className='ModalDescription'>Talk to the employees or ex-employees  and network to understand more about your future job</div>
            {/* <div className="chevronCont">
                <img src={LeftChevron} alt="" onClick={scrollLeft} style={{ margin: 'auto' }} />
                <img src={RightChevron} alt="" onClick={scrollRight} style={{ margin: 'auto' }} />
            </div> */}
            <div className="modalOF" id='Carousel'>
                {/* <div className="carouselCont" >  */}
                {/* <CarouselCard/>
                    <CarouselCard />
                    <CarouselCard />
                    <CarouselCard />
                    <CarouselCard />
                    <CarouselCard /> */}
                <Carousel
                    style={{padding: '10px'}}
                    slidesToSlide={1}
                    className='mobJobCarousel'
                    // removeArrowOnDeviceType={["tablet", "mobile"]}

                    responsive={responsive1}>
                    {carouselcontent.map(props => {
                        return (
                            <div className='carouselCardCont' style={{margin: '0px'}}>
                                <div className="cardProfile">
                                    <img className="profilePic" src="//unsplash.it/80/80" alt="" />
                                    <div>
                                        <div className="profileName">{props.name}</div> 
                                        <div className="profileDesc">Highschool English Teacher</div>
                                        <div className="profileDesc">San Francisco, California</div>
                                    </div>
                                </div>
                                <div>BADGES</div>
                                <div style={{ margin: '20px 0' }}>
                                    <img src={Badge1} alt="" style={{ marginRight: '13px' }} />
                                    <img src={Badge2} alt="" />
                                </div>
                                <div>
                                    <div>WORK EXPERIENCE</div>
                                    <div style={{ margin: '12px 0' }} className="cardText"><b>English teacher at Summit Public Schools</b></div>
                                    <div className="cardText">High School 125 Kent Place Boulevard Summit New Jersey, United States - 07901</div>
                                    <div className="cardText">2016 - 2020 (4 year experience)</div>
                                </div>
                                <div className="cardText" style={{ margin: '20px 0' }}><b>You can message Jenny regarding this school and learn about her experience working there</b></div>
                                <button className="cardC2a">
                                    <img src={Q} alt="" />
                                    Ask Questions
                                </button>
                            </div>
                        )
                    })}

                </Carousel>
                {/* </div>  */}
            </div>
        </div>
    )
}

const CarouselCard = () => {
    return (
        <div className='carouselCardCont'>
            <div className="cardProfile">
                <img src="//unsplash.it/80/80" alt="" />
                <div>
                    <div className="profileName">Jenny Wilson</div>
                    <div className="profileDesc">Highschool English Teacher</div>
                    <div className="profileDesc">San Francisco, California</div>
                </div>
            </div>
            <div>BADGES</div>
            <div style={{ margin: '20px 0' }}>
                <img src={Badge1} alt="" style={{ marginRight: '13px' }} />
                <img src={Badge2} alt="" />
            </div>
            <div>
                <div>WORK EXPERIENCE</div>
                <div style={{ margin: '12px 0' }}><b>English teacher at Summit Public Schools</b></div>
                <div>High School 125 Kent Place Boulevard Summit New Jersey, United States - 07901</div>
                <div>2016 - 2020 (4 year experience)</div>
            </div>
            <div style={{ margin: '20px 0' }}><b>You can message Jenny regarding this school and learn about her experience working there</b></div>
            <button className="cardC2a">
                <img src={Q} alt="" />
                Ask Questions
            </button>
        </div>
    )
}

const carouselcontent = [
    {
        name: 'Jenny Wilson'
    },
    {
        name: 'Jenny Wilson'
    },
    {
        name: 'Jenny Wilson'
    },
    {
        name: 'Jenny Wilson'
    }
]
