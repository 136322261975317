import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Logo from './logo.svg';

import './style.css'

function NavBar_noProfile_emp() {

  return (
    <Navbar fixed="top" expand="lg" collapseOnSelect style={{ backgroundColor: '#b6feed' }} variant="light">
      <Container style={{maxWidth:'100%', margin:'0 20px'}}>
        <Navbar.Brand href="/dashboard"><img src={Logo} alt="logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/search-candidate" className="nav-links">Candidate Search</Nav.Link>
            <Nav.Link href="/employer/job-posting" className="nav-links" >Job Posts</Nav.Link>
            <Nav.Link href="/employer/favorite" className="nav-links">Favorites</Nav.Link>
            <Nav.Link href="#plans" className="nav-links">Frame Change Content</Nav.Link>
            {/* <Nav.Link href="#plans" className="nav-links" >Subscription Plans</Nav.Link> */}
          </Nav>
          <Nav className="ml-auto">
            <Nav.Link href="/" style={{ fontSize: '16px' }} className="nav-links">Find Jobs</Nav.Link>
            <Nav.Link href="/login" style={{ fontSize: '16px' }} className="nav-links">Sign In</Nav.Link>
            <Nav.Link href="/employer/register" style={{ fontSize: '16px', color: 'white', padding: '15px' }} className="sign-up">Sign Up</Nav.Link>
          </Nav>
          <Nav>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavBar_noProfile_emp;

