import React from 'react'
//import NavbarEmp from '../NavBar_emp/index.js'
import { useCallback, useState, useEffect } from 'react';
import NavBar_noProfile_emp from '../NavBar_noProfile_emp/index.js';
import SearchIcon from './Group 217.png'
import { Container, Grid } from '@material-ui/core'
import Lp2 from '../index/Frame 2087.png'
import Lp1 from './Group 2132.png'
import Lp3 from './Group 2133.png'
import Quotes from './Group 2136.png'
import Footer from '../Footer/index.js'
import Left from './Vector 21.png'
import Right from './Vector 20.png'
import Cookies from 'js-cookie';

import './employerLp.css'

const EmployerLp = () => {
  useEffect(() => {
    // if(!(localStorage.getItem('name') && localStorage.getItem('email') && localStorage.getItem('phone')))
    // window.addEventListener('storage', ()=>{
    //   console.log('hello');
    if (localStorage.getItem('access_token')) {
      checkEmployee();
      Cookies.set('access_token', localStorage.getItem('access_token'));
    }
    if (localStorage.getItem('user')) {
      Cookies.set('user', localStorage.getItem('user'));
    }
    if (localStorage.getItem('refresh_token')) {
      Cookies.set('refresh_token', localStorage.getItem('refresh_token'));
    }
    // })
    // console.log(profile);
  }, [])
  return (
    <div>
      <NavBar_noProfile_emp  />
      <div className="emplLpHero">
        <div className="bgcFilter"/>
          <div className="heroHeading">Find your next best educator.</div>
          <div className="heroSubHeading">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla risus aliquet fusce suspendisse. Rhoncus morbi felis orci viverra. </div>
        <div className="heroContentDiv">
          <div className="emplLpfeaturesCont">
            <div className="empLpFeature">
              <img src={ SearchIcon } alt="" />
              <div>
                <div className="empLpFeatureHeading">Search Canidate</div>
                <div className="empLpFeatureSubHeading">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
              </div>
            </div>
            <div className="empLpFeature">
              <img src={ SearchIcon } alt="" />
              <div>
                <div className="empLpFeatureHeading">Video Resume</div>
                <div className="empLpFeatureSubHeading">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
              </div>
            </div>
            <div className="empLpFeature">
              <img src={ SearchIcon } alt="" />
              <div>
                <div className="empLpFeatureHeading">Message Candidate</div>
                <div className="empLpFeatureSubHeading">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
              </div>
            </div>
          </div>
          <div className="c2aHead">Reach out to use right now to get you started</div>
          <button className="c2aBtn">Contact us</button>
        </div>
      </div>
      <div style={{backgroundColor: '#F3F7FF', padding: '50px auto'}}>
        <Container maxWidth="lg">
          <Grid container spacing={2} style={{width: '100%', justifyContent: 'space-between', marginTop: '50px' }} className='mobCenter'>
            <Grid item style={{maxWidth: '500px'}}>
              <img src={Lp1} alt="" style={{maxWidth: '95vw'}} />
            </Grid>  
            <Grid item style={ { maxWidth: '550px', alignContent: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' } }>
              <h2>Watch video-resume even before scheduling a call.</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla risus aliquet fusce suspendisse. Rhoncus morbi felis orci viverra. </p>
            </Grid>
          </Grid>
          </Container>
      </div>
      <div style={{margin: '120px auto', width: 'fit-content'}}>
        <img src={ Lp3 } alt="" style={ { marginBottom: '69px', maxWidth: '95vw' } } />
        <div className="headLp">Manage all your job posts and keep track of them.</div>
        <div className="DescLp">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla risus aliquet fusce suspendisse. Rhoncus morbi felis orci viverra. </div>
      </div>
      <div style={{backgroundColor: '#F3F7FF', padding: '50px auto'}}>
        <Container maxWidth="lg">
          <Grid container spacing={2} style={{width: '100%', justifyContent: 'space-between', marginTop: '50px', flexDirection: 'row-reverse'}} className='mobCenter'>
            <Grid item style={{maxWidth: '500px'}}>
              <img src={Lp2} alt="" style={{maxWidth: '95vw'}} />
            </Grid>  
            <Grid item style={ { maxWidth: '550px', alignContent: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' } }>
              <h2>Connect with 10,000 top educators.</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla risus aliquet fusce suspendisse. Rhoncus morbi felis orci viverra. </p>
            </Grid>
          </Grid>
          </Container>
      </div>
      <Grid container spacing={2} style={{width: '100%', marginTop: '50px' }} className='mobCenter'>
        <Grid item style={{width: '45%', minWidth: '320px', background: '#9CDACB', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} className='paddLeft'>
          <div>
            <div style={{ color: '#141A21', fontWeight: 'bold', fontSize: '32px' }}>Our Happy Client</div>
            <div style={{ marginTop: '24px', fontSize: '14px', color: '#141A21' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla risus aliquet fusce suspendisse. Rhoncus morbi felis orci viverra. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis lacus sit malesuada id. Interdum ut lorem in vitae interdum massa. </div>
          </div>
          <div style={{display: 'inline-flex', gap: '40px', alignItems: 'center', cursor: 'pointer', marginTop: '20px'}}>
            <img src={Left} alt="" />
            <img src={Right} alt="" />
          </div>
        </Grid>  
        <Grid item style={ { width: '55%', minWidth: '320px', alignContent: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'} }  className='paddRight'>
          <img src={Quotes} style={{alignSelf: 'end', marginBottom: '25px'}} alt="" />
          <div style={{display: 'inline-flex', alignItems: 'center', gap: '10px', marginBottom: '40px'}}>
            <img src="//unsplash.it/50/50" style={{borderRadius: '50%'}} alt="" />
            <div>
              <div style={{fontWeight:'600', fontSize: '20px', marginBottom: '4px'}}>Alex Parkinson</div>
              <div style={{fontSize: '12px', color: '#939393'}}>Cheif Operating Officer</div>
            </div>
          </div>
          <p style={{fontSize: '20px', lineHeight: '30px', marginBottom: '70px', color: '#636363'}}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet egestas nunc, suscipit donec sed urna, rhoncus enim volutpat. Aliquam in dapibus pellentesque diam elit orci bibendum. Aliquet a felis semper proin ut facilisi felis semper proin ut facilisi et et odio.
          </p>
          <img src='//unsplash.it/125/67' style={{width: '125px'}} alt="" />
        </Grid>
      </Grid>
      <Footer />
    </div>
  )
}
const checkEmployee = () => {
  if(Cookies.get('access_token')){
    if(Cookies.get('user') === 'JobSeeker'){
      window.location.href="/dashboard"
    }
  }else{
    window.location.href="/login";
  }
}

export default EmployerLp
