import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, TextField, Typography, Button, Input } from '@material-ui/core';
import Logo from './logo.svg';
import S4 from './S4.svg';
import S1 from './S1.svg';
import Map from './storymap.svg';
import lateral from './lateral2.svg';
import Dollar from './dollar.svg';
import Desire from './desiredJob.svg';
import Time from './time.svg';
import Dislike from './dislike.svg';
import LikeImg from './like.svg';
import Teacher from './teacher.svg';
import FullTime from './fullTime.svg';
import Phd from './phd.svg';
import LaunchIcon from '@mui/icons-material/Launch';
import CloseIcon from '@mui/icons-material/Close';
import Carousel from 'react-multi-carousel';
import Room from './room1.png';
import GoogleMapReact from 'google-map-react';
import { useHistory } from "react-router-dom";
import {Helmet} from "react-helmet";
import Alert from '../alerts/alert';
import $ from 'jquery';
import axiosInstance from '../../axios';
import axios from 'axios';
import Cookies from 'js-cookie';


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect( () => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}



const responsive1 = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1.5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1.5
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1.5
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,

    }
};



function Recommand(props) {

    useEffect(() => {
        document.getElementsByClassName('recommandModal')[0]?.scrollTo(0,0);
        // console.log(remJobs);
    }, [])

    let history = useHistory();


    const { height, width } = useWindowDimensions();

    const [Apply, setApply] = React.useState(false);

    const handleApply = () => {
        setApply(!Apply);

    }

    const calculateDollar = (value) => {
        if(value > 0 && value <= 19){
            return(
                <img src={Dollar} />
            )
        }else if(value >19 &&  value<=39){
            return(
                <React.Fragment>
                    <img src={Dollar} />
                    <img src={Dollar} />
                </React.Fragment>
            )
        }else if(value > 39 && value <= 59){
            return(
                <React.Fragment>
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                </React.Fragment>
            )
        }else if(value > 59 && value <= 79){
            return(
                <React.Fragment>
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                </React.Fragment>
            )
        }else if(value > 79){
            return(
                <React.Fragment>
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                </React.Fragment>
            )
        }
    
    }

    const calculateDayLeft = (expiryDate) => {
        const curr = new Date();
        const exp = new Date(expiryDate);
        const oneDay = 24 * 60 * 60 * 1000;

        if(curr > exp)
            return false;

        const diffDays = Math.round(Math.abs((exp - curr) / oneDay));

        return diffDays;
    }


    const [Apply2, setApply2] = React.useState(false);

    const handleApply2 = () => {
        setApply2(!Apply2);

    }

    const [Like, setLike] = React.useState(false);
    const handleLike = () => {
        setLike(!Like);
    }
    const [Like2, setLike2] = React.useState(false);
    const handleLike2 = () => {
        setLike2(!Like2);
    }

    const [Disike, setDisike] = React.useState(false);
    const handleDislike = () => {
        setDisike(!Disike);

    }
    const [Disike2, setDisike2] = React.useState(false);
    const handleDislike2 = () => {
        setDisike2(!Disike2);

    }


    const Map = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    const [inimodalData, setinimodalData] = useState(props.data);
    const [modalData, setmodalData] = useState(props.data);
    const [remJobs, setremJobs] = useState( props.remJobs.slice(1,props.remJobs.length-1) );
    const [activeJob, setActiveJob] = useState();

    const [appliedJobsId, setappliedJobsId] = useState([]);
    // let appliedJobsId = [];

    const changeModalData = (item, index) => {
        const jobs = Array.from(document.getElementsByClassName('remJobs'));
        console.log(jobs);
        jobs.map(i=>{
            i.classList.remove('active_remJob');
        });
        document.getElementById(`remJob_${item.id}`).classList.add('active_remJob');

        setActiveJob( index );
        setmodalData(item);

        setLike(false);
        setLike2(false);
        setDisike(false);
        setDisike2(false);
    }

    let likeKeywords = [];
    let dislikeKeywords = [];

    const handleJobLike = (e) => {
        try{
            if (e.target.classList.contains('optionsCandiActive')) {
                e.target.classList.remove('optionsCandiActive');
                likeKeywords = likeKeywords.filter(i=>i!==e.target.innerText);
            } else {
                e.target.classList.add('optionsCandiActive');
                likeKeywords.push(e.target.innerText);
            } 
        }catch(err)  {
            console.log(err.message);
        }
    }

    const handleLikeFeedback = () => {
        const data = {
            job_id : modalData.id,
            feedback : likeKeywords,
        }
        
        axios.post('https://framechange--backend.herokuapp.com/seeker/positive_feedback/', data, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`,
                'Content-Type': 'application/json'
            }
        }).then(res => {
            // setappliedJobsId(appliedJobsId.push(modalData.id));
            const arr = [...appliedJobsId, modalData.id];
            setappliedJobsId(arr);
            console.log(appliedJobsId);
            // appliedJobsId.push(modalData.id);
            console.log(appliedJobsId, modalData.id);	
            setLike(false);
            setLike2(false);
            setAlertFunc('success', 'Succesfully Saved Job and Submitted Feedback Succesfully')
        }).catch(err => {
            setAlertFunc('error', 'Error in Saving Job');
        })
    }

    const handleJobDislike = (e) => {
        try{
            if (e.target.classList.contains('optionsCandiActive')) {
                e.target.classList.remove('optionsCandiActive');
                dislikeKeywords = dislikeKeywords.filter(i=>i!==e.target.innerText);
            } else {
                e.target.classList.add('optionsCandiActive');
                dislikeKeywords.push(e.target.innerText);
            } 
        }catch(err)  {
            console.log(err.message);
        }
    }

    const handleDislikeFeedback = () => {
        const data = {
            job_id : modalData.id,
            feedback : dislikeKeywords,
        }

        axios.post('https://framechange--backend.herokuapp.com/seeker/negative_feedback/', data, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`,
                'Content-Type': 'application/json'
            }
        }).then(res => {
            const arr = [...appliedJobsId, modalData.id];
            setappliedJobsId(arr);
            // appliedJobsId.push(modalData.id);
            setDisike(false);
            setDisike2(false);
            setAlertFunc('success', 'Submitted Feedback Succesfully')
        }).catch(err => {
            setAlertFunc('error', 'Something went wrong');
        })
    }

    //ALERT CODE:
    const [alertComp, setAlert] = React.useState(false)
    const [typeOfAlert, setType] = React.useState('')
    const [descOfAlert, setDesc] = React.useState('');

    const setAlertFunc = (type, desc) => {
        setAlert(true);
        setType(type);
        setDesc(desc);
    }

    return (
        <>
        
        <div style={ { background: '#000', paddingBottom: '8em' } }>
            <Helmet>
                <title>Frame Change</title>
            </Helmet>
            {/* ALERT */}
            {alertComp ? (
                <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlert} />
            ) : null}
            <Container maxWidth="xl">
                <Grid container justifyContent="space-between" style={{ paddingTop: '1em' }}>
                    <Grid item lg={1}>
                        <img src={Logo} alt="logo" />

                    </Grid>
                    <Grid item lg={1}>
                        <CloseIcon fontSize="large" style={{ color: '#fff',cursor:'pointer' }} onClick={props.closeRecommend ? props.closeRecommend : ()=>{history.push("/dashboard")}} />

                    </Grid>

                </Grid>

                <Grid container spacing={6} style={{ marginTop: '2em' }}>
                    {width < 970 ? <React.Fragment></React.Fragment> : <React.Fragment><Grid item container direction="column" spacing={3} lg={3} md={3}>
                        <Grid container className='scroller' style={{ position: 'sticky', top: '70px', overflow: 'auto', height : '500px' }}>
                        {activeJob > -1 ? 
                        <Grid item lg={12} style={{ marginTop: '10px', padding: '0.2em', borderRadius: '20px', background: '#2D2675', cursor: 'pointer' }} onClick={ () => { setActiveJob(-2);  setmodalData(inimodalData)}}>
                            <div style={{ display: 'flex', flexDirection: 'row', padding: '0.5em', alignItems: 'start' }}>
                                <img src={S4} alt="s4" style={{ width: 35 }} />
                                <div style={{ margin: '0 10px' }}>
                                    <Typography style={{ fontWeight: '600', fontSize: '16px', color: '#fff' }}>
                                        {inimodalData.job_title}
                                    </Typography>
                                    <Typography style={{ fontSize: '14px', color: '#fff' }}>
                                        {inimodalData.district}, {inimodalData.state}
                                    </Typography>
                                    <img src={lateral} alt="lateral" style={{ marginTop: '1em' }} />
                                </div>
                            </div>
                        </Grid> : <Grid item lg={12} style={{ marginTop: '10px', padding: '0.5em 1em', borderRadius: '20px', background: '#ffffff' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', padding: '0.5em', alignItems: 'start' }}>
                                <img src={S4} alt="s4" style={{ width: 35 }} />
                                <div style={{ margin: '0 10px' }}>
                                    <Typography style={{ fontWeight: '600', fontSize: '16px', color: '#000' }}>
                                        {modalData.job_title}
                                    </Typography>
                                    <Typography style={{ fontSize: '14px', color: '#000' }}>
                                        {modalData.district}, {modalData.state}
                                    </Typography>
                                    <img src={lateral} alt="lateral" style={{ marginTop: '1em' }} />
                                </div>
                            </div>
                        </Grid>}
                        <div>
                            {
                                remJobs.map((item, index)=>{
                                    return(
                                        <Grid item lg={ 12 } className="remJobs" id={`remJob_${item.id}`} onClick={()=>changeModalData(item, index)} style={ { marginTop: '10px', padding: '0.5em 1em', borderRadius: '20px', background: `${( activeJob === index ) ? '#ffffff' : '#2D2675'}`, cursor: 'pointer' } }>
                                            <div style={{ display: 'flex', flexDirection: 'row', padding: '0.5em', alignItems: 'start' }}>
                                                <img src={S4} alt="s4" style={{ width: 35 }} />
                                                <div style={{ margin: '0 10px' }}>
                                                    <Typography style={{ fontWeight: '600', fontSize: '16px', color: `${(activeJob === index) ? '#000000' : '#ffffff'}` }}>
                                                        {item.job_title}
                                                    </Typography>
                                                    <Typography style={{ fontSize: '14px', color: `${(activeJob === index) ? '#000000' : '#ffffff'}` }}>
                                                        {item.district}, {item.state}
                                                    </Typography>
                                                    <img src={lateral} alt="lateral" style={{ marginTop: '1em' }} />
                                                </div>
                                            </div>


                                        </Grid>
                                    )
                                })
                            }
                        </div>


                        </Grid>

                    </Grid></React.Fragment>}

                    <Grid item container lg={8} md={8}>

                        <Grid container >
                            <Grid item spacing={3} justifyContent="space-between" lg={12} style={{ marginTop: '10px', padding: '0.5em 1em', borderRadius: '20px', background: '#fff' }}>
                                <Grid container spacing={3} justifyContent="space-between" style={{ marginTop: '10px', padding: '0.5em 1em' }}>
                                    <Grid item lg={9}>
                                        <Typography style={{ fontWeight: '600', fontSize: '23px' }}>{modalData.job_title} {appliedJobsId.length} {appliedJobsId.includes(modalData.id)} </Typography>

                                    </Grid>
                                    <Grid item>
                                        <img src={Desire} alt="desire" />
                                    </Grid>
                                    {
                                        modalData.expiry_date ? (
                                            <Grid item lg={12}>
                                                <Typography style={{ fontWeight: '400', fontSize: '14px' }}>Job expiry : {modalData.expiry_date} ({calculateDayLeft(modalData.expiry_date)} days left apply now)</Typography>

                                            </Grid>
                                        ) : null
                                    }
                                </Grid>
                                <Grid container spacing={3} justifyContent="space-between">
                                    <Grid item lg={6}>
                                        <Grid container spacing={2} style={{ paddingLeft: '1em' }}>
                                            {
                                                modalData.salary_from ? (
                                                    <Grid item>
                                                        <Box>
                                                            <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}>
                                                                {calculateDollar(modalData.salary_from)}
                                                                <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Salary Estimate</button>
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                ) : null
                                            }
                                            
                                            {
                                                modalData.phd_required ? (
                                                    <Grid item>
                                                        <Box>
                                                            <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}>
                                                                <img src={Phd} />

                                                                <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >PHD Required</button>
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                ) : null
                                            }
                                            {
                                                modalData.license_required ? (
                                                    <Grid item>
                                                        <Box>
                                                            <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}>
                                                                <img src={Teacher} />

                                                                <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Teacher’s license  Required</button>
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                ) : null
                                            }
                                            {
                                                modalData.shift_type ? (
                                                    <Grid item>
                                                        <Box>
                                                            <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}>
                                                                <img src={FullTime} />

                                                                <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >{modalData.shift_type}</button>
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                ) : null
                                            }
                                           
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={5}>
                                        <Grid container spacing={2} >
                                            <Grid item lg={12}>

                                                <img src={S1} alt="s1" />

                                            </Grid>
                                            <Grid item lg={12}>

                                                <Typography style={{ fontWeight: '600', fontSize: '15px' }}>{modalData.district}</Typography>

                                            </Grid>
                                            {
                                                (modalData.city || modalData.state) ? (
                                                    <Grid item lg={8}>

                                                        <Typography style={{ fontSize: '13px' }}>{modalData.city} {modalData.state}</Typography>

                                                    </Grid>
                                                ) : null
                                            }

                                        </Grid>

                                    </Grid>

                                </Grid>

                                <br />
                                <br />

                                {Disike ? <React.Fragment><Typography style={{ textAlign: 'center', fontWeight: '600', fontSize: '22px' }}>Why didn't you like this jobs?</Typography>
                                    <br />
                                    <Grid container justifyContent="center" spacing={3} id='dislike-container'>
                                        <Grid item lg={3}>
                                            <Box>
                                                <div >
                                                    <button onClick={handleJobDislike} style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center', fontSize: '12px' }} >Salary is not sufficient</button>
                                                </div>
                                            </Box>

                                        </Grid>
                                        <Grid item lg={3}>
                                            <Box>
                                                <div>
                                                    <button onClick={handleJobDislike} style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center', fontSize: '12px' }} >I didn't meet the qualification</button>
                                                </div>
                                            </Box>

                                        </Grid>
                                        <Grid item lg={3}>
                                            <Box>
                                                <div>
                                                    <button onClick={handleJobDislike} style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center', fontSize: '12px' }} >It's very far from my location    </button>
                                                </div>
                                            </Box>

                                        </Grid>
                                    </Grid>

                                    <div className='d-flex justify-content-center my-2'>
                                        <a onClick={handleDislikeFeedback}  style={{ textTransform: 'none', cursor : 'pointer', fontSize: '15px', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', padding: '0.5em 1.5em', color: '#fff', borderRadius: '50px', textAlign: 'center' }} autoFocus 
                                        >
                                            Done
                                        </a>
                                    </div>
                                    <br />
                                    
                                </React.Fragment> : Like ? <>
                                <Typography style={{ textAlign: 'center', fontWeight: '600', fontSize: '22px' }}>Why you like this jobs?</Typography>
                                    <br />
                                    <Grid container justifyContent="center" spacing={3}>
                                        <Grid item lg={3}>
                                            <Box>
                                                <div >
                                                    <button onClick={handleJobLike}  style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center', fontSize: '12px' }} >Salary is sufficient</button>
                                                </div>
                                            </Box>

                                        </Grid>
                                        <Grid item lg={3}>
                                            <Box>
                                                <div>
                                                    <button  onClick={handleJobLike} style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center', fontSize: '12px' }} >It meets the qualification</button>
                                                </div>
                                            </Box>

                                        </Grid>
                                        <Grid item lg={3}>
                                            <Box>
                                                <div>
                                                    <button onClick={handleJobLike}  style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center', fontSize: '12px' }} >It's near my location    </button>
                                                </div>
                                            </Box>

                                        </Grid>
                                    </Grid>
                                    <div className='d-flex justify-content-center my-2'>
                                        <a onClick={handleLikeFeedback}  style={{ textTransform: 'none', cursor : 'pointer', fontSize: '15px', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', padding: '0.5em 1.5em', color: '#fff', borderRadius: '50px', textAlign: 'center' }} autoFocus 
                                        >
                                            Done
                                        </a>
                                    </div>
                                    <br />
                                    
                                </> : Apply2 ? <React.Fragment> <Typography style={{ textAlign: 'center', fontWeight: '600', fontSize: '22px' }}>Awesome! Let's go to the job website to apply</Typography>
                                    <br />
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                                        <a href={modalData.apply_url} target='_blank' style={{ textTransform: 'none', fontSize: '10px', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', padding: '0.5em', color: '#fff', borderRadius: '50px', textAlign: 'center' }} autoFocus 
                                        >
                                            Apply now <LaunchIcon style={{ margin: '0 0.5em' }} />
                                        </a>
                                    </div></React.Fragment> : <React.Fragment>
                                    <Typography style={{ textAlign: 'center', fontWeight: '600', fontSize: '18px' }}>Do you like this recommended job?</Typography>
                                    <br />
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                        {
                                            !appliedJobsId.includes(modalData.id) ? <>
                                                <img src={LikeImg} style={{ width: '3.5em', margin: '2px 1em', cursor: 'pointer' }} alt="dislike" onClick={() => handleLike()} />
                                                <img src={Dislike} style={{ width: '3.5em', margin: '2px 1em', cursor: 'pointer' }} alt="dislike" onClick={() => handleDislike()} />
                                            </>
                                            : null
                                        }
                                        <a href={modalData.apply_url} target='_blank' style={{ textTransform: 'none', height: '50px', fontSize: '15px', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', padding: '1em', color: '#fff', borderRadius: '50px', textAlign: 'center' }} autoFocus>
                                            Apply now <LaunchIcon />
                                        </a>
                                    </div></React.Fragment>
                                }
                                {/* <img src={LikeImg} style={{ width: '3.5em', margin: '2px 1em', cursor: 'pointer' }} alt="dislike" onClick={() => handleLike()} />
                                <img src={Dislike} style={{ width: '3.5em', margin: '2px 1em', cursor: 'pointer' }} alt="dislike" onClick={() => handleDislike()} /> */}
                                        

                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item spacing={3} justifyContent="space-between" lg={12} style={{ marginTop: '10px', padding: '0.5em 1em', borderRadius: '20px', background: '#fff', maxWidth: '100%' }}>
                                <Grid container direction='column' style={{ marginTop: '1em' }}>
                                    <span style={{ fontSize: '22px', fontWeight: '550' }}>Full Job Description</span>
                                    <p style={{maxWidth: '100%', flex: 1, flexGrow: 1, wordWrap:'break-word'}}>
                                        {modalData.description}
                                    </p>

                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid container>
                            <Grid item spacing={3} justifyContent="space-between" lg={12} style={{ marginTop: '10px', padding: '0.5em 1em', borderRadius: '20px', background: '#fff' }}>
                                <div style={{ margin: '2em 0' }}>
                                    {Disike2 ? <React.Fragment><Typography style={{ textAlign: 'center', fontWeight: '600', fontSize: '22px' }}>Why didn't you like this jobs?</Typography>
                                        <br />
                                        <Grid container justifyContent="center" spacing={3}>
                                            <Grid item lg={3}>
                                                <Box>
                                                    <div >


                                                        <button style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center', fontSize: '12px' }} >Salary is not sufficient</button>
                                                    </div>
                                                </Box>

                                            </Grid>
                                            <Grid item lg={3}>
                                                <Box>
                                                    <div>


                                                        <button style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center', fontSize: '12px' }} >I didn't meet the qualification</button>
                                                    </div>
                                                </Box>

                                            </Grid>
                                            <Grid item lg={3}>
                                                <Box>
                                                    <div>


                                                        <button style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center', fontSize: '12px' }} >It's very far from my location    </button>
                                                    </div>
                                                </Box>

                                            </Grid>





                                        </Grid>
                                        <br />
                                        <Grid container justifyContent="center" spacing={3}>
                                            <Grid item lg={3}>
                                                <Box>
                                                    <div >


                                                        <button style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center', fontSize: '12px' }} >Salary is not sufficient</button>
                                                    </div>
                                                </Box>

                                            </Grid>
                                            <Grid item lg={3}>
                                                <Box>
                                                    <div>


                                                        <button style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center', fontSize: '12px' }} >I didn't meet the qualification</button>
                                                    </div>
                                                </Box>

                                            </Grid>
                                            <Grid item lg={3}>
                                                <Box>
                                                    <div>


                                                        <button style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center', fontSize: '12px' }} >It's very far from my location    </button>
                                                    </div>
                                                </Box>

                                            </Grid>





                                        </Grid>
                                    </React.Fragment> : Apply ? <React.Fragment> <Typography style={{ textAlign: 'center', fontWeight: '600', fontSize: '22px' }}>Awesome! Let's go to the job website to apply</Typography>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                                            <a href={modalData.apply_url} target='_blank' style={{ textTransform: 'none', fontSize: '20px', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', padding: '1em', color: '#fff', borderRadius: '50px' }} autoFocus onClick={() => handleApply()}>
                                                Apply now <LaunchIcon style={{ margin: '0 0.5em' }} />
                                            </a>
                                        </div></React.Fragment> : <React.Fragment>
                                        <Typography style={{ textAlign: 'center', fontWeight: '600', fontSize: '18px' }}>Do you like this recommended job?</Typography>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                            <img src={LikeImg} style={{ width: '3.5em', margin: '2px 1.5em', cursor: 'pointer' }} alt="dislike" onClick={() => handleDislike2()} />
                                            
                                            <img src={Dislike} style={{ width: '3.5em', margin: '2px 1.5em', cursor: 'pointer' }} alt="dislike" onClick={() => handleDislike2()} />
                                            <a href={modalData.apply_url} target='_blank' style={{ textTransform: 'none', height: '50px', fontSize: '15px', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', padding: '1em', color: '#fff', borderRadius: '50px' }} autoFocus onClick={() => handleApply()}>
                                                Apply now <LaunchIcon />
                                            </a>
                                        </div></React.Fragment>}



                                </div>

                            </Grid>


                        </Grid>












                    </Grid>






                </Grid>

            </Container>


        </div>
        </>
    )
}

export default Recommand
