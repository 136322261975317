import { Box, Container, Grid, Typography, Tabs, Tab } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import "react-multi-carousel/lib/styles.css";
import NavBarSrch from '../NavBar_withSearch';
import Dollar from '../Dashboard/dollar.svg';
import Phd from '../Dashboard/phd.svg';
import Teacher from '../Dashboard/teacher.svg';
import FullTime from '../Dashboard/fullTime.svg';
import { FaHeart } from 'react-icons/fa';
import DesiredJob from '../Dashboard/desiredJob.svg';
import Carousel from 'react-multi-carousel';
import NoSavedJobsHeart from './noSavedJobsHeart.png';
import Lateral from '../Dashboard/lateral.svg';
import Stretch from '../Dashboard/stretch.svg';
import S1 from '../Dashboard/S1.svg';
import S2 from '../Dashboard/S2.svg';
import S3 from '../Dashboard/S3.svg';
import S4 from '../Dashboard/S4.svg';
import Footer from '../Footer';
import Mail from './mail.png'
import Call from './call.png'
import { FaRegHeart } from 'react-icons/fa';
import Contact from './contact.png'
import Saji from './saji.png';
import English from './english.svg';
import French from './french.svg';
import Spanish from './spanish.svg';
import SmallMail from './smallMail.svg';
import Phone from './phone.svg';
import {Helmet} from "react-helmet";
import ViewProfile from './viewFullProfile.svg';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}
function NoSavedJobs() {
    const responsive1 = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1.5
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,

        }
    };


    const { height, width } = useWindowDimensions();

    const RecommendedJobsData = [
        {
            title: 'Math Teacher',
            logo: S1,
            secondLogo: DesiredJob,
            location: 'San Francisco, California',

        },
        {
            title: 'Superintendent ',
            logo: S2,
            secondLogo: Stretch,
            location: 'San Francisco, California',

        },
        {
            title: 'Math Teacher',
            logo: S3,
            secondLogo: Lateral,
            location: 'Philadelphia, Pennsylvania',

        },
        {
            title: 'Math Teacher',
            logo: S4,
            secondLogo: Lateral,
            location: 'Philadelphia, Pennsylvania',

        },
    ];

    const HotJobsData = [
        {
            title: 'Superintendent',
            logo: S1,
            secondLogo: DesiredJob,
            location: 'Montgomery County Public Schools - Silver Spring, MD',

        },
        {
            title: 'English Teacher',
            logo: S2,
            secondLogo: Stretch,
            location: 'Montgomery County Public Schools - Silver Spring, MD',

        },
        {
            title: 'Superintendent',
            logo: S1,
            secondLogo: DesiredJob,
            location: 'Montgomery County Public Schools - Silver Spring, MD',

        }
    ];

    const responsiveHotJobs = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1.8
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1.6
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,

        }
    };

    const [toggleHeart, setToggleHeart] = useState(false)

    const changeColor = useCallback(() => {
        setToggleHeart(!toggleHeart)
    }, [])

    return (
        <>
            <Helmet>
                <title>Frame Change</title>
            </Helmet>
            <NavBarSrch />
            <Grid style={{ padding: '3.5em 0', background: '#F5F7FA', marginTop:'3em' }} container alignItems='center' justifyContent='center' direction='column'>

                <Grid container direction='column' style={{ width: '90%' }}>

                    <Container  style={{ margin: 'auto',justifyContent:'center',textAlign:'center',alignItems:'center',display:'flex',flexDirection:'column',width:'100%',minHeight:'392px',padding: '0',background:'#FFF',boxShadow:'0px 2px 10px rgba(134, 150, 166, 0.19)',borderRadius:'10px' }} >
                        <img style={{width:'100%',maxWidth:'20em'}}  src={NoSavedJobsHeart} alt=''/>
                        <span style={{fontSize:'23px',padding:'1em',fontWeight:'500'}}>You have not any saved job yet</span>
                        <p style={{color:'#808080',padding:'1em',maxWidth:'37em'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus maecenas nunc mattis malesuada</p>
                    </Container>
                </Grid>

                <Grid container direction='column' style={{ width: '90%' }}>

                    <span style={{ fontSize: '19px', color: '#808080', padding: '24px' }}>HOT JOBS</span>
                    <Container style={{ margin: 'auto', padding: '0' }} >

                        <Carousel
                            ssr
                            partialVisbile
                            itemClass="image-item"
                            infinite={true}
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            autoPlaySpeed={5000}
                            responsive={responsiveHotJobs}>

                            {HotJobsData.map(props => {
                                return (
                                    <Grid container alignItems='center' justifyContent='center'  style={{ background: '#fff', width : '100%', marginRight : '15%'}} >

                                        <Grid container style={{ boxShadow: '0px 0px 7px 0px rgb(0 0 0 / 30%)', padding: '5px 15px 20px 15px', background: '#fff', margin: '0.5em 10px', borderRadius: '10px' }} item lg={12} direction='column'  >
                                            <div style={{ gap: '20px', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                                                <div className='d-md-flex  justify-content-md-between' style={{paddingTop : '15px' , gap: '12px'}}>
                                                    <div className='d-flex justify-content-center'>
                                                        <img src='//unsplash.it/103/103' style={{width: '70px', height : '70px', borderRadius: '50%', margin : '5px'}} />
                                                        <div style={ { fontWeight: '500', fontSize: '19px', paddingTop : '8px' } }>
                                                            Jenny Wilson
                                                            <Typography style={ { fontSize: "14px", color: '#636363' } }>Math Teacher</Typography>
                                                            <Typography style={{ fontSize: "14px", color: '#636363' }}>San Francisco, California</Typography>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-center pt-md-0 pt-sm-2'>
                                                        <div>
                                                            <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                                                                <img src={Mail} style={{cursor: 'pointer'}} />
                                                                <img src={Call} style={{cursor: 'pointer'}} />
                                                                <img src={Contact} style={{cursor: 'pointer'}} />
                                                            </div>
                                                            <img src={Saji} style={{marginTop: '16px', cursor: 'pointer'}} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-md-flex justify-content-md-between'>
                                                    <div className='d-flex col-md-8 justify-content-md-start justify-content-sm-center col-sm-12' style={{ marginTop: '0.5em', flexWrap: 'wrap' }}>
                                                        <div style={{ border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                            <img src={Teacher} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Teacher’s license</button>
                                                        </div>
                                                        <div style={{ border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                            <img src={Teacher} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Teacher’s license</button>
                                                        </div>
                                                        <div style={{ border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                            <img src={Phd} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Phd completed</button>
                                                        </div>
                                                        <div style={{ border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                            <img src={Teacher} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Teacher’s license</button>
                                                        </div>
                                                        <div style={{ border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                            <img src={FullTime} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >5 Year Work Experience</button>
                                                        </div>
                                                        <div style={{ border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                            <img src={Phd} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Phd completed</button>
                                                        </div>
                                                    </div>
                                                    <div className='d-sm-flex  d-md-block justify-content-sm-center' style={{marginTop:'10px'}}>
                                                        <div style={{display: 'flex', gap: '7px', width: '180px', marginBottom: '8px'}}>
                                                            <img src={SmallMail} />
                                                            <Typography style={{ fontSize: "12px", color: '#636363' }}>test@temp.com</Typography>
                                                        </div>
                                                        <div style={{display: 'flex', gap: '7px', width: '180px', marginBottom: '8px'}}>
                                                            <img src={Phone} />
                                                            <Typography style={{ fontSize: "12px", color: '#636363' }}>+91 222 222 222</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-md-flex justify-content-md-between'>
                                                    <div className='d-flex col-md-7 justify-content-md-start justify-content-sm-center col-sm-12' style={{ marginTop: '0.5em', flexWrap: 'wrap'}}>
                                                        <div style={{ border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                            <img src={English} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >English(Advance)</button>
                                                        </div>
                                                        <div style={{ border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                            <img src={Spanish} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Spanish(Beginner)</button>
                                                        </div>
                                                        <div style={{ border: '1px solid #E4E8F1', margin: '5px', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                            <img src={French} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >French(Intermediate)</button>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-md-start justify-content-around col-md-5 col-sm-10' style={{ marginLeft : '0'}}>
                                                        <Grid item className='col-lg-2 col-md-4 col-sm-1' style={{marginTop: '25px', cursor : 'pointer', display: 'inline-flex', justifyContent:'center', gap: '5px'}}>
                                                            <FaRegHeart size='1.5em' /> 
                                                        </Grid>
                                                        <Grid item >
                                                            <img src={ViewProfile} style={{marginTop: '16px', cursor: 'pointer'}} />
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>)
                            })}
                        </Carousel>
                    </Container>
                </Grid>

                <Grid container direction='column' style={{ width: '90%' }}>

                    <span style={{ fontSize: '19px', color: '#808080', padding: '24px' }}>RECOMENDED JOBS</span>
                    <Container style={{ margin: 'auto', padding: '0' }} >

                        <Carousel
                            ssr
                            partialVisbile
                            itemClass="image-item"
                            infinite={true}
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            autoPlaySpeed={5000}
                            autoPlay
                            responsive={responsive1}>

                            {RecommendedJobsData.map(props => {
                                return (
                                    <Grid container alignItems='center' justifyContent='center' >

                                        <Grid container style={{ boxShadow: '0px 0px 7px 0px rgb(0 0 0 / 30%)', padding: '20px 15px 40px 15px', background: '#fff', margin: '0.5em 10px', borderRadius: '10px' }} item lg={12} direction='column'  >

                                            <Grid container justifyContent='space-between' direction='row' >
                                                <img style={{ height: '54px', width: '50px' }} src={props.logo} />
                                                <img src={props.secondLogo} />
                                            </Grid>
                                            <span style={{ fontSize: '24px', fontWeight: '500', marginTop: '20px' }}>{props.title}</span>
                                            <span style={{ fontWeight: '400', color: '#939393', fontSize: '14px' }}>{props.location}</span>
                                            <div style={{ marginTop: '2.5em', display: 'flex', alignItems: 'center', direction: 'row' }}>
                                                <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >

                                                    <img src={Dollar} />
                                                    <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Salary Estimate</button>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: '0.5em', display: 'flex', alignItems: 'center', direction: 'row' }}>
                                                <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >

                                                    <img src={Phd} />
                                                    <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >    PHD Required</button>
                                                </div>                            </div>
                                            <div style={{ marginTop: '0.5em', display: 'flex', alignItems: 'center', direction: 'row' }}>
                                                <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >

                                                    <img src={Teacher} />
                                                    <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} > Teacher’s license  Required</button>
                                                </div>                            </div>
                                            <div style={{ marginTop: '0.5em', display: 'flex', alignItems: 'center', direction: 'row' }}>
                                                <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >

                                                    <img src={FullTime} />
                                                    <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Full-time</button>
                                                </div>                                   </div>
                                        </Grid>
                                    </Grid>)
                            })}




                        </Carousel>


                    </Container>
                </Grid>
            </Grid>
            <Footer/>
        </>
    )
}

export default NoSavedJobs;
