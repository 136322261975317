import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

//ADD
import Cookies from 'js-cookie';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CookiePrompt() {
  const [open, setOpen] = React.useState(true);


  const handleAccept = () => {
    setOpen(false);
    Cookies.set('check', 'true', { expires: 365 })

  };

  const handleNo = () => {
    setOpen(false);
  };
  

  if (Cookies.get('check') === 'true') {
    return null
  }

  else {          //This means that the user has clicked agree
    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle fontFamily='Montserrat'>{"This Website uses Cookies"}</DialogTitle>
          <DialogContent>
            <DialogContentText fontFamily='Montserrat'>
              This website uses cookies in order to offer you the most relevant information. Please accept cookies for optimal performance.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button fontFamily='Montserrat' style={{
              background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%), #FFFFFF'
              , borderRadius: '102px', color: 'black'
            }} onClick={handleAccept}>Accept cookies</Button>
            <Button fontFamily='Montserrat' style={{
              background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%), #FFFFFF'
              , borderRadius: '102px', color: 'black'
            }} className="buttons" onClick={handleNo}>No</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

}
