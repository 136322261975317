import React, { useState, useEffect, useCallback } from 'react'
import $ from 'jquery'
import Modal from '@mui/material/Modal'
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'
import { RiDeleteBin6Line } from 'react-icons/ri'
import Select from 'react-select'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import MUIRichTextEditor from 'mui-rte'
import { Helmet } from 'react-helmet'
import Navbar from '../NavBar_withSearch/index.js'
import school_district from '../../assets/school.json'
import Checked from '../../images/checked.png'
import PHD from '../Dashboard/phd.svg'
import profImage from '../Job seeker/img6.svg'
import vidResVec from '../../images/vidResVec.png'
import Delete from '../../images/delete.png'
import DeleteWhite from '../../images/delete_white.png';
import DeleteIcon from '../emp_settings/deleteIcon.svg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import edit from '../../images/edit.png'
import addIcon from '../../images/addIcon.png'
import './styles.css'
import Cookies from 'js-cookie'
import axiosInstance from '../../axios'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import Footer from '../Footer/index.js'
import LinkedIn from './linkedin.svg'
import twitter from './twitter.svg'
import facebook from './facebook.svg'
import calendly from '../../assets/calendly.png';
import MaintainenceGuy from '../../images/undraw_maintenance_re_59vn 1.png'
import Alert from '../alerts/alert'
import { jobTitlesArr } from '../navbarArrays/navbarArrays';
import loaderJson from '../../assets/loader.json';
import Lottie from 'react-lottie';

const Profile = () => {
  //ALERT CODE:
  const [alertComp, setAlert] = React.useState(false)
  const [typeOfAlert, setType] = React.useState('')
  const [descOfAlert, setDesc] = React.useState('');

  const languagesArr = [
    { value: 'English', label: 'English' },
    { value: 'French', label: 'French' },
    { value: 'German', label: 'German' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'Italian', label: 'Italian' },
    { value: 'Portuguese', label: 'Portuguese' },
    { value: 'Russian', label: 'Russian' },
    { value: 'Japanese', label: 'Japanese' },
    { value: 'Hindi', label: 'Hindi' },
    { value: 'Arabic', label: 'Arabic' },
    { value: 'Mandarin', label: 'Mandarin' },
    { value: 'Turkish', label: 'Turkish' },
    { value: 'Vietnamese', label: 'Vietnamese' },
    { value: 'Indonesian', label: 'Indonesian' },
    { value: 'Polish', label: 'Polish' },
    { value: 'Danish', label: 'Danish' },
    { value: 'Norwegian', label: 'Norwegian' },
    { value: 'Swedish', label: 'Swedish' },
    { value: 'Finnish', label: 'Finnish' },
    { value: 'Greek', label: 'Greek' },
    { value: 'Hungarian', label: 'Hungarian' },
    { value: 'Croatian', label: 'Croatian' },
    { value: 'Serbian', label: 'Serbian' },
    { value: 'Slovak', label: 'Slovak' },
    { value: 'Bulgarian', label: 'Bulgarian' },
    { value: 'Romanian', label: 'Romanian' },
    { value: 'Czech', label: 'Czech' },
    { value: 'Persian', label: 'Persian' },
    { value: 'Bengali', label: 'Bengali' },
    { value: 'Hebrew', label: 'Hebrew' },
    { value: 'Irish', label: 'Irish' },
    { value: 'Korean', label: 'Korean' },
    { value: 'Malay', label: 'Malay' },
    { value: 'Maltese', label: 'Maltese' },

  ]

  var fromHidden = -90
  const [loginModal, setLoginModal] = useState(false)

  const videoStyle = {
    borderRadius: '10px',
    maxWidth: '95vw',
    width: '600px',
    aspectRatio: '16/9',
  }

  function topAlign(degrees) {
    return degrees - 45
  }

  function rotate(el, degrees) {
    var degrees = topAlign(degrees || 0)
    el.css(
      'transform',
      'rotate(' + degrees + 'deg)',
      '-webkit-transform',
      'rotate(' + degrees + 'deg)',
      '-moz-transform',
      'rotate(' + degrees + 'deg)',
      '-ms-transform',
      'rotate(' + degrees + 'deg)',
      '-o-transform',
      'rotate(' + degrees + 'deg)'
    )
  }

  function circle(el, normalisedValue) {
    var degrees = normalisedValue * 360
    var counter = 1
    el.find('.arc_q').each(function () {
      var angle = Math.min(counter * 90, degrees)
      rotate($(this), fromHidden + angle)
      counter++
    })
    if (degrees > 90) {
      el.find('.arc_cover').css('display', 'none')
    }
  }

  const OpportunityForm = (props) => {
    const { height, width } = useWindowDimensions()
    const [textEditorData, setTextEditorData] = useState('')

    //   const save = (data) => {
    //   console.log(data);
    // };

    const myTheme = createTheme({
      // Set up your custom MUI theme here
    })

    return (
      <div>
        <div
          style={{
            marginLeft: '2.5em',
            marginRight: '2.5em',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>
            <Typography
              style={{ fontSize: '19px', fontWeight: '600', margin: '0 5%' }}
            >
              Opportunity Preferences
            </Typography>

            <Typography
              className='perferencestyle'
              style={
                width < 420
                  ? { fontSize: '14px', color: '#6C6866', margin: '10px 5%' }
                  : { fontSize: '14px', color: '#6C6866', margin: '10px 5%' }
              }
            >
              I am open to receiving requests. This will show your contact
              button on your profile.
            </Typography>
          </div>
          <br />
          {/* <div style={{height: '150px'}}>
              <ThemeProvider theme={myTheme}>
                <MUIRichTextEditor
                  label="Type something here..."
                  onSave={setTextEditorData}
                  inlineToolbar={true}
              controls={ [
                    "title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo"
              ] }
                />
              </ThemeProvider>
            </div> */}
          <div className='optionCont'>
            <div className='option'>
              <input
                type='checkbox'
                name=''
                checked={opportunityPreference.writing}
                onChange={(e) =>
                  setopportunityPreference({
                    ...opportunityPreference,
                    writing: !opportunityPreference.writing,
                  })
                }
                id=''
              />
              <div>
                <div className='optionTitle'>Writing</div>
                <div className='optionSubtext'>
                  Contributing articles or blogs on certain topics
                </div>
              </div>
            </div>
            <div className='option'>
              <input
                type='checkbox'
                checked={opportunityPreference.speaking_on_clubhouse}
                onChange={(e) =>
                  setopportunityPreference({
                    ...opportunityPreference,
                    speaking_on_clubhouse:
                      !opportunityPreference.speaking_on_clubhouse,
                  })
                }
                name=''
                id=''
              />
              <div>
                <div className='optionTitle'>Speaking on ClubHouse</div>
                <div className='optionSubtext'>
                  Contributing articles or blogs on certain topics
                </div>
              </div>
            </div>
            <div className='option'>
              <input
                type='checkbox'
                checked={opportunityPreference.mentoring}
                onChange={(e) =>
                  setopportunityPreference({
                    ...opportunityPreference,
                    mentoring: !opportunityPreference.mentoring,
                  })
                }
                name=''
                id=''
              />
              <div>
                <div className='optionTitle'>Mentoring</div>
                <div className='optionSubtext'>
                  Contributing articles or blogs on certain topics
                </div>
              </div>
            </div>
            <div className='option'>
              <input
                type='checkbox'
                name=''
                id=''
                checked={opportunityPreference.content_creation}
                onChange={(e) =>
                  setopportunityPreference({
                    ...opportunityPreference,
                    content_creation: !opportunityPreference.content_creation,
                  })
                }
              />
              <div>
                <div className='optionTitle'>Content Creation</div>
                <div className='optionSubtext'>
                  Contributing articles or blogs on certain topics
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='btnProfileModalCont'>
          <button className='btnContCancelBtn' onClick={props.closeModal}>
            Cancel
          </button>
          <button
            className='btnContSaveBtn'
            onClick={handleOpportunityPreference}
          >
            Save
          </button>
        </div>
      </div>
    )
  }

  function animate(el, normalisedValue, current) {
    var current = current || 0
    circle(el, current)
    if (current < normalisedValue) {
      current += 0.01
      setTimeout(function () {
        animate(el, normalisedValue, current)
      }, 1)
    }
  }

  useEffect(() => {
    setmissingDict({
      profile_pic: () =>
        document.getElementById('profile_pic_upload_button').click(),
      video_resume: () => document.getElementById('upload_video').click(),

      attachments: () => history.push('setting/attachments'),
      job_title: () => history.push('setting/jobPrefernces'),
      location: () => history.push('setting/jobPrefernces'),
      min_sal: () => history.push('setting/jobPrefernces'),

      education: () => setEducationForm(true),
      work_exp: () => setWorkExperienceForm(true),
      certification: () => setCertificateForm(true),
      skills: () => setSkillsForm(true),
      about_me: () => setaboutMeForm(true),
      languages: () => setLanguageForm(true),

      first_name: () => history.push('/setting/account'),
      last_name: () => history.push('/setting/account'),
      email: () => history.push('/setting/account'),
      address: () => history.push('/setting/account'),
      phone_no: () => history.push('/setting/account'),

      seeker_type: null,
    })
  }, [])

  const [workExperienceForm, setWorkExperienceForm] = useState(false)
  const [certificateForm, setCertificateForm] = useState(false)
  const [educationForm, setEducationForm] = useState(false)
  const [languageForm, setLanguageForm] = useState(false)
  const [skillsForm, setSkillsForm] = useState(false)
  const [opportunityForm, setOpportunityForm] = useState(false)
  const [aboutMeForm, setaboutMeForm] = useState(false)
  const [socialLinkForm, setsocialLinkForm] = useState(false)
  const [pointPercentage, setpointPercentage] = useState(0)
  const [missingPoints, setmissingPoints] = useState([])

  const [accessToken, setAccessToken] = useState('')
  const [profile, setprofile] = useState({
    name:
      localStorage.getItem('name') === '' ||
        localStorage.getItem('name') === 'null'
        ? ''
        : localStorage.getItem('name'),
    email:
      localStorage.getItem('email') === '' ||
        localStorage.getItem('email') === 'null'
        ? ''
        : localStorage.getItem('email'),
    phone:
      localStorage.getItem('phone') === '' ||
        localStorage.getItem('phone') === 'null'
        ? ''
        : localStorage.getItem('phone'),
    about: '',
    city: '',
    state: '',
    profile_pic:
      localStorage.getItem('profile_pic') === '' ||
        localStorage.getItem('profile_pic') === 'null'
        ? ''
        : localStorage.getItem('profile_pic'),
  })
  const [videoResumeURL, setvideoResumeURL] = useState(null)
  const [workExperience, setWorkExperience] = useState([])
  const [education, seteducation] = useState([])
  const [certification, setcertification] = useState([])
  const [language, setlanguage] = useState([])
  const [skillSet, setskill] = useState([])

  const [loader, setloader] = useState(false);


  const [workExpEdit, setworkExpEdit] = useState({
    isEdit: false,
    id: '',
    position: '',
    school_district: '',
    is_working: '',
    start_date: '',
    end_date: '',
    description: '',
  })
  const [educationEdit, seteducationEdit] = useState({
    isEdit: false,
    id: false,
    degree: '',
    school_district: '',
    is_working: '',
    start_date: '',
    end_date: '',
    description: '',
  })
  const [certificationEdit, setcertificationEdit] = useState({
    isEdit: false,
    id: '',
    name: '',
    authority: '',
    does_expire: '',
    start_date: '',
    end_date: '',
    is_working: '',
    description: '',
  })
  const [skillEdit, setskillEdit] = useState({
    isEdit: false,
    id: '',
    title: '',
  })
  const [languageEdit, setlanguageEdit] = useState({
    isEdit: false,
    id: '',
    name: '',
    rating: '',
  })

  // for cropping photo
  const [srcImage, setsrcImage] = useState(null)

  //save the image that used to be crop
  const [image, setImage] = useState(null)
  //change the aspect ratio of crop tool as you preferred
  const [crop, setCrop] = useState({ aspect: 1 / 1 })
  //save the resulted image

  const history = useHistory()

  const [missingDict, setmissingDict] = useState()

  const getData = () => {
    fetching_profile()
    fetching_video_resume()
    fetching_profile_point()
    fetching_opprtunity_preferences()
    fetching_social_link()
  }

  useEffect(() => {
    if (Cookies.get('access_token')) {
      setAccessToken(Cookies.get('access_token'))
      getData()
    } else {
      window.location.href = '/login'
    }
  }, [accessToken])

  // fetching profile
  const fetching_profile = async () => {
    setloader(true);
    axiosInstance
      .get('/seeker/profile')
      .then((res) => {
        // console.log(res.data);
        setloader(false);
        const data = res.data
        if (data.name != '') {
          setprofile({ ...profile, ['name']: data.name })
        }
        setprofile({
          ...profile,
          ['city']: data.city,
          ['state']: data.state,
          ['about']: data.about,
        })
        setWorkExperience(data.work_exps)
        seteducation(data.educations)
        setcertification(data.certificates)
        setlanguage(data.languages)
        setskill(data.skills)
      })
      .catch((err) => {
        setloader(false);
        console.log(err)
      })
  }

  // fetching profile point
  const fetching_profile_point = async () => {
    setloader(true);
    axiosInstance
      .get('/seeker/profile_point')
      .then((res) => {
        setloader(false);
        if (!res.data.msg.percentage) {
          axiosInstance
            .post('/seeker/profile_point')
            .then(() => {
              fetching_profile_point()
            })
            .catch((err) => console.log(err))
        } else {
          console.log(res.data.msg.percentage)
          setpointPercentage(res.data.msg.percentage)
          let missingList = []
          for (var i in res.data.msg.missing_list) {
            // console.log(i);
            missingList.push({
              value: res.data.msg.missing_list[i].value,
              label: i,
              desc: res.data.msg.missing_list[i].label,
            })
          }
          setmissingPoints(missingList)
          animate($('.circle'), pointPercentage)
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err)
      })
  }

  const [socialLink, setsocialLink] = useState({
    linkedin: null,
    twitter: null,
    facebook: null,
    calendly: null
  })

  // fetching Social Links
  const fetching_social_link = async () => {
    setloader(true);
    axiosInstance('/seeker/social_link/').then((res) => {
      setloader(false);
      // console.log(res.data.data);
      setsocialLink(res.data.data)
    }).catch(err => {
      setloader(false);
      console.log(err)
    })
  }

  const [opportunityPreference, setopportunityPreference] = useState({
    writing: false,
    speaking_on_clubhouse: false,
    mentoring: false,
    content_creation: false,
  })
  // fetching opportunity preferences
  const fetching_opprtunity_preferences = async () => {
    setloader(true);
    axiosInstance
      .get('/seeker/opportunity_preference/')
      .then((res) => {
        setloader(false);
        // console.log(res.data.data);
        setopportunityPreference(res.data.data)
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      })
  }

  const handleOpportunityPreference = () => {
    console.log(opportunityPreference)
    const data = {
      // access: accessToken,
      opportunity_preference: opportunityPreference,
    }
    console.log(data)
    axiosInstance
      .post('/seeker/opportunity_preference/', data)
      .then((res) => {
        // console.log(res);
        fetching_opprtunity_preferences()
        setOpportunityForm(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // fetching video resume
  const fetching_video_resume = async () => {
    setloader(true);
    if (!accessToken || accessToken.length === 0) return
    axiosInstance
      .get('/seeker/record_video')
      .then((res) => {
        setloader(false);
        // console.log(res.data.msg.video_url);
        if (res.data.msg.video_url) {
          setvideoResumeURL(res.data.msg.video_url)
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err)
      })
  }

  const checkNullValue = (value) => {
    if (value === '' || value === null || value === undefined) return true
    return false
  }

  // Work Experience

  const WorkExperienceForm = (props) => {
    const [inputFieldsWork, setInputFieldsWork] = useState([
      {
        id: uuidv4(),
        position: '',
        school: '',
        startDate: '',
        endDate: '',
        desc: '',
        isWorking: false,
      },
    ])
    const [activeStep_2, setactiveStep_2] = useState({
      school: false,
      position: false,
      desc: false,
      startDate: false,
    })
    const [endDateVis, setendDateVis] = useState(false)
    const handleSubmitWork = (e) => {
      setloader(true)
      e.preventDefault()
      inputFieldsWork.forEach((e) => {
        if (
          (e.school !== '' &&
            e.position !== '' &&
            e.desc !== '' &&
            e.startDate !== '') ||
          (e.school === '' &&
            e.position === '' &&
            e.desc === '' &&
            e.startDate === '')
        ) {
          setactiveStep_2({
            school: checkNullValue(e.school) ? true : false,
            position: checkNullValue(e.position) ? true : false,
            desc: checkNullValue(e.desc) ? true : false,
            startDate: checkNullValue(e.startDate) ? true : false,
          })
        } else {
          setactiveStep_2({
            school: e.school?.length === 0 ? true : false,
            degree: e.degree?.length === 0 ? true : false,
            desc: e.desc?.length === 0 ? true : false,
            startDate: e.startDate?.length === 0 ? true : false,
          })
          // console.log(e)
          // alert('Please enter the required Information.')
          return
        }
      })
      let work_exps = []
      inputFieldsWork.map((item) => {
        const exp = {
          school_district: item.school.value,
          position: item.position.value,
          start_date: item.startDate,
          end_date: item.endDate,
          description: item.desc,
          is_working: item.isWorking,
        }
        work_exps.push(exp)
      })

      const data = {
        // access: accessToken,
        data: work_exps,
      }

      console.log(data)

      !workExpEdit.isEdit
        ? axiosInstance
          .post('/seeker/work_experience/', {
            // access: accessToken,
            data: work_exps,
          })
          .then((res) => {
            axiosInstance
              .get('/seeker/work_experience/')
              .then((res) => {
                setWorkExperience(res.data.data)
                document.getElementById('work_exp_close_btn').click()
                fetching_profile_point()
              })
              .catch((err) => console.log(err))
          })
          .catch((err) => {
            console.log(err)
            setAlert(true)
            setType('error')
            setDesc(err.toString())
          })
        : axiosInstance
          .patch('/seeker/work_experience', {
            // access: accessToken,
            id: inputFieldsWork[0].id,
            school_district: parseInt(inputFieldsWork[0].school.value),
            position: inputFieldsWork[0].position.value,
            start_date: inputFieldsWork[0].startDate,
            end_date: inputFieldsWork[0].endDate,
            description: inputFieldsWork[0].desc,
            is_working: inputFieldsWork[0].isWorking,
          })
          .then((res) => {
            axiosInstance
              .get('/seeker/work_experience/')
              .then((res) => {
                setWorkExperience(res.data.data)
                fetching_profile_point()
                setAlert(true)
                setType('success')
                setDesc('Successfully Updated')
                document.getElementById('work_exp_close_btn').click()
              })
              .catch((err) => console.log(err))
          })
          .catch((err) => {
            console.log(err)
            setAlert(true)
            setType('warning')
            setDesc('Please Recheck Form')
          })

      console.log('InputFields', inputFieldsWork)
    }

    const handleChangeInputWork = (id, event) => {
      if (
        event.target.name === 'startDate' ||
        event.target.name === 'endDate'
      ) {
        if (event.target.value.split('-')[0].length > 4) {
          return
        }

        // years condition
        if ((event.target.value.split('-')[0] >= 1000) && (event.target.value.split('-')[0] < 1930))
          return;

        if ((event.target.value.split('-')[0] > 2050) && (event.target.value.split('-')[0] < 9999))
          return;

      }
      const newInputFields = inputFieldsWork.map((i) => {
        if (id === i.id) {
          i[event.target.name] = event.target.value
        }
        return i
      })

      setInputFieldsWork(newInputFields)
    }
    const handleChangeInputWorkCheckBox = (id, event) => {
      const newInputFields = inputFieldsWork.map((i) => {
        if (id === i.id) {
          i[event.target.name] = event.target.checked
        }
        return i
      })

      setInputFieldsWork(newInputFields)
    }

    const handleAddFieldsWork = () => {

      setInputFieldsWork([
        ...inputFieldsWork,
        {
          id: uuidv4(),
          position: '',
          school: '',
          startDate: '',
          endDate: '',
          desc: '',
          isWorking: false,
        },
      ])
      // setTimeout(function () {
      //   window.scrollTo(0, document.scrollHeight)
      // }, 50)
    }

    const handleRemoveFieldsWork = (id) => {
      const values = [...inputFieldsWork]
      values.splice(
        values.findIndex((value) => value.id === id),
        1
      )
      setInputFieldsWork(values)
    }

    const handleChangeSchoolWork = (id, selectedDist, name) => {
      console.log(selectedDist)
      const newInputFields = inputFieldsWork.map((i) => {
        if (id === i.id) {
          i[name] = selectedDist
        }
        return i
      })

      setInputFieldsWork(newInputFields)
    }

    useEffect(() => {
      if (workExpEdit.isEdit) {
        setInputFieldsWork([
          {
            id: workExpEdit.id,
            position: {
              value: workExpEdit.position,
              label: workExpEdit.position,
            },
            desc: workExpEdit.description,
            startDate: workExpEdit.start_date,
            endDate: workExpEdit.end_date,
            school: {
              value: workExpEdit.school_district,
              label: workExpEdit.school_district,
            },
            isWorking: workExpEdit.is_working,
          },
        ])
        if (workExpEdit.is_working) {
          setendDateVis(true)
        }
      } else {
        setendDateVis(false)
      }
    }, [])

    const [loader, setloader] = useState(false);

    // school disrict search
    const [schoolList, setschoolList] = useState([]);
    const [searchSchoolText, setsearchSchoolText] = useState('');

    useEffect(() => {
      getSchoolData();
    }, [searchSchoolText])

    const getSchoolData = () => {
      axios.get('https://framechange--backend.herokuapp.com/seeker/get_school_by_search/', {
        params: {
          search: searchSchoolText
        }
      }).then(res => {
        setschoolList(res.data.msg);
      }).catch(err => {
        console.log(err);
      })
    }

    const [characterLimit, setcharacterLimit] = useState(false);

    const checkCharacterLimit = (text) => {
      if (text.length > 224) {
        setcharacterLimit(true);
        return;
      }
      setcharacterLimit(false);
      return;
    }

    return (
      <div>
        <div
          style={{
            marginRight: '2.5em',
            marginLeft: '2.5em',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography style={{ fontSize: '19px', fontWeight: '600' }}>
            Work Experience
          </Typography>
          <br />
          <form>
            {inputFieldsWork.map((inputField, idx) => (
              <Grid key={inputField.id} container item spacing={3}>
                <Grid item lg={6} xs={10}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='label'>Position</span>
                    <Select
                      className={activeStep_2.school ? 'incorrectInput' : null}
                      value={inputField.position}
                      name='position'
                      onChange={(selectedDist) =>
                        handleChangeSchoolWork(
                          inputField.id,
                          selectedDist,
                          'position'
                        )
                      }
                      options={jobTitlesArr}
                    />
                  </div>
                </Grid>
                <Grid item lg={6} xs={10}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='label'>School District</span>
                    <Select
                      className={activeStep_2.school ? 'incorrectInput' : null}
                      name='school'
                      value={inputField.school}
                      onChange={(selectedDist) =>
                        handleChangeSchoolWork(
                          inputField.id,
                          selectedDist,
                          'school'
                        )
                      }
                      isClearable={true}
                      onKeyDown={(e) => { setsearchSchoolText(e.target.value) }}
                      options={schoolList}
                    />
                  </div>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Checkbox
                      style={{ padding: '0', color: '#0DAC69' }}
                      name='isWorking'
                      checked={inputField.isWorking}
                      onChange={(event) => {
                        handleChangeInputWorkCheckBox(inputField.id, event)
                        setendDateVis(inputField.isWorking)
                      }}
                    />
                    <span
                      className='label'
                      style={{ color: '#0F172A', marginLeft: '10px' }}
                    >
                      Currently working
                    </span>
                  </div>
                </Grid>

                <Grid item lg={6}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='label'>Start date</span>
                    <TextField
                      name='startDate'
                      value={inputField.startDate}
                      error={activeStep_2.startDate}
                      onChange={(event) =>
                        handleChangeInputWork(inputField.id, event)
                      }
                      InputProps={{
                        inputProps: {
                          min: "1930-01-01",
                          max: "2050-01-01"
                        },
                      }}
                      size='small'
                      type='date'
                      placeholder='Start date'
                      variant='outlined'
                    />
                  </div>
                </Grid>
                {!endDateVis ? (
                  <Grid item lg={6} id='workExp_EndDate'>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span className='label'>End Date</span>
                      <TextField
                        name='endDate'
                        value={inputField.endDate}
                        InputProps={{
                          inputProps: {
                            min: inputField.startDate
                              ? inputField.startDate
                              : null,
                            max: "2050-01-01"
                          },
                        }}
                        onChange={(event) =>
                          handleChangeInputWork(inputField.id, event)
                        }
                        size='small'
                        type='date'
                        placeholder='End date'
                        variant='outlined'
                      />
                    </div>
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item lg={12}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='label'>Description <small><i>(Character Limit 225)</i></small> </span>
                    <TextField
                      name='desc'
                      value={inputField.desc}
                      error={activeStep_2.desc}
                      onChange={(event) => {
                        handleChangeInputWork(inputField.id, event);
                        checkCharacterLimit(inputField.desc);
                      }
                      }
                      multiline={true}
                      rows={4}
                      size='small'
                      variant='outlined'
                      inputProps={{ maxLength: 225 }}
                    />
                    {
                      characterLimit ? <>
                        <small style={{ color: 'red' }}>You have typed more than 225 characters</small>
                      </ > : null
                    }
                  </div>
                </Grid>
                <Grid
                  container
                  justifyContent='space-between'
                  item
                  lg={12}
                  md={11}
                  sm={11}
                  xs={11}
                >
                  {!workExpEdit.isEdit ? (
                    <Grid container justifyContent={inputFieldsWork.length == idx + 1 ? 'space-between' : 'flex-end'} item lg={12} md={11} sm={11} xs={11}>

                      {inputFieldsWork.length == idx + 1 ? <Button variant='contained' style={{ background: "#fff", borderRadius: '18px', fontWeight: '600', textTransform: 'none', border: '2px solid #0881f4', color: '#0881f4' }} onClick={handleAddFieldsWork}>Add Another</Button> : <></>}
                      <Button
                        variant='contained'
                        style={{
                          background: '#fff',
                          borderRadius: '18px',
                          fontWeight: '600',
                          textTransform: 'none',
                          color: '#909090',
                        }}
                        disabled={inputFieldsWork.length === 1}
                        onClick={() => handleRemoveFieldsWork(inputField.id)}
                      >
                        <RiDeleteBin6Line style={{ height: '2em' }} />&nbsp;
                        Remove
                      </Button>

                    </Grid>

                  ) : (
                    <></>
                  )}
                  <hr style={{ width: '100%' }}></hr>

                </Grid>
              </Grid>
            ))}
          </form>
        </div>
        <div className='btnProfileModalCont'>
          <button
            className='btnContCancelBtn'
            id='work_exp_close_btn'
            onClick={props.closeModal}
          >
            Cancel
          </button>
          {loader ? (
            <CircularProgress
              style={{ color: 'green', justifyContent: 'center' }}
            />
          ) : (
            <button className='btnContSaveBtn' onClick={handleSubmitWork}>
              Save
            </button>
          )}
        </div>
      </div>
    )
  }

  const deleteFunc = (e) => {
    // console.log(e.target)
    let target = e.target.id.split('-')
    const id = target.at(-1)
    target = target[0]
    // console.log(target, id);

    axiosInstance
      .delete(`/seeker/${target}/`, {
        params: {
          // access: accessToken,
          id: parseInt(id),
        },
      })
      .then((res) => {
        axiosInstance
          .get(`/seeker/${target}/`)
          .then((res) => {
            setAlert(true)
            setType('success')
            setDesc('Successfully Deleted')
            getData()
          })
          .catch((err) => {
            setAlert(true)
            setType('error')
            setDesc(err.toString())
          })
      })
      .catch((err) => {
        setAlert(true)
        setType('error')
        setDesc(err.toString())
      })
  }

  // Education

  const EducationForm = (props) => {
    const [inputFieldsEducation, setInputFieldsEducation] = useState([
      {
        id: uuidv4(),
        degree: '',
        school: '',
        startDate: '',
        endDate: '',
        desc: '',
      },
    ])
    const [activeStep_1, setactiveStep_1] = useState({
      school: false,
      degree: false,
      desc: false,
      startDate: false,
    })

    const [loader, setloader] = useState(false)

    const handleSubmitEducation = (e) => {
      setloader(true)
      e.preventDefault()
      inputFieldsEducation.forEach((e) => {
        if (
          (e.school !== '' &&
            e.degree !== '' &&
            e.desc !== '' &&
            e.startDate !== '') ||
          (e.school === '' &&
            e.degree === '' &&
            e.desc === '' &&
            e.startDate === '')
        ) {
          setactiveStep_1({
            school: checkNullValue(e.school) ? true : false,
            degree: checkNullValue(e.degree) ? true : false,
            desc: checkNullValue(e.desc) ? true : false,
            startDate: checkNullValue(e.startDate) ? true : false,
          })
        } else {
          setactiveStep_1({
            school: e.school?.length === 0 ? true : false,
            degree: e.degree?.length === 0 ? true : false,
            desc: e.desc?.length === 0 ? true : false,
            startDate: e.startDate?.length === 0 ? true : false,
          })
          // console.log(e)
          // alert('Please enter the required Information.')

          return
        }
      })
      // console.log("InputFields", inputFieldsEducation);
      let education_list = []
      inputFieldsEducation.map((item) => {
        const edu = {
          degree: item.degree,
          school_district: item.school.value,
          start_date: item.startDate,
          end_date: item.endDate,
          description: item.desc,
          is_working: false,
        }
        education_list.push(edu)
      })

      const data = {
        // access: accessToken,
        data: education_list,
      }
      !educationEdit.isEdit
        ? axiosInstance
          .post('/seeker/education/', data)
          .then((res) => {
            axiosInstance
              .get('/seeker/education/')
              .then((res) => {
                seteducation(res.data.data)
                fetching_profile_point()
                document.getElementById('education_close_modal').click()
              })
              .catch((err) => console.log(err))
          })
          .catch((err) => {
            console.log(err.error)
            setAlert(true)
            setType('warning')
            setDesc('Please Recheck Form')
          })
        : axiosInstance
          .patch('/seeker/education/', {
            // access: accessToken,
            id: inputFieldsEducation[0].id,
            school_district: inputFieldsEducation[0].school.value,
            degree: inputFieldsEducation[0].degree,
            start_date: inputFieldsEducation[0].startDate,
            end_date: inputFieldsEducation[0].endDate,
            description: inputFieldsEducation[0].desc,
            is_working: false,
          })
          .then((res) => {
            axiosInstance
              .get('/seeker/education/')
              .then((res) => {
                setAlert(true)
                setType('success')
                setDesc('Updated Successfully')
                seteducation(res.data.data)
                document.getElementById('education_close_modal').click()
                fetching_profile_point()
              })
              .catch((err) => console.log(err))
          })
          .catch((err) => {
            console.log(err.error)
            setAlert(true)
            setType('warning')
            setDesc('Please Recheck Form')
          })
    }

    const handleChangeInputEducation = (id, event) => {
      if (
        event.target.name === 'startDate' ||
        event.target.name === 'endDate'
      ) {
        if (event.target.value.split('-')[0].length > 4) {
          return
        }

        // years condition
        if ((event.target.value.split('-')[0] >= 1000) && (event.target.value.split('-')[0] < 1930))
          return;

        if ((event.target.value.split('-')[0] > 2050) && (event.target.value.split('-')[0] < 9999))
          return;
      }

      const newInputFields = inputFieldsEducation.map((i) => {
        if (id === i.id) {
          i[event.target.name] = event.target.value
        }
        return i
      })

      setInputFieldsEducation(newInputFields)
    }

    const handleAddFieldsEducation = () => {
      setInputFieldsEducation([
        ...inputFieldsEducation,
        {
          id: uuidv4(),
          degree: '',
          school: '',
          startDate: '',
          endDate: '',
          desc: '',
        },
      ])
    }

    const handleRemoveFieldsEducation = (id) => {
      const values = [...inputFieldsEducation]
      values.splice(
        values.findIndex((value) => value.id === id),
        1
      )
      setInputFieldsEducation(values)
    }

    const handleChangeSchoolWork = (id, selectedDist) => {
      const newInputFields = inputFieldsEducation.map((i) => {
        if (id === i.id) {
          i['school'] = selectedDist
        }
        return i
      })

      setInputFieldsEducation(newInputFields)
    }

    useEffect(() => {
      if (educationEdit.isEdit) {
        setInputFieldsEducation([
          {
            id: educationEdit.id,
            degree: educationEdit.degree,
            desc: educationEdit.description,
            startDate: educationEdit.start_date,
            endDate: educationEdit.end_date,
            school: {
              value: educationEdit.school_district,
              label: educationEdit.school_district,
            },
          },
        ])
      }
    }, [])

    // school disrict search
    const [schoolList, setschoolList] = useState([]);
    const [searchSchoolText, setsearchSchoolText] = useState('');

    useEffect(() => {
      getSchoolData();
    }, [searchSchoolText])

    const getSchoolData = () => {
      axios.get('https://framechange--backend.herokuapp.com/seeker/get_school_by_search/', {
        params: {
          search: searchSchoolText
        }
      }).then(res => {
        setschoolList(res.data.msg);
      }).catch(err => {
        console.log(err);
      })
    }

    const [characterLimit, setcharacterLimit] = useState(false);

    const checkCharacterLimit = (text) => {
      if (text.length > 224) {
        setcharacterLimit(true);
        return;
      }
      setcharacterLimit(false);
      return;
    }


    return (
      <div>
        <div
          style={{
            marginRight: '2.5em',
            marginLeft: '2.5em',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography style={{ fontSize: '19px', fontWeight: '600' }}>
            Education
          </Typography>
          <br />

          <form onSubmit={handleSubmitEducation}>
            {inputFieldsEducation.map((inputField, idx) => (
              <Grid key={inputField.id} container item spacing={3}>
                <Grid item lg={6} xs={10}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='label'>Degree/Speciality</span>
                    <TextField
                      size='small'
                      value={inputField.degree}
                      name='degree'
                      placeholder='Ex: Bachelors'
                      variant='outlined'
                      error={activeStep_1.degree}
                      onChange={(event) =>
                        handleChangeInputEducation(inputField.id, event)
                      }
                    />
                  </div>
                </Grid>

                <Grid item lg={6} xs={10}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='label'>School District</span>
                    <Select
                      value={inputField.school}
                      className={activeStep_1.school ? 'incorrectInput' : null}
                      onChange={(selectedDist) =>
                        handleChangeSchoolWork(inputField.id, selectedDist)
                      }
                      isClearable={true}
                      onKeyDown={(e) => { setsearchSchoolText(e.target.value) }}
                      // onKeyDown = {e=>console.log(e)}
                      options={schoolList}
                    />
                  </div>
                </Grid>

                <Grid item lg={6}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='label'>Start date</span>
                    <TextField
                      size='small'
                      value={inputField.startDate}
                      name='startDate'
                      type='date'
                      error={activeStep_1.startDate}
                      placeholder='Start date'
                      InputProps={{
                        inputProps: {
                          min: "1930-01-01",
                          max: "2050-01-01"
                        },
                      }}
                      variant='outlined'
                      onChange={(event) =>
                        handleChangeInputEducation(inputField.id, event)
                      }
                    />
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='label'>End Date</span>
                    <TextField
                      size='small'
                      name='endDate'
                      InputProps={{
                        inputProps: {
                          min: inputField.startDate
                            ? inputField.startDate
                            : null,
                          max: "2050-01-01"
                        },
                      }}
                      value={inputField.endDate}
                      type='date'
                      placeholder='End date'
                      variant='outlined'
                      onChange={(event) =>
                        handleChangeInputEducation(inputField.id, event)
                      }
                    />
                  </div>
                </Grid>
                <Grid item lg={12} md={11} sm={11} xs={11}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='label'>Description <small><i>(Character Limit 225)</i></small> </span>
                    <TextField
                      name='desc'
                      value={inputField.desc}
                      error={activeStep_1.desc}
                      multiline={true}
                      rows={4}
                      size='small'
                      variant='outlined'
                      inputProps={{ maxLength: 225 }}
                      onChange={(event) => {
                        handleChangeInputEducation(inputField.id, event);
                        checkCharacterLimit(inputField.desc);
                      }
                      }
                    />
                    {
                      characterLimit ? <>
                        <small style={{ color: 'red' }}>You have typed more than 225 characters</small>
                      </ > : null
                    }
                  </div>
                </Grid>
                {educationEdit.isEdit ? null : (
                  <Grid
                    container
                    justifyContent={
                      inputFieldsEducation.length == idx + 1
                        ? 'space-between'
                        : 'flex-end'
                    }
                    item
                    lg={12}
                    md={11}
                    sm={11}
                    xs={11}
                  >
                    {inputFieldsEducation.length === idx + 1 ? (
                      <Button
                        variant='contained'
                        style={{
                          background: '#fff',
                          borderRadius: '18px',
                          fontWeight: '600',
                          textTransform: 'none',
                          border: '2px solid #0881f4',
                          color: '#0881f4',
                        }}
                        onClick={handleAddFieldsEducation}
                      >
                        Add Another
                      </Button>
                    ) : null}
                    <Button
                      variant='contained'
                      style={{
                        background: '#fff',
                        borderRadius: '18px',
                        fontWeight: '600',
                        textTransform: 'none',
                        color: '#909090',
                      }}
                      disabled={inputFieldsEducation.length === 1}
                      onClick={() => handleRemoveFieldsEducation(inputField.id)}
                    >
                      <RiDeleteBin6Line style={{ height: '2em' }} />&nbsp;
                      Remove
                    </Button>
                  </Grid>

                )}
                <hr style={{ width: '100%' }}></hr>
                <br />
              </Grid>
            ))}
          </form>
        </div>
        <div className='btnProfileModalCont'>
          <button
            className='btnContCancelBtn'
            id='education_close_modal'
            onClick={props.closeModal}
          >
            Cancel
          </button>
          {loader ? (
            <CircularProgress
              style={{ color: 'green', justifyContent: 'center' }}
            />
          ) : (
            <button className='btnContSaveBtn' onClick={handleSubmitEducation}>
              Save
            </button>
          )}
        </div>
      </div>
    )
  }

  // Certificate

  const CertficateForm = (props) => {
    const [inputFieldsCertificate, setInputFieldsCertificate] = useState([
      {
        id: uuidv4(),
        certificateName: '',
        certificateAuthority: '',
        startDate: '',
        endDate: '',
        desc: '',
        isNotExpired: false,
      },
    ])
    const [activeStep_3, setactiveStep_3] = useState({
      certificateName: false,
      certificateAuthority: false,
      desc: false,
      startDate: false,
    })
    const [loader, setloader] = useState(false)
    const handleSubmitCertificate = (e) => {
      setloader(true)
      e.preventDefault()
      inputFieldsCertificate.forEach((e) => {
        if (
          (e.certificateName !== '' &&
            e.certificateAuthority !== '' &&
            e.desc !== '' &&
            e.startDate !== '') ||
          (e.certificateName === '' &&
            e.certificateAuthority === '' &&
            e.desc === '' &&
            e.startDate === '')
        ) {
          setactiveStep_3({
            certificateName: checkNullValue(e.certificateName) ? true : false,
            certificateAuthority: checkNullValue(e.certificateAuthority)
              ? true
              : false,
            desc: checkNullValue(e.desc) ? true : false,
            startDate: checkNullValue(e.startDate) ? true : false,
          })
        } else {
          setactiveStep_3({
            certificateName: e.certificateName?.length === 0 ? true : false,
            certificateAuthority:
              e.certificateAuthority?.length === 0 ? true : false,
            desc: e.desc?.length === 0 ? true : false,
            startDate: e.startDate?.length === 0 ? true : false,
          })
          // console.log(e)
          // alert('Please enter the required Information.')
          return
        }
      })
      // console.log("InputFields", inputFieldsCertificate);
      let cert_list = []
      inputFieldsCertificate.map((item) => {
        const cert = {
          name: item.certificateName,
          authority: item.certificateAuthority,
          start_date: item.startDate,
          end_date: item.endDate,
          description: item.desc,
          does_expire: item.isNotExpired,
          is_working: false,
        }
        cert_list.push(cert)
      })

      const data = {
        // access: accessToken,
        data: cert_list,
      }

      !certificationEdit.isEdit
        ? axiosInstance
          .post('/seeker/certification/', data)
          .then((res) => {
            axiosInstance
              .get('/seeker/certification/')
              .then((res) => {
                setcertification(res.data.data)
                document.getElementById('certificate_close_modal').click()
                fetching_profile_point()
              })
              .catch((err) => console.log(err))
          })
          .catch((err) => {
            console.log(err)
            setAlert(true)
            setType('error')
            setDesc(err.toString())
          })
        : axiosInstance
          .patch('/seeker/certification/', {
            // access: accessToken,
            id: inputFieldsCertificate[0].id,
            authority: inputFieldsCertificate[0].certificateAuthority,
            name: inputFieldsCertificate[0].certificateName,
            start_date: inputFieldsCertificate[0].startDate,
            end_date: inputFieldsCertificate[0].endDate,
            description: inputFieldsCertificate[0].desc,
            does_expire: inputFieldsCertificate[0].isNotExpired,
            is_working: false,
          })
          .then((res) => {
            axiosInstance
              .get('/seeker/certification/')
              .then((res) => {
                fetching_profile_point()
                setAlert(true)
                setType('success')
                setDesc('Updated Successfully')
                setcertification(res.data.data)
                document.getElementById('certificate_close_modal').click()
              })
              .catch((err) => console.log(err))
          })
          .catch((err) => {
            console.log(err)
            setAlert(true)
            setType('error')
            setDesc(err.toString())
          })
    }

    const handleChangeInputCertificate = (id, event) => {
      if (
        event.target.name === 'startDate' ||
        event.target.name === 'endDate'
      ) {
        if (event.target.value.split('-')[0].length > 4) {
          return
        }

        // years condition
        if ((event.target.value.split('-')[0] >= 1000) && (event.target.value.split('-')[0] < 1930))
          return;

        if ((event.target.value.split('-')[0] > 2050) && (event.target.value.split('-')[0] < 9999))
          return;
      }
      const newInputFields = inputFieldsCertificate.map((i) => {
        if (id === i.id) {
          i[event.target.name] = event.target.value
        }
        return i
      })

      setInputFieldsCertificate(newInputFields)
    }
    const handleChangeInputCertificateCheckBox = (id, event) => {
      const newInputFields = inputFieldsCertificate.map((i) => {
        if (id === i.id) {
          i[event.target.name] = event.target.checked
        }
        return i
      })

      setInputFieldsCertificate(newInputFields)
    }

    const handleAddFieldsCertificate = () => {
      setInputFieldsCertificate([
        ...inputFieldsCertificate,
        {
          id: uuidv4(),
          certificateName: '',
          certificateAuthority: '',
          startDate: '',
          endDate: '',
          desc: '',
          isNotExpired: false,
        },
      ])
    }

    const handleRemoveFieldsCertificate = (id) => {
      const values = [...inputFieldsCertificate]
      values.splice(
        values.findIndex((value) => value.id === id),
        1
      )
      setInputFieldsCertificate(values)
    }

    useEffect(() => {
      if (certificationEdit.isEdit) {
        setInputFieldsCertificate([
          {
            id: certificationEdit.id,
            certificateName: certificationEdit.name,
            desc: certificationEdit.description,
            startDate: certificationEdit.start_date,
            endDate: certificationEdit.end_date,
            certificateAuthority: certificationEdit.authority,
            isNotExpired: certificationEdit.does_expire,
          },
        ])
      }
    }, [])

    const [characterLimit, setcharacterLimit] = useState(false);

    const checkCharacterLimit = (text) => {
      if (text.length > 224) {
        setcharacterLimit(true);
        return;
      }
      setcharacterLimit(false);
      return;
    }

    return (
      <div>
        <div
          style={{
            marginRight: '2.5em',
            marginLeft: '2.5em',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography style={{ fontSize: '19px', fontWeight: '600' }}>
            Certificate
          </Typography>
          <br />
          <form onSubmit={handleSubmitCertificate}>
            {inputFieldsCertificate.map((inputField, idx) => (
              <Grid container key={inputField.id} item spacing={3}>
                <Grid item lg={6}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='label'>Certificate Name</span>
                    <TextField
                      size='small'
                      name='certificateName'
                      error={activeStep_3.certificateName}
                      value={inputField.certificateName}
                      onChange={(event) =>
                        handleChangeInputCertificate(inputField.id, event)
                      }
                      placeholder='Certificate Name'
                      variant='outlined'
                    />
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='label'>Certificate Authority</span>
                    <TextField
                      name='certificateAuthority'
                      value={inputField.certificateAuthority}
                      error={activeStep_3.certificateAuthority}
                      onChange={(event) =>
                        handleChangeInputCertificate(inputField.id, event)
                      }
                      size='small'
                      placeholder='Certificate Authority'
                      variant='outlined'
                    />
                  </div>
                </Grid>

                <Grid item lg={6}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='label'>Start date</span>
                    <TextField
                      name='startDate'
                      value={inputField.startDate}
                      error={activeStep_3.startDate}
                      onChange={(event) =>
                        handleChangeInputCertificate(inputField.id, event)
                      }
                      size='small'
                      InputProps={{
                        inputProps: {
                          min: "1930-01-01",
                          max: "2050-01-01"
                        },
                      }}
                      type='date'
                      placeholder='Start date'
                      variant='outlined'
                    />
                  </div>
                </Grid>
                <Grid item lg={6}>
                  {!inputField.isNotExpired ? (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className='label'>End Date</span>
                        <TextField
                          name='endDate'
                          InputProps={{
                            inputProps: {
                              min: inputField.startDate
                                ? inputField.startDate
                                : null,
                              max: "2050-01-01"
                            },
                          }}
                          value={inputField.endDate}
                          onChange={(event) =>
                            handleChangeInputCertificate(inputField.id, event)
                          }
                          size='small'
                          type='date'
                          placeholder='End date'
                          variant='outlined'
                        />
                      </div>
                    </>
                  ) : null}
                </Grid>
                <Grid item lg={12} xs={12}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Checkbox
                      style={{ padding: '0', color: '#0DAC69' }}
                      name='isNotExpired'
                      checked={inputField.isNotExpired}
                      onChange={(event) =>
                        handleChangeInputCertificateCheckBox(
                          inputField.id,
                          event
                        )
                      }
                    />
                    <span
                      className='label'
                      style={{ color: '#0F172A', marginLeft: '10px' }}
                    >
                      This certificate does not expire
                    </span>
                  </div>
                </Grid>

                <Grid item lg={12}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='label'>Description <small><i>(Character Limit 225)</i></small></span>
                    <TextField
                      name='desc'
                      value={inputField.desc}
                      error={activeStep_3.desc}
                      onChange={(event) => {
                        handleChangeInputCertificate(inputField.id, event);
                        checkCharacterLimit(inputField.desc);
                      }
                      }
                      multiline={true}
                      rows={4}
                      size='small'
                      variant='outlined'
                      inputProps={{ maxLength: 225 }}
                    />
                    {
                      characterLimit ? <>
                        <small style={{ color: 'red' }}>You have typed more than 225 characters</small>
                      </ > : null
                    }
                  </div>
                </Grid>
                {certificationEdit.isEdit ? null : (
                  <Grid
                    container
                    justifyContent='space-between'
                    item
                    lg={12}
                    md={11}
                    sm={11}
                    xs={11}
                  >
                    <Grid container justifyContent={inputFieldsCertificate.length == idx + 1 ? 'space-between' : 'flex-end'} item lg={12} md={11} sm={11} xs={11}>

                      {inputFieldsCertificate.length == idx + 1 ? <Button variant='contained' style={{ background: "#fff", borderRadius: '18px', fontWeight: '600', textTransform: 'none', border: '2px solid #0881f4', color: '#0881f4' }} onClick={handleAddFieldsCertificate}>Add Another</Button> : <></>}
                      <Button variant='contained' style={{ background: "#fff", borderRadius: '18px', fontWeight: '600', textTransform: 'none', color: '#909090' }} disabled={inputFieldsCertificate.length === 1} onClick={() => handleRemoveFieldsCertificate(inputField.id)}><RiDeleteBin6Line style={{ height: '2em' }} />&nbsp;Remove</Button>


                    </Grid>
                    <hr style={{ width: '100%' }}></hr>
                  </Grid>
                )}
              </Grid>
            ))}
          </form>
        </div>
        <div className='btnProfileModalCont'>
          <button
            className='btnContCancelBtn'
            id='certificate_close_modal'
            onClick={props.closeModal}
          >
            Cancel
          </button>
          {loader ? (
            <CircularProgress
              style={{ color: 'green', justifyContent: 'center' }}
            />
          ) : (
            <button
              className='btnContSaveBtn'
              onClick={handleSubmitCertificate}
            >
              Save
            </button>
          )}
        </div>
      </div>
    )
  }

  // Skill
  const SkillsForm = (props) => {
    const { height, width } = useWindowDimensions()
    const [selectedSkillOption, setSelectedSkillOption] = useState('')

    const Skills = [
      { value: 'Maths Teacher', label: 'Maths Teacher' },
      { value: 'English Teacher', label: 'English Teacher' },
      { value: 'Assistant Teacher', label: 'Assistant Teacher' },
      { value: 'Class Teacher', label: 'Class Teacher' },
      { value: 'History Teacher', label: 'History Teacher' },
    ]

    const [loader, setloader] = useState(false)
    const handleSubmitSkill = (e) => {
      setloader(true)
      e.preventDefault()
      console.log('InputFields', selectedSkillOption)
      let skills = []
      selectedSkillOption.map((item) => {
        const skill = {
          title: item.value,
        }
        skills.push(skill)
      })
      const data = {
        // access: accessToken,
        data: skills,
      }

      axiosInstance
        .post('/seeker/skill/', data)
        .then((res) => {
          axiosInstance
            .get('/seeker/skill/')
            .then((res) => {
              setskill(res.data.data)
              fetching_profile_point()
              document.getElementById('skillCloseButton').click()
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch((err) => {
          console.log(err)
        })

      // !skillEdit.isEdit ? (
      //   axiosInstance.post('/seeker/skill/', data).then(res=>{
      //     axiosInstance.get('/seeker/skill/', {
      //       params:{
      //         "access" : accessToken
      //       }
      //     }).then(res=>{
      //       setskill(res.data.data);
      //       document.getElementById('skillCloseButton').click();
      //     }).catch(err=>{
      //       console.log(err);
      //     })
      //   }).catch(err=>{
      //     console.log(err);
      //   })
      // ) : (
      //   axiosInstance.patch('/seeker/skill/', {
      //     access : accessToken,
      //     id : skillEdit.id,
      //     title : selectedSkillOption
      //   }).then(res=>{
      //     axiosInstance.get('/seeker/skill/', {
      //       params:{
      //         "access" : accessToken
      //       }
      //     }).then(res=>{
      //       setskill(res.data.data);
      //       document.getElementById('skillCloseButton').click();
      //     }).catch(err=>{
      //       console.log(err);
      //     })
      //   }).catch(err=>{
      //     console.log(err);
      //   })
      // )
    }

    useEffect(() => {
      if (skillEdit.isEdit) {
        console.log('herreee')
        setSelectedSkillOption([
          {
            value: skillEdit.title,
            label: skillEdit.title,
          },
        ])
      }
    }, [])

    return (
      <div>
        <div
          style={{
            marginLeft: '2.5em',
            marginRight: '2.5em',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>
            <Typography
              style={{ fontSize: '19px', fontWeight: '600', margin: '0%' }}
            >
              Skills
            </Typography>

            <Typography
              className='perferencestyle'
              style={
                width < 420
                  ? { fontSize: '14px', color: '#6C6866', margin: '10px 0' }
                  : { fontSize: '14px', color: '#6C6866', margin: '10px 0%' }
              }
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In neque
              nunc tristique.
            </Typography>
          </div>
          <br />
          <Grid item container spacing={3}>
            <Grid item lg={12} xs={10}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className='label'>Add skills</span>
                <Select
                  isMulti
                  value={selectedSkillOption}
                  onChange={(selectedSkillOption) => {
                    setSelectedSkillOption(selectedSkillOption)
                    console.log(selectedSkillOption)
                  }}
                  options={Skills}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className='btnProfileModalCont'>
          <button
            className='btnContCancelBtn'
            id='skillCloseButton'
            onClick={props.closeModal}
          >
            Cancel
          </button>
          {loader ? (
            <CircularProgress
              style={{ color: 'green', justifyContent: 'center' }}
            />
          ) : (
            <button className='btnContSaveBtn' onClick={handleSubmitSkill}>
              Save
            </button>
          )}
        </div>
      </div>
    )
  }

  // About Me Form
  const AboutMeForm = (props) => {
    const { height, width } = useWindowDimensions()
    const [aboutmeText, setaboutmeText] = useState('')

    const handleSubmitSkill = (e) => {
      e.preventDefault()
      if (!aboutmeText) {
        setAlert(true)
        setType('warning')
        setDesc('Please Recheck Form')
        return
      }
      axiosInstance
        .post('seeker/profile/', {
          // access: accessToken,
          about: aboutmeText,
        })
        .then((res) => {
          fetching_profile().then(() => {
            setAlert(true)
            setType('success')
            setDesc('Updated Successfully')
            props.closeModal()
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }

    useEffect(() => {
      if (profile.about) {
        setaboutmeText(profile.about)
      }
    }, [])

    const [characterLimit, setcharacterLimit] = useState(false);

    const checkCharacterLimit = (text) => {
      if (text.length > 499) {
        setcharacterLimit(true);
        return;
      }
      setcharacterLimit(false);
      return;
    }
    return (
      <div>
        <div
          style={{
            marginLeft: '2.5em',
            marginRight: '2.5em',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>
            <Typography
              style={{ fontSize: '19px', fontWeight: '600', margin: '0%' }}
            >
              About Me
            </Typography>

            <Typography
              className='perferencestyle'
              style={
                width < 420
                  ? { fontSize: '14px', color: '#6C6866', margin: '10px 0%' }
                  : { fontSize: '14px', color: '#6C6866', margin: '10px 0%' }
              }
            >
              Write about yourself in 500 characters only
            </Typography>
          </div>
          <br />
          <Grid item container spacing={3}>
            <Grid item lg={12} xs={10}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>

                <TextField
                  size='small'
                  name='degree'
                  placeholder='Write About Yourself'
                  variant='outlined'
                  value={aboutmeText}
                  onChange={(e) => {
                    setaboutmeText(e.target.value);
                    checkCharacterLimit(e.target.value);
                  }}
                  inputProps={{ maxLength: 500 }}
                />
                {
                  characterLimit ? <>
                    <small style={{ color: 'red' }}>You have typed more than 500 characters</small>
                  </ > : null
                }
              </div>
            </Grid>
          </Grid>
        </div>
        <div className='btnProfileModalCont'>
          <button
            className='btnContCancelBtn'
            id='skillCloseButton'
            onClick={props.closeModal}
          >
            Cancel
          </button>
          <button className='btnContSaveBtn' onClick={handleSubmitSkill}>
            Save
          </button>
        </div>
      </div>
    )
  }

  // Social Link Form
  const SocialLinkForm = (props) => {
    const { height, width } = useWindowDimensions()
    const [aboutmeText, setaboutmeText] = useState('')
    const [localSocialink, setlocalSocialLink] = useState(socialLink)
    const [selectedOption, setselectedOption] = useState('')

    const handleSubmitSkill = (e) => {
      e.preventDefault();
      let data = localSocialink
      data['access'] = accessToken
      axiosInstance
        .post('/seeker/social_link/', data)
        .then((res) => {
          setAlert(true)
          setType('success')
          setDesc('Updated Successfully')
          fetching_social_link()
          props.closeModal()
        })
        .catch((err) => {
          fetching_social_link()
          console.log(err)
          props.closeModal()
        })
    }

    const socialLinksOption = [
      { value: 'linkedin', label: 'LinkedIn' },
      { value: 'twitter', label: 'Twitter' },
      { value: 'facebook', label: 'Faceboook' },
      { value: 'calendly', label: 'Calendly' },
    ]
    return (

      <div className='py-5'>
        <div
          style={{
            marginLeft: '2.5em',
            marginRight: '2.5em',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>
            <Typography
              style={{ fontSize: '19px', fontWeight: '600', margin: '0%' }}
            >
              Social Link
            </Typography>

            <Typography
              className='perferencestyle'
              style={
                width < 420
                  ? { fontSize: '14px', color: '#6C6866', margin: '10px 0%' }
                  : { fontSize: '14px', color: '#6C6866', margin: '10px 0%' }
              }
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In neque
              nunc tristique.
            </Typography>
          </div>
          <br />
          <Grid item container spacing={3}>
            <Grid item lg={12} xs={10}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Select
                  className='basic-single'
                  options={socialLinksOption}
                  maxMenuHeight={250}
                  onChange={(e) => {
                    setselectedOption(e.value)

                  }}
                />
                {selectedOption.length ? (
                  <>
                    <TextField
                      className='py-2 my-2'
                      size='small'
                      name='degree'
                      placeholder={selectedOption === 'calendly' ? 'Enter your Calendly Username' : 'Add link to your profile'}
                      variant='outlined'
                      value={localSocialink[selectedOption]}
                      onChange={(e) => {
                        setlocalSocialLink({
                          ...localSocialink,
                          [selectedOption]: e.target.value,
                        })
                      }}
                    />
                  </>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className='btnProfileModalCont'>
          <button
            className='btnContCancelBtn'
            id='skillCloseButton'
            onClick={props.closeModal}
          >
            Cancel
          </button>
          <button className='btnContSaveBtn' onClick={handleSubmitSkill}>
            Save
          </button>
        </div>
      </div>
    )
  }

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1])
    else byteString = unescape(dataURI.split(',')[1])

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length)
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ia], { type: mimeString })
  }

  const getCroppedImg = async () => {
    try {
      const canvas = document.createElement('canvas')
      const scaleX = image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height
      canvas.width = crop.width
      canvas.height = crop.height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      )
      // const blob_ = canvas.toBlob(function(blob) {
      //   saveAs(blob, "pretty image.png");
      // });
      const base64Image = canvas.toDataURL('image/jpeg', 1)
      const blob_ = dataURItoBlob(base64Image)
      const imageFile = new File([blob_], `${profile.name}_profilePic`)
      return imageFile
    } catch (e) {
      console.log('crop the image')
    }
  }

  // Language
  const LanguageForm = (props) => {
    const [inputFieldsLang, setInputFieldsLang] = useState([
      { id: uuidv4(), language: '', proffeciency: '' },
    ])
    const [accessToken, setAccessToken] = useState('');

    const profficiencyArr = [
      { 'value': 'Beginner', 'label': 'Beginner' },
      { 'value': 'Intermediate', 'label': 'Intermediate' },
      { 'value': 'Advanced', 'label': 'Advanced' }
    ]

    useEffect(() => {
      if (Cookies.get('access_token')) {
        setAccessToken(Cookies.get('access_token'))
      } else {
        window.location.href = '/login'
      }
    }, [accessToken])

    useEffect(() => {
      if (languageEdit.isEdit) {
        setInputFieldsLang([
          {
            id: languageEdit.id,
            language: { value: languageEdit.name, label: languageEdit.name }, // language: languageEdit.name,
            proffeciency: { value: languageEdit.rating, label: languageEdit.rating }, // proffeciency: languageEdit.rating,
          },
        ])
      }
    }, [])
    const [loader, setloader] = useState(false)
    const handleSubmitLang = (e) => {
      setloader(true)
      e.preventDefault()

      console.log('InputFields', inputFieldsLang)
      let langs = []

      inputFieldsLang.map((item) => {
        const lang = {
          name: item.language.value,
          rating: item.proffeciency.value,
        }
        langs.push(lang)
      })
      const data = {
        // access: accessToken,
        data: langs,
      }
      !languageEdit.isEdit
        ? axiosInstance
          .post('/seeker/language/', data)
          .then((res) => {
            axiosInstance
              .get('/seeker/language/')
              .then((res) => {
                // console.log(res.data.data);
                setlanguage(res.data.data)
                fetching_profile_point()

                // props.closeModal;
                document.getElementById('langCloseModal').click()
              })
          })
          .catch((err) => {
            console.log(err)
          })
        : axiosInstance
          .patch('/seeker/language/', {
            // access: accessToken,
            id: inputFieldsLang[0].id,
            name: inputFieldsLang[0].language.value,
            rating: inputFieldsLang[0].proffeciency.value,
          })
          .then((res) => {
            axiosInstance
              .get('/seeker/language/')
              .then((res) => {
                // console.log(res.data.data);
                fetching_profile_point()
                setAlert(true)
                setType('success')
                setDesc('Updated Successfully')
                setlanguage(res.data.data)
                // props.closeModal;
                document.getElementById('langCloseModal').click()
              })
          })
          .catch((err) => {
            console.log(err)
          })
    }

    const handleChangeInputLang = (id, event) => {
      const newInputFields = inputFieldsLang.map((i) => {
        if (id === i.id) {
          i[event.target.name] = event.target.value
        }
        return i
      })

      setInputFieldsLang(newInputFields)
    }

    const handleChangeInputLanguage = (id, val, name) => {
      const newInputFields = inputFieldsLang.map((i) => {
        if (id === i.id) {
          i[name] = val
        }
        return i
      })

      setInputFieldsLang(newInputFields)
    }

    const handleAddFieldsLang = () => {
      setInputFieldsLang([
        ...inputFieldsLang,
        { id: uuidv4(), language: '', proffeciency: '' },
      ])
    }

    const handleRemoveFieldsLang = (id) => {
      const values = [...inputFieldsLang]
      values.splice(
        values.findIndex((value) => value.id === id),
        1
      )
      setInputFieldsLang(values)
    }

    return (
      <div>
        <div
          style={{
            marginRight: '2.5em',
            marginLeft: '2.5em',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography style={{ fontSize: '19px', fontWeight: '600' }}>
            Language
          </Typography>
          <br />
          <form onSubmit={handleSubmitLang}>
            {inputFieldsLang.map((inputField, idx) => (
              <Grid key={inputField.id} container item spacing={3}>
                <Grid item lg={6}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='label'>Language</span>
                    <Select
                      size='small'
                      name='language'
                      value={inputField.language}
                      onChange={(event) =>
                        handleChangeInputLanguage(inputField.id, event, 'language')
                      }
                      placeholder='Language'
                      variant='outlined'
                      style={{ marginTop: '18px' }}
                      options={languagesArr}
                    />
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='label'>How would you rate yourself on this skill?</span>
                    <Select
                      size='small'
                      name='proffeciency'
                      value={inputField.proffeciency}
                      onChange={(event) =>
                        handleChangeInputLanguage(inputField.id, event, 'proffeciency')
                      }
                      placeholder='Language'
                      variant='outlined'
                      style={{ marginTop: '18px' }}
                      options={profficiencyArr}
                    />
                    {/* <span className='label'>
                      How would you rate yourself on this skill?
                    </span>
                    <select
                      name='proffeciency'
                      id='proffeciency'
                      onChange={(event) =>
                        handleChangeInputLang(inputField.id, event)
                      }
                      style={{
                        marginTop: '12px',
                        padding: '11.5px',
                        borderColor: '#c4c4c4',
                      }}
                    >
                      <option value='' selected disabled>
                        Select proficiency
                      </option>
                      <option value='Beginner'>Beginner</option>
                      <option value='Adavance'>Adavance</option>
                      <option value='Expert'>Expert</option>
                    </select> */}
                  </div>
                </Grid>

                {languageEdit.isEdit ? null : (
                  <Grid
                    container
                    justifyContent='space-between'
                    item
                    lg={12}
                    md={11}
                    sm={11}
                    xs={11}
                  >
                    <Grid container justifyContent={inputFieldsLang.length == idx + 1 ? 'space-between' : 'flex-end'} item lg={12} md={11} sm={11} xs={11}>

                      {inputFieldsLang.length == idx + 1 ? <Button
                        variant='contained'
                        style={{
                          background: '#fff',
                          borderRadius: '18px',
                          fontWeight: '600',
                          textTransform: 'none',
                          border: '2px solid #0881f4',
                          color: '#0881f4',
                        }}
                        onClick={handleAddFieldsLang}
                      >
                        Add Another
                      </Button> : <></>}
                      <Button
                        variant='contained'
                        style={{
                          background: '#fff',
                          borderRadius: '18px',
                          fontWeight: '600',
                          textTransform: 'none',
                          color: '#909090',
                        }}
                        disabled={inputFieldsLang.length === 1}
                        onClick={() => handleRemoveFieldsLang(inputField.id)}
                      >
                        <RiDeleteBin6Line style={{ height: '2em' }} />&nbsp;
                        Remove
                      </Button>
                    </Grid>
                    <hr style={{ width: '100%' }}></hr>
                    <br />
                  </Grid>
                )}
              </Grid>
            ))}
          </form>
        </div>
        <div className='btnProfileModalCont'>
          <button
            className='btnContCancelBtn'
            id='langCloseModal'
            onClick={props.closeModal}
          >
            Cancel
          </button>
          {loader ? (
            <CircularProgress
              style={{ color: 'green', justifyContent: 'center' }}
            />
          ) : (
            <button className='btnContSaveBtn' onClick={handleSubmitLang}>
              Save Languages
            </button>
          )}
        </div>
      </div>
    )
  }
  const deleteAboutMe = () => {
    if (!accessToken) {
      setAlert(true)
      setType('error')
      setDesc('Something Went Wrong')
      return
    }
    axiosInstance
      .post('seeker/profile/', {
        // access: accessToken,
        about: '',
      })
      .then((res) => {
        fetching_profile().then(() => {
          setAlert(true)
          setType('success')
          setDesc('Deleted Successfully')
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getCapitalized = (text) => {
    const wordList = text.split('_')
    let word = ''
    wordList.map((item) => {
      word += item[0].toUpperCase() + item.slice(1) + ' '
    })
    return word.trim()
  }

  const handleProfilePic = (e) => {
    // console.log(e.target.files[0]);
    // console.log(URL.createObjectURL(e.target.files[0]));
    // console.log(typeof(e.target.files[0]));
    setsrcImage(URL.createObjectURL(e.target.files[0]))
  }

  const submitProfilePic = async () => {
    const imageFile = await getCroppedImg()

    let formD = new FormData()
    formD.append('access', accessToken)
    formD.append('photo', imageFile)

    axiosInstance
      .post('/seeker/profile_pic/', formD)
      .then((res) => {
        axiosInstance
          .get('auth/get_user_profile/')
          .then((res) => {
            const data = res.data.msg
            localStorage.removeItem('profile_pic')
            localStorage.setItem('profile_pic', data.profile_pic)
            setprofile({ ...profile, ['profile_pic']: data.profile_pic })
            fetching_profile_point()
            setsrcImage(null)
            setImage(null)
            setCrop(null)
          })
          .catch((err) => {
            console.log(err.data)
            setAlert(true)
            setType('error')
            setDesc(err.data)
          })
        setAlert(true)
        setType('success')
        setDesc('Updated Successfully')
      })
      .catch((err) => {
        console.log(err)
        setAlert(true)
        setType('error')
        setDesc('Something went wrong')
      })
  }

  const [openDeleteAccount, setOpenDeleteAccount] = React.useState(false);

  const handleClickOpenDeleteAccount = () => {
    setOpenDeleteAccount(true);
  };

  const handleCloseDeleteAccount = () => {
    setOpenDeleteAccount(false);
  };

  const deleteVideo = () => {
    const data = {
      access: accessToken
    }
    console.log(data);
    axiosInstance.delete('/seeker/record_video/', {
      params: {
        access: accessToken
      }
    })
      .then(res => {
        setvideoResumeURL(null);
        // alert('Video Deleted Successfully');
        setAlert(true)
        setType('success')
        setDesc('Video Deleted Successfully')
        handleCloseDeleteAccount();
      }).catch(err => {
        console.log(err);
      })
  }


  return (
    <React.Fragment>
      <div className='profile'>
        {
          loader ?
            <div className='' style={{ height: '120vh', display: 'grid', placeContent: 'center', width: '100vw', backgroundColor: 'rgb(255,255,255,0.6)', position: 'fixed', zIndex: '100' }}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: loaderJson,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
                height={200}
                width={200}
              />
            </div> : null
        }
        <Helmet>
          <title>Profile | Frame Change</title>
        </Helmet>
        <Modal
          open={loginModal}
          onClose={() => {
            setLoginModal(false)
          }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div className='modalCont' style={{ width: 'fit-content' }}>
            <img src={MaintainenceGuy} alt='' />
            <div className='loginModalHead'>
              This action is not available yet...
            </div>
            <div className='loginModalSubHead'>
              We are woking on it, please come back later.
            </div>
            <button
              className='loginModalBtn'
              onClick={() => setLoginModal(false)}
            >
              Okay
            </button>
          </div>
        </Modal>
        <Navbar />
        {/* ALERT */}
        {alertComp ? (
          <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlert} />
        ) : null}
        <div className='profileCont'>
          <div className='profileLeft'>
            <div className='profileCard'>
              <div className='profileName'>
                <div>
                  <div class='circle'>
                    <img
                      src={
                        !profile.profile_pic
                          ? `https://ui-avatars.com/api/?name=${profile.name}`
                          : profile.profile_pic
                      }
                      alt=''
                    />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      borderRadius: '20px',
                      height: '10px',
                      backgroundColor: '#c8c9ca',
                      marginTop: '10px',
                    }}
                  >
                    <div
                      style={{
                        height: '10px',
                        borderRadius: '20px',
                        backgroundColor: `${pointPercentage <= 35
                          ? 'red'
                          : `${pointPercentage <= 75 ? '#f08900' : '#23b06c'}`
                          }`,
                        width: `${pointPercentage}%`,
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div className='candidateName'>{profile.name}</div>
                  <div className='candidateLocation'>
                    {profile.city} {profile.city && profile.state ? ',' : null}{' '}
                    {profile.state}
                  </div>

                  <div className='profileCompletion'>
                    {pointPercentage}% Profile completed
                  </div>
                  <div
                    className='profileCompletion'
                    style={{
                      marginTop: '5px',
                      color: '#E1882D',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      document
                        .getElementById('profile_pic_upload_button')
                        .click()
                    }
                  >
                    Change Photo
                    <input
                      type='file'
                      name=''
                      id='profile_pic_upload_button'
                      accept='image/*'
                      hidden
                      onChange={handleProfilePic}
                    />
                  </div>

                  <div>
                    {srcImage && (
                      <div className='crop-container d-lg-flex d-md-block justify-content-around '>
                        <div className='d-lg-block d-sm-flex justify-content-sm-center'>
                          <ReactCrop
                            style={{
                              maxWidth: '60vw',
                              maxHeight: '75vh',
                              padding: '5vh 0',
                              overflow: 'auto',
                            }}
                            src={srcImage}
                            onImageLoaded={setImage}
                            crop={crop}
                            onChange={setCrop}
                          />
                        </div>
                        <div className='d-sm-flex flex-lg-column justify-content-sm-evenly  pt-sm-3'>
                          <Button
                            className='cropButton btnContSaveBtn mb-lg-5 mr-2'
                            onClick={submitProfilePic}
                          >
                            Upload Photo
                          </Button>
                          <Button
                            className='cancelButton'
                            style={{
                              color: 'white',
                              backgroundColor: '#a79b9b',
                            }}
                            onClick={() => {
                              setsrcImage(null)
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    )}
                    {/* {result && (
                              <div>
                                  <img src={result} alt="cropped image"/>
                              </div>
                          )} */}
                  </div>
                </div>
              </div>
              <div className='profileTagCont'>
                {certification.length > 0 ? (
                  <ProfileTags img={PHD} text='Education' />
                ) : null}
                {certification.length > 0 ? (
                  <ProfileTags img={Checked} text='Teacher’s license' />
                ) : null}
                {certification.length > 0 ? (
                  <ProfileTags img={Checked} text='Work Experience' />
                ) : null}
              </div>
            </div>
            <div className='profileCardSec'>
              <div className='opportunityCont'>
                <div className='opportunityTitle'>Opportunity Preferences</div>
                <div className='opportunitySubTitle'>
                  I am open to receiving requests. This will show your contact
                  button on your profile.
                </div>
                <div className='profileTagCont'>
                  {opportunityPreference.writing ? (
                    <ProfileTags text='Writing' />
                  ) : null}
                  {opportunityPreference.speaking_on_clubhouse ? (
                    <ProfileTags text='Speaking on ClubHouse' />
                  ) : null}
                  {opportunityPreference.mentoring ? (
                    <ProfileTags text='Mentoring' />
                  ) : null}
                  {opportunityPreference.content_creation ? (
                    <ProfileTags text='Content Creation' />
                  ) : null}
                </div>
              </div>
              <div
                className='editOpportunityBtn'
                onClick={() => setOpportunityForm(true)}
              >
                Edit Preference
              </div>
            </div>
            <div
              className='profileCard'
              style={{ borderRadius: '8px', marginTop: '40px' }}
            >
              <div className='vidResumeVecCont'>
                {videoResumeURL != null ? (
                  <>
                    <video style={videoStyle} controls>
                      <source src={videoResumeURL} type='video/mp4' />
                    </video>
                  </>
                ) : (
                  <>
                    <img src={vidResVec} alt='' />
                    <div className='vidResCont'>
                      <div className='vidResTitle'>Video resume</div>
                      <div className='vidResDesc'>
                        Introduce yourself to future employers through a short 2
                        minute video.
                      </div>
                      <div className='vidResPoint'>
                        <span style={{ fontWeight: '600' }}>Get a badge</span>{' '}
                        that features you to recruiters
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className='d-flex justify-content-center'>
                <a
                  id='upload_video'
                  href='/video-recording'
                  style={{ textDecoration: 'none' }}
                  className='px-2'
                >
                  <button className='vidResC2a'>
                    Upload or Record {videoResumeURL ? 'New' : null} Video Resume
                  </button>
                </a>
                <a
                  id='upload_video'
                  style={{ textDecoration: 'none' }}
                  className='px-2'
                >
                  {
                    videoResumeURL ? (
                      <button onClick={handleClickOpenDeleteAccount} className='vidResC2a' style={{ backgroundColor: '#F73737', color: 'white' }}>
                        <img src={DeleteWhite} />
                      </button>) : null
                  }
                </a>
              </div>
            </div>
            <div
              className='profileCard'
              style={{ borderRadius: '8px', marginTop: '20px' }}
            >
              <div className='dataCont'>
                <div className='dataContLeft'>WORK EXPERIENCE</div>
                <div className='dataContRight'>
                  {workExperience.map((item) => {
                    return (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <div>
                            <div className='dataContRightTitle'>
                              {item.position}
                            </div>
                            <div className='dataContRightSubTitle'>
                              {item.school}
                            </div>
                            <div className='dataContRightSubTitle'>
                              {item.start_date}
                            </div>
                            <div className='dataContRightData'>
                              {item.description}
                            </div>
                          </div>
                          <div className=''>
                            <a
                              style={{ paddingRight: '20px' }}
                              onClick={() => {
                                console.log(item)
                                setworkExpEdit({
                                  isEdit: true,
                                  id: item.id,
                                  position: item.position,
                                  school_district: item.school,
                                  start_date: item.start_date,
                                  end_date: item.end_date,
                                  is_working: item.is_working,
                                  description: item.description,
                                })
                                setWorkExperienceForm(true)
                              }}
                            >
                              <img
                                src={edit}
                                alt=''
                                style={{ cursor: 'pointer' }}
                              />
                            </a>
                            <a onClick={deleteFunc}>
                              <img
                                id={`work_experience-${item.id}`}
                                src={Delete}
                                style={{ cursor: 'pointer' }}
                              />
                            </a>
                          </div>
                        </div>
                        <br />
                      </>
                    )
                  })}
                </div>
              </div>
              <div
                className='dataContC2a'
                onClick={() => {
                  setWorkExperienceForm(true)
                  setworkExpEdit({ ...workExpEdit, ['isEdit']: false })
                  console.log(workExpEdit)
                }}
              >
                <img src={addIcon} alt='' /> Add Work Experience
              </div>
            </div>
            <div
              className='profileCard'
              style={{ borderRadius: '8px', marginTop: '20px' }}
            >
              <div className='dataCont'>
                <div className='dataContLeft'>SKILLS</div>
                <div className='dataContRight'>
                  <div className='dataContRightData' style={{ marginTop: '0' }}>
                    {skillSet.map((item) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div>
                            {item.title}
                            <br /> <br />
                          </div>
                          <div className=''>
                            <a onClick={deleteFunc}>
                              <img
                                id={`skill-${item.id}`}
                                style={{ cursor: 'pointer' }}
                                src={Delete}
                              />
                            </a>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div
                className='dataContC2a'
                onClick={() => {
                  setSkillsForm(true)
                  setskillEdit({ ...skillEdit, ['isEdit']: false })
                }}
              >
                <img src={addIcon} alt='' /> Add Skills
              </div>
            </div>
            <div
              className='profileCard'
              style={{ borderRadius: '8px', marginTop: '20px' }}
            >
              <div className='dataCont'>
                <div className='dataContLeft'>Certifications</div>
                <div className='dataContRight'>
                  {certification.map((item) => {
                    return (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div>
                            <div className='dataContRightTitle'>
                              {item.name}
                            </div>
                            <div className='dataContRightSubTitle'>
                              {item.authority}
                            </div>
                            <div className='dataContRightSubTitle'>
                              {item.start_date}
                            </div>
                          </div>
                          <div className=''>
                            <a
                              style={{ paddingRight: '20px' }}
                              onClick={() => {
                                setcertificationEdit({
                                  isEdit: true,
                                  id: item.id,
                                  name: item.name,
                                  authority: item.authority,
                                  does_expire: item.does_expire,
                                  start_date: item.start_date,
                                  end_date: item.end_date,
                                  is_working: item.is_working,
                                  description: item.description,
                                })
                                setCertificateForm(true)
                              }}
                            >
                              <img
                                src={edit}
                                alt=''
                                style={{ cursor: 'pointer' }}
                              />
                            </a>
                            <a onClick={deleteFunc}>
                              <img
                                src={Delete}
                                id={`certification-${item.id}`}
                                style={{ cursor: 'pointer' }}
                              />
                            </a>
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
              </div>
              <div
                className='dataContC2a'
                onClick={() => {
                  setCertificateForm(true)
                  setcertificationEdit({
                    ...certificationEdit,
                    ['isEdit']: false,
                  })
                }}
              >
                <img src={addIcon} alt='' /> Add Certifications
              </div>
            </div>
            <div
              className='profileCard'
              style={{ borderRadius: '8px', marginTop: '20px' }}
            >
              <div className='dataCont'>
                <div className='dataContLeft'>EDUCATION</div>
                <div className='dataContRight'>
                  {education.map((item) => {
                    return (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <div>
                            <div className='dataContRightTitle'>
                              {item.degree}
                            </div>
                            <div className='dataContRightSubTitle'>
                              {item.school}
                            </div>
                            <div className='dataContRightSubTitle'>
                              {item.start_date}
                            </div>
                            <div className='dataContRightData'>
                              {item.description}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }} className=''>
                            <a
                              style={{ paddingRight: '20px' }}
                              onClick={() => {
                                console.log(item)
                                seteducationEdit({
                                  isEdit: true,
                                  id: item.id,
                                  degree: item.degree,
                                  school_district: item.school,
                                  start_date: item.start_date,
                                  end_date: item.end_date,
                                  is_working: item.is_working,
                                  description: item.description,
                                })
                                setEducationForm(true)
                              }}
                            >
                              <img
                                src={edit}
                                alt=''
                                style={{ cursor: 'pointer' }}
                              />
                            </a>
                            <a onClick={deleteFunc}>
                              <img
                                src={Delete}
                                style={{ cursor: 'pointer' }}
                                id={`education-${item.id}`}
                              />
                            </a>
                          </div>

                        </div>
                        <br />
                      </>
                    )
                  })}
                </div>
              </div>
              <div
                className='dataContC2a'
                onClick={() => {
                  setEducationForm(true)
                  seteducationEdit({ ...educationEdit, ['isEdit']: false })
                }}
              >
                <img src={addIcon} alt='' /> Add Education
              </div>
            </div>
            <div
              className='profileCard'
              style={{ borderRadius: '8px', marginTop: '20px' }}
            >
              <div className='dataCont'>
                <div className='dataContLeft'>Languages</div>
                <div className='dataContRight'>
                  {language.map((item) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <div className='dataContRightTitle'>{item.name}</div>
                          <div className='dataContRightSubTitle'>
                            {item.rating}
                          </div>
                        </div>

                        <div className='btnCont'>
                          <a
                            onClick={(e) => {
                              setLanguageForm(true);
                              setlanguageEdit({
                                isEdit: true,
                                id: item.id,
                                name: item.name,
                                rating: item.rating,
                              })
                            }}
                          >
                            <img src={edit} alt='' style={{ cursor: 'pointer' }} />
                          </a>
                          <a onClick={deleteFunc}>
                            <img
                              src={Delete}
                              style={{ cursor: 'pointer' }}
                              id={`language-${item.id}`}
                            />
                          </a>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div
                className='dataContC2a'
                onClick={() => {
                  setLanguageForm(true)
                  setlanguageEdit({ ...languageEdit, ['isEdit']: false })
                }}
              >
                <img src={addIcon} alt='' /> Add Languages
              </div>
            </div>
            <div
              className='profileCard'
              style={{ borderRadius: '8px', marginTop: '20px' }}
            >
              <div className='dataCont'>
                <div className='dataContLeft'>ABOUT ME</div>
                <br></br>
                <div className='dataContRight'>
                  <div className='dataContRightData' style={{ marginTop: '0' }}>
                    {profile.about}
                  </div>
                </div>
                <div className='btnCont'>
                  <a
                    onClick={(e) => {
                      setaboutMeForm(true)
                    }}
                  >
                    <img src={edit} alt='' style={{ cursor: 'pointer' }} />
                  </a>
                  <a onClick={deleteAboutMe}>
                    <img style={{ cursor: 'pointer' }} src={Delete} alt='' />
                  </a>
                </div>
              </div>
            </div>

            <div
              className='profileCard'
              style={{ borderRadius: '8px', marginTop: '20px' }}
            >
              <div className='dataCont'>
                <div className='dataContLeft'>Social Link</div>
                <div className='dataContRight'>
                  <div className='dataContRightData' style={{ marginTop: '0' }}>
                    {/* {profile.about} */}
                    {socialLink.linkedin ? (
                      <img
                        src={LinkedIn}
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          socialLink.linkedin.includes('http') ?
                            window.open(socialLink.linkedin, '_blank') : window.open(`https://${socialLink.linkedin}`, '_blank')
                        }
                      />
                    ) : null}
                    {socialLink.facebook ? (
                      <img
                        src={facebook}
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          socialLink.facebook.includes('http') ?
                            window.open(socialLink.facebook, '_blank') : window.open(`https://${socialLink.facebook}`, '_blank')
                        }
                      />
                    ) : null}
                    {socialLink.twitter ? (
                      <img
                        src={twitter}
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          socialLink.twitter.includes('http') ?
                            window.open(socialLink.twitter, '_blank') : window.open(`https://${socialLink.twitter}`, '_blank')
                        }
                      />
                    ) : null}

                    {socialLink.calendly ? (
                      <div className=' py-2 col-5 d-flex justify-content-center' style={{ cursor: 'pointer', 'borderRadius': '10px', border: '#F1F5FA 2px solid' }}>
                        <img
                          src={calendly}
                          style={{ height: '30px' }}
                          onClick={() =>
                            window.open(`https://calendly.com/${socialLink.calendly}/30min`, '_blank')
                          }
                        /> <span style={{ color: 'blue', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>Calendly</span>
                      </div>
                    ) : null}

                  </div>
                </div>
                <div className='btnCont'>
                  <a
                    onClick={(e) => {
                      setsocialLinkForm(true)
                    }}
                  >
                    <img src={edit} alt='' style={{ cursor: 'pointer' }} />
                  </a>
                  <a onClick={() => {
                    alert('Delete ko time hai');
                  }}>
                    <img
                      src={Delete}
                      style={{ cursor: 'pointer' }}
                    />
                  </a>
                  {/* <a onClick={deleteAboutMe}><img style={{"cursor" : "pointer"}} src={Delete} alt="" /></a> */}
                </div>
              </div>
            </div>
          </div>
          <div className='profileRight'>
            <div className='profileRightSticky'>
              <div className='stickyTop'>
                <img src={profImage} alt='' className='profileImgRight' />
                <div className='stickyRightRight'>
                  <div className='__top'>
                    <div className='__topLeft'>I am Proficient</div>
                    <div
                      className='__topRight'
                      onClick={() => setLoginModal(true)}
                    >
                      Change
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {missingPoints.map((item) => {
                  return (
                    <>
                      <div className='__top'>
                        <div
                          className='trpC2a'
                          style={{ cursor: 'pointer' }}
                          onClick={missingDict[item.label]}
                        >
                          + {getCapitalized(item.label)}
                        </div>
                        <div>+{item.value}%</div>
                      </div>
                      <hr />
                      <div style={{ color: '#636363' }}>{item.desc}</div>
                      <hr />
                    </>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={workExperienceForm}
          onClose={() => {
            setWorkExperienceForm(false)
            setworkExpEdit({ ...workExpEdit, ['isEdit']: false })
          }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div className='modalCont'>
            <WorkExperienceForm
              closeModal={() => {
                setWorkExperienceForm(false)
                setworkExpEdit({ ...workExpEdit, ['isEdit']: false })
              }}
            />
          </div>
        </Modal>
        <Modal
          open={certificateForm}
          onClose={() => setCertificateForm(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div className='modalCont'>
            <CertficateForm closeModal={() => setCertificateForm(false)} />
          </div>
        </Modal>
        <Modal
          open={educationForm}
          onClose={() => setEducationForm(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div className='modalCont'>
            <EducationForm closeModal={() => setEducationForm(false)} />
          </div>
        </Modal>
        <Modal
          open={languageForm}
          onClose={() => setLanguageForm(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div className='modalCont'>
            <LanguageForm closeModal={() => setLanguageForm(false)} />
          </div>
        </Modal>
        <Modal
          open={skillsForm}
          onClose={() => setSkillsForm(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div className='modalCont' style={{ height: '462px' }}>
            <SkillsForm closeModal={() => setSkillsForm(false)} />
          </div>
        </Modal>
        <Modal
          open={opportunityForm}
          onClose={() => setOpportunityForm(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div className='modalCont'>
            <OpportunityForm closeModal={() => setOpportunityForm(false)} />
          </div>
        </Modal>

        <Modal
          open={aboutMeForm}
          onClose={() => setaboutMeForm(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div className='modalCont'>
            <AboutMeForm closeModal={() => setaboutMeForm(false)} />
          </div>
        </Modal>

        {/* Social Link Modal */}

        <Modal
          open={socialLinkForm}
          onClose={() => setsocialLinkForm(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div className='modalCont'>
            <SocialLinkForm closeModal={() => setsocialLinkForm(false)} />
          </div>
        </Modal>
      </div>
      {/* Delete Alert */}

      <Dialog
        open={openDeleteAccount}
        onClose={handleCloseDeleteAccount}

      >

        <DialogContent >
          <Grid container justifyContent='row' spacing={1} >
            <Grid item lg={2} md={3} sm={3} xs={3} >
              <img src={DeleteIcon} alt='Delete Icon' />
            </Grid>
            <Grid container direction='column' item lg={10} md={9} sm={9} xs={9} >
              <span style={{ fontWeight: '600', fontSize: '19px' }}>Delete my Video Resume</span>

              <span style={{ fontSize: '14px', marginTop: '1em' }}>Are you sure you want to delete your video Resume? if you delete your video, you will permanently lose your Video Resume.</span>

            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingBottom: '1.5em' }}>
          <Button style={{ textTransform: 'none', border: '1px solid #808080', borderRadius: '18px', padding: '0.5em 1em', color: '#808080' }} onClick={handleCloseDeleteAccount}>Cancel</Button>
          <Button style={{ textTransform: 'none', background: 'linear-gradient(275.34deg, #D80202 -10.41%, #D70000 -10.4%, #FF4545 102.29%)', borderRadius: '18px', padding: '0.5em 1em', color: '#fff' }} onClick={deleteVideo} autoFocus>
            Delete Video
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </React.Fragment>
  )
}

export default Profile

export const ProfileTags = (props) => {
  return (
    <div className={`profileTag ${props.img ? null : 'tagBoldText'}`}>
      <img src={props.img} alt='' />
      {props.text}
    </div>
  )
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
