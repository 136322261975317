import { Box, Container, Grid, Typography, Tabs, Tab, FormControlLabel, Button, Switch, CircularProgress } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import "react-multi-carousel/lib/styles.css";
import Navbar from '../NavBar_withSearch/index.js';
import Dollar from '../Dashboard/dollar.svg';
import Phd from '../Dashboard/phd.svg';
import { styled } from '@material-ui/styles';
import Teacher from '../Dashboard/teacher.svg';
import FullTime from '../Dashboard/fullTime.svg';
import Time from '../Dashboard/time.svg';
import San from '../Dashboard/san.svg';
import { FaRegHeart } from 'react-icons/fa';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { FaHeart } from 'react-icons/fa';
import { AiOutlinePlus } from 'react-icons/ai';
import DesiredJob from './desiredJob.svg';
import GreenDesiredJob from './GreenDesiredJob.svg';
import Profile from './profile.svg';
import School from './school.svg';
import Tick from './Tick.svg'
import Modal from '@mui/material/Modal'
import MaintainenceGuy from '../../images/undraw_maintenance_re_59vn 1.png';
import Edit from '../emp_settings/Frame 678.png'
import { useHistory } from 'react-router-dom';
import Arrow from '../emp_settings/Icon.png'
import Phone from '../emp_settings/Phone.png';
import Mail from '../emp_settings/Mail.png'
import Web from '../emp_settings/Web.png';
import CloseIcon from '../../assets/Close.svg'
import LeftChevron from '../../assets/LeftChevron.svg'
import RightChevron from '../../assets/RightChevron.svg'
import Badge1 from '../../assets/badge1.svg'
import Badge2 from '../../assets/badge2.svg'
import Q from '../../assets/q.svg'
import { Helmet } from "react-helmet";
import './search-jobs.css';
import axiosInstance from '../../axios';
import axios from 'axios';
import ChevRight from './chevron-right 1.png';
import ReactPaginate from 'react-paginate';
import mainLoaderJson from '../../assets/mainLoader.json';
import loaderJson from '../../assets/loader.json';
import Lottie from 'react-lottie';
import $ from 'jquery';
import Footer from '../Footer/index.js'
import Cookies from 'js-cookie';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import srch from '../Dashboard/searchicon.svg';
import pin from '../Dashboard/pincodeicon.svg';
import bgimg from '../Dashboard/mainbg.jpg';
import Select from 'react-select';
import stateflags from './state_flags.js';
import Alert from '../alerts/alert';
import closeIcon from "./close.svg"
import comparePic from "./compare.png"

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        localStorage.setItem('page', 1);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}





function SavedJobs() {

    const [tabValue, setTabValue] = React.useState('job-description');

    const [toggleHeart, setToggleHeart] = useState(false)
    const [loginModal, setLoginModal] = useState(false)
    const [modal, setModal] = useState(false)

    const changeColor = useCallback(() => {
        setToggleHeart(!toggleHeart)
        setLoginModal(true)
    }, [])

    const { height, width } = useWindowDimensions();


    //ALERT CODE:
    const [alertComp, setAlert] = React.useState(false)
    const [alertMsg, setAlertMsg] = React.useState(false)
    const [typeOfAlert, setType] = React.useState('')
    const [descOfAlert, setDesc] = React.useState('');



    const [jobs, setjobs] = useState([]);
    console.log(jobs)
    const [selectedJob, setselectedJob] = useState({
        "id": "",
        "city": "",
        "state": "",
        "district": "",
        "address": "",
        "district_id": "",
        "is_saved_job": "",
        "unmatched_school_district": "",
        "is_scraped": "",
        "scraped_job_ID": "",
        "scrap_source": "",
        "date_created": "",
        "job_title": "",
        "apply_url": "",
        "description": "",
        "location": "",
        "expiry_date": "",
        "date_posted": "",
        "vacancies": "",
        "position": "",
        "salary_from": "",
        "salary_to": "",
        "contact_person": "",
        "contact_title": "",
        "contact_phone": "",
        "contact_email": "",
        "salary_range": "",
        "no_of_job_openings": "",
        "shift_type": "",
        "other_details": "",
        "no_of_views": "",
        "phd_required": "",
        "license_required": "",
        "school_district": "",
        "admin": "",
        "non_admin": ""
    })

    const history = useHistory();

    const calculateDollar = (value) => {
        if (value > 0 && value <= 19) {
            return (
                <img src={Dollar} />
            )
        } else if (value > 19 && value <= 39) {
            return (
                <React.Fragment>
                    <img src={Dollar} />
                    <img src={Dollar} />
                </React.Fragment>
            )
        } else if (value > 39 && value <= 59) {
            return (
                <React.Fragment>
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                </React.Fragment>
            )
        } else if (value > 59 && value <= 79) {
            return (
                <React.Fragment>
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                </React.Fragment>
            )
        } else if (value > 79) {
            return (
                <React.Fragment>
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                </React.Fragment>
            )
        }
    }

    const calculateDayLeft = (expiryDate) => {
        const curr = new Date();
        const exp = new Date(expiryDate);
        const oneDay = 24 * 60 * 60 * 1000;

        if (curr > exp)
            return false;

        const diffDays = Math.round(Math.abs((exp - curr) / oneDay));

        return diffDays;
    }

    const calculateDayDifference = (expiryDate) => {
        const curr = new Date();
        const exp = new Date(expiryDate);
        const oneDay = 24 * 60 * 60 * 1000;

        const diffDays = Math.round(Math.abs((exp - curr) / oneDay));

        return diffDays;
    }

    const [chatWithEmployeesArray, setchatWithEmployeesArray] = useState([]);
    const chatWithEmployee = (job) => {
        const schoolName = String(job.employer_details.name.split('-')[0]).slice(0, job.employer_details.name.split('-')[0].length - 1);
        const schoolID = job.employer_details.id;
        if (schoolID.length > 0) {
            axiosInstance.get('/seeker/get_employee_of_school', {
                params: {
                    school_id: schoolID
                }
            }).then(res => {
                setchatWithEmployeesArray(res.data.data.users);
                setModal(true);
            }).catch(err => {
                console.log(err);
                setchatWithEmployeesArray([]);
                setModal(true);
            });
        } else {
            axiosInstance.get('/seeker/get_employee_of_school', {
                params: {
                    school_name: schoolName
                }
            }).then(res => {
                setchatWithEmployeesArray(res.data.data.users);
                setModal(true);
            }).catch(err => {
                console.log(err);
                setchatWithEmployeesArray([]);
                setModal(true);
            });
        }
        // setModal(true);

    }

    const [openConfirmation, setOpenConfirmation] = React.useState(false);


    const handleCloseConfirmation = () => {
        setOpenConfirmation(false);
    };

    // job alert
    const [jobAlertEmail, setjobAlertEmail] = useState('');
    const handleJobAlert = () => {
        const data = {
            // access : accessToken,
            job_title: localStorage.getItem('jobTitleSearch') ? localStorage.getItem('jobTitleSearch') : "",
            location: localStorage.getItem('locationSearch') ? localStorage.getItem('locationSearch') : "",
            salary: filtersApplied.salary_filter,
            shift_type: filtersApplied.shift_type,
            target_email: jobAlertEmail,
            is_remote: filtersApplied.is_remote,
            license_required: filtersApplied.license_required,
            phd_required: filtersApplied.phd_required,
            frequency: '',
        }

        axiosInstance.post('/seeker/job_alert/', data)
            .then(res => {
                handleCloseConfirmation();
                setAlert(true);
                setType('success');
                setDesc('Successfully Saved');
            }).catch(err => {
                handleCloseConfirmation();
                setAlert(true);
                setType('warning');
                setDesc(err.response.data.message);
            })
    }

    const JobDescription =
        <Grid container direction='column' style={{ marginTop: '1em' }}>
            <span style={{ fontSize: '22px', fontWeight: '550' }}>Full Job Description</span>
            {selectedJob.description ? selectedJob.description : 'No Description Available'}
            {/* <span style={{ marginTop: '1em', fontSize: '18px', fontWeight: '450' }}>Notice:</span>
            <span style={{ marginTop: '1em', fontSize: '16px' }}>This is a free service offered by ITTS for teachers, adventurers, and travel enthusiasts of the latest academic positions available around the world. ITTS's role is as an intermediary for recruitment/talent acquisition.</span>
            <span style={{ marginTop: '1em', fontSize: '18px', fontWeight: '450' }}>Company: <span style={{ fontWeight: '400', fontSize: '16px' }}>ACI Highschool</span></span>
            <span style={{ marginTop: '1em', fontSize: '18px', fontWeight: '450' }}>Fields of Expertise: <span style={{ fontWeight: '400', fontSize: '16px' }}>Kindergarten, Elementary, Middle, High School</span></span>
            <span style={{ marginTop: '1em', fontSize: '18px', fontWeight: '450' }}>Details: <span style={{ fontWeight: '400', fontSize: '16px' }}>Aci Schools , Istanbul ,Turkey Seeking Experienced and Qualified Teachers.</span></span>
            <br />
            <p>
                Aci Schools based in Istanbul is now accepting Kindergarten Homeroom Teachers- Preschool applicants for the 2021-2022 academic year.
                <br /><br />
                Aci Schools is a co-educational private school, from preschool to Grade 12, founded in 1998. Aci Schools is a private Turkish school with a very advanced English program and has a safe and positive learning environment for over 1900 students. Aci Schools provides a broad and balanced curriculum, which is taught in English, with a dynamic teaching staff and access to the latest in educational supplies and equipment.
                <br /><br />
                Aci Schools is also located in central areas of Istanbul, Turkey. Istanbul is a modern and progressive city that has a vibrant social environment rich in culture and history. Living conditions for foreign teachers are very comfortable and we find that foreign teachers enjoy living here.
            </p>
            <br /><br />
            <span style={{ fontSize: '18px', fontWeight: '450' }}>Aci Schools provides:</span>
            <br />
            <p>

                * increased salary according to experience <br /><br />

                * competitive and tax-free salary <br /><br />

                * round trip airfare valid for 12 months for each year<br /><br />

                * housing and utilities paid for, except telephone and cable<br /><br />

                * plane ticket<br /><br />

                * private health insurance<br /><br />

                * work permit expenses<br /><br />

                * contract for 12 months<br /><br />

                * hot lunch when school is open<br /><br />

            </p>
            <br />
            <span style={{ fontSize: '18px', fontWeight: '450' }}>Requirements:</span>
            <br />
            <p>We prefer to hire teachers with a minimum of two years teaching experience. TESOL or TEFL teaching certificate. We seek teachers who are passionate about teaching, caring, team oriented, and who add value to the life of our students and the school community.</p>
            <span style={{ marginTop: '1em', fontSize: '18px', fontWeight: '450' }}>Job Types: <span style={{ fontWeight: '400', fontSize: '16px' }}>Full-time, Contract</span></span>
            <span style={{ marginTop: '1em', fontSize: '18px', fontWeight: '450' }}>Pay: <span style={{ fontWeight: '400', fontSize: '16px' }}>Up to $1,150.00 per month</span></span>
            <span style={{ marginTop: '1em', fontSize: '18px', fontWeight: '450' }}>Schedule: </span>
            <span style={{ fontWeight: '400', fontSize: '16px' }}>8 hour shift</span>
            <span style={{ marginTop: '1em', fontSize: '18px', fontWeight: '450' }}>Language: </span>
            <span style={{ fontWeight: '400', fontSize: '16px' }}>English (Preferred)</span>
            <span style={{ marginTop: '1em', fontSize: '18px', fontWeight: '450' }}>License/Certification: </span>
            <span style={{ fontWeight: '400', fontSize: '16px' }}>Teaching Certification (Preferred)</span>
            <span style={{ marginTop: '1em', fontSize: '18px', fontWeight: '450' }}>Work Location: </span>
            <span style={{ fontWeight: '400', fontSize: '16px' }}>One location</span>
            <span style={{ marginTop: '1em', fontSize: '18px', fontWeight: '450' }}>Work Remotely: </span>
            <span style={{ fontWeight: '400', fontSize: '16px' }}>No</span> */}

        </Grid>;


    // console.log(selectedJob)

    // employer_details:
    // address: null
    // avg_graduation_rate: null
    // city: null
    // highest_grade: null
    // id: 14932
    // latitude: null
    // longitude: null
    // lowest_grade: null
    // name: "Danville Public Schools"
    // no_of_schools: null
    // no_of_teachers: null
    // percent_proficient_maths: null
    // percent_proficient_reading: null
    // phone: null
    // shape_area: null
    // shape_length: null
    // state: "Virginia"
    // student_to_teacher_ratio: null
    // website: "https://www.danvillepublicschools.org/"
    // zipcode: null

    const filledEmpDetails = <React.Fragment>
        <div style={{ marginTop: '70px', marginBottom: '50px', padding: '1rem' }}>
            <div style={{ display: 'inline-flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <h4 style={{ maxWidth: '520px' }}>{selectedJob.employer_details?.name}</h4>
            </div>
            <p style={{ maxWidth: '520px', marginTop: '10px' }}>
                {selectedJob.employer_details?.address}, {selectedJob.employer_details?.city}
            </p>
            <div style={{ display: 'inline-flex', gap: '15px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' }}>
                <a href={selectedJob.employer_details?.website} target='_' style={{ display: 'inline-flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' }}>
                    <img src={Web} /> {selectedJob.employer_details?.website}
                </a>
                <a style={{ display: 'inline-flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' }}>
                    <img src={Mail} /> jennywilson@mail.com
                </a>
                <a href={`tel:${selectedJob.employer_details?.phone}`} style={{ display: 'inline-flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' }}>
                    <img src={Phone} /> {selectedJob.employer_details?.phone}
                </a>
            </div>
            <div className='fourGridCol' style={{ marginTop: '100px' }}>
                <div className='firstColHead'>Academics</div>
                <div className='colData'>
                    <h4>{selectedJob.employer_details?.percent_proficient_reading}%</h4>
                    Percent Proficient - Reading
                </div>
                <div className='colData'>
                    <h4>{selectedJob.employer_details?.percent_proficient_maths}%</h4>
                    Percent Proficient - Math
                </div>
                <div className='colData'>
                    <h4>{selectedJob.employer_details?.avg_graduation_rate}%</h4>
                    Average Graduation Rate
                </div>
                <div className='colData'>
                    <h4>{selectedJob.employer_details?.highest_grade}</h4>
                    Highest Grade Available
                </div>
                <div className='colData'>
                    <h4>{selectedJob.employer_details?.lowest_grade}</h4>
                    Lowest Grade Available
                </div>
            </div>
            <div className='fourGridCol' style={{ marginTop: '50px' }}>
                <div className='firstColHead'>Students</div>
                <div className='colData'>
                    <h4>6,811</h4>
                    Students
                </div>
                <div className='colData'>
                    <h4>30%</h4>
                    Free or Reduced Lunch
                </div>
            </div>
            <div className='fourGridCol' style={{ marginTop: '50px' }}>
                <div className='firstColHead'>Teachers</div>
                <div className='colData'>
                    <h4>{selectedJob.employer_details?.student_to_teacher_ratio}<sub style={{ fontSize: '12px' }}>National 17:1</sub></h4>
                    Student-Teacher Ratio
                </div>
                <div className='colData'>
                    <h4>30%</h4>
                    Teachers in First/Second Year
                </div>
            </div>
            {/*<div className='graphColDiv' style={{marginTop: '50px'}}>
                            <div className='firstColHead'>Finances</div>
                            <div className='colData'>
                                <h4>$37,038 <sub style={{fontSize: '12px'}}>/ student</sub><p style={{fontSize: '12px'}}>National $12,239</p></h4>
                                Median Household Income
                            </div>
                            <div className="bardGraphContDiv">
                                <div className="bardGraphCont">
                                    <div className="barColor" style={{width: '58%'}} />
                                    <div className="barLabel">Instruction</div>
                                    <div className="barPercent">58%</div>
                                </div>
                                <div className="bardGraphCont">
                                    <div className="barColor" style={{width: '58%'}} />
                                    <div className="barLabel">Support Services</div>
                                    <div className="barPercent">58%</div>
                                </div>
                                <div className="bardGraphCont">
                                    <div className="barColor" style={{width: '58%'}} />
                                    <div className="barLabel">Other</div>
                                    <div className="barPercent">58%</div>
                                </div>
                            </div>
                        </div>
                        <div className='fourGridCol' style={{marginTop: '50px'}}>
                            <div className='firstColHead'>LIVING IN THE AREA</div>
                            <div className='colData'>
                                <h4>$37,038 <p style={{fontSize: '12px'}}>National $62,843</p></h4>
                                Median Household Income
                            </div>
                            <div className='colData'>
                                <h4>$757 <p style={{fontSize: '12px'}}>National $1,062</p></h4>
                                Median Rent
                            </div>
                            <div className='colData'>
                                <h4>$72,900 <p style={{fontSize: '12px'}}>National $217,500</p></h4>
                                Median Home Value
                            </div>
        </div>*/}
        </div>
    </React.Fragment>;

    const emptyEmpDetails = <h4>No Data Available</h4>

    const handleEmpDetails = () => {
        if (selectedJob.employer_details.name != null) {
            return filledEmpDetails
        } else {
            return emptyEmpDetails
        }

    }

    const renderSwitch = (param) => {
        switch (param) {
            case "job-description":
                return JobDescription;
            case "employer-details":
                return handleEmpDetails()

            default:
                return

        }
    }


    const get_job = async (id) => {
        jobs.map(item => {
            if (item.id === id) {
                console.log(item);
                return item;
            }
        })
    }

    const [jobCount, setjobCount] = useState(0);

    const [nextLink, setnextLink] = useState(null);
    const [prevLink, setprevLink] = useState(null);
    const [paginationPages, setpaginationPages] = useState([]);
    const [filters, setfilters] = useState({
        shift_type: [],
        salary: [],
        distance: [],
        distance: []
    });

    const [filtersApplied, setfiltersApplied] = useState({
        shift_type: "",
        salary_filter: "",
        posted_any_time_filter: "",
        is_remote: false,
        sort_by_date: false,
        distance: null,
        phd_required: false,
        license_required: false
    });

    const clearFilters = () => {
        setfiltersApplied({
            shift_type: "",
            salary_filter: "",
            posted_any_time_filter: "",
            is_remote: false,
            sort_by_date: false,
            distance: null,
            phd_required: false,
            license_required: false
        });
    }

    const addActiveClass = async (e) => {
        if (window.innerWidth > 960) {
            const jobname_id = `jobCard_${e}`;
            const elements = document.getElementsByClassName('activeJob');
            Array.from(elements).forEach(item => {
                item.classList.remove('activeJob');
            })
            const job = jobs.find(item => item.id === e)
            setselectedJob(job);
            document.getElementById(jobname_id).classList.add('activeJob');
        } else {
            history.push(`/job-detail/${e}`);
            sessionStorage.setItem('mobJobId', e);
        }


        axiosInstance.post('/district/add_job_count/', { job_id: e }).then(res => {
            return;
        }).catch(err => {
            console.log(err);
        })
    }

    const addJobClick = (e) => {
        axiosInstance.post('/district/add_job_click/', { job_id: e }).then(res => {
            return;
        }).catch(err => {
            console.log(err);
        })
    };


    const [accessToken, setaccessToken] = useState('');

    useEffect(() => {
        if (Cookies.get('access_token')) {
            setaccessToken(Cookies.get('access_token'));
        } else {
            window.location.href = '/register';
        }
    }, [])

    useEffect(() => {
        if (accessToken.length > 0) {
            getData(true);
        }

    }, [accessToken, localStorage.getItem('jobTitleSearch'), localStorage.getItem('locationSearch'), localStorage.getItem('page'), filtersApplied])


    const getData = (topOfPage) => {
        setloader(true);
        localStorage.getItem('jobTitleSearch') || localStorage.getItem('locationSearch') || localStorage.getItem('page') ? (
            axiosInstance.get('seeker/search_jobs_paginated/', {
                params: {
                    // access : accessToken,
                    keyword: localStorage.getItem('jobTitleSearch') ? localStorage.getItem('jobTitleSearch') : null,
                    location: localStorage.getItem('locationSearch') ? localStorage.getItem('locationSearch') : null,
                    page: localStorage.getItem('page') ? localStorage.getItem('page') : null,
                    job_type: filtersApplied.shift_type ? filtersApplied.shift_type : null,
                    salary_filter: filtersApplied.salary_filter ? filtersApplied.salary_filter : null,
                    posted_any_time_filter: filtersApplied.posted_any_time_filter ? filtersApplied.posted_any_time_filter : null,
                    is_remote: filtersApplied.is_remote ? filtersApplied.is_remote : null,
                    is_license_required: filtersApplied.license_required ? filtersApplied.license_required : null,
                    is_phd_required: filtersApplied.phd_required ? filtersApplied.phd_required : null
                    , sort_by_date: filtersApplied.sort_by_date ? filtersApplied.sort_by_date : null
                }, timeout: 1000 * 120
            }).then(res => {
                setjobs(res.data.results);
                setjobCount(res.data.count);

                // setting links
                const nextNumber = res.data.links.next ? res.data.links.next.split('page=')[1] : null;
                const prevNumber = res.data.links.previous ? res.data.links.previous.split('page=')[1] : null;

                setnextLink(nextNumber);
                setprevLink(prevNumber);
                setpaginationPages(res.data.links.list_of_pages);

                setloader(false);


                if (topOfPage) {
                    // back to top
                    if (window.innerWidth > 960)
                        document.getElementsByClassName('jobCard')[0].click();
                    // console.log("top", topOfPage);
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    const elem = document.getElementById('topOfJobView');
                    elem.scrollIntoView();
                }
                const job = jobs.filter(item => item.id === selectedJob.id)[0];
                console.log(job);
                if (job.id === selectedJob.id && window.innerWidth > 960) {
                    document.getElementById(`jobCard_${job.id}`).click();
                }
            }).catch(err => {
                console.log(err);
                setloader(false);

            })
        )
            : (
                axiosInstance.get('seeker/search_jobs_paginated/', {
                    timeout: 1000 * 120
                }).then(res => {
                    setjobs(res.data.results);

                    setjobCount(res.data.count);

                    // setting links
                    setnextLink(res.data.links.next);
                    setprevLink(res.data.links.previous);
                    setpaginationPages(res.data.links.list_of_pages);

                    setloader(false);

                    if (topOfPage) {
                        // back to top
                        document.body.scrollTop = 0;
                        document.documentElement.scrollTop = 0;
                        const elem = document.getElementById('topOfJobView');
                        // elem ? elem.scrollIntoView() : ;
                        if (elem)
                            elem.scrollIntoView();
                    }

                }).catch(err => {
                    console.log(err);
                    setloader(false);

                })
            )

        axiosInstance.get('seeker/search_job_filter/', {
            params: {
                keyword: localStorage.getItem('jobTitleSearch') ? localStorage.getItem('jobTitleSearch') : null,
                location: localStorage.getItem('locationSearch') ? localStorage.getItem('locationSearch') : null,
            }, timeout: 1000 * 120
        }).then(res => {
            setfilters({ ...filters, shift_type: res.data.shift_type });

        })

        

    }


    const [activePagination, setActivePagination] = useState(1)


    const handleDateFilter = () => {
        $("#dateFilter").toggleClass('dateFilterActive');
        if ($("#dateFilter").hasClass('dateFilterActive')) {
            setfiltersApplied({ ...filtersApplied, sort_by_date: true });
        } else {
            setfiltersApplied({ ...filtersApplied, sort_by_date: false });
        }
    }


    const itemsPerPage = 10;
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(jobs.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(jobs.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, jobs]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % jobs.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    const [loader, setloader] = useState(false);

    const saveJobFn = (id) => {
        setloader(true);
        const data = {
            // access : accessToken,
            job_id: id
        }
        // console.log(data);
        axiosInstance.post('/seeker/toggle_saved_job_status/', data).then(res => {
            setloader(false);
            getData(false);
            // console.log(selectedJob);

        }).catch(err => {
            alert(err);
            setloader(false);
        })
    }

    const [openFilter, setOpenFilter] = useState(false)
    const [jobTitleSearch, setjobTitleSearch] = useState(localStorage.getItem('jobTitleSearch') > 0 ? localStorage.getItem('jobTitleSearch') : '');
    const [locationSearch, setlocationSearch] = useState(localStorage.getItem('locationSearch') > 0 ? localStorage.getItem('locationSearch') : '');
    const submitQuery = () => {

        // console.log(jobTitle.value, location.value);

        localStorage.setItem('jobTitleSearch', jobTitleSearch);
        localStorage.setItem('locationSearch', locationSearch);

        history.push('/search-jobs');
    }




    // handle compare 
    const [compare, setCompare] = useState([]);
    useEffect(() => {
        localStorage.setItem('compareJobs', JSON.stringify(compare))

    }, [compare])

    console.log(JSON.parse(localStorage.getItem('compareJobs')));

    // const [open, setOpen] = useState(false);
    // const handleClose = () => setOpen(false);

    const handleCompare = singleJob => {

        console.log(singleJob);

        if (compare.length >= 2) {
            compare.pop()
            setCompare([...compare, singleJob])
            return;
        }
        if (compare.length === 0) {
            setCompare([singleJob]);
            return;
        }
        // if (compare.length === 2) {
        //     if (compare[0]?.id === singleJob.id || compare[1]?.id === singleJob.id) {
        //         setAlertMsg(true)
        //         setType('success')
        //         setDesc('Successfully added for comparison')
               
        //          return;
               
                
        //     }
            
        // }
        if (compare.length === 1) {
            if (compare[0]?.id === singleJob.id) {
                setAlertMsg(true)
                setType('success')
                setDesc('Successfully added for comparison')
               
                 return;
               
                
            }
            setCompare([...compare, singleJob]);
            return;
        }
        
    }

    const navigateToCompare = () => {
        window.location.href = '/compare-job'

    }

    return (
        <React.Fragment>
            {
                loader ?
                    <div className='' style={{ height: '120vh', display: 'grid', placeContent: 'center', width: '100vw', backgroundColor: 'rgb(255,255,255,0.6)', position: 'fixed', zIndex: '100' }}>
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: loaderJson,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice"
                                }
                            }}
                            height={200}
                            width={200}
                        />
                    </div> : null
            }
            <Helmet>
                <title>{String(jobCount)}+ Jobs | Frame Change</title>
            </Helmet>
            <Navbar />
            {alertComp ? (
                <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlert} />
            ) : null}
            <Grid style={{ padding: '3.5em 0', background: '#F5F7FA', fontSize: '16px' }} container alignItems='center' justifyContent='center' direction='column'>

                <Grid container direction='column' style={{ width: '90%' }}>

                    <Container style={{ margin: 'auto', padding: '0', marginTop: '10vh' }} >
                        <div className="mobOnlyFilter" style={{ gap: '5px', alignItems: 'center', width: '100%', marginBottom: '50px' }}>
                            <Grid item xs={12}>
                                <div className="searchBoxSmall" style={styles.searchBoxSmall}>
                                    <Grid container direction='row' spacing={2} style={{ alignItems: 'center', width: '100%', flexWrap: 'nowrap' }}>
                                        <Grid item xs={'auto'} style={{ paddingRight: '2px' }}>
                                            <img src={srch} alt="Search by Location" />
                                        </Grid>
                                        <Grid item xs={'auto'} style={{ width: '100%' }}>
                                            <div style={styles.hiddenLabel} aria-hidden="true">Search Candidate by Job Title</div>
                                            <Select
                                                // isMulti
                                                // className=''
                                                id='jobTitleID'
                                                options={jobTitlesArr}
                                                onChange={e => setjobTitleSearch(e.value)}
                                                className='stateDD'
                                                placeholder='Job Title'
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <div style={{ width: '42px', height: '42px', cursor: 'pointer' }} onClick={submitQuery}>
                                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21C42 32.598 32.598 42 21 42C9.40202 42 0 32.598 0 21Z" fill="url(#paint0_linear_3993_30332)" />
                                    <path d="M20.3333 25.6667C23.2789 25.6667 25.6667 23.2789 25.6667 20.3333C25.6667 17.3878 23.2789 15 20.3333 15C17.3878 15 15 17.3878 15 20.3333C15 23.2789 17.3878 25.6667 20.3333 25.6667Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M26.9996 26.9991L24.0996 24.0991" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <defs>
                                        <linearGradient id="paint0_linear_3993_30332" x1="1.81035" y1="-30.1304" x2="70.7503" y2="-5.34066" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#FFCF20" />
                                            <stop offset="0.932292" stop-color="#FD8012" />
                                        </linearGradient>
                                    </defs>
                                </svg>

                            </div>
                            <div onClick={() => setOpenFilter(!openFilter)} style={{ width: '42px', height: '42px', borderRadius: '50%', backgroundColor: '#E9E9E9', padding: '13.6px', display: 'flex', cursor: 'pointer' }}>
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.50033 8.17826V14.5003C6.50034 14.5855 6.52214 14.6694 6.56368 14.7438C6.60522 14.8183 6.66511 14.8809 6.73766 14.9257C6.81021 14.9705 6.89301 14.996 6.97819 14.9998C7.06337 15.0035 7.1481 14.9855 7.22433 14.9473L10.2243 13.4473C10.3073 13.4057 10.377 13.3419 10.4258 13.2629C10.4745 13.184 10.5003 13.093 10.5003 13.0003V8.17826L14.8873 2.81726C14.929 2.76644 14.9602 2.70791 14.9792 2.64503C14.9982 2.58215 15.0046 2.51613 14.9981 2.45076C14.9916 2.38539 14.9723 2.32194 14.9413 2.26403C14.9103 2.20612 14.8682 2.15489 14.8173 2.11326C14.7665 2.07163 14.708 2.04042 14.6451 2.02141C14.5822 2.00239 14.5162 1.99595 14.4508 2.00245C14.3855 2.00895 14.322 2.02827 14.2641 2.05929C14.2062 2.09031 14.155 2.13244 14.1133 2.18326L9.61333 7.68326C9.54017 7.77269 9.50024 7.88471 9.50033 8.00026V12.6913L7.50033 13.6913V8.00026C7.50043 7.88471 7.4605 7.77269 7.38733 7.68326L3.55533 3.00026H11.5003C11.6329 3.00026 11.7601 2.94758 11.8539 2.85381C11.9477 2.76004 12.0003 2.63287 12.0003 2.50026C12.0003 2.36765 11.9477 2.24047 11.8539 2.1467C11.7601 2.05294 11.6329 2.00026 11.5003 2.00026H2.50033C2.40561 2.00019 2.31282 2.02704 2.23277 2.07766C2.15271 2.12829 2.08868 2.20061 2.04813 2.28621C2.00758 2.37182 1.99218 2.46718 2.00373 2.56119C2.01527 2.65521 2.05328 2.74401 2.11333 2.81726L6.50033 8.17826Z" fill="#4A4A4A" />
                                </svg>
                            </div>
                        </div>
                        <div style={{ marginBottom: '2em' }} className={`filterCont ${openFilter ? null : 'deskOnly'}`}>

                            <Grid item xs={12} className='mobOnlyFilter'>
                                <div className="searchBoxSmall" style={styles.searchBoxSmall}>
                                    <Grid container direction='row' spacing={1} style={{ alignItems: 'center', width: '100%', flexWrap: 'nowrap' }}>
                                        <Grid item xs={'auto'} style={{ paddingRight: '2px' }}>
                                            <img src={pin} alt="Search by Location" />
                                        </Grid>
                                        <Grid item xs={'auto'} style={{ width: '100%' }}>
                                            <div style={styles.hiddenLabel} aria-hidden="true">State</div>
                                            <Select
                                                // isMulti
                                                // className=''
                                                id='locationID'
                                                options={Locoptions}
                                                onChange={e => setlocationSearch(e.value)}
                                                className='stateDD'
                                                placeholder='Search by state'
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>


                            <select value={filtersApplied.shift_type} onChange={e => setfiltersApplied({ ...filtersApplied, shift_type: e.target.value })}>
                                <option value="" disabled selected>Shift Type</option>
                                {
                                    filters.shift_type.map(item => {
                                        if (item) {
                                            return (
                                                <option value={item}>
                                                    {item}
                                                </option>
                                            )
                                        }
                                    })
                                }
                            </select>
                            <select value={filtersApplied.posted_any_time_filter} name="posted_any_time_filter" id="posted_any_time_filter" onChange={e => setfiltersApplied({ ...filtersApplied, posted_any_time_filter: e.target.value })}>
                                <option value="" disabled selected>Posted any time</option>
                                <option value="last_week">Last week</option>
                                <option value="last_two_week">Last two weeks</option>
                                <option value="last_month">Last Month</option>
                            </select>
                            <select value={filtersApplied.salary_filter} name="salary_filter" id="salary_filter" onChange={e => setfiltersApplied({ ...filtersApplied, salary_filter: e.target.value })}>
                                <option value="" disabled selected >
                                    Hourly Salary
                                </option>
                                <option value="0-19">$ ($0-$19)</option>
                                <option value="20-39">$$ ($20-$39)</option>
                                <option value="40-59">$$$ ($40-$59)</option>
                                <option value="60-79">$$$$ ($60-$79)</option>
                                <option value="80">$$$$$ (>$79)</option>
                            </select>
                            {/* <select>
                                <option value="">
                                    Distance
                                </option>
                            </select> */}
                            {/* <select>
                                <option value="">
                                    Distance
                                </option>
                            </select> */}
                            <Grid item >
                                <FormControlLabel
                                    value="start" style={{ size: '16px' }}
                                    checked={filtersApplied.is_remote} onChange={e => setfiltersApplied({ ...filtersApplied, is_remote: e.target.checked })}
                                    control={<Switch color="primary" id="remoteJobsSwitch" />}
                                    label={<span style={{ fontSize: '16px' }}>Is Remote</span>} labelPlacement="start"
                                />

                            </Grid>
                            <Grid item >
                                <FormControlLabel
                                    value="start"
                                    checked={filtersApplied.license_required} onChange={e => setfiltersApplied({ ...filtersApplied, license_required: e.target.checked })}
                                    control={<Switch color="primary" id="remoteJobsSwitch" />}
                                    label={<span style={{ fontSize: '16px' }}>License Required</span>}
                                    labelPlacement="start"
                                />

                            </Grid>
                            <Grid item >
                                <FormControlLabel
                                    value="start"
                                    checked={filtersApplied.phd_required} onChange={e => setfiltersApplied({ ...filtersApplied, phd_required: e.target.checked })}
                                    control={<Switch color="primary" id="remoteJobsSwitch" />}
                                    label={<span style={{ fontSize: '16px' }}>PHD Required</span>}
                                    labelPlacement="start"
                                />

                            </Grid>
                            <div className="clearFil" onClick={clearFilters}>Clear filters</div>
                        </div>


                        <Grid container spacing={2}>

                            <Grid container item spacing={2} lg={4} md={5} sm={12} xs={12}>
                                <Grid container direction='column' >
                                    <div className='recieveUpdatesCont' >
                                        <div className="recieveUpdatesHeading" id="topOfJobView">
                                            I want to receive the lastest job alerts based on this search result
                                        </div>
                                        <div className="recieveUpdatesSubHeading">
                                            Get notified on your email when a new job is available
                                        </div>
                                        <div className="recieveUpdateInput">
                                            <input type="email" name="" id="" value={jobAlertEmail} onChange={e => setjobAlertEmail(e.target.value)} disabled value={localStorage.getItem('email')} />
                                            <img src={Tick} alt="" style={{ "cursor": "pointer" }} onClick={() => setOpenConfirmation(true)} />
                                        </div>
                                    </div>
                                    <Box marginBottom="1em">
                                        <div style={{ display: "flex", flexDirection: 'row', alignContent: 'center' }}>
                                            <Typography style={{ margin: '0 0.5em' }}>SORT:</Typography>
                                            <button style={{ margin: '0 0.5em', borderRadius: '25px', fontSize: '14px', color: '#000', borderRadius: '25px', border: '1px solid #E4E8F1' }} className='hoverBtn'>Relevant</button>
                                            <button style={{ margin: '0 0.5em', fontSize: '14px', color: '#000', borderRadius: '25px', border: '1px solid #E4E8F1' }} className='hoverBtn' onClick={handleDateFilter} id="dateFilter">Date</button>
                                            <div className='jobsAvailable'>{jobCount} Jobs available</div>

                                        </div>
                                    </Box>



                                    <Grid id="jobs_container" container style={{ borderRadius: '10px', background: '#fff' }} >

                                        {
                                            alertMsg &&
                                        
                                         <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlertMsg} />
                                        }

                                        {
                                            currentItems &&
                                            currentItems.map(item => {
                                                return (
                                                    <Grid id={`jobCard_${item.id}`} className='jobCard' onClick={() => addActiveClass(item.id)} item lg={12} style={{ padding: '1em 5px', cursor: 'pointer' }}>
                                                        <div style={{ padding: '5px' }}>
                                                            <Grid container spacing={2}>
                                                                <Grid item lg={10}>
                                                                    <Typography className='textOverflow' style={{ fontSize: '16px', fontWeight: '600', lineHeight: '18px', maxHeight: '36px', overflow: 'hidden', position: 'relative' }}>
                                                                        {item.job_title}
                                                                    </Typography>

                                                                </Grid>
                                                                <Grid item lg={10}>
                                                                    <Typography style={{ fontSize: '14px', color: '#393939', fontWeight: '600', maxHeight: '24px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                        {item.unmatched_school_district}
                                                                    </Typography>

                                                                </Grid>
                                                                {/* <Grid item lg={10}>
                                                                    <Typography className='textOverflow' style={{ fontSize: '14px', color: '#474747', lineHeight: '16px', maxHeight: '50px', overflow: 'hidden', position: 'relative' }}>
                                                                       {item.description}
                                                                    </Typography>

                                                                </Grid> */}
                                                            </Grid>
                                                            <Grid container spacing={2}>
                                                                <Grid item container spacing={1} lg={11}>



                                                                    <Grid item lg={8}>



                                                                    </Grid>

                                                                </Grid>
                                                                <Grid item lg={1} onClick={() => saveJobFn(item.id)}>
                                                                    {
                                                                        item.is_saved_job === 1 ?
                                                                            <FaHeart style={{ color: 'orange' }} size='1.5em' /> :
                                                                            <FaRegHeart size='1.5em' />
                                                                    }
                                                                </Grid>
                                                            </Grid>

                                                            <br />
                                                            <Grid container lg={11} justifyContent="space-between">
                                                                {
                                                                    item.date_posted ? (
                                                                        <Grid item lg={4}>
                                                                            <Typography style={{ fontSize: "14px", color: '#636363' }}>{Math.floor(calculateDayDifference(item.date_posted) / 7)} weeks ago</Typography>
                                                                        </Grid>
                                                                    ) : null
                                                                }
                                                                <Grid item lg={3}>

                                                                    <img src={GreenDesiredJob} />
                                                                </Grid>

                                                            </Grid>

                                                        </div>

                                                    </Grid>
                                                )
                                            })
                                        }

                                        {
                                            currentItems && currentItems.length == 0 ? <div className="col-12 d-flex justify-content-center pt-2"><h5>No Job Available</h5></div> : null
                                        }


                                    </Grid>

                                    <div className="paginationCOnt">
                                        <div className={`paginationCircle ${!prevLink ? 'noDispPagi' : null}`} onClick={() => {
                                            setActivePagination(activePagination - 1);
                                            localStorage.setItem('page', prevLink);
                                        }
                                        } ><img src={ChevRight} alt="" style={{ transform: 'rotate(180deg)' }} /></div>

                                        {
                                            paginationPages.map(item => {
                                                return (
                                                    <div className={`paginationCircle ${activePagination === item ? 'activePagi' : null}`} onClick={() => {
                                                        setActivePagination(item);
                                                        localStorage.setItem('page', item);
                                                    }} >{item}</div>
                                                )
                                            })
                                        }

                                        {/* <div className={`paginationCircle ${activePagination === 1 ? 'activePagi' : null}`} onClick={() => setActivePagination(1)} >1</div>
                                        <div className={`paginationCircle ${activePagination === 2 ? 'activePagi' : null}`} onClick={() => setActivePagination(2)} >2</div>
                                        <div className={`paginationCircle ${activePagination === 3 ? 'activePagi' : null}`} onClick={() => setActivePagination(3)} >3</div>
                                        <div className={`paginationCircle ${activePagination === 4 ? 'activePagi' : null}`} onClick={() => setActivePagination(4)} >4</div> */}
                                        <div className={`paginationCircle ${!nextLink ? 'noDispPagi' : null}`} onClick={() => {
                                            setActivePagination(activePagination + 1);
                                            localStorage.setItem('page', nextLink);
                                        }
                                        } ><img src={ChevRight} alt="" /></div>
                                    </div>

                                    {/* <React.Fragment>
                                    <div  className="mt-2">
                                        <ReactPaginate
                                            className='pagination'
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={1}
                                            pageCount={pageCount}
                                            previousLabel="<"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                    </React.Fragment> */}
                                    <div className='recieveUpdatesCont' onClick={() => setLoginModal(true)}>
                                        <div className="recieveUpdatesHeading">
                                            Did we miss a job post?
                                        </div>
                                        <div className="recieveUpdatesSubHeading">
                                            Share a link of a job post that’s missing. This helps us provide you with better search results.
                                        </div>
                                        <button className="missingC2A">I have a missing job post link</button>
                                    </div>


                                </Grid>
                            </Grid>
                            <Grid container item lg={8} md={7} sm={12} xs={12} className='deskOnly scrollThingy' style={{ display: 'block', position: 'sticky', top: '10vh', height: '88vh' }}>


                                {/* compare area  */}

                                <Grid container>

                                    <Grid container
                                        style={{ borderRadius: '15px', marginBottom: "20px", backgroundColor: "white", border: '1px solid #E4E8F1', color: "black" }}>
                                        <Grid item lg={5}
                                            style={{ borderRight: "1px solid #E4E8F1", padding: "10px 10px" }}
                                        >
                                            {
                                                compare.length > 0 ? <Grid container
                                                    style={{ margin: "20px auto" }}>
                                                    <Grid item
                                                        container
                                                        display="flex"
                                                        direction="row"
                                                        justify="space-between"
                                                        alignItems="center"
                                                    >

                                                        <Grid item xs={11}>


                                                            <Grid item><p style={{ fontWeight: "bold", wordWrap: "break-word", fontSize: "", marginBottom: "0" }}>{compare[0].job_title}</p></Grid>
                                                            <Grid item><p style={{ fontSize: "0.9em", marginBottom: "0" }}>{compare[0].location}, {compare[0].state}</p></Grid>


                                                        </Grid>
                                                        <Grid item xs={1}><svg onClick={() => {
                                                            const newCompare = compare.filter(item => item.id != compare[0].id);
                                                            setCompare(newCompare);

                                                        }}
                                                            width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="27" height="27" rx="13.5" fill="#F5F5F5" />
                                                            <path d="M10.0156 10.2002L16.6153 16.7999" stroke="#0A1931" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M10.0156 16.7998L16.6153 10.2001" stroke="#0A1931" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>

                                                        </Grid>


                                                        <Grid item>
                                                            <p style={{ background: "rgba(238, 240, 245, 0.8)", borderRadius: "5px", marginBottom: "0", marginTop: "20px", padding: "5px" }}>{compare[0].location}</p>
                                                        </Grid>
                                                    </Grid>

                                                </Grid> : <div style={{ position: "relative" }}>
                                                    <img style={{ minHeight: "90px", height: "100%" }} src={comparePic} alt="" />
                                                    <p style={{ position: "absolute", left: "50%", top: "50%", transform: " translate(-50%, -50%)" }}>*Add job to compare*</p>
                                                </div>
                                            }
                                        </Grid>
                                        <Grid item lg={5}
                                            style={{ borderRight: "1px solid #E4E8F1", padding: "10px 10px" }}
                                        >
                                            {
                                                compare.length > 1 ? <Grid container
                                                    style={{ margin: "20px auto" }}>
                                                    <Grid item
                                                        container
                                                        display="flex"
                                                        direction="row"
                                                        justify="space-between"
                                                        alignItems="center"
                                                    >

                                                        <Grid item xs={11}>
                                                            <Grid ><p style={{ fontWeight: "bold", fontSize: "", wordWrap: "break-word", marginBottom: "0" }}>
                                                                {compare[1].job_title}</p></Grid>
                                                            <Grid item ><p style={{ fontSize: "0.9em", marginBottom: "0" }}>{compare[1].employer_details.address}</p></Grid>
                                                        </Grid>
                                                        <Grid item xs={1}><svg
                                                            onClick={() => {
                                                                const newCompare = compare.filter(item => item.id != compare[1].id);
                                                                setCompare(newCompare);

                                                            }}
                                                            width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="27" height="27" rx="13.5" fill="#F5F5F5" />
                                                            <path d="M10.0156 10.2002L16.6153 16.7999" stroke="#0A1931" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M10.0156 16.7998L16.6153 10.2001" stroke="#0A1931" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>

                                                        </Grid>


                                                        <Grid item>
                                                            <p style={{ background: "rgba(238, 240, 245, 0.8)", borderRadius: "5px", marginBottom: "0", marginTop: "20px", padding: "5px" }}>{compare[1].location}</p>
                                                        </Grid>
                                                    </Grid>

                                                </Grid> : <div style={{ position: "relative" }}>
                                                    <img style={{ minHeight: "90px", height: "100%" }} src={comparePic} alt="" />
                                                    <p style={{ position: "absolute", left: "50%", top: "50%", transform: " translate(-50%, -50%)" }}>*Add one more job
                                                        listing to compare*</p>
                                                </div>
                                            }
                                        </Grid>
                                        <Grid item container lg={2}
                                            display="flex"
                                            // direction="row"
                                            justify="center"
                                            alignItems="center"
                                        >
                                            <Button onClick={navigateToCompare} style={{ background: "#EEEEEE", borderRadius: "28px", padding: "7px 20px" }}> Compare</Button>
                                        </Grid>

                                    </Grid>
                                    <Grid container direction='column' style={{ padding: '2em 1em', color: '#fff', borderRadius: '15px 15px 0 0', position: 'relative', background: 'linear-gradient(109.78deg, #2C8C63 -15.8%, #1F838E 120.54%)' }}>
                                        <img src={DesiredJob} alt='Desired job' style={{ position: 'absolute', top: '10px', right: '15px' }} />
                                        <span style={{ fontWeight: '450', color: '#FFF' }}>{selectedJob.employer_details?.name}</span>
                                        <span style={{ marginTop: '5px', fontWeight: '200', fontSize: '14px', color: '#FFF' }}>{selectedJob.unmatched_school_district}</span>
                                        <span style={{ marginTop: '5px', lineHeight: '1.2', fontWeight: '600', color: '#FFF', fontSize: '33px' }}>{selectedJob.job_title}</span>
                                        <Grid container spacing={1} style={{ marginTop: '2.5em', display: 'flex', alignItems: 'center', direction: 'row' }}>

                                            {
                                                (selectedJob.state || selectedJob.city) ? (
                                                    <Grid item>
                                                        <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px 0 0', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >

                                                            <img src={stateflags[selectedJob.state]} style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '15px' }} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >{selectedJob.city} {selectedJob.state}</button>
                                                        </div>

                                                    </Grid>
                                                ) : null
                                            }
                                            <Grid item>

                                                {
                                                    selectedJob.license_required ? (
                                                        <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >

                                                            <img src={Teacher} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} > Teacher’s license  Required</button>
                                                        </div>
                                                    ) : null
                                                }
                                            </Grid>

                                            <Grid item>
                                                {
                                                    selectedJob.salary_from ? (
                                                        <LightTooltip title={`${selectedJob.salary_range}`} placement="top" arrow>
                                                            <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                                {calculateDollar(selectedJob.salary_from)}
                                                                <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Salary Estimate</button>
                                                            </div>
                                                        </LightTooltip>
                                                    ) : null
                                                }
                                            </Grid>
                                            <Grid item>

                                                {
                                                    selectedJob.phd_required ? (
                                                        <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >

                                                            <img src={Phd} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >    PHD Required</button>
                                                        </div>
                                                    ) : null
                                                }
                                            </Grid>
                                            {
                                                selectedJob.shift_type ? (
                                                    <Grid item>
                                                        <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >

                                                            <img src={FullTime} />
                                                            <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >{selectedJob.shift_type}</button>
                                                        </div>

                                                    </Grid>
                                                ) : null
                                            }
                                        </Grid>

                                        <Grid container justifyContent='space-between' >
                                            <Grid container direction='column' style={{ marginTop: '1em' }} item lg={6} >
                                                {
                                                    selectedJob.date_created ? (
                                                        <span style={{ fontSize: '14px', color: '#FFF', marginTop: '25px' }}>Start Date: <span style={{ fontWeight: '400' }}>{selectedJob.date_created}</span></span>
                                                    ) : null
                                                }
                                                {
                                                    selectedJob.expiry_date ? (
                                                        <span style={{ fontSize: '14px', color: '#FFF', marginTop: '10px' }}>Job expiry: <span style={{ fontWeight: '400' }}>{selectedJob.expiry_date}
                                                            {
                                                                calculateDayLeft(selectedJob.expiry_date) ? (
                                                                    <React.Fragment> ({calculateDayLeft(selectedJob.expiry_date)} days left apply now)</React.Fragment>
                                                                ) : <span style={{ fontWeight: 'bold' }}> Expired!</span>
                                                            }
                                                        </span>
                                                        </span>
                                                    ) : null
                                                }
                                            </Grid>

                                            <Grid container justifyContent={width < 1280 ? 'center' : 'flex-end'} style={{ marginTop: '1em' }} alignItems='center' direction='row' item lg={6} >
                                                <span
                                                    onClick={() => handleCompare(selectedJob)}
                                                    style={{ marginRight: '15px', cursor: "pointer" }}><span style={{ fontWeight: "800", fontSize: "1.3rem" }}><AiOutlinePlus /></span> Compare</span>
                                                {
                                                    selectedJob.is_saved_job ?
                                                        <FaHeart style={{ color: 'orange' }} size='1.5em' onClick={() => saveJobFn(selectedJob.id)} /> :
                                                        <FaRegHeart size='1.5em' onClick={() => saveJobFn(selectedJob.id)} />
                                                }

                                                <span style={{ marginLeft: '5px' }}>Save Job</span>

                                                <a href={selectedJob.apply_url} target="_" style={{ color: 'unset', textDecoration: 'none' }}>
                                                    <button onClick={() => addJobClick(selectedJob.id)} style={{ color: '#000', background: '#FFF', padding: '0.5em 1em', fontSize: '19px', borderRadius: '25px', border: '0px', marginLeft: '15px', fontWeight: '600' }}>
                                                        Apply now
                                                    </button>
                                                </a>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid container direction='column' style={{ padding: '2em 1em', color: '#000', borderRadius: '0 0 15px 15px', position: 'relative', background: '#fff' }}>
                                        <Grid container spacing={1}>
                                            <Grid container direction='column' item lg={6} md={6} sm={6} xs={12}>
                                                <span style={{ fontWeight: '500', fontSize: '19px' }}>Learn more about the employer</span>
                                                <span style={{ fontWeight: '400', fontSize: '14px' }}>Chat with people who already worked at this school and get to know your future job</span>

                                            </Grid>

                                            <Grid container spacing={1} direction='row' item lg={6} md={6} sm={6} xs={12}>
                                                <Grid item lg={6} onClick={() => chatWithEmployee(selectedJob)}>
                                                    <div style={{ border: '1px solid #E4E8F1', padding: '0 5px', background: '#FFF', height: '62px', borderRadius: '25px', display: 'flex', alignItems: 'center' }} >

                                                        <img src={Profile} />
                                                        <button style={{ background: '#FFF', fontWeight: '600', textAlign: 'start', border: 'none', fontSize: '10px' }} >Chat with employees of this school </button>
                                                    </div>

                                                </Grid>
                                                <Grid item lg={6} onClick={() => setLoginModal(true)}>
                                                    <div style={{ border: '1px solid #E4E8F1', padding: '0 5px', background: '#FFF', height: '62px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >

                                                        <img src={School} />
                                                        <button style={{ background: '#FFF', fontWeight: '600', textAlign: 'start', border: 'none', fontSize: '10px' }} >Chat with Hiring department of this school </button>
                                                    </div>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction='column' style={{ margin: '3em 0' }}>
                                            <Tabs
                                                variant="scrollable"
                                                scrollButtons
                                                allowScrollButtonsMobile
                                                value={tabValue}
                                                onChange={(event, newVal) => { setTabValue(newVal); }}
                                                textColor="inherit"
                                                TabIndicatorProps={{ style: { background: 'orange' } }}
                                                aria-label="secondary tabs example"
                                            >
                                                <Tab style={{ textTransform: 'none' }} value="job-description" label="Job Description" />
                                                {/* <Tab style={{ textTransform: 'none' }} value="employer-details" label="Employer Details" /> */}
                                            </Tabs>
                                            {renderSwitch(tabValue)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid >
            <Footer />

            <Dialog
                open={openConfirmation}
                onClose={handleCloseConfirmation}
            >

                <DialogContent >
                    <Grid container justifyContent='row' spacing={1} >
                        <Grid item lg={2} md={3} sm={3} xs={3} >
                        </Grid>
                        <Grid container direction='column' item lg={10} md={9} sm={9} xs={9} >
                            <span style={{ fontWeight: '600', fontSize: '19px' }}>Save this Job ALert?</span>

                            <span style={{ fontSize: '14px', marginTop: '1em' }}>Are you sure you want to save this set of Job Alert?</span>

                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ paddingBottom: '1.5em' }}>
                    <Button style={{ textTransform: 'none', border: '1px solid #808080', borderRadius: '18px', padding: '0.5em 1em', color: '#808080' }} onClick={handleCloseConfirmation}>Cancel</Button>
                    <Button onClick={handleJobAlert} style={{ textTransform: 'none', background: 'linear-gradient(275.34deg, #25877A -10.41%, #25877A -10.4%, #25877A 102.29%)', borderRadius: '18px', padding: '0.5em 1em', color: '#fff' }} autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Modal
                open={modal}
                onClose={() => setModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modalCont' style={{ width: '1000px' }}><JobModal chatWithEmployeesArray={chatWithEmployeesArray} closeModal={() => setModal(false)} /></div>
            </Modal>
            <Modal
                open={loginModal}
                onClose={() => { setLoginModal(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modalCont' style={{ width: 'fit-content' }}>
                    <img src={MaintainenceGuy} alt="" />
                    <div className='loginModalHead'>This action is not available yet...</div>
                    <div className='loginModalSubHead'>We are woking on it, please come back later.</div>
                    <button className='loginModalBtn' onClick={() => setLoginModal(false)}>Okay</button>
                </div>
            </Modal>
        </React.Fragment>
    )
}


const calculateDayLeft = (expiryDate) => {
    const curr = new Date();
    const exp = new Date(expiryDate);
    const oneDay = 24 * 60 * 60 * 1000;

    if (curr > exp)
        return false;

    const diffDays = Math.round(Math.abs((exp - curr) / oneDay));

    return diffDays;
}

const calculateDayDifference = (expiryDate) => {
    const curr = new Date();
    const exp = new Date(expiryDate);
    const oneDay = 24 * 60 * 60 * 1000;

    const diffDays = Math.round(Math.abs((exp - curr) / oneDay));

    return diffDays;
}


const calculateDollar = (value) => {
    if (value > 0 && value <= 19) {
        return (
            <img src={Dollar} />
        )
    } else if (value > 19 && value <= 39) {
        return (
            <React.Fragment>
                <img src={Dollar} />
                <img src={Dollar} />
            </React.Fragment>
        )
    } else if (value > 39 && value <= 59) {
        return (
            <React.Fragment>
                <img src={Dollar} />
                <img src={Dollar} />
                <img src={Dollar} />
            </React.Fragment>
        )
    } else if (value > 59 && value <= 79) {
        return (
            <React.Fragment>
                <img src={Dollar} />
                <img src={Dollar} />
                <img src={Dollar} />
                <img src={Dollar} />
            </React.Fragment>
        )
    } else if (value > 79) {
        return (
            <React.Fragment>
                <img src={Dollar} />
                <img src={Dollar} />
                <img src={Dollar} />
                <img src={Dollar} />
                <img src={Dollar} />
            </React.Fragment>
        )
    }
}

export default SavedJobs;
export { calculateDayLeft, calculateDayDifference, calculateDollar };

const JobModal = (props) => {

    const scrollRight = () => {
        var carousel = document.getElementById('Carousel')
        carousel.scrollLeft = carousel.scrollLeft + 500
    }
    const scrollLeft = () => {
        var carousel = document.getElementById('Carousel')
        carousel.scrollLeft = carousel.scrollLeft - 500
    }

    const chatWithEmployeesArray = props.chatWithEmployeesArray;

    return (
        <div style={{ position: 'relative' }}>
            <div className='ModalHeading'>Learn about the school district’s work culture</div>
            <div className='ModalDescription'>Talk to the employees or ex-employees  and network to understand more about your future job</div>
            <img src={CloseIcon} alt="" className='modalClose' onClick={props.closeModal} />
            {
                chatWithEmployeesArray.length ? (
                    <>
                        <div className="chevronCont">
                            <img src={LeftChevron} alt="" onClick={scrollLeft} />
                            <img src={RightChevron} alt="" onClick={scrollRight} />
                        </div>
                        <div className="modalOF" id='Carousel'>
                            <div className="carouselCont" >
                                {
                                    chatWithEmployeesArray.map(item => {
                                        return (<CarouselCard cardData={item} />)
                                    })
                                }
                            </div>
                        </div>
                    </>
                ) : <div className='d-flex justify-content-center' style={{ fontSize: '20px' }}><b>No Employees</b></div>
            }
        </div>
    )
}

const CarouselCard = (props) => {
    const data = props.cardData;
    console.log(data);
    return (
        <div className='carouselCardCont'>
            <div className="cardProfile">
                <img src="//unsplash.it/80/80" alt="" />
                <div>
                    <div className="profileName">{data.title}</div>
                    <div className="profileDesc">{data.desc}</div>
                    <div className="profileDesc">{data.city}, {data.state}</div>
                </div>
            </div>
            <div>BADGES</div>
            <div style={{ margin: '20px 0' }}>
                <img src={Badge1} alt="" style={{ marginRight: '13px' }} />
                {
                    data.certificate ?
                        <img src={Badge2} alt="" /> : null
                }
            </div>
            <div>
                <div>WORK EXPERIENCE</div>
                <div style={{ margin: '12px 0' }}><b>{data.work_experience[0].position}</b></div>
                <div>{data.work_experience[0].school}</div>
                <div>{data.work_experience[0].start_date} - {data.work_experience[0].end_date ? data.work_experience[0].end_date : '-'} ({data.work_experience[0].years} year experience)</div>
            </div>
            <div style={{ margin: '20px 0' }}><b>You can message Jenny regarding this school and learn about her experience working there</b></div>
            <button className="cardC2a">
                <img src={Q} alt="" />
                Ask Questions
            </button>
        </div>


    )
}

const jobTitlesArr = [
    { value: 'Athletic Coach', label: 'Athletic Coach' },
    { value: 'Athletic Coordinator', label: 'Athletic Coordinator' },
    { value: 'Athletic Director', label: 'Athletic Director' },
    { value: 'Secretary to Director of Athletics', label: 'Secretary to Director of Athletics' },
    { value: 'Administrative Assistant To Director Of Facilities', label: 'Administrative Assistant To Director Of Facilities' },
    { value: 'Administrative Assistant To The Safety And Security Director', label: 'Administrative Assistant To The Safety And Security Director' },
    { value: 'Asst. Superintendent-Building And Grounds', label: 'Asst. Superintendent-Building And Grounds' },
    { value: 'Buildings And Grounds Administrative Staff', label: 'Buildings And Grounds Administrative Staff' },
    { value: 'Buildings And Grounds Coordinator', label: 'Buildings And Grounds Coordinator' },
    { value: 'Buildings And Grounds Director', label: 'Buildings And Grounds Director' },
    { value: 'Capital Projects', label: 'Capital Projects' },
    { value: 'Capital Projects Coordinator', label: 'Capital Projects Coordinator' },
    { value: 'Capital Projects Director', label: 'Capital Projects Director' },
    { value: 'Custodian', label: 'Custodian' },
    { value: 'Facilities And Maintenance', label: 'Facilities And Maintenance' },
    { value: 'Facilities And Maintenance Coordinator', label: 'Facilities And Maintenance Coordinator' },
    { value: 'Facilities And Maintenance Director', label: 'Facilities And Maintenance Director' },
    { value: 'Maintenance Secretary', label: 'Maintenance Secretary' },
    { value: 'Planning and Construction', label: 'Planning and Construction' },
    { value: 'Planning and Construction Coordinator', label: 'Planning and Construction Coordinator' },
    { value: 'Planning And Construction Director', label: 'Planning And Construction Director' },
    { value: 'Safety And Security', label: 'Safety And Security' },
    { value: 'Safety And Security Coordinator', label: 'Safety And Security Coordinator' },
    { value: 'Safety And Security Director', label: 'Safety And Security Director' },
    { value: 'Warehouse Coordinator', label: 'Warehouse Coordinator' },
    { value: 'Warehouse Services Director', label: 'Warehouse Services Director' },
    { value: 'Assistant to the Business Administrator', label: 'Assistant to the Business Administrator' },
    { value: 'Assistant To The Business Director', label: 'Assistant To The Business Director' },
    { value: 'Assistant to the Director of Operations', label: 'Assistant to the Director of Operations' },
    { value: 'Asst. Superintendent-Business and Finance', label: 'Asst. Superintendent-Business and Finance' },
    { value: 'Attorney / Legal Services', label: 'Attorney / Legal Services' },
    { value: 'Budget and Finance', label: 'Budget and Finance' },
    { value: 'Business Coordinator', label: 'Business Coordinator' },
    { value: 'Business Director', label: 'Business Director' },
    { value: 'Finance and Accounting', label: 'Finance and Accounting' },
    { value: 'Finance and Accounting Coordinator', label: 'Finance and Accounting Coordinator' },
    { value: 'Finance and Accounting Director', label: 'Finance and Accounting Director' },
    { value: 'Purchasing Administrative Staff', label: 'Purchasing Administrative Staff' },
    { value: 'Purchasing Agent', label: 'Purchasing Agent' },
    { value: 'Purchasing Coordinator', label: 'Purchasing Coordinator' },
    { value: 'Purchasing Director', label: 'Purchasing Director' },
    { value: 'Academic Administrative Assistant', label: 'Academic Administrative Assistant' },
    { value: 'Academic Coordinator', label: 'Academic Coordinator' },
    { value: 'Academic Director', label: 'Academic Director' },
    { value: 'Administrative Assistant - Early Childhood Education', label: 'Administrative Assistant - Early Childhood Education' },
    { value: 'Administrative Assistant - Teaching And Learning', label: 'Administrative Assistant - Teaching And Learning' },
    { value: 'Administrative Assistant Curriculum and Instruction', label: 'Administrative Assistant Curriculum and Instruction' },
    { value: 'Administrative Assistant To The Director Of Instruction / Curriculum', label: 'Administrative Assistant To The Director Of Instruction / Curriculum' },
    { value: 'Adult and Continuing Education', label: 'Adult and Continuing Education' },
    { value: 'Adult and Continuing Education Coordinator', label: 'Adult and Continuing Education Coordinator' },
    { value: 'Adult and Continuing Education Director', label: 'Adult and Continuing Education Director' },
    { value: 'Agricultural Education', label: 'Agricultural Education' },
    { value: 'Agricultural Education Coordinator', label: 'Agricultural Education Coordinator' },
    { value: 'Agricultural Education Director', label: 'Agricultural Education Director' },
    { value: 'Alternative Education', label: 'Alternative Education' },
    { value: 'Alternative Education Coordinator', label: 'Alternative Education Coordinator' },
    { value: 'Alternative Education Director', label: 'Alternative Education Director' },
    { value: 'American Sign Language Coordinator', label: 'American Sign Language Coordinator' },
    { value: 'Assessment and Accountability', label: 'Assessment and Accountability' },
    { value: 'Assessment and Accountability Coordinator', label: 'Assessment and Accountability Coordinator' },
    { value: 'Assessment and Accountability Director', label: 'Assessment and Accountability Director' },
    { value: 'Asst. Superintendent-Elementary Education', label: 'Asst. Superintendent-Elementary Education' },
    { value: 'Asst. Superintendent-Instruction / Curriculum', label: 'Asst. Superintendent-Instruction / Curriculum' },
    { value: 'Asst. Superintendent-Secondary Education', label: 'Asst. Superintendent-Secondary Education' },
    { value: 'Bilingual Education', label: 'Bilingual Education' },
    { value: 'Bilingual Education Coordinator', label: 'Bilingual Education Coordinator' },
    { value: 'Bilingual Education Director', label: 'Bilingual Education Director' },
    { value: 'Career And Technical Education', label: 'Career And Technical Education' },
    { value: 'Career And Technical Education Coordinator', label: 'Career And Technical Education Coordinator' },
    { value: 'Career and Technical Education Curriculum', label: 'Career and Technical Education Curriculum' },
    { value: 'Career And Technical Education Director', label: 'Career And Technical Education Director' },
    { value: 'Character Counselor', label: 'Character Counselor' },
    { value: 'College Readiness', label: 'College Readiness' },
    { value: 'College Readiness Coordinator', label: 'College Readiness Coordinator' },
    { value: 'College Readiness Director', label: 'College Readiness Director' },
    { value: 'Common Core Curriculum', label: 'Common Core Curriculum' },
    { value: 'Common Core Curriculum Coordinator', label: 'Common Core Curriculum Coordinator' },
    { value: 'Common Core Curriculum Director', label: 'Common Core Curriculum Director' },
    { value: 'Curriculum-Elementary', label: 'Curriculum-Elementary' },
    { value: 'Curriculum-Elementary Coordinator', label: 'Curriculum-Elementary Coordinator' },
    { value: 'Curriculum-K-12', label: 'Curriculum-K-12' },
    { value: 'Curriculum-Secondary', label: 'Curriculum-Secondary' },
    { value: 'Curriculum-Secondary Coordinator', label: 'Curriculum-Secondary Coordinator' },
    { value: 'Digital Content & Distance Learning', label: 'Digital Content & Distance Learning' },
    { value: 'Digital Content & Distance Learning Coordinator', label: 'Digital Content & Distance Learning Coordinator' },
    { value: 'Digital Content & Distance Learning Director', label: 'Digital Content & Distance Learning Director' },
    { value: 'District Reading Coordinator', label: 'District Reading Coordinator' },
    { value: 'Early Childhood Education', label: 'Early Childhood Education' },
    { value: 'Early Childhood Education Coordinator', label: 'Early Childhood Education Coordinator' },
    { value: 'Early Childhood Education Curriculum', label: 'Early Childhood Education Curriculum' },
    { value: 'Early Childhood Education Director', label: 'Early Childhood Education Director' },
    { value: 'Education Specialist', label: 'Education Specialist' },
    { value: 'Elementary Education Coordinator', label: 'Elementary Education Coordinator' },
    { value: 'Elementary Education Director', label: 'Elementary Education Director' },
    { value: 'English / Lang Arts', label: 'English / Lang Arts' },
    { value: 'English / Lang Arts Coordinator', label: 'English / Lang Arts Coordinator' },
    { value: 'Fine And Performing Arts', label: 'Fine And Performing Arts' },
    { value: 'Fine And Performing Arts Coordinator', label: 'Fine And Performing Arts Coordinator' },
    { value: 'Fine And Performing Arts Director', label: 'Fine And Performing Arts Director' },
    { value: 'Benefits Coordinator', label: 'Benefits Coordinator' },
    { value: 'Fine Arts Curriculum', label: 'Fine Arts Curriculum' },
    { value: 'Fine Arts Curriculum Coordinator', label: 'Fine Arts Curriculum Coordinator' },
    { value: 'Fine Arts Curriculum Director', label: 'Fine Arts Curriculum Director' },
    { value: 'Foreign Language', label: 'Foreign Language' },
    { value: 'Foreign Languages Curriculum Coordinator', label: 'Foreign Languages Curriculum Coordinator' },
    { value: 'Foreign Languages Curriculum Director', label: 'Foreign Languages Curriculum Director' },
    { value: 'Gifted & Talented Curriculum', label: 'Gifted & Talented Curriculum' },
    { value: 'Gifted and Talented Curriculum Coordinator', label: 'Gifted and Talented Curriculum Coordinator' },
    { value: 'Gifted And Talented Dept Chairperson', label: 'Gifted And Talented Dept Chairperson' },
    { value: 'Head Start', label: 'Head Start' },
    { value: 'Head Start Coordinator', label: 'Head Start Coordinator' },
    { value: 'Head Start Director', label: 'Head Start Director' },
    { value: 'Innovative Programs Curriculum', label: 'Innovative Programs Curriculum' },
    { value: 'Innovative Programs Curriculum Coordinator', label: 'Innovative Programs Curriculum Coordinator' },
    { value: 'Innovative Programs Curriculum Director', label: 'Innovative Programs Curriculum Director' },
    { value: 'Instruction / Curriculum', label: 'Instruction / Curriculum' },
    { value: 'Instruction / Curriculum Coach', label: 'Instruction / Curriculum Coach' },
    { value: 'Instruction / Curriculum Coordinator', label: 'Instruction / Curriculum Coordinator' },
    { value: 'Instruction / Curriculum Director', label: 'Instruction / Curriculum Director' },
    { value: 'K-12 Education Coordinator', label: 'K-12 Education Coordinator' },
    { value: 'K-12 Education Director', label: 'K-12 Education Director' },
    { value: 'Language Arts / English Director, K-12', label: 'Language Arts / English Director, K-12' },
    { value: 'Language Arts / English, K-12', label: 'Language Arts / English, K-12' },
    { value: 'Literacy Curriculum', label: 'Literacy Curriculum' },
    { value: 'Literacy Curriculum Coordinator', label: 'Literacy Curriculum Coordinator' },
    { value: 'Literacy Curriculum Director', label: 'Literacy Curriculum Director' },
    { value: 'Mathematics Director, K-12', label: 'Mathematics Director, K-12' },
    { value: 'Mathematics, K-12', label: 'Mathematics, K-12' },
    { value: 'Mathematics, K-12 Coordinator', label: 'Mathematics, K-12 Coordinator' },
    { value: 'Multicultural Education', label: 'Multicultural Education' },
    { value: 'Multicultural Education Coordinator', label: 'Multicultural Education Coordinator' },
    { value: 'Multicultural Education Director', label: 'Multicultural Education Director' },
    { value: 'Music', label: 'Music' },
    { value: 'Music Department Chairperson', label: 'Music Department Chairperson' },
    { value: 'Music Department Coordinator', label: 'Music Department Coordinator' },
    { value: 'Outdoor Education', label: 'Outdoor Education' },
    { value: 'Outdoor Education Coordinator', label: 'Outdoor Education Coordinator' },
    { value: 'Outdoor Education Director', label: 'Outdoor Education Director' },
    { value: 'Reading', label: 'Reading' },
    { value: 'Reading Intervention Director', label: 'Reading Intervention Director' },
    { value: 'Reading Interventionist', label: 'Reading Interventionist' },
    { value: 'Science Coordinator', label: 'Science Coordinator' },
    { value: 'Science Department', label: 'Science Department' },
    { value: 'Science Department Chairperson', label: 'Science Department Chairperson' },
    { value: 'Science, K-12', label: 'Science, K-12' },
    { value: 'Secondary Education Director', label: 'Secondary Education Director' },
    { value: 'Social Studies', label: 'Social Studies' },
    { value: 'Social Studies Coordinator', label: 'Social Studies Coordinator' },
    { value: 'Social Studies Department Chairperson', label: 'Social Studies Department Chairperson' },
    { value: 'Stem Curriculum', label: 'Stem Curriculum' },
    { value: 'Stem Curriculum Coordinator', label: 'Stem Curriculum Coordinator' },
    { value: 'Stem Director', label: 'Stem Director' },
    { value: 'Teaching And Learning', label: 'Teaching And Learning' },
    { value: 'Teaching And Learning Coordinator', label: 'Teaching And Learning Coordinator' },
    { value: 'Teaching And Learning Director', label: 'Teaching And Learning Director' },
    { value: 'Textbook', label: 'Textbook' },
    { value: 'Textbook Coordinator', label: 'Textbook Coordinator' },
    { value: 'Textbook Director', label: 'Textbook Director' },
    { value: 'Vocational Education', label: 'Vocational Education' },
    { value: 'Vocational Education Coordinator', label: 'Vocational Education Coordinator' },
    { value: 'Vocational Education Director', label: 'Vocational Education Director' },
    { value: 'Asst. Superintendent', label: 'Asst. Superintendent' },
    { value: 'Asst. Superintendent-Administration', label: 'Asst. Superintendent-Administration' },
    { value: 'Asst. Superintendent-Human Resources', label: 'Asst. Superintendent-Human Resources' },
    { value: 'Asst. Superintendent-Student Services', label: 'Asst. Superintendent-Student Services' },
    { value: 'Board Clerk', label: 'Board Clerk' },
    { value: 'Board Member', label: 'Board Member' },
    { value: 'Board President', label: 'Board President' },
    { value: 'Board Secretary', label: 'Board Secretary' },
    { value: 'Board Treasurer', label: 'Board Treasurer' },
    { value: 'Board Vice President', label: 'Board Vice President' },
    { value: 'Chief Academic Officer', label: 'Chief Academic Officer' },
    { value: 'Chief Administrative Officer', label: 'Chief Administrative Officer' },
    { value: 'Chief Educational Officer', label: 'Chief Educational Officer' },
    { value: 'Chief Executive Officer', label: 'Chief Executive Officer' },
    { value: 'Chief Financial Officer', label: 'Chief Financial Officer' },
    { value: 'Chief Of Staff', label: 'Chief Of Staff' },
    { value: 'Chief Operating Officer', label: 'Chief Operating Officer' },
    { value: 'Dean', label: 'Dean' },
    { value: 'Dean Of Students', label: 'Dean Of Students' },
    { value: 'Superintendent', label: 'Superintendent' },
    { value: 'Administrative Assist. To The Board', label: 'Administrative Assist. To The Board' },
    { value: 'Administrative Assist. To The Chief Academic Officer', label: 'Administrative Assist. To The Chief Academic Officer' },
    { value: 'Administrative Assist. To The Chief Executive Officer', label: 'Administrative Assist. To The Chief Executive Officer' },
    { value: 'Administrative Assist. To The Chief Financial Officer', label: 'Administrative Assist. To The Chief Financial Officer' },
    { value: 'Administrative Assist. To The Director Of Professional Development', label: 'Administrative Assist. To The Director Of Professional Development' },
    { value: 'Administrative Employee-Pupil Personnel', label: 'Administrative Employee-Pupil Personnel' },
    { value: 'Admissions', label: 'Admissions' },
    { value: 'Admissions Coordinator', label: 'Admissions Coordinator' },
    { value: 'Admissions Director', label: 'Admissions Director' },
    { value: 'Advancement', label: 'Advancement' },
    { value: 'Advancement Coordinator', label: 'Advancement Coordinator' },
    { value: 'Advancement Director', label: 'Advancement Director' },
    { value: 'Assist. Superintendent-Professional Development', label: 'Assist. Superintendent-Professional Development' },
    { value: 'Asst. Superintendent-Pupil Personnel', label: 'Asst. Superintendent-Pupil Personnel' },
    { value: 'Asst. Superintendents - Operations', label: 'Asst. Superintendents - Operations' },
    { value: 'Attendance', label: 'Attendance' },
    { value: 'Attendance Coordinator', label: 'Attendance Coordinator' },
    { value: 'Attendance Director', label: 'Attendance Director' },
    { value: 'Benefits Administrative Assist.', label: 'Benefits Administrative Assist.' },
    { value: 'Benefits Director', label: 'Benefits Director' },
    { value: 'Charter Schools Coordinator', label: 'Charter Schools Coordinator' },
    { value: 'Development Coordinator', label: 'Development Coordinator' },
    { value: 'Development Director', label: 'Development Director' },
    { value: 'District Administration', label: 'District Administration' },
    { value: 'District Administration Coordinator', label: 'District Administration Coordinator' },
    { value: 'District Administration Director', label: 'District Administration Director' },
    { value: 'District Administrator', label: 'District Administrator' },
    { value: 'District Compliance', label: 'District Compliance' },
    { value: 'District Compliance Coordinator', label: 'District Compliance Coordinator' },
    { value: 'District Compliance Director', label: 'District Compliance Director' },
    { value: 'Executive Assistant To The Superintendent', label: 'Executive Assistant To The Superintendent' },
    { value: 'Food Service / Cafeteria Manager', label: 'Food Service / Cafeteria Manager' },
    { value: 'Food Services', label: 'Food Services' },
    { value: 'Food Services Coordinator', label: 'Food Services Coordinator' },
    { value: 'Food Services Director', label: 'Food Services Director' },
    { value: 'Human Resources', label: 'Human Resources' },
    { value: 'Human Resources Administrative Assistant', label: 'Human Resources Administrative Assistant' },
    { value: 'Human Resources Coordinator', label: 'Human Resources Coordinator' },
    { value: 'Human Resources Director', label: 'Human Resources Director' },
    { value: 'Operations', label: 'Operations' },
    { value: 'Operations Coordinator', label: 'Operations Coordinator' },
    { value: 'Operations Director', label: 'Operations Director' },
    { value: 'Organizational Development', label: 'Organizational Development' },
    { value: 'Organizational Development Coordinator', label: 'Organizational Development Coordinator' },
    { value: 'Organizational Development Director', label: 'Organizational Development Director' },
    { value: 'Paraprofessional / Instructional Aide', label: 'Paraprofessional / Instructional Aide' },
    { value: 'Professional Development', label: 'Professional Development' },
    { value: 'Professional Development Coordinator', label: 'Professional Development Coordinator' },
    { value: 'Professional Development Director', label: 'Professional Development Director' },
    { value: 'Publicity And Communications', label: 'Publicity And Communications' },
    { value: 'Publicity And Communications Coordinator', label: 'Publicity And Communications Coordinator' },
    { value: 'Publicity And Communications Director', label: 'Publicity And Communications Director' },
    { value: 'Pupil Personnel', label: 'Pupil Personnel' },
    { value: 'Pupil Personnel Coordinator', label: 'Pupil Personnel Coordinator' },
    { value: 'Pupil Personnel Director', label: 'Pupil Personnel Director' },
    { value: 'Risk Management', label: 'Risk Management' },
    { value: 'Risk Management Coordinator', label: 'Risk Management Coordinator' },
    { value: 'Risk Management Director', label: 'Risk Management Director' },
    { value: 'School Improvement', label: 'School Improvement' },
    { value: 'School Improvement Coordinator', label: 'School Improvement Coordinator' },
    { value: 'School Improvement Director', label: 'School Improvement Director' },
    { value: 'Student Placement', label: 'Student Placement' },
    { value: 'Student Placement Coordinator', label: 'Student Placement Coordinator' },
    { value: 'Student Placement Director', label: 'Student Placement Director' },
    { value: '21St Cclc Coordinator', label: '21St Cclc Coordinator' },
    { value: '21St Cclc Director', label: '21St Cclc Director' },
    { value: 'Administrative Assistant For Grants / Federal Programs', label: 'Administrative Assistant For Grants / Federal Programs' },
    { value: 'Administrative Assistant Of Federal Programs', label: 'Administrative Assistant Of Federal Programs' },
    { value: 'Administrative Assistant Of Special Services', label: 'Administrative Assistant Of Special Services' },
    { value: 'Administrative Assistant Title I Instructional', label: 'Administrative Assistant Title I Instructional' },
    { value: 'Administrative Assistant Title Iii', label: 'Administrative Assistant Title Iii' },
    { value: 'Asst. Superintendent-Federal Programs', label: 'Asst. Superintendent-Federal Programs' },
    { value: 'Business Partnerships & Grants', label: 'Business Partnerships & Grants' },
    { value: 'Business Partnerships & Grants Director', label: 'Business Partnerships & Grants Director' },
    { value: 'Diagnostician / Testing', label: 'Diagnostician / Testing' },
    { value: 'Diagnostician / Testing Coordinator', label: 'Diagnostician / Testing Coordinator' },
    { value: 'Diagnostician / Testing Director', label: 'Diagnostician / Testing Director' },
    { value: 'Federal And State Programs', label: 'Federal And State Programs' },
    { value: 'Federal And State Programs Director', label: 'Federal And State Programs Director' },
    { value: 'Federal Programs And Grants Coordinator', label: 'Federal Programs And Grants Coordinator' },
    { value: 'Gear Up Coordinator', label: 'Gear Up Coordinator' },
    { value: 'Grants', label: 'Grants' },
    { value: 'Grants And Funded Programs Director', label: 'Grants And Funded Programs Director' },
    { value: 'Grants Specialist', label: 'Grants Specialist' },
    { value: 'Guidance & Counseling Coordinator', label: 'Guidance & Counseling Coordinator' },
    { value: 'Guidance & Counseling Director', label: 'Guidance & Counseling Director' },
    { value: 'Guidance & Counseling, Elementary', label: 'Guidance & Counseling, Elementary' },
    { value: 'Guidance & Counseling, K-12', label: 'Guidance & Counseling, K-12' },
    { value: 'Guidance & Counseling, Secondary', label: 'Guidance & Counseling, Secondary' },
    { value: 'Guidance Counselor', label: 'Guidance Counselor' },
    { value: 'Psychologist', label: 'Psychologist' },
    { value: 'Psychologist Coordinator', label: 'Psychologist Coordinator' },
    { value: 'Race To The Top (Rttt) Coordinator', label: 'Race To The Top (Rttt) Coordinator' },
    { value: 'Race To The Top (Rttt) Director', label: 'Race To The Top (Rttt) Director' },
    { value: 'School Psychology Director', label: 'School Psychology Director' },
    { value: 'Secretary To Psychologist', label: 'Secretary To Psychologist' },
    { value: 'Secretary To Title I', label: 'Secretary To Title I' },
    { value: 'Student Assessment', label: 'Student Assessment' },
    { value: 'Student Assessment Coordinator', label: 'Student Assessment Coordinator' },
    { value: 'Title I Coordinator', label: 'Title I Coordinator' },
    { value: 'Title I Director', label: 'Title I Director' },
    { value: 'Title II Coordinator', label: 'Title II Coordinator' },
    { value: 'Title II Director', label: 'Title II Director' },
    { value: 'Title III Coordinator', label: 'Title III Coordinator' },
    { value: 'Title III Director', label: 'Title III Director' },
    { value: 'Title IV Coordinator', label: 'Title IV Coordinator' },
    { value: 'Title IX Coordinator', label: 'Title IX Coordinator' },
    { value: 'Title IX Director', label: 'Title IX Director' },
    { value: 'Title VI Coordinator', label: 'Title VI Coordinator' },
    { value: 'Title VII Coordinator', label: 'Title VII Coordinator' },
    { value: 'Title VII Director', label: 'Title VII Director' },
    { value: 'Title X Coordinator', label: 'Title X Coordinator' },
    { value: 'Child Nutrition', label: 'Child Nutrition' },
    { value: 'Child Nutrition Coordinator', label: 'Child Nutrition Coordinator' },
    { value: 'Child Nutrition Director', label: 'Child Nutrition Director' },
    { value: 'Health Aide', label: 'Health Aide' },
    { value: 'Health And Physical Education', label: 'Health And Physical Education' },
    { value: 'Health And Physical Education Coordinator', label: 'Health And Physical Education Coordinator' },
    { value: 'Health And Physical Education Director', label: 'Health And Physical Education Director' },
    { value: 'Health Services Coordinator', label: 'Health Services Coordinator' },
    { value: 'Health Services Director', label: 'Health Services Director' },
    { value: 'Health Services Secretary', label: 'Health Services Secretary' },
    { value: 'Health Services Staff', label: 'Health Services Staff' },
    { value: 'Nurse', label: 'Nurse' },
    { value: 'Wellness', label: 'Wellness' },
    { value: 'Wellness Coordinator', label: 'Wellness Coordinator' },
    { value: 'Wellness Director', label: 'Wellness Director' },
    { value: 'Asst. Superintendent-Special Education', label: 'Asst. Superintendent-Special Education' },
    { value: 'Asst. Superintendent-Special Services', label: 'Asst. Superintendent-Special Services' },
    { value: 'Autism', label: 'Autism' },
    { value: 'Autism Coordinator', label: 'Autism Coordinator' },
    { value: 'Autism Director', label: 'Autism Director' },
    { value: 'Disability Services', label: 'Disability Services' },
    { value: 'Disability Services Coordinator', label: 'Disability Services Coordinator' },
    { value: 'Disability Services Director', label: 'Disability Services Director' },
    { value: 'District Rti', label: 'District Rti' },
    { value: 'District Rti Coordinator', label: 'District Rti Coordinator' },
    { value: 'District Rti Director', label: 'District Rti Director' },
    { value: 'Occupational And Physical Therapy', label: 'Occupational And Physical Therapy' },
    { value: 'Occupational And Physical Therapy Coordinator', label: 'Occupational And Physical Therapy Coordinator' },
    { value: 'Occupational And Physical Therapy Director', label: 'Occupational And Physical Therapy Director' },
    { value: 'Occupational Therapist', label: 'Occupational Therapist' },
    { value: 'Physical Therapist', label: 'Physical Therapist' },
    { value: 'Special Education Administrative Assistant', label: 'Special Education Administrative Assistant' },
    { value: 'Special Education Aide', label: 'Special Education Aide' },
    { value: 'Special Education Assistant Director', label: 'Special Education Assistant Director' },
    { value: 'Special Education Coordinator', label: 'Special Education Coordinator' },
    { value: 'Special Education Director', label: 'Special Education Director' },
    { value: 'Special Education Instructor', label: 'Special Education Instructor' },
    { value: 'Speech And Hearing', label: 'Speech And Hearing' },
    { value: 'Speech And Hearing Coordinator', label: 'Speech And Hearing Coordinator' },
    { value: 'Speech And Hearing Director', label: 'Speech And Hearing Director' },
    { value: 'Speech and Language Therapist', label: 'Speech and Language Therapist' },
    { value: 'Speech Pathologist', label: 'Speech Pathologist' },
    { value: 'Speech Pathologist Coordinator', label: 'Speech Pathologist Coordinator' },
    { value: 'Speech Pathology Director', label: 'Speech Pathology Director' },
    { value: 'Speech Therapist', label: 'Speech Therapist' },
    { value: 'Activities Coordinator', label: 'Activities Coordinator' },
    { value: 'Activities Director', label: 'Activities Director' },
    { value: 'Before And After School', label: 'Before And After School' },
    { value: 'Before And After School Coordinator', label: 'Before And After School Coordinator' },
    { value: 'Before And After School Director', label: 'Before And After School Director' },
    { value: 'Coach', label: 'Coach' },
    { value: 'Student Activities Coordinator', label: 'Student Activities Coordinator' },
    { value: 'Student Activities Staff', label: 'Student Activities Staff' },
    { value: '504 Coordinator', label: '504 Coordinator' },
    { value: '504 Director', label: '504 Director' },
    { value: 'Aboriginal / Native Education', label: 'Aboriginal / Native Education' },
    { value: 'Aboriginal / Native Education Coordinator', label: 'Aboriginal / Native Education Coordinator' },
    { value: 'Aboriginal / Native Education Director', label: 'Aboriginal / Native Education Director' },
    { value: 'Administrative Assistant For Student Services', label: 'Administrative Assistant For Student Services' },
    { value: 'Administrative Assistant To The Director Of Aboriginal / Native Education', label: 'Administrative Assistant To The Director Of Aboriginal / Native Education' },
    { value: 'Administrative Assistant To The Director Of Student Services & Records', label: 'Administrative Assistant To The Director Of Student Services & Records' },
    { value: 'At-Risk Coordinator', label: 'At-Risk Coordinator' },
    { value: 'At-Risk Director', label: 'At-Risk Director' },
    { value: 'Attendance And Discipline', label: 'Attendance And Discipline' },
    { value: 'Attendance And Discipline Coordinator', label: 'Attendance And Discipline Coordinator' },
    { value: 'Attendance And Discipline Director', label: 'Attendance And Discipline Director' },
    { value: 'Community Education Coordinator', label: 'Community Education Coordinator' },
    { value: 'Community Education Director', label: 'Community Education Director' },
    { value: 'Community Relations', label: 'Community Relations' },
    { value: 'HIV / AIDS', label: 'HIV / AIDS' },
    { value: 'HIV / AIDS Coordinator', label: 'HIV / AIDS Coordinator' },
    { value: 'Home School', label: 'Home School' },
    { value: 'Home School Coordinator', label: 'Home School Coordinator' },
    { value: 'Home School Director', label: 'Home School Director' },
    { value: 'Homeless And Migrant Education', label: 'Homeless And Migrant Education' },
    { value: 'Homeless And Migrant Education Coordinator', label: 'Homeless And Migrant Education Coordinator' },
    { value: 'Homeless And Migrant Education Director', label: 'Homeless And Migrant Education Director' },
    { value: 'International Education', label: 'International Education' },
    { value: 'International Education Coordinator', label: 'International Education Coordinator' },
    { value: 'International Education Director', label: 'International Education Director' },
    { value: 'Parent And Community Relations', label: 'Parent And Community Relations' },
    { value: 'Parent And Community Relations Director', label: 'Parent And Community Relations Director' },
    { value: 'Parent And Community Resource Director', label: 'Parent And Community Resource Director' },
    { value: 'Parent / Community Resource Coordinator', label: 'Parent / Community Resource Coordinator' },
    { value: 'Positive Behavior Interventions & Supports', label: 'Positive Behavior Interventions & Supports' },
    { value: 'Positive Behavior Interventions & Supports Coordinator', label: 'Positive Behavior Interventions & Supports Coordinator' },
    { value: 'Safe & Drug Free Schools', label: 'Safe & Drug Free Schools' },
    { value: 'Safe & Drug Free Schools Coordinator', label: 'Safe & Drug Free Schools Coordinator' },
    { value: 'Safe & Drug Free Schools Director', label: 'Safe & Drug Free Schools Director' },
    { value: 'School Social Worker', label: 'School Social Worker' },
    { value: 'School Social Worker Coordinator', label: 'School Social Worker Coordinator' },
    { value: 'School Social Worker Director', label: 'School Social Worker Director' },
    { value: 'Student Records', label: 'Student Records' },
    { value: 'Student Records Coordinator', label: 'Student Records Coordinator' },
    { value: 'Student Services & Records', label: 'Student Services & Records' },
    { value: 'Student Services & Records Coordinator', label: 'Student Services & Records Coordinator' },
    { value: 'Student Services & Records Director', label: 'Student Services & Records Director' },
    { value: 'Assistant Superintendent Of Administration And Technology', label: 'Assistant Superintendent Of Administration And Technology' },
    { value: 'Automotive Technology', label: 'Automotive Technology' },
    { value: 'Data Management Coordinator', label: 'Data Management Coordinator' },
    { value: 'Data Management Director', label: 'Data Management Director' },
    { value: 'District Network Administrator', label: 'District Network Administrator' },
    { value: 'Education Management Information System Coordinator', label: 'Education Management Information System Coordinator' },
    { value: 'Information Technology Coordinator', label: 'Information Technology Coordinator' },
    { value: 'Information Technology Director', label: 'Information Technology Director' },
    { value: 'Instructional Technology Coordinator', label: 'Instructional Technology Coordinator' },
    { value: 'Instructional Technology Director', label: 'Instructional Technology Director' },
    { value: 'Librarian', label: 'Librarian' },
    { value: 'Library And Media Services', label: 'Library And Media Services' },
    { value: 'Library And Media Services Coordinator', label: 'Library And Media Services Coordinator' },
    { value: 'Library And Media Services Director', label: 'Library And Media Services Director' },
    { value: 'Secretary To The Information Director Of Technology', label: 'Secretary To The Information Director Of Technology' },
    { value: 'Technology And IT', label: 'Technology And IT' },
    { value: 'Technology Technician', label: 'Technology Technician' },
    { value: 'Administrative Secretary Of Transportation', label: 'Administrative Secretary Of Transportation' },
    { value: 'Bus Driver', label: 'Bus Driver' },
    { value: 'Driver Instructor', label: 'Driver Instructor' },
    { value: 'Transportation Administrator', label: 'Transportation Administrator' },
    { value: 'Transportation Coordinator', label: 'Transportation Coordinator' },
    { value: 'Transportation Director', label: 'Transportation Director' },
    { value: 'Transportation Staff', label: 'Transportation Staff' },
    { value: 'Food service worker', label: 'Food service worker' },
    { value: 'Custodian', label: 'Custodian' },
    { value: 'Teacher', label: 'Teacher' },
    { value: 'Teaching assistant', label: 'Teaching assistant' },
    { value: 'Crossing guard', label: 'Crossing guard' },
    { value: 'Sports coach', label: 'Sports coach' },
    { value: 'School bus driver', label: 'School bus driver' },
    { value: 'Academic adviser', label: 'Academic adviser' },
    { value: 'Groundskeeper', label: 'Groundskeeper' },
    { value: 'Maintenance technician', label: 'Maintenance technician' },
    { value: 'Registrar', label: 'Registrar' },
    { value: 'Academic coordinator', label: 'Academic coordinator' },
    { value: 'Special education teacher', label: 'Special education teacher' },
    { value: 'School nurse', label: 'School nurse' },
    { value: 'Webmaster', label: 'Webmaster' },
    { value: 'Guidance counselor', label: 'Guidance counselor' },
    { value: 'Athletic director', label: 'Athletic director' },
    { value: 'Vice principal', label: 'Vice principal' },
    { value: 'Superintendent', label: 'Superintendent' },
    { value: 'Principal', label: 'Principal' },
]

const Locoptions = [
    { value: 'Alabama', label: 'Alabama' },
    { value: 'Alaska', label: 'Alaska' },
    { value: 'American Samoa', label: 'American Samoa' },
    { value: 'Arizona', label: 'Arizona' },
    { value: 'Arkansas', label: 'Arkansas' },
    { value: 'California', label: 'California' },
    { value: 'Colorado', label: 'Colorado' },
    { value: 'Connecticut', label: 'Connecticut' },
    { value: 'Delaware', label: 'Delaware' },
    { value: 'District Of Columbia', label: 'District Of Columbia' },
    { value: 'Federated States Of Micronesia', label: 'Federated States Of Micronesia' },
    { value: 'Florida', label: 'Florida' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Guam', label: 'Guam' },
    { value: 'Hawaii', label: 'Hawaii' },
    { value: 'Idaho', label: 'Idaho' },
    { value: 'Illinois', label: 'Illinois' },
    { value: 'Indiana', label: 'Indiana' },
    { value: 'Iowa', label: 'Iowa' },
    { value: 'Kansas', label: 'Kansas' },
    { value: 'Kentucky', label: 'Kentucky' },
    { value: 'Louisiana', label: 'Louisiana' },
    { value: 'Maine', label: 'Maine' },
    { value: 'Marshall Islands', label: 'Marshall Islands' },
    { value: 'Maryland', label: 'Maryland' },
    { value: 'Massachusetts', label: 'Massachusetts' },
    { value: 'Michigan', label: 'Michigan' },
    { value: 'Minnesota', label: 'Minnesota' },
    { value: 'Mississippi', label: 'Mississippi' },
    { value: 'Missouri', label: 'Missouri' },
    { value: 'Montana', label: 'Montana' },
    { value: 'Nebraska', label: 'Nebraska' },
    { value: 'Nevada', label: 'Nevada' },
    { value: 'New Hampshire', label: 'New Hampshire' },
    { value: 'New Jersey', label: 'New Jersey' },
    { value: 'New Mexico', label: 'New Mexico' },
    { value: 'New York', label: 'New York' },
    { value: 'North Carolina', label: 'North Carolina' },
    { value: 'North Dakota', label: 'North Dakota' },
    { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
    { value: 'Ohio', label: 'Ohio' },
    { value: 'Oklahoma', label: 'Oklahoma' },
    { value: 'Oregon', label: 'Oregon' },
    { value: 'Palau', label: 'Palau' },
    { value: 'Pennsylvania', label: 'Pennsylvania' },
    { value: 'Puerto Rico', label: 'Puerto Rico' },
    { value: 'Rhode Island', label: 'Rhode Island' },
    { value: 'South Carolina', label: 'South Carolina' },
    { value: 'South Dakota', label: 'South Dakota' },
    { value: 'Tennessee', label: 'Tennessee' },
    { value: 'Texas', label: 'Texas' },
    { value: 'Utah', label: 'Utah' },
    { value: 'Vermont', label: 'Vermont' },
    { value: 'Virgin Islands', label: 'Virgin Islands' },
    { value: 'Virginia', label: 'Virginia' },
    { value: 'Washington', label: 'Washington' },
    { value: 'West Virginia', label: 'West Virginia' },
    { value: 'Wisconsin', label: 'Wisconsin' },
    { value: 'Wyoming', label: 'Wyoming' },
];

const styles = {
    mainBGportrait: {
        background: `url(${bgimg}), #c5c8cc`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '80vw',
        paddingTop: '50vw',
        paddingLeft: '5vw',
        paddingRight: '5vw',
        paddingBottom: '3vw',
    },
    mainBGlandscape: {
        background: `url(${bgimg}), #c5c8cc`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '80vh',
        paddingTop: '50vh',
        paddingLeft: '10vh',
        paddingRight: '10vh',
        paddingBottom: '3vh',
    },
    cardstyle: {
        background: '#fff',
        borderRadius: '12px',
        padding: '3vh',
    },
    searchBoxBig: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        background: '#fff',
        border: '1px solid #BEBEBE',
        borderRadius: '25px',
        paddingLeft: '2vh',
        paddingRight: '2vh',
        paddingTop: '4px',
        paddingBottom: '4px',
    },
    searchBoxSmall: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        background: '#fff',
        border: '1px solid #BEBEBE',
        borderRadius: '25px',
        paddingLeft: '1em',
        paddingRight: '1em',
        paddingTop: '1px',
        paddingBottom: '1px',
        width: '100%'
    },
    srchField: {
        border: "none",
        borderColor: "transparent",
        outline: "none",
        width: "100%",
    },
    hiddenLabel: {
        height: 0,
        visibility: "hidden",
    },
};
