import {Box, Container, Grid, Typography, Button, TextField, Checkbox  } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';

import Cookies from 'js-cookie';
import "react-multi-carousel/lib/styles.css";
import NavBar_emp from '../NavBar_emp';
import Navbar from '../NavBar/index';
import Dollar from '../Dashboard/dollar.svg';
import Phd from '../Dashboard/phd.svg';
import Teacher from '../Dashboard/teacher.svg';
import FullTime from '../Dashboard/fullTime.svg';
import Time from '../Dashboard/time.svg';
import San from '../Dashboard/san.svg';
import { RiDeleteBin6Line } from 'react-icons/ri';

import { FaRegHeart } from 'react-icons/fa';
import { FaHeart } from 'react-icons/fa';
import DesiredJob from './desiredJob.svg';
import GreenDesiredJob from './GreenDesiredJob.svg';
import Profile from './profile.svg';
import School from './school.svg';
import Tick from './Tick.svg'
import Modal from '@mui/material/Modal'
import CloseIcon from '../../assets/Close.svg'
import LeftChevron from '../../assets/LeftChevron.svg'
import RightChevron from '../../assets/RightChevron.svg'
import Badge1 from '../../assets/badge1.svg'
import Badge2 from '../../assets/badge2.svg'
import Q from '../../assets/q.svg'
import Mail from './mail.png'
import Call from './call.png'
import Contact from './contact.png';
import Calendly from '../../assets/calendly.png';
import Saji from './saji.png'
import { ProfileTags } from '../profile/profile'
import GreenDot from './Frame 6415.png'
import GreyDot from './Frame 6416.png'
import {Helmet} from "react-helmet";
import './search-jobs.css'
import axiosInstance from '../../axios';
import LinkedIn from '../profile/linkedin.svg';
import facebook from '../profile/facebook.svg';
import twitter from '../profile/twitter.svg';
import { MailOutline, PhoneOutlined } from '@material-ui/icons';
import {useHistory} from 'react-router-dom';


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}
function SavedJobs() {

    const [tabValue, setTabValue] = React.useState('job-description');
    
    const [modal, setModal] = useState(false);

  const [workExperienceForm, setWorkExperienceForm] = useState(false);


    const changeColor = useCallback(() => {
        setToggleHeart(!toggleHeart)
    }, [])

const { height, width } = useWindowDimensions();

const history = useHistory();
const [activeJob, setActiveJob] = useState();
const [jobs, setJobs] = useState([]);
const [accessToken, setaccessToken] = useState('');


const getJobs = () => {
    axiosInstance.get(`/district/get_jobs/?page=1`).then(res => setJobs(res.data.results))
}

const WorkExperienceForm = (props) => {

    const handleSendInvite = () => {
        axiosInstance.post('/messaging/create_room/', {
            // access: accessToken,
            receiver_id:  window.location.href.split('/')[window.location.href.split('/').length -1],
            job_id: activeJob
        }).then(res => {
            history.push(`/message?room_id=${res.data.room_id}`)
        })
    }

    const changeStyle = (e) => {
        Array.from(document.getElementsByClassName('modal_items')).map(item=>{
        if(item.classList.contains('modal_active')){
            item.classList.remove('modal_active');
        }
        })
        document.getElementById(`grid_id_${e}`).classList.add('modal_active');
    }

    return (
    <div>
        <div style={{ marginRight: '3em', marginLeft: '3em', display: "flex", flexDirection: 'column' }}>
            <Typography style={{ fontSize: '19px', fontWeight: '600' }}>Send Job Invite
            <p style={{fontSize : '15px', color : '#888282'}}>Click on a Job Post to select and send Invite</p>
            </Typography>
            <br />
            <form >
                    <Grid  container item spacing={3} style={{display : 'flex', justifyContent : 'center'}} >
                        {
                            jobs.map((e, i)=>{
                                return(
                                    <Grid key={i} className='modal_items' id={`grid_id_${i}`} item lg={12} style={{
                                        borderRadius : '10px', border : '1px solid black', marginBottom : '15px', cursor : 'pointer'
                                        }} onClick={()=>{changeStyle(i); setActiveJob(e.id)}} >
                                        <div style={{display : 'flex', flexDirection : 'column', justifyContent : 'space-between'}} >
                                        <div >
                                            <h5>{e.job_title}</h5>
                                            <p style={{color : '#504b4b'}}>{e.district}</p>
                                        </div>
                                        <div style={{display : 'flex', justifyContent : 'space-between'}}>
                                            <p className='workExp_subdiv'>Posted on {e.expiry_date}</p>
                                            <p className='workExp_subdiv'>Job Expires : {e.date_posted}</p>
                                        </div>
                                        </div>
                                    </Grid>
                                )
                            })
                        }                          
                    </Grid>
            </form>

        </div>
        <div className="btnProfileModalCont">
        <button className='btnContCancelBtn' id='work_exp_close_btn' onClick={props.closeModal}>
            Cancel
        </button>
        <button className='btnContSaveBtn' onClick={handleSendInvite}>
            Send Invite
        </button>
        </div>
    </div>
    )
}

  const [candidate, setcandidate] = useState({
    about: null,
    address: null,
    certificates: [],
    city: null,
    documents: [],
    education_avail: null,
    educations: [],
    email: null,
    full_name: null,
    id: null,
    job_type: null,
    languages: [],
    last_modified: null,
    minimum_salary: null,
    notify_about_nearby_jobs: null,
    online_jobs_only: null,
    opportunities: [],
    phd_avail: null,
    phone: null,
    preffered_job_locations: null,
    preffered_job_titles: null,
    profile_pic: null,
    seeker_type: null,
    skills: [],
    social_link: {social_links: {
        facebook: null,
        twitter: null,
        linkedin: null
    }},
    state: null,
    user: null,
    video_resume: null,
    work_exp_avail: null,
    work_exps: [],
    zipcode: null
  });

    const stringToArray = (str) => {
        let arr = str.replace(/'/g, '"');
        arr = JSON.parse(arr);
        return arr;
    }

    const [socialLinks, setsocialLinks] = useState({
        linkedin : '',
        twitter : '',
        facebook : '',
        calendly : ''
    });

    useEffect(()=>{
        const id = window.location.href.split('/')[window.location.href.split('/').length -1];
        
        axiosInstance('/seeker/get_candidate_details', {
            params : {
                candidate_id : id
            }
        }).then(res=>{
            console.log(res.data.social_link);
            setcandidate(res.data.data);
            setsocialLinks(res.data.social_link);
        }).catch(err=>{
            console.log(err);
        })
    },[])

    const [isCandidate, setisCandidate] = useState(null);
    const [isDistrict, setisDistrict] = useState(null);

    useEffect(() => {
        if ( Cookies.get( 'access_token' ) ) {
            if(Cookies.get('user') === 'JobSeeker'){
                setisCandidate(true);
                setisDistrict(false);
            }else{
                setisCandidate(false);
                setisDistrict(true);
            }
            setaccessToken( Cookies.get( 'access_token' ) );
            getJobs();
        }
    }, [accessToken])


    //FAV:
    const [toggleHeart, setToggleHeart] = useState( false )

    const addToFavorite = (id) => {
        // console.log(id);
        setToggleHeart(!toggleHeart)
        const data = {
            // access: accessToken,
            job_seeker_id: id
        }
        console.log(data);
        axiosInstance.post('/district/save_job_seeker/', data).then(res => {
            console.log(res);
            //getData();
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <>
            <Helmet>
                <title>{candidate.full_name ? String(candidate.full_name + ' |') : ''} Frame Change</title>
            </Helmet>
            {
                isCandidate ? <Navbar /> : null
            }
            {
                isDistrict ? <NavBar_emp /> : null
            }
            <Grid style={{ padding: '3.5em 0', background: '#F5F7FA' }} container alignItems='center' justifyContent='center' direction='column'>

                <Grid container direction='column' style={{ width: '90%' }}>

                    <Container style={{ margin: 'auto', padding: '0' }} >
                        <div style={ { marginTop: '7em', marginBottom: '1em' } }>
                        </div>
                        <Grid container>

                            <Grid container item lg={12} md={12} sm={12} xs={12} style={{display: 'block'}}>
                                <Grid container style={{padding: '20px', boxSizing: 'border-box', borderRadius: '13px', boxShadow: '0px 2px 10px rgba(134, 150, 166, 0.19)'}}>
                                    <div style={{ display: 'inline-flex', gap: '20px', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%' }}>
                                        <div style={{display: 'inline-flex', gap: '12px', width: '310px'}}>
                                            <img src={candidate.profile_pic ? candidate.profile_pic : `https://ui-avatars.com/api/?name=${candidate.full_name}?rounded=true`} style={{width: '103px', borderRadius: '50%'}} />
                                            <div>
                                                <div style={ { fontWeight: '500', fontSize: '19px' } }>{candidate.full_name}</div>
                                                <Typography style={ { fontSize: "14px", color: '#636363' } }>{candidate.preffered_job_titles ? stringToArray(candidate.preffered_job_titles).join() : null}</Typography>
                                                <Typography style={{ fontSize: "14px", color: '#636363' }}>{candidate.city}, {candidate.state}</Typography>
                                            </div>
                                        </div>
                                       
                                        <div>
                                            <div style={{ display: 'flex', gap: '16px', alignItems: 'center', justifyContent : 'center' }}>
                                                <img onClick={()=>window.location.href='mailto:'+candidate.email} src={Mail} style={{cursor: 'pointer'}} />
                                                <img onClick={()=>window.location.href='tel:+'+candidate.phone} src={Call} style={{cursor: 'pointer'}} />
                                                
                                                {
                                                    socialLinks.calendly ? 
                                                    <div className=' py-2 col-5 d-flex justify-content-center' style={{cursor: 'pointer','borderRadius' : '10px', border : '#F1F5FA 2px solid'}}>
                                                        <img
                                                            src={Calendly}
                                                            style={{  height : '30px' }}
                                                            onClick={() =>
                                                            window.open(`https://calendly.com/${socialLinks.calendly}/30min`, '_blank')
                                                            }
                                                        /> <span style={{color : 'blue', display : 'flex', flexDirection : 'column', justifyContent : 'center'}}>Calendly</span>
                                                    </div> : null
                                                }
                                            </div>
                                            {
                                                isCandidate ? null :
                                                <img src={Saji} style={{marginTop: '16px', cursor: 'pointer'}}  onClick={()=>setWorkExperienceForm(true)}  />
                                            }
                                        </div>
                                    </div>
                                    <div className='profileTagCont' style={{marginTop: '20px', width: '75%', maxWidth: 'unset'}}>
                                        {
                                            candidate.job_type ? 
                                            <ProfileTags img={FullTime} text={candidate.job_type ? stringToArray(candidate.job_type).join() ? stringToArray(candidate.job_type).join() : 'Full Time'  : 'Full Time'} />
                                            : null
                                        }
                                        {
                                            candidate.phd_avail ? 
                                            <ProfileTags img={Phd} text="PHD" />
                                            : null
                                        }
                                        {
                                            candidate.work_exp_avail ? 
                                                <ProfileTags img={FullTime} text="Work Experience" /> : null
                                        }
                                        {
                                            candidate.education_avail ?
                                                <ProfileTags img={Teacher} text="Education" /> : null
                                        }
                                    </div>
                                    {/* <Grid item lg={3} style={{marginTop: '20px', display: 'inline-flex', gap: '5px'}}>
                                        <FaRegHeart size='1.5em' /> <span>Add to Favorites</span>
                                    </Grid> */}
                                    {
                                        isCandidate ? null :
                                        <Grid item lg={3} onClick={() => addToFavorite(candidate.id)} style={{marginTop: '20px', display: 'inline-flex', gap: '5px', cursor:'pointer'}}>
                                            {toggleHeart ? <FaHeart size='1.5em' color='orange' /> : <FaRegHeart size='1.5em' />}
                                        </Grid>
                                    }
                                    <div style={{width: '100%', marginTop: '30px'}}>
                                        {candidate.about}
                                    </div>
                                    <hr style={{width: '100%', backgroundColor: '#DFDFDF'}} />
                                    <div style={ { width: '100%', marginBottom: '30px', display: 'inline-flex', justifyContent: 'space-between', gap: '15px', flexWrap: 'wrap'  } }>
                                        {
                                            candidate.video_resume ? 
                                            <>
                                                <div style={ { minWidth: '320px' } }>
                                                    <h4 style={{marginBottom: '20px'}}>Video Resume</h4>
                                                    <video className='vidMob' style={{width: '700px', height: '440px', borderRadius: '10px', boxShadow: '0px 0.666328px 8.47804px rgba(0, 0, 0, 0.18)', maxWidth: '80vw'}} controls>
                                                        <source src={candidate.video_resume} type='video/mp4' />
                                                    </video>
                                                </div>
                                            </> : null
                                        }
                                        {
                                            candidate.skills.length ? <>
                                                <div style={{minWidth: '320px'}}>
                                                    <h4 style={{marginBottom: '20px'}}>Skills</h4>
                                                    <ul>
                                                        {
                                                            candidate.skills.map(item=>{
                                                                return(
                                                                    <li>{item.name}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </> : null
                                        }
                                    </div>
                                    <div style={ { width: '100%', marginBottom: '30px' } }>
                                                {
                                                    candidate.documents.length ? <>
                                                        <h4 style={ { margin: '10px 0' } }>Documents</h4>
                                                        {
                                                            candidate.documents.map(e=>{
                                                                return(
                                                                    <a href={e.uploaded_document} target='_' style={{textDecoration: 'none', color: 'unset'}}>
                                                                        <div style={{display: 'inline-flex', gap: '1.5rem', flexWrap: 'wrap', padding: '12px', borderRadius: '8px', border: '1px solid #E1E1E1', minWidth: '253px'}}>
                                                                            <div style={{display: 'inline-flex', gap: '1.2rem', width: '100%', alignItems: 'center', position: 'relative'}}>
                                                                                <div>
                                                                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M31.498 12H25.498C23.008 12 20.998 9.99 20.998 7.5V1.5C20.998 0.66 20.338 0 19.498 0H5.99805C4.34805 0 2.99805 1.35 2.99805 3V33C2.99805 34.65 4.34805 36 5.99805 36H29.998C31.648 36 32.998 34.65 32.998 33V13.5C32.998 12.66 32.338 12 31.498 12ZM25.498 30H10.498C9.65805 30 8.99805 29.34 8.99805 28.5C8.99805 27.66 9.65805 27 10.498 27H25.498C26.338 27 26.998 27.66 26.998 28.5C26.998 29.34 26.338 30 25.498 30ZM25.498 24H10.498C9.65805 24 8.99805 23.34 8.99805 22.5C8.99805 21.66 9.65805 21 10.498 21H25.498C26.338 21 26.998 21.66 26.998 22.5C26.998 23.34 26.338 24 25.498 24ZM25.498 18H10.498C9.65805 18 8.99805 17.34 8.99805 16.5C8.99805 15.66 9.65805 15 10.498 15H25.498C26.338 15 26.998 15.66 26.998 16.5C26.998 17.34 26.338 18 25.498 18ZM23.998 1.5V6C23.998 7.65 25.348 9 26.998 9H31.498C32.848 9 33.508 7.38 32.548 6.45L26.548 0.45C25.618 -0.51 23.998 0.18 23.998 1.5Z" fill="#0B0B0B" fill-opacity="0.9"/>
                                                                                    </svg>
                        
                                                                                </div>
                                                                                <div style={{marginRight: '30px'}}>
                                                                                    <div style={{fontSize: '14px', fontWeight: '500'}}>{e.name}</div>
                                                                                    <div style={{fontSize: '14px', fontWeight: '300', color: '#0B0B0B'}}>{e.uploaded_on}</div>
                                                                                </div>
                                                                                <div  style={{position: 'absolute', right: '0px'}}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        {/* <iframe hidden0[']></iframe> */}
                                                                                        <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#2F3338" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                        <path d="M5.83398 8.33398L10.0007 12.5007L14.1673 8.33398" stroke="#2F3338" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                        <path d="M10 12.5V2.5" stroke="#2F3338" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                    </svg>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                
                                                                )
                                                            })
                                                        }
                                                    </> : null
                                                }
                                            </div>
                                    <div style={ { width: '100%', marginBottom: '30px' } }>
                                        {
                                            candidate.work_exp_avail ? <>
                                                <h4 style={ { margin: '10px 0' } }>WORK EXPERIENCE</h4>
                                                    {
                                                        candidate.work_exps.map(item=>{
                                                            return(<>
                                                                <div style={{display: 'flex', flexDirection : 'column', gap: '5px', marginTop : '30px' }}>
                                                                    <div style={ { minWidth: '150px', maxWidth: '250px',fontSize: '16px' } }>{item.start_date} – {item.end_date ? item.end_date : 'Present'}</div>
                                                                    <div  className='deskOnly' style={ { width: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                                        <img src={ GreenDot } style={ { width: '15px', height: '15px' } } />
                                                                        <div style={{width: '3px', backgroundColor: '#DDDDDD', height: '100%'}}></div>
                                                                    </div>
                                                                    <div style={{ minWidth: '250px'}}>
                                                                        <h5 style={ { margin: '5px 0' } }>{item.position}</h5>
                                                                        <h6 style={{margin: '5px 0', color: '#636363'}}>{item.school}</h6>
                                                                    </div>
                                                                    <hr />
                                                                </div>
                                                            </>)
                                                        })
                                                    }
                                                    
                                                    
                                            </> : null
                                        }
                                        {
                                             candidate.education_avail ? <>
                                             <h4 style={ { margin: '10px 0' } }>EDUCATION</h4>
                                                 {
                                                     candidate.work_exps.map(item=>{
                                                         return(<>
                                                            <div style={{display: 'flex', gap: '30px', flexWrap: 'wrap', marginTop:'15px' }}>
                                                                <div style={ { minWidth: '150px', maxWidth: '250px',fontSize: '16px' } }>{item.start_date} – {item.end_date ? item.end_date : 'Present'}</div>
                                                                <div  className='deskOnly' style={ { width: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                                    <img src={ GreenDot } style={ { width: '15px', height: '15px' } } />
                                                                    <div style={{width: '3px', backgroundColor: '#DDDDDD', height: '100%'}}></div>
                                                                </div>
                                                                <div style={{ minWidth: '250px'}}>
                                                                    <h5 style={ { margin: '5px 0' } }>{item.degree}</h5>
                                                                    <h6 style={{margin: '5px 0', color: '#636363'}}>{item.school}</h6>
                                                                </div>
                                                            </div>
                                                         </>)
                                                     })
                                                 }
                                         </> : null
                                        }
                                    </div>
                                    {
                                        candidate.certificates.length ? <>
                                            <div>
                                                <h4 style={{margin: '10px 0'}}>Certifications</h4>
                                            {
                                                candidate.certificates.map(item=>{
                                                    return(
                                                            <>
                                                                <h5 style={{margin: '5px 0', marginTop: '30px'}}>{item.name}</h5>
                                                                <h6 style={{margin: '5px 0', color: '#636363'}}>{item.authority}</h6>
                                                                <h6 style={{margin: '5px 0', color: '#636363'}}>{item.start_date}</h6>
                                                                <br/>
                                                            </>
                                                            )
                                                        })
                                                    }
                                            </div>
                                        </> : null
                                    }
                                </Grid>
                            </Grid>
                            <div className="profileCard" style={{borderRadius: '8px', marginTop: '20px'}}>
                                <div className="dataCont">
                                    <div className="dataContLeft">
                                    Social Link

                                    </div>
                                    <div className="dataContRight">
                                    <div className="dataContRightData" style={{marginTop: '0'}}>
                                        {/* {profile.about} */}
                                        {
                                        candidate.social_link.social_links.linkedin ? 
                                        <img src={LinkedIn} style={{cursor : 'pointer'}}  onClick={()=>window.open(`http://${candidate.social_link.social_links.linkedin}`, "_blank")} /> : null
                                        }
                                        {
                                        candidate.social_link.social_links.facebook ? 
                                        <img src={facebook} style={{cursor : 'pointer'}}  onClick={()=>window.open(`http://${candidate.social_link.social_links.facebook}`, "_blank")} /> : null
                                        }
                                        {
                                        candidate.social_link.social_links.twitter ? 
                                        <img src={twitter} style={{cursor : 'pointer'}}  onClick={()=>window.open(`http://${candidate.social_link.social_links.twitter}`, "_blank")} /> : null
                                        }
                                    </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </Grid>
                    </Container>
                </Grid>
            </Grid >
            <Modal
                open={modal}
                onClose={() => setModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modalCont'><JobModal closeModal={() => setModal(false)} /></div>
            </Modal>
            <Modal
                open={workExperienceForm}
                onClose={() => {setWorkExperienceForm(false);}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modalCont'><WorkExperienceForm closeModal={() => {setWorkExperienceForm(false);}} /></div>
            </Modal>
        </>
    )
}


export default SavedJobs;

const JobModal = ( props ) => {

    const scrollRight = () => {
        var carousel = document.getElementById( 'Carousel' )
        carousel.scrollLeft  = carousel.scrollLeft + 500
    }
    const scrollLeft = () => {
        var carousel = document.getElementById( 'Carousel' )
        carousel.scrollLeft  = carousel.scrollLeft - 500
    }

    return (
        <div style={{position: 'relative'}}>
            <div className='ModalHeading'>Learn about the school district’s work culture</div>
            <div className='ModalDescription'>Talk to the employees or ex-employees  and network to understand more about your future job</div>
            <img src={ CloseIcon } alt="" className='modalClose' onClick={ props.closeModal } />
            <div className="chevronCont">
                <img src={LeftChevron} alt="" onClick={scrollLeft}  />
                <img src={RightChevron} alt="" onClick={scrollRight} />
            </div>
            <div className="modalOF" id='Carousel'>
                <div className="carouselCont" >
                    <CarouselCard />
                    <CarouselCard />
                    <CarouselCard />
                    <CarouselCard />
                    <CarouselCard />
                    <CarouselCard />
                </div>
            </div>
        </div>
    )
}

const CarouselCard = () => {




    return (
        <div className='carouselCardCont'>
            <div className="cardProfile">
                <img src="//unsplash.it/80/80" alt="" />
                <div>
                    <div className="profileName">Jenny Wilson</div>
                    <div className="profileDesc">Highschool English Teacher</div>
                    <div className="profileDesc">San Francisco, California</div>
                </div>
            </div>
            <div>BADGES</div>
            <div style={{margin: '20px 0'}}>
                <img src={Badge1} alt="" style={{marginRight: '13px'}} />
                <img src={Badge2} alt="" />
            </div>
            <div>
                <div>WORK EXPERIENCE</div>
                <div style={ { margin: '12px 0' } }><b>English teacher at Summit Public Schools</b></div>
                <div>High School 125 Kent Place Boulevard Summit New Jersey, United States - 07901</div>
                <div>2016 - 2020 (4 year experience)</div>
            </div>
            <div style={ { margin: '20px 0' } }><b>You can message Jenny regarding this school and learn about her experience working there</b></div>
            <button className="cardC2a">
                <img src={ Q } alt="" />
                Ask Questions
            </button>

            

        </div>
    )
}
