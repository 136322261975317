import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Login from './pages/login';
import Register from './pages/register';
import OnBoarding from './pages/OnBoarding';
import JobSeeker from './pages/Job seeker';
import Dashboard from './pages/Dashboard';
import Setting from './pages/Settings';
import ErrorPage from './pages/Error';
import Profile from './pages/profile/profile';
import NavBar from './pages/NavBar';
import SavedJobs from './pages/Saved Jobs';
import NoSavedJobs from './pages/NoSavedJobs';
import SearchJobs from './pages/Search-jobs';
import Jobs from './pages/Mob-Job';
import Recommend from './pages/Dashboard/recommand';
import PrivacyPolicy from './pages/PrivacyPolicy';

import VideoRecording from './pages/Video-recording/videoRecording';
import EmployerRegister from './pages/employer register';
import EmployerDashboard from './pages/employerDashboard/employerDashboard';
import Candidate from './pages/candidate';
import CandidateProfile from './pages/candidateProfile';

import EmployerMainDashboard from './pages/emp_dashboard';
import EmployerSettings from './pages/emp_settings';
import Message from './pages/message/message';
import MessageMsg from './pages/messageMsg/messageMsg';
import JobAlert from './pages/job-alert/job-alert';
import Index from './pages/index';
import AboutUs from './pages/about-us/aboutUs';
import EmployerLp from './pages/employerLp/employerLp';
import EmployerPreview from './pages/employer_preview';
import Favorite from './pages/Favorites/index';

import Blog from './pages/Blog';
import BlogDetail from './pages/BlogDetail';
import ForgotPass from './pages/forgot-password/forgotPassword';
import ActivityLog from './pages/activity-log/activityLog';
import FourOFour from './pages/404/404';
import FiveOFive from './pages/505/505';

import { LinkedInCallback } from "react-linkedin-login-oauth2";
import AllRequests from './pages/All-requests/AllRequests';
import SeekerProfile from './pages/Seeker-profile/SeekerProfile';
import CompareJob from './pages/Compare-page/index';
import ForgetPassword from './pages/forgot-password/forgotPassword';
import OpportunitiesHome from './pages/Posts/OpportunitiesHome';





const theme = createTheme({
  typography: {
      fontFamily: [
          'Montserrat',

      ].join(','),
  },
});

function App() {

  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <Router>
        <Switch>

        <Route exact path="/auth/linkedin/" component={LinkedInCallback} />


        <Route path='/login' exact component={Login}  />
        <Route path='/register' exact component={Register} />
        <Route path='/forget-password' exact component={ForgetPassword} />
        <Route path='/onboarding' exact component={()=><OnBoarding authorized={false} />}  />
        <Route path='/jobseeker' exact component={JobSeeker}  />
        <Route path='/dashboard' exact component={Dashboard}  />
        <Route path='/saved-jobs' exact component={SavedJobs}  />
        <Route path='/no-saved-jobs' exact component={NoSavedJobs}  />
        <Route path='/search-jobs' exact component={SearchJobs}  />
        <Route path='/job-detail/:id' exact component={Jobs}  />
        <Route path='/message' exact component={Message}  />
        <Route path='/messageMsg/:id/:id/:id' exact component={MessageMsg}  />
        <Route path='/job-alert' exact component={JobAlert}  />

        <Route path='/recommend' exact component={Recommend}  />
        <Route path='/setting/:id' component={Setting}  />
        <Route path='/setting/' exact component={Setting}  />
        <Route path='/profile' exact component={Profile}  />
        <Route path='/about-us' exact component={AboutUs}  />
        <Route path='/privacy-policy' exact component={() => <PrivacyPolicy tab='privacy-policy' />} />
        <Route path='/terms-conditions' exact component={() => <PrivacyPolicy tab='terms' />} />
        <Route path='/cookies' exact component={() => <PrivacyPolicy tab='cookies' />} />

        <Route path='/video-recording' exact component={VideoRecording}  />
        <Route path='/employer/register' exact component={EmployerRegister}  />
        <Route path='/employer/job-posting' exact component={EmployerDashboard}  />
        <Route path='/candidate-profile/:id' exact component={CandidateProfile}  />
        <Route path='/search-candidate' exact component={Candidate}  />
        <Route path='/forgot-password' exact component={ForgotPass}  />

        <Route path='/employer/dashboard' exact component={EmployerMainDashboard}  />
        <Route path='/employer/settings/:id' exact component={EmployerSettings}  />
        <Route path='/employer/settings' exact component={EmployerSettings}  />
        <Route path='/activity-log' exact component={ActivityLog}  />
        <Route path='/employerlp' exact component={EmployerLp}  />
        <Route path='/employer/preview/:id' exact component={EmployerPreview}  />
        <Route path='/' exact component={Index}  />
        <Route path='/employer/favorite' exact component={Favorite}  />


        <Route path='/blog' exact component={Blog}  />
        <Route path='/blog/:id' exact component={BlogDetail}  />

        <Route path='/navbar' exact component={NavBar}  />
        <Route path='/opportunities' exact component={()=><OpportunitiesHome tab="opportunities"/>}></Route>
        <Route path='/my-posts' exact component={()=><OpportunitiesHome tab="my-posts"/>}></Route>
        {/* <Route path='/all-requests' exact component={()=><Posts tab="all-requests"/>}></Route>
        <Route path='/my-profile' exact component={()=><Posts tab="my-profile"/>}></Route> */}

        <Route path='/requests' exact component={()=><AllRequests tab="requests"/>}></Route>
        <Route path='/myPosts' exact component={()=><AllRequests tab="myPosts"/>}></Route>
        <Route path='/myConnection' exact component={()=><AllRequests tab="myConnection"/>}></Route>
       
        
       {/* the route name is temporary   */}

       <Route path="/seeker-profile" exact component={SeekerProfile}></Route>

       <Route path='/compare-job' exact component={CompareJob}  />

        

       



        <Route path='/505' component={FiveOFive}  />
        <Route path='/*' component={FourOFour}  />
        

        </Switch>

      </Router>


    </div>
    </ThemeProvider>
  );
}

export default App;
