import { Container, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React, { useState } from 'react';
import PlusButton from './plusButton.js';
import './styles.css';

const faqContent =

    <div style={{ justifyContent: 'center', padding: '5% 10% 5% 10%' }}>

        <Container style={{ margin: 'auto', padding: '0' }} >
            <Grid spacing={2} container >
                <Grid item lg={3} md={3} sm={12} xs={12} style={{ padding: '3% 0%' }}>
                    <div id="list-example" className="list-group">
                        <a className="list-group-item list-group-item-action" href="#item-1">Employer</a>
                        <a className="list-group-item list-group-item-action" href="#item-2">Job Seeker</a>
                    </div>
                </Grid>
                <Grid item lg={9} md={9} sm={12} xs={12}>
                    <Grid container style={{ padding: '0% 5%' }}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <div data-spy="scroll" data-target="#list-example" data-offset={0} className="scrollspy-example">
                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: '3% 0%' }}>
                                    <div id="list-example" className="list-group">
                                        <a className="list-group-item list-group-item-action" href="#item-1">
                                            <PlusButton
                                                question="Frequently asked question goes here"
                                                answer="Answer goes here"
                                         /></a>
                                         <a className="list-group-item list-group-item-action" href="#item-1">
                                            <PlusButton
                                                question="Frequently asked question goes here"
                                                answer="Answer goes here"
                                         /></a>
                                         <a className="list-group-item list-group-item-action" href="#item-1">
                                            <PlusButton
                                                question="Frequently asked question goes here"
                                                answer="Answer goes here"
                                         /></a>
                                         <a className="list-group-item list-group-item-action" href="#item-1">
                                            <PlusButton
                                                question="Frequently asked question goes here"
                                                answer="Answer goes here"
                                         /></a>
                                         <a className="list-group-item list-group-item-action" href="#item-1">
                                            <PlusButton
                                                question="Frequently asked question goes here"
                                                answer="Answer goes here"
                                         /></a>
                                    </div>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>

    </div>

export default faqContent;