const activityLogStatement = (activity, type) => {

    switch(type){
      case 'job_create':
        return <div>Posted a job <a href={activity.job.url} style={{textDecoration: 'none', fontWeight: 'bold'}}>{activity.job.title}</a></div>
      case 'job_edit':
        return <div>Edited a job <a href={activity.job.url} style={{textDecoration: 'none', fontWeight: 'bold'}}>{activity.job.title}</a></div>
      case 'job_delete':
        return <div>Deleted a job <a href={activity.job.url} style={{textDecoration: 'none', fontWeight: 'bold'}}>{activity.job.title}</a></div>
      case 'role_create':
        return <div>Created a role <a href={activity.role.url} style={{textDecoration: 'none', fontWeight: 'bold'}}>{activity.role.name}</a></div>
      case 'role_edit':
        return <div>Edited a role <a href={activity.role.url} style={{textDecoration: 'none', fontWeight: 'bold'}}>{activity.role.name}</a></div>
      case 'role_delete':
        return <div>Deleted a role <a href={activity.role.url} style={{textDecoration: 'none', fontWeight: 'bold'}}>{activity.role.name}</a></div>
      case 'profile_edit':
        return <div><a style={{textDecoration: 'none', fontWeight: 'bold'}}>{activity.admin.name}</a> Edited the District Profile</div>
    }
  
  }
  
  const activityType = (activity) => {
    switch(activity){
      case 'job_create':
        return 'Job'
      case 'job_edit':
        return 'Job'
      case 'job_delete':
        return 'Job'
      case 'role_create':
        return 'Role'
      case 'role_edit':
        return 'Role'
      case 'role_delete':
        return 'Role'
      case 'profile_edit':
        return 'Profile'
    }
  }

  export {activityLogStatement, activityType};