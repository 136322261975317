import React, { useCallback, useState, useEffect } from 'react';
import { Box, Grid, TextField, Typography, Button, Input } from '@material-ui/core';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MailIcon from '@material-ui/icons/Mail';
import { styled, alpha } from '@mui/material/styles';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Divider from '@mui/material/Divider';
import Logo from './logo.svg';
import Profile from './profile.svg';
import Cookies from 'js-cookie';
import srch from './searchicon.svg';
import pin from './pincodeicon.svg';
import { useHistory } from 'react-router-dom';
import './style.css'
import Select from 'react-select';
import Modal from '@mui/material/Modal'
import MaintainenceGuy from '../../images/undraw_maintenance_re_59vn 1.png'
import axiosInstance from '../../axios';
import handleLogout from '../../Logout/logout';

import DrawerComponent from "./Drawer";
import {
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

const styles = {
  searchBoxBig: {
    fontSize: '14px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    background: '#fff',
    border: '1px solid #BEBEBE',
    borderRadius: '25px',
    paddingLeft: '2vh',
    paddingRight: '2vh',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  searchBoxSmall: {
    fontSize: '14px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    background: '#fff',
    border: '1px solid #BEBEBE',
    borderRadius: '25px',
    paddingLeft: '1em',
    paddingRight: '1em',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  srchField: {
    border: "none",
    borderColor: "transparent",
    outline: "none",
    width: "100%",
  },
  hiddenLabel: {
    height: 0,
    visibility: "hidden",
  },
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );



  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Locoptions = [
  { value: 'Alabama', label: 'Alabama' },
  { value: 'Alaska', label: 'Alaska' },
  { value: 'American Samoa', label: 'American Samoa' },
  { value: 'Arizona', label: 'Arizona' },
  { value: 'Arkansas', label: 'Arkansas' },
  { value: 'California', label: 'California' },
  { value: 'Colorado', label: 'Colorado' },
  { value: 'Connecticut', label: 'Connecticut' },
  { value: 'Delaware', label: 'Delaware' },
  { value: 'District Of Columbia', label: 'District Of Columbia' },
  { value: 'Federated States Of Micronesia', label: 'Federated States Of Micronesia' },
  { value: 'Florida', label: 'Florida' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Guam', label: 'Guam' },
  { value: 'Hawaii', label: 'Hawaii' },
  { value: 'Idaho', label: 'Idaho' },
  { value: 'Illinois', label: 'Illinois' },
  { value: 'Indiana', label: 'Indiana' },
  { value: 'Iowa', label: 'Iowa' },
  { value: 'Kansas', label: 'Kansas' },
  { value: 'Kentucky', label: 'Kentucky' },
  { value: 'Louisiana', label: 'Louisiana' },
  { value: 'Maine', label: 'Maine' },
  { value: 'Marshall Islands', label: 'Marshall Islands' },
  { value: 'Maryland', label: 'Maryland' },
  { value: 'Massachusetts', label: 'Massachusetts' },
  { value: 'Michigan', label: 'Michigan' },
  { value: 'Minnesota', label: 'Minnesota' },
  { value: 'Mississippi', label: 'Mississippi' },
  { value: 'Missouri', label: 'Missouri' },
  { value: 'Montana', label: 'Montana' },
  { value: 'Nebraska', label: 'Nebraska' },
  { value: 'Nevada', label: 'Nevada' },
  { value: 'New Hampshire', label: 'New Hampshire' },
  { value: 'New Jersey', label: 'New Jersey' },
  { value: 'New Mexico', label: 'New Mexico' },
  { value: 'New York', label: 'New York' },
  { value: 'North Carolina', label: 'North Carolina' },
  { value: 'North Dakota', label: 'North Dakota' },
  { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
  { value: 'Ohio', label: 'Ohio' },
  { value: 'Oklahoma', label: 'Oklahoma' },
  { value: 'Oregon', label: 'Oregon' },
  { value: 'Palau', label: 'Palau' },
  { value: 'Pennsylvania', label: 'Pennsylvania' },
  { value: 'Puerto Rico', label: 'Puerto Rico' },
  { value: 'Rhode Island', label: 'Rhode Island' },
  { value: 'South Carolina', label: 'South Carolina' },
  { value: 'South Dakota', label: 'South Dakota' },
  { value: 'Tennessee', label: 'Tennessee' },
  { value: 'Texas', label: 'Texas' },
  { value: 'Utah', label: 'Utah' },
  { value: 'Vermont', label: 'Vermont' },
  { value: 'Virgin Islands', label: 'Virgin Islands' },
  { value: 'Virginia', label: 'Virginia' },
  { value: 'Washington', label: 'Washington' },
  { value: 'West Virginia', label: 'West Virginia' },
  { value: 'Wisconsin', label: 'Wisconsin' },
  { value: 'Wyoming', label: 'Wyoming' },
];

const jobTitlesArr = [
  {value: 'Athletic Coach', label: 'Athletic Coach'},
  {value: 'Athletic Coordinator', label: 'Athletic Coordinator'},
  {value: 'Athletic Director', label: 'Athletic Director'},
  {value: 'Secretary to Director of Athletics', label: 'Secretary to Director of Athletics'},
  {value: 'Administrative Assistant To Director Of Facilities', label: 'Administrative Assistant To Director Of Facilities'},
  {value: 'Administrative Assistant To The Safety And Security Director', label: 'Administrative Assistant To The Safety And Security Director'},
  {value: 'Asst. Superintendent-Building And Grounds', label: 'Asst. Superintendent-Building And Grounds'},
  {value: 'Buildings And Grounds Administrative Staff', label: 'Buildings And Grounds Administrative Staff'},
  {value: 'Buildings And Grounds Coordinator', label: 'Buildings And Grounds Coordinator'},
  {value: 'Buildings And Grounds Director', label: 'Buildings And Grounds Director'},
  {value: 'Capital Projects', label: 'Capital Projects'},
  {value: 'Capital Projects Coordinator', label: 'Capital Projects Coordinator'},
  {value: 'Capital Projects Director', label: 'Capital Projects Director'},
  {value: 'Custodian', label: 'Custodian'},
  {value: 'Facilities And Maintenance', label: 'Facilities And Maintenance'},
  {value: 'Facilities And Maintenance Coordinator', label: 'Facilities And Maintenance Coordinator'},
  {value: 'Facilities And Maintenance Director', label: 'Facilities And Maintenance Director'},
  {value: 'Maintenance Secretary', label: 'Maintenance Secretary'},
  {value: 'Planning and Construction', label: 'Planning and Construction'},
  {value: 'Planning and Construction Coordinator', label: 'Planning and Construction Coordinator'},
  {value: 'Planning And Construction Director', label: 'Planning And Construction Director'},
  {value: 'Safety And Security', label: 'Safety And Security'},
  {value: 'Safety And Security Coordinator', label: 'Safety And Security Coordinator'},
  {value: 'Safety And Security Director', label: 'Safety And Security Director'},
  {value: 'Warehouse Coordinator', label: 'Warehouse Coordinator'},
  {value: 'Warehouse Services Director', label: 'Warehouse Services Director'},
  {value: 'Assistant to the Business Administrator', label: 'Assistant to the Business Administrator'},
  {value: 'Assistant To The Business Director', label: 'Assistant To The Business Director'},
  {value: 'Assistant to the Director of Operations', label: 'Assistant to the Director of Operations'},
  {value: 'Asst. Superintendent-Business and Finance', label: 'Asst. Superintendent-Business and Finance'},
  {value: 'Attorney / Legal Services', label: 'Attorney / Legal Services'},
  {value: 'Budget and Finance', label: 'Budget and Finance'},
  {value: 'Business Coordinator', label: 'Business Coordinator'},
  {value: 'Business Director', label: 'Business Director'},
  {value: 'Finance and Accounting', label: 'Finance and Accounting'},
  {value: 'Finance and Accounting Coordinator', label: 'Finance and Accounting Coordinator'},
  {value: 'Finance and Accounting Director', label: 'Finance and Accounting Director'},
  {value: 'Purchasing Administrative Staff', label: 'Purchasing Administrative Staff'},
  {value: 'Purchasing Agent', label: 'Purchasing Agent'},
  {value: 'Purchasing Coordinator', label: 'Purchasing Coordinator'},
  {value: 'Purchasing Director', label: 'Purchasing Director'},
  {value: 'Academic Administrative Assistant', label: 'Academic Administrative Assistant'},
  {value: 'Academic Coordinator', label: 'Academic Coordinator'},
  {value: 'Academic Director', label: 'Academic Director'},
  {value: 'Administrative Assistant - Early Childhood Education', label: 'Administrative Assistant - Early Childhood Education'},
  {value: 'Administrative Assistant - Teaching And Learning', label: 'Administrative Assistant - Teaching And Learning'},
  {value: 'Administrative Assistant Curriculum and Instruction', label: 'Administrative Assistant Curriculum and Instruction'},
  {value: 'Administrative Assistant To The Director Of Instruction / Curriculum', label: 'Administrative Assistant To The Director Of Instruction / Curriculum'},
  {value: 'Adult and Continuing Education', label: 'Adult and Continuing Education'},
  {value: 'Adult and Continuing Education Coordinator', label: 'Adult and Continuing Education Coordinator'},
  {value: 'Adult and Continuing Education Director', label: 'Adult and Continuing Education Director'},
  {value: 'Agricultural Education', label: 'Agricultural Education'},
  {value: 'Agricultural Education Coordinator', label: 'Agricultural Education Coordinator'},
  {value: 'Agricultural Education Director', label: 'Agricultural Education Director'},
  {value: 'Alternative Education', label: 'Alternative Education'},
  {value: 'Alternative Education Coordinator', label: 'Alternative Education Coordinator'},
  {value: 'Alternative Education Director', label: 'Alternative Education Director'},
  {value: 'American Sign Language Coordinator', label: 'American Sign Language Coordinator'},
  {value: 'Assessment and Accountability', label: 'Assessment and Accountability'},
  {value: 'Assessment and Accountability Coordinator', label: 'Assessment and Accountability Coordinator'},
  {value: 'Assessment and Accountability Director', label: 'Assessment and Accountability Director'},
  {value: 'Asst. Superintendent-Elementary Education', label: 'Asst. Superintendent-Elementary Education'},
  {value: 'Asst. Superintendent-Instruction / Curriculum', label: 'Asst. Superintendent-Instruction / Curriculum'},
  {value: 'Asst. Superintendent-Secondary Education', label: 'Asst. Superintendent-Secondary Education'},
  {value: 'Bilingual Education', label: 'Bilingual Education'},
  {value: 'Bilingual Education Coordinator', label: 'Bilingual Education Coordinator'},
  {value: 'Bilingual Education Director', label: 'Bilingual Education Director'},
  {value: 'Career And Technical Education', label: 'Career And Technical Education'},
  {value: 'Career And Technical Education Coordinator', label: 'Career And Technical Education Coordinator'},
  {value: 'Career and Technical Education Curriculum', label: 'Career and Technical Education Curriculum'},
  {value: 'Career And Technical Education Director', label: 'Career And Technical Education Director'},
  {value: 'Character Counselor', label: 'Character Counselor'},
  {value: 'College Readiness', label: 'College Readiness'},
  {value: 'College Readiness Coordinator', label: 'College Readiness Coordinator'},
  {value: 'College Readiness Director', label: 'College Readiness Director'},
  {value: 'Common Core Curriculum', label: 'Common Core Curriculum'},
  {value: 'Common Core Curriculum Coordinator', label: 'Common Core Curriculum Coordinator'},
  {value: 'Common Core Curriculum Director', label: 'Common Core Curriculum Director'},
  {value: 'Curriculum-Elementary', label: 'Curriculum-Elementary'},
  {value: 'Curriculum-Elementary Coordinator', label: 'Curriculum-Elementary Coordinator'},
  {value: 'Curriculum-K-12', label: 'Curriculum-K-12'},
  {value: 'Curriculum-Secondary', label: 'Curriculum-Secondary'},
  {value: 'Curriculum-Secondary Coordinator', label: 'Curriculum-Secondary Coordinator'},
  {value: 'Digital Content & Distance Learning', label: 'Digital Content & Distance Learning'},
  {value: 'Digital Content & Distance Learning Coordinator', label: 'Digital Content & Distance Learning Coordinator'},
  {value: 'Digital Content & Distance Learning Director', label: 'Digital Content & Distance Learning Director'},
  {value: 'District Reading Coordinator', label: 'District Reading Coordinator'},
  {value: 'Early Childhood Education', label: 'Early Childhood Education'},
  {value: 'Early Childhood Education Coordinator', label: 'Early Childhood Education Coordinator'},
  {value: 'Early Childhood Education Curriculum', label: 'Early Childhood Education Curriculum'},
  {value: 'Early Childhood Education Director', label: 'Early Childhood Education Director'},
  {value: 'Education Specialist', label: 'Education Specialist'},
  {value: 'Elementary Education Coordinator', label: 'Elementary Education Coordinator'},
  {value: 'Elementary Education Director', label: 'Elementary Education Director'},
  {value: 'English / Lang Arts', label: 'English / Lang Arts'},
  {value: 'English / Lang Arts Coordinator', label: 'English / Lang Arts Coordinator'},
  {value: 'Fine And Performing Arts', label: 'Fine And Performing Arts'},
  {value: 'Fine And Performing Arts Coordinator', label: 'Fine And Performing Arts Coordinator'},
  {value: 'Fine And Performing Arts Director', label: 'Fine And Performing Arts Director'},
  {value: 'Benefits Coordinator', label: 'Benefits Coordinator'},
  {value: 'Fine Arts Curriculum', label: 'Fine Arts Curriculum'},
  {value: 'Fine Arts Curriculum Coordinator', label: 'Fine Arts Curriculum Coordinator'},
  {value: 'Fine Arts Curriculum Director', label: 'Fine Arts Curriculum Director'},
  {value: 'Foreign Language', label: 'Foreign Language'},
  {value: 'Foreign Languages Curriculum Coordinator', label: 'Foreign Languages Curriculum Coordinator'},
  {value: 'Foreign Languages Curriculum Director', label: 'Foreign Languages Curriculum Director'},
  {value: 'Gifted & Talented Curriculum', label: 'Gifted & Talented Curriculum'},
  {value: 'Gifted and Talented Curriculum Coordinator', label: 'Gifted and Talented Curriculum Coordinator'},
  {value: 'Gifted And Talented Dept Chairperson', label: 'Gifted And Talented Dept Chairperson'},
  {value: 'Head Start', label: 'Head Start'},
  {value: 'Head Start Coordinator', label: 'Head Start Coordinator'},
  {value: 'Head Start Director', label: 'Head Start Director'},
  {value: 'Innovative Programs Curriculum', label: 'Innovative Programs Curriculum'},
  {value: 'Innovative Programs Curriculum Coordinator', label: 'Innovative Programs Curriculum Coordinator'},
  {value: 'Innovative Programs Curriculum Director', label: 'Innovative Programs Curriculum Director'},
  {value: 'Instruction / Curriculum', label: 'Instruction / Curriculum'},
  {value: 'Instruction / Curriculum Coach', label: 'Instruction / Curriculum Coach'},
  {value: 'Instruction / Curriculum Coordinator', label: 'Instruction / Curriculum Coordinator'},
  {value: 'Instruction / Curriculum Director', label: 'Instruction / Curriculum Director'},
  {value: 'K-12 Education Coordinator', label: 'K-12 Education Coordinator'},
  {value: 'K-12 Education Director', label: 'K-12 Education Director'},
  {value: 'Language Arts / English Director, K-12', label: 'Language Arts / English Director, K-12'},
  {value: 'Language Arts / English, K-12', label: 'Language Arts / English, K-12'},
  {value: 'Literacy Curriculum', label: 'Literacy Curriculum'},
  {value: 'Literacy Curriculum Coordinator', label: 'Literacy Curriculum Coordinator'},
  {value: 'Literacy Curriculum Director', label: 'Literacy Curriculum Director'},
  {value: 'Mathematics Director, K-12', label: 'Mathematics Director, K-12'},
  {value: 'Mathematics, K-12', label: 'Mathematics, K-12'},
  {value: 'Mathematics, K-12 Coordinator', label: 'Mathematics, K-12 Coordinator'},
  {value: 'Multicultural Education', label: 'Multicultural Education'},
  {value: 'Multicultural Education Coordinator', label: 'Multicultural Education Coordinator'},
  {value: 'Multicultural Education Director', label: 'Multicultural Education Director'},
  {value: 'Music', label: 'Music'},
  {value: 'Music Department Chairperson', label: 'Music Department Chairperson'},
  {value: 'Music Department Coordinator', label: 'Music Department Coordinator'},
  {value: 'Outdoor Education', label: 'Outdoor Education'},
  {value: 'Outdoor Education Coordinator', label: 'Outdoor Education Coordinator'},
  {value: 'Outdoor Education Director', label: 'Outdoor Education Director'},
  {value: 'Reading', label: 'Reading'},
  {value: 'Reading Intervention Director', label: 'Reading Intervention Director'},
  {value: 'Reading Interventionist', label: 'Reading Interventionist'},
  {value: 'Science Coordinator', label: 'Science Coordinator'},
  {value: 'Science Department', label: 'Science Department'},
  {value: 'Science Department Chairperson', label: 'Science Department Chairperson'},
  {value: 'Science, K-12', label: 'Science, K-12'},
  {value: 'Secondary Education Director', label: 'Secondary Education Director'},
  {value: 'Social Studies', label: 'Social Studies'},
  {value: 'Social Studies Coordinator', label: 'Social Studies Coordinator'},
  {value: 'Social Studies Department Chairperson', label: 'Social Studies Department Chairperson'},
  {value: 'Stem Curriculum', label: 'Stem Curriculum'},
  {value: 'Stem Curriculum Coordinator', label: 'Stem Curriculum Coordinator'},
  {value: 'Stem Director', label: 'Stem Director'},
  {value: 'Teaching And Learning', label: 'Teaching And Learning'},
  {value: 'Teaching And Learning Coordinator', label: 'Teaching And Learning Coordinator'},
  {value: 'Teaching And Learning Director', label: 'Teaching And Learning Director'},
  {value: 'Textbook', label: 'Textbook'},
  {value: 'Textbook Coordinator', label: 'Textbook Coordinator'},
  {value: 'Textbook Director', label: 'Textbook Director'},
  {value: 'Vocational Education', label: 'Vocational Education'},
  {value: 'Vocational Education Coordinator', label: 'Vocational Education Coordinator'},
  {value: 'Vocational Education Director', label: 'Vocational Education Director'},
  {value: 'Asst. Superintendent', label: 'Asst. Superintendent'},
  {value: 'Asst. Superintendent-Administration', label: 'Asst. Superintendent-Administration'},
  {value: 'Asst. Superintendent-Human Resources', label: 'Asst. Superintendent-Human Resources'},
  {value: 'Asst. Superintendent-Student Services', label: 'Asst. Superintendent-Student Services'},
  {value: 'Board Clerk', label: 'Board Clerk'},
  {value: 'Board Member', label: 'Board Member'},
  {value: 'Board President', label: 'Board President'},
  {value: 'Board Secretary', label: 'Board Secretary'},
  {value: 'Board Treasurer', label: 'Board Treasurer'},
  {value: 'Board Vice President', label: 'Board Vice President'},
  {value: 'Chief Academic Officer', label: 'Chief Academic Officer'},
  {value: 'Chief Administrative Officer', label: 'Chief Administrative Officer'},
  {value: 'Chief Educational Officer', label: 'Chief Educational Officer'},
  {value: 'Chief Executive Officer', label: 'Chief Executive Officer'},
  {value: 'Chief Financial Officer', label: 'Chief Financial Officer'},
  {value: 'Chief Of Staff', label: 'Chief Of Staff'},
  {value: 'Chief Operating Officer', label: 'Chief Operating Officer'},
  {value: 'Dean', label: 'Dean'},
  {value: 'Dean Of Students', label: 'Dean Of Students'},
  {value: 'Superintendent', label: 'Superintendent'},
  {value: 'Administrative Assist. To The Board', label: 'Administrative Assist. To The Board'},
  {value: 'Administrative Assist. To The Chief Academic Officer', label: 'Administrative Assist. To The Chief Academic Officer'},
  {value: 'Administrative Assist. To The Chief Executive Officer', label: 'Administrative Assist. To The Chief Executive Officer'},
  {value: 'Administrative Assist. To The Chief Financial Officer', label: 'Administrative Assist. To The Chief Financial Officer'},
  {value: 'Administrative Assist. To The Director Of Professional Development', label: 'Administrative Assist. To The Director Of Professional Development'},
  {value: 'Administrative Employee-Pupil Personnel', label: 'Administrative Employee-Pupil Personnel'},
  {value: 'Admissions', label: 'Admissions'},
  {value: 'Admissions Coordinator', label: 'Admissions Coordinator'},
  {value: 'Admissions Director', label: 'Admissions Director'},
  {value: 'Advancement', label: 'Advancement'},
  {value: 'Advancement Coordinator', label: 'Advancement Coordinator'},
  {value: 'Advancement Director', label: 'Advancement Director'},
  {value: 'Assist. Superintendent-Professional Development', label: 'Assist. Superintendent-Professional Development'},
  {value: 'Asst. Superintendent-Pupil Personnel', label: 'Asst. Superintendent-Pupil Personnel'},
  {value: 'Asst. Superintendents - Operations', label: 'Asst. Superintendents - Operations'},
  {value: 'Attendance', label: 'Attendance'},
  {value: 'Attendance Coordinator', label: 'Attendance Coordinator'},
  {value: 'Attendance Director', label: 'Attendance Director'},
  {value: 'Benefits Administrative Assist.', label: 'Benefits Administrative Assist.'},
  {value: 'Benefits Director', label: 'Benefits Director'},
  {value: 'Charter Schools Coordinator', label: 'Charter Schools Coordinator'},
  {value: 'Development Coordinator', label: 'Development Coordinator'},
  {value: 'Development Director', label: 'Development Director'},
  {value: 'District Administration', label: 'District Administration'},
  {value: 'District Administration Coordinator', label: 'District Administration Coordinator'},
  {value: 'District Administration Director', label: 'District Administration Director'},
  {value: 'District Administrator', label: 'District Administrator'},
  {value: 'District Compliance', label: 'District Compliance'},
  {value: 'District Compliance Coordinator', label: 'District Compliance Coordinator'},
  {value: 'District Compliance Director', label: 'District Compliance Director'},
  {value: 'Executive Assistant To The Superintendent', label: 'Executive Assistant To The Superintendent'},
  {value: 'Food Service / Cafeteria Manager', label: 'Food Service / Cafeteria Manager'},
  {value: 'Food Services', label: 'Food Services'},
  {value: 'Food Services Coordinator', label: 'Food Services Coordinator'},
  {value: 'Food Services Director', label: 'Food Services Director'},
  {value: 'Human Resources', label: 'Human Resources'},
  {value: 'Human Resources Administrative Assistant', label: 'Human Resources Administrative Assistant'},
  {value: 'Human Resources Coordinator', label: 'Human Resources Coordinator'},
  {value: 'Human Resources Director', label: 'Human Resources Director'},
  {value: 'Operations', label: 'Operations'},
  {value: 'Operations Coordinator', label: 'Operations Coordinator'},
  {value: 'Operations Director', label: 'Operations Director'},
  {value: 'Organizational Development', label: 'Organizational Development'},
  {value: 'Organizational Development Coordinator', label: 'Organizational Development Coordinator'},
  {value: 'Organizational Development Director', label: 'Organizational Development Director'},
  {value: 'Paraprofessional / Instructional Aide', label: 'Paraprofessional / Instructional Aide'},
  {value: 'Professional Development', label: 'Professional Development'},
  {value: 'Professional Development Coordinator', label: 'Professional Development Coordinator'},
  {value: 'Professional Development Director', label: 'Professional Development Director'},
  {value: 'Publicity And Communications', label: 'Publicity And Communications'},
  {value: 'Publicity And Communications Coordinator', label: 'Publicity And Communications Coordinator'},
  {value: 'Publicity And Communications Director', label: 'Publicity And Communications Director'},
  {value: 'Pupil Personnel', label: 'Pupil Personnel'},
  {value: 'Pupil Personnel Coordinator', label: 'Pupil Personnel Coordinator'},
  {value: 'Pupil Personnel Director', label: 'Pupil Personnel Director'},
  {value: 'Risk Management', label: 'Risk Management'},
  {value: 'Risk Management Coordinator', label: 'Risk Management Coordinator'},
  {value: 'Risk Management Director', label: 'Risk Management Director'},
  {value: 'School Improvement', label: 'School Improvement'},
  {value: 'School Improvement Coordinator', label: 'School Improvement Coordinator'},
  {value: 'School Improvement Director', label: 'School Improvement Director'},
  {value: 'Student Placement', label: 'Student Placement'},
  {value: 'Student Placement Coordinator', label: 'Student Placement Coordinator'},
  {value: 'Student Placement Director', label: 'Student Placement Director'},
  {value: '21St Cclc Coordinator', label: '21St Cclc Coordinator'},
  {value: '21St Cclc Director', label: '21St Cclc Director'},
  {value: 'Administrative Assistant For Grants / Federal Programs', label: 'Administrative Assistant For Grants / Federal Programs'},
  {value: 'Administrative Assistant Of Federal Programs', label: 'Administrative Assistant Of Federal Programs'},
  {value: 'Administrative Assistant Of Special Services', label: 'Administrative Assistant Of Special Services'},
  {value: 'Administrative Assistant Title I Instructional', label: 'Administrative Assistant Title I Instructional'},
  {value: 'Administrative Assistant Title Iii', label: 'Administrative Assistant Title Iii'},
  {value: 'Asst. Superintendent-Federal Programs', label: 'Asst. Superintendent-Federal Programs'},
  {value: 'Business Partnerships & Grants', label: 'Business Partnerships & Grants'},
  {value: 'Business Partnerships & Grants Director', label: 'Business Partnerships & Grants Director'},
  {value: 'Diagnostician / Testing', label: 'Diagnostician / Testing'},
  {value: 'Diagnostician / Testing Coordinator', label: 'Diagnostician / Testing Coordinator'},
  {value: 'Diagnostician / Testing Director', label: 'Diagnostician / Testing Director'},
  {value: 'Federal And State Programs', label: 'Federal And State Programs'},
  {value: 'Federal And State Programs Director', label: 'Federal And State Programs Director'},
  {value: 'Federal Programs And Grants Coordinator', label: 'Federal Programs And Grants Coordinator'},
  {value: 'Gear Up Coordinator', label: 'Gear Up Coordinator'},
  {value: 'Grants', label: 'Grants'},
  {value: 'Grants And Funded Programs Director', label: 'Grants And Funded Programs Director'},
  {value: 'Grants Specialist', label: 'Grants Specialist'},
  {value: 'Guidance & Counseling Coordinator', label: 'Guidance & Counseling Coordinator'},
  {value: 'Guidance & Counseling Director', label: 'Guidance & Counseling Director'},
  {value: 'Guidance & Counseling, Elementary', label: 'Guidance & Counseling, Elementary'},
  {value: 'Guidance & Counseling, K-12', label: 'Guidance & Counseling, K-12'},
  {value: 'Guidance & Counseling, Secondary', label: 'Guidance & Counseling, Secondary'},
  {value: 'Guidance Counselor', label: 'Guidance Counselor'},
  {value: 'Psychologist', label: 'Psychologist'},
  {value: 'Psychologist Coordinator', label: 'Psychologist Coordinator'},
  {value: 'Race To The Top (Rttt) Coordinator', label: 'Race To The Top (Rttt) Coordinator'},
  {value: 'Race To The Top (Rttt) Director', label: 'Race To The Top (Rttt) Director'},
  {value: 'School Psychology Director', label: 'School Psychology Director'},
  {value: 'Secretary To Psychologist', label: 'Secretary To Psychologist'},
  {value: 'Secretary To Title I', label: 'Secretary To Title I'},
  {value: 'Student Assessment', label: 'Student Assessment'},
  {value: 'Student Assessment Coordinator', label: 'Student Assessment Coordinator'},
  {value: 'Title I Coordinator', label: 'Title I Coordinator'},
  {value: 'Title I Director', label: 'Title I Director'},
  {value: 'Title II Coordinator', label: 'Title II Coordinator'},
  {value: 'Title II Director', label: 'Title II Director'},
  {value: 'Title III Coordinator', label: 'Title III Coordinator'},
  {value: 'Title III Director', label: 'Title III Director'},
  {value: 'Title IV Coordinator', label: 'Title IV Coordinator'},
  {value: 'Title IX Coordinator', label: 'Title IX Coordinator'},
  {value: 'Title IX Director', label: 'Title IX Director'},
  {value: 'Title VI Coordinator', label: 'Title VI Coordinator'},
  {value: 'Title VII Coordinator', label: 'Title VII Coordinator'},
  {value: 'Title VII Director', label: 'Title VII Director'},
  {value: 'Title X Coordinator', label: 'Title X Coordinator'},
  {value: 'Child Nutrition', label: 'Child Nutrition'},
  {value: 'Child Nutrition Coordinator', label: 'Child Nutrition Coordinator'},
  {value: 'Child Nutrition Director', label: 'Child Nutrition Director'},
  {value: 'Health Aide', label: 'Health Aide'},
  {value: 'Health And Physical Education', label: 'Health And Physical Education'},
  {value: 'Health And Physical Education Coordinator', label: 'Health And Physical Education Coordinator'},
  {value: 'Health And Physical Education Director', label: 'Health And Physical Education Director'},
  {value: 'Health Services Coordinator', label: 'Health Services Coordinator'},
  {value: 'Health Services Director', label: 'Health Services Director'},
  {value: 'Health Services Secretary', label: 'Health Services Secretary'},
  {value: 'Health Services Staff', label: 'Health Services Staff'},
  {value: 'Nurse', label: 'Nurse'},
  {value: 'Wellness', label: 'Wellness'},
  {value: 'Wellness Coordinator', label: 'Wellness Coordinator'},
  {value: 'Wellness Director', label: 'Wellness Director'},
  {value: 'Asst. Superintendent-Special Education', label: 'Asst. Superintendent-Special Education'},
  {value: 'Asst. Superintendent-Special Services', label: 'Asst. Superintendent-Special Services'},
  {value: 'Autism', label: 'Autism'},
  {value: 'Autism Coordinator', label: 'Autism Coordinator'},
  {value: 'Autism Director', label: 'Autism Director'},
  {value: 'Disability Services', label: 'Disability Services'},
  {value: 'Disability Services Coordinator', label: 'Disability Services Coordinator'},
  {value: 'Disability Services Director', label: 'Disability Services Director'},
  {value: 'District Rti', label: 'District Rti'},
  {value: 'District Rti Coordinator', label: 'District Rti Coordinator'},
  {value: 'District Rti Director', label: 'District Rti Director'},
  {value: 'Occupational And Physical Therapy', label: 'Occupational And Physical Therapy'},
  {value: 'Occupational And Physical Therapy Coordinator', label: 'Occupational And Physical Therapy Coordinator'},
  {value: 'Occupational And Physical Therapy Director', label: 'Occupational And Physical Therapy Director'},
  {value: 'Occupational Therapist', label: 'Occupational Therapist'},
  {value: 'Physical Therapist', label: 'Physical Therapist'},
  {value: 'Special Education Administrative Assistant', label: 'Special Education Administrative Assistant'},
  {value: 'Special Education Aide', label: 'Special Education Aide'},
  {value: 'Special Education Assistant Director', label: 'Special Education Assistant Director'},
  {value: 'Special Education Coordinator', label: 'Special Education Coordinator'},
  {value: 'Special Education Director', label: 'Special Education Director'},
  {value: 'Special Education Instructor', label: 'Special Education Instructor'},
  {value: 'Speech And Hearing', label: 'Speech And Hearing'},
  {value: 'Speech And Hearing Coordinator', label: 'Speech And Hearing Coordinator'},
  {value: 'Speech And Hearing Director', label: 'Speech And Hearing Director'},
  {value: 'Speech and Language Therapist', label: 'Speech and Language Therapist'},
  {value: 'Speech Pathologist', label: 'Speech Pathologist'},
  {value: 'Speech Pathologist Coordinator', label: 'Speech Pathologist Coordinator'},
  {value: 'Speech Pathology Director', label: 'Speech Pathology Director'},
  {value: 'Speech Therapist', label: 'Speech Therapist'},
  {value: 'Activities Coordinator', label: 'Activities Coordinator'},
  {value: 'Activities Director', label: 'Activities Director'},
  {value: 'Before And After School', label: 'Before And After School'},
  {value: 'Before And After School Coordinator', label: 'Before And After School Coordinator'},
  {value: 'Before And After School Director', label: 'Before And After School Director'},
  {value: 'Coach', label: 'Coach'},
  {value: 'Student Activities Coordinator', label: 'Student Activities Coordinator'},
  {value: 'Student Activities Staff', label: 'Student Activities Staff'},
  {value: '504 Coordinator', label: '504 Coordinator'},
  {value: '504 Director', label: '504 Director'},
  {value: 'Aboriginal / Native Education', label: 'Aboriginal / Native Education'},
  {value: 'Aboriginal / Native Education Coordinator', label: 'Aboriginal / Native Education Coordinator'},
  {value: 'Aboriginal / Native Education Director', label: 'Aboriginal / Native Education Director'},
  {value: 'Administrative Assistant For Student Services', label: 'Administrative Assistant For Student Services'},
  {value: 'Administrative Assistant To The Director Of Aboriginal / Native Education', label: 'Administrative Assistant To The Director Of Aboriginal / Native Education'},
  {value: 'Administrative Assistant To The Director Of Student Services & Records', label: 'Administrative Assistant To The Director Of Student Services & Records'},
  {value: 'At-Risk Coordinator', label: 'At-Risk Coordinator'},
  {value: 'At-Risk Director', label: 'At-Risk Director'},
  {value: 'Attendance And Discipline', label: 'Attendance And Discipline'},
  {value: 'Attendance And Discipline Coordinator', label: 'Attendance And Discipline Coordinator'},
  {value: 'Attendance And Discipline Director', label: 'Attendance And Discipline Director'},
  {value: 'Community Education Coordinator', label: 'Community Education Coordinator'},
  {value: 'Community Education Director', label: 'Community Education Director'},
  {value: 'Community Relations', label: 'Community Relations'},
  {value: 'HIV / AIDS', label: 'HIV / AIDS'},
  {value: 'HIV / AIDS Coordinator', label: 'HIV / AIDS Coordinator'},
  {value: 'Home School', label: 'Home School'},
  {value: 'Home School Coordinator', label: 'Home School Coordinator'},
  {value: 'Home School Director', label: 'Home School Director'},
  {value: 'Homeless And Migrant Education', label: 'Homeless And Migrant Education'},
  {value: 'Homeless And Migrant Education Coordinator', label: 'Homeless And Migrant Education Coordinator'},
  {value: 'Homeless And Migrant Education Director', label: 'Homeless And Migrant Education Director'},
  {value: 'International Education', label: 'International Education'},
  {value: 'International Education Coordinator', label: 'International Education Coordinator'},
  {value: 'International Education Director', label: 'International Education Director'},
  {value: 'Parent And Community Relations', label: 'Parent And Community Relations'},
  {value: 'Parent And Community Relations Director', label: 'Parent And Community Relations Director'},
  {value: 'Parent And Community Resource Director', label: 'Parent And Community Resource Director'},
  {value: 'Parent / Community Resource Coordinator', label: 'Parent / Community Resource Coordinator'},
  {value: 'Positive Behavior Interventions & Supports', label: 'Positive Behavior Interventions & Supports'},
  {value: 'Positive Behavior Interventions & Supports Coordinator', label: 'Positive Behavior Interventions & Supports Coordinator'},
  {value: 'Safe & Drug Free Schools', label: 'Safe & Drug Free Schools'},
  {value: 'Safe & Drug Free Schools Coordinator', label: 'Safe & Drug Free Schools Coordinator'},
  {value: 'Safe & Drug Free Schools Director', label: 'Safe & Drug Free Schools Director'},
  {value: 'School Social Worker', label: 'School Social Worker'},
  {value: 'School Social Worker Coordinator', label: 'School Social Worker Coordinator'},
  {value: 'School Social Worker Director', label: 'School Social Worker Director'},
  {value: 'Student Records', label: 'Student Records'},
  {value: 'Student Records Coordinator', label: 'Student Records Coordinator'},
  {value: 'Student Services & Records', label: 'Student Services & Records'},
  {value: 'Student Services & Records Coordinator', label: 'Student Services & Records Coordinator'},
  {value: 'Student Services & Records Director', label: 'Student Services & Records Director'},
  {value: 'Assistant Superintendent Of Administration And Technology', label: 'Assistant Superintendent Of Administration And Technology'},
  {value: 'Automotive Technology', label: 'Automotive Technology'},
  {value: 'Data Management Coordinator', label: 'Data Management Coordinator'},
  {value: 'Data Management Director', label: 'Data Management Director'},
  {value: 'District Network Administrator', label: 'District Network Administrator'},
  {value: 'Education Management Information System Coordinator', label: 'Education Management Information System Coordinator'},
  {value: 'Information Technology Coordinator', label: 'Information Technology Coordinator'},
  {value: 'Information Technology Director', label: 'Information Technology Director'},
  {value: 'Instructional Technology Coordinator', label: 'Instructional Technology Coordinator'},
  {value: 'Instructional Technology Director', label: 'Instructional Technology Director'},
  {value: 'Librarian', label: 'Librarian'},
  {value: 'Library And Media Services', label: 'Library And Media Services'},
  {value: 'Library And Media Services Coordinator', label: 'Library And Media Services Coordinator'},
  {value: 'Library And Media Services Director', label: 'Library And Media Services Director'},
  {value: 'Secretary To The Information Director Of Technology', label: 'Secretary To The Information Director Of Technology'},
  {value: 'Technology And IT', label: 'Technology And IT'},
  {value: 'Technology Technician', label: 'Technology Technician'},
  {value: 'Administrative Secretary Of Transportation', label: 'Administrative Secretary Of Transportation'},
  {value: 'Bus Driver', label: 'Bus Driver'},
  {value: 'Driver Instructor', label: 'Driver Instructor'},
  {value: 'Transportation Administrator', label: 'Transportation Administrator'},
  {value: 'Transportation Coordinator', label: 'Transportation Coordinator'},
  {value: 'Transportation Director', label: 'Transportation Director'},
  {value: 'Transportation Staff', label: 'Transportation Staff'},
  {value: 'Food service worker', label: 'Food service worker'},
  {value: 'Custodian', label: 'Custodian'},
  {value: 'Teacher', label: 'Teacher'},
  {value: 'Teaching assistant', label: 'Teaching assistant'},
  {value: 'Crossing guard', label: 'Crossing guard'},
  {value: 'Sports coach', label: 'Sports coach'},
  {value: 'School bus driver', label: 'School bus driver'},
  {value: 'Academic adviser', label: 'Academic adviser'},
  {value: 'Groundskeeper', label: 'Groundskeeper'},
  {value: 'Maintenance technician', label: 'Maintenance technician'},
  {value: 'Registrar', label: 'Registrar'},
  {value: 'Academic coordinator', label: 'Academic coordinator'},
  {value: 'Special education teacher', label: 'Special education teacher'},
  {value: 'School nurse', label: 'School nurse'},
  {value: 'Webmaster', label: 'Webmaster'},
  {value: 'Guidance counselor', label: 'Guidance counselor'},
  {value: 'Athletic director', label: 'Athletic director'},
  {value: 'Vice principal', label: 'Vice principal'},
  {value: 'Superintendent', label: 'Superintendent'},
  {value: 'Principal', label: 'Principal'},
]

const SearchBarBig = () => {

  let history = useHistory();

  // const submitQuery = () => {
  //   // let history = useHistory();

  //   const jobTitle = document.getElementById('jobTitleID');
  //   const location = document.getElementById('locationID');

  //   // console.log(jobTitle.value, location.value);

  //   localStorage.setItem('jobTitleSearch', jobTitle.value);
  //   localStorage.setItem('locationSearch', location.value);

  //   const [jobTitleSearch, setjobTitleSearch] = useState('');

  //   history.push('/search-jobs');

  // }

  const [jobTitleSearch, setjobTitleSearch] = useState(localStorage.getItem('jobTitleSearch') ? {
    value : localStorage.getItem('jobTitleSearch'),
    label : localStorage.getItem('jobTitleSearch')
  } : '');
  const [locationSearch, setlocationSearch] = useState(localStorage.getItem('locationSearch') ? {
    value : localStorage.getItem('locationSearch'),
    label : localStorage.getItem('locationSearch')
  } : '');

  const submitQuery = () => {

    // console.log(jobTitle.value, location.value);

    localStorage.setItem('jobTitleSearch', jobTitleSearch ? jobTitleSearch.value : '');
    localStorage.setItem('locationSearch', locationSearch ? locationSearch.value : '');

    history.push('/search-jobs');
  }

  
  useEffect(() => {
    if(window.location.pathname != '/search-jobs'){
      return;
    }
    if(!(jobTitleSearch || locationSearch)){
      submitQuery();
    }
  }, [jobTitleSearch, locationSearch])
  

  return (
    <Container>
      <div className='navbarSearch' style={{ padding: '0em 1em', maxWidth: '900px' }}>
        <Grid container direction='row' spacing={1}>
          <Grid item sm={10}>
            <div className="searchBoxBig" style={styles.searchBoxBig}>
              <Grid container direction='row' spacing={1} style={{ alignItems: 'center' }}>
                <Grid item xs={'auto'} style={{ width: '50%' }}>
                  <Grid container direction='row' spacing={2} style={{alignItems: 'center', width: '100%', flexWrap: 'nowrap'}}>
                              <Grid item xs={'auto'} style={{paddingRight: '2px'}}>
                                  <img src={srch} alt="Search by Location"/>
                              </Grid>
                              <Grid item xs={'auto'} style={{width: '100%'}}>
                                  <div style={styles.hiddenLabel} aria-hidden="true">Search Candidate by Job Title</div>
                                  <Select
                                        // isMulti
                                        // className=''
                                        id='jobTitleID'
                                        options={jobTitlesArr}
                                        onChange={e => {
                                          setjobTitleSearch(e);
                                        }}
                                        value={jobTitleSearch}
                                        className='stateDD'
                                        isClearable={true}
                                        placeholder= 'Job Title'
                                    />
                              </Grid>
                          </Grid>
                </Grid>
                <Grid item xs={'auto'} style={{ width: '50%' }}>
                  <Grid container direction='row' spacing={2} style={{ alignItems: 'center', width: '100%', flexWrap: 'nowrap' }}>
                    <Grid item xs={'auto'} style={{ paddingRight: '2px' }}>
                      <img src={pin} alt="Search by Location" />
                    </Grid>
                    <Grid item xs={'auto'} style={{ width: '100%' }}>
                      <div style={styles.hiddenLabel} aria-hidden="true">State</div>
                      <Select
                        // isMulti
                        // className=''
                        onChange={e => setlocationSearch(e)}
                        value = {locationSearch}
                        style={{ border: "none" }}
                        options={Locoptions}
                        isClearable={true}
                        className='stateDD'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item sm={2}>
            <Button onClick={submitQuery} id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', color: '#FFF', fontWeight: '600', borderRadius: '25px', padding: '10px', minWidth: '100%' }} variant="contained" >Search</Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

const SearchBarSmall = () => {

  let history = useHistory();
  // const submitQuery = () => {
  //   // let history = useHistory();

  //   const jobTitle = document.getElementById('jobTitleIDsm');
  //   const location = document.getElementById('locationIDsm');

  //   // console.log(jobTitle.value, location.value);

  //   localStorage.setItem('jobTitleSearch', jobTitle.value);
  //   localStorage.setItem('locationSearch', location.value);

  //   history.push('/search-jobs');

  // }

  return (
    <Container>
      <div className='navbarSearch' style={{ padding: '0em 1em', maxWidth: '900px' }}>
        <Grid container direction='row' spacing={1}>
          <Grid item xs={12}>
            <Grid container direction='column' spacing={1}>
              <Grid item xs={12}>
                <div className="searchBoxSmall" style={styles.searchBoxSmall}>
                  <Grid container direction='row' spacing={1}>
                    <Grid item xs={'auto'}>
                      <img src={srch} alt="Search by Title" />
                    </Grid>
                    <Grid item xs={'auto'}>
                      <input type="text" id='jobTitleIDsm' placeholder="Search by Job Title" style={styles.srchField} />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="searchBoxSmall" style={styles.searchBoxSmall}>
                  <Grid container direction='row' spacing={1}>
                    <Grid item xs={'auto'}>
                      <img src={pin} alt="Search by Location" />
                    </Grid>
                    <Grid item xs={'auto'}>
                      <input type="text" id='locationIDsm' placeholder="Location or Zip Code" style={styles.srchField} />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', color: '#FFF', fontWeight: '600', borderRadius: '25px', padding: '10px', minWidth: '100%' }} variant="contained" >Search</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}


function NavBarSrch() {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [NotianchorEl, setNotiAnchorEl] = useState(null);
  const [profile, setprofile] = useState({
    "name": '',
    "email": '',
    "phone": '',
    'profile_pic': null
  })

  useEffect(() => {
    // if(!(localStorage.getItem('name') && localStorage.getItem('email') && localStorage.getItem('phone')))

    // window.addEventListener('storage', ()=>{
    //   console.log('hello');
    setprofile({
      "name": (localStorage.getItem('name') === '' || localStorage.getItem('name') === 'null') ? '' : localStorage.getItem('name'),
      "email": (localStorage.getItem('email') === '' || localStorage.getItem('email') === 'null') ? '' : localStorage.getItem('email'),
      "phone": (localStorage.getItem('phone') === '' || localStorage.getItem('phone') === 'null') ? '' : localStorage.getItem('phone'),
      "profile_pic": (localStorage.getItem('profile_pic') === '' || localStorage.getItem('profile_pic') === 'null') ? '' : localStorage.getItem('profile_pic')
    })

    
    if(localStorage.getItem('access_token')){
      Cookies.set('access_token', localStorage.getItem('access_token'));
    }
    if(localStorage.getItem('user')){
      Cookies.set('user', localStorage.getItem('user'));
    }
    if(localStorage.getItem('refresh_token')){
      Cookies.set('refresh_token', localStorage.getItem('refresh_token'));
    }
    // })
    // console.log(profile);
  }, [])





  const Notiopen = Boolean(NotianchorEl);
  const NotihandleClick = (event) => {
    setNotiAnchorEl(event.currentTarget);
  };
  const NotihandleClose = () => {
    setNotiAnchorEl(null);
  };




  const { height, width } = useWindowDimensions();
  const [loginModal, setLoginModal] = useState(false);

  const history = useHistory();

  const [notifications, setnotifications] = useState([]);
  const [notificationCount, setnotificationCount] = useState(0);

  
  useEffect(() => {
    notificationFetch();
  }, [notificationCount])



  const notificationFetch = () => {
    axiosInstance.get('/auth/notification/').then(res=>{
      console.log(res.data);
      setnotifications(res.data.data.notifications);
      setnotificationCount(res.data.data.count);
    }).catch(err=>{
      console.log(err);
    })
  }

  
  const notificationClick = (id, job_id) => {
    // console.log(notification);
    // axiosInstance.post('/notification/changeStatus', { id })
    const data = {
      // access : localStorage.getItem('access_token'),
      notification_id : id
    }
    axiosInstance.post('/auth/notiification_change/', data).then(res=>{
      console.log(res);
      setnotificationCount(notificationCount-1);
      if(job_id){
        sessionStorage.setItem('mobJobId', job_id);
        history.push('/job-detail');
      }
    }).catch(err=>{
      console.log(err);
    })
  }



  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  
  const markAsRead = () => {
    axiosInstance.post('/auth/mark_all_notification/').then(res=>{
      setnotificationCount(0);
      notificationFetch();
    }).catch(err=>{
      console.log(err);
    })
  }

  return (
    <div>
      {
        isMobile ? (
          <DrawerComponent />
        ) : (
        <><Navbar fixed="top" collapseOnSelect expand="lg" style={{ background: '#FFF' }} variant="light">
              <Container>
                <Navbar.Brand href="/dashboard"><img src={Logo} alt="logo" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link href="/search-jobs">Find jobs</Nav.Link>
                    <Nav.Link href="/saved-jobs">Saved jobs</Nav.Link>
                    <Nav.Link href="/job-alert">Jobs alerts</Nav.Link>
                    {/* <Nav.Link href="#plans" onClick={() => setLoginModal(true)}>Subscription plans</Nav.Link> */}
                  </Nav>
                  <Nav>
                    <Nav.Link onClick={NotihandleClick} id="noti-menu">
                      <div>
                        <Badge badgeContent={notificationCount} color="primary" aria-owns={anchorEl ? 'simple-menu' : undefined}
                          aria-haspopup="true">
                          <NotificationsNoneIcon />
                        </Badge>
                      </div>

                    </Nav.Link>
                    <StyledMenu
                      id="noti-menu"
                      anchorEl={NotianchorEl}
                      open={Notiopen}
                      onClose={NotihandleClose}
                    >
                      <div className='notificationDD'>
                        <div className="notiDDHeader">
                          <div className="notiDDTitle">Notifications</div>
                          <div className="notiDDsubTitle" onClick={markAsRead}>Mark all as read</div>
                        </div>
                        <div className="noiCont" >
                          {
                            notifications && notifications.map(item=>{
                              return(<>
                                <Notification notificationClick={notificationClick} id={item.id} img='//placedog.net/40/40' name={item.title} created_at = {item.created_at} job_id = {item.job_id} desc={item.message} />

                              </>)
                            })
                          }
                        </div>
                        <div className="notiC2a">View All</div>
                      </div>
                    </StyledMenu>
                    <Nav.Link href="/message" onClick={() => setLoginModal(true)}>
                      <Badge color="primary">
                        <ChatBubbleOutlineIcon />
                      </Badge>
                    </Nav.Link>
                    <Nav.Link onClick={handleClick} id="profile-menu">
                      <Badge color="primary">
                        <PersonOutlineIcon />
                      </Badge>
                    </Nav.Link>
                    <StyledMenu
                      id="profile-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <Box style={{ display: 'flex', flexDirection: 'row', margin: '1em' }}>
                        <img height='49' width='49' src={(localStorage.getItem('profile_pic') === '' || localStorage.getItem('profile_pic') === 'null') ?
                          `https://ui-avatars.com/api/?name=${profile.name}?rounded=true` : localStorage.getItem('profile_pic')} style={{ margin: '5px', borderRadius: '50%' }} />
                        <Box>
                          <Typography style={{ fontWeight: '600', fontSize: '18px' }}>{!(localStorage.getItem('name') === '' || localStorage.getItem('name') === 'null') ? localStorage.getItem('name') : null}</Typography>
                          <Typography style={{ fontSize: '14px', color: '#878E99' }}>{(localStorage.getItem('email') === '' || localStorage.getItem('email' === 'null')) ?
                            null : localStorage.getItem('email')}</Typography>
                          <Typography style={{ fontSize: '14px', color: '#878E99' }}>{(localStorage.getItem('phone') === '' || localStorage.getItem('phone' === 'null')) ?
                            null : localStorage.getItem('phone')}</Typography>

                        </Box>

                      </Box>
                      <Divider />
                      <MenuItem onClick={() => window.location.href = '/profile'}>Profile</MenuItem>
                      <MenuItem onClick={() => window.location.href = '/setting/jobPrefernces'}>Job preference</MenuItem>
                      <MenuItem onClick={() => window.location.href = '/setting/attachments'}>Upload Documents(Resume)</MenuItem>
                      <MenuItem onClick={() => window.location.href = '/setting/contactPrefernces'}>Contact Preference</MenuItem>
                      <MenuItem onClick={() => window.location.href = '/setting/account'}>Account</MenuItem>
                      <MenuItem onClick={() => window.location.href = '/setting/helpCenter'}>Help Center</MenuItem>
                      <Divider />
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </StyledMenu>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
            <div style={{ position: 'relative', top: '75px', padding: '1em', background: '#fff', zIndex: '9' }}>
                {width > 800 ? <SearchBarBig /> : <SearchBarSmall />}
              </div>
              
              <Modal
                open={loginModal}
                onClose={() => { setLoginModal(false); } }
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div className='modalCont' style={{ width: 'fit-content' }}>
                  <img src={MaintainenceGuy} alt="" />
                  <div className='loginModalHead'>This action is not available yet...</div>
                  <div className='loginModalSubHead'>We are woking on it, please come back later.</div>
                  <button className='loginModalBtn' onClick={() => setLoginModal(false)}>Okay</button>
                </div>
              </Modal></>
        )
      }
    </div>
  )

}

export default NavBarSrch;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(5),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}))

const Notification = (props) => {
  const getDateDifference = (dataContLeft) => {
    var today = new Date();
    var date = new Date(dataContLeft);
    var diffMs = (today - date); // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minute

    const ans = diffDays>0 ? `${diffDays} Day` : '' + diffHrs>0 ? `${diffHrs} Hours` : '' + `${diffMins} mins`;
    console.log(today, dataContLeft, diffDays, diffHrs, diffMins, ans);
    return ans;
  }

  return (
    <div className="notification" style={{"justifyContent" : "start", "cursor" : "pointer"}} onClick={e=>props.notificationClick(props.id, props.job_id)}>
      <img src={props.img} alt="" className="notiImg" />
      <div className="notiBody">
        <div className="notiBodyHead">
          <div className="notiName">{props.name}</div>
          <div className="notiTime">{getDateDifference(props.created_at)}</div>
        </div>
        <div className="notiDesc">{props.desc}</div>
      </div>
    </div>
  )
}
