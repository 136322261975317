import { Grid, Typography } from '@material-ui/core';
import { Button, Rating } from '@mui/material';
import React from 'react';
import NavBar from '../NavBar';
import connection1 from "./connection1.png"
import licenseLogo from "./teacherLicence.svg"
import workExperienceLogo from "./experience.png"
import messageIcon from "./message.svg"
import Footer from '../Footer';
import "./seekerProfile.css"


const SeekerProfile = () => {
    return (
        <div className='rating-page-container' style={{ minHeight: "100vh", backgroundColor: "#F5F7FA", paddingTop: "110px" }}>
            <NavBar />
            <div style={{ width: "75vw", margin: "30px auto 100px", }}>
                <Grid
                    container
                    // direction="row"
                    justify="space-around"
                    alignItems="flex-start"
                    // wrap="wrap"
                    // rowSpacing={10}
                    // columnSpacing={14}
                    spacing={4}
                >
                    <Grid item xs={12} sm={12} md={12} lg={8}
                        
                    >
                        <div className="rating-page-content" style={{ backgroundColor: "white", padding: "45px" }}>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",

                            }}>
                                <div
                                    style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
                                >
                                    <img src={connection1} alt="connection" style={{ width: "5rem", height: "", marginRight: '13px', marginBottom: "10px" }} />
                                    <div>
                                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                                            someOne
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            San Francisco, America
                                        </Typography>
                                    </div>

                                </div>
                                <Button style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: '#0667C3', color: 'white', fontWeight: '500', borderRadius: '25px', padding: '5px 15px', whiteSpace: "nowrap" }} variant="outlined" ><img src={messageIcon} style={{ paddingRight: "5px" }} alt="" /> Message</Button>
                            </div>
                            <Grid
                                container
                                direction="row"
                                style={{ padding: "0 0", marginTop: "20px" }}
                                // justifyContent="space-between"
                                alignItems="center"
                                wrap="wrap"
                                spacing={2}

                            >
                                <Grid item > <Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'white', color: 'black', fontWeight: '600', borderRadius: '25px', border: "1px solid #E4E8F1", padding: '5px 10px', minWidth: '100%', whiteSpace: "nowrap" }}  ><img src={licenseLogo} style={{ padding: "0px 4px" }} alt="" /> Teacher's License</Button></Grid>
                                <Grid item> <Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'white', color: 'black', fontWeight: '600', borderRadius: '25px', border: "1px solid #E4E8F1", padding: '5px 10px', minWidth: '100%', whiteSpace: "nowrap" }}  ><img src={workExperienceLogo} style={{ padding: "0px 4px" }} alt="" /> 5 years work experience</Button></Grid>
                                <Grid item> <Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'white', color: 'black', fontWeight: '600', borderRadius: '25px', border: "1px solid #E4E8F1", padding: '5px 10px', minWidth: '100%', whiteSpace: "nowrap" }}  ><img src={workExperienceLogo} style={{ padding: "0px 4px" }} alt="" /> Work 5 country</Button></Grid>
                                <Grid item> <Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'white', color: 'black', fontWeight: '600', borderRadius: '25px', border: "1px solid #E4E8F1", padding: '5px 10px', minWidth: '100%', whiteSpace: "nowrap" }}  ><img src={workExperienceLogo} style={{ padding: "0px 4px" }} alt="" /> 5 years work experience</Button></Grid>
                                <Grid item> <Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'white', color: 'black', fontWeight: '600', borderRadius: '25px', border: "1px solid #E4E8F1", padding: '5px 10px', minWidth: '100%', whiteSpace: "nowrap" }} ><img src={workExperienceLogo} style={{ padding: "0px 4px" }} alt="" /> Work 5 country</Button></Grid>

                            </Grid>
                            <p style={{ margin: "30px 0" }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo velit repellendus similique totam enim tenetur corrupti animi autem laudantium quisquam!</p>
                            <Typography variant={"h5"}>
                                <h5 style={{ fontWeight: "bold" }}>
                                    I am available for
                                </h5>

                            </Typography>
                            <Grid
                                container
                                direction="row"
                                style={{ padding: "0 0", marginTop: "20px", marginBottom: "64px" }}
                                // justifyContent="space-between"
                                alignItems="center"
                                wrap="wrap"
                                spacing={2}

                            >
                                <Grid item > <Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'white', color: 'black', fontWeight: '600', borderRadius: '25px', border: "1px solid #E4E8F1", padding: '7px 15px', minWidth: '100%', whiteSpace: "nowrap" }}  >Curriculum Developer</Button></Grid>
                                <Grid item> <Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'white', color: 'black', fontWeight: '600', borderRadius: '25px', border: "1px solid #E4E8F1", padding: '7px 15px', minWidth: '100%', whiteSpace: "nowrap" }}  >Academic Writing</Button></Grid>
                                <Grid item> <Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'white', color: 'black', fontWeight: '600', borderRadius: '25px', border: "1px solid #E4E8F1", padding: '7px 15px', minWidth: '100%', whiteSpace: "nowrap" }}  >Writing</Button></Grid>


                            </Grid>

                            <div style={{ backgroundColor: "#F5F7FA", padding: "20px", paddingBottom: "30px" }}>
                                <Grid
                                    container
                                    direction="row"
                                    // justifyContent=""
                                    alignItems="flex-start"
                                    style={{}}
                                // spacing={6}
                                >
                                    <Grid item xs={12} sm={4} md={4} lg={4}
                                    
                                    >
                                        <Typography variant="subtitle1" >
                                            <p>Work experience</p>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8} lg={8}>
                                        <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                            <p style={{ marginBottom: "0" }}>High School Teacher—Chemistry</p>
                                        </Typography>
                                        <Typography variant="subtitle1" style={{}}>
                                            <p style={{ marginBottom: "0" }}>Louis Pasteur High School, Chicago, IL</p>
                                        </Typography>
                                        <Typography variant="subtitle1" style={{}}>
                                            <p style={{ marginBottom: "0" }}>September 2015–July 2018</p>
                                        </Typography>

                                    </Grid>
                                </Grid>

                            </div>
                            <Button variant='outlined' style={{ borderRadius: "43px", display: "block", textAlign: "center", backgroundColor: "white", margin: "40px auto 0", padding: "4px 40px", fontWeight: "bold" }}>View resume</Button>
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}

                    >
                        <div className='rating-giving-div' >
                            <div className='rating-data' style={{ backgroundColor: "#F7F7F7", padding: "20px 15px", paddingBottom:"40px" }}> 

                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                                    <div>
                                        <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                                            <p style={{ fontSize: "1.7rem", marginBottom: "0", fontWeight: "bold", color: "#0BAC72", paddingRight: "7px" }}>3.5</p>
                                            <div>
                                                <svg width="1.5rem" style={{ paddingBottom: "6px" }} viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M28.03 10.3447C27.9503 10.1003 27.7386 9.92362 27.4855 9.89019L18.853 8.55318L14.9883 0.745037C14.8647 0.53016 14.637 0.397949 14.3907 0.397949C14.1443 0.397949 13.9166 0.53016 13.793 0.745037L9.92827 8.61965L1.29582 9.95666C1.04266 9.99008 0.831 10.1668 0.751255 10.4112C0.672399 10.6529 0.739694 10.9188 0.924079 11.0931L7.17936 17.2299L5.70512 25.8265C5.65591 26.0757 5.75433 26.3312 5.9574 26.4819C6.17351 26.6141 6.44505 26.6141 6.66115 26.4819L14.3905 22.444L22.1199 26.5218C22.2129 26.575 22.3182 26.6027 22.4252 26.6021C22.5669 26.6033 22.7059 26.5612 22.8236 26.4819C23.0267 26.3311 23.1251 26.0757 23.0759 25.8265L21.6017 17.1627L27.8569 11.0259C28.0413 10.8516 28.1086 10.5857 28.0298 10.3439L28.03 10.3447Z" fill="#0BAC72" />
                                                </svg>

                                            </div>

                                        </div>
                                        <Typography variant='subtitle2' style={{ fontWeight: "600" }}> 100 reviews</Typography>
                                    </div>
                                    <Button style={{ padding: "0", fontWeight: "600", textTransform: "lowercase" }}><Typography variant="subtitle2">Read community guidelines</Typography> </Button>
                                </div>
                            </div>
                            <div style={{ padding: "15px", paddingTop:"30px" }}>
                                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                    Ratings
                                </Typography>
                                <Typography variant="body1">Rate the user by selecting </Typography>

                                <div style={{display:'flex', justifyContent:"space-between", alignItems:"center", margin:"20px 0"}}>
                                    <Rating
                                        name="simple-controlled"
                                        size="large"
                                        // value={value}
                                        // onChange={(event, newValue) => {
                                        //     setValue(newValue);
                                        // }}
                                    />
                                    <Button id='' style={{ textTransform: 'none', fontSize: "", fontFamily: 'Montserrat', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', color: '#FFF', fontWeight: '600', borderRadius: '25px' }} variant="contained" >Send rating</Button>
                                </div>

                            </div>
                        </div>
                    </Grid>

                </Grid>

            </div>

            <Footer />

        </div>
    );
};

export default SeekerProfile;