import React, { useCallback, useState, useEffect } from 'react';
import { Box, Grid, TextField, Typography, Button, Input } from '@material-ui/core';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MailIcon from '@material-ui/icons/Mail';
import { styled, alpha } from '@mui/material/styles';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Divider from '@mui/material/Divider';
import Logo from './logo.svg';
import Profile from './profile.svg';
import Cookies from 'js-cookie';
import axiosInstance from '../../axios';
import { useHistory } from "react-router-dom";
import './style.css'
import handleLogout from '../../Logout/logout';

import DrawerComponent from "./Drawer";
import {
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

function NavBar_emp() {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [NotianchorEl, setNotiAnchorEl] = useState(null);
  const [profile, setprofile] = useState({
    "name": '',
    "email": '',
    "phone": '',
    'profile_pic': null
  })

  
const history = useHistory();

const [notifications, setnotifications] = useState([]);
const [notificationCount, setnotificationCount] = useState(0);

  useEffect(() => {
      notificationFetch();
    }, [notificationCount])

  const notificationFetch = () => {
      axiosInstance.get('/auth/notification/').then(res=>{
        setnotifications(res.data.data.notifications);
        setnotificationCount(res.data.data.count);
      }).catch(err=>{
        console.log(err);
      })
    }

    const notificationClick = (id, job_id) => {
      // console.log(notification);
      // axiosInstance.post('/notification/changeStatus', { id })
      const data = {
        // access : localStorage.getItem('access_token'),
        notification_id : id
      }
      axiosInstance.post('/auth/notiification_change/', data).then(res=>{
        console.log(res);
        setnotificationCount(notificationCount-1);
        if(job_id){
          sessionStorage.setItem('mobJobId', job_id);
          history.push('/job-detail');
        }
      }).catch(err=>{
        console.log(err);
      })
    }

    const markAsRead = () => {
      axiosInstance.post('/auth/mark_all_notification/').then(res=>{
        setnotificationCount(0);
        notificationFetch();
      }).catch(err=>{
        console.log(err);
      })
    }



  useEffect(() => {
    // if(!(localStorage.getItem('name') && localStorage.getItem('email') && localStorage.getItem('phone')))

    // window.addEventListener('storage', ()=>{
    //   console.log('hello');
    setprofile({
      "name": (localStorage.getItem('name') === '' || localStorage.getItem('name') === 'null') ? '' : localStorage.getItem('name'),
      "email": (localStorage.getItem('email') === '' || localStorage.getItem('email') === 'null') ? '' : localStorage.getItem('email'),
      "phone": (localStorage.getItem('phone') === '' || localStorage.getItem('phone') === 'null') ? '' : localStorage.getItem('phone'),
      "profile_pic": (localStorage.getItem('profile_pic') === '' || localStorage.getItem('profile_pic') === 'null') ? '' : localStorage.getItem('profile_pic')
    })

    
    if(localStorage.getItem('access_token')){
      checkEmployee();
      Cookies.set('access_token', localStorage.getItem('access_token'));
    }
    if(localStorage.getItem('user')){
      Cookies.set('user', localStorage.getItem('user'));
    }
    if(localStorage.getItem('refresh_token')){
      Cookies.set('refresh_token', localStorage.getItem('refresh_token'));
    }
    // })
    // console.log(profile);
  }, [])





  const Notiopen = Boolean(NotianchorEl);
  const NotihandleClick = (event) => {
    setNotiAnchorEl(event.currentTarget);
  };
  const NotihandleClose = () => {
    setNotiAnchorEl(null);
  };


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      {
        isMobile ? (
          <DrawerComponent />
        ) : (
          <Navbar className="navbar" fixed="top" collapseOnSelect expand="lg">
            <Container>
              <Navbar.Brand href="/dashboard"><img src={Logo} alt="logo" /></Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto" >
                  <Nav.Link href="/search-candidate" style={{marginRight:'15px'}}>Candidate Search</Nav.Link>
                  <Nav.Link href="/employer/job-posting" style={{marginRight:'15px'}}>Job Posts</Nav.Link>
                  <Nav.Link href="/employer/favorite" style={{marginRight:'15px'}}>Favorites</Nav.Link>
                  <Nav.Link href="#plans" style={{marginRight:'15px'}}>Frame Change Content</Nav.Link>
                  {/* <Nav.Link href="#plans" style={{marginRight:'15px'}}>Subscription Plans</Nav.Link> */}
                </Nav>
                <Nav>
                  <Nav.Link onClick={NotihandleClick} id="noti-menu">
                    <div>
                      <Badge badgeContent={notificationCount} color="primary" aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true" >
                        <NotificationsNoneIcon />
                      </Badge>
                    </div>

                  </Nav.Link>
                  <StyledMenu
                    id="noti-menu"
                    anchorEl={NotianchorEl}
                    open={Notiopen}
                    onClose={NotihandleClose}
                  >
                    <div className='notificationDD'>
                      <div className="notiDDHeader">
                        <div className="notiDDTitle">Notifications</div>
                        <div className="notiDDsubTitle"  onClick={markAsRead}>Mark all as read</div>
                      </div>
                      <div className="noiCont">
                      {
                          notifications && notifications.map(item=>{
                              return(<>
                                <Notification notificationClick={notificationClick} id={item.id} img='//placedog.net/40/40' name={item.title} created_at = {item.created_at} job_id = {item.job_id} desc={item.message} />
  
                              </>)
                          })
                      }
                      </div>
                      <div className="notiC2a">View All</div>
                    </div>
                  </StyledMenu>
                  <Nav.Link href="/message">
                    <Badge color="primary">
                      <ChatBubbleOutlineIcon />
                    </Badge>
                  </Nav.Link>
                  <Nav.Link onClick={handleClick} id="profile-menu">
                    <Badge color="primary">
                      <PersonOutlineIcon />
                    </Badge>
                  </Nav.Link>
                  <StyledMenu
                    id="profile-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <Box style={{ display: 'flex', flexDirection: 'row', margin: '1em' }}>
                      <img height='49' width='49' src={
                        (localStorage.getItem('profile_pic') === '' || localStorage.getItem('profile_pic') === 'null') ?
                          `https://ui-avatars.com/api/?name=${profile.name}?rounded=true` : localStorage.getItem('profile_pic')
                      } style={{ margin: '5px', borderRadius: '50%' }} />
                      <Box>
                        <Typography style={{ fontWeight: '600', fontSize: '18px' }}>{
                          !(localStorage.getItem('name') === '' || localStorage.getItem('name') === 'null') ? localStorage.getItem('name') : null
                        }</Typography>
                        <Typography style={{ fontSize: '14px', color: '#878E99' }}>{
                          (localStorage.getItem('email') === '' || localStorage.getItem('email' === 'null')) ?
                            null : localStorage.getItem('email')
                        }</Typography>
                        <Typography style={{ fontSize: '14px', color: '#878E99' }}>{
                          (localStorage.getItem('phone') === '' || localStorage.getItem('phone' === 'null')) ?
                            null : localStorage.getItem('phone')
                        }</Typography>

                      </Box>

                    </Box>
                    <Divider />
                    <MenuItem onClick={() => window.location.href = '/employer/settings/distrProfile'}>District Profile</MenuItem>
                    <MenuItem onClick={() => window.location.href = '/employer/settings/contactPreferences'}>Contact preference</MenuItem>
                    <MenuItem onClick={() => window.location.href = '/employer/settings/teamPage'}>Team</MenuItem>
                    <MenuItem onClick={() => window.location.href = '/employer/settings/userRoles'}>User Roles</MenuItem>
                    <MenuItem onClick={() => window.location.href = '/employer/settings/commPreferences'}>Communication Preferences</MenuItem>
                    <MenuItem onClick={() => window.location.href = '/employer/settings/account'}>Account</MenuItem>
                    <MenuItem onClick={() => window.location.href = '/employer/settings/helpCentre'}>Help Center</MenuItem>
                    <Divider />
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </StyledMenu>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        )
      }
    </div>
  )
}

export default NavBar_emp;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(5),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}))

const Notification = (props) => {
  return (
    <div className="notification">
      <img src={props.img} alt="" className="notiImg" />
      <div className="notiBody">
        <div className="notiBodyHead">
          <div className="notiName">{props.name}</div>
          <div className="notiTime">{props.time} min ago</div>
        </div>
        <div className="notiDesc">{props.desc}</div>
      </div>
    </div>
  )
}


const checkEmployee = () => {
  if(Cookies.get('access_token')){
    if(Cookies.get('user') === 'JobSeeker'){
      window.location.href="/dashboard"
    }
  }else{
    window.location.href="/login";
  }
}
