import React from 'react'
import loaderJson from '../../assets/loader.json';
import Lottie from 'react-lottie';


const loader = (props) => {
    const height = props.height ? props.height : 100;
  return (
    <>
        <div className='' style={{height : height + 'vh', display : 'grid', placeContent : 'center', width : '100vw', backgroundColor : 'rgb(255,255,255,0.6)', position : 'fixed', zIndex: '100'}}>
            <Lottie 
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: loaderJson,
                    rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                    }
                }}
                height={200}
                width={200}
            />
        </div>
    </>
  )
}

export default loader