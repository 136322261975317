import React from 'react'
import Footer from '../Footer'
import NavBarSrch from '../NavBar_withSearch'
import Img404 from './Frame 6970.png'

const FourOFour = () => {
  return (
    <div>
      
      <a href='/dashboard'><img src={Img404} alt="" style={{display: 'block', margin: 'auto', marginTop: '10vh', cursor: 'pointer', maxWidth: '95vw'}} /></a>
    </div>
  )
}

export default FourOFour
