import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertFunc(props) {
    const [open, setOpen] = React.useState(true);


    const handleAccept = () => {
        setOpen(false);
        
    };

    const handleNo = () => {
        setOpen(false);
        props.alertState(false)
    };

    let typeOfAlert = props.type;
    //Passing two parameters: type of alert and description of alert
    return (
        <div >
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"     
                fullWidth="true"      
                style={{top:'0px'}} 
            >

                <div >
                    <Alert severity={typeOfAlert} onClose={handleNo}>
                        <AlertTitle>{typeOfAlert.charAt(0).toUpperCase()+typeOfAlert.slice(1)}</AlertTitle>
                        {props.desc}
                    </Alert>
                </div>
            </Dialog>
        </div>

    );
}
