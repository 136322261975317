import { Box, Container, Grid, Typography, Tabs, Tab,CircularProgress } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import "react-multi-carousel/lib/styles.css";
import NavBar from '../NavBar_withSearch';
import Dollar from '../Dashboard/dollar.svg';
import Phd from '../Dashboard/phd.svg';
import Teacher from '../Dashboard/teacher.svg';
import FullTime from '../Dashboard/fullTime.svg';
import Time from '../Dashboard/time.svg';
import San from '../Dashboard/san.svg';
import Carousel from 'react-multi-carousel';
import Phone from '../emp_settings/Phone.png'
import Mail from '../emp_settings/Mail.png'
import Web from '../emp_settings/Web.png';
import { FaRegHeart } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { FaHeart } from 'react-icons/fa';
import DesiredJob from './desiredJob.svg';
import GreenDesiredJob from './GreenDesiredJob.svg';
import Profile from './profile.svg';
import School from './school.svg';
import Footer from '../Footer';
import {Helmet} from "react-helmet";
import NoSavedJobsHeart from '../NoSavedJobs/noSavedJobsHeart.png';
import Lateral from '../Dashboard/lateral.svg';
import Stretch from '../Dashboard/stretch.svg';
import S1 from '../Dashboard/S1.svg';
import S2 from '../Dashboard/S2.svg';
import S3 from '../Dashboard/S3.svg';
import S4 from '../Dashboard/S4.svg';
import axiosInstance from '../../axios';
import Cookies from 'js-cookie';
import Lottie from 'react-lottie';
import loaderJson from '../../assets/loader.json';
import mainLoaderJson from '../../assets/mainLoader.json';
import './style.css';
import LeftChevron from '../../assets/LeftChevron.svg';
import RightChevron from '../../assets/RightChevron.svg';
import ChevRight from '../Search-jobs/chevron-right 1.png';
import stateflags from './state_flags.js';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        localStorage.setItem('page', 1);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}


function SavedJobs() {

    const [tabValue, setTabValue] = React.useState('job-description');

    const [toggleHeart, setToggleHeart] = useState(false)

    const changeColor = useCallback(() => {
        setToggleHeart(!toggleHeart)
    }, [])

    const { height, width } = useWindowDimensions();







    const renderSwitch = (param) => {
        switch (param) {
            case "job-description":
                return JobDescription;
            case "employer-details":
                return <>
                <div style={{marginTop: '70px', marginBottom: '50px', padding: '1rem'}}>
                    <div style={ { display: 'inline-flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' } }>
                        <h4 style={{maxWidth: '520px'}}>Anderson Community School Corporation (School District)</h4>
                    </div>
                    <p style={{maxWidth: '520px',  marginTop: '10px'}}>
                        Anderson Community School Corporation is a public school district located in ANDERSON, IN. It has 6,811 students in grades PK, K-12 with a student-teacher ratio of 15 to 1. According to state test scores, 29% of students are at least proficient in math and 30% in reading.
                    </p>
                    <div style={ { display: 'inline-flex', gap: '15px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' } }>
                        <div style={ { display: 'inline-flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' } }>
                            <img src={Web} /> acsc.net
                        </div>
                        <div style={ { display: 'inline-flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' } }>
                            <img src={Mail} /> jennywilson@mail.com
                        </div>
                        <div style={ { display: 'inline-flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap', color: '#0B6DCA' } }>
                            <img src={Phone} /> 434 241 41284
                        </div>
                    </div>
                    <div className='fourGridCol' style={{marginTop: '100px'}}>
                        <div className='firstColHead'>Academics</div>
                        <div className='colData'>
                            <h4>30%</h4>
                            Percent Proficient - Reading
                        </div>
                        <div className='colData'>
                            <h4>30%</h4>
                            Percent Proficient - Math
                        </div>
                        <div className='colData'>
                            <h4>30%</h4>
                            Average Graduation Rate
                        </div>
                    </div>
                    <div className='fourGridCol' style={{marginTop: '50px'}}>
                        <div className='firstColHead'>Students</div>
                        <div className='colData'>
                            <h4>6,811</h4>
                            Students
                        </div>
                        <div className='colData'>
                            <h4>30%</h4>
                            Free or Reduced Lunch
                        </div>
                    </div>
                    <div className='fourGridCol' style={{marginTop: '50px'}}>
                        <div className='firstColHead'>Teachers</div>
                        <div className='colData'>
                            <h4>15:1 <sub style={{fontSize: '12px'}}>National 17:1</sub></h4>
                            Student-Teacher Ratio
                        </div>
                        <div className='colData'>
                            <h4>30%</h4>
                            Teachers in First/Second Year
                        </div>
                    </div>
                    <div className='graphColDiv' style={{marginTop: '50px'}}>
                        <div className='firstColHead'>Finances</div>
                        <div className='colData'>
                            <h4>$37,038 <sub style={{fontSize: '12px'}}>/ student</sub><p style={{fontSize: '12px'}}>National $12,239</p></h4>
                            Median Household Income
                        </div>
                        <div className="bardGraphContDiv">
                            <div className="bardGraphCont">
                                <div className="barColor" style={{width: '58%'}} />
                                <div className="barLabel">Instruction</div>
                                <div className="barPercent">58%</div>
                            </div>
                            <div className="bardGraphCont">
                                <div className="barColor" style={{width: '58%'}} />
                                <div className="barLabel">Support Services</div>
                                <div className="barPercent">58%</div>
                            </div>
                            <div className="bardGraphCont">
                                <div className="barColor" style={{width: '58%'}} />
                                <div className="barLabel">Other</div>
                                <div className="barPercent">58%</div>
                            </div>
                        </div>
                    </div>
                    <div className='fourGridCol' style={{marginTop: '50px'}}>
                        <div className='firstColHead'>LIVING IN THE AREA</div>
                        <div className='colData'>
                            <h4>$37,038 <p style={{fontSize: '12px'}}>National $62,843</p></h4>
                            Median Household Income
                        </div>
                        <div className='colData'>
                            <h4>$757 <p style={{fontSize: '12px'}}>National $1,062</p></h4>
                            Median Rent
                        </div>
                        <div className='colData'>
                            <h4>$72,900 <p style={{fontSize: '12px'}}>National $217,500</p></h4>
                            Median Home Value
                        </div>
                    </div>
                </div>
            </>;

        }
    }
    const [selectedJob, setselectedJob] = useState({
        "id": "",
        "city": "",
        "state": "",
        "district": "",
        "address": "",
        "district_id": "",
        "is_saved_job": "",
        "unmatched_school_district": "",
        "is_scraped": "",
        "scraped_job_ID": "",
        "scrap_source": "",
        "date_created": "",
        "job_title": "",
        "apply_url": "",
        "description": "",
        "location": "",
        "expiry_date": "",
        "date_posted": "",
        "vacancies": "",
        "position": "",
        "salary_from": "",
        "salary_to": "",
        "contact_person": "",
        "contact_title": "",
        "contact_phone": "",
        "contact_email": "",
        "salary_range": "",
        "no_of_job_openings": "",
        "shift_type": "",
        "other_details": "",
        "no_of_views": "",
        "phd_required": "",
        "license_required": "",
        "school_district": "",
        "admin": "",
        "non_admin": ""
})
    const [jobs, setjobs] = useState([]);
    const [accessToken, setaccessToken] = useState([]);
    const [loader, setloader] = useState(false);
    const [recommendedJobs, setrecommendedJobs] = useState([]);
    const [recommendJobsLoader, setrecommendJobsLoader] = useState(false);

    const history = useHistory();

    const JobDescription =
    <Grid container direction='column' style={{ marginTop: '1em' }}>
        <span style={{ fontSize: '22px', fontWeight: '550' }}>Full Job Description</span>
        {selectedJob.description ? selectedJob.description : 'No Description Available'}
    </Grid>;



    
    const [activePagination, setActivePagination] = useState(1)
    useEffect(() => {
        if ( Cookies.get( 'access_token' ) ) {
            setaccessToken( Cookies.get( 'access_token' ) );
        } else {
            window.location.href = '/register';
        }
    }, [])

    useEffect(() => {
        if(accessToken.length > 0){
            getJobs();
        }
    }, [accessToken, localStorage.getItem('page')])

    const getJobs = () => {
        setloader(true);
        axiosInstance.get('/seeker/saved_jobs_paginated/', {
            params : {
                // access : accessToken,
                page : localStorage.getItem('page') ? localStorage.getItem('page') : ''
            }
        }).then(res=>{
            setjobs(res.data.results);

            // pagination links
            const nextNumber = res.data.links.next ? res.data.links.next.split('page=')[1] : null;
            const prevNumber = res.data.links.previous ? res.data.links.previous.split('page=')[1] : null;

            setnextLink(nextNumber);
            setprevLink(prevNumber);
            setpaginationPages(res.data.links.list_of_pages);

            setloader(false);
            if(res.data.results.length === 0){
                setrecommendJobsLoader(true);
                axiosInstance.get('/seeker/dashboard/', {
                    timeout : 1000 * 20
                }).then(res=>{
                    console.log(res.recommendedJobs);
                    setrecommendedJobs(res.data.recommendedJobs);
                    setrecommendJobsLoader(false);
                })
            }else{
                if(window.innerWidth > 960)
                    document.getElementsByClassName('jobItem')[0].click();
            }

        }).catch(err=>{
            setloader(false);
            console.log(err);
        })
    }

    const addActiveClass = async (e) => {
        if(window.innerWidth > 960){
            const jobname_id = `jobCard_${e}`;
            const elements = document.getElementsByClassName('jobItem');
            Array.from(elements).forEach(item=>{
                item.classList.remove('activeJob');
            })
            const job =jobs.find(item=>item.id === e)
            setselectedJob(job);
            document.getElementById(jobname_id).classList.add('activeJob');
        }else{
            history.push('/job-detail');
            sessionStorage.setItem('mobJobId', e);
        }
    }

    const calculateDollar = (value) => {
        if(value > 0 && value <= 19){
            return(
                <img src={Dollar} />
            )
        }else if(value >19 &&  value<=39){
            return(
                <>
                    <img src={Dollar} />
                    <img src={Dollar} />
                </>
            )
        }else if(value > 39 && value <= 59){
            return(
                <>
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                </>
            )
        }else if(value > 59 && value <= 79){
            return(
                <>
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                </>
            )
        }else if(value > 79){
            return(
                <>
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                    <img src={Dollar} />
                </>
            )
        }
    }

    const calculateDayLeft = (expiryDate) => {
        const curr = new Date();
        const exp = new Date(expiryDate);
        const oneDay = 24 * 60 * 60 * 1000;

        if(curr > exp)
            return false;

        const diffDays = Math.round(Math.abs((exp - curr) / oneDay));

        return diffDays;
    }

    const calculateDayDifference = (expiryDate) => {
        const curr = new Date();
        const exp = new Date(expiryDate);
        const oneDay = 24 * 60 * 60 * 1000;

        const diffDays = Math.round(Math.abs((exp - curr) / oneDay));

        return diffDays;
    }

    const [nextLink, setnextLink] = useState(null);
    const [prevLink, setprevLink] = useState(null);
    const [paginationPages, setpaginationPages] = useState([]);
    const itemsPerPage = 10;
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(jobs.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(jobs.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, jobs]);

    const logoArray = [S1, S2, S3, S4];
    const secondLogoArray = [DesiredJob, Stretch, Lateral];

    const randomSelector = (array) => {
        return array[Math.floor(Math.random()*array.length)];
    }

    const saveJobFn = (id) => {
        setloader(true);
        const data = {
            // access : accessToken,
            job_id : id
        }
        // console.log(data);
        axiosInstance.post('/seeker/toggle_saved_job_status/', data).then(res=>{
            getJobs();
            setloader(false);
        }).catch(err=>{
            alert(err);
            setloader(false);
        })
    }

    const responsive1 = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1.5
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,

        }
    };

    



    return (
        <>
            {
                loader ? 
                <div className='' style={{height : '120vh', display : 'grid', placeContent : 'center', width : '100vw', backgroundColor : 'rgb(255,255,255,0.6)', position : 'absolute', zIndex: '100'}}>
                    {/* <iframe src="https://embed.lottiefiles.com/animation/66433"></iframe> */}
                    <Lottie 
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: loaderJson,
                            rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                            }
                        }}
                        height={200}
                        width={200}
                    />
                </div> : null
            }
            <Helmet>
                <title>Saved Jobs | Frame Change</title>
            </Helmet>
            <NavBar />
            <Grid style={{ padding: '3.5em 0', background: '#F5F7FA' }} container alignItems='center' justifyContent='center' direction='column'>

                <Grid container direction='column' style={{ width: '90%' }}>

                    <Container style={{ margin: 'auto', padding: '0' }} >
                        <div style={{ marginTop: '7em', marginBottom: '1em' }}>
                            <span style={{ fontSize: '19px', color: '#000', fontWeight: '500' }}>Saved jobs</span>
                        </div>
                        <Grid container spacing={2}>
                            {
                               jobs.length ? <>
                                    <Grid container item spacing={2} lg={4} md={5} sm={12} xs={12}>
                                        <Grid container direction='column' >
                                            <Box marginBottom="1em">
                                                <div style={{display:"flex",flexDirection:'row',alignContent:'center'}}>
                                                <Typography style={{margin:'0 0.5em'}}>SORT:</Typography>
                                                <button style={{margin:'0 0.5em',background:'#47BC96',fontSize: '14px', color:'#fff',borderRadius: '25px',border: '1px solid #47BC96'}}>Due date</button>
                                                <button style={{margin:'0 0.5em',color:'#000',fontSize: '14px', borderRadius: '25px',border: '1px solid #E4E8F1'}}>Applied</button>
                                                <button style={{margin:'0 0.5em',color:'#000',fontSize: '14px', borderRadius: '25px',border: '1px solid #E4E8F1'}}>All</button>
                                            
                                                </div>
                                            </Box>
                                            <Grid id="jobs_container" container style={{ padding: '0 1em', borderRadius: '10px', background: '#fff', paddingLeft: '0'}} >

                                                {/* <Items currentItems={currentItems} /> */}
                                                
                                                {
                                                    currentItems &&
                                                    currentItems.map(item=>{
                                                        return(
                                                            <Grid id={`jobCard_${item.id}`} className='jobItem' onClick={()=>addActiveClass(item.id)} item lg={12} style={{ padding: '1em 5px', cursor: 'pointer'}}>
                                                                <div style={{ padding: '5px' }}>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item lg={10}>
                                                                            <Typography className='textOverflow' style={{ fontSize: '16px', fontWeight: '600', lineHeight: '18px', maxHeight: '36px', overflow: 'hidden', position: 'relative' }}>
                                                                            {item.job_title}
                                                                            </Typography>

                                                                        </Grid>
                                                                        <Grid item lg={10}>
                                                                            <Typography style={{ fontSize: '14px', color: '#393939' , fontWeight: '600', maxHeight: '24px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                            {item.unmatched_school_district}
                                                                            </Typography>

                                                                        </Grid>
                                                                        {/* <Grid item lg={10}>
                                                                            <Typography className='textOverflow' style={{ fontSize: '14px', color: '#474747', lineHeight: '16px', maxHeight: '50px', overflow: 'hidden', position: 'relative' }}>
                                                                            {item.description}
                                                                            </Typography>

                                                                        </Grid> */}
                                                                    </Grid>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item container spacing={1} lg={11}>



                                                                            <Grid item lg={8}>
                                                                                


                                                                            </Grid>

                                                                        </Grid>
                                                                        <Grid item lg={1} onClick={()=>saveJobFn(item.id)}>
                                                                            {
                                                                                item.is_saved_job === 1 ? 
                                                                                <FaHeart style={{color : 'orange'}} size='1.5em' /> : 
                                                                                <FaRegHeart  size='1.5em' />
                                                                            }
                                                                        </Grid>
                                                                        </Grid>

                                                                    <br />
                                                                    <Grid container lg={11} justifyContent="space-between">
                                                                        {
                                                                            item.date_posted?(
                                                                                <Grid item lg={4}>
                                                                                    <Typography style={{ fontSize: "14px", color: '#636363' }}>{Math.floor(calculateDayDifference(item.date_posted) / 7)} weeks ago</Typography>
                                                                                </Grid>
                                                                            ) : null
                                                                        }
                                                                        <Grid item lg={3}>

                                                                            <img src={GreenDesiredJob} />
                                                                        </Grid>

                                                                    </Grid>

                                                                </div>

                                                            </Grid>
                                                        )
                                                    })                                            
                                                }

                                                {
                                                    currentItems && currentItems.length == 0 ? <div className="col-12 d-flex justify-content-center pt-2"><h5>No Job Available</h5></div> : null
                                                }
                                                
                                                
                                            </Grid>

                                            <div className="paginationCOnt">
                                                <div className={`paginationCircle ${!prevLink ? 'noDispPagi' : null}`} onClick={() => {
                                                        setActivePagination(activePagination - 1);
                                                        localStorage.setItem('page', prevLink);
                                                    }
                                                } ><img src={ChevRight} alt="" style={{transform: 'rotate(180deg)'}} /></div>

                                                {
                                                    paginationPages.map(item=>{
                                                        return(
                                                            <div className={`paginationCircle ${activePagination === item ? 'activePagi' : null}`} onClick={() => {
                                                                setActivePagination(item);
                                                                localStorage.setItem('page', item);
                                                            }} >{item}</div>
                                                        )
                                                    })
                                                }

                                                {/* <div className={`paginationCircle ${activePagination === 1 ? 'activePagi' : null}`} onClick={() => setActivePagination(1)} >1</div>
                                                <div className={`paginationCircle ${activePagination === 2 ? 'activePagi' : null}`} onClick={() => setActivePagination(2)} >2</div>
                                                <div className={`paginationCircle ${activePagination === 3 ? 'activePagi' : null}`} onClick={() => setActivePagination(3)} >3</div>
                                                <div className={`paginationCircle ${activePagination === 4 ? 'activePagi' : null}`} onClick={() => setActivePagination(4)} >4</div> */}
                                                <div className={`paginationCircle ${!nextLink ? 'noDispPagi' : null}`} onClick={() => {
                                                        setActivePagination(activePagination + 1);
                                                        localStorage.setItem('page', nextLink);
                                                    }
                                                } ><img src={ChevRight} alt="" /></div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container item lg={8} md={7} sm={12} xs={12} className='deskOnly' style={{display: 'block'}}>
                                        <Grid container>
                                            <Grid container direction='column' style={{ padding: '2em 1em', color: '#fff', borderRadius: '15px 15px 0 0', position: 'relative', background: 'linear-gradient(109.78deg, #2C8C63 -15.8%, #1F838E 120.54%)' }}>
                                                <img src={DesiredJob} alt='Desired job' style={{ position: 'absolute', top: '10px', right: '15px' }} />
                                                <span style={{ fontWeight: '450', color: '#FFF' }}>{selectedJob.employer_details.name}</span>
                                                <span style={{ marginTop: '5px', fontWeight: '200', fontSize: '14px', color: '#FFF' }}>{selectedJob.unmatched_school_district}</span>
                                                <span style={{ marginTop: '5px', lineHeight: '1.2', fontWeight: '600', color: '#FFF', fontSize: '33px' }}>{selectedJob.job_title}</span>
                                                <Grid container spacing={1} style={{ marginTop: '2.5em', display: 'flex', alignItems: 'center', direction: 'row' }}>

                                                    {
                                                        (selectedJob.state || selectedJob.city) ? (
                                                            <Grid item>
                                                                <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px 0 0', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >

                                                                    <img src={stateflags[selectedJob.state]} style={{maxWidth:'100%', maxHeight:'100%', borderRadius: '15px', marginLeft:'0'}}/>
                                                                    <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >{selectedJob.city} {selectedJob.state}</button>
                                                                </div>

                                                            </Grid>
                                                        ) : null
                                                    }
                                                    <Grid item>
                                                        {
                                                            selectedJob.license_required ? (
                                                                <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >

                                                                    <img src={Teacher} />
                                                                    <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} > Teacher’s license  Required</button>
                                                                </div>
                                                            ) : null
                                                        }
                                                    </Grid>

                                                    <Grid item>
                                                        {
                                                            selectedJob.salary_from ? (
                                                                <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                                    {calculateDollar(selectedJob.salary_from)}
                                                                    <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Salary Estimate</button>
                                                                </div>
                                                            ) : null
                                                        }
                                                    </Grid>
                                                    <Grid item>
                                                        {
                                                            selectedJob.phd_required ? (
                                                                <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >

                                                                    <img src={Phd} />
                                                                    <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >    PHD Required</button>
                                                                </div>
                                                            ) : null
                                                        }
                                                    </Grid>
                                                    {
                                                        selectedJob.shift_type ? (
                                                            <Grid item>
                                                                <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >

                                                                    <img src={FullTime} />
                                                                    <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >{selectedJob.shift_type}</button>
                                                                </div>

                                                            </Grid>
                                                        ) : null
                                                    }
                                                </Grid>
                                                <Grid container justifyContent='space-between' >
                                                    <Grid container direction='column' style={{ marginTop: '1em' }} item lg={6} >
                                                        {
                                                            selectedJob.date_created ? (
                                                                <span style={{ fontSize: '14px', color: '#FFF', marginTop: '25px' }}>Start Date: <span style={{ fontWeight: '400' }}>{selectedJob.date_created}</span></span>
                                                            ) : null
                                                        }
                                                        {
                                                            selectedJob.expiry_date ? (
                                                                <span style={{ fontSize: '14px', color: '#FFF', marginTop: '10px' }}>Job expiry: <span style={{ fontWeight: '400' }}>{selectedJob.expiry_date}  
                                                                {
                                                                    calculateDayLeft(selectedJob.expiry_date) ? (
                                                                        <> ({calculateDayLeft(selectedJob.expiry_date)} days left apply now)</>
                                                                    ) : <span style={{fontWeight : 'bold'}}> Expired!</span>
                                                                } 
                                                                </span>
                                                                </span>
                                                            ) : null
                                                        }
                                                    </Grid>

                                                    <Grid container justifyContent={width < 1280 ? 'center' : 'flex-end'} style={{ marginTop: '1em' }} alignItems='center' direction='row' item lg={6} >
                                                        {
                                                            selectedJob.is_saved_job === 1 ? 
                                                            <FaHeart style={{color : 'orange'}} size='1.5em' onClick={()=>saveJobFn(selectedJob.id)} /> : 
                                                            <FaRegHeart  size='1.5em' onClick={()=>saveJobFn(selectedJob.id)} />
                                                        }
                                                        <span style={{ marginLeft: '5px' }}>Save Job</span>

                                                        <a href={selectedJob.apply_url} target="_" style={{ color: 'unset', textDecoration: 'none' }}>
                                                            <button style={{ color: '#000', background: '#FFF', padding: '0.5em 1em', fontSize: '19px', borderRadius: '25px', border: '0px', marginLeft: '15px', fontWeight: '600' }}>
                                                                Apply now
                                                            </button>
                                                        </a>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                            <Grid container direction='column' style={{ padding: '2em 1em', color: '#000', borderRadius: '0 0 15px 15px',position: 'relative', background: '#fff' }}>
                                                <Grid container spacing={1}>
                                                    <Grid container direction='column' item lg={6} md={6} sm={6} xs={12}>
                                                        <span style={{ fontWeight: '500', fontSize: '19px' }}>Learn more about the employer</span>
                                                        <span style={{ fontWeight: '400', fontSize: '14px' }}>Chat with people who already worked at this school and get to know your future job</span>

                                                    </Grid>

                                                    <Grid container spacing={1} direction='row' item lg={6} md={6} sm={6} xs={12}>
                                                        <Grid item lg={6}>
                                                            <div style={{ border: '1px solid #E4E8F1', padding: '0 5px', background: '#FFF', height: '62px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >

                                                                <img src={Profile} />
                                                                <button style={{ background: '#FFF', fontWeight: '600', textAlign: 'start', border: 'none', fontSize: '10px' }} >Chat with employees of this school </button>
                                                            </div>

                                                        </Grid>
                                                        <Grid item lg={6}>
                                                            <div style={{ border: '1px solid #E4E8F1', padding: '0 5px', background: '#FFF', height: '62px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >

                                                                <img src={School} />
                                                                <button style={{ background: '#FFF', fontWeight: '600', textAlign: 'start', border: 'none', fontSize: '10px' }} >Chat with Hiring department of this school </button>
                                                            </div>

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid container direction='column' style={{ margin: '3em 0' }}>
                                                    <Tabs
                                                        variant="scrollable"
                                                        scrollButtons
                                                        allowScrollButtonsMobile
                                                        value={tabValue}
                                                        onChange={(event, newVal) => { setTabValue(newVal); }}
                                                        textColor="inherit"
                                                        TabIndicatorProps={{ style: { background: 'orange' } }}
                                                        aria-label="secondary tabs example"
                                                    >
                                                        <Tab style={{ textTransform: 'none' }} value="job-description" label="Job Description" />
                                                        <Tab style={{ textTransform: 'none' }} value="employer-details" label="Employer Details" />
                                                    </Tabs>
                                                    {renderSwitch(tabValue)}
                                                    {/* <Grid container direction='column' style={{ marginTop: '1em',flexWrap:'wrap' }}>
                                                        <span style={{ fontSize: '22px', fontWeight: '550' }}>Full Job Description</span>
                                                        {selectedJob.description ? selectedJob.description : 'No Description Available'}
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                               </> : <>
                                                        
                                    <Grid container direction='column' style={{ width: '90%' }}>

                                        <Container  style={{ margin: 'auto',justifyContent:'center',textAlign:'center',alignItems:'center',display:'flex',flexDirection:'column',width:'100%',minHeight:'392px',padding: '0',background:'#FFF',boxShadow:'0px 2px 10px rgba(134, 150, 166, 0.19)',borderRadius:'10px' }} >
                                            <img style={{width:'100%',maxWidth:'20em'}}  src={NoSavedJobsHeart} alt=''/>
                                            <span style={{fontSize:'23px',padding:'1em',fontWeight:'500'}}>You have not any saved job yet</span>
                                            <p style={{color:'#808080',padding:'1em',maxWidth:'37em'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus maecenas nunc mattis malesuada</p>
                                        </Container>
                                    </Grid>

                                    <Grid container direction='column' style={{ width: '90%' }}>

                                        <span style={{ fontSize: '19px', color: '#808080', padding: '24px' }}>RECOMENDED JOBS</span>
                                        <Container style={{ margin: 'auto', padding: '0' }} >
                                            {
                                                recommendJobsLoader && recommendJobsLoader ? 
                                                <div className='d-flex justify-content-center'>
                                                    <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
                                                </div> :
                                                <Carousel
                                                    ssr
                                                    partialVisbile
                                                    itemClass="image-item"
                                                    infinite={true}
                                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                                    autoPlaySpeed={5000}
                                                    autoPlay
                                                    responsive={responsive1}>
                                                    {recommendedJobs.map(props => {
                                                        return (
                                                            <Grid container alignItems='center' justifyContent='center' >

                                                                <Grid container style={{ boxShadow: '0px 0px 7px 0px rgb(0 0 0 / 30%)', padding: '20px 15px 40px 15px', background: '#fff', margin: '0.5em 10px', borderRadius: '10px' }} item lg={12} direction='column'  >

                                                                    <Grid container justifyContent='space-between' direction='row' >
                                                                        <img style={{ height: '54px', width: '50px' }} src={randomSelector(logoArray)} />
                                                                        <img src={randomSelector(secondLogoArray)} />
                                                                    </Grid>
                                                                    <span style={{ fontSize: '24px', fontWeight: '500', marginTop: '20px' }}>{props.job_title}</span>
                                                                    <span style={{ fontWeight: '400', color: '#939393', fontSize: '14px' }}>{props.location}</span>
                                                                    {
                                                                        selectedJob.salary_from ? <>
                                                                        <div style={{ marginTop: '2.5em', display: 'flex', alignItems: 'center', direction: 'row' }}>
                                                                            <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                                                {calculateDollar(selectedJob.salary_from)}
                                                                                <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Salary Estimate</button>
                                                                            </div>
                                                                        </div>
                                                                        </> : null
                                                                    }
                                                                    <div style={{ marginTop: '0.5em', display: 'flex', alignItems: 'center', direction: 'row' }}>
                                                                        {
                                                                            selectedJob.phd_required ? <>
                                                                                <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                                                    <img src={Phd} />
                                                                                    <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >    PHD Required</button>
                                                                                </div> : null
                                                                            </> : null
                                                                        }           
                                                                    </div>
                                                                    <div style={{ marginTop: '0.5em', display: 'flex', alignItems: 'center', direction: 'row' }}>
                                                                        {
                                                                            selectedJob.license_required ? <>
                                                                                <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >

                                                                                    <img src={Teacher} />
                                                                                    <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} > Teacher’s license  Required</button>
                                                                                </div>
                                                                            </> : null
                                                                        }
                                                                    </div>
                                                                    <div style={{ marginTop: '0.5em', display: 'flex', alignItems: 'center', direction: 'row' }}>
                                                                        {
                                                                            selectedJob.shift_type ? <>
                                                                                <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}    >
                                                                                    <img src={FullTime} />
                                                                                    <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Full-time</button>
                                                                                </div>
                                                                            </> : null
                                                                        }        
                                                                    </div>
                                                                </Grid>
                                                            </Grid>)
                                                    })}
                                                </Carousel>
                                            }
                                        </Container>
                                    </Grid>
                               </>
                            }
                        </Grid>
                    </Container>
                </Grid>
            </Grid >
            <Footer/>
        </>
    )
}

export default SavedJobs;