import React, {useState, useEffect} from 'react'
import NavBarSrch from '../NavBar_withSearch'
import NavBar from '../NavBar'
import Footer from '../Footer'
import Delete from './Frame 323.png';
import axios from 'axios';
import Cookies from 'js-cookie';
import Loader from '../loader/loader'

import './job-alert.css'
import { getDateIntervalDuration } from '@amcharts/amcharts5/.internal/core/util/Time';
import axiosInstance from '../../axios';

const JobAlert = () => {

  const [accessToken, setaccessToken] = useState('');
  useEffect(() => {
      if ( Cookies.get( 'access_token' ) ) {
          setaccessToken( Cookies.get( 'access_token' ) );
      } else {
          window.location.href = '/register';
      }
  }, []);



  useEffect(()=>{
      if(accessToken.length > 0){
          getData();
      }
  }, [accessToken])

  const [jobAlerts, setjobAlerts] = useState([]);
  const [loader, setloader] = useState(false);


  const getData = () => {
    setloader(true);
    axiosInstance.get('https://framechange--backend.herokuapp.com/seeker/job_alert').then(res=>{
      console.log(res.data.data);
      setjobAlerts(res.data.data);
      setloader(false);
    }).catch(err=>{
      console.log(err);
      setloader(false);
    })
  }


  return (
    <div>
      {window.innerWidth > 960 ? <NavBarSrch /> : <NavBar />}
      {loader ? <Loader /> : null}
      <div className="jobAlertCont">
        <h3>Job alerts</h3>
        <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. </p>
        <div className="jobAlertContCards">
          {
            jobAlerts.map(jobAlert=>{
              return(
                <JobAlertCard data={jobAlert} accessToken={accessToken} getData={()=>getData()} jobTitle='Teacher' city='New york' time='Full-time' place='Middle School' email='robert.fox@mailcom' />
              )
            })
          }
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default JobAlert

const JobAlertCard = ( props ) => {
  const data = props.data;
  const getDate = (date) => {
    var d = new Date(date);
    return d.toLocaleDateString();
  }

  const handleDeleteJobAlert = (id) => {
    axiosInstance.delete("/seeker/job_alert/", {
      params : {
        // access : props.accessToken,
        id : id
      }
    }).then(res=>{
      console.log(res);
      props.getData();
    }).catch(err=>{
      console.log(err.response.data);
    })
  }

  return (
    <div className="JobAlertCard">
      <div className="alertFirstCol">
        <div className="alertTitle">{data.job_title}</div>
        <div className="alertCity">{data.location}</div>
        <div className="alertCity">{getDate(data.created_at)}</div>
        {/* <div className="alertCity">{props.place}</div> */}
      </div>
      <div className="alertSecondCol">
        <div className="alertCity">Alert frequency</div>
        <select style={{border: 'none'}}><option value="">Monthly</option></select>
        <div className="alertCity" style={{marginTop: '17px'}}>{data.target_email}</div>
      </div>
      <div className="alertThirdCol">
        <img src={Delete} style={{"cursor" : "pointer"}} onClick={()=>handleDeleteJobAlert(data.id)} alt="" />
      </div>
    </div>
  )
}
