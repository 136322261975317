import { Box, Container, Grid, Typography, Tabs, Tab,  FormControlLabel, Switch } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import "react-multi-carousel/lib/styles.css";
import NavBar_emp from '../NavBar_emp_searchBar'
import Dollar from '../Dashboard/dollar.svg';
import Phd from '../Dashboard/phd.svg';
import Teacher from '../Dashboard/teacher.svg';
import FullTime from '../Dashboard/fullTime.svg';
import Time from '../Dashboard/time.svg';
import San from '../Dashboard/san.svg';
import { FaRegHeart } from 'react-icons/fa';
import { FaHeart } from 'react-icons/fa';
import DesiredJob from './desiredJob.svg';
import GreenDesiredJob from './GreenDesiredJob.svg';
import Profile from './profile.svg';
import School from './school.svg';
import Tick from './Tick.svg'
import { useHistory } from 'react-router-dom';
import Modal from '@mui/material/Modal'
import CloseIcon from '../../assets/Close.svg'
import LeftChevron from '../../assets/LeftChevron.svg'
import RightChevron from '../../assets/RightChevron.svg'
import Badge1 from '../../assets/badge1.svg'
import Badge2 from '../../assets/badge2.svg'
import Q from '../../assets/q.svg'
import Mail from './mail.png'
import Call from './call.png'
import Contact from './contact.png'
import Saji from './saji.png'
import { ProfileTags } from '../profile/profile'
import GreenDot from './Frame 6415.png'
import GreyDot from './Frame 6416.png'
import {Helmet} from "react-helmet";
import './search-jobs.css'
import Cookies from 'js-cookie';
import axios from 'axios';
import ChevRight from '../Search-jobs/chevron-right 1.png';
import axiosInstance from '../../axios';
import Footer from '../Footer/index'
import loaderJson from '../../assets/loader.json';
import mainLoaderJson from '../../assets/mainLoader.json';
import Lottie from 'react-lottie';
import { SelectAllRounded } from '@material-ui/icons';
import srch from '../Dashboard/searchicon.svg';
import pin from '../Dashboard/pincodeicon.svg';
import bgimg from '../Dashboard/mainbg.jpg';
import Select from 'react-select';
import LinkedIn from '../profile/linkedin.svg';
import facebook from '../profile/facebook.svg';
import twitter from '../profile/twitter.svg';


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}
function SavedJobs () {
    
    const [candidates, setcandidates] = useState([]);
    const [selectedCandidate, setselectedCandidate] = useState({
        id: null,
        email: null,
        full_name: null,
        phd_avail: false,
        work_exp_avail: false,
        education_avail: false,
        video_resume: null,
        profile_pic: null,
        certificates: [],
        educations: [],
        work_exps: [],
        languages: [],
        skills: [],
        opportunities: [],
        documents: [],
        seeker_type: null,
        about: null,
        phone: null,
        address: null,
        city: null,
        state: null,
        zipcode: null,
        preffered_job_titles: null,
        preffered_job_locations: null,
        minimum_salary: null,
        job_type: null,
        online_jobs_only: null,
        notify_about_nearby_jobs: false,
        last_modified: null,
        user: null,
        is_saved : false,
        social_link: {social_links: {
            facebook: null,
            twitter: null,
            linkedin: null
        }},
    });

    const [candidateKeyword, setcandidateKeyword] = useState(localStorage.getItem('candidateKeyword'));
    const [candidateLocation, setcandidateLocation] = useState(localStorage.getItem('candidateLocation'));

    // pagination
    const [nextLink, setnextLink] = useState(null);
    const [prevLink, setprevLink] = useState(null);
    const [paginationPages, setpaginationPages] = useState([]);
    const [activePagination, setActivePagination] = useState(1);

    const stringToArray = (str) => {
        let arr = str.replace(/'/g, '"');
        arr = JSON.parse(arr);
        return arr;
    }

    window.addEventListener('storage', ()=>{
        console.log("I am Here");
        console.log(localStorage.getItem('candidateKeyword'));
    })

    const [filtersApplied, setfiltersApplied] = useState({
        is_phd_required : false,
        is_license_required : false,
        salary_filter : "",
    });

    const [accessToken, setaccessToken] = useState('');
    useEffect(() => {
        if ( Cookies.get( 'access_token' ) ) {
            setaccessToken( Cookies.get( 'access_token' ) );
        } else {
            window.location.href = '/register';
        }
    }, [])
    
    useEffect(() => {
        // axios.get(`https://framechange--backend.herokuapp.com/district/candidate-search/?keyword=${'teacher' || localStorage.getItem('candidateKeyword')}&location=${'mumbai' || localStorage.getItem('candidateLocation')}&shift_type=&salary_filter=39&is_remote=`)
        // .then(res => setcandidates(res.data.results))
        getData();
        getJobs();

    }, [accessToken, localStorage.getItem('candidate_page'), localStorage.getItem('candidateKeyword'), localStorage.getItem('candidateLocation'), filtersApplied]);
    

    const getData = () => {
        setloader(true);
        console.log(filtersApplied.is_phd_required, filtersApplied.is_license_required);
        axiosInstance.get('/district/save_job_seeker', {
            params : {
                // access : accessToken,
                page : localStorage.getItem('candidate_page') ? localStorage.getItem('candidate_page') : 1,
                keyword : localStorage.getItem('candidateKeyword') ? localStorage.getItem('candidateKeyword') : null,
                location : localStorage.getItem('candidateLocation') ? localStorage.getItem('candidateLocation') : null,
                salary_filter : filtersApplied.salary_filter ? filtersApplied.salary_filter : null,
                is_license_required : filtersApplied.is_license_required ? filtersApplied.is_license_required : null,
                is_phd_required : filtersApplied.is_phd_required ? filtersApplied.is_phd_required : null
            }, timeout : 1000 * 10
        })
        .then(res=>{
            setcandidates(res.data.results);
            // setting links
            const nextNumber = res.data.links.next ? res.data.links.next.split('page=')[1] : null;
            const prevNumber = res.data.links.previous ? res.data.links.previous.split('page=')[1] : null;

            setnextLink(nextNumber);
            setprevLink(prevNumber);
            setpaginationPages(res.data.links.list_of_pages);

            if(res.data.links.list_of_pages.length > 0){
                setselectedCandidate(res.data.results[0]);
            }

            setloader(false);
        }).catch(err=>{
            console.log(err);
            setloader(false);
        })
    }

    const addActiveClass = async (e) => {
        // if(window.innerWidth > 960){
            const jobname_id = `jobCard_${e}`;
            const elements = document.getElementsByClassName('activeJob');
            Array.from(elements).forEach(item=>{
                item.classList.remove('activeJob');
            })
            const job =candidates.find(item=>item.id === e)
            setselectedCandidate(job);
            document.getElementById(jobname_id).classList.add('activeJob');
        // }else{
        //     history.push('/job-detail');
        //     sessionStorage.setItem('mobJobId', e);
        // }
      
        
        // axiosInstance.post('/district/add_job_count/', {job_id : e}).then(res=>{
        //     return;
        // }).catch(err=>{
        //     console.log(err);
        // })
    }


    const [tabValue, setTabValue] = React.useState('job-description');
    const [loader, setloader] = useState(false);

    const [toggleHeart, setToggleHeart] = useState( false )
    
    const [modal, setModal] = useState(false)

    const changeColor = useCallback(() => {
        setToggleHeart(!toggleHeart)
    }, [])

    const addToFavorite = (id) => {
        // console.log(id);
        const data = {
            // access : accessToken,
            job_seeker_id : id
        }
        // console.log(data);
        axiosInstance.post('/district/save_job_seeker/', data).then(res=>{
            // console.log(res);
            getData();
        }).catch(err=>{
            console.log(err);
        })
    }

    const { height, width } = useWindowDimensions();
    const [workExperienceForm, setWorkExperienceForm] = useState( false );
    const [activeJob, setActiveJob] = useState();
    const [jobs, setJobs] = useState([])

    const getJobs = () => {
        axiosInstance.get(`/district/get_jobs/?page=1`).then(res => setJobs(res.data.results))
    }

    const WorkExperienceForm = (props) => {
        const handleSendInvite = () => {
            axiosInstance.post('/messaging/create_room/', {
                // access: accessToken,
                receiver_id: selectedCandidate.user_id,
                job_id: activeJob
            }).then(res => {
                history.push(`/message?room_id=${res.data.room_id}`)
            })
        }
  
    const changeStyle = (e) => {
        Array.from(document.getElementsByClassName('modal_items')).map(item=>{
           if(item.classList.contains('modal_active')){
               item.classList.remove('modal_active');
           }
        })
        document.getElementById(`grid_id_${e}`).classList.add('modal_active');
    }

    return (
      <div>
        <div style={{ marginRight: '3em', marginLeft: '3em', display: "flex", flexDirection: 'column' }}>
              <Typography style={{ fontSize: '19px', fontWeight: '600' }}>Send Job Invite
              <p style={{fontSize : '15px', color : '#888282'}}>Click on a Job Post to select and send Invite</p>
              </Typography>
              <br />
              <form >
                      <Grid  container item spacing={3} style={{display : 'flex', justifyContent : 'center'}} >
                          {
                              jobs.map((e, i)=>{
                                  return(
                                    <Grid key={i} className='modal_items' id={`grid_id_${i}`} item lg={12} style={{
                                        borderRadius : '10px', border : '1px solid black', marginBottom : '15px', cursor : 'pointer'
                                        }} onClick={()=>{changeStyle(i); setActiveJob(e.id)}} >
                                        <div style={{display : 'flex', flexDirection : 'column', justifyContent : 'space-between'}} >
                                          <div >
                                              <h5>{e.job_title}</h5>
                                              <p style={{color : '#504b4b'}}>{e.district}</p>
                                          </div>
                                          <div style={{display : 'flex', justifyContent : 'space-between'}}>
                                              <p className='workExp_subdiv'>Posted on {e.expiry_date}</p>
                                              <p className='workExp_subdiv'>Job Expires : {e.date_posted}</p>
                                          </div>
                                        </div>
                                    </Grid>
                                  )
                              })
                          }                          
                      </Grid>
              </form>
  
        </div>
        <div className="btnProfileModalCont">
          <button className='btnContCancelBtn' id='work_exp_close_btn' onClick={props.closeModal}>
            Cancel
          </button>
          <button className='btnContSaveBtn' onClick={handleSendInvite}>
            Send Invite
          </button>
        </div>
      </div>
    )
  }
  
  const history = useHistory();

    const [filters, setfilters] = useState({
        shift_type : [],
        salary : [],
        distance : [],
        distance : []
    });

    

    const clearFilter = () =>{
        setfiltersApplied({
            shift_type : "",
            salary_filter : "",
        });

        localStorage.removeItem('candidateKeyword');
        localStorage.removeItem('candidateLocation');

        setjobTitleSearch('');

    }
    
    const [openFilter, setOpenFilter] = useState( false )
        const [jobTitleSearch, setjobTitleSearch] = useState(localStorage.getItem('jobTitleSearch') > 0? localStorage.getItem('jobTitleSearch') : '');
    const [locationSearch, setlocationSearch] = useState(localStorage.getItem('locationSearch') > 0? localStorage.getItem('locationSearch') : '');
    const submitQuery =  () => {

    // console.log(jobTitle.value, location.value);

    localStorage.setItem('jobTitleSearch', jobTitleSearch);
    localStorage.setItem('locationSearch', locationSearch);

    history.push('/search-candidate');
}
    
    return (
        <>
            <Helmet>
                <title>Favorite Candidates | Frame Change</title>
            </Helmet>
            {
                loader ? 
                <div className='' style={{height : '120vh', display : 'grid', placeContent : 'center', width : '100vw', backgroundColor : 'rgb(255,255,255,0.6)', position : 'fixed', zIndex: '100'}}>
                    {/* <iframe src="https://embed.lottiefiles.com/animation/66433"></iframe> */}
                    <Lottie 
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: loaderJson,
                            rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                            }
                        }}
                        height={200}
                        width={200}
                    />
                </div> : null
            }
            <NavBar_emp getData={()=>getData()} />
            <Grid style={{background: '#F5F7FA', maxWidth: '100%', width:'100%', margin:'auto'}} container alignItems='center' justifyContent='center' direction='column'>

                <Grid container direction='column' style={{ width: '90%', padding: '3.5em 0', maxWidth: '100%', marginTop: '8vh'}}>

                    {/* <Container style={ { margin: 'auto', padding: '0' } } >
                        <div style={ { marginTop: '7em', marginBottom: '1em' } }>
                        </div> */}
                        <div className="mobOnlyFilter" style={{gap: '5px', alignItems: 'center', width: '100%', marginBottom: '50px'}}>
                        <Grid item xs={12}>
                                    <div className="searchBoxSmall" style={styles.searchBoxSmall}>
                                        <Grid container direction='row' spacing={2} style={{alignItems: 'center', width: '100%', flexWrap: 'nowrap'}}>
                              <Grid item xs={'auto'} style={{paddingRight: '2px'}}>
                                  <img src={srch} alt="Search by Location"/>
                              </Grid>
                              <Grid item xs={'auto'} style={{width: '100%'}}>
                                  <div style={styles.hiddenLabel} aria-hidden="true">Search Candidate by Job Title</div>
                                  <Select
                                        // isMulti
                                        // className=''
                                        id='jobTitleID'
                                        options={jobTitlesArr}
                                        value={jobTitleSearch || {value: '', label: ''}}
                                        onChange={e => setjobTitleSearch(e.value)}
                                        className='stateDD'
                                        placeholder= 'Job Title'
                                    />
                              </Grid>
                          </Grid>
                                    </div>
                                </Grid>
                            <div style={{width: '42px', height: '42px', cursor: 'pointer'}} onClick={submitQuery}>
                                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21C42 32.598 32.598 42 21 42C9.40202 42 0 32.598 0 21Z" fill="url(#paint0_linear_3993_30332)"/>
<path d="M20.3333 25.6667C23.2789 25.6667 25.6667 23.2789 25.6667 20.3333C25.6667 17.3878 23.2789 15 20.3333 15C17.3878 15 15 17.3878 15 20.3333C15 23.2789 17.3878 25.6667 20.3333 25.6667Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M26.9996 26.9991L24.0996 24.0991" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_3993_30332" x1="1.81035" y1="-30.1304" x2="70.7503" y2="-5.34066" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFCF20"/>
<stop offset="0.932292" stop-color="#FD8012"/>
</linearGradient>
</defs>
</svg>

                            </div>
                            <div onClick={() => setOpenFilter(!openFilter)} style={{width: '42px', height: '42px', borderRadius: '50%', backgroundColor: '#E9E9E9', padding: '13.6px', display: 'flex', cursor: 'pointer'}}>
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.50033 8.17826V14.5003C6.50034 14.5855 6.52214 14.6694 6.56368 14.7438C6.60522 14.8183 6.66511 14.8809 6.73766 14.9257C6.81021 14.9705 6.89301 14.996 6.97819 14.9998C7.06337 15.0035 7.1481 14.9855 7.22433 14.9473L10.2243 13.4473C10.3073 13.4057 10.377 13.3419 10.4258 13.2629C10.4745 13.184 10.5003 13.093 10.5003 13.0003V8.17826L14.8873 2.81726C14.929 2.76644 14.9602 2.70791 14.9792 2.64503C14.9982 2.58215 15.0046 2.51613 14.9981 2.45076C14.9916 2.38539 14.9723 2.32194 14.9413 2.26403C14.9103 2.20612 14.8682 2.15489 14.8173 2.11326C14.7665 2.07163 14.708 2.04042 14.6451 2.02141C14.5822 2.00239 14.5162 1.99595 14.4508 2.00245C14.3855 2.00895 14.322 2.02827 14.2641 2.05929C14.2062 2.09031 14.155 2.13244 14.1133 2.18326L9.61333 7.68326C9.54017 7.77269 9.50024 7.88471 9.50033 8.00026V12.6913L7.50033 13.6913V8.00026C7.50043 7.88471 7.4605 7.77269 7.38733 7.68326L3.55533 3.00026H11.5003C11.6329 3.00026 11.7601 2.94758 11.8539 2.85381C11.9477 2.76004 12.0003 2.63287 12.0003 2.50026C12.0003 2.36765 11.9477 2.24047 11.8539 2.1467C11.7601 2.05294 11.6329 2.00026 11.5003 2.00026H2.50033C2.40561 2.00019 2.31282 2.02704 2.23277 2.07766C2.15271 2.12829 2.08868 2.20061 2.04813 2.28621C2.00758 2.37182 1.99218 2.46718 2.00373 2.56119C2.01527 2.65521 2.05328 2.74401 2.11333 2.81726L6.50033 8.17826Z" fill="#4A4A4A"/>
</svg>
                            </div>
                    </div>
                        <div style={ { marginBottom: '2em', maxWidth: '100%'  } } className={`filterCont ${openFilter ? null : 'deskOnly'}`}>
 <Grid item xs={12} className='mobOnlyFilter'>
                                    <div className="searchBoxSmall" style={styles.searchBoxSmall}>
                                        <Grid container direction='row' spacing={1} style={{alignItems: 'center', width: '100%', flexWrap: 'nowrap'}}>
                              <Grid item xs={'auto'} style={{paddingRight: '2px'}}>
                                  <img src={pin} alt="Search by Location"/>
                              </Grid>
                              <Grid item xs={'auto'} style={{width: '100%'}}>
                                  <div style={styles.hiddenLabel} aria-hidden="true">State</div>
                                  <Select
                                        // isMulti
                                        // className=''
                                        id='locationID'
                                        options={Locoptions}
                                        onChange={e => setlocationSearch(e.value)}
                                        className='stateDD'
                                        placeholder= 'Search by state'
                                    />
                              </Grid>
                          </Grid>
                                    </div>
                                </Grid>                            
                            {/* <select>
                                <option value="" disabled selected>Job Type</option>
                                {
                                    filters.shift_type.map(item=>{
                                        if(item){
                                            return(
                                                <option value={item}>
                                                    {item}
                                                </option>
                                            )
                                        }
                                    })
                                }
                            </select>
                            <select name="posted_any_time_filter" id="posted_any_time_filter">
                                <option value="" disabled selected>Posted any time</option>
                                <option value="last_week">Last week</option>
                                <option value="last_two_week">Last two weeks</option>
                                <option value="last_month">Last Month</option>
                            </select> */}
                            <select name="salary_filter" id="salary_filter" value={filtersApplied.salary_filter} onChange={(e)=>{
                                setfiltersApplied({...filtersApplied, salary_filter : e.target.value})
                            }}>
                                <option value="" disabled selected >
                                   Hourly Salary
                                </option>
                                <option value="0-19">$ ($0-$19)</option>
                                <option value="20-39">$$ ($20-$39)</option>
                                <option value="40-59">$$$ ($40-$59)</option>
                                <option value="60-79">$$$$ ($60-$79)</option>
                                <option value="80">$$$$$ (>$79)</option>
                            </select>

                            <Grid item >
                               <FormControlLabel
                                    value="start"
                                    checked={filtersApplied.is_license_required} onChange={e=>setfiltersApplied({...filtersApplied, is_license_required : e.target.checked})}
                                    control={<Switch color="primary" id="remoteJobsSwitch"  />}
                                    label={<span style={{fontSize : '16px'}}>License Required</span>}
                                    labelPlacement="start"
                                />
                                
                            </Grid>
                            <Grid item >
                               <FormControlLabel
                                    value="start"
                                    checked={filtersApplied.is_phd_required} onChange={e=>setfiltersApplied({...filtersApplied, is_phd_required : e.target.checked})}
                                    control={<Switch color="primary" id="remoteJobsSwitch"  />}
                                    label={<span style={{fontSize : '16px'}}>PHD Required</span>}
                                    labelPlacement="start"
                                />
                                
                            </Grid>

                            {/* <select>
                                <option value="">
                                    Distance
                                </option>
                            </select> */}
                            {/* <select>
                                <option value="">
                                    Distance
                                </option>
                            </select> */}
                            <div className="clearFil" onClick={()=>clearFilter()}>Clear filters</div>
                        </div>
                        <Grid container spacing={4}>

                            <Grid container item className="teacherCards" spacing={2} lg={4} md={5} sm={12} xs={12}>
                                <Grid container direction='column' >
                                    

                                    { candidates && candidates.map( e =>
                                        <Grid container style={{ borderRadius: '10px', background: '#fff'}}  >
                                        <Grid id={`jobCard_${e.id}`} className='jobCard' onClick={()=>addActiveClass(e.id)} item lg={12} style={{ padding: '1em 0', width:'100%'}}>
                                            <div style={{ padding: '10px' }}>
                                                <Grid container spacing={2}>
                                                    <Grid item lg={10}>
                                                        <div style={{display: 'inline-flex', gap: '12px'}}>
                                                            <img src={e.profile_pic ? e.profile_pic : `https://ui-avatars.com/api/?name=${e.full_name}?rounded=true`} style={{width: '72px',height: '72px', borderRadius: '50%'}} />
                                                            <div>
                                                                <div style={ { fontWeight: '500', fontSize: '19px' } }>{e.full_name}</div>
                                                                <Typography style={ { fontSize: "14px", color: '#636363' } }>{e.preffered_job_titles?  stringToArray(e.preffered_job_titles).join() : null}</Typography>
                                                                <Typography style={{ fontSize: "14px", color: '#636363' }}>{e.city}, {e.state}</Typography>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item container spacing={1} lg={11}>
                                                        <Grid item lg={6}>
                                                            {
                                                                e.minimum_salary ? 
                                                                <Box>
                                                                    <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}>
                                                                        <img src={Dollar} />
                                                                        <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Salary Estimate</button>
                                                                    </div>
                                                                </Box> : null
                                                            }
                                                        </Grid>
                                                        <Grid item lg={6}>
                                                            {
                                                                e.phd_avail ?
                                                                <Box>
                                                                    <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}>
                                                                        <img src={Phd} />
                                                                        <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >PHD Required</button>
                                                                    </div>
                                                                </Box> : null
                                                            }
                                                        </Grid>
                                                        <Grid item lg={8}>
                                                            {
                                                                e.education_avail ? 
                                                                <Box>
                                                                    <div style={{ border: '1px solid #E4E8F1', background: '#FFF', height: '34px', padding: '0 10px', borderRadius: '25px', display: 'flex', alignItems: 'center' }}>
                                                                        <img src={Teacher} />
                                                                        <button style={{ background: '#FFF', border: 'none', fontSize: '12px' }} >Teacher’s license  Required</button>
                                                                    </div>
                                                                </Box> : null   
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={1} onClick={()=>addToFavorite(e.id)}>
                                                        {e.is_saved ? <FaHeart size='1.5em' color='orange' /> : <FaRegHeart size='1.5em' />}
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container lg={11} justifyContent="space-between">
                                                    <Grid item lg={11}>
                                                        <Typography style={{ fontSize: "14px", color: '#636363' }}>Last profile updated : {e.last_modified}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        </Grid>
                                    )}

                                    {
                                       candidates &&candidates.length == 0 ? <div className="col-12 d-flex justify-content-center pt-2"><h5>No Candidates Available</h5></div> : null
                                    }
                                        
                                        


                                </Grid>

                                <div className="paginationCOnt">
                                    <div className={`paginationCircle ${!prevLink ? 'noDispPagi' : null}`} onClick={() => {
                                            setActivePagination(activePagination - 1);
                                            localStorage.setItem('candidate_page', prevLink);
                                        }
                                    } ><img src={ChevRight} alt="" style={{transform: 'rotate(180deg)'}} /></div>

                                    {
                                        paginationPages.map(item=>{
                                            return(
                                                <div className={`paginationCircle ${activePagination === item ? 'activePagi' : null}`} onClick={() => {
                                                    setActivePagination(item);
                                                    localStorage.setItem('candidate_page', item);
                                                }} >{item}</div>
                                            )
                                        })
                                    }

                                    {/* <div className={`paginationCircle ${activePagination === 1 ? 'activePagi' : null}`} onClick={() => setActivePagination(1)} >1</div>
                                    <div className={`paginationCircle ${activePagination === 2 ? 'activePagi' : null}`} onClick={() => setActivePagination(2)} >2</div>
                                    <div className={`paginationCircle ${activePagination === 3 ? 'activePagi' : null}`} onClick={() => setActivePagination(3)} >3</div>
                                    <div className={`paginationCircle ${activePagination === 4 ? 'activePagi' : null}`} onClick={() => setActivePagination(4)} >4</div> */}
                                    <div className={`paginationCircle ${!nextLink ? 'noDispPagi' : null}`} onClick={() => {
                                            setActivePagination(activePagination + 1);
                                            localStorage.setItem('candidate_page', nextLink);
                                        }
                                    } ><img src={ChevRight} alt="" /></div>
                                </div>

                            </Grid>
                            {
                                selectedCandidate && candidates.length ? <>
                                    <Grid container item className="cardDetail" lg={8} md={7} sm={12} xs={12} style={{display: 'block'}}>
                                        <Grid container style={{padding: '20px', boxSizing: 'border-box', borderRadius: '13px', boxShadow: '0px 2px 10px rgba(134, 150, 166, 0.19)'}}>
                                            <div style={{ display: 'inline-flex', gap: '20px', flexWrap: 'wrap' }}>
                                                <div style={{display: 'inline-flex', gap: '12px', width: '310px'}}>
                                                    <img alt={selectedCandidate.full_name} src={selectedCandidate.full_name ? selectedCandidate.profile_pic ? selectedCandidate.profile_pic : `https://ui-avatars.com/api/?name=${selectedCandidate.full_name}?rounded=true` : null} style={{width: '103px', height: '103px',borderRadius: '50%'}} />
                                                    <div>
                                                        <div style={ { fontWeight: '500', fontSize: '19px' } }>{selectedCandidate.full_name}</div>
                                                        <Typography style={ { fontSize: "14px", color: '#636363' } }>{selectedCandidate.preffered_job_titles ? stringToArray(selectedCandidate.preffered_job_titles) : null}</Typography>
                                                        {/* <Typography style={{ fontSize: "14px", color: '#636363' }}>{candidates[0] ? candidates[0].city : null}, {candidates[0] ? candidates[0].state : null}</Typography> */}
                                                    </div>
                                                </div>
                                                <div>
                                                    {/* <div style={{display: 'flex', gap: '7px', width: '180px', marginBottom: '8px'}}>
                                                        <img src='//unsplash.it/16/16'  />
                                                        <Typography style={{ fontSize: "12px", color: '#636363' }}>{candidates[0] ? candidates[0].email : null}</Typography>
                                                    </div> */}
                                                    {/* <div style={{display: 'flex', gap: '7px', width: '180px', marginBottom: '8px'}}>
                                                        <img src='//unsplash.it/16/16' />
                                                        <Typography style={{ fontSize: "12px", color: '#636363' }}>+{candidates[0] ? candidates[0].phone : null}</Typography>
                                                    </div> */}
                                                </div>
                                                <div>
                                                    <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }} onClick={()=>setWorkExperienceForm(true)}>
                                                        <img src={Mail} style={{cursor: 'pointer'}} />
                                                        <img src={Call} style={{cursor: 'pointer'}} />
                                                        <img src={Contact} style={{cursor: 'pointer'}} />
                                                    </div>
                                                    <img src={Saji} style={{marginTop: '16px', cursor: 'pointer'}}  onClick={()=>setWorkExperienceForm(true)} />
                                                </div>
                                            </div>
                                            <div className='profileTagCont' style={{marginTop: '20px'}}>
                                                {
                                                    selectedCandidate.phd_avail ? 
                                                    <ProfileTags img={Phd} text='Full-time, Part-time' /> : null
                                                }
                                                {
                                                    selectedCandidate.education_avail ? 
                                                    <ProfileTags img={Teacher} text="Teacher's License" /> : null
                                                }
                                                {
                                                    selectedCandidate.work_exp_avail ? 
                                                    <ProfileTags img={Teacher} text='Work Experience' /> : null
                                                }
                                                <ProfileTags img={Phd} text='Full-time' />
                                            </div>
                                            <Grid item lg={3} style={{marginTop: '20px', display: 'inline-flex', gap: '5px'}} onClick={()=>addToFavorite(selectedCandidate.id)}>
                                                {selectedCandidate.is_saved ? <FaHeart size='1.5em' color='orange' /> : <FaRegHeart size='1.5em' />} {selectedCandidate.is_saved ? <span>Saved</span> :  <span>Add to Favorites</span>}
                                            </Grid>
                                            <div style={{width: '100%', marginTop: '30px'}}>
                                                {selectedCandidate.about}
                                            </div>
                                            <hr style={{width: '100%', backgroundColor: '#DFDFDF'}} />
                                            <div style={ { width: '100%', marginBottom: '30px', display: 'inline-flex', justifyContent: 'space-between', gap: '15px', flexWrap: 'wrap' } }>
                                                {
                                                    selectedCandidate.video_resume ? <>
                                                        <div style={ { minWidth: '320px' } }>
                                                            <h4 style={{marginBottom: '20px'}}>Video Resume</h4>
                                                            <video style={{width: '350px', height: '220px', borderRadius: '10px', boxShadow: '0px 0.666328px 8.47804px rgba(0, 0, 0, 0.18)', maxWidth: '90%' }} controls>
                                                                <source src={selectedCandidate.video_resume} type='video/mp4' />
                                                            </video>
                                                        </div>
                                                    </> : null
                                                }
                                                {
                                                    selectedCandidate.skills.length ? <>
                                                        <div style={{minWidth: '320px'}}>
                                                            <h4 style={{marginBottom: '20px'}}>Skills</h4>
                                                            <ul>
                                                                {selectedCandidate.skills.map(e=><li>{e.name}</li>)}
                                                                {/* {candidates[0] ? candidates[0].skills.map(e => <li>{e}</li>) : null} */}
                                                            </ul>
                                                        </div>
                                                    </> : null
                                                }
                                            </div>
                                            <div style={ { width: '100%', marginBottom: '30px' } }>
                                                {
                                                    selectedCandidate.documents.length ? <>
                                                        <h4 style={ { margin: '10px 0' } }>Documents</h4>
                                                        {
                                                            selectedCandidate.documents.map(e=>{
                                                                return(
                                                                    <div style={{display: 'inline-flex', gap: '1.5rem', flexWrap: 'wrap', padding: '12px', borderRadius: '8px', border: '1px solid #E1E1E1', minWidth: '253px'}}>
                                                                        <div style={{display: 'inline-flex', gap: '1.2rem', width: '100%', alignItems: 'center', position: 'relative'}}>
                                                                            <div>
                                                                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M31.498 12H25.498C23.008 12 20.998 9.99 20.998 7.5V1.5C20.998 0.66 20.338 0 19.498 0H5.99805C4.34805 0 2.99805 1.35 2.99805 3V33C2.99805 34.65 4.34805 36 5.99805 36H29.998C31.648 36 32.998 34.65 32.998 33V13.5C32.998 12.66 32.338 12 31.498 12ZM25.498 30H10.498C9.65805 30 8.99805 29.34 8.99805 28.5C8.99805 27.66 9.65805 27 10.498 27H25.498C26.338 27 26.998 27.66 26.998 28.5C26.998 29.34 26.338 30 25.498 30ZM25.498 24H10.498C9.65805 24 8.99805 23.34 8.99805 22.5C8.99805 21.66 9.65805 21 10.498 21H25.498C26.338 21 26.998 21.66 26.998 22.5C26.998 23.34 26.338 24 25.498 24ZM25.498 18H10.498C9.65805 18 8.99805 17.34 8.99805 16.5C8.99805 15.66 9.65805 15 10.498 15H25.498C26.338 15 26.998 15.66 26.998 16.5C26.998 17.34 26.338 18 25.498 18ZM23.998 1.5V6C23.998 7.65 25.348 9 26.998 9H31.498C32.848 9 33.508 7.38 32.548 6.45L26.548 0.45C25.618 -0.51 23.998 0.18 23.998 1.5Z" fill="#0B0B0B" fill-opacity="0.9"/>
                                                                                </svg>
                    
                                                                            </div>
                                                                            <div>
                                                                                <div style={{fontSize: '14px', fontWeight: '500'}}>Resume.docx</div>
                                                                                <div style={{fontSize: '14px', fontWeight: '300', color: '#0B0B0B'}}>20/02/22</div>
                                                                            </div>
                                                                            <div  style={{position: 'absolute', right: '0px'}}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    {/* <iframe hidden0[']></iframe> */}
                                                                                    <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#2F3338" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                    <path d="M5.83398 8.33398L10.0007 12.5007L14.1673 8.33398" stroke="#2F3338" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                    <path d="M10 12.5V2.5" stroke="#2F3338" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                
                                                                )
                                                            })
                                                        }
                                                    </> : null
                                                }
                                            </div>
                                            <div style={ { width: '100%', marginBottom: '30px' } }>
                                                {
                                                    selectedCandidate.work_exps.length ? <>
                                                        <h4 style={ { margin: '10px 0' } }>WORK EXPERIENCE</h4>
                                                        {
                                                            selectedCandidate.work_exps.map(e=>{
                                                                return(
                                                                    <div style={{display: 'flex', gap: '30px', flexWrap: 'wrap' }}>
                                                                        <div  className='deskOnly' style={ { width: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                                            <img src={ GreenDot } style={ { width: '15px', height: '15px' } } />
                                                                            <div style={{width: '3px', backgroundColor: '#DDDDDD', height: '100%'}}></div>
                                                                        </div>
                                                                        <div style={{ minWidth: '250px'}}>
                                                                            <h5 style={ { margin: '5px 0' } }>{e.position}</h5>
                                                                            <h6 style={{margin: '5px 0', color: '#636363'}}>{e.school}</h6>
                                                                        </div>
                                                                        <div style={ { minWidth: '150px', maxWidth: '250px',fontSize: '16px' } }>{e.start_date} - {e.end_date}</div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </> : null
                                                }
                                                {
                                                    selectedCandidate.educations.length ? <>
                                                        <h4 style={ { margin: '10px 0' } }>EDUCATION</h4>
                                                        {
                                                            selectedCandidate.educations.map(e=>{
                                                                return(
                                                                    <div style={{display: 'flex', gap: '30px', flexWrap: 'wrap' }}>
                                                                        <div  className='deskOnly' style={ { width: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                                            <img src={ GreyDot } style={ { width: '15px', height: '15px' } } />
                                                                            <div style={{width: '3px', backgroundColor: '#DDDDDD', height: '100%'}}></div>
                                                                        </div>
                                                                        <div style={{ minWidth: '250px'}}>
                                                                            <h5 style={ { margin: '5px 0' } }>{e.degree}</h5>
                                                                            <h6 style={{margin: '5px 0', color: '#636363'}}>{e.school}</h6>
                                                                        </div>
                                                                        <div style={ { minWidth: '150px', maxWidth: '250px',fontSize: '16px' } }>{e.start_date} - {e.end_date}</div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </> : null
                                                }
                                                {/* <div style={ { display: 'flex', gap: '30px', flexWrap: 'wrap'  } }>
                                                    <div  className='deskOnly' style={ { width: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                        <img src={ GreyDot } style={ { width: '15px', height: '15px' } } />
                                                        <div style={{width: '3px', backgroundColor: '#DDDDDD', height: '100%'}}></div>
                                                    </div>
                                                    <div style={ { minWidth: '150px', maxWidth: '250px',marginTop: '40px'  } }>
                                                        <h4 style={ { margin: '10px 0' } }>EDUCATION</h4>
                                                        <div style={ { fontSize: '16px' } }>July 2015 – 2018</div>                                                
                                                    </div>
                                                    <div  className='deskOnly' style={ { width: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                    </div>
                                                    <div style={{marginTop: '40px', minWidth: '250px' }}>
                                                        <h5 style={ { margin: '5px 0' } }>High School Teacher—Chemistry</h5>
                                                        <h6 style={{margin: '5px 0', color: '#636363'}}>Louis Pasteur High School, Chicago, IL</h6>
                                                        <h6 style={{margin: '5px 0', color: '#636363'}}>Louis Pasteur High School, Chicago, IL</h6>
                                                    </div>
                                                </div> */}
                                            </div>
                                            {
                                                selectedCandidate.certificates.length ? <>
                                                    <div>
                                                        <h4 style={{margin: '10px 0'}}>Certifications</h4>
                                                        {
                                                            selectedCandidate.certificates.map(e=>{
                                                                return(<>
                                                                    <h5 style={{margin: '5px 0', marginTop: '30px'}}>{e.name}</h5>
                                                                    <h6 style={{margin: '5px 0', color: '#636363'}}>{e.authority}</h6>
                                                                    <h6 style={{margin: '5px 0', color: '#636363'}}>{e.start_date} - {e.end_date}</h6>
                                                                </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </> : null
                                            }

                                            {/* Social Link */}
                                            {
                                                (selectedCandidate.social_link.social_links.linkedin || selectedCandidate.social_link.social_links.twitter || selectedCandidate.social_link.social_links.facebook) ? <>
                                                    <div className="profileCard" style={{borderRadius: '8px', marginTop: '20px'}}>
                                                        <div className="dataCont">
                                                            <div className="dataContLeft">
                                                            Social Link

                                                            </div>
                                                            <div className="dataContRight">
                                                            <div className="dataContRightData" style={{marginTop: '0'}}>
                                                                {/* {profile.about} */}
                                                                {
                                                                selectedCandidate.social_link.social_links.linkedin ? 
                                                                <img src={LinkedIn} style={{cursor : 'pointer'}}  onClick={()=>window.open(`http://${selectedCandidate.social_link.social_links.linkedin}`, "_blank")} /> : null
                                                                }
                                                                {
                                                                selectedCandidate.social_link.social_links.facebook ? 
                                                                <img src={facebook} style={{cursor : 'pointer'}}  onClick={()=>window.open(`http://${selectedCandidate.social_link.social_links.facebook}`, "_blank")} /> : null
                                                                }
                                                                {
                                                                selectedCandidate.social_link.social_links.twitter ? 
                                                                <img src={twitter} style={{cursor : 'pointer'}}  onClick={()=>window.open(`http://${selectedCandidate.social_link.social_links.twitter}`, "_blank")} /> : null
                                                                }
                                                            </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </> : null
                                            }

                                        </Grid>
                                    </Grid>
                                </> : <div className="d-flex justify-content-center col-7 pt-5 mt-5"><h5>{candidates.length ? 'Please Select a Candidate' : ''}</h5></div>
                            }
                        </Grid>
                    {/* </Container> */}
                </Grid>
            </Grid >
            <Modal
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='modalCont'><JobModal closeModal={() => setModal(false)} /></div>
            </Modal>
            <Modal
                open={workExperienceForm}
                onClose={() => {setWorkExperienceForm(false);}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modalCont'><WorkExperienceForm closeModal={() => {setWorkExperienceForm(false);}} /></div>
            </Modal>
            <Footer />
        </>
    )
}

export default SavedJobs;

const JobModal = ( props ) => {

    const scrollRight = () => {
        var carousel = document.getElementById( 'Carousel' )
        carousel.scrollLeft  = carousel.scrollLeft + 500
    }
    const scrollLeft = () => {
        var carousel = document.getElementById( 'Carousel' )
        carousel.scrollLeft  = carousel.scrollLeft - 500
    }

    return (
        <div style={{position: 'relative'}}>
            <div className='ModalHeading'>Learn about the school district’s work culture</div>
            <div className='ModalDescription'>Talk to the employees or ex-employees  and network to understand more about your future job</div>
            <img src={ CloseIcon } alt="" className='modalClose' onClick={ props.closeModal } />
            <div className="chevronCont">
                <img src={LeftChevron} alt="" onClick={scrollLeft}  />
                <img src={RightChevron} alt="" onClick={scrollRight} />
            </div>
            <div className="modalOF" id='Carousel'>
                <div className="carouselCont" >
                    <CarouselCard />
                    <CarouselCard />
                    <CarouselCard />
                    <CarouselCard />
                    <CarouselCard />
                    <CarouselCard />
                </div>
            </div>
        </div>
    )
}

const CarouselCard = () => {
    return (
        <div className='carouselCardCont'>
            <div className="cardProfile">
                <img src="//unsplash.it/80/80" alt="" />
                <div>
                    <div className="profileName">Jenny Wilson</div>
                    <div className="profileDesc">Highschool English Teacher</div>
                    <div className="profileDesc">San Francisco, California</div>
                </div>
            </div>
            <div>BADGES</div>
            <div style={{margin: '20px 0'}}>
                <img src={Badge1} alt="" style={{marginRight: '13px'}} />
                <img src={Badge2} alt="" />
            </div>
            <div>
                <div>WORK EXPERIENCE</div>
                <div style={ { margin: '12px 0' } }><b>English teacher at Summit Public Schools</b></div>
                <div>High School 125 Kent Place Boulevard Summit New Jersey, United States - 07901</div>
                <div>2016 - 2020 (4 year experience)</div>
            </div>
            <div style={ { margin: '20px 0' } }><b>You can message Jenny regarding this school and learn about her experience working there</b></div>
            <button className="cardC2a">
                <img src={ Q } alt="" />
                Ask Questions
            </button>
        </div>
    )
}


const jobTitlesArr = [
  {value: 'Athletic Coach', label: 'Athletic Coach'},
  {value: 'Athletic Coordinator', label: 'Athletic Coordinator'},
  {value: 'Athletic Director', label: 'Athletic Director'},
  {value: 'Secretary to Director of Athletics', label: 'Secretary to Director of Athletics'},
  {value: 'Administrative Assistant To Director Of Facilities', label: 'Administrative Assistant To Director Of Facilities'},
  {value: 'Administrative Assistant To The Safety And Security Director', label: 'Administrative Assistant To The Safety And Security Director'},
  {value: 'Asst. Superintendent-Building And Grounds', label: 'Asst. Superintendent-Building And Grounds'},
  {value: 'Buildings And Grounds Administrative Staff', label: 'Buildings And Grounds Administrative Staff'},
  {value: 'Buildings And Grounds Coordinator', label: 'Buildings And Grounds Coordinator'},
  {value: 'Buildings And Grounds Director', label: 'Buildings And Grounds Director'},
  {value: 'Capital Projects', label: 'Capital Projects'},
  {value: 'Capital Projects Coordinator', label: 'Capital Projects Coordinator'},
  {value: 'Capital Projects Director', label: 'Capital Projects Director'},
  {value: 'Custodian', label: 'Custodian'},
  {value: 'Facilities And Maintenance', label: 'Facilities And Maintenance'},
  {value: 'Facilities And Maintenance Coordinator', label: 'Facilities And Maintenance Coordinator'},
  {value: 'Facilities And Maintenance Director', label: 'Facilities And Maintenance Director'},
  {value: 'Maintenance Secretary', label: 'Maintenance Secretary'},
  {value: 'Planning and Construction', label: 'Planning and Construction'},
  {value: 'Planning and Construction Coordinator', label: 'Planning and Construction Coordinator'},
  {value: 'Planning And Construction Director', label: 'Planning And Construction Director'},
  {value: 'Safety And Security', label: 'Safety And Security'},
  {value: 'Safety And Security Coordinator', label: 'Safety And Security Coordinator'},
  {value: 'Safety And Security Director', label: 'Safety And Security Director'},
  {value: 'Warehouse Coordinator', label: 'Warehouse Coordinator'},
  {value: 'Warehouse Services Director', label: 'Warehouse Services Director'},
  {value: 'Assistant to the Business Administrator', label: 'Assistant to the Business Administrator'},
  {value: 'Assistant To The Business Director', label: 'Assistant To The Business Director'},
  {value: 'Assistant to the Director of Operations', label: 'Assistant to the Director of Operations'},
  {value: 'Asst. Superintendent-Business and Finance', label: 'Asst. Superintendent-Business and Finance'},
  {value: 'Attorney / Legal Services', label: 'Attorney / Legal Services'},
  {value: 'Budget and Finance', label: 'Budget and Finance'},
  {value: 'Business Coordinator', label: 'Business Coordinator'},
  {value: 'Business Director', label: 'Business Director'},
  {value: 'Finance and Accounting', label: 'Finance and Accounting'},
  {value: 'Finance and Accounting Coordinator', label: 'Finance and Accounting Coordinator'},
  {value: 'Finance and Accounting Director', label: 'Finance and Accounting Director'},
  {value: 'Purchasing Administrative Staff', label: 'Purchasing Administrative Staff'},
  {value: 'Purchasing Agent', label: 'Purchasing Agent'},
  {value: 'Purchasing Coordinator', label: 'Purchasing Coordinator'},
  {value: 'Purchasing Director', label: 'Purchasing Director'},
  {value: 'Academic Administrative Assistant', label: 'Academic Administrative Assistant'},
  {value: 'Academic Coordinator', label: 'Academic Coordinator'},
  {value: 'Academic Director', label: 'Academic Director'},
  {value: 'Administrative Assistant - Early Childhood Education', label: 'Administrative Assistant - Early Childhood Education'},
  {value: 'Administrative Assistant - Teaching And Learning', label: 'Administrative Assistant - Teaching And Learning'},
  {value: 'Administrative Assistant Curriculum and Instruction', label: 'Administrative Assistant Curriculum and Instruction'},
  {value: 'Administrative Assistant To The Director Of Instruction / Curriculum', label: 'Administrative Assistant To The Director Of Instruction / Curriculum'},
  {value: 'Adult and Continuing Education', label: 'Adult and Continuing Education'},
  {value: 'Adult and Continuing Education Coordinator', label: 'Adult and Continuing Education Coordinator'},
  {value: 'Adult and Continuing Education Director', label: 'Adult and Continuing Education Director'},
  {value: 'Agricultural Education', label: 'Agricultural Education'},
  {value: 'Agricultural Education Coordinator', label: 'Agricultural Education Coordinator'},
  {value: 'Agricultural Education Director', label: 'Agricultural Education Director'},
  {value: 'Alternative Education', label: 'Alternative Education'},
  {value: 'Alternative Education Coordinator', label: 'Alternative Education Coordinator'},
  {value: 'Alternative Education Director', label: 'Alternative Education Director'},
  {value: 'American Sign Language Coordinator', label: 'American Sign Language Coordinator'},
  {value: 'Assessment and Accountability', label: 'Assessment and Accountability'},
  {value: 'Assessment and Accountability Coordinator', label: 'Assessment and Accountability Coordinator'},
  {value: 'Assessment and Accountability Director', label: 'Assessment and Accountability Director'},
  {value: 'Asst. Superintendent-Elementary Education', label: 'Asst. Superintendent-Elementary Education'},
  {value: 'Asst. Superintendent-Instruction / Curriculum', label: 'Asst. Superintendent-Instruction / Curriculum'},
  {value: 'Asst. Superintendent-Secondary Education', label: 'Asst. Superintendent-Secondary Education'},
  {value: 'Bilingual Education', label: 'Bilingual Education'},
  {value: 'Bilingual Education Coordinator', label: 'Bilingual Education Coordinator'},
  {value: 'Bilingual Education Director', label: 'Bilingual Education Director'},
  {value: 'Career And Technical Education', label: 'Career And Technical Education'},
  {value: 'Career And Technical Education Coordinator', label: 'Career And Technical Education Coordinator'},
  {value: 'Career and Technical Education Curriculum', label: 'Career and Technical Education Curriculum'},
  {value: 'Career And Technical Education Director', label: 'Career And Technical Education Director'},
  {value: 'Character Counselor', label: 'Character Counselor'},
  {value: 'College Readiness', label: 'College Readiness'},
  {value: 'College Readiness Coordinator', label: 'College Readiness Coordinator'},
  {value: 'College Readiness Director', label: 'College Readiness Director'},
  {value: 'Common Core Curriculum', label: 'Common Core Curriculum'},
  {value: 'Common Core Curriculum Coordinator', label: 'Common Core Curriculum Coordinator'},
  {value: 'Common Core Curriculum Director', label: 'Common Core Curriculum Director'},
  {value: 'Curriculum-Elementary', label: 'Curriculum-Elementary'},
  {value: 'Curriculum-Elementary Coordinator', label: 'Curriculum-Elementary Coordinator'},
  {value: 'Curriculum-K-12', label: 'Curriculum-K-12'},
  {value: 'Curriculum-Secondary', label: 'Curriculum-Secondary'},
  {value: 'Curriculum-Secondary Coordinator', label: 'Curriculum-Secondary Coordinator'},
  {value: 'Digital Content & Distance Learning', label: 'Digital Content & Distance Learning'},
  {value: 'Digital Content & Distance Learning Coordinator', label: 'Digital Content & Distance Learning Coordinator'},
  {value: 'Digital Content & Distance Learning Director', label: 'Digital Content & Distance Learning Director'},
  {value: 'District Reading Coordinator', label: 'District Reading Coordinator'},
  {value: 'Early Childhood Education', label: 'Early Childhood Education'},
  {value: 'Early Childhood Education Coordinator', label: 'Early Childhood Education Coordinator'},
  {value: 'Early Childhood Education Curriculum', label: 'Early Childhood Education Curriculum'},
  {value: 'Early Childhood Education Director', label: 'Early Childhood Education Director'},
  {value: 'Education Specialist', label: 'Education Specialist'},
  {value: 'Elementary Education Coordinator', label: 'Elementary Education Coordinator'},
  {value: 'Elementary Education Director', label: 'Elementary Education Director'},
  {value: 'English / Lang Arts', label: 'English / Lang Arts'},
  {value: 'English / Lang Arts Coordinator', label: 'English / Lang Arts Coordinator'},
  {value: 'Fine And Performing Arts', label: 'Fine And Performing Arts'},
  {value: 'Fine And Performing Arts Coordinator', label: 'Fine And Performing Arts Coordinator'},
  {value: 'Fine And Performing Arts Director', label: 'Fine And Performing Arts Director'},
  {value: 'Benefits Coordinator', label: 'Benefits Coordinator'},
  {value: 'Fine Arts Curriculum', label: 'Fine Arts Curriculum'},
  {value: 'Fine Arts Curriculum Coordinator', label: 'Fine Arts Curriculum Coordinator'},
  {value: 'Fine Arts Curriculum Director', label: 'Fine Arts Curriculum Director'},
  {value: 'Foreign Language', label: 'Foreign Language'},
  {value: 'Foreign Languages Curriculum Coordinator', label: 'Foreign Languages Curriculum Coordinator'},
  {value: 'Foreign Languages Curriculum Director', label: 'Foreign Languages Curriculum Director'},
  {value: 'Gifted & Talented Curriculum', label: 'Gifted & Talented Curriculum'},
  {value: 'Gifted and Talented Curriculum Coordinator', label: 'Gifted and Talented Curriculum Coordinator'},
  {value: 'Gifted And Talented Dept Chairperson', label: 'Gifted And Talented Dept Chairperson'},
  {value: 'Head Start', label: 'Head Start'},
  {value: 'Head Start Coordinator', label: 'Head Start Coordinator'},
  {value: 'Head Start Director', label: 'Head Start Director'},
  {value: 'Innovative Programs Curriculum', label: 'Innovative Programs Curriculum'},
  {value: 'Innovative Programs Curriculum Coordinator', label: 'Innovative Programs Curriculum Coordinator'},
  {value: 'Innovative Programs Curriculum Director', label: 'Innovative Programs Curriculum Director'},
  {value: 'Instruction / Curriculum', label: 'Instruction / Curriculum'},
  {value: 'Instruction / Curriculum Coach', label: 'Instruction / Curriculum Coach'},
  {value: 'Instruction / Curriculum Coordinator', label: 'Instruction / Curriculum Coordinator'},
  {value: 'Instruction / Curriculum Director', label: 'Instruction / Curriculum Director'},
  {value: 'K-12 Education Coordinator', label: 'K-12 Education Coordinator'},
  {value: 'K-12 Education Director', label: 'K-12 Education Director'},
  {value: 'Language Arts / English Director, K-12', label: 'Language Arts / English Director, K-12'},
  {value: 'Language Arts / English, K-12', label: 'Language Arts / English, K-12'},
  {value: 'Literacy Curriculum', label: 'Literacy Curriculum'},
  {value: 'Literacy Curriculum Coordinator', label: 'Literacy Curriculum Coordinator'},
  {value: 'Literacy Curriculum Director', label: 'Literacy Curriculum Director'},
  {value: 'Mathematics Director, K-12', label: 'Mathematics Director, K-12'},
  {value: 'Mathematics, K-12', label: 'Mathematics, K-12'},
  {value: 'Mathematics, K-12 Coordinator', label: 'Mathematics, K-12 Coordinator'},
  {value: 'Multicultural Education', label: 'Multicultural Education'},
  {value: 'Multicultural Education Coordinator', label: 'Multicultural Education Coordinator'},
  {value: 'Multicultural Education Director', label: 'Multicultural Education Director'},
  {value: 'Music', label: 'Music'},
  {value: 'Music Department Chairperson', label: 'Music Department Chairperson'},
  {value: 'Music Department Coordinator', label: 'Music Department Coordinator'},
  {value: 'Outdoor Education', label: 'Outdoor Education'},
  {value: 'Outdoor Education Coordinator', label: 'Outdoor Education Coordinator'},
  {value: 'Outdoor Education Director', label: 'Outdoor Education Director'},
  {value: 'Reading', label: 'Reading'},
  {value: 'Reading Intervention Director', label: 'Reading Intervention Director'},
  {value: 'Reading Interventionist', label: 'Reading Interventionist'},
  {value: 'Science Coordinator', label: 'Science Coordinator'},
  {value: 'Science Department', label: 'Science Department'},
  {value: 'Science Department Chairperson', label: 'Science Department Chairperson'},
  {value: 'Science, K-12', label: 'Science, K-12'},
  {value: 'Secondary Education Director', label: 'Secondary Education Director'},
  {value: 'Social Studies', label: 'Social Studies'},
  {value: 'Social Studies Coordinator', label: 'Social Studies Coordinator'},
  {value: 'Social Studies Department Chairperson', label: 'Social Studies Department Chairperson'},
  {value: 'Stem Curriculum', label: 'Stem Curriculum'},
  {value: 'Stem Curriculum Coordinator', label: 'Stem Curriculum Coordinator'},
  {value: 'Stem Director', label: 'Stem Director'},
  {value: 'Teaching And Learning', label: 'Teaching And Learning'},
  {value: 'Teaching And Learning Coordinator', label: 'Teaching And Learning Coordinator'},
  {value: 'Teaching And Learning Director', label: 'Teaching And Learning Director'},
  {value: 'Textbook', label: 'Textbook'},
  {value: 'Textbook Coordinator', label: 'Textbook Coordinator'},
  {value: 'Textbook Director', label: 'Textbook Director'},
  {value: 'Vocational Education', label: 'Vocational Education'},
  {value: 'Vocational Education Coordinator', label: 'Vocational Education Coordinator'},
  {value: 'Vocational Education Director', label: 'Vocational Education Director'},
  {value: 'Asst. Superintendent', label: 'Asst. Superintendent'},
  {value: 'Asst. Superintendent-Administration', label: 'Asst. Superintendent-Administration'},
  {value: 'Asst. Superintendent-Human Resources', label: 'Asst. Superintendent-Human Resources'},
  {value: 'Asst. Superintendent-Student Services', label: 'Asst. Superintendent-Student Services'},
  {value: 'Board Clerk', label: 'Board Clerk'},
  {value: 'Board Member', label: 'Board Member'},
  {value: 'Board President', label: 'Board President'},
  {value: 'Board Secretary', label: 'Board Secretary'},
  {value: 'Board Treasurer', label: 'Board Treasurer'},
  {value: 'Board Vice President', label: 'Board Vice President'},
  {value: 'Chief Academic Officer', label: 'Chief Academic Officer'},
  {value: 'Chief Administrative Officer', label: 'Chief Administrative Officer'},
  {value: 'Chief Educational Officer', label: 'Chief Educational Officer'},
  {value: 'Chief Executive Officer', label: 'Chief Executive Officer'},
  {value: 'Chief Financial Officer', label: 'Chief Financial Officer'},
  {value: 'Chief Of Staff', label: 'Chief Of Staff'},
  {value: 'Chief Operating Officer', label: 'Chief Operating Officer'},
  {value: 'Dean', label: 'Dean'},
  {value: 'Dean Of Students', label: 'Dean Of Students'},
  {value: 'Superintendent', label: 'Superintendent'},
  {value: 'Administrative Assist. To The Board', label: 'Administrative Assist. To The Board'},
  {value: 'Administrative Assist. To The Chief Academic Officer', label: 'Administrative Assist. To The Chief Academic Officer'},
  {value: 'Administrative Assist. To The Chief Executive Officer', label: 'Administrative Assist. To The Chief Executive Officer'},
  {value: 'Administrative Assist. To The Chief Financial Officer', label: 'Administrative Assist. To The Chief Financial Officer'},
  {value: 'Administrative Assist. To The Director Of Professional Development', label: 'Administrative Assist. To The Director Of Professional Development'},
  {value: 'Administrative Employee-Pupil Personnel', label: 'Administrative Employee-Pupil Personnel'},
  {value: 'Admissions', label: 'Admissions'},
  {value: 'Admissions Coordinator', label: 'Admissions Coordinator'},
  {value: 'Admissions Director', label: 'Admissions Director'},
  {value: 'Advancement', label: 'Advancement'},
  {value: 'Advancement Coordinator', label: 'Advancement Coordinator'},
  {value: 'Advancement Director', label: 'Advancement Director'},
  {value: 'Assist. Superintendent-Professional Development', label: 'Assist. Superintendent-Professional Development'},
  {value: 'Asst. Superintendent-Pupil Personnel', label: 'Asst. Superintendent-Pupil Personnel'},
  {value: 'Asst. Superintendents - Operations', label: 'Asst. Superintendents - Operations'},
  {value: 'Attendance', label: 'Attendance'},
  {value: 'Attendance Coordinator', label: 'Attendance Coordinator'},
  {value: 'Attendance Director', label: 'Attendance Director'},
  {value: 'Benefits Administrative Assist.', label: 'Benefits Administrative Assist.'},
  {value: 'Benefits Director', label: 'Benefits Director'},
  {value: 'Charter Schools Coordinator', label: 'Charter Schools Coordinator'},
  {value: 'Development Coordinator', label: 'Development Coordinator'},
  {value: 'Development Director', label: 'Development Director'},
  {value: 'District Administration', label: 'District Administration'},
  {value: 'District Administration Coordinator', label: 'District Administration Coordinator'},
  {value: 'District Administration Director', label: 'District Administration Director'},
  {value: 'District Administrator', label: 'District Administrator'},
  {value: 'District Compliance', label: 'District Compliance'},
  {value: 'District Compliance Coordinator', label: 'District Compliance Coordinator'},
  {value: 'District Compliance Director', label: 'District Compliance Director'},
  {value: 'Executive Assistant To The Superintendent', label: 'Executive Assistant To The Superintendent'},
  {value: 'Food Service / Cafeteria Manager', label: 'Food Service / Cafeteria Manager'},
  {value: 'Food Services', label: 'Food Services'},
  {value: 'Food Services Coordinator', label: 'Food Services Coordinator'},
  {value: 'Food Services Director', label: 'Food Services Director'},
  {value: 'Human Resources', label: 'Human Resources'},
  {value: 'Human Resources Administrative Assistant', label: 'Human Resources Administrative Assistant'},
  {value: 'Human Resources Coordinator', label: 'Human Resources Coordinator'},
  {value: 'Human Resources Director', label: 'Human Resources Director'},
  {value: 'Operations', label: 'Operations'},
  {value: 'Operations Coordinator', label: 'Operations Coordinator'},
  {value: 'Operations Director', label: 'Operations Director'},
  {value: 'Organizational Development', label: 'Organizational Development'},
  {value: 'Organizational Development Coordinator', label: 'Organizational Development Coordinator'},
  {value: 'Organizational Development Director', label: 'Organizational Development Director'},
  {value: 'Paraprofessional / Instructional Aide', label: 'Paraprofessional / Instructional Aide'},
  {value: 'Professional Development', label: 'Professional Development'},
  {value: 'Professional Development Coordinator', label: 'Professional Development Coordinator'},
  {value: 'Professional Development Director', label: 'Professional Development Director'},
  {value: 'Publicity And Communications', label: 'Publicity And Communications'},
  {value: 'Publicity And Communications Coordinator', label: 'Publicity And Communications Coordinator'},
  {value: 'Publicity And Communications Director', label: 'Publicity And Communications Director'},
  {value: 'Pupil Personnel', label: 'Pupil Personnel'},
  {value: 'Pupil Personnel Coordinator', label: 'Pupil Personnel Coordinator'},
  {value: 'Pupil Personnel Director', label: 'Pupil Personnel Director'},
  {value: 'Risk Management', label: 'Risk Management'},
  {value: 'Risk Management Coordinator', label: 'Risk Management Coordinator'},
  {value: 'Risk Management Director', label: 'Risk Management Director'},
  {value: 'School Improvement', label: 'School Improvement'},
  {value: 'School Improvement Coordinator', label: 'School Improvement Coordinator'},
  {value: 'School Improvement Director', label: 'School Improvement Director'},
  {value: 'Student Placement', label: 'Student Placement'},
  {value: 'Student Placement Coordinator', label: 'Student Placement Coordinator'},
  {value: 'Student Placement Director', label: 'Student Placement Director'},
  {value: '21St Cclc Coordinator', label: '21St Cclc Coordinator'},
  {value: '21St Cclc Director', label: '21St Cclc Director'},
  {value: 'Administrative Assistant For Grants / Federal Programs', label: 'Administrative Assistant For Grants / Federal Programs'},
  {value: 'Administrative Assistant Of Federal Programs', label: 'Administrative Assistant Of Federal Programs'},
  {value: 'Administrative Assistant Of Special Services', label: 'Administrative Assistant Of Special Services'},
  {value: 'Administrative Assistant Title I Instructional', label: 'Administrative Assistant Title I Instructional'},
  {value: 'Administrative Assistant Title Iii', label: 'Administrative Assistant Title Iii'},
  {value: 'Asst. Superintendent-Federal Programs', label: 'Asst. Superintendent-Federal Programs'},
  {value: 'Business Partnerships & Grants', label: 'Business Partnerships & Grants'},
  {value: 'Business Partnerships & Grants Director', label: 'Business Partnerships & Grants Director'},
  {value: 'Diagnostician / Testing', label: 'Diagnostician / Testing'},
  {value: 'Diagnostician / Testing Coordinator', label: 'Diagnostician / Testing Coordinator'},
  {value: 'Diagnostician / Testing Director', label: 'Diagnostician / Testing Director'},
  {value: 'Federal And State Programs', label: 'Federal And State Programs'},
  {value: 'Federal And State Programs Director', label: 'Federal And State Programs Director'},
  {value: 'Federal Programs And Grants Coordinator', label: 'Federal Programs And Grants Coordinator'},
  {value: 'Gear Up Coordinator', label: 'Gear Up Coordinator'},
  {value: 'Grants', label: 'Grants'},
  {value: 'Grants And Funded Programs Director', label: 'Grants And Funded Programs Director'},
  {value: 'Grants Specialist', label: 'Grants Specialist'},
  {value: 'Guidance & Counseling Coordinator', label: 'Guidance & Counseling Coordinator'},
  {value: 'Guidance & Counseling Director', label: 'Guidance & Counseling Director'},
  {value: 'Guidance & Counseling, Elementary', label: 'Guidance & Counseling, Elementary'},
  {value: 'Guidance & Counseling, K-12', label: 'Guidance & Counseling, K-12'},
  {value: 'Guidance & Counseling, Secondary', label: 'Guidance & Counseling, Secondary'},
  {value: 'Guidance Counselor', label: 'Guidance Counselor'},
  {value: 'Psychologist', label: 'Psychologist'},
  {value: 'Psychologist Coordinator', label: 'Psychologist Coordinator'},
  {value: 'Race To The Top (Rttt) Coordinator', label: 'Race To The Top (Rttt) Coordinator'},
  {value: 'Race To The Top (Rttt) Director', label: 'Race To The Top (Rttt) Director'},
  {value: 'School Psychology Director', label: 'School Psychology Director'},
  {value: 'Secretary To Psychologist', label: 'Secretary To Psychologist'},
  {value: 'Secretary To Title I', label: 'Secretary To Title I'},
  {value: 'Student Assessment', label: 'Student Assessment'},
  {value: 'Student Assessment Coordinator', label: 'Student Assessment Coordinator'},
  {value: 'Title I Coordinator', label: 'Title I Coordinator'},
  {value: 'Title I Director', label: 'Title I Director'},
  {value: 'Title II Coordinator', label: 'Title II Coordinator'},
  {value: 'Title II Director', label: 'Title II Director'},
  {value: 'Title III Coordinator', label: 'Title III Coordinator'},
  {value: 'Title III Director', label: 'Title III Director'},
  {value: 'Title IV Coordinator', label: 'Title IV Coordinator'},
  {value: 'Title IX Coordinator', label: 'Title IX Coordinator'},
  {value: 'Title IX Director', label: 'Title IX Director'},
  {value: 'Title VI Coordinator', label: 'Title VI Coordinator'},
  {value: 'Title VII Coordinator', label: 'Title VII Coordinator'},
  {value: 'Title VII Director', label: 'Title VII Director'},
  {value: 'Title X Coordinator', label: 'Title X Coordinator'},
  {value: 'Child Nutrition', label: 'Child Nutrition'},
  {value: 'Child Nutrition Coordinator', label: 'Child Nutrition Coordinator'},
  {value: 'Child Nutrition Director', label: 'Child Nutrition Director'},
  {value: 'Health Aide', label: 'Health Aide'},
  {value: 'Health And Physical Education', label: 'Health And Physical Education'},
  {value: 'Health And Physical Education Coordinator', label: 'Health And Physical Education Coordinator'},
  {value: 'Health And Physical Education Director', label: 'Health And Physical Education Director'},
  {value: 'Health Services Coordinator', label: 'Health Services Coordinator'},
  {value: 'Health Services Director', label: 'Health Services Director'},
  {value: 'Health Services Secretary', label: 'Health Services Secretary'},
  {value: 'Health Services Staff', label: 'Health Services Staff'},
  {value: 'Nurse', label: 'Nurse'},
  {value: 'Wellness', label: 'Wellness'},
  {value: 'Wellness Coordinator', label: 'Wellness Coordinator'},
  {value: 'Wellness Director', label: 'Wellness Director'},
  {value: 'Asst. Superintendent-Special Education', label: 'Asst. Superintendent-Special Education'},
  {value: 'Asst. Superintendent-Special Services', label: 'Asst. Superintendent-Special Services'},
  {value: 'Autism', label: 'Autism'},
  {value: 'Autism Coordinator', label: 'Autism Coordinator'},
  {value: 'Autism Director', label: 'Autism Director'},
  {value: 'Disability Services', label: 'Disability Services'},
  {value: 'Disability Services Coordinator', label: 'Disability Services Coordinator'},
  {value: 'Disability Services Director', label: 'Disability Services Director'},
  {value: 'District Rti', label: 'District Rti'},
  {value: 'District Rti Coordinator', label: 'District Rti Coordinator'},
  {value: 'District Rti Director', label: 'District Rti Director'},
  {value: 'Occupational And Physical Therapy', label: 'Occupational And Physical Therapy'},
  {value: 'Occupational And Physical Therapy Coordinator', label: 'Occupational And Physical Therapy Coordinator'},
  {value: 'Occupational And Physical Therapy Director', label: 'Occupational And Physical Therapy Director'},
  {value: 'Occupational Therapist', label: 'Occupational Therapist'},
  {value: 'Physical Therapist', label: 'Physical Therapist'},
  {value: 'Special Education Administrative Assistant', label: 'Special Education Administrative Assistant'},
  {value: 'Special Education Aide', label: 'Special Education Aide'},
  {value: 'Special Education Assistant Director', label: 'Special Education Assistant Director'},
  {value: 'Special Education Coordinator', label: 'Special Education Coordinator'},
  {value: 'Special Education Director', label: 'Special Education Director'},
  {value: 'Special Education Instructor', label: 'Special Education Instructor'},
  {value: 'Speech And Hearing', label: 'Speech And Hearing'},
  {value: 'Speech And Hearing Coordinator', label: 'Speech And Hearing Coordinator'},
  {value: 'Speech And Hearing Director', label: 'Speech And Hearing Director'},
  {value: 'Speech and Language Therapist', label: 'Speech and Language Therapist'},
  {value: 'Speech Pathologist', label: 'Speech Pathologist'},
  {value: 'Speech Pathologist Coordinator', label: 'Speech Pathologist Coordinator'},
  {value: 'Speech Pathology Director', label: 'Speech Pathology Director'},
  {value: 'Speech Therapist', label: 'Speech Therapist'},
  {value: 'Activities Coordinator', label: 'Activities Coordinator'},
  {value: 'Activities Director', label: 'Activities Director'},
  {value: 'Before And After School', label: 'Before And After School'},
  {value: 'Before And After School Coordinator', label: 'Before And After School Coordinator'},
  {value: 'Before And After School Director', label: 'Before And After School Director'},
  {value: 'Coach', label: 'Coach'},
  {value: 'Student Activities Coordinator', label: 'Student Activities Coordinator'},
  {value: 'Student Activities Staff', label: 'Student Activities Staff'},
  {value: '504 Coordinator', label: '504 Coordinator'},
  {value: '504 Director', label: '504 Director'},
  {value: 'Aboriginal / Native Education', label: 'Aboriginal / Native Education'},
  {value: 'Aboriginal / Native Education Coordinator', label: 'Aboriginal / Native Education Coordinator'},
  {value: 'Aboriginal / Native Education Director', label: 'Aboriginal / Native Education Director'},
  {value: 'Administrative Assistant For Student Services', label: 'Administrative Assistant For Student Services'},
  {value: 'Administrative Assistant To The Director Of Aboriginal / Native Education', label: 'Administrative Assistant To The Director Of Aboriginal / Native Education'},
  {value: 'Administrative Assistant To The Director Of Student Services & Records', label: 'Administrative Assistant To The Director Of Student Services & Records'},
  {value: 'At-Risk Coordinator', label: 'At-Risk Coordinator'},
  {value: 'At-Risk Director', label: 'At-Risk Director'},
  {value: 'Attendance And Discipline', label: 'Attendance And Discipline'},
  {value: 'Attendance And Discipline Coordinator', label: 'Attendance And Discipline Coordinator'},
  {value: 'Attendance And Discipline Director', label: 'Attendance And Discipline Director'},
  {value: 'Community Education Coordinator', label: 'Community Education Coordinator'},
  {value: 'Community Education Director', label: 'Community Education Director'},
  {value: 'Community Relations', label: 'Community Relations'},
  {value: 'HIV / AIDS', label: 'HIV / AIDS'},
  {value: 'HIV / AIDS Coordinator', label: 'HIV / AIDS Coordinator'},
  {value: 'Home School', label: 'Home School'},
  {value: 'Home School Coordinator', label: 'Home School Coordinator'},
  {value: 'Home School Director', label: 'Home School Director'},
  {value: 'Homeless And Migrant Education', label: 'Homeless And Migrant Education'},
  {value: 'Homeless And Migrant Education Coordinator', label: 'Homeless And Migrant Education Coordinator'},
  {value: 'Homeless And Migrant Education Director', label: 'Homeless And Migrant Education Director'},
  {value: 'International Education', label: 'International Education'},
  {value: 'International Education Coordinator', label: 'International Education Coordinator'},
  {value: 'International Education Director', label: 'International Education Director'},
  {value: 'Parent And Community Relations', label: 'Parent And Community Relations'},
  {value: 'Parent And Community Relations Director', label: 'Parent And Community Relations Director'},
  {value: 'Parent And Community Resource Director', label: 'Parent And Community Resource Director'},
  {value: 'Parent / Community Resource Coordinator', label: 'Parent / Community Resource Coordinator'},
  {value: 'Positive Behavior Interventions & Supports', label: 'Positive Behavior Interventions & Supports'},
  {value: 'Positive Behavior Interventions & Supports Coordinator', label: 'Positive Behavior Interventions & Supports Coordinator'},
  {value: 'Safe & Drug Free Schools', label: 'Safe & Drug Free Schools'},
  {value: 'Safe & Drug Free Schools Coordinator', label: 'Safe & Drug Free Schools Coordinator'},
  {value: 'Safe & Drug Free Schools Director', label: 'Safe & Drug Free Schools Director'},
  {value: 'School Social Worker', label: 'School Social Worker'},
  {value: 'School Social Worker Coordinator', label: 'School Social Worker Coordinator'},
  {value: 'School Social Worker Director', label: 'School Social Worker Director'},
  {value: 'Student Records', label: 'Student Records'},
  {value: 'Student Records Coordinator', label: 'Student Records Coordinator'},
  {value: 'Student Services & Records', label: 'Student Services & Records'},
  {value: 'Student Services & Records Coordinator', label: 'Student Services & Records Coordinator'},
  {value: 'Student Services & Records Director', label: 'Student Services & Records Director'},
  {value: 'Assistant Superintendent Of Administration And Technology', label: 'Assistant Superintendent Of Administration And Technology'},
  {value: 'Automotive Technology', label: 'Automotive Technology'},
  {value: 'Data Management Coordinator', label: 'Data Management Coordinator'},
  {value: 'Data Management Director', label: 'Data Management Director'},
  {value: 'District Network Administrator', label: 'District Network Administrator'},
  {value: 'Education Management Information System Coordinator', label: 'Education Management Information System Coordinator'},
  {value: 'Information Technology Coordinator', label: 'Information Technology Coordinator'},
  {value: 'Information Technology Director', label: 'Information Technology Director'},
  {value: 'Instructional Technology Coordinator', label: 'Instructional Technology Coordinator'},
  {value: 'Instructional Technology Director', label: 'Instructional Technology Director'},
  {value: 'Librarian', label: 'Librarian'},
  {value: 'Library And Media Services', label: 'Library And Media Services'},
  {value: 'Library And Media Services Coordinator', label: 'Library And Media Services Coordinator'},
  {value: 'Library And Media Services Director', label: 'Library And Media Services Director'},
  {value: 'Secretary To The Information Director Of Technology', label: 'Secretary To The Information Director Of Technology'},
  {value: 'Technology And IT', label: 'Technology And IT'},
  {value: 'Technology Technician', label: 'Technology Technician'},
  {value: 'Administrative Secretary Of Transportation', label: 'Administrative Secretary Of Transportation'},
  {value: 'Bus Driver', label: 'Bus Driver'},
  {value: 'Driver Instructor', label: 'Driver Instructor'},
  {value: 'Transportation Administrator', label: 'Transportation Administrator'},
  {value: 'Transportation Coordinator', label: 'Transportation Coordinator'},
  {value: 'Transportation Director', label: 'Transportation Director'},
  {value: 'Transportation Staff', label: 'Transportation Staff'},
  {value: 'Food service worker', label: 'Food service worker'},
  {value: 'Custodian', label: 'Custodian'},
  {value: 'Teacher', label: 'Teacher'},
  {value: 'Teaching assistant', label: 'Teaching assistant'},
  {value: 'Crossing guard', label: 'Crossing guard'},
  {value: 'Sports coach', label: 'Sports coach'},
  {value: 'School bus driver', label: 'School bus driver'},
  {value: 'Academic adviser', label: 'Academic adviser'},
  {value: 'Groundskeeper', label: 'Groundskeeper'},
  {value: 'Maintenance technician', label: 'Maintenance technician'},
  {value: 'Registrar', label: 'Registrar'},
  {value: 'Academic coordinator', label: 'Academic coordinator'},
  {value: 'Special education teacher', label: 'Special education teacher'},
  {value: 'School nurse', label: 'School nurse'},
  {value: 'Webmaster', label: 'Webmaster'},
  {value: 'Guidance counselor', label: 'Guidance counselor'},
  {value: 'Athletic director', label: 'Athletic director'},
  {value: 'Vice principal', label: 'Vice principal'},
  {value: 'Superintendent', label: 'Superintendent'},
  {value: 'Principal', label: 'Principal'},
]

const Locoptions = [
    { value: 'Alabama', label: 'Alabama' },
    { value: 'Alaska', label: 'Alaska' },
    { value: 'American Samoa', label: 'American Samoa' },
    { value: 'Arizona', label: 'Arizona' },
    { value: 'Arkansas', label: 'Arkansas' },
    { value: 'California', label: 'California' },
    { value: 'Colorado', label: 'Colorado' },
    { value: 'Connecticut', label: 'Connecticut' },
    { value: 'Delaware', label: 'Delaware' },
    { value: 'District Of Columbia', label: 'District Of Columbia' },
    { value: 'Federated States Of Micronesia', label: 'Federated States Of Micronesia' },
    { value: 'Florida', label: 'Florida' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Guam', label: 'Guam' },
    { value: 'Hawaii', label: 'Hawaii' },
    { value: 'Idaho', label: 'Idaho' },
    { value: 'Illinois', label: 'Illinois' },
    { value: 'Indiana', label: 'Indiana' },
    { value: 'Iowa', label: 'Iowa' },
    { value: 'Kansas', label: 'Kansas' },
    { value: 'Kentucky', label: 'Kentucky' },
    { value: 'Louisiana', label: 'Louisiana' },
    { value: 'Maine', label: 'Maine' },
    { value: 'Marshall Islands', label: 'Marshall Islands' },
    { value: 'Maryland', label: 'Maryland' },
    { value: 'Massachusetts', label: 'Massachusetts' },
    { value: 'Michigan', label: 'Michigan' },
    { value: 'Minnesota', label: 'Minnesota' },
    { value: 'Mississippi', label: 'Mississippi' },
    { value: 'Missouri', label: 'Missouri' },
    { value: 'Montana', label: 'Montana' },
    { value: 'Nebraska', label: 'Nebraska' },
    { value: 'Nevada', label: 'Nevada' },
    { value: 'New Hampshire', label: 'New Hampshire' },
    { value: 'New Jersey', label: 'New Jersey' },
    { value: 'New Mexico', label: 'New Mexico' },
    { value: 'New York', label: 'New York' },
    { value: 'North Carolina', label: 'North Carolina' },
    { value: 'North Dakota', label: 'North Dakota' },
    { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
    { value: 'Ohio', label: 'Ohio' },
    { value: 'Oklahoma', label: 'Oklahoma' },
    { value: 'Oregon', label: 'Oregon' },
    { value: 'Palau', label: 'Palau' },
    { value: 'Pennsylvania', label: 'Pennsylvania' },
    { value: 'Puerto Rico', label: 'Puerto Rico' },
    { value: 'Rhode Island', label: 'Rhode Island' },
    { value: 'South Carolina', label: 'South Carolina' },
    { value: 'South Dakota', label: 'South Dakota' },
    { value: 'Tennessee', label: 'Tennessee' },
    { value: 'Texas', label: 'Texas' },
    { value: 'Utah', label: 'Utah' },
    { value: 'Vermont', label: 'Vermont' },
    { value: 'Virgin Islands', label: 'Virgin Islands' },
    { value: 'Virginia', label: 'Virginia' },
    { value: 'Washington', label: 'Washington' },
    { value: 'West Virginia', label: 'West Virginia' },
    { value: 'Wisconsin', label: 'Wisconsin' },
    { value: 'Wyoming', label: 'Wyoming' },
];

const styles = {
        mainBGportrait: {
            background: `url(${bgimg}), #c5c8cc`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            minHeight: '80vw',
            paddingTop: '50vw',
            paddingLeft: '5vw',
            paddingRight: '5vw',
            paddingBottom: '3vw',
        },
        mainBGlandscape: {
            background: `url(${bgimg}), #c5c8cc`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            minHeight: '80vh',
            paddingTop: '50vh',
            paddingLeft: '10vh',
            paddingRight: '10vh',
            paddingBottom: '3vh',
        },
        cardstyle: {
            background: '#fff',
            borderRadius: '12px',
            padding: '3vh',
        },
        searchBoxBig: {
            fontSize: '14px',
            fontFamily: 'Montserrat',
            fontWeight: '600',
            background: '#fff',
            border: '1px solid #BEBEBE',
            borderRadius: '25px',
            paddingLeft: '2vh',
            paddingRight: '2vh',
            paddingTop: '4px',
            paddingBottom: '4px',
        },
        searchBoxSmall: {
            fontSize: '14px',
            fontFamily: 'Montserrat',
            fontWeight: '600',
            background: '#fff',
            border: '1px solid #BEBEBE',
            borderRadius: '25px',
            paddingLeft: '1em',
            paddingRight: '1em',
            paddingTop: '1px',
            paddingBottom: '1px',
            width: '100%'
        },
        srchField: {
            border: "none",
            borderColor: "transparent",
            outline: "none",
            width: "100%",
        },
        hiddenLabel: {
            height: 0,
            visibility: "hidden",
        },
};
