import Cookies from "js-cookie";
import axios from 'axios';
import baseURL from '../axios';

const handleLogout = () => {
    const refreshToken = localStorage.getItem('refresh_token');
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
    Cookies.remove('user');

    localStorage.clear();

    window.location.href = '/';

    axios.post(`${baseURL}/auth/logout/blacklist/`, {
        "refresh_token" : refreshToken
    })
}

export default handleLogout;