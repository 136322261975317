import { Container, Switch } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import NavBarSrch from '../NavBar_withSearch'
import NavBar from '../NavBar'
import NavBarEmpSrch from '../NavBar_emp_searchBar'
import NavBarEmp from '../NavBar_emp'
import { SearchBar } from '../emp_settings'
import Dot3 from './3dot.png'
import Send from './send.png'
import Check from './Frame 5924.png'
import axios from 'axios'
import Cookies from 'js-cookie'
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Loader from '../loader/loader';
import axiosInstance from '../../axios'
import moment from 'moment';

const MessageMsg = () => {
  const [activeMessageProf, setActiveMessageProf] = useState(null)
  const [priMessages, setPriMessages] = useState([])

  const [accessToken, setaccessToken] = useState('')
  useEffect(() => {
    if (Cookies.get('access_token')) {
      setaccessToken(Cookies.get('access_token'))
    } else {
      window.location.href = '/register'
    }
  }, [])

  const [socketUrl, setSocketUrl] = useState(null)
  const [messageHistory, setMessageHistory] = useState([])

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl)
  useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastMessage))
      setPriMessages((prev) => prev.concat(JSON.parse(lastMessage.data)))
    }
  }, [lastMessage, setMessageHistory])

  useEffect(() => {
    if (activeMessageProf !== null) {
      getPrevMessages()
      setSocketUrl(
        `wss://framechange--backend.herokuapp.com/ws/chat/${
          window.location.pathname.split('/')[3]
        }/?access=${accessToken}`
      )
    }
  }, [accessToken, activeMessageProf])

  const [curMsg, setCurMsg] = useState('')

  const submitFormOnEnter = (e) => {
      if(e.keyCode === 13){
          document.getElementById('sendMessageBtn').click();
      }
      return;
  }

  const handleClickSendMessage = () => {
    if(curMsg.length <= 0)
      return;
    sendMessage(
      JSON.stringify({
        message: curMsg,
        room_id: activeMessageProf.room,
        sender: accessToken,
        sender_type: localStorage.getItem('user') === 'JobSeeker' ? '1' : '0',
      })
    )
    setCurMsg('')
  }

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState]

  const getPrevMessages = () => {
    setloader(true);
    axiosInstance
      .get(
        `/messaging/view_messages?room_id=${
          window.location.pathname.split('/')[3]
        }`
      )
      .then((res) => {
        setPriMessages(res.data)
        setloader(false);
      })
      .then(() =>
        document
          .getElementsByClassName('messageCont')[0]
          .scrollTo(
            0,
            document.getElementsByClassName('messageCont')[0].scrollHeight
          )
      )
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    document
      .getElementsByClassName('messageCont')[0]
      .scrollTo(
        0,
        document.getElementsByClassName('messageCont')[0].scrollHeight
      )
  }, [priMessages])

  useEffect(() => {
    // console.log('message', messageHistory)
    // console.log('priMessages', priMessages)
  }, [messageHistory, priMessages])

  useEffect(() => {
      getAllMsgs()
  }, [accessToken])

  const getAllMsgs = () => {
    axiosInstance
      .get(
        `/messaging/chat_screen`,
        {
          params : {
            is_seekers : window.location.pathname.split('/')[4],
            job_id : ''
          }
        }
      )
      .then((res) => {
        setActiveMessageProf(
          res.data.participants.filter(
            (x) => x.room === parseInt(window.location.pathname.split('/')[3])
            )[0]
            )
            console.log(res.data)
       
      })
      .catch((err) => console.log(err))
  }

  const [loader, setloader] = useState(false);
  function convertUTCDateToLocalDate(date) {

    var local = moment.utc(date).local().format('LLL');
    // console.log(local, "- UTC now to local"); 
    return local
  }

  return (
    <div>
      {Cookies.get('user') === 'JobSeeker' ? (
        window.innerWidth > 960 ? (
          <NavBarSrch />
        ) : (
          <NavBar />
        )
      ) : window.innerWidth > 960 ? (
        <NavBarEmpSrch />
      ) : (
        <NavBarEmp />
      )}
      {
        loader ? <Loader /> : null
      }
      <Container style={{ marginTop: '100px' }}>
        <div className='message-container' style={{ display: 'block' }}>
          <div className='rightMessageMsg'>
            <div className='messageRightTop'>
              <MessageProf
                name={activeMessageProf?.user_name}
                profPic={
                  activeMessageProf?.profile_pic === ''
                    ? `https://ui-avatars.com/api/?name=${activeMessageProf?.user_name}?rounded=true`
                    : activeMessageProf?.profile_pic
                }
              />
              <img src={Dot3} alt='' />
            </div>
            <div
              className='messageCont'
              style={{ height: 'calc(100vh - 270px)' }}
            >
              {priMessages.map((e) => {
                return (
                  <MessageComp
                    profImg={
                      e.sender !== activeMessageProf?.user
                        ? `https://ui-avatars.com/api/?name=${e.sender_name}?rounded=true`
                        : activeMessageProf.profile_pic === ''
                        ? `https://ui-avatars.com/api/?name=${e.sender_name}?rounded=true`
                        : activeMessageProf?.profile_pic
                    }
                    time={convertUTCDateToLocalDate(e.sent_at)}
                    name={e.sender_name}
                    left={
                      e.sender_id
                        ? e.sender_id !== activeMessageProf?.user
                          ? false
                          : true
                        : e.sender !== activeMessageProf?.user
                        ? false
                        : true
                    }
                  >
                    {e.message}
                  </MessageComp>
                )
              })}
            </div>
            <div
              className='sendMsgCont'
              style={{ paddingLeft: '0', paddingRight: '0', gap: '5px' }}
            >
              <input
                onKeyDown={submitFormOnEnter}
                type='text'
                placeholder='Type a message...'
                className='sendMsgInpt'
                value={curMsg}
                onChange={(e) => setCurMsg(e.target.value)}
              />
              <button
                id="sendMessageBtn"
                className='sendMsgBtn'
                onClick={handleClickSendMessage}
                disabled={readyState !== ReadyState.OPEN}
              >
                <img src={Send} alt='' width={20} />
              </button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default MessageMsg

const MessageProf = (props) => {
  return (
    <div
      className={`messageProfileCont ${
        props.active ? 'activeMessageProf' : null
      }`}
    >
      <img src={props.profPic} alt='' />
      <div>
        <div className='profName'>{props.name}</div>
        <div className='profLocation'>{props.location}</div>
      </div>
    </div>
  )
}

const MessageComp = (props) => {
  return (
    <div
      className={`messageContMsg ${
        props.left ? 'messageLeft' : 'messageRight'
      }`}
    >
      <img
        src={props.profImg}
        alt=''
        style={{ borderRadius: '50%' }}
        width={30}
        height={30}
      />
      <div className='message'>
        {props.children}
        <div className='timeContMsg'>
          {props.time} {props.checked ? <img src={Check} alt='' /> : null}
        </div>
      </div>
    </div>
  )
}

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  margin: '10px',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#10B981',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))
