import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Logo from './logo.svg';

import './style.css'

function NavBar_noProfile() {

  return (
    <Navbar fixed="top" collapseOnSelect expand="lg" style={{ background: '#F3F7FF' }} variant="light">
      <Container style={{ maxWidth: '100%', margin: '0 20px' }}>
        <Navbar.Brand href="/dashboard"><img src={Logo} alt="logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/dashboard" className="nav-links">Jobs</Nav.Link>
            <Nav.Link href="/" className="nav-links">Teacher Certification Guide</Nav.Link>
            <Nav.Link href="/" className="nav-links">Courses</Nav.Link>
          </Nav>
          <Nav className="ml-auto">
          <Nav.Link href="/employerlp" style={{fontSize:'16px'}} className="nav-links">Hire Teachers</Nav.Link>
            <Nav.Link href="/login" style={{fontSize:'16px'}} className="nav-links">Sign In</Nav.Link>
            <Nav.Link href="/register" style={{fontSize:'16px', color:'white', padding:'15px'}} className="sign-up">Sign Up</Nav.Link>
          </Nav>
          <Nav>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavBar_noProfile;

