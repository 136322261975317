import { Box, ButtonGroup, Card, Checkbox, CircularProgress, Grid, IconButton, Tab, Tabs, TextareaAutosize, TextField, Typography } from '@material-ui/core';
import { GoChevronDown } from "react-icons/go";
import React from 'react';
import Container from 'react-bootstrap/Container';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import postIcon from "./Vector (1).png"
import srch from "./searchicon.svg"
import "react-multi-carousel/lib/styles.css";
import Select from 'react-select';
import NavBar from '../NavBar';
import Footer from '../Footer';
import { CardContent, Button, CardMedia, createTheme, Divider, FormControl, FormControlLabel, FormLabel, Modal, Radio, RadioGroup, Stack, Dialog, DialogContent, DialogActions } from '@mui/material';
import './styles.css';
import PopularService1 from "./popularService(1).png"
import PopularService2 from "./popularService(2).png"
import PopularService3 from "./popularService(3).png"
import PopularService4 from "./popularService(4).png"
import PopularService5 from "./popularService(5).png"
import deleteIcon from "./delete.svg"
import DeleteIcon from "./deleteIcon.svg"
import editIcon from "./edit.svg"
import axiosInstance from '../../axios';
import Checked from '../../images/checked.png'
import PHD from '../Dashboard/phd.svg'
import Alert from "../alerts/alert"
import Carousel from 'react-multi-carousel';
import { ProfileTags2 } from '../All-requests/AllRequests';
import { HiOutlinePhone } from "react-icons/hi";
import { HiOutlineMail } from "react-icons/hi";
import { IoPeopleOutline } from "react-icons/io5";


// get window dimension 

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}


const styles = {
  searchBoxBig: {
    fontSize: '14px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    background: '#fff',
    border: '1px solid #BEBEBE',
    borderRadius: '25px',
    paddingLeft: '2vh',
    paddingRight: '2vh',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  searchBoxSmall: {
    fontSize: '14px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    background: '#fff',
    border: '1px solid #BEBEBE',
    borderRadius: '25px',
    paddingLeft: '1em',
    paddingRight: '1em',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  srchField: {
    border: "none",
    borderColor: "transparent",
    outline: "none",
    width: "100%",
  },
  hiddenLabel: {
    height: 0,
    visibility: "hidden",
  },
};



// responsive design 
const responsiveHotJobs = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1.8
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1.6
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,

  }
};



// job opportunity modal 

const OpportunityForm2 = (props) => {
  const { height, width } = useWindowDimensions()
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [radioValue, setRadioValue] = useState(false)
  const [compensation, setCompensation] = useState('');
  const [opportunityPreference, setopportunityPreference] = useState({
    writing: false,
    speaking_on_clubhouse: false,
    mentoring: false,
    content_creation: false,
  })

  //   const save = (data) => {
  //   console.log(data);
  // };

  const myTheme = createTheme({
    // Set up your custom MUI theme here
  })

  const createOpportunity = () => {

    const opportunityData = {
      "title": title,
      "description": description,
      "compensation": compensation,
      "writing": opportunityPreference.writing ? "1" : "0",
      "speaking_on_clubhouse": opportunityPreference.speaking_on_clubhouse ? "1" : "0",
      "mentoring": opportunityPreference.mentoring ? "1" : "0",
      "content_creation": opportunityPreference.content_creation ? "1" : "0"

    }

    axiosInstance.post('/opportunities/posts/', opportunityData)
      .then(res => {
        props.getApportunityPosts();
        props.closeModal();
        props.setAlert(true)
        props.setType("success")
        props.setDesc("Successfully created opportunity")
      }
      )
      .catch(err => console.log(err))

    // props.closeModal()
  }

  return (
    <div>
      <div
        style={{
          marginLeft: '2.5em',
          marginRight: '2.5em',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div>
          <Typography
            style={{ fontSize: '19px', fontWeight: '700', margin: '0 ' }}
          >
            Post an Opportunity
          </Typography>

          <Typography
            className='perferencestyle'
            style={
              width < 420
                ? { fontSize: '14px', color: '#6C6866', margin: '10px 0' }
                : { fontSize: '14px', color: '#6C6866', margin: '10px 0' }
            }
          >
            I am open to receiving requests.
            This will show your contact button on your profile.
          </Typography>
        </div>
        <div >
          <Divider style={{ marginTop: "15px ", marginBottom: "20px" }} />
        </div>
        <div>
          <div
            style={{ marginBottom: '24px' }}
          >
            <Typography
              style={{ fontSize: '17px', fontWeight: '700', }}
            >
              Title
            </Typography>
            <Typography
              variant='p'
              style={{ marginBottom: "24px" }}
            >
              Write a title for the opportunity.
            </Typography>
          </div>
          <TextField
            size='small'
            name='degree'
            placeholder='ex: Bachelors'
            variant='outlined'
            // style={{width:'100%', marginTop:'10px'}} 
            className="descInput"
            // value={aboutmeText}
            onChange={(e) => {
              setTitle(e.target.value);
              // checkCharacterLimit(e.target.value);
            }}
            inputProps={{ maxLength: 500 }}
          />
          <div
            style={{ marginTop: '30px' }}
          > <Typography
            style={{ fontSize: '17px', fontWeight: '700', }}
          >
              Description
            </Typography>
            <Typography
              variant='p'
              style={{ marginBottom: "24px" }}
            >
              Write a detailed description about this opportunity.
            </Typography>
          </div>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={6}
            // style={{ width: 200 }}
            className="descInput"
            onChange={(e) => {
              setDescription(e.target.value);
              // checkCharacterLimit(e.target.value);
            }}
          />
        </div>
        <div style={{ marginBottom: "24px" }}>
          <Typography
            style={{ fontSize: '17px', fontWeight: '700', margin: '0 ' }}
          >
            Compensataion
          </Typography>
          <Typography
            variant='p'
            style={{ marginBottom: "24px" }}
          >
            Is it a paid opportunity?
          </Typography>

          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexWrap: "wrap" }}>
            <FormControl>
              {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel value="unpaid" onChange={() => {
                  setRadioValue(false)
                  setCompensation({
                    amount: '',
                    hourly: ''
                  })
                }} style={{ marginRight: "100px" }} control={<Radio />} label="Unpaid" />
                <FormControlLabel value="paid" onChange={() => {
                  setRadioValue(true)
                }} control={<Radio />} label="Paid" />

              </RadioGroup>
            </FormControl>
            {
              radioValue &&
              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} className="priceInput">
                <TextField
                  size='small'
                  name='degree'
                  placeholder='Enter amount'
                  variant='outlined'
                  style={{ display: "block", marginBottom: "-5px", padding: "0 6px" }}
                  // className="descInput"
                  // value={aboutmeText}
                  onChange={(e) => {
                    setCompensation(e.target.value);
                    // checkCharacterLimit(e.target.value);
                  }}
                  inputProps={{ maxLength: 500 }}
                />
                <select name="salary_filter" id="salary_filter" style={{ display: "block", padding: "13px 10px" }}
                //  value={filtersApplied.salary_filter} onChange={(e) => {
                //   setfiltersApplied({ ...filtersApplied, salary_filter: e.target.value })}}
                // onChange={(e) => {
                //   setCompensation({
                //     ...compensation,
                //     hourly: e.target.value,        
                //   });
                // checkCharacterLimit(e.target.value);
                // }}
                >
                  <option value="" selected disabled >
                    Hourly Salary
                  </option>
                  <option value="0-19">$ ($0-$19)</option>
                  <option value="20-39">$$ ($20-$39)</option>
                  <option value="40-59">$$$ ($40-$59)</option>
                  <option value="60-79">$$$$ ($60-$79)</option>
                  <option value="80">$$$$$ ({'>'}$79)</option>
                </select>
              </div>
            }

          </div>

        </div>
        <br />
        {/* <div style={{height: '150px'}}>
            <ThemeProvider theme={myTheme}>
              <MUIRichTextEditor
                label="Type something here..."
                onSave={setTextEditorData}
                inlineToolbar={true}
            controls={ [
                  "title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo"
            ] }
              />
            </ThemeProvider>
          </div> */}
        <div style={{ marginBottom: "24px" }}>
          <Typography
            style={{ fontSize: '17px', fontWeight: '700', margin: '0 ' }}
          >
            Cateagory
          </Typography>
          <Typography
            variant='p'
            style={{ marginBottom: "24px" }}
          >
            Select category this opportunity fits in.
          </Typography>
        </div>
        <div className='optionCont2'>
          <div className='option2'>
            <input
              type='checkbox'
              name=''
              checked={opportunityPreference.writing}
              onChange={(e) =>
                setopportunityPreference({
                  ...opportunityPreference,
                  writing: !opportunityPreference.writing,
                })
              }
              id=''
            />
            <div>
              <div className='optionTitle'>Writing</div>
              <div className='optionSubtext'>
                Contributing articles or blogs on certain topics
              </div>
            </div>
          </div>
          <div className='option2'>
            <input
              type='checkbox'
              checked={opportunityPreference.speaking_on_clubhouse}
              onChange={(e) =>
                setopportunityPreference({
                  ...opportunityPreference,
                  speaking_on_clubhouse:
                    !opportunityPreference.speaking_on_clubhouse,
                })
              }
              name=''
              id=''
            />
            <div>
              <div className='optionTitle'>Speaking on ClubHouse</div>
              <div className='optionSubtext'>
                Contributing articles or blogs on certain topics
              </div>
            </div>
          </div>
          <div className='option2'>
            <input
              type='checkbox'
              checked={opportunityPreference.mentoring}
              onChange={(e) =>
                setopportunityPreference({
                  ...opportunityPreference,
                  mentoring: !opportunityPreference.mentoring,
                })
              }
              name=''
              id=''
            />
            <div>
              <div className='optionTitle'>Mentoring</div>
              <div className='optionSubtext'>
                Contributing articles or blogs on certain topics
              </div>
            </div>
          </div>
          <div className='option2'>
            <input
              type='checkbox'
              name=''
              id=''
              checked={opportunityPreference.content_creation}
              onChange={(e) =>
                setopportunityPreference({
                  ...opportunityPreference,
                  content_creation: !opportunityPreference.content_creation,
                })
              }
            />
            <div >
              <div className='optionTitle'>Content Creation</div>
              <div className='optionSubtext'>
                Contributing articles or blogs on certain topics
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='btnProfileModalCont'>
        <button className='btnContCancelBtn' onClick={props.closeModal}>
          Cancel
        </button>
        <button
          className='btnContSaveBtn'
          onClick={createOpportunity}
        >
          Save
        </button>
      </div>
    </div>
  )
}

// small search bar 

const SearchBarSmall = () => {

  let history = useHistory();
  const submitQuery = () => {
    // let history = useHistory();

    const jobTitle = document.getElementById('jobTitleIDsm');
    const location = document.getElementById('locationIDsm');

    // console.log(jobTitle.value, location.value);

    localStorage.setItem('candidateKeyword', jobTitle.value);
    localStorage.setItem('candidateLocation', location.value);

    // history.push('/search-candidate');

  }

  return (
    <Container>
      <div className='navbarSearch' style={{
        // padding: '0em 1em',
        padding: "0",
        maxWidth: '900px'
      }}>
        <Grid container direction='row'
        //  spacing={1}
        >
          <Grid item xs={12}>
            <Grid container direction='column'
            // spacing={1}
            >
              {/* dfd */}



              {/* dfd */}
              <Grid item xs={12}>
                <div className="searchBoxSmall" style={styles.searchBoxSmall}>
                  <Grid container direction='row'
                  // spacing={1}
                  >
                    <Grid item xs={'auto'}>
                      <img src={srch} alt="Search by Title" />
                    </Grid>
                    <Grid item xs={'auto'}>
                      <input type="text" id='jobTitleIDsm' placeholder="Search for small gigs" style={styles.srchField} />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* <Grid item xs={12}>
                <div className="searchBoxSmall" style={styles.searchBoxSmall}>
                  <Grid container direction='row' spacing={1}>
                    <Grid item xs={'auto'}>
                      <img src={pin} alt="Search by Location" />
                    </Grid>
                    <Grid item xs={'auto'}>
                      <input type="text" id='locationIDsm' placeholder="Location or Zip Code" style={styles.srchField} />
                    </Grid>
                  </Grid>
                </div>
              </Grid> */}
              {/* <Grid item xs={12}>
                <Button id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', color: '#FFF', fontWeight: '600', borderRadius: '25px', padding: '10px', minWidth: '100%' }} variant="contained" >Search</Button>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}


// popular services data 

const PopularServices = [
  {
    image: PopularService1,
    title: "Writing Coach",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  },
  {
    image: PopularService2,
    title: "Curriculum Developer",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  },
  {
    image: PopularService3,
    title: "Teaching Materials Provider",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  },
  {
    image: PopularService4,
    title: "Test Scorer",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  },
  {
    image: PopularService5,
    title: "Educational Writer",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  },
]

// const calculateDayLeft = (expiryDate) => {
//   const curr = new Date();
//   const exp = new Date(expiryDate);
//   const oneDay = 24 * 60 * 60 * 1000;

//   if (curr > exp)
//     return false;

//   const diffDays = Math.round(Math.abs((exp - curr) / oneDay));
//   console.log("days different : ", diffDays)

//   return diffDays;
// }

const calculateDayDifference = (expiryDate) => {
  const curr = new Date();
  const exp = new Date(expiryDate);
  const oneDay = 24 * 60 * 60 * 1000;

  // const diffDays = Math.round(Math.abs((exp - curr) / oneDay));
  const diffDays2 = Math.abs((exp - curr) / oneDay);
  if (diffDays2 >= 1) {
    let days = Math.floor(diffDays2);
    return (`${days} days ago`);
  }
  else if (diffDays2 < 1) {
    let hours = diffDays2 * 24;
    if (hours >= 1) {
      let hs = Math.floor(hours);
      if (hs > 1) {
        return (`${hs} hours ago`);
      }
      else {
        return (`${hs} hour ago`);
      }

    }
    else {
      let mins = Math.floor(hours * 60);
      return (`${mins} mins ago`);
    }

  }


}

// opportunities data 



const Opportunities = (props) => {
  const { opportunity, btnState, setBtnState } = props
  const [alertComp, setAlert] = React.useState(false)
  const [typeOfAlert, setType] = React.useState('')
  const [descOfAlert, setDesc] = React.useState('');
  const [loading, setLoading]=useState(false)


  const sendRequest = (id) => {
    // e.preventDefault() 
    setLoading(true)
    console.log(id);
    const sendReq = {
      "receiver": id

    }
    axiosInstance.post('/opportunities/send_request/', sendReq)
      .then(res => {
        setLoading(false)
        setAlert(true)
        setType('success')
        setDesc('Successfully Updated')
        console.log(res);

      }
      )
      .catch(err => {
        setLoading(false)
        console.log(err)
        setAlert(true)
        setType('error')
        setDesc(err.toString())

      })

  }



  return (
    <div>
      {/* alert  */}
      {alertComp ? (
        <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlert} />
      ) : null}

      <div
        style={{
          boxSizing: "border-box", backgroundColor: "white", borderRadius: "10px", padding: "35p 25px",
          minHeight: "380px",
        }}
        className="post-card"

      //  
      //  key={index}
      >

        <div style={{ display: "flex", boxSizing: "border-box", justifyContent: "space-between", alignItems: "center", margin: "0 10px", marginBottom: "15px", padding: "12px 15px" }}>

          <div style={{
            display: "flex",
            // justifyContent:"space-between",
            alignItems: "center",
          }}>

            <img src={opportunity.seeker_details.profile_pic == null ? `https://ui-avatars.com/api/?name=${opportunity.full_name}?rounded=true` : opportunity.seeker_details.profile_pic} alt="connection" style={{ width: "3.2rem", height: "auto", marginRight: " 10px", borderRadius: "50%" }} />
            <div>
              <Typography variant="subtitle1"><h6 style={{ marginBottom: "0px", fontWeight: "600" }} className='personName'>{opportunity.full_name}</h6></Typography>
              <Typography variant="subtitle2"><p style={{ marginBottom: "0px" }}>
                {calculateDayDifference(opportunity.created_at)}
              </p></Typography>
            </div>
          </div>


          <div style={{ position: "relative" }}>

            <button onClick={() => {

              props.setId(opportunity.id) 
              setBtnState(!btnState)

            }}
            

              className='connectBtn' style={{ border: "1px solid #D4E7F3 ", backgroundColor: "white", color: "blue", padding: "5px 10px", borderRadius: "25px" }}>
              {loading ? <div className='d-flex justify-content-center '>
            <CircularProgress style={{ color: 'green', justifyContent: 'center', height: "25px", width: "25px" }} />
          </div>
              :
              <>Connect <GoChevronDown/></> }
              </button>
            {
              (btnState && props.id == opportunity.id) &&
              <div style={{ position: "absolute", top: "120%", right: "0%", width: "137px", backgroundColor: "white", padding: "17px", zIndex: "9", border: "1px solid #e4e8f1", borderRadius: "8px" }}>
                <a href="Tel: 123-456-7890" style={{ marginBottom: "16px", cursor: "pointer", }} className="connectOption"><HiOutlinePhone /><span style={{ marginLeft: "10px", fontWeight: "600" }}>Phone </span></a>
                <a href="mailto:example@gmail.com" style={{ marginBottom: "16px", cursor: "pointer", }} className="connectOption"><HiOutlineMail /><span style={{ marginLeft: "10px", fontWeight: "600" }}>Email</span> </a>  
                <p onClick={() => sendRequest(opportunity.user)} style={{ marginBottom: "0px", cursor: "pointer", }} className="connectOption"><IoPeopleOutline /><span style={{ marginLeft: "10px", fontWeight: "600" }}>Connect</span> </p> 
              </div>
            }
          </div>

        </div>

        <div style={{ minHeight: "50px", padding: "0 25px" }}>

          <Grid
            container
            direction="row"
            style={{ marginTop: "20px" }}
            // justifyContent="space-between"
            alignItems="center"
            // wrap="nowrap"
            spacing={1}
            overflow="hidden"

          >
            <div
              className='profileTagContainer'
            >
              {
                opportunity.seeker_details.is_education && <ProfileTags img={PHD} text='Education' />
              }
              {
                opportunity.seeker_details.is_phd && <ProfileTags img={PHD} text='PHD' />
              }
              {
                opportunity.seeker_details.is_work_exp && <ProfileTags img={Checked} text='Work Experience' />
              }


            </div>


          </Grid>
        </div>

        <Divider style={{ margin: "20px 25px", marginTop: "15px" }} />


        <div style={{ padding: "0 25px", minHeight: "140px" }}>
          <h6 style={{ fontWeight: "bold" }}>{opportunity.title}</h6>
          <p>{
            opportunity.description.substring(0, 110) + "..."
          }
            {/* <Button>view more</Button> */}
          </p>
        </div>


        <div style={{ padding: "0 25px", paddingBottom: "20px" }}>

          <Grid
            container
            direction="row"
            // justifyContent="space-between"
            alignItems="center"
            // wrap="nowrap"
            style={{}}
            spacing={1}

          >
            <div
              className='profileTagContainer'
            >
              {
                opportunity.content_creation &&
                <ProfileTags2 text='Content writing' />
              }
              {
                opportunity.speaking_on_clubhouse &&
                <ProfileTags2 text='Academic writing' />
              }
              {
                opportunity.writing &&
                <ProfileTags2 text='Writing' />
              }


            </div>
          </Grid>
        </div>
      </div>
    </div>
  )
}

// for post 
const OpportunityForm = (props) => {
  const { height, width } = useWindowDimensions()
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [radioValue, setRadioValue] = useState(false)
  const [compensation, setCompensation] = useState('');
  // const [checking, setChecking] = useState(false)
  const [opportunityPreference, setopportunityPreference] = useState({
    writing: false,
    speaking_on_clubhouse: false,
    mentoring: false,
    content_creation: false,
  })

  useEffect(() => {
    setTitle(props.postData.title)
  }, [])

  useEffect(() => {
    setDescription(props.postData.description)
  }, [])

  useEffect(() => {
    if (props.postData.compensation) {
      setCompensation(props.postData.compensation)
      // setChecking(true)
      setRadioValue(true)

    }
  }, [])
  useEffect(() => {
    // checkedOptions()
    setopportunityPreference({
      writing: props.postData.writing,
      speaking_on_clubhouse: props.postData.speaking_on_clubhouse,
      mentoring: props.postData.mentoring,
      content_creation: props.postData.content_creation,
    })

  }, [])



  const myTheme = createTheme({
    // Set up your custom MUI theme here
  })

  const createOpportunity = () => {
    console.log(props.selectedId);

    const opportunityData = {
      "id": props.postData.id,
      "title": title,
      "description": description,
      "compensation": compensation,
      "writing": opportunityPreference.writing ? "1" : "0",
      "speaking_on_clubhouse": opportunityPreference.speaking_on_clubhouse ? "1" : "0",
      "mentoring": opportunityPreference.mentoring ? "1" : "0",
      "content_creation": opportunityPreference.content_creation ? "1" : "0"

    }

    axiosInstance.patch('/opportunities/posts/', opportunityData)
      .then(res => {
        props.closeModal();
        props.setAlert(true)
        props.setType('success')
        props.setDesc('Successfully Updated')
        props.getApportunityPosts();




      }
      )
      .catch(err => {
        console.log("err is ", err)
        props.setAlert(true)
        props.setType('error')
        props.setDesc(err.toString())

      })


  }

  return (
    <div>
      <div
        style={{
          marginLeft: '2.5em',
          marginRight: '2.5em',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div>
          <Typography
            style={{ fontSize: '19px', fontWeight: '700', margin: '0 ' }}
          >
            Post an Opportunity
          </Typography>

          <Typography
            className='perferencestyle'
            style={
              width < 420
                ? { fontSize: '14px', color: '#6C6866', margin: '10px 0' }
                : { fontSize: '14px', color: '#6C6866', margin: '10px 0' }
            }
          >
            I am open to receiving requests.
            This will show your contact button on your profile.
          </Typography>
        </div>
        <div >
          <Divider style={{ marginTop: "15px ", marginBottom: "20px" }} />
        </div>
        <div>
          <div
            style={{ marginBottom: '24px' }}
          >
            <Typography
              style={{ fontSize: '17px', fontWeight: '700', }}
            >
              Title
            </Typography>
            <Typography
              variant='p'
              style={{ marginBottom: "24px" }}
            >
              Write a title for the opportunity.
            </Typography>
          </div>
          <TextField
            size='small'
            name='degree'
            placeholder='ex: Bachelors'
            variant='outlined'
            // style={{width:'100%', marginTop:'10px'}} 
            className="descInput"
            defaultValue={props.postData.title}
            onChange={(e) => {
              setTitle(e.target.value);
              // checkCharacterLimit(e.target.value);
            }}
            inputProps={{ maxLength: 500 }}
          />
          <div
            style={{ marginTop: '30px' }}
          > <Typography
            style={{ fontSize: '17px', fontWeight: '700', }}
          >
              Description
            </Typography>
            <Typography
              variant='p'
              style={{ marginBottom: "24px" }}
            >
              Write a detailed description about this opportunity.
            </Typography>
          </div>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={6}
            // style={{ width: 200 }}
            className="descInput"
            defaultValue={props.postData.description}
            onChange={(e) => {
              setDescription(e.target.value);
              // checkCharacterLimit(e.target.value);
            }}
          />
        </div>
        <div style={{ marginBottom: "24px" }}>
          <Typography
            style={{ fontSize: '17px', fontWeight: '700', margin: '0 ' }}
          >
            Compensataion
          </Typography>
          <Typography
            variant='p'
            style={{ marginBottom: "24px" }}
          >
            Is it a paid opportunity?
          </Typography>

          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexWrap: "wrap" }}>
            <FormControl>
              {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel value="unpaid" onChange={() => {
                  setRadioValue(false)
                  setCompensation("")
                }} style={{ marginRight: "100px" }} control={<Radio />} label="Unpaid" />
                <FormControlLabel
                  //  checked={true} 
                  checked={
                    compensation ? true : false
                  }
                  value="paid" onChange={() => {
                    setRadioValue(true)
                  }} control={<Radio />} label="Paid" />

              </RadioGroup>
            </FormControl>
            {
              radioValue &&
              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} className="priceInput">
                <TextField
                  size='small'
                  name='degree'
                  placeholder='Enter amount'
                  variant='outlined'
                  style={{ display: "block", marginBottom: "-5px", padding: "0 6px" }}
                  // className="descInput"
                  defaultValue={compensation}
                  onChange={(e) => {
                    setCompensation(e.target.value);
                    // checkCharacterLimit(e.target.value);
                  }}
                  inputProps={{ maxLength: 500 }}
                />
                <select name="salary_filter" id="salary_filter" style={{ display: "block", padding: "13px 10px" }}
                //  value={filtersApplied.salary_filter} onChange={(e) => {
                //   setfiltersApplied({ ...filtersApplied, salary_filter: e.target.value })}}
                // onChange={(e) => {
                //   setCompensation({
                //     ...compensation,
                //     hourly: e.target.value,        
                //   });
                // checkCharacterLimit(e.target.value);
                // }}
                >
                  <option value="" selected disabled >
                    Hourly Salary
                  </option>
                  <option value="0-19">$ ($0-$19)</option>
                  <option value="20-39">$$ ($20-$39)</option>
                  <option value="40-59">$$$ ($40-$59)</option>
                  <option value="60-79">$$$$ ($60-$79)</option>
                  <option value="80">$$$$$ ({'>'}$79)</option>
                </select>
              </div>
            }

          </div>

        </div>
        <br />
        {/* <div style={{height: '150px'}}>
            <ThemeProvider theme={myTheme}>
              <MUIRichTextEditor
                label="Type something here..."
                onSave={setTextEditorData}
                inlineToolbar={true}
            controls={ [
                  "title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo"
            ] }
              />
            </ThemeProvider>
          </div> */}
        <div style={{ marginBottom: "24px" }}>
          <Typography
            style={{ fontSize: '17px', fontWeight: '700', margin: '0 ' }}
          >
            Cateagory
          </Typography>
          <Typography
            variant='p'
            style={{ marginBottom: "24px" }}
          >
            Select category this opportunity fits in.
          </Typography>
        </div>
        <div className='optionCont2'>
          <div className='option2'>
            <input
              type='checkbox'
              name=''
              checked={opportunityPreference.writing}
              onChange={(e) =>
                setopportunityPreference({
                  ...opportunityPreference,
                  writing: !opportunityPreference.writing,
                })
              }
              id=''
            />
            <div>
              <div className='optionTitle'>Writing</div>
              <div className='optionSubtext'>
                Contributing articles or blogs on certain topics
              </div>
            </div>
          </div>
          <div className='option2'>
            <input
              type='checkbox'
              checked={opportunityPreference.speaking_on_clubhouse}
              onChange={(e) =>
                setopportunityPreference({
                  ...opportunityPreference,
                  speaking_on_clubhouse:
                    !opportunityPreference.speaking_on_clubhouse,
                })
              }
              name=''
              id=''
            />
            <div>
              <div className='optionTitle'>Speaking on ClubHouse</div>
              <div className='optionSubtext'>
                Contributing articles or blogs on certain topics
              </div>
            </div>
          </div>
          <div className='option2'>
            <input
              type='checkbox'
              checked={opportunityPreference.mentoring}
              onChange={(e) =>
                setopportunityPreference({
                  ...opportunityPreference,
                  mentoring: !opportunityPreference.mentoring,
                })
              }
              name=''
              id=''
            />
            <div>
              <div className='optionTitle'>Mentoring</div>
              <div className='optionSubtext'>
                Contributing articles or blogs on certain topics
              </div>
            </div>
          </div>
          <div className='option2'>
            <input
              type='checkbox'
              name=''
              id=''
              checked={opportunityPreference.content_creation}
              onChange={(e) =>
                setopportunityPreference({
                  ...opportunityPreference,
                  content_creation: !opportunityPreference.content_creation,
                })
              }
            />
            <div >
              <div className='optionTitle'>Content Creation</div>
              <div className='optionSubtext'>
                Contributing articles or blogs on certain topics
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='btnProfileModalCont'>
        <button className='btnContCancelBtn' onClick={props.closeModal}>
          Cancel
        </button>
        <button
          className='btnContSaveBtn'
          onClick={createOpportunity}
        >
          Save
        </button>
      </div>
    </div>
  )
}

const PostCarousel = (props) => {
  const { responsiveHotJobs, posts, getApportunityPosts } = props;

  const [openDeleteAccount, setOpenDeleteAccount] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const [selectedId, setSelectedId] = React.useState(null);
  // const [loadingState, setLoadingState] = React.useState(false);

  // for alert 
  const [alertComp, setAlert] = React.useState(false)
  const [typeOfAlert, setType] = React.useState('')
  const [descOfAlert, setDesc] = React.useState('');


  const handleClickOpenDeleteAccount = (id) => {
    setSelected(id);
    setOpenDeleteAccount(true);
  };
  const handleCloseDeleteAccount = () => {
    setOpenDeleteAccount(false);
  };

  const handleDeleteButton = () => {

    axiosInstance.delete('/opportunities/posts/',
      {
        data: {
          id: selected
        }
      }
    )
      .then(res => {
        console.log(res)
        getApportunityPosts()
        handleCloseDeleteAccount();
      })
      .then(err => {
        console.log(err);
      })


  }

  const handleEditPost = (postData) => {
    setOpportunityForm(true)
    setSelectedId(postData)
  }

  const [opportunityForm, setOpportunityForm] = useState(false)

  const character = (arr) => {
    let arr2 = arr.split("")
    if (arr2.length > 150) {
      return <>
        {arr.substring(0, 150) + "..."}
      </>
    }
    else {
      return <>
        {arr}
      </>
    }

  }

  return (
    <>
      {alertComp ? (
        <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlert} />
      ) : null}
      <Modal
        open={opportunityForm}
        onClose={() => setOpportunityForm(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='modalCont'>
          <OpportunityForm closeModal={() => setOpportunityForm(false)}
            getApportunityPosts={getApportunityPosts} postData={selectedId} setAlert={setAlert} setType={setType} setDesc={setDesc}
          />

        </div>
      </Modal>

      <div style={{ paddingBottom: "30px" }}>
        {

          posts.length < 1 ?
            <div className='d-flex justify-content-center mt-5'>
              <h6 style={{ fontWeight: "bold" }}>No post available</h6>
            </div>
            :
            <Carousel
              ssr
              partialVisbile
              itemClass="image-item"
              infinite={true}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              // autoPlaySpeed={5000}
              // autoPlay
              responsive={responsiveHotJobs}>

              {

                posts.map(data => {
                  return (

                    <div style={{
                      border: "1px solid #CED1D6", borderRadius: "10px", marginTop: "0px",
                      padding: "16px 20px", minHeight: "206px",
                    }}>
                      <div style={{}}>
                        <div style={{}}>
                          <h5 style={{ fontWeight: "semi-bold", height: "30px", }}>{data.title}</h5>
                          <p style={{ minHeight: "76px" }}>{
                            character(data.description)
                          }

                          </p>
                        </div>
                        <div style={{ width: "100%", textAlign: "end" }}>

                          <Button variant="text" style={{ fontWeight: "bold" }} onClick={() => handleClickOpenDeleteAccount(data.id)}><img src={deleteIcon} alt="" style={{ paddingRight: "7px", paddingBottom: "3px" }} /> Delete</Button>
                          <Button variant="text" style={{ fontWeight: "bold" }} onClick={() => handleEditPost(data)}><img src={editIcon} alt="" style={{ paddingRight: "7px", paddingBottom: "3px" }} /> Edit</Button>


                        </div>


                      </div>
                    </div>



                  )
                })
              }

            </Carousel>

        }



      </div>
      <Dialog
        open={openDeleteAccount}
        onClose={handleCloseDeleteAccount}

      >

        <DialogContent >
          <Grid container justifyContent='row' spacing={1} >
            <Grid item lg={2} md={3} sm={3} xs={3} >
              <img src={DeleteIcon} alt='Delete Icon' />
            </Grid>
            <Grid container direction='column' item lg={10} md={9} sm={9} xs={9} >
              <span style={{ fontWeight: '600', fontSize: '19px' }}>Delete This post</span>

              <span style={{ fontSize: '14px', marginTop: '1em' }}>Are you sure you want to delete this post? if you delete this, it never can be back</span>

            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingBottom: '1.5em' }}>
          <Button style={{ textTransform: 'none', border: '1px solid #808080', borderRadius: '18px', padding: '0.5em 1em', color: '#808080' }} onClick={handleCloseDeleteAccount}>Cancel</Button>
          <Button style={{ textTransform: 'none', background: 'linear-gradient(275.34deg, #D80202 -10.41%, #D70000 -10.4%, #FF4545 102.29%)', borderRadius: '18px', padding: '0.5em 1em', color: '#fff' }} onClick={handleDeleteButton} autoFocus>
            Delete Account
          </Button>
        </DialogActions>
      </Dialog>



    </>
  )
}
const ConnectionCarousel = (props) => {
  const { responsiveHotJobs, pendingRequests, setPendingRequests, loadingState, getApportunityPosts } = props;
  const card = Math.ceil(pendingRequests.length / 3);
  const [acceptLoading, setAcceptLoading] = useState(false)
  const [rejectLoading, setRejectLoading] = useState(false)
  const [idForLoading, setIdForLoading] = useState("")

  // handle accept button 

  const requestAccept = (id) => {
    setAcceptLoading(true)
    setIdForLoading(id)
    const accepted = {
      "request_id": id,
      "is_accepted": "1"
    }
    axiosInstance.post('/opportunities/pending_requests/', accepted)
      .then(res => {
        setPendingRequests(pendingRequests.filter(e => e.request_id != id))
        props.setAlert(true)
        props.setType('success')
        props.setDesc('Successfully Updated')
        setAcceptLoading(false)
        getApportunityPosts()

      }
      )
      .catch(err => {
        console.log(err)
        props.setAlert(true)
        props.setType('error')
        props.setDesc(err.toString())

      })

  }
  const requestReject = (id) => {
    setRejectLoading(true)
    setIdForLoading(id)

    const rejected = {
      "request_id": id,
      "is_accepted": "0"
    }
    axiosInstance.post('/opportunities/pending_requests/', rejected)
      .then(res => {
        setPendingRequests(pendingRequests.filter(e => e.request_id != id))
        props.setAlert(true)
        props.setType('success')
        props.setDesc('Request rejected')
        setRejectLoading(false)
        getApportunityPosts()
        console.log(res);

      }
      )
      .catch(err => {
        console.log(err)
        props.setAlert(true)
        props.setType('error')
        props.setDesc(err.toString())

      })

  }




  return (
    <div style={{ paddingBottom: "30px" }}>

      {loadingState ?
        <div className='d-flex justify-content-center mt-5'>
          <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
        </div>

        :
        pendingRequests.length < 1 ?
          <div className='d-flex justify-content-center mt-5'>
            <h6 style={{ fontWeight: "bold" }}>No request pending</h6>
          </div>
          :

          <Carousel
            ssr
            partialVisbile
            itemClass="image-item"
            infinite={true}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            // autoPlaySpeed={5000}
            // autoPlay
            responsive={responsiveHotJobs}>
            {
              [...Array(card)].map((connection, index) => {
                return (
                  <div key={index} style={{ border: "1px solid #CED1D6", borderRadius: "10px", }}>
                    {/* minHeight:"225px" */}
                    {
                      pendingRequests.slice(index * 3, index * 3 + 3).map((connection, index) => {
                        return (
                          <div key={index}>

                            <div className='request-body' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "0 10px", padding: "8px 15px", flexDirection: "row" }}>

                              <div style={{
                                display: "flex",
                                // justifyContent:"space-between",
                                alignItems: "center",
                              }}>
                                <img src={connection.sender_info.profile_pic == null ? `https://ui-avatars.com/api/?name=${connection.sender_info.full_name}?rounded=true` : connection.sender_info.profile_pic} alt="connection" style={{ width: "50px", height: "50px", margin: "0 10px", borderRadius: "50%" }} />
                                <div>
                                  <Typography variant="subtitle1"><p style={{ fontWeight: "bold", marginBottom: "0" }}>{connection.sender_info.full_name}</p> </Typography>
                                  <Typography variant="subtitle2">{connection.sender_info.location}</Typography>
                                </div>
                              </div>
                              <div className='requestDiv' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Button className='requestButton' id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'white', color: '#0667C3', fontWeight: '600', borderRadius: '25px', padding: '4px 15px', margin: "10px 10px" }} variant="outlined" onClick={() => requestAccept(connection.request_id)} >
                                  {(acceptLoading && connection.request_id == idForLoading) ?
                                    <div className='d-flex justify-content-center '>
                                      <CircularProgress style={{ color: 'green', justifyContent: 'center', height: "25px", width: "25px" }} />
                                    </div>

                                    : "Accept"}
                                </Button>
                                <Button className='requestButton' id='srchButton' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'white', fontWeight: '600', borderRadius: '25px', padding: '4px 15px', margin: "10px 10px" }} variant="outlined" color="error"
                                  onClick={() => requestReject(connection.request_id)} >
                                  {(rejectLoading && connection.request_id == idForLoading) ?
                                    <div className='d-flex justify-content-center '>
                                      <CircularProgress style={{ color: 'green', justifyContent: 'center', height: "25px", width: "25px" }} />
                                    </div> : "Reject"}
                                </Button>

                              </div>

                            </div>
                          </div>
                        )
                      }
                      )
                    }
                  </div>
                )
              })

            }


          </Carousel>



      }

    </div>
  )
}

const OpportunitiesHome = () => {

  // get window dimension 

  const { height, width } = useWindowDimensions();
  const [value, setValue] = useState();
  const [opportunityForm2, setOpportunityForm2] = useState(false)
  const [opportunities, setOpportunities] = useState([])

  // for connection request option 
  const [pendingRequests, setPendingRequests] = useState([]);
  const [requestLoading, setRequestLoading] = useState(true)

  // for alert 
  const [alertComp, setAlert] = React.useState(false)
  const [typeOfAlert, setType] = React.useState('')
  const [descOfAlert, setDesc] = React.useState('');
  const [btnState, setBtnState] = useState(false)
  const [personId, setPersonId] = useState(0)

  useEffect(() => {
    allPendingRequests()

  }, [])

  const allPendingRequests = () => {
    axiosInstance.get('/opportunities/pending_requests')
      .then(res => {
        setPendingRequests(res.data)
        setRequestLoading(false)
        // console.log(res.data);
      })
      .catch(err => {
        console.log(err)
      })

  }

  useEffect(() => {
    getApportunityPosts()

  }, [])

  const getApportunityPosts = () => {
    axiosInstance.get('/opportunities/posts')
      .then(res => {
        setOpportunities(res.data)
        setLoadingState(false)
        console.log(res.data);
      })
      .catch(err => {
        console.log(err)
      })

  }

  let history = useHistory();

  useEffect(() => {

    let urlPath = history.location.pathname;
    let pathName = urlPath.substring(1);
    setValue(pathName)
  }, [history.location.pathname])




  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTimeout(() => { window.scrollTo(0, document.body.scrollTop) }, 50);

  };


  const renderSwitch = (param) => {
    switch (param) {
      case "opportunities":
        return connectionRequests;
      case "my-posts":
        return myPosts;


    }

  }


  // loading 

  const [loadingState, setLoadingState] = React.useState(true)

  // for connection requests ===========================================================

  const handleNavigate = () => {
    window.location.href = '/myConnection'
  }

  const connectionRequests = <>
    <div style={{ margin: "0 auto" }}>

      {/* alert  */}
      {alertComp ? (
        <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlert} />
      ) : null}


      {/* carousel  */}

      <div className='my-own-custom-container' style={{ backgroundColor: "white" }}>
        <ConnectionCarousel responsiveHotJobs={responsiveHotJobs} pendingRequests={pendingRequests} setPendingRequests={setPendingRequests} loadingState={requestLoading} setAlert={setAlert} setType={setType} setDesc={setDesc} getApportunityPosts={getApportunityPosts}></ConnectionCarousel>
      </div>
      <div style={{ textAlign: "right" }}>
        <Button onClick={handleNavigate} style={{ padding: "0", textTransform: "lowercase", textAlign: "right" }}><Typography variant="subtitle1" style={{ fontWeight: "700", }}>View connection requests</Typography> </Button>
      </div>




    </div>
  </>

  // for posts option ===================================================================


  const myPosts = <>
    <div style={{ margin: "0 auto" }}>
      <div className='my-own-custom-container' style={{ backgroundColor: "white" }}>
        {loadingState ?
          <div className='d-flex justify-content-center mt-5'>
            <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
          </div> :
          <PostCarousel responsiveHotJobs={responsiveHotJobs} posts={opportunities} getApportunityPosts={getApportunityPosts} ></PostCarousel>
        }

      </div>
      <div style={{ textAlign: "right" }}>
        <Button onClick={() => window.location.href = '/myPosts'} style={{ padding: "0", textTransform: "lowercase", textAlign: "right" }}><Typography variant="subtitle1" style={{ fontWeight: "700", }}>View all posts</Typography> </Button>
      </div>

    </div>
  </>


  return (
    <div
      onClick={() => {
        if (btnState) {
          setBtnState(!btnState)
        }

      }}
      style={{ paddingTop: "144px", minHeight: "100vh", backgroundColor: "#F5F7FA", boxSizing: "border-box" }}>

      {/* alert  */}
      {alertComp ? (
        <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlert} />
      ) : null}

      {/* job opportunity modal  */}

      <Modal
        open={opportunityForm2}
        onClose={() => setOpportunityForm2(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='modalCont'>
          <OpportunityForm2 closeModal={() => setOpportunityForm2(false)} getApportunityPosts={getApportunityPosts} setAlert={setAlert} setType={setType} setDesc={setDesc} />

        </div>
      </Modal>
      {/* navbar  */}

      {/* <NavBar_emp /> */}
      <NavBar></NavBar>


      <div className='posts-div'>
        <div className='postHome' style={{
          margin: "0 auto", backgroundColor: "white", boxShadow: " 0px 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.11)",
          borderRadius: "8px"
        }}>



          {/* search bar and related  */}

          <div
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            // flexWrap="wrap"
            className='searchBox'
          // style= {width < 961 ? {direction : 'column'} : {direction : 'row'}}


          >
            <div>
              <Typography variant="h3">
                <h1 style={{ paddingBottom: "25px", fontSize: "2rem", fontWeight: "bold", color: "#000000" }}>Your Posts <img style={{ maxHeight: "18px" }} src={postIcon} alt="" /> </h1>
              </Typography>
            </div>

            {/* search box  */}


            <div>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              // wrap='nowrap'
              >

                {width > 960 && <div> <SearchBarSmall /></div>}


                <div className="mobOnlyFilter searchbox2" style={{ gap: '5px', alignItems: 'center', width: '100%' }}>
                  <Grid item xs={12}>
                    <div className="searchBoxSmall searchBoxWithLogo" style={styles.searchBoxSmall}>
                      <Grid container direction='row' rowSpacing={2} style={{ alignItems: 'center', width: '100%', flexWrap: 'nowrap' }}>
                        <Grid item xs={'auto'} style={{ paddingRight: '2px' }}>
                          <img src={srch} alt="Search for small gigs" />
                        </Grid>
                        <Grid item xs={'auto'} style={{ width: '100%', padding: "0 auto" }}>
                          <div style={styles.hiddenLabel} aria-hidden="true">Search for small gigs</div>
                          <Select
                            // isMulti
                            // className=''
                            id='jobTitleID'
                            // options={jobTitlesArr}
                            // onChange={e => setjobTitleSearch(e.value)}
                            className='stateDD'
                            placeholder='Search for small gigs'
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <div style={{ width: '42px', height: '42px', cursor: 'pointer' }}
                  //  onClick={submitQuery}
                  >
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21C42 32.598 32.598 42 21 42C9.40202 42 0 32.598 0 21Z" fill="url(#paint0_linear_3993_30332)" />
                      <path d="M20.3333 25.6667C23.2789 25.6667 25.6667 23.2789 25.6667 20.3333C25.6667 17.3878 23.2789 15 20.3333 15C17.3878 15 15 17.3878 15 20.3333C15 23.2789 17.3878 25.6667 20.3333 25.6667Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M26.9996 26.9991L24.0996 24.0991" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <defs>
                        <linearGradient id="paint0_linear_3993_30332" x1="1.81035" y1="-30.1304" x2="70.7503" y2="-5.34066" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#FFCF20" />
                          <stop offset="0.932292" stop-color="#FD8012" />
                        </linearGradient>
                      </defs>
                    </svg>

                  </div>
                  <div
                    //  onClick={() => setOpenFilter(!openFilter)}
                    style={{ width: '42px', height: '42px', borderRadius: '50%', backgroundColor: '#E9E9E9', padding: '13.6px', display: 'none', cursor: 'pointer' }}>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.50033 8.17826V14.5003C6.50034 14.5855 6.52214 14.6694 6.56368 14.7438C6.60522 14.8183 6.66511 14.8809 6.73766 14.9257C6.81021 14.9705 6.89301 14.996 6.97819 14.9998C7.06337 15.0035 7.1481 14.9855 7.22433 14.9473L10.2243 13.4473C10.3073 13.4057 10.377 13.3419 10.4258 13.2629C10.4745 13.184 10.5003 13.093 10.5003 13.0003V8.17826L14.8873 2.81726C14.929 2.76644 14.9602 2.70791 14.9792 2.64503C14.9982 2.58215 15.0046 2.51613 14.9981 2.45076C14.9916 2.38539 14.9723 2.32194 14.9413 2.26403C14.9103 2.20612 14.8682 2.15489 14.8173 2.11326C14.7665 2.07163 14.708 2.04042 14.6451 2.02141C14.5822 2.00239 14.5162 1.99595 14.4508 2.00245C14.3855 2.00895 14.322 2.02827 14.2641 2.05929C14.2062 2.09031 14.155 2.13244 14.1133 2.18326L9.61333 7.68326C9.54017 7.77269 9.50024 7.88471 9.50033 8.00026V12.6913L7.50033 13.6913V8.00026C7.50043 7.88471 7.4605 7.77269 7.38733 7.68326L3.55533 3.00026H11.5003C11.6329 3.00026 11.7601 2.94758 11.8539 2.85381C11.9477 2.76004 12.0003 2.63287 12.0003 2.50026C12.0003 2.36765 11.9477 2.24047 11.8539 2.1467C11.7601 2.05294 11.6329 2.00026 11.5003 2.00026H2.50033C2.40561 2.00019 2.31282 2.02704 2.23277 2.07766C2.15271 2.12829 2.08868 2.20061 2.04813 2.28621C2.00758 2.37182 1.99218 2.46718 2.00373 2.56119C2.01527 2.65521 2.05328 2.74401 2.11333 2.81726L6.50033 8.17826Z" fill="#4A4A4A" />
                    </svg>
                  </div>
                </div>

                <div>
                  <Button id='srchButton' className='oppoBtn' style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', color: '#FFF', fontWeight: '600', borderRadius: '25px', padding: '10px 21px', minWidth: '100%', }} variant="contained" onClick={() => setOpportunityForm2(true)}>Job Opportunity</Button>
                </div>
              </Grid>

            </div>


          </div>

          {/* tab section  */}


          <div style={{ marginBottom: "61px", overflow: "auto", whiteSpace: "nowrap" }}>
            <Tabs
              variant="scrollable"
              scrollButtons={false}
              allowScrollButtonsMobile
              value={value}
              onChange={handleChange}
              textColor="inherit"
              TabIndicatorProps={{ style: { background: 'orange' } }}
            // initialSelectedIndex={"opportunities"}

            >
              <Tab style={{ textTransform: 'capitalize' }} value="opportunities" label="Connection Requests" />
              <Tab style={{ textTransform: 'capitalize' }} value="my-posts" label="My Posts" />
            </Tabs>
          </div>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            wrap='nowrap'
            style={{
              background: "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0667C3",
              borderRadius: "8px", padding: "20px 18px", color: "#0667C3", marginBottom: "55px"
            }}
            className="tabContent"
          >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
              <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 8C16.21 8 18 6.21 18 4C18 1.79 16.21 0 14 0C11.79 0 10 1.79 10 4C10 6.21 11.79 8 14 8ZM5 6V4C5 3.45 4.55 3 4 3C3.45 3 3 3.45 3 4V6H1C0.45 6 0 6.45 0 7C0 7.55 0.45 8 1 8H3V10C3 10.55 3.45 11 4 11C4.55 11 5 10.55 5 10V8H7C7.55 8 8 7.55 8 7C8 6.45 7.55 6 7 6H5ZM14 10C11.33 10 6 11.34 6 14V15C6 15.55 6.45 16 7 16H21C21.55 16 22 15.55 22 15V14C22 11.34 16.67 10 14 10Z" fill="#0667C3" />
              </svg>
              <p style={{ fontWeight: "bold", paddingLeft: "10px", }}>
                Connect with users to collaborate and work together on a project. It helps you grow your skills :)
              </p>

            </div>
            <div>
              <svg onClick={() => { document.querySelector(".tabContent").style.display = "none" }} width="20" height="20" style={{ cursor: "pointer", marginLeft: "10px" }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C13.91 14.69 13.28 14.69 12.89 14.3L10 11.41L7.11 14.3C6.72 14.69 6.09 14.69 5.7 14.3C5.31 13.91 5.31 13.28 5.7 12.89L8.59 10L5.7 7.11C5.31 6.72 5.31 6.09 5.7 5.7C6.09 5.31 6.72 5.31 7.11 5.7L10 8.59L12.89 5.7C13.28 5.31 13.91 5.31 14.3 5.7C14.69 6.09 14.69 6.72 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z" fill="#2C659B" />
              </svg>
            </div>

          </Grid>

          {renderSwitch(value)}

        </div>



        <section style={{ marginTop: "80px" }}>


          <section style={{ margin: "0 auto" }}>
            <p style={{ margin: "0px 5px" }}>Find small services or collabration opportunity</p>
            <Typography variant="h4" style={{ color: "#000000" }}>
              <h4 style={{ fontWeight: "900", fontSize: "1.5rem", margin: "0 5px", marginBottom: "35px" }}>Popular services for educators </h4>
            </Typography>
            <div
              // container
              // direction="row"
              // justifyContent="space-between"
              // alignItems="center"
              className="card"
            // spacing={2}
            >

              {
                PopularServices.map((service, index) => {
                  return (

                    <Card
                      //   sx={{ maxWidth: 100 }}
                      style={{ borderRadius: "10px" }}


                    >
                      <div className='card-content'>
                        <CardMedia
                          component="img"
                          height="300"
                          image={service.image}
                          alt="green iguana"


                        />
                      </div>
                      <CardContent
                      //   className='card-content'
                      >
                        <Typography gutterBottom variant="h5" component="div">
                          {service.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {service.description}
                        </Typography>
                      </CardContent>

                    </Card>
                  )
                })
              }
            </div>

          </section>
        </section>


        <section className='opportunitySection' style={{ margin: "0 auto", boxSizing: "border-box" }}>


          {/* search section  */}
          <div
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            // flexWrap="wrap"
            className='searchBox'
            // style= {width < 961 ? {direction : 'column'} : {direction : 'row'}}
            style={{ marginBottom: "40px", marginTop: "80px" }}


          >
            <div>

              <h2
                style={{ fontSize: "2rem", fontWeight: "bold", }} className="opportunity-headText"
              >Opportunities</h2>
            </div>

            {/* search box  */}


            <div>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ boxSizing: "border-box" }}
              // wrap='nowrap'
              >

                {width > 960 && <div> <SearchBarSmall /></div>}


                <div className="mobOnlyFilter" style={{ gap: '5px', alignItems: 'center', width: '100%', }}>
                  <Grid item xs={12}>
                    <div className="searchBoxSmall searchBoxWithLogo opportunitiesSearch" style={styles.searchBoxSmall}>
                      <Grid container direction='row' rowSpacing={2} style={{ alignItems: 'center', width: '100%', flexWrap: 'nowrap' }}>
                        <Grid item xs={'auto'} style={{ paddingRight: '2px' }}>
                          <img src={srch} alt="Search for small gigs" />
                        </Grid>
                        <Grid item xs={'auto'} style={{ width: '100%', padding: "0 auto" }}>
                          <div style={styles.hiddenLabel} aria-hidden="true">Search for small gigs</div>
                          <Select
                            // isMulti
                            // className=''
                            id='jobTitleID'
                            // options={jobTitlesArr}
                            // onChange={e => setjobTitleSearch(e.value)}
                            className='stateDD'
                            placeholder='Search for small gigs'
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <div style={{ width: '42px', height: '42px', cursor: 'pointer' }}
                  //  onClick={submitQuery}
                  >
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21C42 32.598 32.598 42 21 42C9.40202 42 0 32.598 0 21Z" fill="url(#paint0_linear_3993_30332)" />
                      <path d="M20.3333 25.6667C23.2789 25.6667 25.6667 23.2789 25.6667 20.3333C25.6667 17.3878 23.2789 15 20.3333 15C17.3878 15 15 17.3878 15 20.3333C15 23.2789 17.3878 25.6667 20.3333 25.6667Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M26.9996 26.9991L24.0996 24.0991" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <defs>
                        <linearGradient id="paint0_linear_3993_30332" x1="1.81035" y1="-30.1304" x2="70.7503" y2="-5.34066" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#FFCF20" />
                          <stop offset="0.932292" stop-color="#FD8012" />
                        </linearGradient>
                      </defs>
                    </svg>

                  </div>
                  <div
                    //  onClick={() => setOpenFilter(!openFilter)}
                    style={{ width: '42px', height: '42px', borderRadius: '50%', backgroundColor: '#E9E9E9', padding: '13.6px', display: 'none', cursor: 'pointer' }}>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.50033 8.17826V14.5003C6.50034 14.5855 6.52214 14.6694 6.56368 14.7438C6.60522 14.8183 6.66511 14.8809 6.73766 14.9257C6.81021 14.9705 6.89301 14.996 6.97819 14.9998C7.06337 15.0035 7.1481 14.9855 7.22433 14.9473L10.2243 13.4473C10.3073 13.4057 10.377 13.3419 10.4258 13.2629C10.4745 13.184 10.5003 13.093 10.5003 13.0003V8.17826L14.8873 2.81726C14.929 2.76644 14.9602 2.70791 14.9792 2.64503C14.9982 2.58215 15.0046 2.51613 14.9981 2.45076C14.9916 2.38539 14.9723 2.32194 14.9413 2.26403C14.9103 2.20612 14.8682 2.15489 14.8173 2.11326C14.7665 2.07163 14.708 2.04042 14.6451 2.02141C14.5822 2.00239 14.5162 1.99595 14.4508 2.00245C14.3855 2.00895 14.322 2.02827 14.2641 2.05929C14.2062 2.09031 14.155 2.13244 14.1133 2.18326L9.61333 7.68326C9.54017 7.77269 9.50024 7.88471 9.50033 8.00026V12.6913L7.50033 13.6913V8.00026C7.50043 7.88471 7.4605 7.77269 7.38733 7.68326L3.55533 3.00026H11.5003C11.6329 3.00026 11.7601 2.94758 11.8539 2.85381C11.9477 2.76004 12.0003 2.63287 12.0003 2.50026C12.0003 2.36765 11.9477 2.24047 11.8539 2.1467C11.7601 2.05294 11.6329 2.00026 11.5003 2.00026H2.50033C2.40561 2.00019 2.31282 2.02704 2.23277 2.07766C2.15271 2.12829 2.08868 2.20061 2.04813 2.28621C2.00758 2.37182 1.99218 2.46718 2.00373 2.56119C2.01527 2.65521 2.05328 2.74401 2.11333 2.81726L6.50033 8.17826Z" fill="#4A4A4A" />
                    </svg>
                  </div>
                </div>

                <div>
                  <Button id='srchButton' className="opportunity-btn" style={{ textTransform: 'none', fontSize: "14px", fontFamily: 'Montserrat', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', color: '#FFF', fontWeight: '600', borderRadius: '25px', padding: '10px 21px', minWidth: '100%', margin: "10px 0px" }} variant="contained" onClick={() => setOpportunityForm2(true)}>Job Opportunity</Button>
                </div>
              </Grid>

            </div>


          </div>

          {/* end search section  */}

          {
            loadingState ?
              <div className='d-flex justify-content-center mt-5 mb-5'>
                <CircularProgress style={{ color: 'green', justifyContent: 'center' }} />
              </div>

              :

              <div style={{ boxSizing: "border-box" }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  wrap="wrap"
                  style={{ margin: "0px auto" }}
                >
                  {/* setId  */}

                  {
                    opportunities.map((e, i) => {
                      return (
                        <Grid item sm={12} md={6} lg={4} className="singleCard" ><Opportunities btnState={btnState} setBtnState={setBtnState} opportunity={e} id={personId} setId={setPersonId} /></Grid>

                      )
                    })
                  }


                </Grid>
              </div>
          }

        </section>

        <Button variant='outlined' style={{ borderRadius: "43px", display: "block", textAlign: "center", backgroundColor: "white", margin: "50px auto", marginBottom: "311px", padding: "12px 30px", fontWeight: "bold" }}>View more</Button>


      </div>

      <Footer />


    </div>
  );
};

export default OpportunitiesHome;



export const ProfileTags = (props) => {
  return (
    <div style={{ marginRight: "5px", whiteSpace: "nowrap" }} className={`profileTag3 ${props.img ? null : 'tagBoldText'}`}>
      <img src={props.img} alt='' />
      {props.text}
    </div>
  )
}
