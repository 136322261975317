import React, { useState, useEffect, useHistory } from 'react';
import { Link } from 'react-router-dom'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Carousel from 'react-material-ui-carousel'

import NavBar_noProfile from '../NavBar_noProfile';
import Footer from '../Footer';
import { Helmet } from "react-helmet";
import { Box, Container, Grid, Typography, Tabs, Tab } from '@material-ui/core';
import './styles.css';
import srch from './searchicon.svg';
import axiosInstance from '../../axios';
import loaderJson from '../../assets/loader.json';
import mainLoaderJson from '../../assets/mainLoader.json';
import Lottie from 'react-lottie';

import blog1 from '../../images/blog1.png';

import ScrollToTop from './ScrollToTop.js';
import { BlurOff } from '@material-ui/icons';


function BlogDetail() {

    const [blog, setblog] = useState({
        author: "",
        author_name: "",
        blog_content: "",
        blog_image: "",
        blog_tags: [],
        blog_title: "",
        id: null,
        long_description: "",
        posting_date: ""
    })

    const [recommendedBlog, setrecommendedBlog] = useState([]);
    const [loader, setloader] = useState(false);

    useEffect(()=>{
        // const path = location.pathname;
        // console.log(history.location.pathname);
        const id = window.location.href.split('/')[window.location.href.split('/').length -1];
        // console.log(id)
        setloader(true);

        axiosInstance.get(`/blogs/detailed-blog/${id}`).
        then(res=>{
            setblog(res.data);
            setloader(false);
        }).catch(err=>{
            console.log(err);
            setloader(false);
        })

        axiosInstance.get('/blogs/latest_blog/')
        .then(res=>{
            // console.log(res.data);
            setrecommendedBlog(res.data.results.slice(0, 3));
        }).catch(err=>{
            console.log(err);
        })
    }, [window.location.href])

    return (
        <div>
            <Helmet>
                <title>Blogs | Frame Change</title>
            </Helmet>
            <ScrollToTop />
            <NavBar_noProfile />
            {
                loader ? 
                <div className='' style={{height : '120vh', display : 'grid', placeContent : 'center', width : '100vw', backgroundColor : 'rgb(255,255,255,0.6)', position : 'fixed', zIndex: '100'}}>
                    {/* <iframe src="https://embed.lottiefiles.com/animation/66433"></iframe> */}
                    <Lottie 
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: loaderJson,
                            rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                            }
                        }}
                        height={200}
                        width={200}
                    />
                </div> : null
            }

            <Container maxWidth="lg" style={{ width: '100%', marginTop: '120px' }}>

                {/* Blog Detail */}
                <Grid container spacing={1} style={{ width: '100%', marginTop: '30px' }}>
                    <Grid item lg={8} md={8} sm={12} xs={12} style={{ padding: '1.5em 1em 1em 2em' }}>
                        <h3>{blog.blog_title}</h3>
                        <div style={{ maxWidth: '100%', display: 'flex', flexDirection: "row" }}>
                            <p style={{ fontSize: '0.9rem' }}><strong>-{blog.author_name}</strong></p>
                            <p style={{ fontSize: '0.9rem', paddingLeft: '30px' }}>{blog.posting_date}</p>
                        </div>
                        <p>{blog.short_description}</p>

                        <img src={blog.blog_image ? blog.blog_image : blog1} alt="" style={{ maxWidth: '90%' }} />
                        <p dangerouslySetInnerHTML={{__html : blog.blog_content}}></p>
                    </Grid>
                    <Grid item className="search" lg={4} md={4} sm={12} xs={12} spacing={1} style={{ width: '100%' }}>
                        <div>
                            <Grid container className='searchBar' spacing={1} style={{ width: '95%', margin: 'auto', paddingRight: '5rem' }}>
                                <img src={srch} alt="" style={{ maxWidth: '100%', position: 'absolute' }} />
                                <div style={{ margin: '0px 30px' }}>Search</div>
                            </Grid>
                            {
                                blog.blog_tags.length ? <>
                                    <p style={{ fontSize: '0.9rem', padding: '1rem 0rem 1rem 0.5rem' }}>Popular Topics to search for blogs</p>
                                    <div className="bubblediv" style={{ maxWidth: '100%', display: 'flex', flexWrap : "wrap", flexDirection: "row" }}>
                                        {
                                            blog.blog_tags.map(item=>{
                                                return(
                                                    <p className="bubble" style={{ fontSize: '0.9rem', paddingLeft: '0.5rem' }}>{item}</p>
                                                )
                                            })
                                        }
                                    </div>
                                </> : null
                            }
                        </div>


                        {/* Ad */}
                        <div className="ad" style={{ textAlign: 'center' }}>
                            AD
                        </div>

                        {/* Subscription */}
                        <div className="subscription">
                            <h5 style={{ fontSize: '1rem', textAlign: 'center', padding: '1rem' }}>Learn about more insightful topics regarding teaching profession</h5>
                            <p style={{ fontSize: '0.9rem', textAlign: 'center' }}>Stay up to date, subscribe to user our newsletter</p>
                            <p style={{ fontSize: '0.9rem', paddingLeft: '0.5rem' }}>Select topic of interest</p>
                            <div className="bubblediv" style={{ maxWidth: '100%', flexWrap : 'wrap', display: 'flex', flexDirection: "row" }}>
                                {
                                    blog.blog_tags.map(e=>{
                                        return(
                                            <p className="bubble" style={{ fontSize: '0.9rem', paddingLeft: '0.5rem' }}>{e}</p>
                                        )
                                    })
                                }
                            </div>
                            

                            <div className="email">
                                <div style={{ margin: '0px 20px' }}>Enter your email</div>
                            </div>

                            <div className="subscribeButton">
                                <div style={{ margin: '0px 20px' }}>Subscribe</div>
                            </div>

                        </div>

                        {/* Recommended */}
                        {
                            recommendedBlog.map(item=>{
                                return(<>
                                    <p>{item.posting_date}</p>
                                    <Link to=
                                        {{
                                            pathname: `/blog/${item.id}`
                                        }}
                                    >
                                        <h5 style={{ color: 'black' }}>{item.blog_title}</h5></Link>
                                    <p style={{ fontSize: '0.9rem' }}>{item.short_description}</p>
                                    <p><strong>-{item.author_name}</strong></p>
                                </>)
                            })
                        }
                    </Grid>
                </Grid>
            </Container >


            {/* Contact Us */}

            <Container className='contactUs' maxWidth="100%" style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>

                <Grid container style={{ width: '100%', gap: 45 }} >
                    <Grid container style={{ width: '100%' }}>
                        <Grid className='contactForm' item lg={5} md={5} sm={12} xs={12}>
                            <h3>Contact Us</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                            <Grid container direction='column' >
                                <span className='label'>First Name</span>
                                <OutlinedInput
                                    className='inputField'
                                    placeholder='First Name'
                                    variant='outlined'
                                />
                                <span className='label'>Last Name</span>
                                <OutlinedInput
                                    className='inputField'
                                    placeholder='Last Name'
                                    variant='outlined'
                                />
                                <span className='label'>Email</span>
                                <OutlinedInput
                                    className='inputField'
                                    placeholder='Email'
                                    variant='outlined'
                                />
                                <span className='label'>Message</span>
                                <OutlinedInput
                                    className='inputField'
                                    placeholder='Message'
                                    variant='outlined'
                                />
                            </Grid>


                            <div className="subscribeButton" style={{ width: '30%' }}>
                                Submit
                            </div>
                        </Grid>

                        {/* Swipable Review cards */}
                        <Grid item className="card" lg={5} md={5} sm={12} xs={12} style={{ margin: 'auto' }}>
                            <div>
                                <Carousel
                                    className="carousel"
                                    animation='slide'
                                    autoPlay={false}
                                    activeIndicatorIconButtonProps={{
                                        style: {
                                            backgroundColor: '#DDE3EE',
                                            color: '#FD8012',
                                        }
                                    }}

                                    indicatorIconButtonProps={{
                                        style: {
                                            color: '#DDE3EE',
                                        }
                                    }}
                                >
                                    <div className='carouselItem'>
                                        <p className="quote" >“</p>
                                        <p className="comment">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet egestas nunc, suscipit donec sed urna, rhoncus enim volutpat. Aliquam in dapibus pellentesque diam elit orci bibendum. Aliquet a felis semper proin ut facilisi felis semper proin ut facilisi et et odio. </p>
                                        <p className="commentBy">Jenny Wilson</p>
                                        <p className="commentLoc">Lorem Ipsum</p>
                                    </div>
                                    <div className='carouselItem'>
                                        <p className="quote" >“</p>
                                        <p className="comment">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet egestas nunc, suscipit donec sed urna, rhoncus enim volutpat. Aliquam in dapibus pellentesque diam elit orci bibendum. Aliquet a felis semper proin ut facilisi felis semper proin ut facilisi et et odio.</p>
                                        <p className="commentBy">Jenny Wilson</p>
                                        <p className="commentLoc">Lorem Ipsum</p>
                                    </div>
                                    <div className='carouselItem'>
                                        <p className="quote" >“</p>
                                        <p className="comment">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet egestas nunc, suscipit donec sed urna, rhoncus enim volutpat. Aliquam in dapibus pellentesque diam elit orci bibendum. Aliquet a felis semper proin ut facilisi felis semper proin ut facilisi et et odio.</p>
                                        <p className="commentBy">Jenny Wilson</p>
                                        <p className="commentLoc">Lorem Ipsum</p>
                                    </div>
                                </Carousel>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>


            <Footer style={{ marginTop: '1000px' }} />
        </div >
    )
}

export default BlogDetail;