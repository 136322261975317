import { Button, Checkbox, Container, FormControl, FormControlLabel, Grid, NativeSelect, Switch, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import EditImg from './edit.svg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from './deleteIcon.svg';
import Modal from '@mui/material/Modal'
import NavBar from '../NavBar';
import Footer from '../Footer'
import { Helmet } from "react-helmet";
import Pen from './pen.png'
import Visible from './visible.png'
import Invisible from './invisible.png'
import Del from './delete.png'
import Download from './download.png'
import FileUpoloadVector from './fileUpoloadVector.png'
import MaintainenceGuy from '../../images/undraw_maintenance_re_59vn 1.png'

import Loader from '../loader/loader';

import './style.css'
import axios from 'axios';
import Alert from '../alerts/alert';
import handleLogout from '../../Logout/logout';

import Cookies from 'js-cookie';
import axiosInstance from '../../axios';
import { jobTitlesArr } from '../navbarArrays/navbarArrays';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    margin: '10px',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#10B981',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));


function Setting() {


    const [openEditAccountInfo, setOpenEditAccountInfo] = React.useState(false);

    const [accessToken, setAccessToken] = useState('');

    const [firstName, setfirstName] = useState('NA');
    const [lastName, setlastName] = useState('NA');
    const [email, setemail] = useState('NA');
    const [phone, setphone] = useState('NA');
    const [street, setstreet] = useState('NA');
    const [city, setcity] = useState('NA');
    const [state, setstate] = useState('NA');
    const [zipcode, setzipCode] = useState('NA');

    // Edit Form
    const [firstNameEdit, setfirstNameEdit] = useState(firstName);
    const [lastNameEdit, setlastNameEdit] = useState(lastName);
    const [emailEdit, setemailEdit] = useState(email);
    const [phoneEdit, setphoneEdit] = useState(phone);
    const [streetEdit, setstreetEdit] = useState(street);
    const [cityEdit, setcityEdit] = useState(city);
    const [stateEdit, setstateEdit] = useState(state);
    const [zipcodeEdit, setzipCodeEdit] = useState(zipcode);

    //ALERT CODE:
    const [alertComp, setAlert] = React.useState(false);
    const [typeOfAlert, setType] = React.useState("");
    const [descOfAlert, setDesc] = React.useState("");

    // change password
    const [changePassword, setchangePassword] = useState({
        "current_password": '',
        "new_password": '',
        "confirm_new_password": ''
    })

    // change password form input handler
    const changePasswordInput = (e) => {
        // console.log(changePassword);
        const targetName = e.target.name;
        const targetValue = e.target.value;

        setchangePassword({ ...changePassword, [targetName]: targetValue })
    }

    const comparePassword = (pass1, pass2) => {
        if (pass1 === pass2)
            return true;
        return false;
    }

    const handleChngePasswordForm = async () => {
        // console.log(changePassword);
        const { current_password, new_password, confirm_new_password } = changePassword;
        const samePassword = await comparePassword(new_password, confirm_new_password);
        if (!(current_password && new_password && confirm_new_password)) {
            setAlert(true);
            setType("warning")
            setDesc('Please fill all the inputs');
            return;
        }
        if (!samePassword) {
            setAlert(true);
            setType("warning")
            setDesc('Passwords are not matching');
            return;
        }
        const data = {
            // 'access': accessToken,
            'current_password': current_password,
            'new_password': new_password
        }
        axiosInstance.post('/auth/change_password/', data)
            .then(data => {
                // console.log(data);
                setAlert(true);
                setType("success")
                setDesc('Password changed successfully!');
                setchangePassword({
                    "current_password": '',
                    "new_password": '',
                    "confirm_new_password": ''
                });
                handleCloseEditPassword();
            })
            .catch(data => {
                console.log(data);
                setAlert(true);
                setType("warning")
                setDesc("Incorrect Current Password! Try Again");
            })
    }


    const handleDeleteButton = () => {
        const data = {
            'access': accessToken
        }
        axiosInstance.post('/auth/delete_account/', data)
            .then(() => {
                setAlert(true);
                setType("success");
                setDesc('Successfully deleted account');
                handleLogout();
            })
            .catch(data => {
                console.log(data);
            })
    }

    const deleteFunc = (e) => {
        // console.log(e.target)
        let target = e.target.id.split('-');
        const id = target.at(-1);
        target = target[0];
        // console.log(target, id);

        axiosInstance.delete(`/seeker/${target}/`, {
            params: {
                // "access": accessToken,
                "id": parseInt(id)
            }
        }).then(res => {
            axiosInstance.get(`/seeker/${target}/`).then(res => {
                setAlert(true);
                setType("success")
                setDesc('Successfully deleted');
                getData();
            }).catch(err => {
                setAlert(true);
                setType("error")
                setDesc(err.toString());
            })
        }).catch(err => {
            setAlert(true);
            setType("error")
            setDesc(err.toString());
        })
    }
    const handleClickOpenEditAccountInfo = () => {
        setOpenEditAccountInfo(true);
    };

    const handleCloseEditAccountInfo = () => {
        setOpenEditAccountInfo(false);
    };

    const [openEditPassword, setOpenEditPassword] = React.useState(false);

    const handleClickOpenEditPassword = () => {
        setOpenEditPassword(true);
    };

    const handleCloseEditPassword = () => {
        setOpenEditPassword(false);
    };
    const [openDeleteAccount, setOpenDeleteAccount] = React.useState(false);
    const [openVisibleDoc, setopenVisibleDoc] = useState(false);
    const [documentData, setdocumentData] = useState({
        "makeVisible": true,
        "id": ''
    })

    const handleClickOpenDeleteAccount = () => {
        setOpenDeleteAccount(true);
    };

    const handleCloseDeleteAccount = () => {
        setOpenDeleteAccount(false);
    };

    const handleCloseVisibleDoc = () => {
        setopenVisibleDoc(false);
    }


    // resume
    const [resumes, setresumes] = useState([]);

    const getData = async () => {
        setloader(true)
        axiosInstance.get('/auth/settings/')
            .then(data => {
                const msg = data.data.msg;
                setfirstName(msg.first_name);
                setlastName(msg.last_name);
                // localStorage.getItem('name') = (msg.first_name ? toString(msg.first_name) : '')+ ' ' + (msg.last_name ? toString(msg.last_name) : '')
                localStorage.setItem('name', (msg.first_name ? msg.first_name : '') + ' ' + (msg.last_name ? msg.last_name : ''))
                setemail(msg.email);
                setphone(msg.phone);
                setstreet(msg.street);
                setcity(msg.city);
                setstate(msg.state);
                setzipCode(msg.zipcode);

                setfirstNameEdit(msg.first_name);
                setlastNameEdit(msg.last_name);
                setemailEdit(msg.email);
                setphoneEdit(msg.phone);
                localStorage.setItem('phone', msg.phone);
                setstreetEdit(msg.street);
                setcityEdit(msg.city);
                setstateEdit(msg.state);
                setzipCodeEdit(msg.zipcode);

                setloader(false)
            })

        // fetching documents
        fetching_documents();

        // fetching job preferences
        fetching_job_preferences();

        // fetching contact preferences
        fetching_contact_preference();
    }

    // fetching job preferences
    const fetching_job_preferences = () => {
        setloader(true);
        axiosInstance.get('/seeker/onboarding_2/')
            .then(data => {
                const prefered_jobs = [];
                const prefered_loc = [];
                const minimumSalary = data.data.msg['minimum_salary'];
                const notify_about_job = data.data.msg['notify_about_nearby_jobs'];

                data.data.msg['preffered_job_titles'].slice(1, -1).split(',').map(item => {
                    if (item) {
                        const title = {
                            "value": item.trim().slice(1, -1),
                            "label": item.trim().slice(1, -1)
                        }
                        prefered_jobs.push(title);
                    }
                })
                console.log(prefered_jobs);
                setSelectedJobOption(prefered_jobs);

                data.data.msg['preffered_job_locations'].slice(1, -1).split(',').map(item => {
                    if (item) {
                        const title = {
                            "value": item.trim().slice(1, -1),
                            "label": item.trim().slice(1, -1)
                        }
                        prefered_loc.push(title);
                    }
                })
                setSelectedLocOption(prefered_loc);

                setminimumSalary(minimumSalary);

                if (notify_about_job === false) {
                    setnotifyAboutJobs(false);
                } else {
                    setnotifyAboutJobs(true);
                }


                data.data.msg['job_type'].slice(1, -1).split(',').map(item => {

                    const v = item.trim().slice(1, -1);
                    if (v === 'Full Time') {
                        setjobTypeFullTime(true);
                    } else if (v === 'Part Time') {
                        setjobTypePartTime(true);
                    } else if (v === 'Remote Only') {
                        setjobTypeRemote(true);
                    }
                })
                setloader(false);
            })
            .catch(data => {
                setloader(false);
                console.log(data);
            })
    }

    // fetching documents
    const fetching_documents = () => {
        setloader(true);
        axiosInstance.get('/seeker/document_upload/')
            .then(data => {
                // console.log(data.data.msg.documents);
                setresumes(data.data.msg.documents);
                setloader(false);
            })
            .catch(data => {
                // console.log(data);
                setloader(false);
            })
    }

    const fetching_contact_preference = () => {
        setloader(true);
        axiosInstance.get('/seeker/settings/').then(res => {
            const data = res.data.data[0];
            delete data['id'];
            delete data['job_seeker_id'];
            console.log(data);
            setcontactPreferences(data);
            setloader(false);
        }).catch(error => {
            console.log(error);
            setloader(false);
        })
    }

    const checkEditForm = () => {

        if (firstNameEdit && lastNameEdit && phoneEdit && streetEdit && cityEdit && stateEdit && zipcodeEdit) {
            const numberRegex = /^[0-9]+$/;
            if (numberRegex.test(phoneEdit) && phoneEdit.length == 10) {
                if (zipcodeEdit.length < 8) {
                    return true;
                } else {
                    setAlert(true);
                    setType("warning");
                    setDesc("Zipcode is too long try with different one!")
                    return false;
                }
            } else {
                setAlert(true);
                setType("warning");
                setDesc("Please Enter valid Phone Number!")
                return false;
            }
        }
        setAlert(true);
        setType("warning");
        setDesc('Enter all Inputs!')
        return false;
    }

    const handleEditForm = async () => {
        const data = {
            // "access": accessToken,
            "firstName": firstNameEdit,
            "lastName": lastNameEdit,
            "email": emailEdit,
            "phone": phoneEdit,
            "street": streetEdit,
            "city": cityEdit,
            "state": stateEdit,
            "zipcode": zipcodeEdit
        }
        // console.log(checkEditForm());
        const checkValidation = await checkEditForm();
        if (!checkValidation) {
            return;
        }

        axiosInstance.post('/auth/settings/', data)
            .then(
                msg => {
                    // console.log(msg);
                    getData().then(
                        () => handleCloseEditAccountInfo()
                    )
                    // handleCloseEditAccountInfo();
                }
            )
            .catch(
                msg => {
                    console.log(msg);
                }
            )


        // handleCloseEditAccountInfo();
    }



    // resume upload function
    const handleResumeUpload = () => {
        // console.log(uploadedFile);

        let form = new FormData();
        form.append('access', accessToken);
        form.append('files', uploadedFile);
        axiosInstance.post('/seeker/document_upload/', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(async data => {
                getData()
                    .then(() => {
                        setUploadedFile('');
                        setAlert(true);
                        setType("success");
                        setDesc('Successfully Uploaded')
                        setUploadResumeModal(false);
                    })
            })
            .catch(data => {
                setAlert(true);
                setType("error");
                setDesc(data);
            })
    }

    // Job Preference
    // check job preference


    // job preference function
    const handleJobPreference = () => {
        let prefer_jobs = [];
        let prefer_location = [];
        selectedJobOption.map(item => {
            if (item.value)
                prefer_jobs.push(item.value);
        })
        if (prefer_jobs.length === 0) {
            prefer_jobs = null;
        }
        selectedLocOption.map(item => {
            if (item.value)
                prefer_location.push(item.value);
        })
        if (prefer_location.length === 0) {
            prefer_location = null;
        }
        const data = {
            "access": accessToken,
            "preffered_job_titles": prefer_jobs,
            "preffered_job_locations": prefer_location,
            "minimum_salary": minimumSalary,
            "notify_about_nearby_jobs": 'False',
            "job_type": []
        }
        if (jobTypeFullTime === true)
            data["job_type"].push("Full Time")
        if (jobTypePartTime === true)
            data["job_type"].push("Part Time")
        if (jobTypeRemote === true)
            data["job_type"].push("Remote Only")

        if (notifyAboutJobs === true)
            data['notify_about_nearby_jobs'] = 'True'


        axiosInstance.post('/seeker/onboarding_2/', data)
            .then(data => {
                // console.log(data);
                setAlert(true);
                setType("success");
                setDesc('Updated Successfully!');
                fetching_job_preferences();
            })
            .catch(data => {
                console.log(data);
            })
    }

    // contact preference function
    const handleContactPreference = () => {
        console.log(contactPreferences, "helllo");
        let data = contactPreferences;
        // data['access'] = accessToken;
        console.log(data);
        axiosInstance.post('/seeker/settings/', contactPreferences).then(res => {
            console.log(res.data);
            fetching_contact_preference();
            setAlert(true);
            setType("success");
            setDesc('Updated Successfully!');
        }).catch(err => {
            setAlert(true);
            setType("error");
            setDesc(err.toString());
        })
    }


    useEffect(() => {
        if (Cookies.get('access_token')) {
            setAccessToken(Cookies.get('access_token'))
            getData();
        } else {
            window.location.href = '/login';
        }
    }, [accessToken])

    const [value, setValue] = React.useState();
    useEffect(() => {
        let route = window.location.pathname
        let reouteBrkDown = route.split('/')
        setValue(reouteBrkDown[2])
    }, [])
    const { height, width } = useWindowDimensions();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setTimeout(() => { window.scrollTo(0, document.body.scrollTop) }, 50);

    };

    const handleChangeVisibility = () => {
        axiosInstance.patch('/seeker/document_upload/', {
            // access: accessToken,
            visibility: documentData.makeVisible,
            id: documentData.id
        }).then(() => {
            setAlert(true);
            setType("success");
            setDesc('Updated Successfully!');
            setopenVisibleDoc(false);
            fetching_documents();
        }).catch(err => {
            console.log(err);
        })
    }

    const [selectedJobOption, setSelectedJobOption] = useState([]);
    const [selectedLocOption, setSelectedLocOption] = useState([]);
    const [minimumSalary, setminimumSalary] = useState(null);
    const [notifyAboutJobs, setnotifyAboutJobs] = useState(false);

    const [jobTypeFullTime, setjobTypeFullTime] = useState(false);
    const [jobTypePartTime, setjobTypePartTime] = useState(false);
    const [jobTypeRemote, setjobTypeRemote] = useState(false);

    const [contactPreferences, setcontactPreferences] = useState({
        "send_recommendations_via_email": false,
        "send_alerts_via_email": true,
        "send_hot_jobs_via_email": false,
        "send_saved_reminder_via_email": false,
        "send_recommendations_viapush": false,
        "send_alerts_via_push": false,
        "send_hot_jobs_via_push": false,
        "send_saved_reminder_viapush": false,
        "employer_message_via_email": false,
        "job_seeker_message_via_email": false,
        "employer_message_via_push": false,
        "job_seeker_message_via_push": false,
        "send_newsletters_via_email": false,
        "send_podcasts_via_email": false,
        "send_salary_insights_via_email": false,
        "send_newsletters_via_push": false,
        "send_podcasts_via_push": false,
        "send_salary_insights_via_push": false
    })

    const Joboptions = [
        { value: 'Maths Teacher', label: 'Maths Teacher' },
        { value: 'English Teacher', label: 'English Teacher' },
        { value: 'Assistant Teacher', label: 'Assistant Teacher' },
        { value: 'Class Teacher', label: 'Class Teacher' },
        { value: 'History Teacher', label: 'History Teacher' },
    ];

    const Locoptions = [
        { value: 'Alabama', label: 'Alabama' },
        { value: 'Alaska', label: 'Alaska' },
        { value: 'American Samoa', label: 'American Samoa' },
        { value: 'Arizona', label: 'Arizona' },
        { value: 'Arkansas', label: 'Arkansas' },
        { value: 'California', label: 'California' },
        { value: 'Colorado', label: 'Colorado' },
        { value: 'Connecticut', label: 'Connecticut' },
        { value: 'Delaware', label: 'Delaware' },
        { value: 'District Of Columbia', label: 'District Of Columbia' },
        { value: 'Federated States Of Micronesia', label: 'Federated States Of Micronesia' },
        { value: 'Florida', label: 'Florida' },
        { value: 'Georgia', label: 'Georgia' },
        { value: 'Guam', label: 'Guam' },
        { value: 'Hawaii', label: 'Hawaii' },
        { value: 'Idaho', label: 'Idaho' },
        { value: 'Illinois', label: 'Illinois' },
        { value: 'Indiana', label: 'Indiana' },
        { value: 'Iowa', label: 'Iowa' },
        { value: 'Kansas', label: 'Kansas' },
        { value: 'Kentucky', label: 'Kentucky' },
        { value: 'Louisiana', label: 'Louisiana' },
        { value: 'Maine', label: 'Maine' },
        { value: 'Marshall Islands', label: 'Marshall Islands' },
        { value: 'Maryland', label: 'Maryland' },
        { value: 'Massachusetts', label: 'Massachusetts' },
        { value: 'Michigan', label: 'Michigan' },
        { value: 'Minnesota', label: 'Minnesota' },
        { value: 'Mississippi', label: 'Mississippi' },
        { value: 'Missouri', label: 'Missouri' },
        { value: 'Montana', label: 'Montana' },
        { value: 'Nebraska', label: 'Nebraska' },
        { value: 'Nevada', label: 'Nevada' },
        { value: 'New Hampshire', label: 'New Hampshire' },
        { value: 'New Jersey', label: 'New Jersey' },
        { value: 'New Mexico', label: 'New Mexico' },
        { value: 'New York', label: 'New York' },
        { value: 'North Carolina', label: 'North Carolina' },
        { value: 'North Dakota', label: 'North Dakota' },
        { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
        { value: 'Ohio', label: 'Ohio' },
        { value: 'Oklahoma', label: 'Oklahoma' },
        { value: 'Oregon', label: 'Oregon' },
        { value: 'Palau', label: 'Palau' },
        { value: 'Pennsylvania', label: 'Pennsylvania' },
        { value: 'Puerto Rico', label: 'Puerto Rico' },
        { value: 'Rhode Island', label: 'Rhode Island' },
        { value: 'South Carolina', label: 'South Carolina' },
        { value: 'South Dakota', label: 'South Dakota' },
        { value: 'Tennessee', label: 'Tennessee' },
        { value: 'Texas', label: 'Texas' },
        { value: 'Utah', label: 'Utah' },
        { value: 'Vermont', label: 'Vermont' },
        { value: 'Virgin Islands', label: 'Virgin Islands' },
        { value: 'Virginia', label: 'Virginia' },
        { value: 'Washington', label: 'Washington' },
        { value: 'West Virginia', label: 'West Virginia' },
        { value: 'Wisconsin', label: 'Wisconsin' },
        { value: 'Wyoming', label: 'Wyoming' },

    ];

    const JobPreference = <>

        <div style={{ display: 'flex', justifyContent: 'center' }}>

            <div style={{ width: '95%', maxWidth: '43em', margin: '6% 3%  ', backgroundColor: '#FFF', borderRadius: '10px', border: '2px solid #D0DBE6' }}>

                <div style={{ marginLeft: '2.5em', marginRight: "2.5em", display: "flex", flexDirection: 'column' }}>
                    <div style={{ marginTop: '1em' }}>
                        <Typography style={{ fontSize: '19px', fontWeight: '600', margin: '0 0' }}>Job Preferences</Typography>

                        <Typography className="perferencestyle" style={{ fontSize: '14px', color: '#6C6866', margin: '10px 0' }}>Let’s select your job preference so we can better help you find what you truely need</Typography>
                    </div>
                    <br />
                    <Grid item container spacing={3} lg={11}>
                        <Grid item lg={12} xs={10}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>


                                <span className='label'>Prefered Job Title</span>
                                <Select
                                    isMulti
                                    value={selectedJobOption}
                                    onChange={(selectedJobOption) => {
                                        if (selectedJobOption.length <= 3)
                                            setSelectedJobOption(selectedJobOption)
                                        else
                                            {
                                                // alert('You can select only 3 job titles');
                                                setAlert(true);
                                                setType("warning");
                                                setDesc("You can select only 3 job titles!")
                                            }
                                    }}
                                    options={jobTitlesArr}
                                />
                                {/* <TextField size="small" placeholder='Certificate Authority' variant='outlined' /> */}
                            </div>
                        </Grid>
                        <Grid item lg={12} xs={10}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>


                                <span className='label'>Prefered Job Location</span>
                                <Select
                                    isMulti
                                    value={selectedLocOption}
                                    onChange={(selectedLocOption) => {
                                        if (selectedLocOption.length <= 3)
                                            setSelectedLocOption(selectedLocOption)
                                        else
                                        {
                                            setAlert(true);
                                            setType("warning");
                                            setDesc("You can select only 3 job locations!")
                                        }
                                    }}
                                    options={Locoptions}
                                />
                            </div>
                        </Grid>


                        <Grid item style={{paddingBottom : '0px', marginBottom : '0px'}} lg={12}>
                            <span className='col-12 ml-5 label'><b>I am Open to</b></span>
                        </Grid>
                        <Grid item lg={4} sm={12}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>


                                <Checkbox
                                    style={{ padding: '0', color: '#0DAC69' }}
                                    checked={jobTypeFullTime}
                                    onChange={e => setjobTypeFullTime(e.target.checked)}

                                />
                                <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Full-time</span>
                            </div>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>


                                <Checkbox
                                    style={{ padding: '0', color: '#0DAC69' }}
                                    checked={jobTypePartTime}
                                    onChange={e => { setjobTypePartTime(e.target.checked) }}

                                />
                                <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Part-time</span>
                            </div>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>


                                <Checkbox
                                    style={{ padding: '0', color: '#0DAC69' }}
                                    checked={jobTypeRemote}
                                    onChange={e => { setjobTypeRemote(e.target.checked) }}
                                />
                                <span className='label' style={{ color: '#0F172A', marginLeft: '10px' }}>Remote-only</span>
                            </div>
                        </Grid>


                        <Grid item lg={5}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>


                                <FormControl variant="outlined" size="small">
                                    <span className='label'>Prefered Salary</span>
                                    <select value={minimumSalary} name='age' id='outlined-age-native-simple' onChange={(e) => { setminimumSalary(e.target.value) }}>
                                        <option value="" disabled selected >
                                            Hourly Salary
                                        </option>
                                        <option value="0-19">$ ($0-$19)</option>
                                        <option value="20-39">$$ ($20-$39)</option>
                                        <option value="40-59">$$$ ($40-$59)</option>
                                        <option value="60-79">$$$$ ($60-$79)</option>
                                        <option value="80">$$$$$ (>$79)</option>
                                    </select>
                                </FormControl>
                            </div>
                        </Grid>
                        {/* <Grid item lg={12}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography style={{ fontSize: '14px', color: '#706F6F', marginRight: '1.5em' }}>Recommend Online jobs</Typography>

                                <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}

                                />
                            </div>
                        </Grid> */}
                        
                        {/* <Grid item lg={10}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>


                                <Checkbox
                                    style={{ padding: '0', color: '#FF4642' }} id='notify_about_nearby_jobs'
                                    checked={notifyAboutJobs}
                                    onChange={e => { setnotifyAboutJobs(e.target.checked) }}

                                />
                                <span className='label' style={{ color: '#0F172A', marginLeft: '10px', marginRight: '15px' }}>Notify me when nearby job posts are available</span>
                            </div>
                        </Grid> */}




                    </Grid>








                </div>
                <Grid container>
                    <Grid item lg={12}>
                        <div style={{ float: 'right' }} >
                            {/* <Button

                                style={{ textTransform: 'none', border: '1px solid #808080', fontSize: "14px", margin: "15px 15px", width: '6em', fontFamily: 'Montserrat', background: '#FFF', color: '#808080', fontWeight: '600', borderRadius: '20px', padding: '7px 10px' }} variant="contained" >Skip</Button> */}
                            <Button
                                style={{ textTransform: 'none', fontSize: "14px", margin: "15px 15px", width: '8em', fontFamily: 'Montserrat', backgroundImage: 'linear-gradient(to right,#FFBB1D , #FD9315)', color: '#FFF', fontWeight: '600', borderRadius: '20px', padding: '7px 10px' }}
                                onClick={handleJobPreference}
                            >
                                Save</Button>


                        </div>
                    </Grid>

                </Grid>
            </div>
        </div>



    </>

    const ContactPreference =
        <Grid container justifyContent='center' direction='column' alignItems='center' style={{ background: '#F5F7FA', padding: '5% 0' }} id="contactPrefsMainGrid">
            <Grid container direction='column' item lg={7} md={10} sm={11} xs={11} style={{ borderRadius: '10px', border: '2px solid #D0DBE6', background: '#FFF', margin: '1em 0', padding: '2em' }}>
                <Grid container direction='row' justifyContent="space-between">
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container direction='column'>
                            <span style={{ fontSize: '23px', fontWeight: '600', marginBottom: '8px' }}>Job</span>

                            <span style={{ color: '#636363', marginBottom: '8px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</span>
                        </Grid>
                    </Grid>
                    <Grid item lg={1}>
                        <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                            label="ON"
                        />
                    </Grid>
                </Grid>
                <hr style={{ width: '100%', border: '1px solid #D0DBE6 ', margin: '1em 0' }} />
                <br />
                <Grid container item lg={12} style={{ margin: '1em 0' }} spacing={1}>
                    <Grid container direction='column' item lg={5} md={6}>
                        <span style={{ fontSize: '16px', fontWeight: '600', marginBottom: '8px' }}>Job Recommendations</span>
                        <span style={{ fontSize: '16px', color: '#9C9C9C', marginBottom: '8px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</span>

                    </Grid>
                    <Grid container justifyContent={width > 959 ? 'flex-end' : 'flex-start'} item lg={6} md={6}>
                        <FormControlLabel
                            label="Email"
                            style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                            control={<Checkbox
                                checked={contactPreferences['send_recommendations_via_email']}
                                onChange={e => {
                                    setcontactPreferences({ ...contactPreferences, ['send_recommendations_via_email']: e.target.checked });
                                    console.log(contactPreferences['send_recommendations_via_email'], e.target.checked);
                                }}
                                style={{ color: '#0AA55E' }} />}
                        />
                        <FormControlLabel
                            label="Push Notification"
                            style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                            control={<Checkbox
                                checked={contactPreferences['send_recommendations_viapush']}
                                onChange={e => setcontactPreferences({ ...contactPreferences, ['send_recommendations_viapush']: e.target.checked })}
                                style={{ color: '#0AA55E' }} />}
                        />
                    </Grid>
                </Grid>
                <Grid container item lg={12} style={{ margin: '1em 0' }} spacing={1}>
                    <Grid container direction='column' item lg={5} md={6}>
                        <span style={{ fontSize: '16px', fontWeight: '600', marginBottom: '8px' }}>Job Alerts</span>
                        <span style={{ fontSize: '16px', color: '#9C9C9C', marginBottom: '8px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</span>

                    </Grid>
                    <Grid item container justifyContent={width > 959 ? 'flex-end' : 'flex-start'} lg={6} md={6}>
                        <FormControlLabel
                            label="Email"
                            style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                            control={<Checkbox
                                checked={contactPreferences['send_alerts_via_email']}
                                onChange={e => setcontactPreferences({ ...contactPreferences, ['send_alerts_via_email']: e.target.checked })}
                                style={{ color: '#0AA55E' }} />}
                        />
                        <FormControlLabel
                            label="Push Notification"
                            style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                            control={<Checkbox
                                checked={contactPreferences['send_alerts_via_push']}
                                onChange={e => setcontactPreferences({ ...contactPreferences, ['send_alerts_via_push']: e.target.checked })}
                                style={{ color: '#0AA55E' }} />}
                        />
                    </Grid>
                </Grid>
                <Grid container item lg={12} style={{ margin: '1em 0' }} spacing={1}>
                    <Grid container direction='column' item lg={5} md={6}>
                        <span style={{ fontSize: '16px', fontWeight: '600', marginBottom: '8px' }}>Hot jobs</span>
                        <span style={{ fontSize: '16px', color: '#9C9C9C', marginBottom: '8px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</span>

                    </Grid>
                    <Grid item container justifyContent={width > 959 ? 'flex-end' : 'flex-start'} lg={6} md={6}>
                        <FormControlLabel
                            label="Email"
                            style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                            control={<Checkbox
                                checked={contactPreferences['send_hot_jobs_via_email']}
                                onChange={e => setcontactPreferences({ ...contactPreferences, ['send_hot_jobs_via_email']: e.target.checked })}
                                style={{ color: '#0AA55E' }} />}
                        />
                        <FormControlLabel
                            label="Push Notification"
                            style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                            control={<Checkbox
                                checked={contactPreferences['send_hot_jobs_via_push']}
                                onChange={e => setcontactPreferences({ ...contactPreferences, ['send_hot_jobs_via_push']: e.target.checked })}
                                style={{ color: '#0AA55E' }} />}
                        />
                    </Grid>
                </Grid>
                <Grid container item lg={12} style={{ margin: '1em 0' }} spacing={1}>
                    <Grid container direction='column' item lg={5} md={6}>
                        <span style={{ fontSize: '16px', fontWeight: '600', marginBottom: '8px' }}>Saved job reminder</span>
                        <span style={{ fontSize: '16px', color: '#9C9C9C', marginBottom: '8px' }}>Get a reminder when you have Saved Jobs expiring that week but you've not yet applied.</span>

                    </Grid>
                    <Grid item container justifyContent={width > 959 ? 'flex-end' : 'flex-start'} lg={6} md={6}>
                        <FormControlLabel
                            label="Email"
                            style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                            control={<Checkbox
                                checked={contactPreferences['send_saved_reminder_via_email']}
                                onChange={e => setcontactPreferences({ ...contactPreferences, ['send_saved_reminder_via_email']: e.target.checked })}
                                style={{ color: '#0AA55E' }} />}
                        />
                        <FormControlLabel
                            label="Push Notification"
                            style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                            control={<Checkbox
                                checked={contactPreferences['send_saved_reminder_viapush']}
                                onChange={e => setcontactPreferences({ ...contactPreferences, ['send_saved_reminder_viapush']: e.target.checked })}
                                style={{ color: '#0AA55E' }} />}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction='row' item lg={7} md={10} sm={11} xs={11} style={{ borderRadius: '10px', border: '2px solid #D0DBE6', background: '#FFF', margin: '1em 0', padding: '2em' }}>
                <Grid container direction='row' justifyContent="space-between">
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container direction='column'>
                            <span style={{ fontSize: '23px', fontWeight: '600', marginBottom: '8px' }}>Messages</span>

                            <span style={{ color: '#636363', marginBottom: '8px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</span>
                        </Grid>
                    </Grid>
                </Grid>
                <hr style={{ width: '100%', border: '1px solid #D0DBE6 ', margin: '1em 0' }} />
                <br />
                <Grid container item lg={12} style={{ margin: '1em 0' }} spacing={1}>
                    <Grid container direction='column' item lg={5} md={6}>
                        <span style={{ fontSize: '16px', fontWeight: '600', marginBottom: '8px' }}>Employer messages</span>
                        <span style={{ fontSize: '16px', color: '#9C9C9C', marginBottom: '8px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</span>

                    </Grid>
                    <Grid container direction='column' item lg={6} md={6}>
                        <Grid container justifyContent={width > 959 ? 'flex-end' : 'flex-start'} >
                            <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                label="ON"
                            />
                        </Grid>
                        <Grid container justifyContent={width > 959 ? 'flex-end' : 'flex-start'}>
                            <FormControlLabel
                                label="Email"
                                style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                                control={<Checkbox
                                    checked={contactPreferences['employer_message_via_email']}
                                    onChange={e => setcontactPreferences({ ...contactPreferences, ['employer_message_via_email']: e.target.checked })}
                                    style={{ color: '#0AA55E' }} />}
                            />
                            <FormControlLabel
                                label="Push Notification"
                                style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                                control={<Checkbox
                                    checked={contactPreferences['employer_message_via_push']}
                                    onChange={e => setcontactPreferences({ ...contactPreferences, ['employer_message_via_push']: e.target.checked })}
                                    style={{ color: '#0AA55E' }} />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item lg={12} style={{ margin: '1em 0' }} spacing={1}>
                    <Grid container direction='column' item lg={5} md={6}>
                        <span style={{ fontSize: '16px', fontWeight: '600', marginBottom: '8px' }}>Job seeker messages</span>
                        <span style={{ fontSize: '16px', color: '#9C9C9C', marginBottom: '8px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</span>

                    </Grid>
                    <Grid container direction='column' item lg={6} md={6}>
                        <Grid container justifyContent={width > 959 ? 'flex-end' : 'flex-start'} >
                            <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                label="ON"
                            />
                        </Grid>
                        <Grid container justifyContent={width > 959 ? 'flex-end' : 'flex-start'}>
                            <FormControlLabel
                                label="Email"
                                style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                                control={<Checkbox
                                    checked={contactPreferences['job_seeker_message_via_email']}
                                    onChange={e => setcontactPreferences({ ...contactPreferences, ['job_seeker_message_via_email']: e.target.checked })}
                                    style={{ color: '#0AA55E' }} />}
                            />
                            <FormControlLabel
                                label="Push Notification"
                                style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                                control={<Checkbox
                                    checked={contactPreferences['job_seeker_message_via_push']}
                                    onChange={e => setcontactPreferences({ ...contactPreferences, ['job_seeker_message_via_push']: e.target.checked })}
                                    style={{ color: '#0AA55E' }} />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container direction='column' item lg={7} md={10} sm={11} xs={11} style={{ borderRadius: '10px', border: '2px solid #D0DBE6', background: '#FFF', margin: '1em 0', padding: '2em' }}>
                <Grid container direction='row' justifyContent="space-between">
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container direction='column'>
                            <span style={{ fontSize: '23px', fontWeight: '600', marginBottom: '8px' }}>Career guidance</span>

                            <span style={{ color: '#636363', marginBottom: '8px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</span>
                        </Grid>
                    </Grid>
                    <Grid item lg={1}>
                        <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                            label="ON"
                        />
                    </Grid>
                </Grid>
                <hr style={{ width: '100%', border: '1px solid #D0DBE6 ', margin: '1em 0' }} />
                <br />
                <Grid container item lg={12} style={{ margin: '1em 0' }} spacing={1}>
                    <Grid container direction='column' item lg={5} md={6}>
                        <span style={{ fontSize: '16px', fontWeight: '600', marginBottom: '8px' }}>Newsletters</span>
                        <span style={{ fontSize: '16px', color: '#9C9C9C', marginBottom: '8px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</span>

                    </Grid>
                    <Grid container justifyContent={width > 959 ? 'flex-end' : 'flex-start'} item lg={6} md={6}>
                        <FormControlLabel
                            label="Email"
                            style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                            control={<Checkbox
                                checked={contactPreferences['send_newsletters_via_email']}
                                onChange={e => setcontactPreferences({ ...contactPreferences, ['send_newsletters_via_email']: e.target.checked })}
                                style={{ color: '#0AA55E' }} />}
                        />
                        <FormControlLabel
                            label="Push Notification"
                            style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                            control={<Checkbox
                                checked={contactPreferences['send_newsletters_via_push']}
                                onChange={e => setcontactPreferences({ ...contactPreferences, ['send_newsletters_via_push']: e.target.checked })}
                                style={{ color: '#0AA55E' }} />}
                        />
                    </Grid>
                </Grid>
                <Grid container item lg={12} style={{ margin: '1em 0' }} spacing={1}>
                    <Grid container direction='column' item lg={5} md={6}>
                        <span style={{ fontSize: '16px', fontWeight: '600', marginBottom: '8px' }}>Latest Postcasts</span>
                        <span style={{ fontSize: '16px', color: '#9C9C9C', marginBottom: '8px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</span>

                    </Grid>
                    <Grid item container justifyContent={width > 959 ? 'flex-end' : 'flex-start'} lg={6} md={6}>
                        <FormControlLabel
                            label="Email"
                            style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                            control={<Checkbox
                                checked={contactPreferences['send_podcasts_via_email']}
                                onChange={e => setcontactPreferences({ ...contactPreferences, ['send_podcasts_via_email']: e.target.checked })}
                                style={{ color: '#0AA55E' }} />}
                        />
                        <FormControlLabel
                            label="Push Notification"
                            style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                            control={<Checkbox
                                checked={contactPreferences['send_podcasts_via_push']}
                                onChange={e => setcontactPreferences({ ...contactPreferences, ['send_podcasts_via_push']: e.target.checked })}
                                style={{ color: '#0AA55E' }} />}
                        />
                    </Grid>
                </Grid>
                <Grid container item lg={12} style={{ margin: '1em 0' }} spacing={1}>
                    <Grid container direction='column' item lg={5} md={6}>
                        <span style={{ fontSize: '16px', fontWeight: '600', marginBottom: '8px' }}>Salary & Skills Insights</span>
                        <span style={{ fontSize: '16px', color: '#9C9C9C', marginBottom: '8px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</span>

                    </Grid>
                    <Grid item container justifyContent={width > 959 ? 'flex-end' : 'flex-start'} lg={6} md={6}>
                        <FormControlLabel
                            label="Email"
                            style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                            control={<Checkbox
                                checked={contactPreferences['send_salary_insights_via_email']}
                                onChange={e => setcontactPreferences({ ...contactPreferences, ['send_salary_insights_via_email']: e.target.checked })}
                                style={{ color: '#0AA55E' }} />}
                        />
                        <FormControlLabel
                            label="Push Notification"
                            style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                            control={<Checkbox
                                checked={contactPreferences['send_salary_insights_via_push']}
                                onChange={e => setcontactPreferences({ ...contactPreferences, ['send_salary_insights_via_push']: e.target.checked })}
                                style={{ color: '#0AA55E' }} />}
                        />
                    </Grid>
                </Grid>
                <Button style={{ textTransform: 'none', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', borderRadius: '18px', padding: '0.5em 1em', color: '#fff' }} onClick={handleContactPreference} autoFocus>
                    Save Changes
                </Button>
            </Grid>
        </Grid>;

    const ProfileForm = <></>

    const [uploadResumeModal, setUploadResumeModal] = useState(false);
    const [uploadedFile, setUploadedFile] = useState('');

    const DocRow = (props) => {
        const [visible, setVisible] = useState(props.data.visibility);

        // useEffect(()=>{
        //     setVisible(props.data.visibility);
        // }, [])

        const handleVisibleFunc = async (vis, id) => {
            // console.log(vis, id);
            setdocumentData({
                makeVisible: vis,
                id: id
            })
            console.log(documentData);
        }

        return (
            <div className={`tableLayout docRow ${props.data.id === 1 ? 'docRowFirst' : null} ${props.data.id === props.data.length - 1 ? 'docRowLast' : null}`}>
                <div className="docName">{props.data.document_name}
                    {/* <img src={Pen} alt="" /> */}
                </div>
                <div className="docDate">{props.data.uploaded_on}</div>
                <div className="docAction">
                    <div className="visibilityChanger" onClick={() => {
                        setdocumentData({
                            makeVisible: !visible,
                            id: props.data.id
                        })
                        setopenVisibleDoc(true);
                    }}>
                        <img src={visible ? Visible : Invisible} alt="" />

                        {visible ? 'Visible to employer : Click to hide' : 'Invisible to employer : Click to make visible'}
                    </div>
                    <div className="IconCont">
                        <a onClick={deleteFunc} > <img style={{ "cursor": "pointer" }} id={`document_upload-${props.data.id}`} src={Del} /> </a>
                        <a href={props.data.document_url} target="_blank" download ><img src={Download} /></a>
                    </div>
                </div>
            </div>
        )
    }

    const attachmentsForm = <>
        <div>
            <div className='attachmentHeading'>
                You have {resumes.length} {resumes.length === 1 ? 'attachment' : 'attachments'}
            </div>
            <div className="uploadBTN" onClick={() => setUploadResumeModal(true)}>Upload new resume</div>
        </div>
        {
            resumes.length > 0 ? (
                <div className="tableCont">
                    <div className="tableLayout">
                        <div className="tableHead">File name</div>
                        <div className="tableHead">Uploaded</div>
                        <div className="tableHead">Action</div>
                    </div>
                    <div className="docTableCont">
                        {
                            resumes.map((item) => {
                                return (<DocRow data={item} />)
                            })
                        }
                    </div>
                </div>)
                : (
                    <h2> No Documents Uploaded </h2>
                )
        }
        <Modal
            open={uploadResumeModal}
            onClose={() => setUploadResumeModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='modalCont' style={{ padding: '1em' }}>
                <div className="modalHeadinFileUp">
                    <div>
                        <h3 style={{ maxWidth: '288px', fontSize: '16px' }}>Upload attachments</h3>
                        <p style={{ maxWidth: '288px', fontSize: '14px' }}>You can upload resume, cover letter or any other document</p>
                    </div>
                </div>
                {uploadedFile === '' ? (<>
                    <label htmlFor="fileUp" className='FileInputDesign'>
                        <img src={FileUpoloadVector} alt="" />
                        <h3 style={{ fontSize: '14px', textAlign: 'center', color: '#343C42' }}>Drag and drop a file here or <b style={{ cursor: 'pointer', color: '#E3740B' }}>browse</b></h3>
                        <p style={{ textAlign: 'center', color: '#A7AEBA', fontSize: '14px' }}>Format: PDF, DOC, DOCX</p>
                        <input type="file" onChange={args => setUploadedFile(args.target.files[0])} name="fileUp" id='fileUp' accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                    </label>
                </>) :
                    <div>
                        <div>{uploadedFile.name}</div>
                        <br></br>
                        <button className="fileUPC2a" onClick={handleResumeUpload}>Submit</button>
                    </div>}
            </div>
        </Modal>
    </>





    const Account =
        <Grid container justifyContent='center' direction='column' alignItems='center' style={{ background: '#F5F7FA', padding: '5% 0' }}>

            <Grid container item lg={8} md={10} sm={10} xs={10} style={{ borderRadius: '15px', border: '2px solid #D0DBE6', position: 'relative', padding: '20px' }}>
                <div onClick={handleClickOpenEditAccountInfo} style={{ position: 'absolute', cursor: 'pointer', right: '2%', top: '5%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <img style={{ width: '20px', height: '20px' }} src={EditImg} alt='edit' /><span style={{ paddingLeft: '10px', fontWeight: '500', color: '#F28612', fontSize: '16px' }}>Edit</span>
                </div>
                <Grid container spacing={2} >

                    <Grid item lg={3} md={2} sm={2} xs={12}>
                        <div>
                            <Typography style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}>CONTACT INFO</Typography>
                        </div>

                    </Grid>
                    <Grid item lg={4} md={5} sm={5} xs={12}>
                        <div>

                            <label style={{ color: '#636363', paddingTop: '3px' }} htmlFor="name">First Name</label>

                        </div>
                        <div style={{ paddingTop: '10px', fontWeight: '450' }}>
                            <span style={{ maxWidth: '15rem', wordWrap: 'break-word' }}>{firstName}</span>
                        </div>
                        <br></br>
                        <br></br>

                        <div >

                            <label style={{ color: '#636363' }} htmlFor="email">Email</label>
                        </div>
                        <div style={{ paddingTop: '10px', fontWeight: '450' }}>

                            <span style={{ paddingTop: '3px', maxWidth: '15rem', wordWrap: 'break-word' }}>{email}</span>
                        </div>

                    </Grid>
                    <Grid item lg={4} md={5} sm={5} xs={12}>
                        <div>
                            <label style={{ color: '#636363' }} htmlFor="name">Last Name</label>
                        </div>
                        <div style={{ paddingTop: '10px', fontWeight: '450' }}>
                            <span style={{ maxWidth: '15rem', wordWrap: 'break-word' }}>{lastName}</span>
                        </div>
                        <br>
                        </br>
                        <br></br>
                        <div>
                            <label style={{ color: '#636363' }}>Phone Number</label>
                        </div>
                        <div style={{ paddingTop: '10px', fontWeight: '450' }}>
                            <span style={{ maxWidth: '15rem', wordWrap: 'break-word' }}>{phone}</span>
                        </div>
                        <br>
                        </br>
                        <br></br>

                    </Grid>

                </Grid>

                <Grid container spacing={2}   >
                    <Grid item lg={3} md={2} sm={2} xs={12}>
                        <div>
                            <Typography style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}>ADDRESS INFO</Typography>
                        </div>

                    </Grid>

                    <Grid item lg={4} md={5} sm={5} xs={12}>
                        <div>

                            <label style={{ color: '#636363', fontsize: '16px' }} htmlFor="name">Street</label>

                        </div>
                        <div style={{ paddingTop: '10px', fontWeight: '450' }}>
                            <span style={{ maxWidth: '15rem', wordWrap: 'break-word' }}>{street}</span>
                        </div>
                        <br></br>
                        <br></br>

                        <div>

                            <label style={{ color: '#636363' }} htmlFor="name">State</label>
                        </div>
                        <div style={{ paddingTop: '10px', fontWeight: '450' }}>

                            <span style={{ maxWidth: '15rem', wordWrap: 'break-word' }}>{state}</span>
                        </div>

                    </Grid>




                    <Grid item lg={4} md={5} sm={5} xs={12}>
                        <div>
                            <label style={{ color: '#636363' }} htmlFor="city">City/Suburb</label>
                        </div>

                        <div style={{ paddingTop: '10px', fontWeight: '450' }}>
                            <span>{city}</span>
                        </div>
                        <br>
                        </br>
                        <br></br>


                        <div>
                            <label style={{ color: '#636363' }}>Zip/Post code</label>
                        </div>
                        <div style={{ paddingTop: '10px', fontWeight: '450' }}>
                            <span>{zipcode}</span>
                        </div>
                        <br>
                        </br>
                        <br></br>




                    </Grid>

                </Grid>
            </Grid>
            <Grid container item lg={8} md={10} sm={10} xs={10} style={{ borderRadius: '15px', border: '2px solid #D0DBE6', marginTop: '2em', padding: '20px' }}>
                <Grid container spacing={2} >
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography style={{ color: '#000', fontWeight: '600', fontSize: '19px' }}>Account Settings</Typography>
                        <Typography style={{ color: '#000', fontSize: '14px', marginTop: '0.5em' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida.</Typography>

                        <Typography style={{ color: '#0667C3', fontSize: '16px', marginTop: '2em', fontWeight: '600', cursor: 'pointer' }} onClick={handleClickOpenEditPassword}>Change Password</Typography>
                        <Typography style={{ color: '#EC2525', fontSize: '16px', marginTop: '0.5em', fontWeight: '600', cursor: 'pointer' }} onClick={handleClickOpenDeleteAccount}>Delete Account</Typography>

                    </Grid>

                </Grid>


            </Grid>
        </Grid>

    const HelpCenter = <div className='modalCont' style={{ width: 'fit-content' }}>
        <img src={MaintainenceGuy} alt="" />
        <div className='loginModalHead'>This action is not available yet...</div>
        <div className='loginModalSubHead'>We are woking on it, please come back later.</div>
    </div>

    const renderSwitch = (param) => {
        switch (param) {
            case "attachments":
                return attachmentsForm;
            case "jobPrefernces":
                return JobPreference;
            case "contactPrefernces":
                return ContactPreference;
            case "account":
                return Account;
            case "helpCenter":
                return HelpCenter;


        }
    }

    const [loader, setloader] = useState(false);

    return (
        <div style={{ background: '#F5F7FA' }}>
            <Helmet>
                <title>Settings | Frame Change</title>
            </Helmet>

            {loader ? <Loader /> : null}

            <NavBar />
            {/* ALERT */}
            {alertComp ? <Alert type={typeOfAlert} desc={descOfAlert} alertState={setAlert} /> : null}
            <Container>

                <div style={{ paddingTop: '2em', marginTop: '8vh', marginBottom: '50px' }}>
                    <div style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>

                        <Tabs
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            value={value}
                            onChange={handleChange}
                            textColor="inherit"
                            TabIndicatorProps={{ style: { background: 'orange' } }}
                            aria-label="secondary tabs example"
                        >
                            <Tab style={{ textTransform: 'capitalize' }} value="attachments" label="Attachments" />
                            <Tab style={{ textTransform: 'capitalize' }} value="jobPrefernces" label="Job Preferences" />
                            <Tab style={{ textTransform: 'capitalize' }} value="contactPrefernces" label="Contact Preferences" />
                            <Tab style={{ textTransform: 'capitalize' }} value="account" label="Account" />
                            <Tab style={{ textTransform: 'capitalize' }} value="helpCenter" label="Help Center" />
                        </Tabs>


                    </div>
                    {renderSwitch(value)}
                </div>
            </Container>

            {/* Edit Job Alert Card */}
            <Dialog
                open={openEditAccountInfo}
                onClose={handleCloseEditAccountInfo}

            >
                <DialogTitle style={{ padding: '1.5em 1em' }} >
                    <span style={{ fontWeight: '700' }}>Edit account information</span>
                    <br />
                    <span style={{ fontWeight: '300', fontSize: '14px', color: '#474747' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In neque nunc tristique.</span>

                </DialogTitle>
                <DialogContent >
                    <Grid container justifyContent='column'>
                        <span style={{ fontWeight: '600', fontSize: '16px' }}>Contact info</span>
                        <Grid container direction='row' style={{ marginTop: '1em' }} spacing={1}>
                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12} >
                                <span style={{ fontSize: '14px' }}>First name<span style={{ color: 'red' }}>*</span></span>
                                <TextField
                                    id="standard-size-small"
                                    defaultValue={firstName}
                                    size="small"
                                    variant="outlined"
                                    onChange={(e) => setfirstNameEdit(e.target.value)} value={firstNameEdit}
                                />
                            </Grid>
                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12} >
                                <span style={{ fontSize: '14px' }}>Last name<span style={{ color: 'red' }}>*</span></span>
                                <TextField
                                    id="standard-size-small"
                                    defaultValue={lastName}
                                    size="small"
                                    variant="outlined"
                                    onChange={(e) => setlastNameEdit(e.target.value)} value={lastNameEdit}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction='row' style={{ marginTop: '1em' }} spacing={1}>
                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12} >
                                <span style={{ fontSize: '14px' }}>Email<span style={{ color: 'red' }}>*</span></span>
                                <TextField
                                    id="standard-size-small"
                                    defaultValue={email}
                                    size="small"
                                    variant="outlined"
                                    disabled
                                />
                            </Grid>
                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12} >
                                <span style={{ fontSize: '14px' }}>Phone number<span style={{ color: 'red' }}>*</span></span>
                                <TextField
                                    id="standard-size-small"
                                    defaultValue={phone}
                                    size="small"
                                    variant="outlined"
                                    onChange={(e) => setphoneEdit(e.target.value)} value={phoneEdit}
                                />
                            </Grid>
                        </Grid>
                        <span style={{ fontWeight: '600', fontSize: '16px', marginTop: '2em' }}>Address info</span>
                        <Grid container direction='row' style={{ marginTop: '1em' }} spacing={1}>
                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12} >
                                <span style={{ fontSize: '14px' }}>Street<span style={{ color: 'red' }}>*</span></span>
                                <TextField
                                    id="standard-size-small"
                                    defaultValue={street}
                                    size="small"
                                    variant="outlined"
                                    onChange={(e) => setstreetEdit(e.target.value)} value={streetEdit}
                                />
                            </Grid>
                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12} >
                                <span style={{ fontSize: '14px' }}>City/Suburb<span style={{ color: 'red' }}>*</span></span>
                                <TextField
                                    id="standard-size-small"
                                    defaultValue={city}
                                    size="small"
                                    variant="outlined"
                                    onChange={(e) => setcityEdit(e.target.value)} value={cityEdit}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction='row' style={{ marginTop: '1em' }} spacing={1}>
                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12} >
                                <span style={{ fontSize: '14px' }}>State<span style={{ color: 'red' }}>*</span></span>
                                <TextField
                                    id="standard-size-small"
                                    defaultValue={state}
                                    size="small"
                                    variant="outlined"
                                    onChange={(e) => setstateEdit(e.target.value)} value={stateEdit}
                                />
                            </Grid>
                            <Grid container direction='column' item lg={6} md={12} sm={12} xs={12} >
                                <span style={{ fontSize: '14px' }}>Zip/Post code<span style={{ color: 'red' }}>*</span></span>
                                <TextField
                                    id="standard-size-small"
                                    defaultValue={zipcode}
                                    size="small"
                                    variant="outlined"
                                    onChange={(e) => setzipCodeEdit(e.target.value)} value={zipcodeEdit}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions style={{ paddingBottom: '1.5em' }}>
                    <Button style={{ textTransform: 'none', border: '1px solid #808080', borderRadius: '18px', padding: '0.5em 1em', color: '#808080' }} onClick={handleCloseEditAccountInfo}>Cancel</Button>
                    <Button style={{ textTransform: 'none', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', borderRadius: '18px', padding: '0.5em 1em', color: '#fff' }} onClick={handleEditForm} autoFocus>
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>
            {/* EditPassword */}
            <Dialog
                open={openEditPassword}
                onClose={handleCloseEditPassword}

            >
                <DialogTitle style={{ padding: '1em 1em', minWidth: '300px' }} >
                    <span style={{ fontWeight: '700' }}>Change Password</span>
                </DialogTitle>
                <DialogContent >
                    <Grid container justifyContent='column' spacing={2}>
                        <Grid container direction='column' item lg={12} md={12} sm={12} xs={12} >
                            <span style={{ fontSize: '14px' }}>Current password</span>
                            <TextField
                                placeholder="Enter your current password"
                                size="medium"
                                variant="outlined"
                                value={changePassword.current_password}
                                name='current_password'
                                type='password'
                                onChange={changePasswordInput}
                            />
                        </Grid>
                        <Grid container direction='column' item lg={12} md={12} sm={12} xs={12} >
                            <span style={{ fontSize: '14px' }}>New password</span>
                            <TextField
                                placeholder="Create a  new password"
                                size="medium"
                                variant="outlined"
                                type='password'
                                value={changePassword.new_passord}
                                name='new_password'
                                onChange={changePasswordInput}
                            />
                        </Grid>
                        <Grid container direction='column' item lg={12} md={12} sm={12} xs={12} >
                            <span style={{ fontSize: '14px' }}>Confirm password</span>
                            <TextField
                                placeholder="Confirm your new password"
                                size="medium"
                                variant="outlined"
                                type='password'
                                value={changePassword.confirm_new_password}
                                name='confirm_new_password'
                                onChange={changePasswordInput}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions style={{ paddingBottom: '1.5em' }}>
                    <Button style={{ textTransform: 'none', border: '1px solid #808080', borderRadius: '18px', padding: '0.5em 1em', color: '#808080' }} onClick={handleCloseEditPassword}>Cancel</Button>
                    <Button style={{ textTransform: 'none', background: 'linear-gradient(109.78deg, #FFCF20 -15.8%, #FD8012 111.31%)', borderRadius: '18px', padding: '0.5em 1em', color: '#fff' }} onClick={handleChngePasswordForm} autoFocus>
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>



            <Modal
                open={openVisibleDoc}
                onClose={handleCloseVisibleDoc}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #999',
                    boxShadow: 24,
                    p: 3,
                    borderRadius: '5px'
                }}>

                    <Grid container direction='column' item lg={10} md={9} sm={9} xs={9} >
                        <span style={{ fontWeight: '600', fontSize: '19px' }}>Make your Document {!documentData.makeVisible ? 'Hide' : 'Visible'} ? </span>

                        <span style={{ fontSize: '14px', marginTop: '1em' }}>Are you sure you want to make your Document {!documentData.makeVisible ? 'Hide' : 'Visible'}  ?</span>

                    </Grid>
                    <br />
                    <Button style={{ textTransform: 'none', border: '1px solid #808080', borderRadius: '18px', padding: '0.5em 1em', color: '#808080' }} onClick={handleCloseVisibleDoc}>Cancel</Button>
                    <Button style={{ textTransform: 'none', background: 'linear-gradient(275.34deg, #FFA500 -10.41%, #FFA500 -10.4%, #FFA500 102.29%)', borderRadius: '18px', padding: '0.5em 1em', color: '#fff', marginLeft: '5px' }} onClick={handleChangeVisibility} autoFocus>
                        Confirm
                    </Button>

                </Box>
            </Modal>

            {/* Delete Alert */}

            <Dialog
                open={openDeleteAccount}
                onClose={handleCloseDeleteAccount}

            >

                <DialogContent >
                    <Grid container justifyContent='row' spacing={1} >
                        <Grid item lg={2} md={3} sm={3} xs={3} >
                            <img src={DeleteIcon} alt='Delete Icon' />
                        </Grid>
                        <Grid container direction='column' item lg={10} md={9} sm={9} xs={9} >
                            <span style={{ fontWeight: '600', fontSize: '19px' }}>Delete my account</span>

                            <span style={{ fontSize: '14px', marginTop: '1em' }}>Are you sure you want to delete your account? if you delete your account, you will permanently lose your profile, messages, and photos</span>

                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ paddingBottom: '1.5em' }}>
                    <Button style={{ textTransform: 'none', border: '1px solid #808080', borderRadius: '18px', padding: '0.5em 1em', color: '#808080' }} onClick={handleCloseDeleteAccount}>Cancel</Button>
                    <Button style={{ textTransform: 'none', background: 'linear-gradient(275.34deg, #D80202 -10.41%, #D70000 -10.4%, #FF4545 102.29%)', borderRadius: '18px', padding: '0.5em 1em', color: '#fff' }} onClick={handleDeleteButton} autoFocus>
                        Delete Account
                    </Button>
                </DialogActions>
            </Dialog>
            <Footer />
        </div>
    )
}



export default Setting
