import { Container, Grid, Typography } from '@material-ui/core';
import './style.css';
import React, { useState } from 'react';
import Img1 from './img1.svg';
import Img2 from './img2.svg';
import Img3 from './img3.svg';
import Img4 from './img4.svg';
import Img5 from './img5.svg';
import Img6 from './img6.svg';
import {Helmet} from "react-helmet";
function JobSeeker() {
    const [isHover1,setIsHover1] = useState(false);
    const [isHover2,setIsHover2] = useState(false);
    const [isHover3,setIsHover3] = useState(false);
    const [isHover4,setIsHover4] = useState(false);
    const [isHover5,setIsHover5] = useState(false);
    const [isHover6,setIsHover6] = useState(false);
    return (
        <Container>
            <Helmet>
                <title>Frame Change</title>
            </Helmet>
        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',textAlign:'center',margin:"4em 2em"}}>
            <Typography style={{fontSize:'23px',fontWeight:'600'}}>
            What type of job seeker are you?
            </Typography>
            <Typography style={{fontSize:'19px',color:'#170D08',opacity:'100%'}}>
            Help us find you best Job, Resources and a Career growth plan based on your needs
            </Typography>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            
            <Grid container spacing={4} justifyContent="center" >
                <Grid item lg={4} md={6}> 
                <div style={{ display:'flex',height:'9.5em',cursor:"pointer", border: '0.5px solid #D8D2CC', borderRadius: '5px', boxShadow: isHover1 ? '0 0px 10px 10px rgb(51 51 51 / 20%)' : '0 1px 9px 2px rgb(51 51 51 / 10%)' }} onMouseEnter={()=>{setIsHover1(true)}} onMouseLeave={()=>{setIsHover1(false)}}>
                    <div style={{margin:'1em 0.5em 3em 1em',display:'flex',alignItems:'start'}} >
                    <img src={Img1} alt="img1"/>
                    <div style={{display:'flex',flexDirection:'column',textAlign:'start',marginLeft:'2px'}}>
                    <Typography style={{fontWeight:'600',fontSize:'16px'}}>Novice</Typography>
                    
                    <Typography style={{fontSize:'14px',marginTop:'2px',opacity:'80%'}}>For freshers just out of college or beginning to start searching for jobs</Typography>
                    </div>
                    </div>
                    
                </div>
                </Grid>
                <Grid item lg={4} md={6}> 
                <div style={{ display:'flex',height:'9.5em',cursor:"pointer", border: '0.5px solid #D8D2CC', borderRadius: '5px', boxShadow: isHover2 ? '0 0px 10px 10px rgb(51 51 51 / 20%)' : '0 1px 9px 2px rgb(51 51 51 / 10%)' }} onMouseEnter={()=>{setIsHover2(true)}} onMouseLeave={()=>{setIsHover2(false)}}>
                    <div style={{margin:'1em 0.5em 3em 1em',display:'flex',alignItems:'start'}}>
                    <img src={Img2} alt="img2 "/>
                    <div style={{display:'flex',flexDirection:'column',textAlign:'start',marginLeft:'2px'}}>
                    <Typography style={{fontWeight:'600',fontSize:'16px'}}>Proficient</Typography>
                    
                    <Typography style={{fontSize:'14px',marginTop:'2px',opacity:'80%'}}>For seekers who already have a job , but looking for another job or trying to switch into another career</Typography>
                    </div>
                    </div>
                    
                </div>
                </Grid>
                <Grid item lg={4} md={6}> 
                <div style={{ display:'flex',height:'9.5em',cursor:"pointer", border: '0.5px solid #D8D2CC', borderRadius: '5px', boxShadow: isHover3 ? '0 0px 10px 10px rgb(51 51 51 / 20%)' : '0 1px 9px 2px rgb(51 51 51 / 10%)' }} onMouseEnter={()=>{setIsHover3(true)}} onMouseLeave={()=>{setIsHover3(false)}}>
                    <div style={{margin:'1em 0.5em 3em 1em',display:'flex',alignItems:'start'}}>
                    <img src={Img3} alt="img3"/>
                    <div style={{display:'flex',flexDirection:'column',textAlign:'start',marginLeft:'2px'}}>
                    <Typography style={{fontWeight:'600',fontSize:'16px'}}>Onlooker / Observer</Typography>
                    
                    <Typography style={{fontSize:'14px',marginTop:'2px',opacity:'80%'}}>
                    Already has a job and not actively searching for jobs, but is open to career opportunities if one came along
                    </Typography>
                    </div>
                    </div>
                    
                </div>
                </Grid>
                <Grid item lg={4} md={6}> 
                <div style={{ display:'flex',height:'9.5em',cursor:"pointer", border: '0.5px solid #D8D2CC', borderRadius: '5px', boxShadow: isHover4 ? '0 0px 10px 10px rgb(51 51 51 / 20%)' : '0 1px 9px 2px rgb(51 51 51 / 10%)' }} onMouseEnter={()=>{setIsHover4(true)}} onMouseLeave={()=>{setIsHover4(false)}}>
                    <div style={{margin:'1em 0.5em 3.2em 1em',display:'flex',alignItems:'start'}}>
                    <img src={Img4} alt="img4"/>
                    <div style={{display:'flex',flexDirection:'column',textAlign:'start',marginLeft:'2px'}}>
                    <Typography style={{fontWeight:'600',fontSize:'16px'}}>Executive</Typography>
                    
                    <Typography style={{fontSize:'14px',marginTop:'2px',opacity:'80%'}}>People looking for promotions (want to be in the education, but does not want to be in classroom)
                    </Typography>
                    </div>
                    </div>
                    
                </div>
                </Grid>
                <Grid item lg={4} md={6}> 
                <div style={{ display:'flex',height:'9.5em',cursor:"pointer", border: '0.5px solid #D8D2CC', borderRadius: '5px', boxShadow: isHover5 ? '0 0px 10px 10px rgb(51 51 51 / 20%)' : '0 1px 9px 2px rgb(51 51 51 / 10%)' }} onMouseEnter={()=>{setIsHover5(true)}} onMouseLeave={()=>{setIsHover5(false)}}>
                    <div style={{margin:'1em 0.5em 4.5em 1em',display:'flex',alignItems:'start'}}>
                    <img src={Img5} alt="img5"/>
                    <div style={{display:'flex',flexDirection:'column',textAlign:'start',marginLeft:'2px'}}>
                    <Typography style={{fontWeight:'600',fontSize:'16px'}}>Superannuated/former</Typography>
                    
                    <Typography style={{fontSize:'14px',marginTop:'2px',opacity:'80%'}}>Retired teachers that only want to be temporary</Typography>
                    </div>
                    </div>
                    
                </div>
                </Grid>
                <Grid item lg={4} md={6}> 
                <div style={{ display:'flex',height:'9.5em',cursor:"pointer", border: '0.5px solid #D8D2CC', borderRadius: '5px', boxShadow: isHover6 ? '0 0px 10px 10px rgb(51 51 51 / 20%)' : '0 1px 9px 2px rgb(51 51 51 / 10%)' }} onMouseEnter={()=>{setIsHover6(true)}} onMouseLeave={()=>{setIsHover6(false)}}>
                    <div style={{margin:'1em 0.5em 2em 1em',display:'flex',alignItems:'start'}}>
                    <img src={Img6} alt="img6"/>
                    <div style={{display:'flex',flexDirection:'column',textAlign:'start',marginLeft:'2px'}}>
                    <Typography style={{fontWeight:'600',fontSize:'16px'}}>Community</Typography>
                    
                    <Typography style={{fontSize:'14px',marginTop:'2px',opacity:'80%'}}>
                        People only looking to network with the schools/ school districts or researching for information to make decisions later
                    </Typography>
                    </div>
                    </div>
                    
                </div>
                </Grid>
                


            </Grid>
            

            
        </div>
        </Container>
    )
}

export default JobSeeker
