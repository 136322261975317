import { Container, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import NavBar_noProfile from '../NavBar_noProfile';
import {useHistory} from 'react-router-dom'
import { Button } from '@material-ui/core';
import srch from '../NavBar_withSearch/searchicon.svg';
import pin from '../NavBar_withSearch/pincodeicon.svg';
import Footer from '../Footer/index.js'
import Carousel from 'react-material-ui-carousel'
import Select from 'react-select';
import CountUp from 'react-countup';
import Modal from '@mui/material/Modal'
import MaintainenceGuy from '../../images/undraw_maintenance_re_59vn 1.png'
import HeroImg from './Group 1.png'
import AU1 from './image 273.png'
import Badge1 from './Frame 6831.png'
import Badge2 from './Frame 6833.png'
import Badge3 from './Frame 6832.png'
import Bruce from './Rectangle 998.png'
import Cabral from './Rectangle 999.png'
import OutlinedInput from '@material-ui/core/OutlinedInput';


import './aboutUs.css'

const AboutUs = () => {

  const [loginModal, setLoginModal] = useState(false)


const history = useHistory();
  return (
    <div> 
      <Modal
        open={loginModal}
        onClose={() => {setLoginModal(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"  
      >
                <div className='modalCont' style={{width: 'fit-content'}}>
                    <img src={ MaintainenceGuy } alt="" />
                    <div className='loginModalHead'>This action is not available yet...</div>
                    <div className='loginModalSubHead'>We are woking on it, please come back later.</div>
                    <button className='loginModalBtn' onClick={() => setLoginModal(false)}>Okay</button>
                </div>
      </Modal>
      <div style={{backgroundColor: '#F3F7FF'}}>
        <NavBar_noProfile />
          <Container maxWidth="lg" style={{paddingTop: '150px'}}>
            <Grid container spacing={2} style={{width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
              <Grid item style={{maxWidth: '550px'}}>
                <h1>About us</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra arcu et pellentesque convallis ullamcorper elit. Eleifend scelerisque tempor sem euismod vestibulum nullam facilisi sed sed. Facilisis amet, lectus quam semper non. Vitae elementum, gravida condimentum id.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra arcu et pellentesque convallis ullamcorper elit. Eleifend scelerisque tempor sem euismod vestibulum nullam facilisi sed sed. Facilisis amet, lectus quam semper non. Vitae elementum, gravida condimentum id.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra arcu et pellentesque convallis ullamcorper elit. Eleifend scelerisque tempor sem euismod vestibulum nullam facilisi sed sed. 
              </p>
                <div className="badgeContGrid" style={{marginTop: '70px'}} onClick={() => setLoginModal(true)}>
                  <Badge text='Lorem ipsum dolor sit ame' />
                  <Badge text='Lorem ipsum dolor sit ame' />
                </div>
              </Grid>
              <Grid item style={{maxWidth: '500px'}}>
                <img src={HeroImg} alt="" style={{maxWidth: '95vw', width: '140%'}} />
              </Grid>
            </Grid>
          </Container>
      </div>
        <div className="statsCont" style={{gap: '5rem', marginTop: '150px'}}>
          <StatBadge img={Badge1} title='Pioneering Solutions' desc='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />
          <StatBadge img={Badge2} title='Supporting the Underserved' desc='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />
          <StatBadge img={Badge3} title='Creating Networks for Social Impact' desc='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />
        </div>
      <div style={{backgroundColor: '#F3F7FF', padding: '50px auto'}}>
        <Container maxWidth="lg">
          <Grid container spacing={2} style={{width: '100%', justifyContent: 'space-between', marginTop: '100px'}} className='mobCenter'>
            <Grid item style={{maxWidth: '500px'}}>
              <img src={AU1} alt="" style={{maxWidth: '95vw', width: '100%'}} />
            </Grid>
            <Grid item style={ { maxWidth: '550px', alignContent: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' } }>
              <h2>How We’re Different</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum integer id tristique iaculis mi platea mi vestibulum odio. Nisi arcu eget varius fermentum etiam dictum lacinia arcu rhoncus. Justo, dolor vulputate tellus, tincidunt dignissim nisl in imperdiet. Etiam nunc mollis porttitor a. Arcu, aliquet dignissim dui tortor, etiam est mi. Leo nec in aliquet lacinia.</p>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{width: '100%', justifyContent: 'space-between', marginTop: '100px', flexDirection: 'row-reverse'}} className='mobCenter'>
            <Grid item style={{maxWidth: '500px'}}>
              <img src={AU1} alt="" style={{maxWidth: '95vw', width: '100%'}} />
            </Grid>  
            <Grid item style={ { maxWidth: '550px', alignContent: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' } }>
              <h2>How We’re Different</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum integer id tristique iaculis mi platea mi vestibulum odio. Nisi arcu eget varius fermentum etiam dictum lacinia arcu rhoncus. Justo, dolor vulputate tellus, tincidunt dignissim nisl in imperdiet. Etiam nunc mollis porttitor a. Arcu, aliquet dignissim dui tortor, etiam est mi. Leo nec in aliquet lacinia.</p>
            </Grid>
          </Grid>
          </Container>
      </div>
      <Container maxWidth="lg">
        <h2 style={{textAlign: 'center', marginTop: '10rem', marginBottom: '22px'}}>Meet our founders</h2>
        <p style={ { textAlign: 'center'} }>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra arcu et pellentesque convallis ullamcorper elit. Eleifend scelerisque tempor sem euismod vestibulum nullam facilisi sed sed. Facilisis amet, lectus quam semper non. Vitae elementum, gravida condimentum id.Lorem ipsum dolor sit amet
        </p>
        <Grid container spacing={2} style={{width: '90%', justifyContent: 'start', gap: '1rem', alignItems: 'center', marginTop: '70px'}} className='mobCenter'>
          <Grid item style={{maxWidth: '500px'}}>
            <img src={Bruce} alt="" style={{maxWidth: '95vw', width: '100%'}} />
          </Grid>
          <Grid item style={ { maxWidth: '550px', alignContent: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' } }>
            <h2>Bruce</h2>
            <p>I live my life by the bible scripture Jeremiah 29:11, "Pursue Your Destiny", which has led me to establish Frame Change to repurpose education to be inclusive for all students, especially underserved learners. It is time for a change that brings solutions to the global marketplace that better educate, and nurture underserved learners, and that is the mission and challenge I have taken on and why I established Frame Change.</p>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{width: '90%', justifyContent: 'end', gap: '1rem', alignItems: 'center', marginTop: '50px'}} className='mobCenter'>
          <Grid item style={ { maxWidth: '550px', alignContent: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' } }>
            <h2>Cabral</h2>
            <p>I am passionate about developing pathways for achievement for students, their families, and the community. I am an avid fan of soccer (AC Milan) and dark chocolate. My professional background includes management consulting, technology and innovation development, and multimedia content production. I aspire to learn to play guitar. I also plan to visit every country on the continent of Africa.</p>
          </Grid>
          <Grid item style={{maxWidth: '500px'}}>
            <img src={Cabral} alt="" style={{maxWidth: '95vw', width: '100%'}} />
          </Grid>
        </Grid>
      </Container>
      {/* Contact Us */}
            <Container className='contactUs' maxWidth="100%" style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <Grid container style={{ width: '100%', gap: 80 }} >
                    <Grid className='contactForm' item lg={5} md={5} sm={12} xs={12} >
                        <h3>Contact Us</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                        <Grid container direction='column' >
                            <span className='label'>First Name</span>
                            <OutlinedInput
                                className='inputField'
                                placeholder='First Name'
                                variant='outlined'
                            />
                            <span className='label'>Last Name</span>
                            <OutlinedInput
                                className='inputField'
                                placeholder='Last Name'
                                variant='outlined'
                            />
                            <span className='label'>Email</span>
                            <OutlinedInput
                                className='inputField'
                                placeholder='Email'
                                variant='outlined'
                            />
                            <span className='label'>Message</span>
                            <OutlinedInput
                                className='inputField'
                                placeholder='Message'
                                variant='outlined'
                            />
                        </Grid>


                        <div className="subscribeButton" style={{ width: '30%' }}>
                            Submit
                        </div>
                    </Grid>

                    {/* Swipable Review cards */}
                    <Grid item className="card" lg={5} md={5} sm={12} xs={12} style={{ margin: 'auto' }}>
                        <div>
                            <Carousel
                                className="carousel"
                                animation='slide'
                                autoPlay={false}
                                activeIndicatorIconButtonProps={{
                                    style: {
                                        backgroundColor: '#DDE3EE',
                                        color: '#FD8012',
                                        marginTop: '5px'
                                    }
                                }}

                                indicatorIconButtonProps={{
                                    style: {
                                        color: '#DDE3EE',
                                        marginTop: '5px'
                                    }
                                }}
                            >
                                <div className='carouselItem'>
                                    <p className="quote" >“</p>
                                    <p className="comment">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet egestas nunc, suscipit donec sed urna, rhoncus enim volutpat. Aliquam in dapibus pellentesque diam elit orci bibendum. Aliquet a felis semper proin ut facilisi felis semper proin ut facilisi et et odio. </p>
                                    <p className="commentBy">Jenny Wilson</p>
                                    <p className="commentLoc">Lorem Ipsum</p>
                                </div>
                                <div className='carouselItem'>
                                    <p className="quote" >“</p>
                                    <p className="comment">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet egestas nunc, suscipit donec sed urna, rhoncus enim volutpat. Aliquam in dapibus pellentesque diam elit orci bibendum. Aliquet a felis semper proin ut facilisi felis semper proin ut facilisi et et odio.</p>
                                    <p className="commentBy">Jenny Wilson</p>
                                    <p className="commentLoc">Lorem Ipsum</p>
                                </div>
                                <div className='carouselItem'>
                                    <p className="quote" >“</p>
                                    <p className="comment">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet egestas nunc, suscipit donec sed urna, rhoncus enim volutpat. Aliquam in dapibus pellentesque diam elit orci bibendum. Aliquet a felis semper proin ut facilisi felis semper proin ut facilisi et et odio.</p>
                                    <p className="commentBy">Jenny Wilson</p>
                                    <p className="commentLoc">Lorem Ipsum</p>
                                </div>
                            </Carousel>
                        </div>
                    </Grid>
                </Grid>

            </Container>
      <Footer register />
    </div>
  )
}

export default AboutUs

const Badge = (props) => {
  return (
    <div className="badgeCont">
      {props.text}
    </div>
  )
}

const StatBadge = ( props ) => { 
  return (
    <div className="statBadgeCont">
      <img src={ props.img } alt="" />
      <div className="textBadge">
        <div className="textBadgeTitle">{props.title}</div>
        <div className="textBadgeDesc">{props.desc}</div>
      </div>
    </div>
  )
}

const styles = {
  searchBoxBig: {
    fontSize: '14px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    background: '#fff',
    border: '1px solid #BEBEBE',
    borderRadius: '25px',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  searchBoxSmall: {
      fontSize: '14px',
      fontFamily: 'Montserrat',
      fontWeight: '600',
      background: '#fff',
      border: '1px solid #BEBEBE',
      borderRadius: '25px',
      paddingLeft: '1em',
      paddingRight: '1em',
      paddingTop: '4px',
      paddingBottom: '4px',
  },
  srchField: {
      border: "none",
      borderColor: "transparent",
      outline: "none",
      width: "100%",
  },
  hiddenLabel: {
      height: 0,
      visibility: "hidden",
  },
};

const Locoptions = [
    { value: 'Alabama', label: 'Alabama' },
    { value: 'Alaska', label: 'Alaska' },
    { value: 'American Samoa', label: 'American Samoa' },
    { value: 'Arizona', label: 'Arizona' },
    { value: 'Arkansas', label: 'Arkansas' },
    { value: 'California', label: 'California' },
    { value: 'Colorado', label: 'Colorado' },
    { value: 'Connecticut', label: 'Connecticut' },
    { value: 'Delaware', label: 'Delaware' },
    { value: 'District Of Columbia', label: 'District Of Columbia' },
    { value: 'Federated States Of Micronesia', label: 'Federated States Of Micronesia' },
    { value: 'Florida', label: 'Florida' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Guam', label: 'Guam' },
    { value: 'Hawaii', label: 'Hawaii' },
    { value: 'Idaho', label: 'Idaho' },
    { value: 'Illinois', label: 'Illinois' },
    { value: 'Indiana', label: 'Indiana' },
    { value: 'Iowa', label: 'Iowa' },
    { value: 'Kansas', label: 'Kansas' },
    { value: 'Kentucky', label: 'Kentucky' },
    { value: 'Louisiana', label: 'Louisiana' },
    { value: 'Maine', label: 'Maine' },
    { value: 'Marshall Islands', label: 'Marshall Islands' },
    { value: 'Maryland', label: 'Maryland' },
    { value: 'Massachusetts', label: 'Massachusetts' },
    { value: 'Michigan', label: 'Michigan' },
    { value: 'Minnesota', label: 'Minnesota' },
    { value: 'Mississippi', label: 'Mississippi' },
    { value: 'Missouri', label: 'Missouri' },
    { value: 'Montana', label: 'Montana' },
    { value: 'Nebraska', label: 'Nebraska' },
    { value: 'Nevada', label: 'Nevada' },
    { value: 'New Hampshire', label: 'New Hampshire' },
    { value: 'New Jersey', label: 'New Jersey' },
    { value: 'New Mexico', label: 'New Mexico' },
    { value: 'New York', label: 'New York' },
    { value: 'North Carolina', label: 'North Carolina' },
    { value: 'North Dakota', label: 'North Dakota' },
    { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
    { value: 'Ohio', label: 'Ohio' },
    { value: 'Oklahoma', label: 'Oklahoma' },
    { value: 'Oregon', label: 'Oregon' },
    { value: 'Palau', label: 'Palau' },
    { value: 'Pennsylvania', label: 'Pennsylvania' },
    { value: 'Puerto Rico', label: 'Puerto Rico' },
    { value: 'Rhode Island', label: 'Rhode Island' },
    { value: 'South Carolina', label: 'South Carolina' },
    { value: 'South Dakota', label: 'South Dakota' },
    { value: 'Tennessee', label: 'Tennessee' },
    { value: 'Texas', label: 'Texas' },
    { value: 'Utah', label: 'Utah' },
    { value: 'Vermont', label: 'Vermont' },
    { value: 'Virgin Islands', label: 'Virgin Islands' },
    { value: 'Virginia', label: 'Virginia' },
    { value: 'Washington', label: 'Washington' },
    { value: 'West Virginia', label: 'West Virginia' },
    { value: 'Wisconsin', label: 'Wisconsin' },
    { value: 'Wyoming', label: 'Wyoming' },
];
